import React, { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ManagePowerBIModel } from "@store/common/powerbi.model";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";

interface PowerBIAddUrlModalProps {
  handleAdd: (powerBI: ManagePowerBIModel) => void;
  handleClose: () => void;
}

const PowerBIAddUrlModal = (props: PowerBIAddUrlModalProps) => {
  const {handleAdd, handleClose} = props;

  const {t} = useTranslation();

  const [data, setData] = useState<ManagePowerBIModel>({});

  const updateData = (name: string) => (value: any) => setData((state) => ({...state, [name]: value}));

  return (
    <ModalComponent
      maxWidth="xs"
      title={t("clients.details.parametersTab.powerBI.addUrl")}
      titleLeft
      content={(
        <Stack px="30px" spacing="15px" py="10px">
          <AIOTextfieldComponent
            title={t("clients.details.parametersTab.powerBI.label")}
            placeholder={t("clients.details.parametersTab.powerBI.label")}
            onChange={updateData('label')}
            value={data.label || ''}/>
          <AIOTextfieldComponent
            title={t("clients.details.parametersTab.powerBI.url")}
            placeholder={t("clients.details.parametersTab.powerBI.url")}
            onChange={updateData('url')}
            value={data.url || ''}/>
        </Stack>
      )}
      actions={(
        <AIOButtonComponent
          disabled={!data.label || !data.url}
          onClick={() => handleAdd(data)}
          variant="contained"
          title={t("global.add")}/>
      )}
      handleClose={handleClose}/>
  );
};

export default PowerBIAddUrlModal;
