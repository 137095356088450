import React from "react";
import { BrowserRouter } from "react-router-dom";
import theme from "./themes/index";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import { CheckCircleOutline, ErrorOutline, HighlightOff } from "@mui/icons-material";
import Screens from "@screens/index";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { enableElfProdMode } from "@ngneat/elf";

if (process.env.NODE_ENV === "production") {
  enableElfProdMode();
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          iconVariant={{
            success: <CheckCircleOutline style={{ marginRight: 10 }} />,
            error: <HighlightOff style={{ marginRight: 10 }} />,
            info: <ErrorOutline style={{ marginRight: 10 }} />,
          }}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}>
          <CssBaseline />

          <BrowserRouter basename={process.env.PUBLIC_URL}>
            <Screens />
          </BrowserRouter>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
