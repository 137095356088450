import { Tab, Tabs } from "@mui/material";

interface AIOTabComponentProps {
  tabs: string[];
  handleChange?: (value: number) => void;
  value?: number;
  variant?: "scrollable" | "standard";
}

const AIOTabComponent = (props: AIOTabComponentProps) => {
  const { tabs, handleChange, value, variant } = props;

  return (
    <Tabs
      onChange={(evt, value) => handleChange?.(value)}
      value={value}
      variant={variant}
      TabIndicatorProps={{ style: { display: "none" } }}>
      {tabs.map((tab, index) => (
        <Tab key={`Tab ${index} - ${tab}`} label={tab} value={index} />
      ))}
    </Tabs>
  );
};

export default AIOTabComponent;
