import { select } from "@ngneat/elf";
import { selectPaginationData } from "@ngneat/elf-pagination";

import { companiesStore } from "./companies.store";
import { getCompaniesDataSource } from "./companies.requests";

export class CompaniesQuery {
  store = companiesStore;

  companies$ = getCompaniesDataSource.data$();
  companiesPaginationData$ = this.store.pipe(selectPaginationData());

  notReattachedWithApplicationCount$ = this.store.pipe(select((state) => state.notReattachedWithCandidatesCount));

  filters$ = this.store.pipe(select((state) => state.filters));

  sort$ = this.store.pipe(select((state) => state.sort));
}

export const companiesQuery = new CompaniesQuery();
