import { Box, Checkbox, FormControlLabel, Stack, Switch, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { ManageEntityParametersModel } from "@store/entities/details";
import { ManageSellsyModel } from "@store/common/sellsy.model";

import { Colors } from "@constants/colors.constant";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";

interface SellsyConfigComponentProps {
  errors: { [key: string]: any };
  isActivated?: boolean;
  sellsy: ManageSellsyModel;
  updateParameters: Dispatch<SetStateAction<ManageEntityParametersModel>>;
}

const SellsyConfigComponent = (props: SellsyConfigComponentProps) => {
  const {errors, isActivated = false, sellsy, updateParameters} = props;

  const { t } = useTranslation();

  const updateSellsy = (name: string) => (value: any) => {
    updateParameters((state) => ({
      ...state,
      sellsy: {
        ...state.sellsy,
        [name]: value,
      },
    }))
  };

  return (
    <Stack width="100%" p="25px" pb="35px" bgcolor={Colors.background} borderRadius="15px" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
        <Typography fontSize={16} fontWeight="700">
          {t("clients.details.parametersTab.sellsy.sellsy")}
        </Typography>
        <Switch
          checked={isActivated}
          disabled={isActivated}
          onChange={(evt, checked) =>
            updateParameters((state) => ({...state, sellsyActivated: checked}))
          }/>
      </Stack>
      {isActivated && (
        <Stack spacing={1}>
          <Box pl="12px">
            <FormControlLabel
              onChange={(evt, checked) => updateSellsy("subscriptionInvoiceDisabled")(checked)}
              control={<Checkbox size="small" />}
              checked={sellsy.subscriptionInvoiceDisabled}
              label={
                <Typography ml="10px" fontWeight="300">
                  {t("clients.details.parametersTab.sellsy.invoiceSub")}
                </Typography>
              } />
          </Box>
          <Box flex={1}>
            <AIOTextfieldComponent
              title={t("clients.details.parametersTab.sellsy.sellsyClientId")}
              placeholder={t("clients.details.parametersTab.sellsy.sellsyClientId")}
              onChange={updateSellsy("sellsyClientId")}
              value={sellsy.sellsyClientId}
              color="secondary"
              error={errors.sellsy.sellsyClientId}
              fullWidth />
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default SellsyConfigComponent;
