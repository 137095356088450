import { catchError, from, map, Observable, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";

import { getBannersDataSource } from "@store/banners/banners.requests";
import { bannersStore } from "@store/banners/banners.store";
import { BannerModel, BannersFilters, ManageBanner } from "@store/banners/banners.model";

import SnackError from "@utils/error.utils";
import APIAxios, { APIRoutes } from "@api/axios.api";

export class BannersService {
  store = bannersStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<BannersFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getBanners = (filters?: BannersFilters): Observable<BannerModel[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETBanners(),
        params: {
          search: filters?.search || undefined,
          affiliateIds: filters?.affiliates?.some((x) => x.value === "FAVORITES") ? undefined : filters?.affiliates?.map((x) => x.value) || undefined,
          favorite: filters?.affiliates?.some((x) => x.value === "FAVORITES") || undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<BannerModel[]>) => {
        return response.data;
      }),
      tap((banners) => {
        this.store.update(setEntities(banners), getBannersDataSource.setSuccess());
      }),
      getBannersDataSource.trackRequestStatus()
    );
  };

  getBannerForUser = (): Observable<BannerModel[]> => {
    return from(APIAxios(APIRoutes.GETBannerForUser())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<BannerModel[]>) => {
        return response.data;
      })
    );
  };

  getBanner = (bannerId: string): Observable<BannerModel> => {
    return from(APIAxios(APIRoutes.GETBanner(bannerId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<BannerModel>) => {
        return response.data;
      })
    );
  };

  createBanner = (banner: ManageBanner): Observable<BannerModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTBanner(),
        data: {
          ...banner,
          affiliates: banner.affiliates?.map((a) => a.value),
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<BannerModel>) => {
        return response.data;
      }),
      tap((banner) => {
        this.store.update(addEntities(banner));
      })
    );
  };

  updateBanner = (bannerId: string, banner: ManageBanner): Observable<BannerModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHBanner(bannerId),
        data: {
          ...banner,
          affiliates: banner.affiliates?.map((a) => a.value),
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<BannerModel>) => {
        return response.data;
      }),
      tap((updatedBanner) => {
        this.store.update(updateEntities(updatedBanner.id, updatedBanner));
      })
    );
  };

  deleteBanner = (bannerId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETEBanner(bannerId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      tap(() => {
        this.store.update(deleteEntities(bannerId));
      })
    );
  };
}

export const bannersService = new BannersService();
