import { SelectItem } from "@components/input/Select.component";
import I18n from "@utils/i18n.utils";

export enum ATSEnum {
  EASY_CRUIT = "EASY_CRUIT",
  TALENT_SOFT = "TALENT_SOFT",
  SMART_RECRUITERS = "SMART_RECRUITERS",
  FLUX_CONVERTER = "FLUX_CONVERTER",
  AI_O = "AI_O",
}

export namespace ATSEnumFunctions {
  export const selectItems: SelectItem[] = [
    { label: I18n.t(`ats.${ATSEnum.EASY_CRUIT}`), value: ATSEnum.EASY_CRUIT },
    { label: I18n.t(`ats.${ATSEnum.TALENT_SOFT}`), value: ATSEnum.TALENT_SOFT },
    { label: I18n.t(`ats.${ATSEnum.SMART_RECRUITERS}`), value: ATSEnum.SMART_RECRUITERS },
    { label: I18n.t(`ats.${ATSEnum.FLUX_CONVERTER}`), value: ATSEnum.FLUX_CONVERTER },
    { label: I18n.t(`ats.${ATSEnum.AI_O}`), value: ATSEnum.AI_O },
  ];
}

export const atsToManageATS = (ats?: ATSModel) => ({
  ...ats,
  type: ats ? ATSEnumFunctions.selectItems.find((a) => a.value === ats.type) : undefined,
});

export interface ManageATSModel extends Partial<Omit<ATSModel, "type">> {
  type?: SelectItem;
}

export interface ATSModel {
  url: string;
  type: ATSEnum;
  companyNames: string[];
  autoStartCampaign: boolean;
}