import { Colors } from "@constants/colors.constant";
import { CardActionArea, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { filesService } from "@store/files";

interface LinkCardProps {
  icon: React.ReactNode;
  text: string;
  link: string;
}

const LinkCard = (props: LinkCardProps) => {
  const { icon, text, link } = props;
  return (
    <CardActionArea
      onClick={() => filesService.openUrlInNewTab(link)}
      sx={{
        borderRadius: "15px !important"
      }}>
      <Stack direction="row" alignItems="center"
             sx={{ backgroundColor: Colors.lightMarine, borderRadius: "15px", p: "20px" }}>
        <Stack width="30px">{icon}</Stack>
        <Typography
          fontSize="14px"
          fontWeight={300}
          mx="16px"
          dangerouslySetInnerHTML={{
            __html: text
          }} />
        <Stack
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
          sx={{
            backgroundColor: Colors.primary,
            width: "30px",
            height: "30px",
            borderRadius: "50%",
            boxShadow: "0 8px 20px 0 rgb(232 97 77 / 50%)",
            ml: "auto"
          }}>
          <img src="/images/arrow_white.svg" alt="arrow-right" />
        </Stack>
      </Stack>
    </CardActionArea>
  );
};

export default LinkCard;
