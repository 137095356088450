import { select } from "@ngneat/elf";

import { affiliateDetailsStore } from "@store/entities/afiliates/details/affiliateDetails.store";
import {
  getAffiliateActionsDataSource,
  getAffiliateCommentsDataSource,
  getAffiliateDetailsDataSource,
  getAffiliateDetailsToolboxToolsDataSource
} from "@store/entities/afiliates/details/affiliateDetails.requests";

export class AffiliateDetailsQuery {
  store = affiliateDetailsStore;

  details$ = getAffiliateDetailsDataSource.data$();
  comments$ = getAffiliateCommentsDataSource.data$();
  actions$ = getAffiliateActionsDataSource.data$();
  toolboxTools$ = getAffiliateDetailsToolboxToolsDataSource.data$();
  actionsPaginatedMeta$ = this.store.pipe(select((state) => state.actionsPaginatedMeta));

  affiliateDetails$ = this.store.pipe(select((state) => state.details))

  get timeline() {
    return this.store.getValue().details?.timeline;
  }

  get prefilteredJobBoards() {
    return this.store.getValue().details?.prefilteredJobBoards || [];
  }
}

export const affiliateDetailsQuery = new AffiliateDetailsQuery();
