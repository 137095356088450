import React from "react";
import { IconButton, Stack, Tooltip, Typography } from "@mui/material";
import styled from "@emotion/styled";

import { BannerModel } from "@store/banners";

import { Colors } from "@constants/colors.constant";
import { useTranslation } from "react-i18next";

const StyledBannerCard = styled(Stack)({
  padding: "20px",
  borderRadius: "15px",
  backgroundColor: Colors.white,
  border: `1px solid ${Colors.cardBorder}`,
  width: "100%",
});

interface BannerCardProps {
  banner: BannerModel;
  onClick: () => void;
}

const BannerCard = (props: BannerCardProps) => {
  const { banner, onClick } = props;

  const { t } = useTranslation();

  return (
    <StyledBannerCard direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
      <Typography fontWeight={500}>{banner.title}</Typography>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Tooltip
          arrow
          title={
            <Stack spacing={0.25} minWidth="150px">
              <Typography fontSize={12} fontWeight="500">
                {t("adminParameters.banners.companies")}
              </Typography>
              {banner.affiliates?.map((a) => (
                <Typography key={a.id} fontSize={12} fontWeight="300">
                  &#x2022; {a.name}
                </Typography>
              ))}
            </Stack>
          }>
          <Stack height="20px" width="20px" border={`2px solid ${Colors.primary}`} borderRadius="50%" alignItems="center" justifyContent="center">
            <Typography color="primary" textAlign="center" fontWeight="500" lineHeight={1}>
              {banner.affiliates.length}
            </Typography>
          </Stack>
        </Tooltip>

        <IconButton onClick={onClick}>
          <img height={"20px"} alt="edit answer" src="/images/edit.svg" />
        </IconButton>
      </Stack>
    </StyledBannerCard>
  );
};

export default BannerCard;
