import styled from "@emotion/styled";
import { IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AdModel } from "@store/ads";

import { Colors } from "@constants/colors.constant";
import { AdRoutes, Pages } from "@utils/routes.utils";

const AdsListContainer = styled(Stack)(
  (props: { inaccordion?: string }) => `
  border-radius: ${props.inaccordion === "true" ? 0 : "15px"} ${props.inaccordion === "true" ? 0 : "15px"} 15px 15px;
  background-color: ${props.inaccordion ? 'transparent' : Colors.users.listBackground};
`
);

const AdRow = styled(Stack)`
  padding: 16px 22px;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.users.border};
  }
`;

interface AdsListComponentProps {
  ads: AdModel[];
  affiliateId?: string;
  inAccordion?: boolean;
}

const AdsListComponent = (props: AdsListComponentProps) => {
  const { ads, affiliateId, inAccordion} = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const {t} = useTranslation();

  return (
    <AdsListContainer inaccordion={inAccordion ? "true" : undefined}>
      {ads.map((ad) => {
        return (
          <AdRow key={ad.id} direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing="10px">
            <Stack direction={"row"} alignItems={"center"} spacing={"12px"} minWidth={breakpointDownSM ? "45%" : "30%"}>
              <Typography fontSize={14} fontWeight={500} width={breakpointDownMD ? "100px" : "auto"}>
                {t("global.reference", {reference: ad.reference})} - {ad.name}
              </Typography>
            </Stack>

            {!breakpointDownSM && (
              <Stack minWidth={"30%"}>
                <Typography fontSize={14} fontWeight={300}>
                  {ad.location}
                </Typography>
              </Stack>
            )}

            <IconButton sx={{ ml: "auto" }} onClick={() => navigate(`/${Pages.ADS}/${ad.id}/${AdRoutes.DETAIL}`)}>
              <EastIcon color="primary" />
            </IconButton>
          </AdRow>
        );
      })}
    </AdsListContainer>
  );
};
export default AdsListComponent;
