import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";

import { adsService } from "@store/ads/ads.service";
import { AdsFilters, AdsSort } from "@store/ads/ads.model";

interface SearchAdsParams {
  filters?: AdsFilters;
  sort?: AdsSort;
  page?: number;
  take?: number;
  withStatsAndBoostInfo?: boolean;
}

export const searchAdsEffect = createEffectFn((params$: Observable<SearchAdsParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ filters, sort, page, take, withStatsAndBoostInfo }) =>
      withStatsAndBoostInfo ? adsService.getAdsWithStats(filters, sort, page, take) : adsService.getAds(filters, sort, page, take)
    )
  );
});
