import React, { useMemo, useState } from "react";
import { Box, Checkbox, FormControlLabel, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { ConnectorMetaData, connectorsService, ConnectorTypeEnum, ManageConnector } from "@store/connectors";

import { FieldValidationType, getFieldError } from "@utils/yup.utils";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import ConfirmModal from "@components/modal/Confirm.modal";

interface ActivateConnectorModalProps {
  affiliateId: string;
  connectorId?: string;
  connectorMetaData: ConnectorMetaData;
  handleClose: () => void;
  isCreation?: boolean;
}

const ActivateConnectorModal = (props: ActivateConnectorModalProps) => {
  const {affiliateId, connectorId, connectorMetaData, handleClose, isCreation} = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ManageConnector>({
    affiliateId,
    media: connectorMetaData.media,
    type: connectorMetaData.type,
  });

  const [confirmationChecked, setConfirmationChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const errors = useMemo(() => {
    return {
      ...(connectorMetaData.type === ConnectorTypeEnum.PASSWORD
        ? {
          login: getFieldError(data.login, FieldValidationType.REQUIRED_STRING),
          password: getFieldError(data.password, FieldValidationType.REQUIRED_STRING),
        }
        : {}),
      ...(connectorMetaData.type === ConnectorTypeEnum.URL
        ? {
          company: getFieldError(data.company, FieldValidationType.REQUIRED_STRING),
          url: getFieldError(data.url, FieldValidationType.REQUIRED_STRING),
        }
        : {}),
    };
  }, [connectorMetaData, data]);

  const updateData = (name: string) => (value: any) => setData((state) => ({ ...state, [name]: value }));


  const handleActivate = () => {
    if (isCreation) {
      setLoading(true);
      connectorsService
        .activateConnector(data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t('connectors.success.activated'), {variant: 'success'});
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      if (!connectorId) return;
      setLoading(true);
      connectorsService
        .updateConnector(connectorId, data)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t('connectors.success.updated'), {variant: 'success'});
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  };

  if (connectorMetaData.type === ConnectorTypeEnum.STANDALONE) {
    return (
      <ConfirmModal
        closeOnDemand
        logo={connectorMetaData.logo}
        handleClose={handleClose}
        handleConfirm={handleActivate}
        confirmMsg={t('connectors.confirmModal.messageActivate')}
        modalTitle={connectorMetaData.name}
        hideCancel/>
    );
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={connectorMetaData.name}
      content={
        <Stack alignItems={"center"} py="15px" spacing={3}>
          {connectorMetaData.logo}

          {connectorMetaData.type === ConnectorTypeEnum.PASSWORD && (
            <Stack width="100%" spacing={3}>
              <Box width="100%">
                <AIOTextfieldComponent
                  placeholder={t("connectors.login")}
                  title={t("connectors.login")}
                  onChange={updateData("login")}
                  value={data.login}
                  error={errors.login}
                  fullWidth
                />
              </Box>
              <Box width="100%">
                <AIOTextfieldComponent
                  title={t("connectors.password")}
                  placeholder={t("connectors.password")}
                  onChange={updateData("password")}
                  value={data.password || ""}
                  error={errors.password}
                  endAdornment={
                    <IconButton size="small" sx={{ mr: "-5px" }} onClick={() => setShowPassword((state) => !state)}>
                      <img alt="show password" src={`/images/icon_${showPassword ? "hide" : "show"}_pwd_primary.svg`} />
                    </IconButton>
                  }
                  type={showPassword ? undefined : "password"}
                  fullWidth
                  required
                />
              </Box>
            </Stack>
          )}

          {connectorMetaData.type === ConnectorTypeEnum.URL && (
            <Stack width="100%" spacing={3}>
              <Box width="100%">
                <AIOTextfieldComponent
                  placeholder={t("connectors.company")}
                  title={t("connectors.company")}
                  onChange={updateData("company")}
                  value={data.company}
                  error={errors.company}
                  fullWidth
                  required
                />
              </Box>
              <Box width="100%">
                <AIOTextfieldComponent
                  title={t("connectors.url", { media: connectorMetaData.name })}
                  placeholder={t("connectors.url", { media: connectorMetaData.name })}
                  onChange={updateData("url")}
                  value={data.url || ""}
                  error={errors.url}
                  fullWidth
                  required
                />
              </Box>
            </Stack>
          )}

          <Stack pt="10px" spacing={3}>
            <FormControlLabel
              sx={{alignItems: 'flex-start'}}
              checked={confirmationChecked}
              onChange={(_, evt) => setConfirmationChecked(evt)}
              control={<Checkbox />}
              label={(
                <Typography ml="10px" fontSize="12px" lineHeight="1.25" fontWeight="300">
                  {t(`connectors.confirmModal.legalConfirm.${connectorMetaData.media}`)}
                </Typography>
              )} />
          </Stack>
        </Stack>
      }
      maxWidth="xs"
      paddingButton="10px 0px 40px 0px"
      actions={(
        <AIOButtonComponent
          title={t("global.confirm")}
          ariaLabel="modal"
          variant="contained"
          disabled={loading || !confirmationChecked}
          onClick={handleActivate} />
      )}
    />
  );
}

export default ActivateConnectorModal;