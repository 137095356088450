import React, { ChangeEvent, useRef, useState } from "react";
import { alpha, CardActionArea, CircularProgress, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { CompanyDetailsDetailsModel, companyDetailsService } from "@store/ai-o/companies/details";

import { Colors } from "@constants/colors.constant";

interface CoverPictureBlockProps {
  company: CompanyDetailsDetailsModel;
}

const CoverPictureBlock = (props: CoverPictureBlockProps) => {
  const { company } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar()

  const inputRef = useRef<HTMLInputElement | null>(null);

  const [loading, setLoading] = useState(false);

  const handleUpdatePicture = (evt: ChangeEvent<HTMLInputElement>) => {
    const files = evt.target.files;
    if (!!files?.length) {
      setLoading(true);
      companyDetailsService.updateCoverPictureCompany(company.id, files[0]).pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("aio.clients.details.coverPicture.success"), { variant: "success"});
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
    evt.target.value = '';
  }

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("aio.clients.details.coverPicture.title")}
          </Typography>
        </Stack>
        <Stack
          component={CardActionArea}
          sx={{
            background: company.pictureCoverURL
              ? `linear-gradient(${alpha(Colors.black, 0.4)} 0%, ${alpha(Colors.black, 0.4)} 100%),
                url(${company.pictureCoverURL})`
              : alpha(Colors.primary, 0.1),
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
          disabled={loading}
          onClick={() => inputRef.current?.click()}
          height="130px"
          width="100%"
          borderRadius="7px">
          {loading ? (
            <CircularProgress sx={{color: company.pictureCoverURL ? Colors.white : Colors.primary}} size="20px"/>
          ) : (
            <img alt="edit" src={`/images/icon_edit_fill_${company.pictureCoverURL ? 'white' : 'primary'}.svg`} />
          )}
        </Stack>
        <input accept="image/*" ref={inputRef} hidden type="file" onChange={handleUpdatePicture}/>
      </Stack>
    </Stack>
  );
};

export default CoverPictureBlock;
