import { catchError, from, map, Observable, switchMap, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";
import { deleteEntities, setEntities } from "@ngneat/elf-entities";

import { getUsersDataSource } from "@store/users/users.requests";
import { usersStore } from "@store/users/users.store";
import {
  ManageUserModel,
  manageUserToFormData,
  UserModel,
  UserRoleEnum,
  UsersFilters,
  UsersSort,
  UsersSortField
} from "@store/users/users.model";
import { sessionQuery } from "@store/session";

import SnackError from "@utils/error.utils";
import APIAxios, { APIRoutes } from "@api/axios.api";
import { SelectItem } from "@components/input/Select.component";

export class UsersService {
  store = usersStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<UsersFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getUsers = (filters?: UsersFilters, sort?: UsersSort): Observable<UserModel[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETUsers(),
        params: {
          search: filters?.search || undefined,
          roles: filters?.roles || undefined,
          clientIds: filters?.clientIds || undefined,
          affiliateIds: filters?.affiliateIds || undefined,
          sort: sort?.field,
          order: sort?.field ? 1 : undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UserModel[]>) => {
        return response.data;
      }),
      tap((users) => {
        this.store.update(setEntities(users), getUsersDataSource.setSuccess());
      }),
      getUsersDataSource.trackRequestStatus()
    );
  };

  searchUsers =
    (filters?: UsersFilters) =>
    async (search?: string): Promise<SelectItem[]> => {
      try {
        const response: AxiosResponse<UserModel[]> = await APIAxios({
          ...APIRoutes.GETUsers(),
          params: {
            search: search ?? undefined,
            limit: filters?.limit ?? undefined,
            roles: filters?.roles ?? undefined,
            clientIds: filters?.clientIds || undefined,
            sort: UsersSortField.LASTNAME,
            order: 1,
          },
        });
        return response.data.map((e) => ({ label: `${e.lastname} ${e.firstname}`, value: e.id, data: e }));
      } catch (err: any) {
        return [];
      }
    };

  getUserDetails = (userId: number | string): Observable<UserModel> => {
    return from(APIAxios(APIRoutes.GETUserDetails(userId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UserModel>) => {
        return response.data;
      })
    );
  };

  createUser = (user: ManageUserModel): Observable<UserModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUser(),
        data: manageUserToFormData(user),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UserModel>) => {
        return response.data;
      }),
      switchMap((user) => {
        return this.getUsers(this.store.getValue().filters, this.store.getValue().sort).pipe(map(() => user));
      })
    );
  };

  updateUser = (userId: number | string, user: ManageUserModel): Observable<UserModel> => {
    const isCurrentUser = userId === sessionQuery.user.id;

    return from(
      APIAxios({
        ...(isCurrentUser ? APIRoutes.PUTUser() : APIRoutes.PUTUser()),
        data: manageUserToFormData(user, userId, isCurrentUser),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UserModel>) => {
        return response.data;
      }),
      switchMap((user) => {
        return this.getUsers(this.store.getValue().filters, this.store.getValue().sort).pipe(map(() => user));
      })
    );
  };

  deleteUser = (userId: string): Observable<UserModel> => {
    return from(APIAxios(APIRoutes.DELETEUser(userId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UserModel>) => {
        return response.data;
      }),
      tap((deletedUser) => {
        this.store.update(deleteEntities(deletedUser.id));
      }),
      switchMap((user) => {
        return this.getUsers(this.store.getValue().filters, this.store.getValue().sort).pipe(map(() => user));
      })
    );
  };

  getAdminUsers = (): Observable<UserModel[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETUsers(),
        params: {
          roles: [UserRoleEnum.ADMIN],
          sort: UsersSortField.LASTNAME,
          order: 1,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UserModel[]>) => {
        return response.data;
      })
    );
  };

  getUsersCsv = () => {
    return from(APIAxios(APIRoutes.GETUsersCsv())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  };
}

export const usersService = new UsersService();
