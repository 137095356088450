import { StripeElementLocale } from "@stripe/stripe-js";

export enum AppCountryEnum {
  FR = "FR",
  US = "US",
}

export const getAppCountry = (): AppCountryEnum => {
  switch (process.env.REACT_APP_COUNTRY) {
    case "FR":
      return AppCountryEnum.FR;
    case "US":
      return AppCountryEnum.US;
    default:
      return AppCountryEnum.FR;
  }
};

export const getAppCurrency = (): string => {
  switch (getAppCountry()) {
    case AppCountryEnum.FR:
      return "€";
    case AppCountryEnum.US:
      return "$";
    default:
      return "€";
  }
};

export const getToLocalStringCode = (): string => {
  switch (getAppCountry()) {
    case AppCountryEnum.FR:
      return "fr-FR";
    case AppCountryEnum.US:
      return "en-US";
    default:
      return "fr-FR";
  }
};

export const getStripeElementsLocale = (): StripeElementLocale => {
  switch (getAppCountry()) {
    case AppCountryEnum.FR:
      return "fr";
    case AppCountryEnum.US:
      return "en";
    default:
      return "fr";
  }
};

export const getLocalizedDateInputFormat = (): string => {
  switch (getAppCountry()) {
    case AppCountryEnum.FR:
      return "DD/MM/YYYY";
    case AppCountryEnum.US:
      return "MM/DD/YYYY";
    default:
      return "DD/MM/YYYY";
  }
}

export const getCurrencyLogo = (): string => {
  switch (getAppCountry()) {
    case AppCountryEnum.FR:
      return "/images/salary_white_euros.svg";
    case AppCountryEnum.US:
      return "/images/salary_white_dollars.svg";
    default:
      return "/images/salary_white_euros.svg";
  }
}
