import React, { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";

interface ATSAddCompanyNameModalProps {
  handleAdd: (companyName: string) => void;
  handleClose: () => void;
}

const ATSAddCompanyNameModal = (props: ATSAddCompanyNameModalProps) => {
  const {handleAdd, handleClose} = props;

  const {t} = useTranslation();

  const [companyName, setCompanyName] = useState("");

  return (
    <ModalComponent
      maxWidth="xs"
      title={t("clients.details.parametersTab.ats.addCompanyName")}
      titleLeft
      content={(
        <Stack px="30px" spacing="15px" py="10px">
          <AIOTextfieldComponent
            title={t("clients.details.parametersTab.ats.companyName")}
            placeholder={t("clients.details.parametersTab.ats.companyName")}
            onChange={setCompanyName}
            value={companyName}/>
        </Stack>
      )}
      actions={(
        <AIOButtonComponent
          disabled={!companyName}
          onClick={() => handleAdd(companyName)}
          variant="contained"
          title={t("global.add")}/>
      )}
      handleClose={handleClose}/>
  );
};

export default ATSAddCompanyNameModal;
