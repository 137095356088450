import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";
import React, { useEffect, useState } from "react";

import { stripeProductsQuery } from "@store/stripe/products/stripeProducts.query";
import { stripeProductsService } from "@store/stripe/products/stripeProducts.service";
import { searchStripeProductsEffect } from "@store/stripe/products/stripeProducts.effects";
import { StripeProductTypeEnum } from "@store/stripe/products/stripeProducts.model";
import { jobBoardsQuery } from "@store/jobBoards";

import AIOSearchComponent from "@components/input/AIOSearch.component";
import StripeProductCard from "@components/card/StripeProduct.card";
import AIOButtonComponent from "@components/Button.component";
import StripeProductsUpdateConfirmModal
  from "@screens/auth/admin/parameters/components/StripeProductsUpdateConfirm.modal";

const AdminStripeProductsTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const searchStripeProducts = useEffectFn(searchStripeProductsEffect);

  const [{ stripeProducts, error: stripeProductsError, loading: stripeProductsLoading }] = useObservable(stripeProductsQuery.stripeProducts$);

  const [stripeProductsCount] = useObservable(stripeProductsQuery.stripeProductsCount$);

  const [filters] = useObservable(stripeProductsQuery.filters$);

  const [sequences] = useObservable(jobBoardsQuery.sequences$);
  const [jobBoards] = useObservable(jobBoardsQuery.jobBoards$);
  const [boostActions] = useObservable(jobBoardsQuery.boostActions$);
  const [schools] = useObservable(jobBoardsQuery.schools$);

  const [openUpdateConfirm, setOpenUpdateConfirm] = useState(false);

  useEffect(() => {
    stripeProductsService.resetStore();
  }, []);

  useEffect(() => {
    if (stripeProductsError) enqueueSnackbar((stripeProductsError as any).text, (stripeProductsError as any).options);
  }, [stripeProductsError, enqueueSnackbar]);

  useEffect(() => {
    searchStripeProducts({ filters });
  }, [filters, searchStripeProducts]);

  return (
    <Stack px={breakpointDownSM ? "10px" : "40px"} flex={1} width="100%" overflow="auto">
      <Stack direction="row" alignItems="center" spacing={2} mb={breakpointDownSM ? "0px" : "28px"}>
        <Typography fontSize="20px" fontWeight="700">
          {stripeProductsLoading ? "-" : ""}
          {!stripeProductsLoading && t(`adminParameters.stripeProducts.amount`, { count: stripeProductsCount })}
        </Typography>
        {stripeProductsLoading && <CircularProgress size={20} />}
      </Stack>
      <Stack py={breakpointDownSM ? "20px" : undefined} spacing={"20px"}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
          <Stack
            direction={breakpointDownSM ? "column-reverse" : "row"}
            width="100%"
            alignItems="center"
            spacing={breakpointDownSM ? 0 : 3}
            justifyContent={breakpointDownSM ? "flex-start" : "flex-end"}
            mb={breakpointDownSM ? "20px" : 0}>
            <AIOSearchComponent
              width="100%"
              placeholder={t("adminParameters.stripeProducts.search")}
              onChange={(value) => stripeProductsService.setFilters({ search: value })}
            />
            <Box width="fit-content" whiteSpace="nowrap">
              <AIOButtonComponent
                onClick={() => setOpenUpdateConfirm(true)}
                icon={<img alt="" src="/images/icon_refresh_white.svg" />}
                title={t("adminParameters.stripeProducts.update")}
                variant="contained"
                color="secondary"
              />
            </Box>
          </Stack>
        </Stack>
        <Box
          overflow={breakpointDownSM ? undefined : "auto"}
          className={breakpointDownSM ? undefined : "scrollable"}
          py={breakpointDownSM ? "0px" : "30px"}
          px="10px">
          <Stack spacing={breakpointDownSM ? 3 : 6}>
            <Stack spacing={2}>
              <Box>
                <Grid container spacing={3}>
                  {stripeProducts.map((sp) => {
                    let productType = StripeProductTypeEnum.NONE;

                    if (sequences.some((s) => s.stripeProductId === sp.id)) {
                      productType = StripeProductTypeEnum.SEQUENCE;
                    } else if (jobBoards.some((j) => j.stripeProductId === sp.id)) {
                      productType = StripeProductTypeEnum.JOB_BOARD;
                    } else if (boostActions.some((b) => b.stripeProductId === sp.id)) {
                      productType = StripeProductTypeEnum.BOOST_ACTION;
                    } else if (schools.some((s) => s.stripeProductId === sp.id)) {
                      productType = StripeProductTypeEnum.SCHOOL;
                    }

                    return (
                      <Grid item xs={6} sm={4} lg={3} key={sp.id}>
                        <StripeProductCard stripeProduct={sp} productType={productType} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      {openUpdateConfirm && (
        <StripeProductsUpdateConfirmModal handleClose={() => setOpenUpdateConfirm(false)} />
      )}
    </Stack>
  );
};

export default AdminStripeProductsTab;
