import React, { useEffect, useState } from "react";
import { CircularProgress, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";
import { Outlet, useNavigate } from "react-router-dom";

import { searchUsersEffect, UserAffiliateRoleEnum, UserRoleEnum, usersQuery, usersService } from "@store/users";
import { sessionQuery } from "@store/session";

import AIOButtonComponent from "@components/Button.component";
import UsersAccordionComponent from "@screens/auth/admin/entities/components/UsersAccordion.component";
import AddUserModal from "@screens/auth/admin/entities/components/AddUser.modal";

const ClientUsersTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [user] = useObservable(sessionQuery.user$);

  const searchUsers = useEffectFn(searchUsersEffect);

  const [{ users, error: usersError, loading: usersLoading }] = useObservable(usersQuery.users$);

  const [usersCount] = useObservable(usersQuery.usersCount$);
  const [filters] = useObservable(usersQuery.filters$);

  const [openAddUser, setOpenAddUser] = useState(false);

  useEffect(() => {
    usersService.resetStore();
    const affiliateIds = sessionQuery.user?.affiliates?.map((a) => a.affiliate.id) ?? [];
    usersService.setFilters({
      affiliateIds,
      roles: [UserRoleEnum.CLIENT],
    });
  }, []);

  useEffect(() => {
    if (usersError) enqueueSnackbar((usersError as any).text, (usersError as any).options);
  }, [usersError, enqueueSnackbar]);

  useEffect(() => {
    searchUsers({ filters });
  }, [filters, searchUsers]);

  const canAddUsers = user?.affiliates.some((a) => a.role === UserAffiliateRoleEnum.RESPONSIBLE);

  return (
    <Stack flex={1} width="100%" px={breakpointDownSM ? "0px" : "20px"} overflow="auto" py="60px">
      <Stack sx={{ px: breakpointDownSM ? "4px" : undefined }}>
        <Stack width="100%">
          <Stack spacing={4}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              {usersLoading ? (
                <CircularProgress />
              ) : (
                <Typography fontSize={16} fontWeight="500">
                  {t("parameters.users.amount", { count: usersCount })}
                </Typography>
              )}
              <div>
                {canAddUsers && (
                  <AIOButtonComponent
                    title={t("parameters.users.addUser")}
                    onClick={() => setOpenAddUser(true)}
                    variant="contained"
                    color="secondary" />
                )}
              </div>
            </Stack>

            {(sessionQuery.user?.affiliates || []).map((a) => (
              <UsersAccordionComponent
                key={a.affiliate.id}
                entity={a.affiliate}
                users={(users || []).filter((u) =>
                  u.affiliates.find((ua) => ua.affiliate.id === a.affiliate.id)
                )} />
            ))}
          </Stack>
        </Stack>
      </Stack>
      {openAddUser && <AddUserModal entity={sessionQuery.user.client} handleClose={() => setOpenAddUser(false)} />}
      <Outlet />
    </Stack>
  );
};

export default ClientUsersTab;