import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { from } from "rxjs";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { sessionQuery } from "@store/session";
import { UserRoleEnum, usersService, UsersSortField } from "@store/users";
import { affiliateDetailsQuery } from "@store/entities/afiliates/details";

import dayjsUtils from "@utils/dayjs.utils";

import ActionTimelineComponent from "@screens/auth/admin/entities/components/timeline/ActionTimeline.component";
import NewMediaComponent from "@screens/auth/admin/entities/components/timeline/NewMedia.component";
import ProSubscriptionsCard from "@screens/auth/admin/entities/components/subscription/ProSubscriptions.card";
import CompanySubscriptionsCard from "@screens/auth/admin/entities/components/subscription/CompanySubscriptions.card";

const EntitySubscriptionTab = () => {
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const {t} = useTranslation();
  const {entityId} = useParams();
  const {enqueueSnackbar} = useSnackbar();

  const [affiliate] = useObservable(affiliateDetailsQuery.affiliateDetails$);

  useEffect(() => {
    from(usersService.getUsers(
      { roles: [UserRoleEnum.CLIENT], affiliateIds: entityId ? [entityId] : undefined },
      { field: UsersSortField.LASTNAME } ))
      .subscribe({ error: (err) => enqueueSnackbar(err.text, err.options) })
  }, [enqueueSnackbar, entityId]);

  if (!affiliate) return null;

  return (
    <>
      <Stack width="100%" alignItems="center" py={breakpointDownSM ? "20px" : "40px"} overflow="auto" className="scrollable">
        <Container maxWidth="md" sx={{ padding: breakpointDownSM ? 0 : undefined }}>
          <Stack spacing={2}>
            <Typography fontSize="20px" fontWeight={700}>
              {t("subscriptions.currentSubscription")}
            </Typography>
            <Stack direction="row" spacing="30px">
              <Box flex={0.5}>
                <ProSubscriptionsCard/>
              </Box>
              <Box flex={0.5}>
                <CompanySubscriptionsCard
                  subscriptions={affiliate.subscriptions.filter((s) =>
                    dayjsUtils().isBetween(s.startDate, s.endDate, 'd', '[]')
                  )}/>
              </Box>
            </Stack>
          </Stack>
          {sessionQuery.role === UserRoleEnum.ADMIN && (
            <Stack spacing={2} sx={{ marginTop: "36px" }}>
              <NewMediaComponent/>
              <ActionTimelineComponent affiliate={affiliate}/>
            </Stack>
          )}
        </Container>
      </Stack>
      <Outlet />
    </>
  );
};

export default EntitySubscriptionTab;
