import React, { useEffect, useState } from "react";
import { alpha, Box, Checkbox, FormControlLabel, IconButton, Radio, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { manageUserFromUser, ManageUserModel, UserAffiliateRoleEnum, UserAffiliateRoleEnumFunctions, UserRoleEnum, usersService } from "@store/users";
import { sessionQuery } from "@store/session";

import { Colors } from "@constants/colors.constant";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import AvatarPickerComponent from "@components/input/AvatarPicker.component";
import ConfirmModal from "@components/modal/Confirm.modal";
import { entityDetailsService } from "@store/entities/details";
import { EntityTypeEnum } from "@store/entities/entities.model";
import { AffiliateModel } from "@store/entities/afiliates";

export const RoleForm = styled(FormControlLabel)({
  margin: "0px 0px 0px 10px",
  alignItems: "flex-start",
  "& .MuiFormControlLabel-label": {
    fontSize: 12,
    fontWeight: 300,
    paddingLeft: "2px",
  },
});

export const RadioBtn = styled(Radio)({
  width: "13px",
  height: "13px",
  alignSelf: "center",
  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
    color: Colors.primary,
    width: "13px",
    height: "13px",
  },
  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
    color: Colors.primary,
    width: "13px",
    height: "13px",
  },
});

export const CheckBoxRadioBtn = styled(Checkbox)({
  width: "13px",
  height: "13px",
  alignSelf: "center",
  "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
    color: Colors.primary,
    width: "13px",
    height: "13px",
  },
  "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
    color: Colors.primary,
    width: "13px",
    height: "13px",
  },
});

interface UserModalProps {
  entityType?: EntityTypeEnum;
  handleClose?: () => void;
  isCreation?: UserRoleEnum;
  readOnly?: boolean;
}

const UserModal = (props: UserModalProps) => {
  const { entityType, handleClose: handleCloseProps, isCreation, readOnly } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { entityId, userId } = useParams();

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const [affiliates, setAffiliates] = useState<AffiliateModel[]>([]);

  const [openSelectAvatar, setOpenSelectAvatar] = useState(false);
  const [user, setUser] = useState<ManageUserModel>({
    role: isCreation,
    dontSendWelcomeEmail: false,
  });

  const handleClose = () => (handleCloseProps ? handleCloseProps() : navigate("..", { replace: true }));

  useEffect(() => {
    if (!isCreation && !!userId) {
      usersService.getUserDetails(userId).subscribe({
        next: (u) => setUser(manageUserFromUser(u)),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
    // TODO: CHECK IF BETTER SOLUTION
    if (entityType && !readOnly) {
      entityDetailsService.getEntityDetails(entityType, entityId || sessionQuery.user.client?.id).subscribe({
        next: (entity) => {
          if (entity.type === EntityTypeEnum.CLIENT) {
            setUser((state) => ({ ...state, client: entity.id }));
          } else if (entity.type === EntityTypeEnum.AFFILIATE) {
            setUser((state) => ({ ...state, client: entity.client?.id }));
          }
          setAffiliates(entity.affiliates ?? []);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [enqueueSnackbar, entityId, entityType, readOnly, isCreation, userId]);

  if (!isCreation && !user.id) return null;

  const handleSave = () => {
    if (isCreation) {
      usersService
        .createUser(user)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("userDetails.success.created"), { variant: "success" });
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      if (!userId) return;
      setLoading(true);
      usersService
        .updateUser(userId, user)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("userDetails.success.edited"), { variant: "success" });
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  };

  const handleDelete = () => {
    if (!userId) return;
    setLoading(true);
    usersService
      .deleteUser(userId)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("userDetails.success.deleted"), { variant: "success" });
          setOpenDelete(false);
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const canEdit = !readOnly && (isCreation || sessionQuery.role === UserRoleEnum.ADMIN);

  const showPostalCode = process.env.REACT_APP_COUNTRY === "US";

  const errors = {
    firstname: getFieldError(user.firstname, FieldValidationType.REQUIRED_STRING),
    lastname: getFieldError(user.lastname, FieldValidationType.REQUIRED_STRING),
    ...(showPostalCode ? { postalCode: getFieldError(user.postalCode, FieldValidationType.REQUIRED_STRING) } : {}),
    position: getFieldError(user.position, FieldValidationType.REQUIRED_STRING),
    email: !!isCreation || sessionQuery.role === UserRoleEnum.ADMIN ? getFieldError(user.email, FieldValidationType.REQUIRED_EMAIL) : undefined,
    client: user.role === UserRoleEnum.CLIENT ? getFieldError(user.client, FieldValidationType.REQUIRED_STRING) : undefined,
    affiliates:
      user.role === UserRoleEnum.CLIENT ? user.affiliates?.map((a) => (!a.affiliate || !a.role ? t("errors.missingFields") : undefined)) : undefined,
  };

  const updateUser = (name: string) => (value: any) => setUser((state) => ({ ...state, [name]: value }));
  const updateUserAccess = (index: number, name: string) => (value: any) => {
    const access = [...(user.affiliates ?? [])];
    access[index] = { ...access[index], [name]: value };
    updateUser("affiliates")(access);
  };

  const selectableAffiliates = affiliates.filter(
    (a) =>
      !user.affiliates?.some((ua) => ua.affiliate?.value === a.id) &&
      (sessionQuery.role === UserRoleEnum.ADMIN ||
        sessionQuery.user.affiliates.some((ua) => ua.affiliate.id === a.id && ua.role === UserAffiliateRoleEnum.RESPONSIBLE))
  );

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        titleLeft
        title={t(`userDetails.title.${isCreation ? "new" : "details"}`)}
        content={
          <Stack px={breakpointDownMD ? "10px" : "20px"} spacing={3} py={breakpointDownMD ? "15px" : "25px"}>
            <Stack alignItems="center">
              <IconButton
                aria-label="avatar-circle"
                disabled={!canEdit}
                onClick={() => setOpenSelectAvatar(true)}
                sx={{
                  background:
                    user.pictureFile || user.picture
                      ? `linear-gradient(${alpha(Colors.black, 0.4)}, ${alpha(Colors.black, 0.4)}), url(${
                          user.pictureFile ? URL.createObjectURL(user.pictureFile) : user.picture
                        })`
                      : "",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}>
                {canEdit && <img alt="" src="/images/camera.svg" />}
              </IconButton>
            </Stack>
            <AIOTextfieldComponent
              required
              title={t("userDetails.lastname")}
              placeholder={t("userDetails.lastname")}
              value={user.lastname ?? ""}
              error={errors.lastname}
              readonly={!canEdit}
              onChange={updateUser("lastname")}
            />
            <AIOTextfieldComponent
              required
              title={t("userDetails.firstname")}
              placeholder={t("userDetails.firstname")}
              value={user.firstname ?? ""}
              error={errors.firstname}
              readonly={!canEdit}
              onChange={updateUser("firstname")}
            />
            {showPostalCode && (
              <AIOTextfieldComponent
                required
                title={t("userDetails.postalCode")}
                placeholder={t("userDetails.postalCode")}
                value={user.postalCode ?? ""}
                error={errors.postalCode}
                readonly={!canEdit}
                pattern={/[0-9]+/}
                onChange={updateUser("postalCode")}
              />
            )}
            <AIOTextfieldComponent
              required
              title={t("userDetails.position")}
              placeholder={t("userDetails.position")}
              value={user.position ?? ""}
              error={errors.position}
              readonly={!canEdit}
              onChange={updateUser("position")}
            />
            <AIOTextfieldComponent
              required
              title={t("userDetails.email")}
              placeholder={t("userDetails.email")}
              readonly={readOnly || (!isCreation && sessionQuery.role !== UserRoleEnum.ADMIN)}
              value={user.email ?? ""}
              error={errors.email}
              onChange={updateUser("email")}
            />
            {isCreation && sessionQuery.role === UserRoleEnum.ADMIN && (
              <FormControlLabel
                onChange={(evt, checked) => {
                  setUser((state) => ({ ...state, dontSendWelcomeEmail: checked }));
                }}
                disabled={loading}
                control={<Checkbox size="small" />}
                checked={user.dontSendWelcomeEmail ?? false}
                label={
                  <Typography ml="10px" mt="1px" fontSize="12px" lineHeight={1.25}>
                    {t("userDetails.dontSendWelcomeEmail")}
                  </Typography>
                }
              />
            )}
            {user.role !== UserRoleEnum.ADMIN && (
              <Stack spacing="10px">
                <Stack direction="row" justifyContent="space-between" alignItems="center" mr="-12px">
                  <Typography fontSize="17px" fontWeight={700} color={Colors.secondary}>
                    {t("userDetails.addAccesses")}
                  </Typography>
                  {!readOnly &&
                    selectableAffiliates.length >
                      (user.affiliates?.filter((ua) => selectableAffiliates.some((sa) => sa.id === ua.affiliate?.value) || !ua.affiliate).length ||
                        0) &&
                    sessionQuery.user.id !== user.id && (
                      <AIOButtonComponent
                        title={t("userDetails.addAccess")}
                        onClick={() => setUser((state) => ({ ...state, affiliates: (state.affiliates ?? []).concat({}) }))}
                        color="primary"
                      />
                    )}
                </Stack>
                <Stack spacing={3}>
                  {user.affiliates?.map((access, index) => {
                    const canEditAccess =
                      !readOnly &&
                      (sessionQuery.role === UserRoleEnum.ADMIN ||
                        sessionQuery.user.affiliates.some(
                          (a) => (!access.affiliate || a.affiliate.id === access.affiliate?.value) && a.role === UserAffiliateRoleEnum.RESPONSIBLE
                        )) &&
                      sessionQuery.user.id !== user.id;

                    return (
                      <Stack
                        key={`affiliate - ${access?.affiliate?.value || index}`}
                        spacing="5px"
                        alignItems="center"
                        direction="row"
                        width={!canEditAccess ? "calc(100% + 11px)" : undefined}
                        ml={canEditAccess ? "-8px !important" : undefined}>
                        {canEditAccess && (
                          <IconButton
                            onClick={() => {
                              setUser((state) => ({
                                ...state,
                                affiliates: (state.affiliates ?? []).filter((_, aIndex) => aIndex !== index),
                              }));
                            }}>
                            <img alt="remove" src="/images/icon_remove.svg" />
                          </IconButton>
                        )}
                        <Stack direction="row" alignItems="center" spacing="10px" flex={1}>
                          <SelectWithSearchComponent
                            readOnly={!canEditAccess}
                            error={errors.affiliates?.[index]}
                            placeholder={t("userDetails.selectAffiliate")}
                            items={selectableAffiliates.map((a) => ({ label: a.name, value: a.id }))}
                            value={access?.affiliate || ""}
                            handleChange={updateUserAccess(index, "affiliate")}
                          />
                          <Box width="240px">
                            <SelectWithSearchComponent
                              readOnly={!canEditAccess}
                              placeholder={t("userDetails.selectRole")}
                              items={UserAffiliateRoleEnumFunctions.allItems}
                              value={access?.role ? { label: UserAffiliateRoleEnumFunctions.label(access.role), value: access.role } : undefined}
                              handleChange={(r) => updateUserAccess(index, "role")(r?.value)}
                            />
                          </Box>
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>
              </Stack>
            )}
          </Stack>
        }
        maxWidth="xs"
        actions={
          <>
            {!isCreation && !readOnly && sessionQuery.role === UserRoleEnum.ADMIN && userId !== sessionQuery.user.id && (
              <AIOButtonComponent
                title={t("global.delete")}
                ariaLabel="modal"
                disabled={loading || checkIfErrors(errors)}
                onClick={() => setOpenDelete(true)}
                color="primary"
              />
            )}
            {!readOnly &&
              (sessionQuery.role === UserRoleEnum.ADMIN ||
                sessionQuery.affiliatesItems.some((a) => a.data.role === UserAffiliateRoleEnum.RESPONSIBLE)) && (
                <AIOButtonComponent
                  title={t(`global.${isCreation ? "add" : "modify"}`)}
                  ariaLabel="modal"
                  disabled={loading || checkIfErrors(errors)}
                  onClick={handleSave}
                  variant="contained"
                  color="secondary"
                />
              )}
          </>
        }
      />
      {openSelectAvatar && (
        <AvatarPickerComponent
          picture={user.picture}
          pictureFile={user.pictureFile}
          handleChangePicture={(file, avatar) => {
            setUser((state) => ({ ...state, pictureFile: file, picture: avatar }));
          }}
          handleClose={() => setOpenSelectAvatar(false)}
        />
      )}
      {openDelete && (
        <ConfirmModal
          handleClose={() => setOpenDelete(false)}
          handleConfirm={handleDelete}
          confirmMsg={t("userDetails.delete.message")}
          modalTitle={t("userDetails.delete.title")}
        />
      )}
    </>
  );
};

export default UserModal;
