import { alpha, IconButton, Stack } from "@mui/material";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { ManageTone, Tone, tonesService } from "@store/ai-o/tones";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

import { Colors } from "@constants/colors.constant";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";

interface TonesModalProps {
  handleClose: () => void;
  tone?: Tone;
}

const TonesModal = (props: TonesModalProps) => {
  const { handleClose, tone } = props

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const inputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ManageTone>(tone ?? {});

  const updateData = (name: string) => (value: any) => setData((state) => ({...state, [name]: value}));

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      const file = event.target.files[0];
      if (file) {
        updateData("logoFile")(file);
      }
    }
  };

  const errors = {
    title: getFieldError(data.title, FieldValidationType.REQUIRED_STRING),
    description: getFieldError(data.description, FieldValidationType.REQUIRED_STRING),
    logo: !data?.logoURL ? getFieldError(data.logoFile, FieldValidationType.REQUIRED_FILE) : undefined,
  };

  const handleSaveModal = () => {
    if (!!tone) {
      setLoading(true);
      tonesService.updateTone(tone.id, data).pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("aio.tones.success.edit"), {variant: "success"});
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      setLoading(true);
      tonesService.createTone(data).pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("aio.tones.success.edit"), {variant: "success"});
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  }

  return (
    <ModalComponent
      maxWidth="sm"
      handleClose={handleClose}
      title={t(`aio.tones.${tone ? 'editTitle' : 'addTitle'}`)}
      titleLeft
      actions={(
        <AIOButtonComponent
          disabled={checkIfErrors(errors) || loading}
          title={t('global.validate')}
          variant="contained"
          onClick={handleSaveModal} />
      )}
      content={(
        <Stack py="20px">
          <Stack alignItems="center">
            <input ref={inputRef} hidden onChange={handleSelectFile} accept="image/*" type="file" />
            <IconButton
              aria-label="avatar-rounded"
              onClick={() => inputRef.current?.click()}
              sx={{
                background:
                  data.logoFile || data.logoURL
                    ? `linear-gradient(${alpha(Colors.black, 0.4)}, ${alpha(Colors.black, 0.4)}), url(${
                      data.logoFile ? URL.createObjectURL(data.logoFile) : data.logoURL
                    })`
                    : "",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}>
              <img alt="" src="/images/camera.svg" />
            </IconButton>
          </Stack>
          <Stack mt="45px" spacing={3}>
            <AIOTextfieldComponent
              required
              value={data.title ?? ''}
              onChange={updateData('title')}
              title={t('aio.tones.fields.title')}
              placeholder={t('aio.tones.fields.title')}
              error={errors.title} />
            <AIOTextfieldComponent
              required
              multiline
              value={data.description ?? ''}
              onChange={updateData('description')}
              minRows={8}
              title={t('aio.tones.fields.description')}
              placeholder={t('aio.tones.fields.description')}
              error={errors.description} />
          </Stack>
        </Stack>
      )} />
  )
}

export default TonesModal