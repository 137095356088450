import { entitiesPropsFactory, setEntities, UIEntitiesRef, updateAllEntities, updateEntities, upsertEntities } from "@ngneat/elf-entities";
import { catchError, from, map, Observable, tap } from "rxjs";
import { BoostAction, JobBoard, JobBoardsFilters, School, Sequence } from "./jobBoards.model";
import { jobBoardsStore } from "./jobBoards.store";
import APIAxios, { APIRoutes } from "@api/axios.api";
import { AxiosError, AxiosResponse } from "axios";
import SnackError from "@utils/error.utils";

const { sequencesEntitiesRef } = entitiesPropsFactory("sequences");
const { boostActionsEntitiesRef } = entitiesPropsFactory("boostActions");
const { schoolsEntitiesRef } = entitiesPropsFactory("schools");

export class JobBoardsService {
  store = jobBoardsStore;

  resetSelected = () => this.store.update(updateAllEntities({ selected: false }, { ref: UIEntitiesRef }));

  setFilters = (filters: Partial<JobBoardsFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getJobBoards = (): Observable<JobBoard[]> => {
    return from(APIAxios(APIRoutes.GETJobBoards())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<JobBoard[]>) => {
        return response.data;
      }),
      tap((jobBs) => {
        this.store.update(
          setEntities(jobBs.sort((a, b) => a.name.localeCompare(b.name))),
          upsertEntities(
            jobBs.map((j) => ({ id: j.id, selected: false })),
            { ref: UIEntitiesRef }
          )
        );
      })
    );
  };

  getSequences = (): Observable<Sequence[]> => {
    return from(APIAxios(APIRoutes.GETSequences())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Sequence[]>) => {
        return response.data;
      }),
      tap((sequence) => {
        this.store.update(
          setEntities(sequence, { ref: sequencesEntitiesRef }),
          upsertEntities(
            sequence.map((j) => ({ id: j.id, selected: false })),
            { ref: UIEntitiesRef }
          )
        );
      })
    );
  };

  getBoostActions = (): Observable<BoostAction[]> => {
    return from(APIAxios(APIRoutes.GETBoostActions())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<BoostAction[]>) => {
        return response.data;
      }),
      tap((boostAction) => {
        this.store.update(
          setEntities(boostAction, { ref: boostActionsEntitiesRef }),
          upsertEntities(
            boostAction.map((b) => ({ id: b.id, selected: false })),
            { ref: UIEntitiesRef }
          )
        );
      })
    );
  };

  getSchools = (): Observable<School[]> => {
    return from(APIAxios(APIRoutes.GETSchools())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<School[]>) => {
        return response.data;
      }),
      tap((schools) => {
        this.store.update(
          setEntities(schools, { ref: schoolsEntitiesRef }),
          upsertEntities(
            schools.map((s) => ({ id: s.id, selected: false })),
            { ref: UIEntitiesRef }
          )
        );
      })
    );
  };

  selectJobBoardOrSequenceOrBoostAction = (jobBoardOrSequenceId: string) => {
    this.store.update(
      updateEntities(
        jobBoardOrSequenceId,
        (jobBoardOrSequenceUI) => ({
          ...jobBoardOrSequenceUI,
          selected: !jobBoardOrSequenceUI.selected,
        }),
        { ref: UIEntitiesRef }
      )
    );
  };
}

export const jobBoardsService = new JobBoardsService();
