import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CompanyDetailsDetailsModel } from "@store/ai-o/companies/details";

import { Colors } from "@constants/colors.constant";

import EditPresentationModal from "@screens/auth/admin/aio/customerDetails/profileBlocks/EditPresentation.modal";

interface PresentationBlockProps {
  company: CompanyDetailsDetailsModel;
}

const PresentationBlock = (props: PresentationBlockProps) => {
  const { company } = props;

  const { t } = useTranslation();

  const [openEdit, setOpenEdit] = useState(false);

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("aio.clients.details.presentation.title")}
          </Typography>
          <Box sx={{"& > .MuiButton-root": { padding: "5px 10px" }}}>
            <Button variant="text" color="primary" onClick={() => setOpenEdit(true)}>
              {t("global.modify")}
            </Button>
          </Box>
        </Stack>
        <Stack>
          <Typography fontSize="14px" fontWeight="500" color={Colors.greyLight} pt="1px">
            {company.description}
          </Typography>
        </Stack>
      </Stack>
      {openEdit && <EditPresentationModal company={company} handleClose={() => setOpenEdit(false)}/>}
    </Stack>
  );
};

export default PresentationBlock;
