import { catchError, from, map, Observable, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";
import { Application } from "@store/applications";
import { finishedTwilioAiCallStatus, TwilioAiCall, TwilioWebsocketMessage } from "./twilioAiCalls.model";
import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { getAllEntities, setEntities, updateEntities, upsertEntities } from "@ngneat/elf-entities";
import { getTwilioAiCallsDataSource } from "./twilioAiCalls.requests";
import { twilioAiCallsStore } from "./twilioAiCalls.store";
import socketUtilsInstance from "@utils/socket.utils";
import { adDetailsService } from "@store/ads/details";

export class TwilioAiCallsService {
  store = twilioAiCallsStore;

  resetStore = () => this.store.reset();

  makeAiCall = (application: Application): Observable<TwilioAiCall> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTMakeAiCall(),
        data: {
          applicationId: application.id,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<TwilioAiCall>) => {
        return response.data;
      }),
      tap((twilioAiCall) => {
        const entitiesFiltered = this.store.query(getAllEntities()).filter((entity) => entity.application.id !== twilioAiCall.application.id);

        this.store.update(setEntities([...entitiesFiltered, twilioAiCall]));
      })
    );
  };

  getAiCalls = (announcementId: string): Observable<TwilioAiCall[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAiCalls(announcementId),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<TwilioAiCall[]>) => {
        return response.data;
      }),
      tap((twilioAiCalls) => {
        this.store.update(setEntities(twilioAiCalls), getTwilioAiCallsDataSource.setSuccess());
      }),
      getTwilioAiCallsDataSource.trackRequestStatus()
    );
  };

  getAiCall = (applicationId: string): Observable<TwilioAiCall> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAiCall(applicationId),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<TwilioAiCall>) => {
        return response.data;
      })
    );
  };

  downloadCall = (callSid: string) => {
    return from(
      APIAxios({
        ...APIRoutes.GETDownloadCall(callSid),
      })
    ).pipe(
      map((response: AxiosResponse<string>) => {
        return response.data;
      })
    );
  };

  upsertEntities = (twilioAiCall: TwilioAiCall) => {
    this.store.update(upsertEntities(twilioAiCall));
  };

  handleSocketMessage = (twilioWebsocketMessage: TwilioWebsocketMessage) => {
    const twilioAiCalls = this.store.query(getAllEntities());
    const twilioAiCall = twilioAiCalls.find((twilioAiCall) => twilioAiCall.callSid === twilioWebsocketMessage.callSid);

    if (twilioAiCall) {
      if (twilioWebsocketMessage.data.callStatus) {
        twilioAiCall.callStatus = twilioWebsocketMessage.data.callStatus;
      }
      if (twilioWebsocketMessage.data.conversationMessages) {
        twilioWebsocketMessage.data.conversationMessages.forEach((conversationMessage) => {
          twilioAiCall.conversation.push(conversationMessage);
        });
      }

      if (twilioWebsocketMessage.data.recordingSid) {
        twilioAiCall.recordingSid = twilioWebsocketMessage.data.recordingSid;
      }

      if (twilioWebsocketMessage.data.callAnalysis) {
        twilioAiCall.callAnalysis = twilioWebsocketMessage.data.callAnalysis;
      }

      this.store.update(updateEntities(twilioAiCall.id, twilioAiCall));
      window.dispatchEvent(new Event(`twilio_call_changed_${twilioAiCall.application.id}`));
      adDetailsService.getAdApplicationDetails(twilioAiCall.application.id).subscribe();
    }

    if (twilioWebsocketMessage.data.callStatus && finishedTwilioAiCallStatus.includes(twilioWebsocketMessage.data.callStatus)) {
      socketUtilsInstance.removeListener(twilioWebsocketMessage.callSid);
    }
  };

  handleSocketListeners = (twilioAiCalls: TwilioAiCall[]) => {
    twilioAiCalls.forEach((twilioAiCall) => {
      if (!finishedTwilioAiCallStatus.includes(twilioAiCall.callStatus)) {
        socketUtilsInstance.listenEvent(twilioAiCall.callSid, this.handleSocketMessage);
      }
    });
  };
}

export const twilioAiCallsService = new TwilioAiCallsService();
