import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";
import { StripeProductsFilters } from "@store/stripe/products/stripeProducts.model";
import { stripeProductsService } from "@store/stripe/products/stripeProducts.service";

interface SearchStripeProductsParams {
  filters?: StripeProductsFilters;
}

export const searchStripeProductsEffect = createEffectFn((params$: Observable<SearchStripeProductsParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ filters }) =>
      stripeProductsService.getStripeProducts(filters)
    )
  );
});
