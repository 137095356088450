import { Button, Stack, Typography } from "@mui/material";
import { BoldTitle, Content } from "../legalStyle";
import AIOLinkInTxt from "@components/AIOLinkInTxt";
import { useResponsive } from "@utils/useResponsive";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutesUnAuth } from "@utils/routes.utils";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery } from "@store/session";

interface CGUFRProps {
  hideHeader?: boolean;
}

const CGUFR = (props: CGUFRProps) => {
  const {hideHeader} = props;
  
  const { isMobileOrTablet } = useResponsive();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [{ user }] = useObservable(sessionQuery.isAuthenticated$);
  const isAuthenticated = !!user;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && containerRef.current.parentElement) containerRef.current.parentElement.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Stack
      px={isMobileOrTablet ? "16px" : "40px"}
      pb={isMobileOrTablet ? "5px" : "60px"}
      height="100%"
      width="100%"
      overflow="auto"
      ref={containerRef}>
      {!hideHeader && (
        <>
          <Stack width={"100%"} direction={"row"} mt={isMobileOrTablet ? "15px" : "40px"}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<img alt="back" src="/images/arrow_back.svg" />}
              onClick={() => (isAuthenticated ? navigate(-1) : navigate(RoutesUnAuth.LOGIN))}>
              <Typography fontWeight="300" color="secondary">
                {t(`global.back`)}
              </Typography>
            </Button>
          </Stack>

          <Stack mt={"14px"} alignSelf={"center"} direction={"row"}>
            <Typography fontWeight={700} fontSize={"30px"}>
              Conditions Générales de ALLInOne
            </Typography>
          </Stack>
        </>
      )}
      <Stack mt={"45px"} alignSelf={"center"} spacing={3}>
        <BoldTitle>Article 1 : Présentation de ALLInOne</BoldTitle>
        <Content>
          TWININ exploite une solution logicielle accessible via le site
          <AIOLinkInTxt url={"https://aio.twinin.fr/login"} label="https://aio.twinin.fr/login" />
          (ci-après la {`<< Plateforme >>`}), à travers laquelle elle met à disposition de toute personne qui cherche recruter du personnel (ci-après
          le {`<< Recruteur >>`}) des outils lui permettant d'automatiser et ainsi d'améliorer ses campagnes et process de recrutement (ci-après les{" "}
          {`<< Services >>`}).
        </Content>
        <Content>TWININ et le Recruteur sont ci-après désignés ensemble ou individuellement une {`<< Partie >>`}.</Content>
        <Content>
          Les présentes conditions générales (ci-après les {`<< Conditions Générales >>`}) ont pour objet de fixer les modalités et conditions par
          lesquelles TWININ fournit aux Recruteurs les Services, ainsi que de définir les droits et obligations des Parties dans ce cadre.
        </Content>
        <Content>
          Elles expriment l'intégralité de l'accord des Parties et annulent et remplacent tout accord, lettre, offre ou autre document écrit ou oral
          antérieur ayant le même objet.
        </Content>
        <Content>Elles sont accessibles et imprimables à tout moment par un lien direct en page d'accueil de la Plateforme.</Content>
        <BoldTitle>Article 2 : Exploitant de la Plateforme et des Services</BoldTitle>
        <Content>
          La Plateforme et les Services sont exploités par la société TWININ, société par actions simplifiée, immatriculée au Registre du commerce et
          des sociétés de Paris sous le numéro 520 301 557, dont le siège social est situé 229 rue Saint-Honoré, 75001 Paris.
        </Content>
        <Content>TWININ peut être contactée aux coordonnées suivantes :</Content>
        <Stack spacing={1} pl={"20px"}>
          <Content>
            Adresse électronique : <AIOLinkInTxt url={"mailto:info@twinin.fr"} label="info@twinin.fr" />
          </Content>
          <Content>N° de TVA intracommunautaire : FR83520301557</Content>
        </Stack>
        <BoldTitle>Article 3 : Acceptation des Conditions Générales</BoldTitle>
        <Content>
          Le Recruteur déclare, en souscrivant aux Services dans les conditions prévues ci-après, avoir pris connaissance des présentes Conditions
          Générales et les accepter expressément.
        </Content>
        <Content>
          Cette acceptation, qui est matérialisée par une case à cocher dans le formulaire d'inscription, ne peut être que pleine et entière, sans
          modification de quelque nature que ce soit.
        </Content>
        <Content>
          Toute adhésion sous réserve est considérée comme nulle et non avenue. Le Recruteur qui n'accepte pas d'être lié par les présentes Conditions
          Générales ne doit pas souscrire aux Services, ni accéder à la Plateforme.
        </Content>
        <BoldTitle>Article 4 : Accès à la Plateforme et aux Services</BoldTitle>

        <Content>
          <p>La Plateforme et les Services sont accessibles :</p>
          <ul>
            <li>
              à toute personne physique disposant de la pleine capacité juridique pour s'engager au titre des présentes Conditions Générales et âgée
              d'au moins dix-huit (18) ans ;
            </li>
            <li>
              à toute personne morale agissant par l'intermédiaire d'une personne physique disposant des pouvoirs pour contracter au nom et pour le
              compte de la personne morale.
            </li>
          </ul>
          <p>Les Services s'adressent aux Recruteurs professionnels agissant dans le cadre de leur activité.</p>
        </Content>

        <BoldTitle>Article 5 : Inscription à la Plateforme et aux Services</BoldTitle>

        <Content>
          <BoldTitle>
            5.1 Pour accéder aux Services et y souscrire, le Recruteur est tenu d'ouvrir un compte sur la Plateforme, selon la procédure décrite
            ci-dessous (ci-après le {`<< Compte >>`}).
          </BoldTitle>
          <p>Aux fins d'ouverture de son Compte, le Recruteur doit :</p>
          <ul>
            <li>
              Remplir le formulaire prévu à cet effet en renseignant toutes les informations requises, et notamment ses nom, prénom, adresse email,
              photo de profil et l'intitulé de son poste ;
            </li>
            <li>Choisir un mot de passe, modifiable à tout moment. Il est recommandé de changer régulièrement de mot de passe ;</li>
            <li>Choisir la formule d'abonnement souhaitée ;</li>
            <li>
              Indiquer le nombre d'utilisateurs auxquels le Recruteur souhaite donner accès à la Plateforme (ci-après l'{`<< Utilisateur >>`}) ;
              chaque Utilisateur disposera d'un Compte lui permettant d'utiliser les Services ;
            </li>
            <li>
              Cocher, sur le formulaire d'inscription, les cases (i) {`<< J'ai lu et j'accepte les conditions générales de ALLInOne >>`} et (ii){" "}
              {`<< J'ai lu et j'accepte les conditions générales d'utilisation du prestataire de paiement Stripe >>`}.
            </li>
          </ul>
          <p>
            Le Recruteur doit fournir l'ensemble des informations marquées comme obligatoires, ce qu'il reconnaît et accepte. Toute inscription
            incomplète ne sera pas validée, ce que les Recruteurs reconnaissent et acceptent.
          </p>
          <p>
            Le Recruteur est informé que TWININ met en œuvre un processus de vérification du Compte créé par lui, afin notamment de s'assurer de son
            identité et/ou de ses coordonnées.
          </p>
          <p>
            Le Recruteur reconnaît et accepte que l'inscription aux Services entraîne une inscription automatique aux emails relatifs aux transactions
            depuis la Plateforme (message reçu, paiement accepté, etc.).
          </p>
        </Content>

        <Content>
          <BoldTitle>5.2 L'inscription des Recruteurs entraîne, après validation de TWININ, l'ouverture :</BoldTitle>
          <ul>
            <li>d'un Compte à leur nom, sur le back office de la Plateforme (ci-après le {`<< Compte Administrateur >>`}) ;</li>
            <li>
              d'autant de Compte(s) Utilisateur que renseigné dans le formulaire d'inscription (ci-après le ou les {`<< Compte(s) Utilisateur >>`}).
            </li>
          </ul>
          <p>
            Chaque Compte donne accès à un espace personnel (ci-après l'{`<< Espace Personnel >>`}) qui permet à la personne qui en est responsable de
            gérer son utilisation des Services sous une forme et selon les moyens techniques que TWININ juge les plus appropriés pour rendre les
            Services.
          </p>
          <p>
            Le Recruteur et/ou les Utilisateurs accèdent à tout moment à leur Espace Personnel après s'être identifié à l'aide de leur identifiant de
            connexion et de leur mot de passe.
          </p>
          <p>
            Le Recruteur garantit que toutes les informations qu'il renseigne dans le formulaire d'inscription sont exactes, à jour et sincères, et ne
            sont entachées d'aucun caractère trompeur.
          </p>
          <p>
            Il s'engage à mettre à jour sans délai ces informations dans son Espace Personnel en cas de modifications, afin qu'elles correspondent
            toujours aux critères susvisés.
          </p>
          <p>
            Les Recruteurs sont informés et acceptent que les informations saisies aux fins de création ou de mise à jour des Comptes valent preuve de
            l'identité des personnes qui l'utilisent. Les informations saisies par les Recruteurs les engagent dès leur validation.
          </p>
        </Content>
        <BoldTitle>Article 6 : Usage strictement personnel</BoldTitle>

        <Content>
          Le Recruteur s'engage à ce que seules les personnes, membres de son personnel, mandatées à cet effet utilisent le Compte Administrateur et
          les Comptes Utilisateurs. Il s'engage ainsi à ne permettre à aucun tiers, non expressément mandaté, d'utiliser les Comptes ou les Services à
          la place ou pour le compte de la personne expressément mandatée, sauf à en supporter l'entière responsabilité.
        </Content>

        <Content>
          Le Recruteur est par ailleurs responsable du maintien de la confidentialité des identifiants et mot de passe et reconnaît expressément que
          toute utilisation des Services depuis les Comptes sera réputée avoir été effectuée par la personne qui en est responsable (l'Administrateur
          ou l'Utilisateur concerné).
        </Content>

        <Content>
          Dans l'hypothèse où le Recruteur constaterait qu'un des Comptes a été utilisé par une personne non habilitée, il s'engage à en avertir
          TWININ dans les plus brefs délais, aux coordonnées mentionnées à l'article {`<< Exploitant de la Plateforme et des Services >>`}. Il
          reconnait à TWININ le droit de prendre toutes mesures appropriées en pareil cas.
        </Content>

        <BoldTitle>Article 7 : Usage non commercial</BoldTitle>

        <Content>
          Le Recruteur s'interdit de monnayer, vendre, concéder, échanger et plus généralement, négocier tout ou partie de l'accès aux Services ou à
          la Plateforme, ainsi qu'aux informations et/ou textes, images, et autres contenus exploités par TWININ sur la Plateforme dans le cadre de
          l'utilisation des Services.
        </Content>
        <BoldTitle>Article 8 : Description des Services</BoldTitle>

        <Content>
          Le Recruteur a accès aux Services qui suivent, en fonction de la formule d'abonnement qu'il a choisi, sous une forme et selon les moyens
          techniques que TWININ juge les plus appropriés.
        </Content>

        <Content>
          <BoldTitle>Formule {`<< Freemium >>`}</BoldTitle>
          <ul>
            <li>Diffusion et gestion, depuis son Compte, des offres d'emploi vers différents sites emploi;</li>
            <li>Réception, sur son Compte, de l'ensemble des candidatures adressées sur les différents sites emploi;</li>
            <li>
              Boost marketing ; ce Service, dédié à améliorer la visibilité des offres d'emploi, permet au Recruteur de bénéficier d'une augmentation
              du nombre de CV reçus par offre d'emploi;
            </li>
            <li>
              AI Finder ; ce Service permet de créer un {`<< matching >>`} ou une correspondance entre des profils de candidats sur Linkedin et des
              offres d'emploi publiées sur la Plateforme;
            </li>
            <li>
              Publier des offres d'emploi via l'IA sur Linkedin ; à travers ce Service, la Plateforme permet de générer, via l'intelligence
              artificielle et pour le compte du Recruteur, un post ciblé et personnalisé sur Linkedin;
            </li>
            <li>Communiquer directement avec les candidats;</li>
            <li>Se constituer, accéder et gérer une base de données candidats (Cvthèque).</li>
          </ul>
        </Content>

        <Content>
          <BoldTitle>Formule {`<< Professionnel >>`}</BoldTitle>
          <p>
            Cette formule permet au Recruteur de bénéficier de l'ensemble des Services proposés dans le cadre de la formule {`<< Freemium >>`}{" "}
            susvisée, mais également des Services complémentaires suivants :
          </p>
          <ul>
            <li>Création d'un site carrière ou site, pour le Recruteur, dédié au recrutement par l'IA;</li>
            <li>
              Analyse synthétique ou {`<< parsing >>`} de CV (illimité) ; à travers ce Service, le Recruteur a accès à un outil permettant de scanner
              et sélectionner toutes les informations contenues dans un CV.
            </li>
          </ul>
        </Content>

        <Content>
          <BoldTitle>Formule {`<< Entreprise >>`}</BoldTitle>
          <p>
            A travers cette formule, le Recruteur bénéficie des Services proposés dans le cadre de la formule {`<< Professionnel >>`}, mais également
            des Services complémentaires suivants :
          </p>
          <ul>
            <li>
              La mise en place de scénarios de sourcing automatisés ; cela correspond au Service {`<< Boost marketing >>`} qui sont payantes en plus
              au déclenchement;
            </li>
            <li>
              Le déploiement d'un plan média automatique par l'intelligence artificielle pour optimiser la diffusion des offres d'emploi du Recruteur.
            </li>
          </ul>
        </Content>

        <Content>
          <BoldTitle>Formule {`<< Marque employeur >>`}</BoldTitle>
          <p>A travers cette formule, le Recruteur a accès aux Services et fonctionnalités suivantes :</p>
          <ul>
            <li>Audit collaborateur.</li>
            <li>A/B Testing.</li>
            <li>Création.</li>
            <li>Déclinaison.</li>
            <li>Brand book.</li>
          </ul>
        </Content>

        <Content>
          TWININ se réserve la faculté de proposer tout autre Service qu'elle jugerait utile, sous une forme et selon les fonctionnalités et moyens
          techniques qu'elle estimera les plus appropriés pour rendre ces Services.
        </Content>

        <BoldTitle>Article 9 : Conditions financières</BoldTitle>

        <Content>
          <BoldTitle>9.1 Prix des Services</BoldTitle>
          <p>Le prix des Services est fonction de la formule d'abonnement choisie, tel que ci-après détaillé :</p>
          <ul>
            <li>
              <BoldTitle>Formule {`<< Freemium >>`} :</BoldTitle> Services accessibles à titre gratuit;
            </li>
            <li>
              <BoldTitle>Formule {`<< Professionnel >>`} :</BoldTitle> Services accessibles moyennant le versement de la somme mensuelle de 79 euros
              par Utilisateur.
            </li>
            <li>
              <BoldTitle>Formule {`<< Entreprise >>`} :</BoldTitle> Services accessibles moyennant le versement de la somme mensuelle de 99 euros par
              offre d'emploi publiée sur la Plateforme.
            </li>
            <li>
              <BoldTitle>Formule {`<< Marque employeur >>`} :</BoldTitle> Services accessibles à partir du paiement de la somme forfaitaire de 9 990
              euros.
            </li>
          </ul>
          <p>
            TWININ offre la possibilité à tout Recruteur qui le souhaite de la contacter pour obtenir des informations complémentaires sur les
            Services fournis dans le cadre de cette offre, mais aussi sur les modalités du paiement de son prix.
          </p>
          <p>Sauf mention contraire, les prix sont indiqués en euros et hors taxes.</p>
          <p>
            Il est précisé que TWININ applique sur ces prix une réduction de 10% pour toute souscription de ces offres dans le cadre d'un abonnement à
            durée déterminée annuel (cf : article {`<< Durée >>`} ci-après).
          </p>
        </Content>

        <Content>
          <BoldTitle>9.2 Modalités de paiement</BoldTitle>
          <p>
            Le paiement des Abonnements s'effectue par prélèvement automatique sur le compte bancaire dont les coordonnées ont été renseignées au
            moment de la souscription aux Services (ci-après le {`<< Prélèvement >>`}), ce que le Recruteur reconnait et accepte.
          </p>
          <p>
            Le Prélèvement du prix de l'Abonnement s'effectue à la date souscrite le premier mois, puis à cette même date tous les mois suivants, sauf
            indications contraires mentionnées sur la Plateforme au moment de la souscription (ci-après la {`<< Date du Prélèvement >>`}).
          </p>
          <p>Le Recruteur s'engage à prendre toutes les mesures nécessaires afin que le Prélèvement puisse être effectué.</p>
          <p>
            Le Prélèvement est réalisé par le prestataire de solutions de paiement sécurisé suivant STRIPE (ci-après le{" "}
            {`<< Prestataire de Paiement >>`}), auquel TWININ sous-traite la mise en œuvre du paiement et qui seul conserve les coordonnées bancaires
            du Recruteur à cette fin.
          </p>
          <p>
            Le Recruteur est informé que TWININ ne conserve aucune coordonnée bancaire. Le Recruteur procède au paiement au moyen de l'un des modes de
            paiement sécurisé proposés sur la Plateforme.
          </p>
          <p>Le Recruteur garantit TWININ qu'il dispose des autorisations nécessaires pour utiliser le mode de règlement choisi.</p>
          <p>
            TWININ adresse au Recruteur une facture correspondant au prix des Services qu'elle met à la disposition du Recruteur au sein de l'Espace
            Personnel de son Compte Administrateur.
          </p>
        </Content>

        <Content>
          <BoldTitle>9.3 Révision des prix</BoldTitle>
          <p>Le prix des Services peut faire l'objet d'une révision par TWININ à tout moment et à sa libre discrétion.</p>
          <p>
            Le Recruteur sera informé de toute modification de prix par tout moyen écrit utile et notamment par email, au minimum un mois et demi
            avant l'entrée en vigueur des nouveaux tarifs.
          </p>
          <p>Les nouveaux prix s'appliquent lors du renouvellement de l'Abonnement du Recruteur.</p>
          <p>
            Le Recruteur qui n'accepte pas les nouveaux prix doit dénoncer son Abonnement dans les conditions de l'article{" "}
            {`<< Désabonnement du Recruteur >>`}. A défaut, le Recruteur est réputé avoir accepté les nouveaux tarifs.
          </p>
        </Content>

        <Content>
          <BoldTitle>9.4 Données bancaires</BoldTitle>
          <p>
            Les informations à caractère bancaire des Recruteurs en rapport avec leur utilisation des Services font l'objet d'un traitement automatisé
            de données, dont le responsable est le Prestataire de Paiement.
          </p>
        </Content>

        <Content>
          <BoldTitle>9.5 Défaut de paiement</BoldTitle>
          <p>
            Le Recruteur est informé et accepte expressément que tout retard de paiement de tout ou partie des sommes dues à leur échéance entraînera
            automatiquement, sans préjudice des dispositions de l'article {`<< Résiliation pour manquement >>`}, sans mise en demeure préalable :
          </p>
          <ul>
            <li>La déchéance du terme de l'ensemble des sommes dues par le Recruteur et leur exigibilité immédiate;</li>
            <li>La suspension immédiate des Services en cours jusqu'au complet paiement de l'intégralité des sommes dues par le Recruteur;</li>
            <li>
              La facturation au profit de TWININ d'un intérêt de retard au taux de trois (3) fois le taux de l'intérêt légal, assis sur le montant de
              l'intégralité des sommes dues par le Recruteur et d'une indemnité forfaitaire de quarante (40) euros au titre des frais de recouvrement.
            </li>
          </ul>
        </Content>

        <BoldTitle>Article 10 : Durée</BoldTitle>

        <Content>
          <BoldTitle>10.1 Durée des Services</BoldTitle>
        </Content>

        <Content>Les Services sont fournis aux Recruteurs pendant toute la durée pour laquelle ils y ont souscrit.</Content>

        <Content>
          <ul>
            <li>
              Soit d'un abonnement à durée déterminée d'un (1) mois, renouvelable tacitement pour des périodes successives de même durée, sous réserve
              de toute dénonciation du Recruteur dans les conditions de l'article {`<< Désabonnement >>`} ci-après ;
            </li>
            <li>Soit d'un abonnement à durée déterminée de douze (12) mois, sans tacite reconduction.</li>
          </ul>
        </Content>

        <Content>Le choix de la durée de l'Abonnement est effectué par le Recruteur au moment de la souscription des Services.</Content>

        <Content>
          <BoldTitle>10.2 Désabonnement</BoldTitle>
        </Content>

        <Content>
          <BoldTitle>Abonnements renouvelables par tacite reconduction</BoldTitle>
        </Content>

        <Content>
          <ul>
            <li>
              Le Recruteur qui a souscrit à un Abonnement à durée déterminée d'un (1) mois renouvelable par tacite reconduction peut s'en désinscrire
              (ci-après le {`<< Désabonnement >>`}), au choix :
            </li>
            <ul>
              <li>
                Soit en adressant à TWININ, par email aux coordonnées mentionnées à l'article {`<< Exploitation de la Plateforme et des Services >>`}{" "}
                au plus tard 2 jours avant la date de son renouvellement, une demande à cet effet (ci-après le {`<< Désabonnement >>`}) ;
              </li>
              <li>
                Soit en dénonçant son Abonnement directement depuis la Plateforme, à travers son Compte, au plus tard 1 mois avant la date de son
                renouvellement.
              </li>
            </ul>
            <li>À défaut pour le Recruteur de se désabonner dans ces conditions, il est réengagé, au moins pour le mois suivant.</li>
          </ul>
        </Content>

        <Content>
          <BoldTitle>Abonnements sans reconduction tacite</BoldTitle>
        </Content>

        <Content>
          Le Recruteur ne peut se désabonner d'un Abonnement à durée déterminée de douze (12) mois, ce qu'il reconnaît et accepte. Il est ainsi tenu
          d'aller au terme de la durée de son Abonnement.
        </Content>

        <Content>Le Recruteur reçoit un e-mail de confirmation de son Désabonnement dans les meilleurs délais.</Content>

        <Content>
          <BoldTitle>10.3 Résiliation pour manquement</BoldTitle>
        </Content>

        <Content>
          Par exception, en cas de manquement du Recruteur à l'une quelconque de ses obligations au titre des présentes Conditions Générales, auquel
          il ne serait pas remédié dans un délai de trente (30) jours à compter de la réception d'une mise en demeure d'avoir à remédier audit
          manquement, TWININ pourra, de plein droit et sans formalité judiciaire, résilier les présentes Conditions Générales et fermer son ou ses
          Compte(s).
        </Content>

        <Content>
          La résiliation des présentes est sans préjudice de tous dommages-intérêts auxquels TWININ pourrait prétendre du fait du manquement du
          Recruteur et de toute pénalité qui lui serait éventuellement due.
        </Content>

        <Content>
          <BoldTitle>10.4 Conséquence de la fin des Services</BoldTitle>
        </Content>

        <Content>
          La fin des Services n'entraîne pas la suppression du ou des Compte(s) du Recruteur, qui continuera à y avoir accès, sans toutefois pouvoir
          utiliser les Services.
        </Content>

        <Content>
          Le Recruteur est expressément informé et accepte que l'ensemble des informations accessibles via son ou ses Compte(s) pourra rester
          accessible sur celui ou ceux-ci, pendant trois (3) mois maximum. Ainsi, le Recruteur fera son affaire pour récupérer les données sur le
          format de son choix.
        </Content>

        <Content>
          Au-delà de ce délai, TWININ est libre de supprimer tout accès aux Comptes et/ou aux données qui y sont hébergées, ce que le Recruteur
          reconnaît et accepte.
        </Content>

        <BoldTitle>Article 11 : Garantie de niveau de Services</BoldTitle>

        <Content>
          TWININ fera ses meilleurs efforts pour que la Plateforme fonctionne sans interruption 24 heures sur 24 heures et 7 jours sur 7 jours, sauf
          en cas de force majeure.
        </Content>

        <Content>
          En cas de nécessité, TWININ se réserve la possibilité de limiter ou de suspendre l'accès à la Plateforme, pour procéder à toute opération de
          maintenance et/ou d'amélioration.
        </Content>

        <Content>
          Dans cette hypothèse, TWININ s'engage à faire ses meilleurs efforts pour informer à l'avance par message informatif général sur la page
          d'accueil de la Plateforme de ces opérations de maintenance et/ou d'amélioration.
        </Content>

        <Content>
          Dans le cadre de ces opérations de maintenance et/ou d'évolution, TWININ s'engage à faire ses meilleurs efforts pour effectuer les
          sauvegardes des contenus qui y sont hébergés, afin de permettre leur restauration, en l'état, à la date des opérations de maintenance et/ou
          d'évolution.
        </Content>

        <Content>
          La responsabilité de TWININ ne saurait en aucun cas être engagée en cas de perte de contenus au cours des opérations de maintenance et/ou
          d'évolution.
        </Content>

        <Content>
          TWININ s'engage également à prendre toutes précautions utiles, au regard de la nature des données et des risques présentés par les
          traitements automatisés de données mis en œuvre pour les besoins des Services, pour préserver la sécurité des données, et notamment empêcher
          qu'elles soient déformées, endommagées ou que des tiers non autorisés y aient accès.
        </Content>

        <BoldTitle>Article 12 : Données de TWININ</BoldTitle>

        <Content>
          Le Recruteur reconnaît et accepte expressément :
          <ul>
            <li>
              que les données recueillies sur la Plateforme et les équipements informatiques de TWININ font foi de la réalité des opérations
              intervenues dans le cadre des présentes ;
            </li>
            <li>que ces données constituent un mode de preuve admis entre les parties.</li>
          </ul>
        </Content>

        <BoldTitle>Article 13 : Obligations du Recruteur</BoldTitle>
        <Content>Sans préjudice des autres obligations prévues aux présentes, le Recruteur s'engage à respecter les obligations qui suivent.</Content>

        <Stack spacing={1} pl={"20px"}>
          <Content>
            (i) Le Recruteur s'engage, dans son usage des Services, à respecter les lois et règlements en vigueur et à ne pas porter atteinte aux
            droits de tiers ou à l'ordre public.
          </Content>
          <Content>
            (ii) Le Recruteur reconnaît avoir pris connaissance des caractéristiques et contraintes, notamment techniques, de l'ensemble des Services.
          </Content>
          <Content>
            Il est seul responsable de son utilisation des Services et/ou de celles de ses Utilisateurs et notamment des relations qu'il(s) pourra/ont
            nouer avec les candidats et des informations qu'il(s) leur communique(nt) dans le cadre des Services.
          </Content>
          <Content>Il(s) lui appartien(nen)t d'exercer la prudence et le discernement appropriés dans ces relations et communications.</Content>
          <Content>
            Le Recruteur s'engage en outre, dans ses échanges avec les candidats, à respecter les règles usuelles de politesse et de courtoisie. Il se
            porte fort du respect, par les Utilisateurs, de ces obligations et plus généralement, de toutes celles qui leur incombent en exécution des
            présentes.
          </Content>
          <Content>
            (iii) Le Recruteur est seul responsable des contenus de toute nature (rédactionnels, graphiques, audios, audiovisuels ou autres, en ce
            compris la dénomination et/ou l'image éventuellement choisies par lui ou un Utilisateur pour l'identifier sur la Plateforme) qu'il diffuse
            sur ses Comptes (Administrateur et Utilisateurs) dans le cadre des Services (ci-après désignés les {`<< Contenus >>`}).
          </Content>
          <Content>Il garantit à TWININ qu'il dispose de tous les droits et autorisations nécessaires à la diffusion de ces Contenus.</Content>
          <Content>
            Il s'engage à ce que ces Contenus soient licites, ne portent pas atteinte à l'ordre public, aux bonnes mœurs ou aux droits de tiers,
            n'enfreignent aucune disposition législative ou règlementaire et plus généralement, ne soient aucunement susceptibles de mettre en jeu la
            responsabilité civile ou pénale de TWININ.
          </Content>
          <Content>
            Le Recruteur s'interdit ainsi de diffuser, notamment et sans que cette liste soit exhaustive :
            <ul>
              <li>
                des Contenus pornographiques, obscènes, indécents, choquants ou inadaptés à un public familial, diffamatoires, injurieux, violents,
                racistes, xénophobes ou révisionnistes,
              </li>
              <li>des Contenus contrefaisants,</li>
              <li>des Contenus attentatoires à l'image d'un tiers,</li>
              <li>des Contenus mensongers, trompeurs ou proposant ou promouvant des activités illicites, frauduleuses ou trompeuses,</li>
              <li>des Contenus nuisibles aux systèmes informatiques de tiers (tels que virus, vers, chevaux de Troie, etc.),</li>
              <li>
                et plus généralement des Contenus susceptibles de porter atteinte aux droits de tiers ou d'être préjudiciables à des tiers, de quelque
                manière et sous quelque forme que ce soit.
              </li>
            </ul>
          </Content>
          <Content>
            (iv) Le Recruteur reconnaît que les Services lui offrent une solution supplémentaire, non alternative, d'automatiser et ainsi d'améliorer
            ses campagnes et process de recrutement et que cette solution ne saurait se substituer aux autres moyens dont il peut disposer par
            ailleurs pour atteindre le même objectif.
          </Content>
          <Content>
            (v) Le Recruteur reconnaît et accepte qu'il doit prendre les mesures nécessaires pour sauvegarder par ses propres moyens les informations
            des Espaces Personnels de ses Comptes qu'il juge nécessaires, dont aucune copie ne lui sera fournie.
          </Content>
          <Content>
            (vi) Le Recruteur s'engage à fournir à TWININ tous les documents, éléments, données et informations nécessaires à la bonne exécution des
            Services. Il s'engage en outre à ce que les informations communiquées soient exactes, complètes et actualisées. Le Recruteur demeure seul
            responsable des conséquences pouvant résulter de la fourniture d'informations mensongères ou erronées à TWININ.
          </Content>
          <Content>
            (vii) Plus généralement, le Recruteur s'engage à coopérer activement avec TWININ en vue de la bonne exécution des présentes et à
            l'informer de toutes difficultés liées à cette exécution.
          </Content>
          <Content>
            (viii) Le Recruteur est informé et accepte que la mise en œuvre des Services nécessite qu'il soit connecté à internet et que la qualité
            des Services dépend directement de cette connexion, dont il est seul responsable.
          </Content>
        </Stack>
        <Content>
          Il est strictement interdit à tout Recruteur et/ou tout Utilisateur de copier et/ou de détourner, à ses fins ou à celles de tiers, le
          concept, les technologies ou tout autre élément de la Plateforme.
        </Content>
        <Content>
          Sont également strictement interdits :
          <ul>
            <li>tous comportements de nature à interrompre, suspendre, ralentir ou empêcher la continuité des Services,</li>
            <li>toutes intrusions ou tentatives d'intrusions dans les systèmes de TWININ,</li>
            <li>tous détournements des ressources système de la Plateforme,</li>
            <li>toutes actions de nature à imposer une charge disproportionnée sur les infrastructures de cette dernière,</li>
            <li>
              tous actes de nature à porter atteinte aux droits et intérêts financiers, commerciaux ou moraux de TWININ et enfin, plus généralement,
            </li>
            <li>tout manquement aux présentes Conditions Générales.</li>
          </ul>
        </Content>
        <Content>Le Recruteur garantit TWININ contre tout comportement, action ou omission, d'un Utilisateur contraire aux présentes.</Content>
        <BoldTitle>Article 14 : Plainte et réclamation</BoldTitle>
        <Content>
          Le Recruteur dispose de la faculté de transmettre une plainte ou une réclamation à TWININ aux coordonnées mentionnées à l'article{" "}
          {`<< Exploitant de la Plateforme et des Services >>`}.
        </Content>
        <Content>TWININ s'engage à répondre à toute réclamation dans les meilleurs délais.</Content>
        <BoldTitle>Article 15: Sanction des manquements </BoldTitle>
        <Content>
          En cas de manquement à l'une quelconque des dispositions des présentes Conditions Générales, ou plus généralement, d'infraction aux lois et
          règlements par le Recruteur ou l'un de ses Utilisateurs, TWININ se réserve le droit de :
          <ul>
            <li>
              suspendre de manière temporaire ou définitive, sans délai, l'accès aux Services du Recruteur et/ou de l'Utilisateur auteur du manquement
              ou de l'infraction, ou y ayant participé ;
            </li>
            <li>supprimer tout contenu en lien avec le manquement ou l'infraction considéré(e), en totalité ou en partie ;</li>
            <li>prendre toutes mesures appropriées et engager toute action en justice ;</li>
            <li>
              avertir le cas échéant les autorités compétentes, coopérer avec elles et leur fournir toutes les informations utiles à la recherche et à
              la répression d'activités illégales ou illicites.
            </li>
          </ul>
        </Content>
        <BoldTitle>Article 16 : Limitation de responsabilité</BoldTitle>

        <Content>
          TWININ s'engage à faire ses meilleurs efforts pour fournir les Services avec diligence et selon les règles de l'art, étant précisé qu'il
          pèse sur elle une obligation de moyen, à l'exclusion de toute obligation de résultat, ce que le Recruteur reconnaît et accepte expressément.
        </Content>

        <Content>
          Sa responsabilité est exclusivement limitée à la fourniture des Services selon les modalités décrites aux présentes, à l'exclusion de toute
          autre prestation. Le Recruteur reconnaît et accepte en outre que les Services sont fournis tel quel sans aucune garantie d'aucune sorte,
          expresse ou implicite.
          <ul>
            <li>
              TWININ ne garantit pas que les Services, soumis à une recherche constante pour en améliorer notamment la performance et le progrès,
              seront totalement exempts d'erreurs, de vices ou défauts ;
            </li>
            <li>
              Les Services étant standards et nullement proposés à la seule intention du Recruteur en fonction de ses propres contraintes
              personnelles, répondront spécifiquement à ses besoins et attentes ;
            </li>
            <li>
              La Plateforme fonctionnera de manière ininterrompue, TWININ se réservant la faculté d'interrompre momentanément son accès, notamment
              pour des raisons de maintenance dans les conditions de l'article {`<< Garantie de niveau de service >>`}, et ne pouvant en aucun cas
              être tenue responsable des perturbations ou défaillances du réseau internet ou du réseau de télécommunication, et plus généralement qui
              auraient leur origine dans des circonstances qui lui sont extérieures ou découlant de la force majeure.
            </li>
          </ul>
        </Content>

        <Content>
          Le Recruteur reconnaît et accepte que TWININ intervient en qualité de simple intermédiaire entre lui et les candidats. Elle n'est, en
          conséquence, pas partie aux accords convenus et/ou aux relations nouées entre les candidats et les Recruteurs. Elle ne peut non plus
          garantir la qualité des candidatures reçues.
        </Content>

        <Content>
          Sa responsabilité se limite à la mise à disposition des Recruteurs des moyens techniques leur permettant d'entrer en relation avec des
          candidats aux offres d'emploi publiées au moyen de la Plateforme. Sa responsabilité se limite à la fourniture de ces moyens et ce, dans les
          conditions prévues aux présentes.
        </Content>

        <Content>
          TWININ ne saurait dès lors être responsable de tout différend ou litige qui surviendrait entre un candidat et le Recruteur. Le Recruteur
          accepte d'utiliser la Plateforme à ses propres risques et assume l'entière responsabilité de ses actions sur la Plateforme.
        </Content>

        <Content>
          Au demeurant, TWININ n'a pas connaissance des Contenus mis en ligne par les Recruteurs ou Utilisateurs dans le cadre de leur utilisation des
          Services, sur lesquels elle n'effectue aucune modération, sélection, vérification ou contrôle d'aucune sorte et à l'égard desquels elle
          n'intervient qu'en tant que prestataire d'hébergement.
        </Content>

        <Content>En conséquence, TWININ ne peut être tenue pour responsable des Contenus publiés au moyen de la Plateforme.</Content>

        <Content>
          TWININ et le Recruteur sont des parties indépendantes, chacune agissant en son nom et pour son propre compte. Les Conditions Générales ne
          créent aucun lien de subordination de quelque nature que ce soit ou de relation employeur/employé entre TWININ et les Recruteurs.
        </Content>
        <BoldTitle>Article 17 : Propriété Intellectuelle</BoldTitle>

        <Content>
          <BoldTitle>17.1 Propriété Intellectuelle de TWININ</BoldTitle>
          Les Conditions Générales ne confèrent au Recruteur aucun droit de propriété intellectuelle d'aucune sorte sur la Plateforme, ainsi que sur
          l'ensemble des textes, images, contenus exploités par TWININ sur la Plateforme, en ce compris les présentes Conditions Générales, les
          marques, noms commerciaux et logos, les logiciels, structures, infrastructures et bases de données utilisés par TWININ au sein de la
          Plateforme, qui demeurent la propriété exclusive, pleine et entière de TWININ.
        </Content>

        <Content>
          Tout acte de désassemblage, décompilation, décryptage, extraction, réutilisation, et plus généralement toute reproduction, représentation,
          distribution, adaptation, commercialisation du Site et/ou des textes, images, contenus exploités par TWININ sur la Plateforme, non conforme
          aux dispositions des présentes Conditions Générales, sont interdits et pourront faire l'objet de poursuites judiciaires.
        </Content>

        <Content>
          <BoldTitle>17.2 Propriété Intellectuelle du Recruteur</BoldTitle>
          Dans l'hypothèse où le Recruteur pourrait revendiquer un droit d'auteur sur les contenus qu'il transmettrait à TWININ via la Plateforme, il
          accorde, par les présentes, à TWININ, une licence gratuite, pour le monde entier, et pour toute la durée légale des droits d'auteur,
          d'utiliser, de reproduire, de représenter, de publier, de mettre à disposition, de communiquer, de modifier, d'adapter, d'afficher, sur tout
          support (notamment sur tout support physique ou numérique), par tous moyens, tout ou partie des contenus dont il serait l'auteur, protégés
          par le droit d'auteur.
        </Content>

        <Content>
          <BoldTitle>17.3 Droit à l'image du Recruteur</BoldTitle>
          En communiquant, à travers la Plateforme, la photo de son Administrateur et/ou de ses Utilisateurs (ci-après la {`<< Photo >>`}), le
          Recruteur déclare et garantit que les personnes dont l'image est fournie à TWININ l'autorisent expressément à utiliser, par voie de
          fixation, de représentation, de reproduction, d'adaptation et de diffusion leur image telle que figurant sur la Photo (ci-après l'
          {`<< Image >>`}).
        </Content>

        <Content>
          Cette autorisation est accordée à titre gratuit et pour le monde entier ; elle emporte la possibilité, pour TWININ, d'apporter à la Photo
          toute adjonction, suppression, modification qu'elle jugerait utile, dès lors que ces modifications n'entraînent aucune altération de la
          personnalité et de l'Image.
        </Content>

        <Content>
          Par {`<< Image >>`}, il est entendu les attributs de la personnalité de la personne qui en est l'objet que sont, son image, son nom, son
          prénom.
        </Content>

        <Content>Cette autorisation est accordée pendant toute la durée de l'accès aux Services du Recruteur.</Content>
        <BoldTitle>Article 18 : Assurance</BoldTitle>

        <Content>
          TWININ a souscrit une police d'assurance auprès de la Société MAAF, SA immatriculée au RCS Niort sous le numéro 542 073 580 - Code APE 6512
          Z, dont le siège est situé à Chaban 79180 CHAURAY.
        </Content>

        <Content>Ce contrat est applicable sur tout le territoire de France.</Content>

        <BoldTitle>Article 19 : Force majeure</BoldTitle>

        <Content>
          TWININ ne pourra être tenue responsable vis-à-vis du Recruteur dans l'hypothèse où l'exécution de ses obligations serait retardée,
          restreinte ou rendue impossible du fait de la survenance d'un évènement de force majeure. Sont notamment considérées comme cas de force
          majeure, les hypothèses habituellement reconnues par la jurisprudence et les tribunaux français.
        </Content>

        <BoldTitle>Article 20 : Nullité</BoldTitle>

        <Content>
          Si l'une quelconque des dispositions des présentes Conditions Générales venait à être déclarée nulle ou non applicable en raison d'une loi,
          d'un règlement ou à la suite d'une décision définitive rendue par une juridiction compétente, seule cette disposition serait frappée de
          nullité, les autres dispositions garderont toute leur force et leur portée.
        </Content>

        <BoldTitle>Article 21 : Modifications des Conditions Générales</BoldTitle>

        <Content>
          TWININ se réserve le droit de modifier les présentes Conditions Générales, mais aussi de modifier et/ou d'arrêter de proposer tout ou partie
          des Services.
        </Content>

        <Content>
          Les Recruteurs seront informés de tout changement par courrier électronique, au moins un mois et demi avant leur entrée en vigueur.
        </Content>

        <Content>Les Conditions Générales modifiées s'appliquent lors du renouvellement de l'Abonnement du Recruteur.</Content>

        <Content>
          Le Recruteur qui n'accepterait pas les Conditions Générales modifiées est tenu de dénoncer son Abonnement dans les conditions de l'article{" "}
          {`<< Désabonnement du Recruteur >>`}. A défaut, le Recruteur est réputé avoir accepté les Conditions Générales modifiées.
        </Content>

        <BoldTitle>Article 22 : Données personnelles</BoldTitle>

        <Content>
          TWININ prend la protection des données personnelles très au sérieux. Pour plus d'informations sur la manière dont nous traitons vos données,
          veuillez consulter notre politique de données personnelles.
        </Content>

        <BoldTitle>Article 23 : Notifications</BoldTitle>

        <Content>
          Pour toute demande d'information, TWININ peut être contactée aux coordonnées mentionnées à l'article{" "}
          {`<< Exploitant de la Plateforme et des Services >>`} des présentes.
        </Content>

        <BoldTitle>Article 24 : Langue</BoldTitle>

        <Content>
          Dans l'hypothèse d'une traduction des présentes Conditions Générales dans une ou plusieurs langues, la langue d'interprétation sera la
          langue française en cas de contradiction ou de contestation sur la signification d'un terme ou d'une disposition.
        </Content>

        <BoldTitle>Article 25 : Loi applicable et juridiction</BoldTitle>

        <Content>Ces Conditions Générales sont régies par la loi française.</Content>

        <Content>
          En cas de contestation sur la validité, l'interprétation et/ou l'exécution des présentes Conditions Générales, les Parties conviennent que
          les tribunaux de Paris seront exclusivement compétents pour en juger, sauf règles de procédure impératives contraires.
        </Content>

        <BoldTitle>Article 26 : Mandat de diffusion</BoldTitle>

        <Content>ALLinOne est une plateforme de recrutement qui a développé un savoir-faire et une compétence approfondie dans la conception, la diffusion et l’optimisation de campagnes de recrutement.</Content>

        <Content>A compter de ce jour, le Client confie à ALLinOne la diffusion et la gestion des annonces de recrutement du Client.</Content>

        <Content>
          Le mandat a pour objet de confier à ALLinOne la diffusion, la gestion et l’optimisation des offres d’emploi du client. Cette mission se traduit par :
          <ul>
            <li>
              Le management d'espaces gratuits et payants pour la diffusion des annonces de recrutement (« Annonces Client ») fournies par le Client sur les supports appropriés. Pour ce faire, ALLinOne se verra confier les mots de passe et identifiants des sites emploi ou se verra nommé "administrateur" sur les plateformes emploi pour une complète prise en charge de la diffusion des offres d'emploi.
            </li>
            <li>
              L'achat d'espaces pour la diffusion des Contenus afin d’assurer leur communication au public, et la redirection des internautes vers la plateforme ALLinOne dans les conditions définies ci-après.
            </li>
          </ul>
        </Content>

        <Content>
          Ainsi, le Client mandate ALLinOne pour déposer en son nom et pour son compte ses offres d'emplois, auprès de l'ensemble des médias, supports, les offres médias de type RH, les missions de négociation, de signature de la convention d'achat d'espaces pour le placement de ses Contenus.
        </Content>
      </Stack>
    </Stack>
  );
};

export default CGUFR;
