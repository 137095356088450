import styled from "@emotion/styled";
import { Avatar, Box, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useNavigate } from "react-router-dom";

import { UserAffiliateRoleEnum, UserAffiliateRoleEnumFunctions, UserLightModel, UserModel } from "@store/users";

import { Colors } from "@constants/colors.constant";

const RoleBadge = styled(Box)(
  (props: { role?: UserAffiliateRoleEnum }) => `
    background-color: ${props.role && Colors.users[props.role]};
    border-radius: 13px;
    padding: 5px 12px;
    `
);

const UserListContainer = styled(Stack)(
  (props: { inaccordion?: string }) => `
  border-radius: ${props.inaccordion === "true" ? 0 : "15px"} ${props.inaccordion === "true" ? 0 : "15px"} 15px 15px;
  background-color: ${props.inaccordion ? 'transparent' : Colors.users.listBackground};
`
);

const UserRow = styled(Stack)`
  padding: 16px 22px;

  &:not(:last-child) {
    border-bottom: 1px solid ${Colors.users.border};
  }
`;

interface UsersListComponentProps {
  affiliateId?: string;
  inAccordion?: boolean;
  users: UserModel[] | UserLightModel[];
}

const UsersListComponent = (props: UsersListComponentProps) => {
  const { users, affiliateId, inAccordion} = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  return (
    <UserListContainer inaccordion={inAccordion ? "true" : undefined}>
      {users.map((user) => {
        const userRole = "affiliates" in user
          ? user.affiliates?.find((a) => a.affiliate.id === affiliateId)?.role
          : undefined;

        return (
          <UserRow key={user.id} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
            <Stack direction={"row"} alignItems={"center"} spacing={"12px"} minWidth={breakpointDownSM ? "45%" : "30%"}>
              <Box p="1px" bgcolor={Colors.greyLight} borderRadius="50%">
                <Avatar sx={{ border: `1.5px solid ${Colors.white}`, height: 35, width: 35 }} src={user.picture} />
              </Box>
              <Typography fontSize={14} fontWeight={500} width={breakpointDownMD ? "100px" : "auto"}>
                {`${user.firstname} ${user.lastname}`}
              </Typography>
            </Stack>

            {!breakpointDownSM && (
              <Stack minWidth={"30%"}>
                <Typography fontSize={14} fontWeight={300}>
                  {user.email}
                </Typography>
              </Stack>
            )}

            <RoleBadge role={userRole}>
              <Typography fontSize={14} fontWeight={500}>
                {!!userRole && UserAffiliateRoleEnumFunctions.label(userRole)}
              </Typography>
            </RoleBadge>
            <IconButton sx={{ ml: "auto" }} onClick={() => navigate(`${user.id}`)}>
              <EastIcon color="primary" />
            </IconButton>
          </UserRow>
        );
      })}
    </UserListContainer>
  );
};
export default UsersListComponent;
