import React from 'react';
import {Checkbox, FormControlLabel} from '@mui/material';

interface Props {
  label: string;
  handleChange?: () => void;
}

const CheckboxComponent = ({label, handleChange}: Props) => {
  return (
    <FormControlLabel
      sx={{width: "fit-content"}}
      label={label}
      onChange={handleChange}
      control={<Checkbox/>}/>
  );
};

export default CheckboxComponent;
