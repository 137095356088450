import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withPagination } from "@ngneat/elf-pagination";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { BoostFilters, BoostModel } from "./boosts.model";

interface BoostStoreProps {
  filters: BoostFilters;
}

export const boostsStore = createStore(
  { name: "boosts" },
  withProps<BoostStoreProps>({
    filters: { year: new Date().getFullYear() },
  }),
  withEntities<BoostModel>(),
  withRequestsStatus(),
  withPagination()
);
