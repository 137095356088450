import { PaginatedMetaData } from "@utils/infinitescroll.utils";
import {
  EntityDetailsModel,
  ManageEntityDetailsModel,
  ManageEntityParametersModel
} from "@store/entities/details/entityDetails.model";
import { sessionQuery } from "@store/session";
import { UserLightModel, UserModel, UserRoleEnum } from "@store/users";
import { Action } from "@store/common/actions.model";
import { ToolboxColumn } from "@store/toolbox";
import { AdModel } from "@store/ads";
import { Comment } from "@store/common/comments.model";

export interface ManageAffiliateDetailsModel extends ManageEntityDetailsModel {}

export const manageAffiliateDetailsToFormData = (affiliate: ManageAffiliateDetailsModel, clientId?: number | string) => {
  const formData = new FormData();

  if (clientId) formData.append("clientId", clientId.toString());

  if (affiliate.name) formData.append("name", affiliate.name);
  if (affiliate.address) formData.append("address", affiliate.address);
  if (affiliate.city) formData.append("city", affiliate.city);
  formData.append("description", affiliate.description ?? "");
  formData.append("otherInformation", affiliate.otherInformation ?? "");
  formData.append("contacts", JSON.stringify(affiliate.contacts ?? []));

  if (sessionQuery.role === UserRoleEnum.ADMIN) {
    formData.append("twinnerIds", JSON.stringify(affiliate.twinners?.filter((t) => !!t)?.map((t) => t!.value) ?? []));
  }

  if (affiliate.pictureFile) formData.append("picture", affiliate.pictureFile);
  if (affiliate.timelineFile) formData.append("timelineImg", affiliate.timelineFile);

  return formData;
};

export const newToolboxToolToFormData = (newToolboxToolDocuments: NewToolboxToolDocument[]) => {
  const formData = new FormData();
  newToolboxToolDocuments.forEach((newToolboxToolDocument) => {
    if (newToolboxToolDocument.file) formData.append("files[]", newToolboxToolDocument.file);
  });
  formData.append("columnId", newToolboxToolDocuments[0].columnId);
  formData.append("toolTitle", newToolboxToolDocuments[0].toolTitle);
  if (newToolboxToolDocuments[0].toolCategory) formData.append("toolCategory", newToolboxToolDocuments[0].toolCategory);
  return formData;
};

export interface ManageAffiliateParametersModel extends ManageEntityParametersModel {}

export interface AffiliateSubscription {
  startCredits?: number;
  currentCredits?: number;
  unlimited: boolean;
  startDate: Date;
  endDate: Date;
  ads: AdModel[];
}

export interface AffiliateDetailsDetailsModel extends EntityDetailsModel {
  toolboxColumns: ToolboxColumn[];
}

export enum AffiliateToolboxToolStatusEnum {
  REQUESTED = "REQUESTED",
  UPLOADED = "UPLOADED",
}

export interface AddSubscription {
  credits: number;
  unlimited: boolean;
  startDate: Date;
  endDate: Date;
  ads: AdModel[];
}

export interface AffiliateToolboxTool {
  id: string;
  column: ToolboxColumn;
  category?: string | null;
  title: string;
  documentKey?: string | null;
  downloadURL?: string | null;
  fileFormat?: string | null;
  downloads: number;
  requestedBy: UserLightModel;
  status: AffiliateToolboxToolStatusEnum;
}

export interface NewToolboxToolDocument {
  file?: File | undefined;
  columnId: string;
  toolTitle: string;
  toolCategory?: string;
}

export interface AffiliateDetailsModel {
  details?: AffiliateDetailsDetailsModel;
  comments?: Comment[];
  users?: UserModel[];
  actions?: Action[];
  actionsPaginatedMeta?: PaginatedMetaData;
  affiliateToolboxTools?: AffiliateToolboxTool[];
}
