import React from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

import { PotentialProfilesRoutes } from "@utils/routes.utils";

import AIORoutesTabComponent from "@components/AIORoutesTab.component";

const AdsPotentialProfilesTab = () => {
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : "30px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 1 : 2}
      width="100%"
      overflow="hidden"
      height="100%">
      <AIORoutesTabComponent
        routes={PotentialProfilesRoutes.routes}
        disableCenter />
      <Outlet/>
    </Stack>
  );
};

export default AdsPotentialProfilesTab;
