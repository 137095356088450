import { alpha, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Colors } from "@constants/colors.constant";
import { useNavigate, useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import React, { useEffect } from "react";
import { boostDetailsQuery, boostDetailsService } from "@store/boosts/details";
import { BoostStatus, BoostType } from "@store/boosts";
import dayjsUtils from "@utils/dayjs.utils";
import BoostsDetailsTab from "@screens/auth/common/boosts/details/BoostsDetails.tab";

const BoostsDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { boostId } = useParams();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));


  const [{ boostDetails: boost }] = useObservable(boostDetailsQuery.details$);

  useEffect(() => {
    boostDetailsService.resetStore();

    if (boostId) {
      boostDetailsService.getBoostDetails(boostId).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
    }
  }, [boostId, enqueueSnackbar]);

  return (
    <Stack flex={1} justifyContent="flex-start" overflow="hidden">
      <Stack
        justifyContent="flex-start"
        px={breakpointDownSM ? "10px" : "20px"}
        pt="20px"
        pb={breakpointDownSM ? "20px" : "30px"}
        bgcolor="white"
        sx={{
          backgroundImage: 'linear-gradient(to left, rgba(232, 97, 77, 0.05) 0%, rgba(232, 97, 77, 0) 100%)',
        }}
        boxShadow={`0 0px 50px 0 ${alpha(Colors.black, 0.1)}`}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button
            variant="text"
            color="inherit"
            startIcon={<img alt="back" src="/images/arrow_back.svg" />}
            onClick={() => navigate("..")}>
            <Typography fontWeight="300" color="secondary">{t("menu.boost")}</Typography>
          </Button>
          <Stack direction="row" alignItems="center" mb="10px" spacing="10px">
            {boost && (
              <Stack
                height="22px" width="fit-content"
                bgcolor={BoostStatus.backgroundColor(boost.isNew && boost.state === BoostStatus.IN_PROGRESS ? BoostStatus.NEW : boost.state)}
                borderRadius="4px"
                alignItems="center" justifyContent="center">
                <Typography
                  mx={"10px"}
                  color={BoostStatus.labelColor(boost.isNew && boost.state === BoostStatus.IN_PROGRESS ? BoostStatus.NEW : boost.state)}
                  fontWeight={500}
                  fontSize={11}>
                  {BoostStatus.label(boost.isNew && boost.state === BoostStatus.IN_PROGRESS ? BoostStatus.NEW : boost.state)}
                </Typography>
              </Stack>
            )}
            {!!boost?.announcement.managedByTwinin && (
              <Stack
                height="22px"
                width="22px"
                bgcolor={alpha(Colors.gold, 0.2)}
                borderRadius="4px"
                alignItems="center"
                justifyContent="center">
                <img alt="twininT" src="/images/icon_star_3d.svg" height="12px" />
              </Stack>
            )}
          </Stack>
        </Stack>
        <Stack height="70px" justifyContent="center" mb="20px">
          <Stack direction="row" alignItems="center" spacing={1.5} alignSelf="center">
            <Stack
              height="29px"
              width="29px"
              bgcolor={Colors.lightpurple}
              borderRadius="15px"
              alignItems="center"
              justifyContent="center">
              <img alt="boost" src="/images/icon_boost_rocket.svg" style={{ height: "14px", width: "14px" }} />
            </Stack>
            <Typography textAlign="center" fontSize="25px" fontWeight={700}>
              {BoostType.selectTypesItems.find((t) => t.value === boost?.type)?.label ?? "-"}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            {boost?.state === BoostStatus.VALIDATED && (
            <Stack alignItems="center" justifyContent="center">
              <Typography color={Colors.boostCard.secondaryText} fontWeight={300} fontSize={12}>
                {dayjsUtils().to(boost.endDate)}
              </Typography>
            </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
      <BoostsDetailsTab />
    </Stack>
  );
};

export default BoostsDetails;
