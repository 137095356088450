import { catchError, forkJoin, from, map, Observable, of, switchMap, tap } from "rxjs";
import SnackError from "@utils/error.utils";
import { AxiosError, AxiosResponse } from "axios";
import APIAxios, { APIRoutes } from "@api/axios.api";
import {
  AddStripeProductModel,
  StripeProductModel,
  StripeProductsFilters,
  StripeProductTypeEnum
} from "./stripeProducts.model";
import { stripeProductsStore } from "./stripeProducts.store";
import { setEntities } from "@ngneat/elf-entities";
import { getStripeProductsDataSource } from "./stripeProducts.requests";
import { BoostAction, JobBoard, jobBoardsService, School, Sequence } from "@store/jobBoards";

export class StripeProductsService {
  store = stripeProductsStore;

  resetStore = () => this.store.reset();

  setFilters = (filters: Partial<StripeProductsFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getStripeProducts = (filters?: StripeProductsFilters): Observable<StripeProductModel[]> => {
    return from(APIAxios({
      ...APIRoutes.GETStripeProducts(),
      params: {
        search: filters?.search || undefined,
      },
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<StripeProductModel[]>) => {
        return response.data;
      }),
      tap((products) => {
        this.store.update(setEntities(products), getStripeProductsDataSource.setSuccess());
      }),
      getStripeProductsDataSource.trackRequestStatus()
    );
  };

  createStripeProduct = (data: AddStripeProductModel): Observable<(Sequence | JobBoard | BoostAction | School)[]> => {
    return from(APIAxios({
      ...APIRoutes.POSTStripeProduct(),
      data: {
        stripeProductId: data.stripeProductId,
        productType: data.productType.value,
        type: [StripeProductTypeEnum.JOB_BOARD, StripeProductTypeEnum.SCHOOL].includes(data.productType.value)
          ? data.type
          : undefined,
        link: data.productType.value === StripeProductTypeEnum.JOB_BOARD
          ? data.link
          : undefined,
        sellsyProviderId: data.productType.value === StripeProductTypeEnum.JOB_BOARD
          ? data.sellsyProviderId
          : undefined,
        providerPrice: data.productType.value === StripeProductTypeEnum.JOB_BOARD && !!data.sellsyProviderId
          ? data.providerPrice
          : -1,
        jobboards: data.productType.value === StripeProductTypeEnum.SEQUENCE
          ? data.jobboards?.map((x) => x.value) ?? []
          : undefined,
      },
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => {
        switch (data.productType.value) {
          case StripeProductTypeEnum.SEQUENCE:
            return jobBoardsService.getSequences();
          case StripeProductTypeEnum.JOB_BOARD:
            return jobBoardsService.getJobBoards();
          case StripeProductTypeEnum.BOOST_ACTION:
            return jobBoardsService.getBoostActions();
          case StripeProductTypeEnum.SCHOOL:
            return jobBoardsService.getSchools();
          default:
            return of([]);
        }
      }),
    );
  };

  updateStripeProducts = (): Observable<any> => {
    return from(APIAxios(APIRoutes.PUTStripeProducts())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => forkJoin([
        jobBoardsService.getSequences(),
        jobBoardsService.getJobBoards(),
        jobBoardsService.getBoostActions(),
        jobBoardsService.getSchools(),
      ])),
    );
  };
}

export const stripeProductsService = new StripeProductsService();
