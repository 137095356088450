import React, { useEffect, useState } from "react";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { boostsService, BoostType } from "@store/boosts";
import { adDetailsQuery, adDetailsService } from "@store/ads/details";
import { jobBoardsService } from "@store/jobBoards";

import { Colors } from "@constants/colors.constant";

import ModalComponent from "@components/modal/Modal.component";
import CardBackground from "@components/cardBackground/CardBackground.component";
import AIOButtonComponent from "@components/Button.component";
import ManualBoostRequestModal from "./ManualBoostRequest.modal";

interface SelectBoostRequestTypeModalProps {
  handleClose: () => void;
  adIdFromProps?: string;
}

const SelectBoostRequestTypeModal = (props: SelectBoostRequestTypeModalProps) => {
  const { handleClose, adIdFromProps } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { adId: adIdFromParams } = useParams();

  const adId = adIdFromProps || adIdFromParams;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);

  const [{ adDetails: ad }] = useObservable(adDetailsQuery.details$);

  const [requestType, setRequestType] = useState<BoostType | undefined>();
  const [requestSent, setRequestSent] = useState(false);

  useEffect(() => {
    return () => jobBoardsService.resetSelected();
  }, []);

  useEffect(() => {
    if (adIdFromProps) {
      adDetailsService.getAdDetails(adIdFromProps).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [adIdFromProps, enqueueSnackbar]);

  if (!ad) return null;

  if (requestSent) {
    return (
      <ModalComponent
        handleClose={handleClose}
        icon={<img alt="check" src="/images/illus_check.svg" />}
        title={t(`boostRequest.selection.${requestType === BoostType.TWININ ? "twinin" : "myself"}.requestSent.title`)}
        content={
          <Stack px="50px" py="20px">
            <Typography
              textAlign="center"
              style={{ wordBreak: "break-word", paddingBottom: "25px" }}
              lineHeight="1.25"
              color={Colors.secondaryText}
              fontWeight={300}>
              {t(`boostRequest.selection.${requestType === BoostType.TWININ ? "twinin" : "myself"}.requestSent.description`)}
            </Typography>
          </Stack>
        }
        maxWidth="xs"
        paddingButton="10px 0px 40px 0px"
      />
    );
  }

  if (requestType === BoostType.MANUAL) {
    return <ManualBoostRequestModal adIdFromProps={adId} handleClose={handleClose} handleSuccess={() => setRequestSent(true)} />;
  }

  return (
    <ModalComponent
      maxWidth="sm"
      titleLeft
      handleClose={handleClose}
      title={t("boostRequest.selection.title")}
      content={
        <Stack spacing={4} mt="-5px" py="20px">
          <Typography fontSize={14} fontWeight="500" sx={{ opacity: 0.47 }}>
            {t("boostRequest.selection.description")}
          </Typography>
          <Stack spacing={3}>
            <CardBackground img="/images/boost-soimeme.svg" minHeight="160px" height={breakpointDownSM ? undefined : "160px"}>
              <Stack
                direction={breakpointDownSM ? "column" : "row"}
                px="32px"
                py="20px"
                alignItems="center"
                height="100%"
                spacing={2}
                justifyContent="space-between">
                <Stack>
                  <Typography color="white" fontSize={20} fontWeight="700">
                    {t("boostRequest.selection.myself.title")}
                  </Typography>
                  <Typography color="white" fontSize={14} fontWeight="300">
                    {t("boostRequest.selection.myself.description")}
                  </Typography>
                </Stack>
                <Box minWidth="150px" whiteSpace="nowrap">
                  <AIOButtonComponent
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    onClick={() => setRequestType(BoostType.MANUAL)}
                    title={t("boostRequest.selection.myself.title")}
                  />
                </Box>
              </Stack>
            </CardBackground>
            {ad.managedByTwinin && (
              <CardBackground img="/images/boost_twinin.svg" minHeight="160px" height={breakpointDownSM ? undefined : "160px"}>
                <Stack
                  direction={breakpointDownSM ? "column" : "row"}
                  px="32px"
                  py="20px"
                  alignItems="center"
                  height="100%"
                  spacing={2}
                  justifyContent="space-between">
                  <Stack>
                    <Typography color="white" fontSize={20} fontWeight="700">
                      {t("boostRequest.selection.twinin.title")}
                    </Typography>
                    <Typography color="white" fontSize={14} fontWeight="300">
                      {t("boostRequest.selection.twinin.description")}
                    </Typography>
                  </Stack>
                  <Box minWidth="150px" whiteSpace="nowrap">
                    <AIOButtonComponent
                      variant="contained"
                      color="secondary"
                      disabled={loading}
                      onClick={() => {
                        if (!adId) return;
                        setLoading(true);
                        setRequestType(BoostType.TWININ);
                        boostsService
                          .createTwininBoostRequest(adId)
                          .pipe(finalize(() => setLoading(false)))
                          .subscribe({
                            next: () => setRequestSent(true),
                            error: (err) => enqueueSnackbar(err.text, err.options),
                          });
                      }}
                      title={t("boostRequest.selection.twinin.title")}
                    />
                  </Box>
                </Stack>
              </CardBackground>
            )}
          </Stack>
        </Stack>
      }
    />
  );
};

export default SelectBoostRequestTypeModal;
