import ModalComponent from "@components/modal/Modal.component";
import { Grid } from "@mui/material";
import ToolboxToolCell from "@screens/auth/client/toolbox/components/ToolboxToolCell.component";
import { AffiliateToolboxTool } from "@store/entities/afiliates/details";
import { EntitiesModel } from "@store/entities/entities.model";

interface MultipleToolboxCellsModalProps {
  entity: EntitiesModel;
  columnId: string;
  handleClose: () => void;
  tools: AffiliateToolboxTool[];
}

const MultipleToolboxCellsModal = (props: MultipleToolboxCellsModalProps) => {
  const { handleClose, tools, entity, columnId } = props;

  return (
    <ModalComponent
      handleClose={handleClose}
      content={(
        <Grid container spacing={4} py="10px">
          {tools.map((tool) => (
            <Grid item xs={12} md={6} key={tool.id}>
              <ToolboxToolCell
                tool={{ category: tool.category ?? undefined, title: tool.title }}
                affiliateToolboxTool={tool}
                entity={entity}
                columnId={columnId}
              />
            </Grid>
          ))}
        </Grid>
      )}
      fullWidth={false}
      maxWidth="sm" />
  );
};

export default MultipleToolboxCellsModal;
