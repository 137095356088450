import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";

import { BannersFilters } from "@store/banners/banners.model";
import { bannersService } from "@store/banners/banners.service";

interface SearchBannersParams {
  filters?: BannersFilters;
}

export const searchBannersEffect = createEffectFn((params$: Observable<SearchBannersParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ filters }) => bannersService.getBanners(filters))
  );
});
