import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";
import { CircularProgress, Stack, Typography } from "@mui/material";

import { AdDetailsDetailsModel } from "@store/ads/details";
import { linkedinService } from "@store/linkedin";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { subscriptionsQuery } from "@store/subscriptions";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";

interface LinkedinPostModalProps {
  ad: AdDetailsDetailsModel;
  handleClose: () => void;
}

const LinkedinPostModal = (props: LinkedinPostModalProps) => {
  const { ad, handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingPublish, setLoadingPublish] = useState(false);
  const [loadingGeneration, setLoadingGeneration] = useState(true);
  const [postContent, setPostContent] = useState("");

  useEffect(() => {
    if (postContent.length > 0) return;
    setLoadingGeneration(true);
    const _generate$ = linkedinService
      .generateLinkedinPost(ad.id, sessionQuery.user?.role !== UserRoleEnum.ADMIN && !subscriptionsQuery.sessionUserSubscriptionOngoing)
      .pipe(finalize(() => setLoadingGeneration(false)))
      .subscribe({
        next: (res) => {
          setPostContent(res);
        },
      });

    return () => _generate$.unsubscribe();
  }, [ad, postContent.length]);

  const handlePublish = () => {
    setLoadingPublish(true);
    linkedinService
      .publishLinkedinPost({ content: postContent })
      .pipe(finalize(() => setLoadingPublish(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("ads.details.detailTab.linkedin.postPublished"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const errors = {
    postContent: getFieldError(postContent, FieldValidationType.REQUIRED_STRING),
  };

  return (
    <ModalComponent
      maxWidth="xs"
      titleLeft
      title={t("ads.details.detailTab.linkedin.postOnLinkedin")}
      handleClose={handleClose}
      content={
        <Stack spacing={3} py="25px" alignItems="stretch" width="100%">
          {loadingGeneration ? (
            <Stack spacing={2} justifyContent="center" alignItems="center" height="200px">
              <CircularProgress size={26} />
              <Typography>{t("ads.details.detailTab.linkedin.generatingPost")}</Typography>
            </Stack>
          ) : (
            <AIOTextfieldComponent
              required
              title={t("ads.details.detailTab.linkedin.postContent")}
              placeholder={t("ads.details.detailTab.linkedin.postContent")}
              onChange={(value) => setPostContent(value)}
              value={postContent}
              fullWidth
              multiline
              minRows={5}
              error={errors.postContent}
            />
          )}
        </Stack>
      }
      actions={
        <>
          <AIOButtonComponent title={t("global.cancel")} ariaLabel="modal" onClick={handleClose} />
          <AIOButtonComponent
            title={t("ads.details.detailTab.linkedin.publish")}
            ariaLabel="modal"
            disabled={loadingPublish || loadingGeneration || checkIfErrors(errors)}
            onClick={handlePublish}
            variant="contained"
            color="secondary"
          />
        </>
      }
    />
  );
};

export default LinkedinPostModal;
