import { createStore, withProps } from "@ngneat/elf";
import { Session } from "@store/session/session.model";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { Buffer } from "buffer";
import { AFFILIATES_FILTER_STORAGE_KEY, TOKEN_STORAGE_KEY } from "@constants/localStorage.constant";

export const sessionStore = createStore(
  {name: "session"},
  withProps<Session>({
    buyerMode: false,
    accessToken: Buffer.from(localStorage.getItem(TOKEN_STORAGE_KEY) || "", "base64").toString(),
    affiliateIds: JSON.parse(localStorage.getItem(AFFILIATES_FILTER_STORAGE_KEY) ?? "[]"),
  }),
  withRequestsStatus(),
);


