import { select } from "@ngneat/elf";
import { selectAllEntitiesApply, selectEntitiesCount } from "@ngneat/elf-entities";

import { usersStore } from "@store/users/users.store";
import { getUsersDataSource } from "@store/users/users.requests";
import { subscriptionsQuery } from "@store/subscriptions";

export class UsersQuery {
  store = usersStore;

  users$ = getUsersDataSource.data$();

  usersCount$ = this.store.pipe(selectEntitiesCount());

  filters$ = this.store.pipe(select((state) => state.filters));

  usersWithoutSubscription$ = this.store.pipe(
    selectAllEntitiesApply({
      filterEntity: (e) => !subscriptionsQuery.subscriptionOngoing(e),
    })
  );

  get filters() {
    return this.store.getValue().filters;
  }
}

export const usersQuery = new UsersQuery();
