import { catchError, from, map, Observable, tap } from "rxjs";
import { deleteAllEntities, deleteEntities, updateEntities, upsertEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";

import { getAffiliatesDataSource } from "@store/entities/afiliates/affiliates.requests";
import { affiliatesStore } from "@store/entities/afiliates/affiliates.store";
import { AffiliateModel } from "@store/entities/afiliates/affiliates.model";
import { entitiesFiltersQuery, EntitiesSortFieldEnum } from "@store/entities/entitiesFilters";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { SelectItem } from "@components/input/Select.component";
import { deleteAllPages, setPage, updatePaginationData } from "@ngneat/elf-pagination";
import { PaginatedData } from "@utils/infinitescroll.utils";
import { EntityFavoritesFilterEnum } from "../entities.model";

export class AffiliatesService {
  store = affiliatesStore;

  resetStore = () => this.store.reset();
  deleteEntities = () => this.store.update(deleteAllEntities());
  deleteAllPages = () => this.store.update(deleteAllPages());

  getAffiliates = (page?: number, take?: number): Observable<PaginatedData<AffiliateModel[]>> => {
    const {
      filters: { city, search, favorites },
      sort: { field },
    } = entitiesFiltersQuery.params;

    return from(
      APIAxios({
        ...APIRoutes.GETAffiliates(),
        params: {
          search: search || undefined,
          favorites: favorites === EntityFavoritesFilterEnum.FAVORITES ? true : undefined,
          city: city || undefined,
          sort: field,
          page,
          take,
          disablePagination: !page && !take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<AffiliateModel[]>>) => {
        return response.data;
      }),
      tap((affiliates) => {
        this.store.update(
          upsertEntities(affiliates.data),
          updatePaginationData({
            currentPage: affiliates.meta.page,
            perPage: affiliates.meta.take,
            total: affiliates.meta.itemCount,
            lastPage: affiliates.meta.pageCount,
          }),
          setPage(
            affiliates.meta.page,
            affiliates.data.map((affiliate) => affiliate.id)
          ),
          getAffiliatesDataSource.setSuccess()
        );
      }),
      getAffiliatesDataSource.trackRequestStatus()
    );
  };

  searchAffiliates = async (search?: string, parentClientId?: string): Promise<SelectItem[]> => {
    try {
      const response: AxiosResponse<PaginatedData<AffiliateModel[]>> = await APIAxios({
        ...APIRoutes.GETAffiliates(),
        params: {
          search: search ?? undefined,
          parentClientId: parentClientId ?? undefined,
          page: 1,
          take: 20,
          sort: EntitiesSortFieldEnum.NAME,
        },
      });
      return response.data.data.map((e) => ({ label: e.name, value: e.id, data: e }));
    } catch (err: any) {
      return [];
    }
  };

  setFavorite = (affiliateId: string, favorite: boolean) => {
    this.store.update(updateEntities(affiliateId, { isFavorite: favorite }));
  };

  deleteAffiliate = (affiliateId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETEAffiliate(affiliateId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        return response.data;
      }),
      tap(() => this.store.update(deleteEntities(affiliateId)))
    );
  };
}

export const affiliatesService = new AffiliatesService();
