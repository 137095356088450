import { select } from "@ngneat/elf";

import { unipileInvitationsStore } from "./unipileInvitations.store";
import { getUnipileInvitationsDataSource } from "./unipileInvitations.requests";

export class UnipileInvitationsQuery {
  store = unipileInvitationsStore;

  unipileInvitations$ = getUnipileInvitationsDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));
}

export const unipileInvitationsQuery = new UnipileInvitationsQuery();
