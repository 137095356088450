import { catchError, from, map, Observable, tap } from "rxjs";
import { setEntities, updateEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";

import AIOAPIAxios, { AIOAPIRoutes } from "@api/aio-axios.api";
import SnackError from "@utils/error.utils";
import { templatesStore } from "./templates.store";
import { Template } from "./templates.model";
import { getTemplatesDataSource } from "./templates.requests";

export class TemplatesService {
  store = templatesStore;

  getTemplates = (): Observable<Template[]> => {
    return from(AIOAPIAxios(AIOAPIRoutes.GETTemplates())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Template[]>) => {
        return response.data;
      }),
      tap((templates) => {
        this.store.update(setEntities(templates), getTemplatesDataSource.setSuccess());
      }),
      getTemplatesDataSource.trackRequestStatus()
    );
  };

  updateTemplate = (templateId: string, title: string): Observable<Template> => {
    return from(AIOAPIAxios({ ...AIOAPIRoutes.PUTTemplate(templateId), data: { title } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Template>) => {
        return response.data;
      }),
      tap((template) => {
        this.store.update(updateEntities(templateId, template));
      })
    );
  };
}

export const templatesService = new TemplatesService();
