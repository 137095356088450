import { SelectItem } from "@components/input/Select.component";
import I18n from "@utils/i18n.utils";

export enum GoogleAnalyticsVariablesEnum {
  ID = "{{ID}}",
  REFERENCE = "{{REFERENCE}}",
}

export namespace GoogleAnalyticsVariablesEnumFunctions {
  export const selectItems: SelectItem[] = [
    { label: I18n.t("clients.details.parametersTab.googleAnalytics.atsId"), value: GoogleAnalyticsVariablesEnum.ID },
    { label: I18n.t("clients.details.parametersTab.googleAnalytics.ref"), value: GoogleAnalyticsVariablesEnum.REFERENCE },
  ];
}

export interface ManageGoogleAnalyticsByCompanyNameModel extends Partial<GoogleAnalyticsByCompanyNameModel> {
  companyName: string;
}

export interface ManageGoogleAnalyticsModel extends Partial<Omit<GoogleAnalyticsModel, "companyNames">> {
  companyNames?: ManageGoogleAnalyticsByCompanyNameModel[];
}

export interface GoogleAnalyticsByCompanyNameModel {
  companyName: string;
  companyNameFilter: string;
  adsFilters: string[];
}

export interface GoogleAnalyticsModel {
  propertyId: string;
  companyNames: GoogleAnalyticsByCompanyNameModel[];
}