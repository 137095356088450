import { createRequestDataSource } from "@ngneat/elf-requests";
import { sessionStore } from "@store/session/session.store";
import { select } from "@ngneat/elf";

export const getUserDataSource = createRequestDataSource({
  store: sessionStore,
  data$: () => sessionStore.pipe(
    select((state) => state.user)
  ),
  requestKey: "getUser",
  dataKey: "user",
});

export const getUnipileAccountStatusDataSource = createRequestDataSource({
  store: sessionStore,
  data$: () => sessionStore.pipe(
    select((state) => state.unipileAccountStatus)
  ),
  requestKey: "getUnipileAccountStatus",
  dataKey: "unipileAccountStatus",
});
