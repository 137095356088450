import { useEffect } from "react";
import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Outlet, useNavigate } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";

import { searchUsersEffect, UserRoleEnum, usersQuery, usersService } from "@store/users";

import AIOButtonComponent from "@components/Button.component";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import UserCard from "@components/card/User.card";
import { filesService } from "@store/files";
import dayjsUtils from "@utils/dayjs.utils";

const AdminUsersTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const searchUsers = useEffectFn(searchUsersEffect);

  const [{ users, error: usersError, loading: usersLoading }] = useObservable(usersQuery.users$);

  const [usersCount] = useObservable(usersQuery.usersCount$);

  const [filters] = useObservable(usersQuery.filters$);

  const HandleExportCsv = () => {
    usersService.getUsersCsv().subscribe({
      next: (res) => {
        filesService.downloadDocument(res, `users_${dayjsUtils().format("DD-MM-YYYY")}.csv`);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  useEffect(() => {
    usersService.resetStore();
    usersService.setFilters({ roles: [UserRoleEnum.ADMIN] });
  }, []);

  useEffect(() => {
    if (usersError) enqueueSnackbar((usersError as any).text, (usersError as any).options);
  }, [usersError, enqueueSnackbar]);

  useEffect(() => {
    searchUsers({ filters });
  }, [filters, searchUsers]);

  return (
    <Stack px={breakpointDownSM ? "10px" : "40px"} flex={1} width="100%" overflow="auto">
      <Stack direction="row" alignItems="center" spacing={2} mb={breakpointDownSM ? "0px" : "28px"}>
        <Typography fontSize="20px" fontWeight="700">
          {usersLoading ? "-" : ""}
          {!usersLoading && t(`adminParameters.admins.amount`, { count: usersCount ?? 0 })}
        </Typography>
        {usersLoading && <CircularProgress size={20} />}
      </Stack>
      <Stack py={breakpointDownSM ? "20px" : undefined} spacing={"20px"}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
          <Stack
            direction={breakpointDownSM ? "column-reverse" : "row"}
            width="100%"
            alignItems="center"
            spacing={breakpointDownSM ? 0 : 3}
            justifyContent={breakpointDownSM ? "flex-start" : "flex-end"}
            mb={breakpointDownSM ? "20px" : 0}>
            <AIOSearchComponent
              width="100%"
              placeholder={t("adminParameters.admins.search")}
              onChange={(value) => usersService.setFilters({ search: value })}
            />
            <Stack direction="row" spacing={2} mb={breakpointDownSM ? "20px" : "0px"}>
              <Box minWidth="200px">
                <AIOButtonComponent
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate("add")}
                  title={t("adminParameters.admins.addAdmin")}
                />
              </Box>
              <Box minWidth="200px">
                <AIOButtonComponent
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={HandleExportCsv}
                  title={t("adminParameters.admins.exportcsv")}
                />
              </Box>
            </Stack>
          </Stack>
        </Stack>
        <Box
          overflow={breakpointDownSM ? undefined : "auto"}
          className={breakpointDownSM ? undefined : "scrollable"}
          py={breakpointDownSM ? "0px" : "30px"}
          px="10px">
          <Stack spacing={breakpointDownSM ? 3 : 6}>
            <Stack spacing={2}>
              <Box>
                <Grid container spacing={3}>
                  {users.map((user) => (
                    <Grid item xs={6} sm={4} lg={3} key={user.id}>
                      <UserCard user={user} handleClick={() => navigate(`${user.id}`)} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default AdminUsersTab;
