import { Card, CardContent, Divider, Stack, Typography } from "@mui/material";
import ModalComponent from "./Modal.component";
import { useTranslation } from "react-i18next";
import AIOButtonComponent from "@components/Button.component";
import { ChangeEvent, useMemo, useState } from "react";
import { Coupon, StripePaymentIntentSecret } from "@store/stripe/stripe.model";
import StripeModal from "./Stripe.modal";
import { stripeService } from "@store/stripe/stripe.service";
import { useSnackbar } from "notistack";
import { ManageSubscriptionParameters } from "@store/subscriptions";
import { Colors } from "@constants/colors.constant";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { finalize } from "rxjs";
import { getAppCurrency, getToLocalStringCode } from "@store/common/country.model";

interface OrderReviewModalProps {
  handleClose: () => void;
  userIds: string[];
  isYearly: boolean;
  selectedSubscription: ManageSubscriptionParameters;
}

const OrderPreviewModal = (props: OrderReviewModalProps) => {
  const {
    handleClose,
    userIds,
    selectedSubscription: { subscription },
    isYearly,
  } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [stripeLoading, setStripeLoading] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);

  const [coupon, setCoupon] = useState<Coupon>();
  const [showStripe, setShowStripe] = useState<StripePaymentIntentSecret | false>(false);

  const handleAddCoupon = (evt: ChangeEvent<HTMLInputElement>) => {
    const couponName = evt.target.value;

    if (!couponName) return;
    setCouponLoading(true);
    stripeService.getCoupon(couponName).pipe(finalize(() => setCouponLoading(false))).subscribe({
      next: (coupon) => {
        enqueueSnackbar(t('global.couponApplied'), {variant: 'success'});
        setCoupon(coupon);
      },
      error: (err) => {
        enqueueSnackbar(err.text, err.options);
        evt.target.value = '';
      },
    });
  };

  const calculatePriceWithCoupon = (totalPrice: number, coupon: Coupon) => {
    if (!coupon) return totalPrice;
    if (coupon.amountOff) return totalPrice - coupon.amountOff / 100;
    if (coupon.percentOff) return totalPrice - (totalPrice * coupon.percentOff) / 100;
    return totalPrice; // Retourne le prix total si aucune condition de coupon n'est remplie
  };

  const unitPrice = useMemo(() => !isYearly ? subscription.price : subscription.price * 0.9, [isYearly, subscription.price]);

  const totalPrice = useMemo(() => unitPrice * userIds.length, [unitPrice, userIds]);

  if (!!showStripe) {
    return (
      <StripeModal
        stripePaymentIntendSecret={showStripe}
        title={t("subscriptions.professionalOfferPayment")}
        handleClose={() => setShowStripe(false)}
      />
    );
  }

  return (
    <ModalComponent
      maxWidth="sm"
      title={t("subscriptions.previewOrder.title")}
      handleClose={handleClose}
      titleLeft
      content={
        <Stack flex={1} p="20px" spacing={2}>
          <Card elevation={5}>
            <CardContent>
              <Stack spacing={2}>
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                  <Typography fontSize="14px" fontWeight="300">{t("subscriptions.previewOrder.subscription")} </Typography>
                  <Typography fontSize="14px" fontWeight="400">{subscription.name} </Typography>
                </Stack>
                <Divider sx={{ my: 1 }} />
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                  <Typography fontSize="14px" fontWeight="300">{t("subscriptions.previewOrder.isYearly")} </Typography>
                  <Typography fontSize="14px" fontWeight="400">{t(`global.${isYearly ? 'yes' : 'no'}`)}</Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                  <Typography fontSize="14px" fontWeight="300">{t("subscriptions.previewOrder.price")} </Typography>
                  <Typography fontSize="14px" fontWeight="400">
                    {unitPrice.toLocaleString(getToLocalStringCode())}{getAppCurrency()} {t("global.exclTax")}
                  </Typography>
                </Stack>
                <Divider sx={{ my: 1 }} />
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                  <Typography fontSize="14px" fontWeight="300">{t("subscriptions.previewOrder.userCount")} </Typography>
                  <Typography fontSize="14px" fontWeight="400">{userIds.length}</Typography>
                </Stack>
                <Divider sx={{ my: 1 }} />
                <Stack direction="row" justifyContent="space-between" alignItems={"center"}>
                  <Typography fontSize="14px" fontWeight="300">{t("subscriptions.previewOrder.totalExclTax")}</Typography>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <Typography
                      fontSize="14px"
                      fontWeight={coupon ? "300" : "400"}
                      sx={{ color: coupon ? Colors.error : Colors.green, textDecoration: coupon ? "line-through" : "" }}>
                      {totalPrice.toLocaleString(getToLocalStringCode())}{getAppCurrency()} {t("global.exclTax")}
                    </Typography>
                    {coupon && (
                      <Typography fontSize="14px" fontWeight="400" sx={{ color: Colors.green }}>
                        {calculatePriceWithCoupon(totalPrice, coupon).toLocaleString(getToLocalStringCode())}
                        {getAppCurrency()} {t("global.exclTax")}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </CardContent>
          </Card>
          <Stack direction="row" spacing={3} alignSelf="flex-end" alignItems="flex-end">
            <AIOTextfieldComponent title={t("global.coupon")} onBlur={handleAddCoupon} />
          </Stack>
        </Stack>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          disabled={stripeLoading || couponLoading}
          title={t("global.validate")}
          onClick={() => {
            setStripeLoading(true);
            stripeService
              .getProSubClientSecret({ userIds, isYearly, couponId: coupon?.id })
              .pipe(finalize(() => setStripeLoading(false)))
              .subscribe({
                next: setShowStripe,
                error: (err) => enqueueSnackbar(err.text, err.options),
              });
          }}
        />
      }
    />
  );
};

export default OrderPreviewModal;
