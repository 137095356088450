import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import React, { useState } from "react";
import { useObservable } from "@ngneat/react-rxjs";

import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { reportsQuery, reportsService } from "@store/reports";

import { ReportsRoutes } from "@utils/routes.utils";

import AIORoutesTabComponent from "@components/AIORoutesTab.component";
import { SelectItem } from "@components/input/Select.component";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";

const Reports = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [affiliates] = useState<SelectItem[]>(sessionQuery.affiliatesItems);
  const [filters] = useObservable(reportsQuery.filters$);

  const powerBI = clientViewClient
    ? clientViewClient.powerBI || []
    : sessionQuery.user.affiliates.flatMap((a) => a.affiliate.powerBI || []);

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : sessionQuery.role === UserRoleEnum.ADMIN ? "30px" : "60px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 1 : 2}
      width="100%"
      overflow="hidden"
      height="100%">
      {sessionQuery.role !== UserRoleEnum.ADMIN && (
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography fontSize={breakpointDownMD ? "30px" : "35px"} fontWeight={700} lineHeight={1.25}>
            {t(`reports.title`)}
          </Typography>
          {affiliates.length > 1 && (
            <Box width="250px">
              <MultiSelectWithSearchComponent
                handleChange={(values) => reportsService.setFilters({ affiliateIds: values })}
                startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
                placeholder={t("global.filterByCompanies")}
                items={affiliates}
                values={filters.affiliateIds ?? []}
              />
            </Box>
          )}
        </Stack>
      )}
      <AIORoutesTabComponent
        routes={ReportsRoutes.routes.filter((_: any, index: number) => !powerBI.length ? index !== 2 : true)}
        disableCenter />
      <Outlet />
    </Stack>
  );
};

export default Reports;
