import { createRequestDataSource } from "@ngneat/elf-requests";
import { globalStatisticsStore } from "@store/globalStatistics/globalStatistics.store";
import { select } from "@ngneat/elf";
import { AdminStats, AffiliatesStats } from "@store/globalStatistics/globalStatistics.model";

export const getAffiliatesGlobalStatisticsDataSource = createRequestDataSource({
  store: globalStatisticsStore,
  data$: () => globalStatisticsStore.pipe(select((state) => state.affiliates ?? {} as Partial<AffiliatesStats>)),
  requestKey: "getAffiliatesGlobalStatistics",
  dataKey: "affiliatesStats"
});

export const getAdminGlobalStatisticsDataSource = createRequestDataSource({
  store: globalStatisticsStore,
  data$: () => globalStatisticsStore.pipe(select((state) => state.admin ?? {} as Partial<AdminStats>)),
  requestKey: "getAdminGlobalStatistics",
  dataKey: "adminStats"
});
