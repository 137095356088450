import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { personaeStore } from "@store/personae/personae.store";
import { select } from "@ngneat/elf";

export const getPersonaeDataSource = createRequestDataSource({
  store: personaeStore,
  data$: () => personaeStore.pipe(selectAllEntities()),
  requestKey: "getPersonae",
  dataKey: "personae"
});

export const getPersonaeQuizDataSource = createRequestDataSource({
  store: personaeStore,
  data$: () => personaeStore.pipe(select((state) => state.quiz)),
  requestKey: "getPersonaeQuiz",
  dataKey: "personaeQuiz"
});