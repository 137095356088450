import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";
import { useCallback, useEffect, useState } from "react";

import { ToolboxColumn, ToolboxTool } from "@store/toolbox";
import { affiliateDetailsService } from "@store/entities/afiliates/details";
import AIOButtonComponent from "@components/Button.component";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import PreviewImageComponent from "@components/PreviewImage.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import { useDropzone } from "react-dropzone";
import ModalComponent from "@components/modal/Modal.component";
import { EntitiesModel } from "@store/entities/entities.model";

interface ToolRequestModalProps {
  handleClose: () => void;
  toolboxColumns: ToolboxColumn[];
  entity: EntitiesModel;
  columnIdToAddDocument: string;
}

const AddToolboxDocumentModal = (props: ToolRequestModalProps) => {
  const { handleClose, toolboxColumns, entity, columnIdToAddDocument } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [previewImages, setPreviewImages] = useState<{ id: string; src: string; file: File }[]>([]);
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setMyFiles([...myFiles, ...acceptedFiles.filter((f) => !myFiles.find((mf) => mf.name + mf.size === f.name + f.size))]);
    },
    [myFiles]
  );

  useEffect(() => {
    myFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target?.result && typeof e.target?.result === "string") {
          setPreviewImages((prevState) => [
            ...prevState.filter((img) => img.file.name !== file.name),
            {
              id: Math.random().toString(),
              src: file.type === "application/pdf" ? "/images/pdf-thumbnail.png" : (e.target?.result as string),
              file,
            },
          ]);
        }
      };
      reader.readAsDataURL(file);
    });
  }, [myFiles]);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    multiple: true,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "application/pdf": [],
    },
  });

  const handleRemoveFile = (file: File) => {
    setPreviewImages((prevState) => prevState.filter((p) => p.file !== file));
    setMyFiles((prevState) => prevState.filter((f) => f !== file));
  };

  const documentTypeItems = toolboxColumns
    .find((c) => c.id === columnIdToAddDocument)
    ?.tools.map((c) => ({ label: `${c.category ? c.category + " - " : ""}${c.title}`, value: c }));

  const [documentTypeSelected, setDocumentTypeSelected] = useState<ToolboxTool>();

  const handleChangeDocumentType = (value: ToolboxTool) => {
    setDocumentTypeSelected(value);
  };

  const handleConfirm = () => {
    if (!documentTypeSelected) {
      return;
    }
    if (!myFiles.length) return;
    setLoading(true);
    affiliateDetailsService
      .uploadToolboxTools(
        myFiles.map((f) => ({
          file: f,
          columnId: columnIdToAddDocument,
          toolTitle: documentTypeSelected.title,
          toolCategory: documentTypeSelected.category,
        })),
        entity.id
      )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => handleClose(),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("toolbox.addDocument")}
      titleLeft
      content={
        <Stack mt="20px" spacing={4}>
          <SelectWithSearchComponent
            placeholder={t("toolbox.docType")}
            items={documentTypeItems}
            handleChange={(value) => handleChangeDocumentType(value?.value)}
            value={documentTypeItems?.find((c) => c.value.title === documentTypeSelected?.title) ?? ""}
            error={!documentTypeSelected ? t("toolbox.selectDocumentType") : ""} />
          <Stack spacing={2} alignItems="center">
            <div {...getRootProps()} style={{ width: "100%" }}>
              <Button
                component="label"
                sx={{
                  padding: "30px",
                  backgroundColor: isDragActive ? Colors.lightBlue : Colors.lightPrimary,
                  border: isDragActive ? "solid 1px transparent" : "dashed 1px",
                  width: "100%",
                }}
                onClick={open}>
                <Stack spacing={2} alignItems="center">
                  <Typography color={Colors.primary}>{t("global.importFile")}</Typography>
                  <img width="20px" alt="upload" src="/images/download_media.svg" />
                </Stack>
              </Button>
              <input {...getInputProps()} hidden type="file" multiple />
            </div>

            {!!previewImages.length && (
              <Grid container spacing={"20px"} marginLeft="-20px !important" width="100%">
                {previewImages.map((img) => (
                  <PreviewImageComponent key={img.id} img={img} handleRemoveFile={handleRemoveFile} />
                ))}
              </Grid>
            )}
          </Stack>
        </Stack>
      }
      maxWidth="xs"
      actions={(
        <AIOButtonComponent title={t("global.confirm")} disabled={loading} variant="contained" ariaLabel="modal" onClick={handleConfirm} />
      )}
    />
  );
};

export default AddToolboxDocumentModal;
