import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { companiesStore } from "./companies.store";

export const getCompaniesDataSource = createRequestDataSource({
  store: companiesStore,
  data$: () => companiesStore.pipe(selectAllEntities()),
  requestKey: "getCompanies",
  dataKey: "companies"
});
