import React, { useEffect, useState } from "react";
import { Box, Stack } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { useParams } from "react-router-dom";

import { sessionQuery } from "@store/session";
import { PowerBIModel } from "@store/common/powerbi.model";
import { affiliateDetailsQuery } from "@store/entities/afiliates/details";

import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import { reportsQuery } from "@store/reports";

const PowerBiReportsTab = () => {
  const { entityId } = useParams();

  const [powerBI, setPowerBI] = useState<PowerBIModel[]>([]);
  const [selectedPowerBI, setSelectedPowerBI] = useState(0);

  const [currentAffiliate] = useObservable(affiliateDetailsQuery.affiliateDetails$);
  const [reportsFilters] = useObservable(reportsQuery.filters$);
  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  useEffect(() => {
    if (clientViewClient) {
      setPowerBI(clientViewClient.powerBI || []);
    } else if (entityId) {
      setPowerBI(currentAffiliate?.powerBI || []);
    } else if (reportsFilters.affiliateIds?.length) {
      setPowerBI(reportsFilters.affiliateIds.flatMap((a) => a.data.affiliate.powerBI || []));
    } else {
      setPowerBI(
        sessionQuery.user.affiliates.flatMap((a) => a.affiliate.powerBI || []),
      );
    }
    setSelectedPowerBI(0);
  }, [clientViewClient, currentAffiliate, entityId, reportsFilters]);

  if (!powerBI?.length) return null;

  return (
    <Stack flex={1} mb="40px !important" mt="-40px !important" spacing="20px">
      <Stack alignItems="flex-end">
        <Box width="300px">
          <SelectWithSearchComponent
            items={powerBI.map((p, index) => ({label: p.label, value: index})) || []}
            value={{
              label: powerBI[selectedPowerBI].label,
              value: selectedPowerBI,
            }}
            handleChange={(item) => setSelectedPowerBI(item?.value ?? 0)} />
        </Box>
      </Stack>
      <iframe
        title={powerBI[selectedPowerBI].label}
        width="100%"
        height="100%"
        src={powerBI[selectedPowerBI].url}
        frameBorder="0"
        allowFullScreen/>
    </Stack>
  );
};

export default PowerBiReportsTab;
