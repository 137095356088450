import { createStore, withProps } from "@ngneat/elf";
import { withRequestsStatus } from "@ngneat/elf-requests";

import {
  EntitiesFiltersModel,
  EntitiesSortFieldEnum,
  EntitiesSortModel
} from "@store/entities/entitiesFilters/entitiesFilters.model";

interface EntitiesStoreProps {
  filters: EntitiesFiltersModel;
  sort: EntitiesSortModel;
}

export const entitiesFiltersStore = createStore(
  {name: "entities-filters"},
  withProps<EntitiesStoreProps>({
    filters: {},
    sort: {field: EntitiesSortFieldEnum.NAME},
  }),
  withRequestsStatus(),
);
