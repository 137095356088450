import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { Personae, PersonaeFilters, PersonaeSort, PersonaeSortField } from "@store/personae/personae.model";
import { PersonaeQuiz } from "@store/personae/personaeQuiz.model";

interface PersonaeStoreProps {
  quiz: PersonaeQuiz;
  filters: PersonaeFilters;
  sort: PersonaeSort;
}

export const personaeStore = createStore(
  { name: "personae" },
  withProps<PersonaeStoreProps>({
    quiz: {},
    filters: {},
    sort: { field: PersonaeSortField.NAME }
  }),
  withEntities<Personae>(),
  withRequestsStatus()
);
