import { Stack, Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import { useTranslation } from "react-i18next";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { BoostDecision, boostDetailsService } from "@store/boosts/details";
import { BoostStatus } from "@store/boosts";
import { finalize } from "rxjs";
import { sessionQuery } from "@store/session";

interface BoostConfirmationModalProps {
  boostDecision: BoostDecision;
  handleClose: () => void;
}

const BoostConfirmationModal = (props: BoostConfirmationModalProps) => {
  const { boostDecision, handleClose } = props;

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { boostId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [reason, setReason] = useState("");

  const handleConfirm = () => {
    if (!boostId) return;

    setLoading(true);
    boostDetailsService.manageBoostRequest(boostId, boostDecision, reason)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(
            t(`boost.details.detailsTab.confirmation.success.${boostDecision === BoostStatus.VALIDATED ? "accepted" : "rejected"}`),
            { variant: "success" }
          );
          if (sessionQuery.buyerMode) navigate("..");
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t(`boost.details.detailsTab.confirmation.${boostDecision === BoostStatus.VALIDATED ? "acceptTitle" : "title"}`)}
      maxWidth="xs"
      titleLeft
      content={(
        <Stack spacing={3} py="20px">
          <Typography flex={1} color={Colors.secondaryText} lineHeight="1.25" fontWeight={300}>
            {t(`boost.details.detailsTab.confirmation.${boostDecision === BoostStatus.VALIDATED ? "acceptDescription" : "description"}`)}
          </Typography>
          {boostDecision === BoostStatus.REJECTED && (
            <AIOTextfieldComponent
              placeholder={t("boost.details.detailsTab.confirmation.reason")}
              value={reason ?? ""}
              onChange={setReason}
              multiline={true}
              minRows={5} />
          )}
        </Stack>
      )}
      paddingButton="10px 0px 40px 0px"
      actions={(
        <AIOButtonComponent
          title={t("global.confirm")}
          variant="contained"
          disabled={loading || (boostDecision === BoostStatus.REJECTED && !reason)}
          ariaLabel="modal"
          onClick={handleConfirm} />
      )} />
  );
};

export default BoostConfirmationModal;