export enum TemplateTypeEnum {
  BRUT = "BRUT",
  BLUE = "BLUE",
  RED = "RED",
  MOTIFS = "MOTIFS",
}

export namespace TemplateTypeEnumFunctions {
  export const images = {
    [TemplateTypeEnum.BRUT]: '/images/img_cvtemplate_small_1.svg',
    [TemplateTypeEnum.BLUE]: '/images/img_cvtemplate_small_2.svg',
    [TemplateTypeEnum.RED]: '/images/img_cvtemplate_small_3.svg',
    [TemplateTypeEnum.MOTIFS]: '/images/img_cvtemplate_small_4.svg',
  }
}

export interface Template {
  id: string;
  title: string;
  model: TemplateTypeEnum;
}
