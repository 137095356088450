import { alpha, Avatar, CardActionArea, Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";

import { StripeProductModel, StripeProductTypeEnum } from "@store/stripe/products/stripeProducts.model";
import { getAppCurrency } from "@store/common/country.model";

import { Colors } from "@constants/colors.constant";

import StripeProductDetails from "@components/modal/StripeProduct.modal";

const StripeProductCardStyled = styled(Stack)`
  background-color: ${Colors.white};
  border-radius: 15px;
  flex: 1;
  height: 100%;
  opacity: 1;
  position: relative;
  width: 100%;
  & > *:first-of-type {
    align-items: center;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(34,34,64, 0.05);  
    padding: 40px 22px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
  };
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    };
  };
`;

const BadgeType = styled(Stack)`
  border-radius: 5px;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 500;
  height: 15px;
  padding: 0 8px;
  position: absolute;
  top: -8px;
  z-index: 1;
`;

interface StripeProductCardProps {
  stripeProduct: StripeProductModel;
  productType: StripeProductTypeEnum;
}

const StripeProductCard = (props: StripeProductCardProps) => {
  const { stripeProduct, productType } = props;

  const { t } = useTranslation();

  const [openDetails, setOpenDetails] = useState(false);

  const handleClick = () => setOpenDetails(true);

  return (
    <StripeProductCardStyled>
      <Stack component={CardActionArea} onClick={handleClick}>
        {productType === StripeProductTypeEnum.NONE && (
          <BadgeType bgcolor={Colors.error} alignItems="center" justifyContent="center">
            {t(`adminParameters.stripeProducts.type.${productType}`)}
          </BadgeType>
        )}
        {productType === StripeProductTypeEnum.SEQUENCE && (
          <BadgeType bgcolor={Colors.mauve} alignItems="center" justifyContent="center">
            {t(`adminParameters.stripeProducts.type.${productType}`)}
          </BadgeType>
        )}
        {productType === StripeProductTypeEnum.JOB_BOARD && (
          <BadgeType bgcolor={Colors.green} alignItems="center" justifyContent="center">
            {t(`adminParameters.stripeProducts.type.${productType}`)}
          </BadgeType>
        )}
        {productType === StripeProductTypeEnum.BOOST_ACTION && (
          <BadgeType bgcolor={Colors.orange} alignItems="center" justifyContent="center">
            {t(`adminParameters.stripeProducts.type.${productType}`)}
          </BadgeType>
        )}
        {productType === StripeProductTypeEnum.SCHOOL && (
          <BadgeType bgcolor={Colors.affiliate} alignItems="center" justifyContent="center">
            {t(`adminParameters.stripeProducts.type.${productType}`)}
          </BadgeType>
        )}
        <Stack alignItems="center" direction="row" width="100%" spacing={1.5}>
          <Avatar
            sx={{
              border: "none",
              borderRadius: "10px !important",
              backgroundColor: stripeProduct.picture ? Colors.white : alpha(Colors.lightpurple, 0.6),
              color: Colors.secondary,
              height: 53,
              width: 53,
            }}
            src={stripeProduct.picture}>
            {stripeProduct.name[0]}
          </Avatar>
          <Stack>
            <Typography
              fontSize={16}
              fontWeight="500"
              lineHeight={1}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineClamp: 2,
                WebkitLineClamp: 2,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              }}>
              {stripeProduct.name}
            </Typography>
            <Stack spacing={1} alignItems="center" direction="row">
              <img alt="location" src="/images/icon_euro_grey.svg" />
              <Typography color={Colors.secondaryText} fontWeight={500} pt="1px">
                {stripeProduct.price} {getAppCurrency()}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {openDetails && (
        <StripeProductDetails
          stripeProduct={stripeProduct}
          productType={productType}
          handleClose={() => setOpenDetails(false)}/>
      )}
    </StripeProductCardStyled>
  );
};

export default StripeProductCard;
