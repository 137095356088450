import { select } from "@ngneat/elf";
import { companyDetailsStore } from "./companyDetails.store";
import { getCompanyDetailsDetailsDataSource, getCompanyDetailsStatsDataSource } from "./companyDetails.requests";

export class CompanyDetailsQuery {
  store = companyDetailsStore;

  details$ = getCompanyDetailsDetailsDataSource.data$();
  stats$ = getCompanyDetailsStatsDataSource.data$();

  companyDetails$ = this.store.pipe(select((state) => state.details));
}

export const companytDetailsQuery = new CompanyDetailsQuery();
