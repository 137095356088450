import {Components} from "@mui/material";
import {Colors} from "../constants/colors.constant";

const styles: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "fit-content",
  padding: "35px",
  backgroundColor: Colors.lightOrange,
  cursor: "pointer",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  objectFit: "fill",
};

const iconButton: Components = {
  MuiIconButton: {
    styleOverrides: {
      root: (props) => ({
        ...(props.ownerState["aria-label"] === "avatar-circle" ? {
          ...styles,
          backgroundColor: 'rgba(232,97,77, 0.1)',
          borderRadius: "none",
          height: 105,
          width: 105,
          "&.Mui-disabled": {
            backgroundColor: 'rgba(232,97,77, 0.1)',
          },
        } : props.ownerState["aria-label"] === "avatar-rounded" ? {
          ...styles,
          backgroundColor: 'rgba(232,97,77, 0.1)',
          borderRadius: "15px",
          boxShadow: '0px 0px 20px rgba(0,0,0, 0.05)',
          height: 105,
          width: 105,
          "&.Mui-disabled": {
            backgroundColor: 'rgba(232,97,77, 0.1)',
          },
        } : {}),
        ...(props.ownerState["aria-label"] === "dashboard-button" ? {
          backgroundColor: Colors.white,
          borderRadius: "15px",
          width: "40px",
          height: "40px",
        } : {}),
      }),
    },
  },
};
export default iconButton;
