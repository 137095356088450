import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { tonesStore } from "./tones.store";

export const getTonesDataSource = createRequestDataSource({
  store: tonesStore,
  data$: () => tonesStore.pipe(selectAllEntities()),
  requestKey: "getTones",
  dataKey: "tones",
});
