import CardBackgroundComponent from "@components/cardBackground/CardBackground.component";
import { Colors } from "@constants/colors.constant";
import styled from "@emotion/styled";
import { alpha, Collapse, LinearProgress, Stack, Typography } from "@mui/material";
import { Subscription, UserSubscription } from "@store/subscriptions";
import { useTranslation } from "react-i18next";
import EuroIcon from "@mui/icons-material/Euro";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import dayjsUtils from "@utils/dayjs.utils";
import AIOButtonComponent from "@components/Button.component";
import { useState } from "react";
import UsersListComponent from "@screens/auth/admin/entities/components/UsersList.component";

const CardContainer = styled(Stack)({
  backgroundColor: Colors.users.listBackground,
  borderRadius: "10px",
  overflow: 'visible',
  width: '100%',
});

const Tag = styled(Stack)({
  backgroundColor: alpha(Colors.white, 0.1),
  borderRadius: "7px",
  padding: "4px 6px",
});

const TagText = styled(Typography)({
  color: Colors.white,
  fontSize: "14px",
  fontWeight: 500,
});

const TagTextSecondary = styled(Typography)({
  color: Colors.greyLight,
  fontSize: "11px",
  fontWeight: 300,
});

const StyledLinearProgress = styled(LinearProgress)({
  backgroundColor: alpha(Colors.white, 0.1),
  height: "5px",
  width: "100%",
  borderRadius: "3px",
  "& .MuiLinearProgress-bar": {
    backgroundColor: Colors.white,
  },
});

interface ProSubscriptionCardProps {
  userSubscription: UserSubscription;
  subscription?: Subscription;
}

const ProSubscriptionCard = (props: ProSubscriptionCardProps) => {
  const { subscription, userSubscription } = props;
  const { t } = useTranslation();

  const [showUsers, setShowUsers] = useState(false);

  const daysLeftBeforeInvoice = Math.abs(dayjsUtils().diff(userSubscription.currentPeriodEnd, "day"));
  const startPeriod = dayjsUtils(userSubscription.currentPeriodEnd).subtract(1, 'month');

  const totalDaysInPeriod = Math.abs(startPeriod.diff(userSubscription.currentPeriodEnd, 'd'));

  return (
    <CardContainer>
      <CardBackgroundComponent img="/images/background_campaign.svg" borderRadius="10px">
        <Stack px="21px" py="13px">
          <Stack direction="row" justifyContent="space-between" mb="30px">
            <Stack direction="row" spacing="5px" alignItems="center" mr="20px">
              <img height="14px" src="/images/subscription/icon_subscription_professional.svg" alt="professional" />
              <Typography fontSize="16px" fontWeight={500} color={Colors.subscriptions.professional}>
                {subscription?.name}
              </Typography>
            </Stack>
            <Stack direction="row" spacing="5px" alignItems="center">
              <Tag direction="row" alignItems="center" spacing="4px">
                <PersonOutlineIcon sx={{ color: Colors.greyLight, fontSize: "16px" }} />
                <TagText>{userSubscription?.users.length}</TagText>
              </Tag>
              <Tag direction="row" alignItems="center" spacing="4px">
                <EuroIcon sx={{ color: Colors.greyLight, fontSize: "14px" }} />
                <Stack direction="row" alignItems="center">
                  <TagText>{((subscription?.price ?? 0) * (userSubscription.isYearly ? 0.9 : 1)).toFixed(2)}</TagText>
                  <TagTextSecondary>{`/${t("subscriptions.user", {count: 1})}`}</TagTextSecondary>
                </Stack>
              </Tag>
              <Tag direction="row" alignItems="center" spacing="4px">
                <img width="12px" alt="business" src="/images/business_case.svg" />
                <TagText>{userSubscription.isYearly ? t("subscriptions.yearly") : t("subscriptions.monthly")}</TagText>
              </Tag>
            </Stack>
          </Stack>
          <StyledLinearProgress variant="determinate" value={100 - (daysLeftBeforeInvoice / totalDaysInPeriod * 100)} />
          <Stack width="100%" direction="row" justifyContent="flex-end" mt="8px" mb="5px">
            {!!userSubscription.endDate && Math.abs(dayjsUtils().diff(userSubscription.endDate, 'month', true)) <= 1 ? (
              <Typography fontWeight={500} color={Colors.white}>
                {t("subscriptions.endSub", { date: new Date(userSubscription.endDate) })}
              </Typography>
            ) : (
              <Typography fontWeight={500} color={Colors.white}>
                {t("subscriptions.nextInvoice", { date: new Date(userSubscription.currentPeriodEnd) })}
              </Typography>
            )}
          </Stack>
          <Stack alignSelf="center">
            <AIOButtonComponent
              variant="text"
              color="primary"
              endIcon={(
                <img
                  alt=""
                  style={{
                    transform: showUsers ? 'rotate(180deg)' : undefined,
                    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  }}
                  src="/images/arrow_dropdownlist_primary.svg" />
              )}
              title={t(`subscriptions.${showUsers ? 'hideUsers' : 'showUsers'}`)}
              onClick={() => setShowUsers((state) => !state)} />
          </Stack>
        </Stack>
      </CardBackgroundComponent>
      <Collapse in={showUsers}>
        <UsersListComponent inAccordion users={userSubscription.users}/>
      </Collapse>
    </CardContainer>
  );
};

export default ProSubscriptionCard;
