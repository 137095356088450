import { select } from "@ngneat/elf";
import { map, Observable } from "rxjs";
import { jobBoardsStore } from "./jobBoards.store";
import {
  entitiesPropsFactory,
  getAllEntities,
  getAllEntitiesApply,
  selectAllEntities,
  selectEntities,
  UIEntitiesRef,
  unionEntities
} from "@ngneat/elf-entities";
import {
  BoostAction,
  JobBoard,
  JobBoardAndSequenceAndBoostActionAndSchoolUI,
  School,
  Sequence
} from "@store/jobBoards/jobBoards.model";
import { SelectItem } from "@components/input/Select.component";

const { sequencesEntitiesRef } = entitiesPropsFactory("sequences");
const { boostActionsEntitiesRef } = entitiesPropsFactory("boostActions");
const { schoolsEntitiesRef } = entitiesPropsFactory("schools");

export class JobBoardsQuery {
  store = jobBoardsStore;

  jobBoards$: Observable<(JobBoard & JobBoardAndSequenceAndBoostActionAndSchoolUI)[]> = this.store
    .combine({
      entities: this.store.pipe(selectAllEntities()),
      UIEntities: this.store.pipe(selectEntities({ ref: UIEntitiesRef })),
    })
    .pipe(unionEntities());
  jobBoardSelected$: Observable<(JobBoard & JobBoardAndSequenceAndBoostActionAndSchoolUI)[]> = this.store
    .combine({
      entities: this.store.pipe(selectAllEntities()),
      UIEntities: this.store.pipe(selectEntities({ ref: UIEntitiesRef })),
    })
    .pipe(
      unionEntities(),
      map((jobBoards) => jobBoards.filter((j) => j.selected))
    );

  sequences$: Observable<(Sequence & JobBoardAndSequenceAndBoostActionAndSchoolUI)[]> = this.store
    .combine({
      entities: this.store.pipe(selectAllEntities({ ref: sequencesEntitiesRef })),
      UIEntities: this.store.pipe(selectEntities({ ref: UIEntitiesRef })),
    })
    .pipe(unionEntities());
  sequencesSelected$: Observable<(Sequence & JobBoardAndSequenceAndBoostActionAndSchoolUI)[]> = this.store
    .combine({
      entities: this.store.pipe(selectAllEntities({ ref: sequencesEntitiesRef })),
      UIEntities: this.store.pipe(selectEntities({ ref: UIEntitiesRef })),
    })
    .pipe(
      unionEntities(),
      map((sequences) => sequences.filter((s) => s.selected))
    );

  boostActions$: Observable<(BoostAction & JobBoardAndSequenceAndBoostActionAndSchoolUI)[]> = this.store
    .combine({
      entities: this.store.pipe(selectAllEntities({ ref: boostActionsEntitiesRef })),
      UIEntities: this.store.pipe(selectEntities({ ref: UIEntitiesRef })),
    })
    .pipe(unionEntities());
  boostActionsSelected$: Observable<(BoostAction & JobBoardAndSequenceAndBoostActionAndSchoolUI)[]> = this.store
    .combine({
      entities: this.store.pipe(selectAllEntities({ ref: boostActionsEntitiesRef })),
      UIEntities: this.store.pipe(selectEntities({ ref: UIEntitiesRef })),
    })
    .pipe(
      unionEntities(),
      map((boostActions) => boostActions.filter((s) => s.selected))
    );

  schools$: Observable<(School & JobBoardAndSequenceAndBoostActionAndSchoolUI)[]> = this.store
    .combine({
      entities: this.store.pipe(selectAllEntities({ ref: schoolsEntitiesRef })),
      UIEntities: this.store.pipe(selectEntities({ ref: UIEntitiesRef })),
    })
    .pipe(unionEntities());
  schoolsSelected$: Observable<(School & JobBoardAndSequenceAndBoostActionAndSchoolUI)[]> = this.store
    .combine({
      entities: this.store.pipe(selectAllEntities({ ref: schoolsEntitiesRef })),
      UIEntities: this.store.pipe(selectEntities({ ref: UIEntitiesRef })),
    })
    .pipe(
      unionEntities(),
      map((schools) => schools.filter((s) => s.selected))
    );

  filters$ = this.store.pipe(select((state) => state.filters));

  get jobBoards() {
    return this.store.query(getAllEntities());
  }

  get jobBoardsSelectItems(): SelectItem[] {
    return this.store.query(
      getAllEntitiesApply({
        mapEntity: (e) => ({ label: e.name, value: e.id, data: e }),
      })
    );
  }

  get sequencesSelectItems(): SelectItem[] {
    return this.store.query(
      getAllEntitiesApply({
        mapEntity: (e) => ({ label: e.name, value: e.id, data: e }),
        ref: sequencesEntitiesRef,
      })
    );
  }

  get boostActionsSelectItems(): SelectItem[] {
    return this.store.query(
      getAllEntitiesApply({
        mapEntity: (e) => ({ label: e.name, value: e.id, data: e }),
        ref: boostActionsEntitiesRef,
      })
    );
  }

  get schoolsSelectItems(): SelectItem[] {
    return this.store.query(
      getAllEntitiesApply({
        mapEntity: (e) => ({ label: e.name, value: e.id, data: e }),
        ref: schoolsEntitiesRef,
      })
    );
  }
}

export const jobBoardsQuery = new JobBoardsQuery();
