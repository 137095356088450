import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Grid, Stack, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";
import { finalize, Observable, switchMap } from "rxjs";

import { AdModel, adsQuery, adsService, AdsSortField, searchAdsEffect } from "@store/ads";
import { Candidate } from "@store/ai-o/candidates";

import { Colors } from "@constants/colors.constant";

import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import { InfiniteScrollContainer, VirtualScrollItem } from "@utils/infinitescroll.utils";
import AdCard from "@components/card/Ad.card";
import AIOSearchComponent from "@components/input/AIOSearch.component";

interface AssociateAnnouncementModalProps {
  candidate: Candidate;
  handleClose: () => void;
  updateCandidates: () => Observable<any>;
}

const AssociateAnnouncementModal = (props: AssociateAnnouncementModalProps) => {
  const { candidate, handleClose, updateCandidates } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const scrollableAdsRef = useRef<HTMLDivElement | null>(null);

  const searchAds = useEffectFn(searchAdsEffect);

  const [{ ads, error, loading: adsLoading }] = useObservable(adsQuery.ads$);
  const [adsPaginationData] = useObservable(adsQuery.adsPaginationData$);

  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;

  const [selectedAd, setSelectedAd] = useState<AdModel | undefined>();

  const [showSuggestedAds, setShowSuggestedAds] = useState(false);

  useEffect(() => adsService.resetStore(), [search, showSuggestedAds]);

  useEffect(() => {
    if (error) enqueueSnackbar((error as any).text, (error as any).options);
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    searchAds({
      filters: {
        search,
        ...(showSuggestedAds && {
          aioCandidateJobName: candidate.job.name,
          aioCandidateJobLocation: candidate.job.location,
          aioCandidateJobReference: candidate.job.reference,
        }),
        affiliateIds: candidate.companyAffiliateId ? [{ value: candidate.companyAffiliateId, label: "" }] : [],
      },
      sort: { field: AdsSortField.NAME },
      page: currentPage,
      take: itemsPerPage,
    });
  }, [candidate, searchAds, itemsPerPage, currentPage, search, showSuggestedAds]);

  const handleNextPage = useCallback(() => {
    if (adsPaginationData.lastPage >= adsPaginationData.currentPage) {
      setCurrentPage(adsPaginationData.currentPage + 1);
    }
  }, [adsPaginationData.currentPage, adsPaginationData.lastPage]);

  const backToTop = () => {
    if (scrollableAdsRef.current)
      scrollableAdsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  return (
    <ModalComponent
      maxWidth="lg"
      title={t("aio.applications.associateAd.title")}
      handleClose={handleClose}
      titleLeft
      content={
        <Stack direction="column" spacing={2} overflow="auto" height="60vh" mb={1} py="16px" width="100%">
          <Stack direction="row" alignItems="center" spacing={"10px"}>
            <Switch checked={showSuggestedAds} onChange={(evt, checked) => setShowSuggestedAds(checked)} />
            <Typography>{t("aio.applications.associateAd.showSuggested")}</Typography>
          </Stack>
          <Box width="100%">
            <AIOSearchComponent
              width="100%"
              placeholder={t("aio.applications.associateAd.searchAd")}
              value={search}
              onChange={(value) => setSearch(value ?? "")}
            />
          </Box>
          <Box overflow="auto" className="scrollable" py="20px" px="5px" ref={scrollableAdsRef}>
            <Grid container spacing={3}>
              <InfiniteScrollContainer
                nextPageHandler={handleNextPage}
                itemsPerPage={itemsPerPage}
                componentType="Grid"
                listItems={ads.map((ad) => (
                  <VirtualScrollItem
                    key={ad.id}
                    height={200}
                    children={<AdCard selected={ad.id === selectedAd?.id} ad={ad} handleClick={() => setSelectedAd(ad)} />}
                  />
                ))}
              />
            </Grid>
          </Box>
          {adsLoading && (
            <Stack direction="row" width="100%" justifyContent="center" mt="20px" height={"80px"} alignItems="center">
              {adsLoading && <CircularProgress size={30} />}
            </Stack>
          )}
        </Stack>
      }
      actions={
        <Stack direction="row" alignItems="center" spacing="10px">
          <AIOButtonComponent
            variant="contained"
            color="secondary"
            ariaLabel="modal"
            title={t("global.validate")}
            onClick={() => {
              if (!selectedAd) return;

              setLoading(true);
              adsService
                .createAIOApplication(selectedAd.id, selectedAd.reference, candidate)
                .pipe(
                  switchMap(() => updateCandidates()),
                  finalize(() => setLoading(false))
                )
                .subscribe({
                  next: () => {
                    enqueueSnackbar(t("aio.applications.associateAd.success"), { variant: "success" });
                    handleClose();
                  },
                  error: (err) => enqueueSnackbar(err.text, err.options),
                });
            }}
            disabled={loading || !selectedAd}
          />
          <Stack
            sx={{
              bgcolor: Colors.lightPink,
              borderRadius: "15px",
              cursor: "pointer",
              height: "39px",
              p: "10px",
            }}
            justifyContent="center"
            alignItems="center"
            onClick={backToTop}>
            <img width="18px" src="/images/arrow_up.svg" alt="back to top arrow" />
          </Stack>
        </Stack>
      }
    />
  );
};

export default AssociateAnnouncementModal;
