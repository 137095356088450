import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { Outlet } from "react-router-dom";

import { ReportsRoutes } from "@utils/routes.utils";

import AIORoutesTabComponent from "@components/AIORoutesTab.component";
import { useObservable } from "@ngneat/react-rxjs";
import { affiliateDetailsQuery } from "@store/entities/afiliates/details";

const EntityReportsTab = () => {
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [affiliate] = useObservable(affiliateDetailsQuery.affiliateDetails$)

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : "30px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 1 : 2}
      width="100%"
      overflow="hidden"
      height="100%">
      <AIORoutesTabComponent
        routes={ReportsRoutes.routes.filter((_: any, index: number) => !affiliate?.powerBI?.length ? index !== 2 : true)}
        disableCenter />
      <Outlet />
    </Stack>
  );
};

export default EntityReportsTab;
