import { getPromptsDataSource } from "./prompts.requests";
import { promptsStore } from "./prompts.store";

export class PromptsQuery {
  store = promptsStore;

  prompts$ = getPromptsDataSource.data$();
}

export const promptsQuery = new PromptsQuery();
