import AIOButtonComponent from "@components/Button.component";
import { CircularProgress, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { sessionService } from "@store/session";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { finalize } from "rxjs";

const LinkedinSync = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const code = searchParams.get("code");

    if (code) {
      sessionService
        .syncLinkedinUser(code)
        .pipe(
          finalize(() => {
            setLoading(false);
          })
        )
        .subscribe({
          next: () => navigate(`${searchParams.get("state")}`),
          error: () => setError(true),
        });
    }
  }, [searchParams, enqueueSnackbar, t, navigate]);

  return (
    <Stack width="100%" height="100%" justifyContent="center" alignItems="center">
      {loading && (
        <Stack spacing={2} alignItems="center">
          <Typography>{t("linkedin.connecting")}</Typography>
          <CircularProgress />
        </Stack>
      )}
      {!loading && (
        <Stack spacing={2} alignItems="center">
          {error && <Typography>{t("linkedin.syncronizedError")}</Typography>}
          <AIOButtonComponent
            variant="contained"
            onClick={() => {
              navigate(`${searchParams.get("state")}`);
            }}
            title={t("linkedin.comeBack")}
          />
        </Stack>
      )}
    </Stack>
  );
};
export default LinkedinSync;
