import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import {
  AIPromptModel,
  aiPromptsService,
  AIPromptTypeEnum,
  AIPromptTypeFunctions,
  ManageAIPromptModel
} from "@store/aiPrompts";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

import PromptsCard from "@components/card/Prompts.card";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import VariablesComponent from "@components/aio-prompts/Variables.component";
import { Colors } from "@constants/colors.constant";

const AdminPromptsComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [prompts, setPrompts] = useState<AIPromptModel[]>([]);

  useEffect(() => {
    aiPromptsService.getPrompts().subscribe({
      next: setPrompts,
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  const getSearchKeyWords = useMemo(() => prompts.find((p) => p.type === AIPromptTypeEnum.PROFILE_SEARCH_KEYWORD), [prompts]);
  const getAISlogans = useMemo(() => prompts.find((p) => p.type === AIPromptTypeEnum.SLOGAN), [prompts]);
  const getAIHashtags = useMemo(() => prompts.find((p) => p.type === AIPromptTypeEnum.HASHTAG), [prompts]);
  const getAILinkedinPost = useMemo(() => prompts.find((p) => p.type === AIPromptTypeEnum.LINKEDIN_POST), [prompts]);

  const updatePrompt = (name: string, promptType: AIPromptTypeEnum) => (value: string) => {
    if (!promptType) return;
    setPrompts((state) => state.map((p) => (p.type === promptType ? { ...p, [name]: value } : p)));
  };

  const handleSave = (prompt?: ManageAIPromptModel) => {
    if (!prompt?.id) return;

    setLoading(true);
    aiPromptsService
      .updatePrompt(prompt.id, prompt)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => enqueueSnackbar(t("adminParameters.promptsALLinOne.success"), { variant: "success" }),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const errors = {
    [AIPromptTypeEnum.PROFILE_SEARCH_KEYWORD]: {
      model: getFieldError(getSearchKeyWords?.model, FieldValidationType.REQUIRED_STRING),
      systemContent: getFieldError(getSearchKeyWords?.systemContent, FieldValidationType.REQUIRED_STRING),
      userContent: getFieldError(getSearchKeyWords?.userContent, FieldValidationType.REQUIRED_STRING),
    },
    [AIPromptTypeEnum.SLOGAN]: {
      model: getFieldError(getAISlogans?.model, FieldValidationType.REQUIRED_STRING),
      systemContent: getFieldError(getAISlogans?.systemContent, FieldValidationType.REQUIRED_STRING),
      userContent: getFieldError(getAISlogans?.userContent, FieldValidationType.REQUIRED_STRING),
    },
    [AIPromptTypeEnum.HASHTAG]: {
      model: getFieldError(getAIHashtags?.model, FieldValidationType.REQUIRED_STRING),
      systemContent: getFieldError(getAIHashtags?.systemContent, FieldValidationType.REQUIRED_STRING),
      userContent: getFieldError(getAIHashtags?.userContent, FieldValidationType.REQUIRED_STRING),
    },
    [AIPromptTypeEnum.LINKEDIN_POST]: {
      model: getFieldError(getAILinkedinPost?.model, FieldValidationType.REQUIRED_STRING),
      systemContent: getFieldError(getAILinkedinPost?.systemContent, FieldValidationType.REQUIRED_STRING),
      userContent: getFieldError(getAILinkedinPost?.userContent, FieldValidationType.REQUIRED_STRING),
    },
  };

  return (
    <Stack className="scrollable" overflow="auto" py="15px">
      {prompts.map((prompt) => (
        <PromptsCard key={prompt.id}>
          <Stack mt="29px">
            <AIOTextfieldComponent
              placeholder={t("adminParameters.promptsALLinOne.modelExample")}
              title={t("adminParameters.promptsALLinOne.modelChatGPT")}
              onChange={(value) => updatePrompt("model", prompt.type)(value)}
              value={prompt.model ?? ""}
              error={errors[prompt.type].model}
            />
          </Stack>
          <Stack mt="20px">
            <AIOTextfieldComponent
              multiline
              minRows={2}
              title={t(`adminParameters.promptsALLinOne.labels.${prompt.type}.systemContent`)}
              placeholder={t(`adminParameters.promptsALLinOne.labels.${prompt.type}.systemContent`)}
              value={prompt.systemContent ?? ""}
              onChange={(value) => updatePrompt("systemContent", prompt.type)(value)}
              error={errors[prompt.type].systemContent}
            />
          </Stack>
          <Stack mt="20px">
            <AIOTextfieldComponent
              multiline
              minRows={4}
              title={t(`adminParameters.promptsALLinOne.labels.${prompt.type}.userContent`)}
              placeholder={t(`adminParameters.promptsALLinOne.labels.${prompt.type}.userContent`)}
              value={prompt.userContent ?? ""}
              onChange={(value) => updatePrompt("userContent", prompt.type)(value)}
              error={errors[prompt.type].userContent}
            />
          </Stack>
          <Stack mt="20px" spacing="5px">
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {t("adminParameters.promptsALLinOne.variables")}
            </Typography>
            <VariablesComponent variables={AIPromptTypeFunctions.variables[prompt.type]} />
          </Stack>
          <Stack m="20px 0 30px 0" alignSelf="center">
            <AIOButtonComponent
              disabled={checkIfErrors(errors[prompt.type]) || loading}
              onClick={() => handleSave(prompt)}
              title={t("global.save")}
              variant="contained"
            />
          </Stack>
        </PromptsCard>
      ))}
    </Stack>
  );
};

export default AdminPromptsComponent;
