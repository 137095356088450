import { catchError, from, map, Observable } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";

export class UnipileService {
  getUnipileHostedAuthURL = (currentRoute: string): Observable<string> => {
    return from(APIAxios({
      ...APIRoutes.POSTUnipileHostedAuthURL(),
      data: { currentRoute },
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<string>) => {
        return response.data;
      })
    );
  };
}

export const unipileService = new UnipileService();
