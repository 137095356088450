import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { Colors } from "@constants/colors.constant";
import styled from "@emotion/styled";
import { alpha, CircularProgress, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { PersonaeQuiz, PersonaeQuizAnswer, PersonaeQuizCategory } from "@store/personae";
import { useTranslation } from "react-i18next";

const ContainerStyled = styled(Stack)({
  height: "358px",
  overflow: "hidden",
  backgroundColor: Colors.greyCardBg,
  padding: "10px 30px",
  borderRadius: "15px !important",
});

const Row = styled(Stack)({
  borderBottom: `1px solid ${alpha(Colors.greyLight, 0.4)}`,
  width: "100%",
  margin: "0px !important",
  "& input": {
    padding: "12px 0px !important",
  },
});
interface PersonaeQuizCardProps {
  setManageAnswer: (manageAnswer: { answer?: PersonaeQuizAnswer; category: PersonaeQuizCategory; isCreation: boolean }) => void;
  category: PersonaeQuizCategory;
  personaeQuiz?: PersonaeQuiz;
  loading: boolean;
}

const PersonaeQuizCard = (props: PersonaeQuizCardProps) => {
  const { category, loading, personaeQuiz, setManageAnswer } = props;

  const { t } = useTranslation();

  return (
    <ContainerStyled>
      <Stack height="100%" pt="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Typography textAlign="center" fontWeight="700" fontSize="20px">
            {PersonaeQuizCategory.label(category)}
          </Typography>
          <AIOButtonComponent
            color="primary"
            title={t("global.add")}
            onClick={() => setManageAnswer({ category, answer: undefined, isCreation: true })}
          />
        </Stack>
        {loading ? (
          <Stack alignItems="center" justifyContent="center" height="calc(100% - 30px)">
            <CircularProgress size={40} />
          </Stack>
        ) : (
          <Stack spacing={2} overflow="auto" className="scrollable" py="20px" pr="20px" alignItems="center">
            {personaeQuiz?.[PersonaeQuizCategory.fieldKey(category)]?.map((answer) => (
              <Row key={answer.id}>
                <AIOTextfieldComponent
                  fullWidth
                  readonly
                  multiline
                  value={answer.name}
                  endAdornment={
                    <Stack sx={{ mr: "-5px" }} direction="row" alignItems="center" spacing={0.25}>
                      {!!answer.affiliates?.length && (
                        <Tooltip
                          arrow
                          title={
                            <Stack spacing={0.25} minWidth="150px">
                              <Typography fontSize={12} fontWeight="500">
                                {t("adminParameters.personae.affiliates")}
                              </Typography>
                              {answer.affiliates?.map((a) => (
                                <Typography key={a.id} fontSize={12} fontWeight="300">
                                  &#x2022; {a.name}
                                </Typography>
                              ))}
                            </Stack>
                          }>
                          <Stack
                            height="18px"
                            width="18px"
                            border={`1px solid ${Colors.primary}`}
                            borderRadius="50%"
                            alignItems="center"
                            justifyContent="center">
                            <Typography color="primary" textAlign="center" fontWeight="500">
                              {answer.affiliates.length}
                            </Typography>
                          </Stack>
                        </Tooltip>
                      )}
                      <IconButton onClick={() => setManageAnswer({ category, answer, isCreation: false })}>
                        <img alt="edit answer" src="/images/edit.svg" />
                      </IconButton>
                    </Stack>
                  }
                />
              </Row>
            ))}
          </Stack>
        )}
      </Stack>
    </ContainerStyled>
  );
};

export default PersonaeQuizCard;
