import { useState } from "react";
import { Box, Button, Checkbox, FormControlLabel, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { useParams } from "react-router-dom";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { AddSubscription, affiliateDetailsService } from "@store/entities/afiliates/details";

import { Colors } from "@constants/colors.constant";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import dayjsUtils from "@utils/dayjs.utils";

import ModalComponent from "@components/modal/Modal.component";
import DatePickerComponent from "@components/input/DatePicker.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import SelectCompanySubscriptionAdsModal
  from "@screens/auth/admin/entities/components/subscription/modal/SelectCompanySubscriptionAds.modal";
import AdCard from "@components/card/Ad.card";

const StyledFormControlLabel = styled(FormControlLabel)({
  margin: "0px !important",
  "& .MuiButtonBase-root": {
    color: Colors.primary,
  },
});


interface AddCompanySubscriptionModalProps {
  handleClose: () => void;
}

const AddCompanySubscriptionModal = (props: AddCompanySubscriptionModalProps) => {
  const { handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { entityId } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<AddSubscription>>({});

  const [selectConcernedAdsModalOpen, setSelectConcernedAdsModalOpen] = useState(false);

  const updateData = (name: string) => (value: any) => setData((state) => ({...state, [name]: value}));

  const errors = {
    startDate: getFieldError(data.startDate, FieldValidationType.REQUIRED_DATE)
      ?? (dayjsUtils(data.endDate).diff(data.startDate, 'month', true) < 1 ? t("errors.minimum1Month") : undefined),
    endDate: getFieldError(data.endDate, FieldValidationType.REQUIRED_DATE)
      ?? (dayjsUtils(data.endDate).diff(data.startDate, 'month', true) < 1 ? t("errors.minimum1Month") : undefined),
    credits: !data.unlimited ? getFieldError(data.credits, FieldValidationType.REQUIRED_NUMBER) : undefined,
  };

  const handleValidate = () => {
    if (!entityId) return;
    setLoading(true);
    affiliateDetailsService.addAffiliateSubscription(entityId, data)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("subscriptions.addCompanySubscription.success"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("subscriptions.addCompanySubscription.title")}
      titleLeft
      content={
        <Stack py="10px" spacing="30px">
          <Box>
            <Grid container spacing="20px" alignItems="center">
              <Grid item xs={6}>
                <DatePickerComponent
                  error={errors.startDate}
                  required
                  title={t("subscriptions.addCompanySubscription.startDate")}
                  placeholder={t("global.select")}
                  color="secondary"
                  handleChange={updateData('startDate')}
                  value={data.startDate} />
              </Grid>
              <Grid item xs={6}>
                <DatePickerComponent
                  error={errors.endDate}
                  required
                  title={t("subscriptions.addCompanySubscription.endDate")}
                  placeholder={t("global.select")}
                  color="secondary"
                  handleChange={updateData('endDate')}
                  value={data.endDate} />
              </Grid>
              <Grid item xs={6}>
                <AIOTextfieldComponent
                  required
                  error={errors.credits}
                  title={t("subscriptions.addCompanySubscription.adsNbr")}
                  placeholder={t("subscriptions.addCompanySubscription.adsNbr")}
                  onChange={(amount) => updateData('credits')(amount ? +amount : undefined)}
                  value={data.unlimited ? t("subscriptions.unlimitedOffer") : data.credits?.toString() || ""}
                  endAdornment={(
                    <StyledFormControlLabel
                      onChange={(evt, checked) => {
                        setData((state) => ({
                          ...state,
                          unlimited: checked,
                          credits: undefined,
                        }));
                      }}
                      control={<Checkbox size="small" />}
                      checked={data.unlimited ?? false}
                      label={(
                        <Typography ml="5px" mt="1px" fontSize="12px" noWrap fontWeight={300} lineHeight={1.25}>
                          {t("subscriptions.unlimitedOffer")}
                        </Typography>
                      )} />
                  )}
                  pattern={new RegExp(/^\d*$/)}
                  readonly={data.unlimited}
                  fullWidth />
              </Grid>
            </Grid>
          </Box>
          {!data.unlimited && (
            <Stack spacing="18px" width="100%">
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography fontSize="16px" fontWeight={700}>
                  {t("subscriptions.addCompanySubscription.concernedAds")}
                </Typography>
                <Button
                  variant="text"
                  color="primary"
                  sx={{p: '5px 10px'}}
                  disabled={!data.credits || data.credits <= 0}
                  onClick={() => setSelectConcernedAdsModalOpen(true)}>
                  <Typography fontWeight={500}>{t("subscriptions.addCompanySubscription.addAds")}</Typography>
                </Button>
              </Stack>
              <Box width={"100%"}>
                <Grid container spacing={3}>
                  {(data.ads ?? []).map((ad) => (
                    <Grid item xs={12} sm={6} key={ad.id}>
                      <Stack direction="row" spacing="10px" alignItems="center">
                        <IconButton
                          onClick={() => {
                            setData((state) => ({
                              ...state,
                              ads: (state.ads ?? []).filter((x) => ad.id !== x.id),
                            }))
                          }}>
                          <img src="/images/icon_remove.svg" alt="" />
                        </IconButton>
                        <AdCard ad={ad}/>
                      </Stack>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Stack>
          )}
          {selectConcernedAdsModalOpen && !data.unlimited && !!data.credits && data.credits > 0 && (
            <SelectCompanySubscriptionAdsModal
              handleClose={() => setSelectConcernedAdsModalOpen(false)}
              handleValidate={updateData("ads")}
              concernedAds={data.ads ?? []}
              credits={data.credits ?? 0}
            />
          )}
        </Stack>
      }
      actions={(
        <AIOButtonComponent
          ariaLabel="modal"
          color="secondary"
          variant="contained"
          onClick={handleValidate}
          disabled={checkIfErrors(errors) || loading}
          title={t("global.add")}/>
      )}
      maxWidth="md"
    />
  );
};

export default AddCompanySubscriptionModal;