import { catchError, from, map, Observable, switchMap, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";

import { PaginatedData } from "@utils/infinitescroll.utils";

import { affiliateDetailsStore } from "@store/entities/afiliates/details/affiliateDetails.store";
import {
  getAffiliateCommentsDataSource,
  getAffiliateDetailsDataSource,
  getAffiliateDetailsToolboxToolsDataSource
} from "@store/entities/afiliates/details/affiliateDetails.requests";
import {
  AddSubscription,
  AffiliateDetailsDetailsModel,
  AffiliateToolboxTool,
  ManageAffiliateDetailsModel,
  manageAffiliateDetailsToFormData,
  ManageAffiliateParametersModel,
  NewToolboxToolDocument,
  newToolboxToolToFormData
} from "@store/entities/afiliates/details/affiliateDetails.model";
import { AffiliateMailType } from "@store/common/mail.model";
import { BudgetLimitationPeriodEnum } from "@store/common/budgetLimitation.model";
import { UserModel, UsersSortField } from "@store/users";
import { affiliatesService } from "@store/entities/afiliates";
import { ToolboxToolRequest } from "@store/toolbox";
import { EntityTimeline } from "@store/entities/entities.model";
import { sessionService } from "@store/session";
import { MentionItem } from "react-mentions";
import { Comment } from "@store/common/comments.model";

export class AffiliateDetailsService {
  store = affiliateDetailsStore;

  resetStore = () => this.store.reset();

  setDetails = (details: AffiliateDetailsDetailsModel) =>
    this.store.update((state) => ({
      ...state,
      details,
    }));

  getAffiliateDetails = (affiliateId: string | number): Observable<AffiliateDetailsDetailsModel> => {
    return from(APIAxios(APIRoutes.GETAffiliateDetails(affiliateId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        this.store.update(
          (state) => ({
            ...state,
            details,
          }),
          getAffiliateDetailsDataSource.setSuccess()
        );
      }),
      getAffiliateDetailsDataSource.trackRequestStatus()
    );
  };

  setAffiliateFavorite = (affiliateId: string, favorite: boolean): Observable<AffiliateDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTAffiliateFavorite(affiliateId),
        data: { favorite },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        affiliatesService.setFavorite(affiliateId, favorite);
        this.store.update((state) => ({
          ...state,
          details,
        }));
      })
    );
  };

  createAffiliate = (affiliate: ManageAffiliateDetailsModel, clientId?: string | number): Observable<AffiliateDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTAffiliate(),
        data: manageAffiliateDetailsToFormData(affiliate, clientId),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  sendMailToAffiliate = (affiliateId: number | string, mailType: AffiliateMailType, mediaName?: string): Observable<AxiosResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTAffiliateMail(affiliateId),
        data: { mailType, mediaName },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  updateAffiliateDetails = (affiliateId: string | number, affiliate: ManageAffiliateDetailsModel): Observable<AffiliateDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAffiliateDetails(affiliateId),
        data: manageAffiliateDetailsToFormData(affiliate),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  updateAffiliateTimeline = (affiliateId: string | number, timeline: EntityTimeline): Observable<AffiliateDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAffiliateDetails(affiliateId),
        data: {
          timeline: {
            actions: timeline.actions,
            fileKey: timeline.fileKey,
            filename: timeline.filename,
          },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  getAffiliateTimelineImage = (affiliateId: string | number): Observable<string | null> => {
    return from(APIAxios(APIRoutes.GETAffiliateTimelineImage(affiliateId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<string | null>) => {
        return response.data;
      })
    );
  };

  getAffiliatePlanMediaFile = (affiliateId: string | number): Observable<string | null> => {
    return from(APIAxios(APIRoutes.GETAffiliatePlanMediaFile(affiliateId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<string | null>) => {
        return response.data;
      })
    );
  };

  validateAffiliateTimelineAction = (
    affiliateId: string | number,
    actionName: string,
    emails?: string[]
  ): Observable<AffiliateDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTValidateActionTimeline(affiliateId),
        data: {
          actionName,
          emails,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  updateAffiliateParameters = (
    affiliateId: string | number,
    parameters: ManageAffiliateParametersModel
  ): Observable<AffiliateDetailsDetailsModel> => {
    const formData = new FormData();

    if (parameters.ats && parameters.atsActivated) {
      formData.append("ats", JSON.stringify({ ...parameters.ats, type: parameters.ats.type?.value }));
    }

    if (parameters.googleAnalytics && parameters.googleAnalyticsActivated) {
      formData.append("googleAnalytics", JSON.stringify(parameters.googleAnalytics));
    }

    if (parameters.sellsy && parameters.sellsyActivated) {
      formData.append("sellsy", JSON.stringify(parameters.sellsy));
    }

    if (parameters.budgetLimitation && parameters.budgetLimitationActivated) {
      const budgetLimitation =
        parameters.budgetLimitation.amount !== undefined
          ? {
              ...parameters.budgetLimitation,
              from:
                parameters.budgetLimitation.to && parameters.budgetLimitation.duration !== BudgetLimitationPeriodEnum.MONTH ? new Date() : undefined,
              to:
                parameters.budgetLimitation.to && parameters.budgetLimitation.duration !== BudgetLimitationPeriodEnum.MONTH
                  ? parameters.budgetLimitation.to
                  : undefined,
            }
          : null;
      formData.append("budgetLimitation", JSON.stringify(budgetLimitation));
    }

    if (parameters.prefilteredJobBoards && parameters.prefilteredJobBoardsActivated) {
      formData.append("prefilteredJobBoardIds", JSON.stringify(parameters.prefilteredJobBoards.map((j) => j.value)));
    }

    if (parameters.powerBI && parameters.powerBIActivated) {
      formData.append("powerBI", JSON.stringify(parameters.powerBI));
    }

    if (parameters.boostCredits && parameters.boostCreditsActivated) {
      const boostCredits = parameters.boostCredits
        .filter((bc) => !!bc.type && !!bc.boostItem && !!bc.credits)
        .map((bc) => ({
          type: bc.type!.value,
          boostItemId: bc.boostItem!.value,
          credits: bc.credits,
          creditsWaitingForValidation: bc.creditsWaitingForValidation,
        }));
      formData.append("boostCredits", JSON.stringify(boostCredits));
    }

    if (parameters.planMedia && parameters.planMediaActivated) {
      if (parameters.planMedia.file) {
        formData.append("planMediaFile", parameters.planMedia.file);
      }
      formData.append('planMedia', JSON.stringify({
        fileKey: parameters.planMedia.fileKey,
        fileName: parameters.planMedia.fileName,
        generateTimelineAutomatically: parameters.planMedia.generateTimelineAutomatically,
        customPrompt: parameters.planMedia.customPrompt,
      }))
    }

    return from(
      APIAxios({
        ...APIRoutes.PUTAffiliateDetails(affiliateId),
        data: formData,
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  getAffiliateUsers = (affiliateId: string | number): Observable<UserModel[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETUsers(),
        params: {
          affiliateIds: [affiliateId],
          sort: UsersSortField.LASTNAME,
          order: 1,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UserModel[]>) => {
        return response.data;
      })
    );
  };

  getAffiliateHistory = (affiliateId: string | number, page: number = 1, take: number = 24): Observable<PaginatedData<any[]>> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAffiliateHistory(affiliateId),
        params: { page, take },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<any[]>>) => {
        return response.data ?? [];
      }),
      tap((actions) => {
        this.store.update((state) => ({
          ...state,
          actions: state.actions
            ? [...state.actions, ...actions.data.filter((action) => !state.actions?.some((a) => a.id === action.id))]
            : actions.data,
          actionsPaginatedMeta: actions.meta,
        }));
      })
    );
  };

  refreshAts = (affiliateId: string | number): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.POSTAffiliateRefreshATS(affiliateId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  setAIOFlux = (affiliateId: string | number, companyId: string, companyName: string): Observable<AxiosResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAffiliateSetAIOFlux(affiliateId),
        data: { companyId, companyName },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  importPowerBIFile = (affiliateId: string | number, powerBi: File): Observable<AxiosResponse> => {
    const formData = new FormData();
    formData.append("xlsx", powerBi);

    return from(
      APIAxios({
        ...APIRoutes.POSTAffiliatePowerBI(affiliateId),
        data: formData,
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  getAffiliateToolboxTools = (affiliateId: string): Observable<AffiliateToolboxTool[]> => {
    return from(APIAxios(APIRoutes.GETAffiliateToolboxTools(affiliateId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateToolboxTool[]>) => {
        return response.data;
      }),
      tap((affiliateToolboxTools) => {
        this.store.update(
          (state) => ({
            ...state,
            affiliateToolboxTools,
          }),
          getAffiliateDetailsToolboxToolsDataSource.setSuccess()
        );
      }),

      getAffiliateDetailsToolboxToolsDataSource.trackRequestStatus()
    );
  };

  requestToolboxTool = (affiliateId: string, requestedTool: ToolboxToolRequest): Observable<AffiliateToolboxTool> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTRequestToolboxTool(affiliateId),
        data: {
          ...requestedTool,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateToolboxTool>) => {
        return response.data;
      }),
      tap((affiliateToolboxTool) => {
        this.store.update((state) => ({
          ...state,
          affiliateToolboxTools: [...(state.affiliateToolboxTools ?? []), affiliateToolboxTool],
        }));
      })
    );
  };

  deleteToolboxTool = (toolboxToolId: string, documentKey: string): Observable<AffiliateToolboxTool> => {
    return from(
      APIAxios({
        ...APIRoutes.DELETEToolboxTool(),
        data: { toolboxToolId, documentKey },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateToolboxTool>) => {
        return response.data;
      }),
      tap(() => {
        this.store.update((state) => ({
          ...state,
          affiliateToolboxTools: state.affiliateToolboxTools?.filter((tool) => tool.id !== toolboxToolId),
        }));
      })
    );
  };

  addToolboxColumn = (affiliateId: string, columnName: string): Observable<AffiliateDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAddToolboxColumn(affiliateId),
        data: { columnName },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        this.store.update((state) => ({
          ...state,
          details,
        }));
      })
    );
  };

  deleteToolboxColumn = (affiliateId: string, columnId: string, columnName: string): Observable<AffiliateDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.DELETEToolboxColumn(affiliateId),
        data: {
          columnName,
          columnId,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        this.store.update((state) => ({
          ...state,
          details,
          affiliateToolboxTools: state.affiliateToolboxTools?.filter((tool) => tool.column.id !== columnId),
        }));
      })
    );
  };

  uploadToolboxTools = (newDocuments: NewToolboxToolDocument[], clientId: string): Observable<AffiliateToolboxTool[]> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTUploadTooboxDoucument(clientId),
        data: newToolboxToolToFormData(newDocuments),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateToolboxTool[]>) => {
        return response.data;
      }),
      tap((affiliateToolboxTools) => {
        this.store.update((state) => ({
          ...state,
          affiliateToolboxTools: [
            ...(state.affiliateToolboxTools?.filter((t) => !affiliateToolboxTools.some((ct) => ct.id === t.id)) ?? []),
            ...affiliateToolboxTools,
          ],
        }));
      })
    );
  };

  incDownloadToolboxTool = (toolboxToolId: string): Observable<AffiliateToolboxTool> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTIncDownloadToolboxDocument(),
        data: { toolboxToolId },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateToolboxTool>) => {
        return response.data;
      }),
      tap((affiliateToolboxTool) => {
        this.store.update((state) => ({
          ...state,
          affiliateToolboxTools: state.affiliateToolboxTools?.map((t) => (t.id === affiliateToolboxTool.id ? affiliateToolboxTool : t)),
        }));
      })
    );
  };

  addAffiliateSubscription = (affiliateId: number | string, data: Partial<AddSubscription>): Observable<AffiliateDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTAffiliateSubscription(affiliateId),
        data: {
          ...data,
          unlimited: data.unlimited ?? false,
          ads: data.ads?.map((a) => a.id),
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  addAffiliateSubscriptionAds = (
    affiliateId: number | string,
    ads: string[],
    subscriptionIndex: number
  ): Observable<AffiliateDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHAddAffilateSubscriptionAds(affiliateId),
        data: {
          ads,
          subscriptionIndex,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      switchMap((details) => sessionService.fetchCurrentUser().pipe(map(() => details)))
    );
  };

  getAffiliateComments = (affiliateId: string | number): Observable<Comment[]> => {
    return from(APIAxios(APIRoutes.GETAffiliateComments(affiliateId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment[]>) => {
        return response.data;
      }),
      tap((comments) => {
        this.store.update(
          (state) => ({
            ...state,
            comments,
          }),
          getAffiliateCommentsDataSource.setSuccess()
        );
      }),
      getAffiliateCommentsDataSource.trackRequestStatus()
    );
  };

  addComment = (affiliateId: number | string, content: string, mentions: MentionItem[] = []): Observable<Comment> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTAffiliateComment(affiliateId),
        data: { content, mentions },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment>) => {
        return response.data;
      }),
      tap((comment) => {
        this.store.update((state) => ({
          ...state,
          comments: [comment, ...(state.comments || [])],
        }));
      })
    );
  };

  updateComment = (clientId: number | string, commentId: string, content: string, mentions: MentionItem[] = []): Observable<Comment> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAffiliateComment(clientId),
        data: { commentId, content, mentions },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment>) => {
        return response.data;
      }),
      tap((updatedComment) => {
        this.store.update((state) => {
          const updatedComments = state.comments?.map((comment) => (comment.id === updatedComment.id ? updatedComment : comment));
          return {
            ...state,
            comments: updatedComments ?? [],
          };
        });
      })
    );
  };

  generateTimelineWithAi = (affiliateId: string | number): Observable<AffiliateDetailsDetailsModel> => {
    return from(APIAxios(APIRoutes.POSTGenerateTimelineWithAi(affiliateId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getAffiliateDetails(affiliateId))
    );
  };
}

export const affiliateDetailsService = new AffiliateDetailsService();
