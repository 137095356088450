import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";

import { PersonaeFilters, PersonaeSort } from "@store/personae/personae.model";
import { personaeService } from "@store/personae/personae.service";

interface SearchPersonaeParams {
  filters?: PersonaeFilters;
  sort?: PersonaeSort;
}

export const searchPersonaeEffect = createEffectFn((params$: Observable<SearchPersonaeParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ filters, sort }) => personaeService.getAllPersonae(filters, sort))
  );
});
