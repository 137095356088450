import { select } from "@ngneat/elf";
import { bannersStore } from "@store/banners/banners.store";
import { getBannersDataSource } from "@store/banners/banners.requests";

export class BannersQuery {
  store = bannersStore;

  banners$ = getBannersDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));

  get filters() {
    return this.store.getValue().filters;
  }
}

export const bannersQuery = new BannersQuery();
