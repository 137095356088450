import { alpha, Components } from "@mui/material";
import { Colors } from "@constants/colors.constant";

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    'company-value': true;
    diffusion: true;
  }
}

const chip: Components = {
  MuiChip: {
    styleOverrides: {
      label: (props) => ({
        ...(props.ownerState.variant === "diffusion" && props.ownerState.color === "primary" ? {
          color: Colors.secondary,
          padding: 0,
          fontSize: "14px",
          fontWeight: 500,
        } : {}),
        ...(props.ownerState.variant === "diffusion" && props.ownerState.color === "secondary" ? {
          color: Colors.white,
          padding: 0,
          fontSize: "14px",
          fontWeight: 500,
        } : {}),
        ...(props.ownerState.variant === "company-value" ? {
          color: Colors.primary,
          fontSize: "14px",
          fontWeight: 500,
        } : {}),
      }),
      root: (props) => ({
        ...(props.ownerState.variant === "diffusion" && props.ownerState.color === "primary" ? {
          width: "fit-content",
          borderRadius: "7px",
          padding: "9px 16px",
          backgroundColor: alpha(Colors.marine, 0.1),
        } : {}),
        ...(props.ownerState.variant === "diffusion" && props.ownerState.color === "secondary" ? {
          width: "fit-content",
          borderRadius: "7px",
          padding: "9px 16px",
          backgroundColor: alpha(Colors.white, 0.1),
        } : {}),
        ...(props.ownerState.variant === "company-value" ? {
          backgroundColor: alpha(Colors.primary, 0.1),
          borderRadius: "7px",
          padding: "9px 16px",
          width: "fit-content",
        } : {})
      }),
    },
  },
};

export default chip;