import { clientsStore } from "@store/entities/clients/clients.store";
import { getClientsDataSource } from "@store/entities/clients/clients.requests";
import { selectPaginationData } from "@ngneat/elf-pagination";

export class ClientsQuery {
  store = clientsStore;

  clients$ = getClientsDataSource.data$();
  clientsPaginationData$ = this.store.pipe(selectPaginationData());
}

export const clientsQuery = new ClientsQuery();
