import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

import ModalComponent from "@components/modal/Modal.component";
import { Colors } from "@constants/colors.constant";

interface PersonaeHelpModalProps {
  handleClose: () => void;
}

const PersonaeHelpModal = (props: PersonaeHelpModalProps) => {
  const { handleClose } = props;

  const { t } = useTranslation();

  return (
    <ModalComponent
      handleClose={handleClose}
      icon={<img alt="check" src="/images/icon_illus_question.svg" style={{ height: 50, width: 50 }} />}
      title={t("personae.help.title")}
      content={(
        <Stack py="15px" pb="25px" px="15px">
          <Typography
            textAlign="center"
            style={{ wordBreak: "break-word" }}
            lineHeight="1.25"
            color={Colors.secondaryText}
            fontWeight={300}>
            {t("personae.help.description")}
          </Typography>
        </Stack>
      )}
      maxWidth="xs"
      paddingButton="10px 0px 40px 0px"
    />
  );
};

export default PersonaeHelpModal;
