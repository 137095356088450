import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useDropzone } from "react-dropzone";

import { Colors } from "@constants/colors.constant";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import LaptopIcon from "@mui/icons-material/Laptop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import PreviewImageComponent from "@components/PreviewImage.component";
import { MediaSizeFormatEnum, companyDetailsService } from "@store/ai-o/companies/details";
import { finalize } from "rxjs";
import { NoMaxWidthTooltip } from "./Media.block";

interface AddMediasModalProps {
  companyId: string;
  handleClose: () => void;
  type: "image" | "video";
}

const AddMediasModal = (props: AddMediasModalProps) => {
  const { companyId, handleClose, type } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [previewImages, setPreviewImages] = useState<{ id: string; src: string; file: File; sizeFormat: MediaSizeFormatEnum }[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFiles((state) => [...state, ...acceptedFiles.filter((f) => !state.find((mf) => mf.name + mf.size === f.name + f.size))]);
  }, []);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    multiple: true,
    accept:
      type === "image"
        ? {
            "image/*": [],
          }
        : {
            "video/mp4": [],
            "video/x-m4v": [],
            "video/*": [],
          },
  });

  const getSizeFormat = useCallback((dimensions: { width: number; height: number }) => {
    const thresholdFormat = 0.08;
    console.log("Math.abs(dimensions.width / dimensions.height - 1 / 1) : ", Math.abs(dimensions.width / dimensions.height - 1 / 1));
    return Math.abs(dimensions.width / dimensions.height - 1 / 1) < thresholdFormat
      ? MediaSizeFormatEnum.MOBILE
      : Math.abs(dimensions.width / dimensions.height - 16 / 9) < thresholdFormat
      ? MediaSizeFormatEnum.TABLET
      : Math.abs(dimensions.width / dimensions.height - 5 / 6) < thresholdFormat
      ? MediaSizeFormatEnum.DESKTOP
      : MediaSizeFormatEnum.UNKNOWN;
  }, []);

  useEffect(() => {
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        if (e.target?.result && typeof e.target?.result === "string") {
          const img = new Image();
          img.onload = () => {
            const width = img.width;
            const height = img.height;
            console.log("width : ", width);
            console.log("height : ", height);
            const sizeFormat = getSizeFormat({ width, height });
            URL.revokeObjectURL(img.src);
            setPreviewImages((state) => [
              ...state.filter((img) => img.file.name !== file.name),
              {
                id: Math.random().toString(),
                src: e.target?.result as string,
                file,
                sizeFormat,
              },
            ]);
          };
          img.src = e.target?.result as string;
        }
      };
      reader.readAsDataURL(file);
    });
  }, [files, getSizeFormat]);

  const handleRemoveFile = (file: File) => {
    setPreviewImages((state) => state.filter((p) => p.file !== file));
    setFiles((state) => state.filter((f) => f !== file));
  };

  const handleAdd = () => {
    setLoading(true);
    companyDetailsService
      .updateMediasCompany(companyId, type, files)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t(`aio.clients.details.${type}.success`), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <ModalComponent
      title={t(`aio.clients.details.${type}.addTitle`)}
      titleLeft
      maxWidth="xs"
      content={
        <Stack spacing={2} alignItems="center" p="10px">
          {!!previewImages.length && (
            <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" py="10px">
              <Stack direction="row" spacing={1} alignItems="center">
                <NoMaxWidthTooltip
                  title={
                    <Typography sx={{ whiteSpace: "nowrap" }} fontSize="14px">
                      {t("aio.clients.details.image.tooltips.mobileImageSize")}
                    </Typography>
                  }
                  placement="top">
                  <PhoneIphoneIcon />
                </NoMaxWidthTooltip>
                {previewImages.some((img) => img.sizeFormat === MediaSizeFormatEnum.MOBILE) ? (
                  <CheckCircleIcon style={{ color: "green", fontSize: "20px" }} />
                ) : (
                  <Tooltip
                    title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.noMobileImageSize")}</Typography>}
                    placement="top">
                    <CancelIcon style={{ color: "red", fontSize: "20px" }} />
                  </Tooltip>
                )}
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <NoMaxWidthTooltip
                  title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.tabletImageSize")}</Typography>}
                  placement="top">
                  <TabletMacIcon />
                </NoMaxWidthTooltip>
                {previewImages.some((img) => img.sizeFormat === MediaSizeFormatEnum.TABLET) ? (
                  <CheckCircleIcon style={{ color: "green", fontSize: "20px" }} />
                ) : (
                  <NoMaxWidthTooltip
                    title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.noTabletImageSize")}</Typography>}
                    placement="top">
                    <CancelIcon style={{ color: "red", fontSize: "20px" }} />
                  </NoMaxWidthTooltip>
                )}
              </Stack>
              <Stack direction="row" spacing={1} alignItems="center">
                <NoMaxWidthTooltip
                  title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.desktopImageSize")}</Typography>}
                  placement="top">
                  <LaptopIcon />
                </NoMaxWidthTooltip>
                {previewImages.some((img) => img.sizeFormat === MediaSizeFormatEnum.DESKTOP) ? (
                  <CheckCircleIcon style={{ color: "green", fontSize: "20px" }} />
                ) : (
                  <NoMaxWidthTooltip
                    title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.noDesktopImageSize")}</Typography>}
                    placement="top">
                    <CancelIcon style={{ color: "red", fontSize: "20px" }} />
                  </NoMaxWidthTooltip>
                )}
              </Stack>
            </Stack>
          )}
          <div {...getRootProps()} style={{ width: "100%" }}>
            <Button
              component="label"
              sx={{
                padding: "30px",
                backgroundColor: isDragActive ? Colors.lightBlue : Colors.lightPrimary,
                border: isDragActive ? "solid 1px transparent" : "dashed 1px",
                width: "100%",
              }}
              onClick={open}>
              <Stack spacing={2} alignItems="center">
                <Typography color={Colors.primary}>{t("global.importFile")}</Typography>
                <img width="20px" alt="upload" src="/images/download_media.svg" />
              </Stack>
            </Button>
            <input {...getInputProps()} hidden type="file" multiple />
          </div>

          {!!previewImages.length && (
            <Grid container spacing={"20px"} marginLeft="-20px !important" width="100%">
              {previewImages.map((img) => (
                <PreviewImageComponent
                  key={img.id}
                  img={img}
                  handleRemoveFile={handleRemoveFile}
                  icon={
                    img.sizeFormat === MediaSizeFormatEnum.MOBILE ? (
                      <Stack sx={{ background: Colors.white, borderRadius: "50%", padding: "4px" }}>
                        <PhoneIphoneIcon sx={{ fontSize: "16px" }} />
                      </Stack>
                    ) : img.sizeFormat === MediaSizeFormatEnum.TABLET ? (
                      <Stack sx={{ background: Colors.white, borderRadius: "50%", padding: "4px" }}>
                        <TabletMacIcon sx={{ fontSize: "16px" }} />
                      </Stack>
                    ) : img.sizeFormat === MediaSizeFormatEnum.DESKTOP ? (
                      <Stack sx={{ background: Colors.white, borderRadius: "50%", padding: "4px" }}>
                        <LaptopIcon sx={{ fontSize: "16px" }} />
                      </Stack>
                    ) : undefined
                  }
                />
              ))}
            </Grid>
          )}
        </Stack>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          ariaLabel="modal"
          disabled={loading || !files.length}
          onClick={handleAdd}
          title={t("global.add")}
        />
      }
      handleClose={handleClose}
    />
  );
};

export default AddMediasModal;
