import I18nUtils from "@utils/i18n.utils";
import { SelectItem } from "@components/input/Select.component";
import { AffiliateLightModel } from "@store/entities/afiliates";

export enum PersonaeQuizCategory {
  JOB_CATEGORY = "JOB_CATEGORY",
  LEVEL_OF_STUDY = "LEVEL_OF_STUDY",
  STATUS = "STATUS",
  WORKING_SECTOR = "WORKING_SECTOR",
  COMPETENCES_TO_BE = "COMPETENCES_TO_BE",
  COMPETENCES_TO_DO = "COMPETENCES_TO_DO",
  OTHER_JOB = "OTHER_JOB",
  INTERESTS = "INTERESTS",
  LIFE_EVENT = "LIFE_EVENT",
}

export namespace PersonaeQuizCategory {
  export const categories: PersonaeQuizCategory[] = [
    PersonaeQuizCategory.JOB_CATEGORY,
    PersonaeQuizCategory.LEVEL_OF_STUDY,
    PersonaeQuizCategory.STATUS,
    PersonaeQuizCategory.WORKING_SECTOR,
    PersonaeQuizCategory.COMPETENCES_TO_BE,
    PersonaeQuizCategory.COMPETENCES_TO_DO,
    PersonaeQuizCategory.OTHER_JOB,
    PersonaeQuizCategory.INTERESTS,
    PersonaeQuizCategory.LIFE_EVENT
  ];

  export const singleSelectionCategories = [
    PersonaeQuizCategory.JOB_CATEGORY,
    PersonaeQuizCategory.LEVEL_OF_STUDY,
    PersonaeQuizCategory.STATUS
  ];

  export const label = (category: PersonaeQuizCategory) => {
    return I18nUtils.t(`personae.quizCategories.${category}`);
  };

  export const personaeLabel = (category: PersonaeQuizCategory) => {
    return I18nUtils.t(`personae.personaeCategories.${category}`);
  };

  export const question = (category: PersonaeQuizCategory) => {
    return I18nUtils.t(`personae.quizQuestions.${category}`);
  };

  export const fieldKey = (category: PersonaeQuizCategory) => {
    switch (category) {
      case PersonaeQuizCategory.JOB_CATEGORY:
        return "jobCategory";
      case PersonaeQuizCategory.LEVEL_OF_STUDY:
        return "levelOfStudy";
      case PersonaeQuizCategory.STATUS:
        return "status";
      case PersonaeQuizCategory.WORKING_SECTOR:
        return "workingSector";
      case PersonaeQuizCategory.COMPETENCES_TO_BE:
        return "competencesToBe";
      case PersonaeQuizCategory.COMPETENCES_TO_DO:
        return "competencesToDo";
      case PersonaeQuizCategory.OTHER_JOB:
        return "otherJob";
      case PersonaeQuizCategory.INTERESTS:
        return "interests";
      case PersonaeQuizCategory.LIFE_EVENT:
        return "lifeEvent";
      default:
        return "";
    }
  };

  export const categoryFromFieldKey = (fieldKey: string) => {
    switch (fieldKey) {
      case "jobCategory":
        return PersonaeQuizCategory.JOB_CATEGORY;
      case "levelOfStudy":
        return PersonaeQuizCategory.LEVEL_OF_STUDY;
      case "status":
        return PersonaeQuizCategory.STATUS;
      case "workingSector":
        return PersonaeQuizCategory.WORKING_SECTOR;
      case "competencesToBe":
        return PersonaeQuizCategory.COMPETENCES_TO_BE;
      case "competencesToDo":
        return PersonaeQuizCategory.COMPETENCES_TO_DO;
      case "otherJob":
        return PersonaeQuizCategory.OTHER_JOB;
      case "interests":
        return PersonaeQuizCategory.INTERESTS;
      case "lifeEvent":
        return PersonaeQuizCategory.LIFE_EVENT;
      default:
        return "";
    }
  };
}

export interface ManagePersonaeQuizAnswer extends Partial<Omit<PersonaeQuizAnswer, "affiliates">> {
  affiliates?: SelectItem[];
}

export namespace ManagePersonaeQuizAnswer {
  export const fromPersonaeQuizAnswer = (answer: PersonaeQuizAnswer) => ({
    ...answer,
    affiliates: answer.affiliates?.map((c) => ({ label: c.name, value: c.id }))
  });
}

export interface PersonaeQuizAnswer {
  id: string;
  name: string;
  type: PersonaeQuizCategory;
  affiliates: AffiliateLightModel[];
}

export interface PersonaeQuiz {
  [key: string]: PersonaeQuizAnswer[];
}