import React from "react";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";

import { globalStatisticsQuery } from "@store/globalStatistics";

import ModalComponent from "@components/modal/Modal.component";
import ActionsTimelineComponent from "@components/ActionsTimeline.component";
import { Box, useMediaQuery, useTheme } from "@mui/material";

interface LastActionsModalProps {
  handleClose: () => void;
  isInClient?: boolean;
}

const LastActionsModal = (props: LastActionsModalProps) => {
  const { handleClose, isInClient } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [lastActions] = useObservable(globalStatisticsQuery.lastActions$(isInClient));

  return (
    <ModalComponent
      maxWidth={breakpointDownSM ? "xs" : false}
      fullWidth={breakpointDownSM}
      handleClose={handleClose}
      titleLeft
      content={(
        <Box py="20px" className="scrollable" width={breakpointDownSM ? "100%" : "700px"}
             maxHeight="80vh" overflow="auto" px="10px">
          <ActionsTimelineComponent actions={lastActions} />
        </Box>
      )}
      title={t("dashboard.actions.lastActions", { count: (lastActions ?? []).length })} />
  );
};

export default LastActionsModal;