import { select } from "@ngneat/elf";
import { reportsStore } from "@store/reports/reports.store";
import { getReportsDataSource } from "@store/reports/reports.requests";

export class ReportsQuery {
  store = reportsStore;

  reports$ = getReportsDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));
}

export const reportsQuery = new ReportsQuery();