import { select } from "@ngneat/elf";
import { selectPaginationData } from "@ngneat/elf-pagination";

import { boostsStore } from "./boosts.store";
import { getBoostsDataSource } from "./boosts.requests";

export class BoostsQuery {
  store = boostsStore;

  boosts$ = getBoostsDataSource.data$();
  boostsPaginationData$ = this.store.pipe(selectPaginationData());

  filters$ = this.store.pipe(select((state) => state.filters));
}

export const boostsQuery = new BoostsQuery();