import { Box, Stack, Switch, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { ManageEntityParametersModel } from "@store/entities/details";
import {
  BudgetLimitationPeriodEnum,
  BudgetLimitationPeriodEnumFunctions,
  ManageBudgetLimitationModel
} from "@store/common/budgetLimitation.model";

import { Colors } from "@constants/colors.constant";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import DatePickerComponent from "@components/input/DatePicker.component";
import AIOSelectComponent from "@components/input/Select.component";
import { getAppCurrency } from "@store/common/country.model";

interface BudgetLimitationConfigComponentProps {
  budgetLimitation: ManageBudgetLimitationModel;
  isActivated?: boolean;
  updateParameters: Dispatch<SetStateAction<ManageEntityParametersModel>>;
}

const BudgetLimitationConfigComponent = (props: BudgetLimitationConfigComponentProps) => {
  const {budgetLimitation, isActivated = false, updateParameters} = props;

  const { t } = useTranslation();

  const updateBudgetLimitation = (name: string) => (value: any) => {
    updateParameters((state) => ({
      ...state,
      budgetLimitation: {
        ...state.budgetLimitation,
        [name]: value,
      },
    }))
  };

  return (
    <Stack width="100%" p="25px" pb="35px" bgcolor={Colors.background} borderRadius="15px" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
        <Typography fontSize={16} fontWeight="700">
          {t("clients.details.parametersTab.budgetLimitation.budgetLimitation")}
        </Typography>
        <Switch
          checked={isActivated}
          disabled={isActivated}
          onChange={(evt, checked) =>
            updateParameters((state) => ({...state, budgetLimitationActivated: checked}))
          }/>
      </Stack>
      {isActivated && (
        <Stack spacing={1}>
          <Stack alignItems="flex-end" spacing={2}>
            <Box width="100%">
              <AIOSelectComponent
                color="secondary"
                fullWidth
                items={BudgetLimitationPeriodEnumFunctions.selectItems}
                value={budgetLimitation.duration}
                handleChange={updateBudgetLimitation("duration")} />
            </Box>
            <Box width="100%">
              <AIOTextfieldComponent
                title={t("clients.details.parametersTab.budgetLimitation.budgetLabel")}
                placeholder={t("clients.details.parametersTab.budgetLimitation.budget")}
                startAdornment={(
                  <Typography fontSize="14px" fontWeight="500" mr="5px">{getAppCurrency()}</Typography>
                )}
                onChange={(amount) => updateBudgetLimitation("amount")(amount ? +amount : undefined)}
                value={budgetLimitation.amount?.toString() || ""}
                pattern={new RegExp(/^\d*$/)}
                color="secondary"
                fullWidth />
            </Box>
            {budgetLimitation.duration === BudgetLimitationPeriodEnum.CUSTOM && (
              <Box width="100%">
                <DatePickerComponent
                  title={t("clients.details.parametersTab.budgetLimitation.until")}
                  placeholder={t("global.select")}
                  color="secondary"
                  handleChange={updateBudgetLimitation("to")}
                  value={budgetLimitation.to} />
              </Box>
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default BudgetLimitationConfigComponent;
