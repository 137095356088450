import { useEffectFn } from "@ngneat/effects-hooks";
import { useObservable } from "@ngneat/react-rxjs";
import { useCallback, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { emitOnce } from "@ngneat/elf";
import { entitiesFiltersQuery, entitiesFiltersService, searchEntitiesEffect } from "./entitiesFilters";
import { clientsQuery, clientsService } from "./clients";
import { affiliatesQuery, affiliatesService } from "./afiliates";
import { EntityTypeEnum } from "./entities.model";
import { RELOAD_CLIENTS_AND_AFFILIATES } from "@constants/events.constant";

const usePaginatedEntitiesStore = (entityType: EntityTypeEnum) => {
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;

  const searchEntities = useEffectFn(searchEntitiesEffect);

  const [{ clients, error: clientsError, loading: clientsLoading }] = useObservable(clientsQuery.clients$);
  const [{ affiliates, error: affiliatesError, loading: affiliateLoading }] = useObservable(affiliatesQuery.affiliates$);

  const entities = entityType === EntityTypeEnum.CLIENT ? clients : affiliates;
  const error = entityType === EntityTypeEnum.CLIENT ? clientsError : affiliatesError;
  const loading = entityType === EntityTypeEnum.CLIENT ? clientsLoading : affiliateLoading;

  const [clientPaginationData] = useObservable(clientsQuery.clientsPaginationData$);
  const [affiliatePaginationData] = useObservable(affiliatesQuery.affiliatesPaginationData$);

  const paginationData = entityType === EntityTypeEnum.CLIENT ? clientPaginationData : affiliatePaginationData;

  const [filters] = useObservable(entitiesFiltersQuery.filters$);
  const [sort] = useObservable(entitiesFiltersQuery.sort$);

  const getEntities = useCallback(() => {
    searchEntities({ filters, sort, page: currentPage, take: itemsPerPage });
  }, [filters, currentPage, searchEntities, itemsPerPage, sort]);

  useEffect(() => {
    emitOnce(() => {
      affiliatesService.deleteEntities();
      affiliatesService.deleteAllPages();
      clientsService.deleteEntities();
      clientsService.deleteAllPages();
    });
    setCurrentPage(1);
  }, [entityType, filters, sort]);

  useEffect(() => {
    emitOnce(() => {
      entitiesFiltersService.resetStore();
      if (entityType) entitiesFiltersService.setFilters({type: entityType});
    });
  }, [entityType]);

  useEffect(() => {
    searchEntities({ filters, sort, page: currentPage, take: itemsPerPage });
  }, [filters, searchEntities, currentPage, itemsPerPage, sort, entityType]);

  useEffect(() => {
    if (error) enqueueSnackbar((error as any).text, (error as any).options);
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    window.addEventListener(RELOAD_CLIENTS_AND_AFFILIATES, getEntities);

    getEntities();

    return () => {
      window.removeEventListener(RELOAD_CLIENTS_AND_AFFILIATES, getEntities);
    };
  }, [getEntities]);

  const handleNextPage = useCallback(() => {
    if (paginationData.lastPage >= paginationData.currentPage) {
      return setCurrentPage(paginationData.currentPage + 1);
    }
  }, [paginationData.lastPage, paginationData.currentPage]);

  return {
    entities,
    entitiesPaginationData: paginationData,
    filters,
    handleNextPage,
    itemsPerPage,
    loading,
    take: paginationData.perPage,
  };
};

export default usePaginatedEntitiesStore;
