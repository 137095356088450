import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { closestCenter, DndContext, DragEndEvent, PointerSensor, TouchSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

import { ActionTimeline, EntityTypeEnum } from "@store/entities/entities.model";
import { AffiliateDetailsDetailsModel, affiliateDetailsService } from "@store/entities/afiliates/details";
import { EntityDetailsModel, entityDetailsService, entityToManageEntity } from "@store/entities/details";

import { Colors } from "@constants/colors.constant";

import SortableActionRow from "@screens/auth/admin/entities/components/timeline/SortableActionRow.component";
import AddOrEditAction from "@screens/auth/admin/entities/components/timeline/AddOrEditAction.modal";
import ConfirmModal from "@components/modal/Confirm.modal";

interface ActionTimelineComponentProps {
  affiliate: AffiliateDetailsDetailsModel;
}

const ActionTimelineComponent = (props: ActionTimelineComponentProps) => {
  const { affiliate } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { entityId } = useParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const [actions, setActions] = useState<ActionTimeline[]>([]);
  const [actionToEdit, setActionToEdit] = useState<ActionTimeline | boolean>(false);

  const [openConfirmResetActions, setOpenConfirmResetActions] = useState(false);

  const [loadingTimelineImg, setLoadingTimelineImg] = useState(false);
  const [loadingResetActions, setLoadingResetActions] = useState(false);

  const sensors = useSensors(
    useSensor(isMobile ? TouchSensor : PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  useEffect(() => {
    if (affiliate) {
      setActions(affiliate.timeline.actions);
    }
  }, [affiliate]);

  const handleDragEnd = (event: DragEndEvent) => {
    const active = event.active;
    const over = event.over;
    if (active && over && active.id !== over.id) {
      let tempActions = [...actions];
      const activeIndex = tempActions.findIndex((action) => action.name === active.id);
      const overIndex = tempActions.findIndex((action) => action.name === over.id);
      let actionsDayNumbers = tempActions.map((action) => action.j);
      tempActions = arrayMove(tempActions, activeIndex, overIndex).map((action, index) => {
        return { ...action, j: actionsDayNumbers[index] };
      });
      tempActions = tempActions.map((a) => ({
        name: a.name,
        j: a.j,
        strategy: a.strategy,
        isPassed: a.isPassed,
        emailTemplateName: a.emailTemplateName,
      }));
      setActions(tempActions);
      updateTimeline(tempActions);
    }
  };

  const updateTimeline = (actions: ActionTimeline[]) => {
    if (!entityId) return;
    affiliateDetailsService
      .updateAffiliateTimeline(entityId, { actions, fileKey: affiliate.timeline?.fileKey, filename: affiliate.timeline?.filename })
      .subscribe({ error: (err) => enqueueSnackbar(err.text, err.options) });
  };

  const handleDeleteAction = (actionTitleToDelete: string) => {
    let tempActions = [...actions];
    tempActions = tempActions.filter((action) => action.name !== actionTitleToDelete);
    setActions(tempActions);
    updateTimeline(tempActions);
  };

  const handleDeleteTimelineImg = () => {
    if (!entityId) return;
    setLoadingTimelineImg(true);
    affiliateDetailsService
      .updateAffiliateTimeline(entityId, { actions: actions, fileKey: undefined, filename: undefined })
      .pipe(finalize(() => setLoadingTimelineImg(false)))
      .subscribe({
        next: () => enqueueSnackbar(t("subscriptions.deleteTimelineImgSuccess"), { variant: "success" }),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!!files?.length && !!entityId) {
      setLoadingTimelineImg(true);
      entityDetailsService
        .updateEntityDetails(EntityTypeEnum.AFFILIATE, entityId, { ...entityToManageEntity(affiliate as EntityDetailsModel), timelineFile: files[0] })
        .pipe(finalize(() => setLoadingTimelineImg(false)))
        .subscribe({
          next: () => enqueueSnackbar(t("subscriptions.sendTimelineImgSuccess"), { variant: "success" }),
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
      e.target.value = "";
    }
  };

  const handleConfirmResetActions = () => {
    if (!entityId) return;
    setLoadingResetActions(true);
    affiliateDetailsService
      .updateAffiliateTimeline(entityId, {
        actions: actions.map((a) => ({ ...a, isPassed: false })),
        fileKey: affiliate.timeline?.fileKey,
        filename: affiliate.timeline?.filename,
      })
      .pipe(finalize(() => setLoadingResetActions(false)))
      .subscribe({ error: (err) => enqueueSnackbar(err.text, err.options) });
  };

  return (
    <>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography fontSize="20px" fontWeight={700}>
            {t("subscriptions.timelineTitle")}
          </Typography>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Button variant="text" color="primary" onClick={() => setActionToEdit(true)}>
              <Typography fontWeight={500}>{t("subscriptions.addAction")}</Typography>
            </Button>
            {loadingResetActions ? (
              <CircularProgress size={20} />
            ) : (
              <Button variant="text" color="primary" onClick={() => setOpenConfirmResetActions(true)}>
                <Typography fontWeight={500}>{t("subscriptions.resetActions")}</Typography>
              </Button>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" p="26px" bgcolor={Colors.greyCardBg} borderRadius="10px">
          <Typography fontSize="16px" fontWeight={700}>
            {t("subscriptions.timeline")}
          </Typography>
          {!!affiliate.timeline?.fileKey && !!affiliate.timeline.filename ? (
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack direction="row" alignItems="center" spacing="4px">
                <InsertDriveFileOutlinedIcon fontSize="small" />
                <Typography fontSize="14px" fontWeight={100}>
                  {affiliate.timeline.filename}
                </Typography>
              </Stack>
              <IconButton
                onClick={() => {
                  if (!!affiliate.timeline?.fileKey) handleDeleteTimelineImg();
                }}>
                <img height="23px" src="/images/icon_delete.svg" alt="delete" />
              </IconButton>
            </Stack>
          ) : (
            <>
              <input ref={inputRef} hidden onChange={handleSelectFile} accept="image/jpeg,image/webp,image/png" type="file" />
              {loadingTimelineImg ? (
                <CircularProgress />
              ) : (
                <Button onClick={() => inputRef.current?.click()}>
                  <Typography fontSize="14px" fontWeight={500} color="primary">
                    {t("subscriptions.joinFile")}
                  </Typography>
                </Button>
              )}
            </>
          )}
        </Stack>
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <Stack spacing={2}>
            {actions && (
              <SortableContext items={actions.map((a) => ({ ...a, id: a.name }))} strategy={verticalListSortingStrategy}>
                {actions.map((action) => (
                  <SortableActionRow
                    key={action.name + "draggable"}
                    handleDeleteAction={handleDeleteAction}
                    handleEditAction={() => setActionToEdit(action)}
                    action={action}
                  />
                ))}
              </SortableContext>
            )}
          </Stack>
        </DndContext>
      </Stack>
      {!!actionToEdit && (
        <AddOrEditAction
          setActions={setActions}
          actions={actions}
          updateTimeline={updateTimeline}
          actionToEdit={actionToEdit}
          handleCloseModal={() => setActionToEdit(false)}
        />
      )}
      {openConfirmResetActions && (
        <ConfirmModal
          handleClose={() => setOpenConfirmResetActions(false)}
          handleConfirm={handleConfirmResetActions}
          confirmMsg={t("subscriptions.resetActionsConfirm")}
          modalTitle={t("subscriptions.resetActions")}
        />
      )}
    </>
  );
};

export default ActionTimelineComponent;
