import { Elements } from "@stripe/react-stripe-js";
import { Appearance, StripeElementsOptions } from "@stripe/stripe-js";
import { alpha, CircularProgress, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { stripeService } from "@store/stripe/stripe.service";

import { Colors } from "@constants/colors.constant";

import ModalComponent from "./Modal.component";
import StripeCheckoutForm from "@components/StripeCheckout.form";
import { getStripeElementsLocale } from "@store/common/country.model";
import { StripePaymentIntentSecret } from "@store/stripe/stripe.model";

interface StripeModalProps {
  stripePaymentIntendSecret: StripePaymentIntentSecret;
  handleClose: () => void;
  returnUrl?: string;
  title: string;
}

const StripeModal = (props: StripeModalProps) => {
  const { stripePaymentIntendSecret, handleClose, returnUrl, title } = props;

  const { t } = useTranslation();

  const [hasError, setHasError] = useState<string | false>(false);

  const appearance: Appearance = {
    theme: "flat",
    variables: {
      fontFamily: '"YoutubeSans", sans-serif',
      colorBackground: Colors.background,
      colorPrimaryText: Colors.black,
      colorDanger: Colors.error,
    },
    rules: {
      ".Input": {
        borderRadius: "10px",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "1.475rem",
        padding: "8.5px 14px",
      },
      ".Input:focus": {
        outline: "none",
        boxShadow: "none",
      },
      ".Input:disabled, .Input--invalid:disabled": {
        color: Colors.grey,
      },
      ".Error": {
        fontSize: "11px",
        fontWeight: "400",
        margin: "8px 14px 0",
      },
      ".Tab": {
        color: alpha(Colors.black, 0.5),
        padding: "20px 12px",
        border: "none",
      },
      ".Tab:hover": {
        color: Colors.black,
        border: "none",
        boxShadow: "none",
      },
      ".Tab--selected, .Tab--selected:focus, .Tab--selected:hover": {
        border: `1px solid ${Colors.twinin}`,
        backgroundColor: Colors.white,
        boxShadow: "none",
        color: Colors.twinin,
      },
      ".Label": {
        color: Colors.secondaryText,
        fontSize: "12px",
        fontWeight: "500",
      },
      ".TermsText": {
        color: Colors.grey,
        fontSize: "14px",
        fontWeight: "400",
      },
    },
  };

  const options: StripeElementsOptions = { appearance, clientSecret: stripePaymentIntendSecret.clientSecret, locale: getStripeElementsLocale() };

  return (
    <ModalComponent
      maxWidth={false}
      fullWidth={false}
      title={!!hasError ? t("stripe.paymentError.title") : title}
      handleClose={handleClose}
      titleLeft={!hasError}
      icon={!!hasError ? <img alt="check" src="/images/illus_warning.svg" /> : undefined}
      content={
        !!hasError ? (
          <Stack px="50px" py="20px">
            <Typography textAlign="center" pb="25px" lineHeight="1.25" color={Colors.secondaryText} fontWeight={300}>
              {hasError || t("stripe.paymentError.description")}
            </Typography>
          </Stack>
        ) : (
          <Stack alignItems="center" p="20px">
            {!stripeService.stripePromise || !stripePaymentIntendSecret ? (
              <CircularProgress />
            ) : (
              <Elements options={options} stripe={stripeService.stripePromise}>
                <StripeCheckoutForm price={stripePaymentIntendSecret.amountDue} setHasError={setHasError} returnUrl={returnUrl} />
              </Elements>
            )}
          </Stack>
        )
      }
    />
  );
};

export default StripeModal;
