import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useObservable } from "@ngneat/react-rxjs";
import { Stack, Typography } from "@mui/material";
import { finalize, iif, of, switchMap } from "rxjs";

import { sessionQuery } from "@store/session";
import { AffiliateModel } from "@store/entities/afiliates";
import { subscriptions, SubscriptionTypeEnum } from "@store/subscriptions";
import { affiliateDetailsService, AffiliateSubscription } from "@store/entities/afiliates/details";
import { AdModel } from "@store/ads";

import { Colors } from "@constants/colors.constant";
import dayjsUtils from "@utils/dayjs.utils";

import { SelectItem } from "@components/input/Select.component";
import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import SeeSubscriptionsModal from "@components/subscriptions/modal/SeeSubscriptions.modal";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import CompanySubscriptionCard from "@components/card/subscriptions/CompanySubscription.card";
import SelectCompanySubscriptionAdsModal
  from "@screens/auth/admin/entities/components/subscription/modal/SelectCompanySubscriptionAds.modal";
import { AdDetailsDetailsModel, adDetailsService } from "@store/ads/details";

interface ConfyAdsModalProps {
  ad?: AdDetailsDetailsModel;
  handleClose: () => void;
  handleSuccess?: () => void;
}

const ConfyAdsModal = (props: ConfyAdsModalProps) => {
  const { ad, handleClose, handleSuccess } = props;

  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [openUnlimitedSubscription, setOpenUnlimitedSubscription] = useState(false);

  const [openNoSubscriptions, setOpenNoSubscriptions] = useState(false);
  const [openSeeSubscriptionsModal, setOpenSeeSubscriptionsModal] = useState(false);

  const [step, setStep] = useState(1);

  const [loading, setLoading] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = useState<SelectItem | undefined>();
  const [selectedSubscription, setSelectedSubscription] = useState<AffiliateSubscription | undefined>();

  useEffect(() => {
    const currentAffiliates: SelectItem[] = clientViewClient
      ? [{ value: clientViewClient.id, label: clientViewClient.name, data: { affiliate: clientViewClient } }]
      : sessionQuery.affiliatesItems.filter((a) => !ad || ad.affiliate.id === a.value);

    const hasNoCurrentSubscriptions = currentAffiliates.every(
      (a) =>
        !(a.data.affiliate as AffiliateModel).subscriptions?.length ||
        (a.data.affiliate as AffiliateModel).subscriptions.every((s) => !s.unlimited && (s.currentCredits ?? 0) <= 0)
    );

    if (hasNoCurrentSubscriptions) {
      setOpenNoSubscriptions(true);
      return;
    }

    if (currentAffiliates.length === 1) {
      const affiliateSubscriptions = (currentAffiliates[0].data.affiliate as AffiliateModel).subscriptions;

      if (affiliateSubscriptions.some((s) => s.unlimited)) {
        setOpenUnlimitedSubscription(true);
        return;
      }

      setSelectedAffiliate(currentAffiliates[0]);
    }
  }, [ad, clientViewClient]);

  useEffect(() => {
    const affiliateSubscriptions = ((selectedAffiliate?.data.affiliate as AffiliateModel)?.subscriptions ?? []).filter(
      (s) => dayjsUtils().isBetween(s.startDate, s.endDate, "d", "[]") && (s.unlimited || (s.currentCredits ?? 0) > 0)
    );

    if (affiliateSubscriptions.length === 1) {
      setSelectedSubscription(affiliateSubscriptions[0]);
    }
  }, [selectedAffiliate]);

  const handleValidate = (selectedAds: AdModel[] | AdDetailsDetailsModel[]) => {
    if (!selectedAffiliate || !selectedSubscription || !selectedAds.length) return;

    setLoading(true);
    affiliateDetailsService.addAffiliateSubscriptionAds(
        selectedAffiliate.value,
        selectedAds.map((x) => x.id),
        (selectedAffiliate.data.affiliate as AffiliateModel).subscriptions.indexOf(selectedSubscription)
      )
      .pipe(
        switchMap(() => iif(
          () => !!ad,
          adDetailsService.getAdDetails(ad!.id),
          of(undefined),
        )),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {
          enqueueSnackbar(t("ads.confy.success"), { variant: "success" });
          handleSuccess?.();
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  if (openNoSubscriptions) {
    return (
      <>
        <ModalComponent
          handleClose={handleClose}
          icon={<img alt="warning" src="/images/illus_warning.svg" />}
          title={t("ads.confy.warning.title")}
          content={
            <Stack px="50px" py="20px">
              <Typography
                textAlign="center"
                whiteSpace="pre-wrap"
                style={{ paddingBottom: "25px" }}
                lineHeight="1.25"
                color={Colors.secondaryText}
                fontWeight={300}>
                {t("ads.confy.warning.noSubscription")}
              </Typography>
            </Stack>
          }
          actions={
            <AIOButtonComponent
              title={t("subscriptions.seeSubscriptions")}
              variant="contained"
              ariaLabel="modal"
              onClick={() => setOpenSeeSubscriptionsModal(true)}
            />
          }
          maxWidth="xs"
        />
        {openSeeSubscriptionsModal && (
          <SeeSubscriptionsModal
            subscriptions={subscriptions.filter((s) => s.type === SubscriptionTypeEnum.COMPANY)}
            handleClose={() => setOpenSeeSubscriptionsModal(false)}
          />
        )}
      </>
    );
  }

  if (openUnlimitedSubscription) {
    return (
      <ModalComponent
        handleClose={handleClose}
        icon={<img alt="warning" src="/images/illus_warning.svg" />}
        title={t("ads.confy.warning.title")}
        content={
          <Stack px="50px" py="20px">
            <Typography
              textAlign="center"
              whiteSpace="pre-wrap"
              style={{ paddingBottom: "25px" }}
              lineHeight="1.25"
              color={Colors.secondaryText}
              fontWeight={300}>
              {t("ads.confy.warning.unlimitedSubscription")}
            </Typography>
          </Stack>
        }
        maxWidth="xs"
      />
    );
  }

  const affiliateSubscriptions = ((selectedAffiliate?.data.affiliate as AffiliateModel)?.subscriptions ?? []).filter(
    (s) => dayjsUtils().isBetween(s.startDate, s.endDate, "d", "[]") && (s.unlimited || (s.currentCredits ?? 0) > 0)
  );

  switch (step) {
    case 1:
      return (
        <ModalComponent
          maxWidth="sm"
          title={t("ads.confyAdsToTwinin")}
          titleLeft
          handleClose={handleClose}
          content={
            <Stack spacing="30px" py="10px">
              <Typography fontSize={14} fontWeight={500} color={Colors.greyLight} lineHeight={1.25} whiteSpace="pre-wrap">
                {t("ads.confy.descriptionSubscription")}
              </Typography>
              <Stack spacing="8px">
                <Typography fontSize="14px" fontWeight="500">
                  {t("ads.confy.selectAffiliate")}
                </Typography>
                <SelectWithSearchComponent
                  placeholder={t("ads.confy.selectAffiliate")}
                  value={selectedAffiliate ?? ""}
                  items={
                    clientViewClient
                      ? [{ value: clientViewClient.id, label: clientViewClient.name, data: { affiliate: clientViewClient } }]
                      : sessionQuery.affiliatesItems
                  }
                  handleChange={setSelectedAffiliate}
                  readOnly={!!clientViewClient || sessionQuery.affiliatesItems.length === 1 || !!ad}
                />
              </Stack>
              {selectedAffiliate && (
                <Stack spacing="8px">
                  <Typography fontSize="14px" fontWeight="500">
                    {t("ads.confy.selectSubscription")}
                  </Typography>
                  <Stack spacing={"10px"}>
                    {!affiliateSubscriptions.length && (
                      <Stack spacing="20px" pt="30px" pb="20px">
                        <img alt="warning" src="/images/illus_warning.svg" />
                        <Typography fontWeight={300} textAlign="center" lineHeight="1.25" color={Colors.secondaryText} whiteSpace="pre-wrap">
                          {t("ads.confy.warning.noSubscription")}
                        </Typography>
                      </Stack>
                    )}
                    {affiliateSubscriptions.some((s) => s.unlimited) ? (
                      <Stack spacing="20px" pt="30px" pb="20px">
                        <img alt="warning" src="/images/illus_warning.svg" />
                        <Typography fontWeight={300} textAlign="center" lineHeight="1.25" color={Colors.secondaryText} whiteSpace="pre-wrap">
                          {t("ads.confy.warning.unlimitedSubscription")}
                        </Typography>
                      </Stack>
                    ) : (
                      affiliateSubscriptions.map((subscription, index) => (
                        <CompanySubscriptionCard
                          key={`subscription - ${index + 1}`}
                          onClick={() => setSelectedSubscription((state) => (state === subscription ? undefined : subscription))}
                          selected={selectedSubscription === subscription}
                          affiliateSubscription={subscription}
                          subscription={subscriptions.find((s) => s.type === SubscriptionTypeEnum.COMPANY)}
                        />
                      ))
                    )}
                  </Stack>
                </Stack>
              )}
            </Stack>
          }
          actions={
            <AIOButtonComponent
              variant="contained"
              disabled={!selectedAffiliate || !selectedSubscription}
              onClick={() => {
                if (!selectedAffiliate || !selectedSubscription) return;
                if (ad) {
                  handleValidate([ad]);
                } else {
                  setStep(2);
                }
              }}
              title={t("global.validate")}
            />
          }
        />
      );
    case 2:
      return !!selectedAffiliate && !!selectedSubscription ? (
        <SelectCompanySubscriptionAdsModal
          disableConcernedAds
          disableButtons={loading}
          entityId={selectedAffiliate.value}
          handleClose={handleClose}
          handleValidate={handleValidate}
          subtitle={t("ads.confy.descriptionAds")}
          concernedAds={selectedSubscription.ads}
          credits={selectedSubscription.currentCredits ?? 0}
        />
      ) : null;
    default:
      return null;
  }
};

export default ConfyAdsModal;
