import { createRequestDataSource } from "@ngneat/elf-requests";
import { select } from "@ngneat/elf";
import { companyDetailsStore } from "./companyDetails.store";

export const getCompanyDetailsDetailsDataSource = createRequestDataSource({
  store: companyDetailsStore,
  data$: () => companyDetailsStore.pipe(select((state) => state.details)),
  requestKey: "getCompanyDetailsDetails",
  dataKey: "companyDetails",
});

export const getCompanyDetailsStatsDataSource = createRequestDataSource({
  store: companyDetailsStore,
  data$: () => companyDetailsStore.pipe(select((state) => state.stats)),
  requestKey: "getCompanyDetailsStats",
  dataKey: "companyStats",
});
