import { catchError, from, map, Observable, tap } from "rxjs";
import { ToolboxColumn } from "@store/toolbox/toolbox.model";
import { toolboxStore } from "@store/toolbox/toolbox.store";
import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { AxiosError, AxiosResponse } from "axios";
import { setEntities } from "@ngneat/elf-entities";

export class ToolboxService {
  store = toolboxStore;

  getToolboxes = (): Observable<ToolboxColumn[]> => {
    return from(APIAxios(APIRoutes.GETToolboxes())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ToolboxColumn[]>) => {
        return response.data;
      }),
      tap((toolbox) => this.store.update(setEntities(toolbox))),
    );
  };
}

export const toolboxService = new ToolboxService();
