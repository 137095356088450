import ModalComponent from "@components/modal/Modal.component";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import AIOButtonComponent from "@components/Button.component";
import { Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import AIODropZone from "@components/AIODropZone.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import { SelectItem } from "@components/input/Select.component";
import { jobBoardsService } from "@store/jobBoards";
import { Colors } from "@constants/colors.constant";
import { finalize } from "rxjs";
import { adDetailsService } from "@store/ads/details";
import { ApplicationsListEnum } from "@store/applications";

interface ToolRequestModalProps {
  handleClose: () => void;
  itemsName: ApplicationsListEnum;
  adId: string | undefined;
}

const UploadCvModal = (props: ToolRequestModalProps) => {
  const { handleClose, adId, itemsName } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [selectedJobBoard, setSelectedJobBoard] = useState<SelectItem>();
  const [jobBoards, setJobBoards] = useState<SelectItem[]>([]);

  useEffect(() => {
    jobBoardsService.getJobBoards().subscribe({
      next: (j) => setJobBoards(j.map((j) => ({ label: j.name, value: j.id }))),
      error: (err) => enqueueSnackbar(err.text, err.options)
    });
  }, [enqueueSnackbar]);

  const handleConfirm = (files: File[]) => {
    if (files?.length) {
      if (!adId) return;
      setLoading(true);
      adDetailsService.uploadAdApplications(adId, itemsName, Array.from(files), selectedJobBoard?.value)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("ads.details.applyTab.success.cvs"), { variant: "success" });
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options)
        });
    }
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("applications.uploadApplications")}
      titleLeft
      content={(
        <Stack mt="20px" spacing={2}>
          <Stack>
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {t("ads.details.applyTab.selectSource")}
            </Typography>
            <SelectWithSearchComponent
              placeholder={t("ads.details.applyTab.otherSource")}
              handleChange={setSelectedJobBoard}
              items={jobBoards}
              value={selectedJobBoard} />
          </Stack>
          <AIODropZone setFiles={setMyFiles} />
        </Stack>
      )}
      maxWidth="xs"
      actions={(
        <AIOButtonComponent
          title={t("global.confirm")}
          variant="contained"
          ariaLabel="modal"
          onClick={() => handleConfirm(myFiles)}
          disabled={loading} />
      )} />
  );
};

export default UploadCvModal;
