export enum MediaSizeFormatEnum {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
  UNKNOWN = 'unknown',
}

export interface ManageCompanySocialMedia {
  linkedin?: string;
  twitter?: string;
  youtube?: string;
  instagram?: string;
  tiktok?: string;
  facebook?: string;
}

export interface ManageCompanyInformation {
  location?: string;
  description?: string;
  creationDate?: Date;
  nbCollaborates?: number;
  turnover?: number;
  telework?: boolean;
  website?: string;
}

export interface CompanyStats {
  nbrJobs: number;
  nbrCv: number;
  nbrLetterCover: number;
  nbrCandidature: number;
}

export interface Media {
  id: string;
  type: "video" | "image";
  downloadUrl: string;
  fileName: string;
  sizeFormat: MediaSizeFormatEnum;
}

export interface CompanyDetailsDetailsModel {
  id: string;
  name: string;
  logo?: string;
  location: string;
  creationDate: Date;
  nbCollaborates?: number;
  turnover?: number;
  telework: boolean;
  description?: string;
  linkedin?: string;
  twitter?: string;
  youtube?: string;
  instagram?: string;
  tiktok?: string;
  facebook?: string;
  website?: string;
  pictureCoverURL?: string;
  affiliateId?: string;
  medias: Media[];
  youtubeVideos?: string[];
  nbEmployees?: number;
  lightInfos?: {
    info: string;
    description: string;
    id: string;
  }[];
  values?: {
    name: string;
    description?: string;
    icon: string;
    id: string;
  }[];
  testimonials?: {
    title: string;
    content: string;
    name: string;
    job: string;
    id: string;
  }[];
  theme?: {
    primary: string,
    secondary: string,
    tertiary: string,
  }
  banned?: boolean;
}

export interface CompanyDetailsModel {
  details?: CompanyDetailsDetailsModel;
  stats?: CompanyStats;
}
