import React, { useCallback, useMemo, useState } from "react";
import { Box, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { finalize, map, of, switchMap } from "rxjs";
import { useParams } from "react-router-dom";

import { jobBoardsQuery, jobBoardsService } from "@store/jobBoards";
import { boostsService } from "@store/boosts";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import AIOTabComponent from "@components/AIOTab.component";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import JobBoardsList from "./JobBoards.list";
import SequencesList from "./Sequences.list";

import { Colors } from "@constants/colors.constant";
import BoostActionsList from "./BoostActions.list";
import { adDetailsQuery } from "@store/ads/details";
import StripeModal from "@components/modal/Stripe.modal";
import { stripeService } from "@store/stripe/stripe.service";
import { AppCountryEnum, getAppCountry, getAppCurrency } from "@store/common/country.model";
import { StripePaymentIntentSecret } from "@store/stripe/stripe.model";
import { BoostItemEnum } from "@store/common/boostCredits.model";
import SchoolsList from "./Schools.list";

enum SelectionType {
  SEQUENCES,
  JOBBOARDS,
  BOOST_ACTIONS,
  SCHOOLS,
}

interface ManualBoostRequestModalProps {
  handleClose: () => void;
  handleSuccess: () => void;
  adIdFromProps?: string;
}

const ManualBoostRequestModal = (props: ManualBoostRequestModalProps) => {
  const { handleClose, handleSuccess, adIdFromProps } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { adId: adIdFromParams } = useParams();

  const adId = adIdFromProps || adIdFromParams;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);
  const [showStripe, setShowStripe] = useState<StripePaymentIntentSecret | false>(false);

  const [selectionType, setSelectionType] = useState<SelectionType>(SelectionType.SEQUENCES);

  const [selectedJobBoards] = useObservable(jobBoardsQuery.jobBoardSelected$);
  const [selectedSequences] = useObservable(jobBoardsQuery.sequencesSelected$);
  const [selectedBoostActions] = useObservable(jobBoardsQuery.boostActionsSelected$);
  const [selectedSchools] = useObservable(jobBoardsQuery.schoolsSelected$);

  const [ad] = useObservable(adDetailsQuery.adDetails$);

  const hasCreditForBoostItem = useCallback(
    (boostItemId: string) => {
      return !!ad?.affiliate.boostCredits.some((c) => c.boostItemId === boostItemId);
    },
    [ad]
  );

  const allSelectedItems = useMemo(
    () => [...selectedSequences, ...selectedJobBoards, ...selectedBoostActions, ...selectedSchools],
    [selectedSequences, selectedJobBoards, selectedBoostActions, selectedSchools]
  );
  const selectedItemsWithCredits = useMemo(
    () => allSelectedItems.filter((x) => hasCreditForBoostItem(x.id)),
    [allSelectedItems, hasCreditForBoostItem]
  );

  const jobBoardsTotal = useMemo(
    () => selectedJobBoards.filter((j) => j.price && j.price > 0 && !hasCreditForBoostItem(j.id)).reduce((prev, current) => prev + current.price, 0),
    [hasCreditForBoostItem, selectedJobBoards]
  );
  const sequencesTotal = useMemo(
    () => selectedSequences.filter((s) => s.price && s.price > 0 && !hasCreditForBoostItem(s.id)).reduce((prev, current) => prev + current.price, 0),
    [hasCreditForBoostItem, selectedSequences]
  );
  const boostActionsTotal = useMemo(
    () =>
      selectedBoostActions.filter((b) => b.price && b.price > 0 && !hasCreditForBoostItem(b.id)).reduce((prev, current) => prev + current.price, 0),
    [hasCreditForBoostItem, selectedBoostActions]
  );

  const schoolsTotal = useMemo(
    () => selectedSchools.filter((s) => s.price && s.price > 0 && !hasCreditForBoostItem(s.id)).reduce((prev, current) => prev + current.price, 0),
    [hasCreditForBoostItem, selectedSchools]
  );

  const handleValidate = () => {
    if (!adId) return;
    setLoading(true);

    if (!ad?.affiliate.hasSellsyId) {
      stripeService
        .getBoostRequestClientSecret(
          adId,
          selectedSequences.map((s) => s.id),
          selectedJobBoards.map((j) => j.id),
          selectedBoostActions.map((b) => b.id),
          selectedSchools.map((s) => s.id)
        )
        .pipe(
          switchMap((paymentIntent) => {
            if (paymentIntent) {
              return of(paymentIntent);
            } else {
              return boostsService
                .createManualBoostRequest(
                  adId,
                  selectedSequences.map((s) => s.id),
                  selectedJobBoards.map((j) => j.id),
                  selectedBoostActions.map((b) => b.id),
                  selectedSchools.map((s) => s.id)
                )
                .pipe(map(() => undefined));
            }
          }),
          finalize(() => setLoading(false))
        )
        .subscribe({
          next: (paymentIntent) => {
            if (paymentIntent) setShowStripe(paymentIntent);
            else handleSuccess();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      boostsService
        .createManualBoostRequest(
          adId,
          selectedSequences.map((s) => s.id),
          selectedJobBoards.map((j) => j.id),
          selectedBoostActions.map((b) => b.id),
          selectedSchools.map((s) => s.id)
        )
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => handleSuccess(),
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  };

  if (showStripe) {
    return (
      <StripeModal
        stripePaymentIntendSecret={showStripe}
        title={t("boostRequest.selection.myself.selection.manualBoostPayment")}
        handleClose={() => setShowStripe(false)}
      />
    );
  }

  return (
    <ModalComponent
      handleClose={handleClose}
      maxWidth="lg"
      customHeader
      titleLeft
      title={t("boostRequest.selection.myself.selection.title")}
      subtitle={t("boostRequest.selection.myself.selection.description")}
      icon={<img alt="" src="/images/icon_boost_rocket.svg" />}
      content={
        <Stack
          direction={breakpointDownMD ? "column-reverse" : "row"}
          height={breakpointDownMD ? "auto" : "55vh"}
          spacing={breakpointDownSM ? 3 : 6}
          mt={breakpointDownSM ? "10px" : "30px"}
          mb="10px">
          <Stack flex={1} height="100%">
            <Box>
              <Stack direction={breakpointDownSM ? "column" : "row"} alignItems="center" justifyContent="space-between">
                <AIOTabComponent
                  tabs={[
                    t("boostRequest.selection.myself.selection.tabs.sequences"),
                    t("boostRequest.selection.myself.selection.tabs.jobBoards"),
                    t("boostRequest.selection.myself.selection.tabs.boostActions"),
                    ...(getAppCountry() === AppCountryEnum.FR ? [t("boostRequest.selection.myself.selection.tabs.schools")] : []),
                  ]}
                  handleChange={setSelectionType}
                  value={selectionType}
                />
                <AIOSearchComponent
                  width={breakpointDownMD ? undefined : "300px"}
                  onChange={(search) => jobBoardsService.setFilters({ search })}
                  color="primary"
                />
              </Stack>
            </Box>
            <Box overflow="auto" className="scrollable" py="20px" flex={1} px="5px">
              {selectionType === SelectionType.SEQUENCES ? (
                <SequencesList boostCredits={ad?.affiliate.boostCredits.filter((x) => x.type === BoostItemEnum.SEQUENCE)} />
              ) : selectionType === SelectionType.JOBBOARDS ? (
                <JobBoardsList boostCredits={ad?.affiliate.boostCredits.filter((x) => x.type === BoostItemEnum.JOBBOARD)} />
              ) : selectionType === SelectionType.BOOST_ACTIONS ? (
                <BoostActionsList boostCredits={ad?.affiliate.boostCredits.filter((x) => x.type === BoostItemEnum.BOOST_ACTION)} />
              ) : (
                <SchoolsList boostCredits={ad?.affiliate.boostCredits.filter((x) => x.type === BoostItemEnum.SCHOOL)} />
              )}
            </Box>
          </Stack>
          <Stack width={breakpointDownMD ? "100%" : "300px"} spacing={2} overflow="auto">
            <Stack pt="30px" pl="30px" pr="20px" bgcolor={Colors.background} borderRadius="15px" flex={1} overflow="auto">
              <Typography fontSize={16} fontWeight="700">
                {t("boostRequest.selection.myself.selection.summary.title")}
              </Typography>
              <Box flex={1} overflow="auto" pr="10px" className="scrollable" py="20px">
                <Stack spacing={1.5}>
                  {selectedSequences.map((sequence) => (
                    <Box key={`selected ${sequence.id}`} p="14px" bgcolor={Colors.white} borderRadius="15px">
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
                        <IconButton size="small" onClick={() => jobBoardsService.selectJobBoardOrSequenceOrBoostAction(sequence.id)}>
                          <img alt="delete sequence" src="/images/icon_remove.svg" />
                        </IconButton>
                        <Typography fontSize={14} fontWeight="300" flex={1} lineHeight={1.25}>
                          {sequence.name}
                        </Typography>
                        <Typography fontSize={14} fontWeight="500">
                          {selectedItemsWithCredits.some((x) => x.id === sequence.id)
                            ? t("boostRequest.selection.myself.credits.amount", { count: 1 })
                            : `${sequence.price}${getAppCurrency()} ${t("global.exclTax")}`}
                        </Typography>
                      </Stack>
                    </Box>
                  ))}
                  {selectedJobBoards.map((jobBoard) => (
                    <Box key={`selected ${jobBoard.id}`} p="14px" bgcolor={Colors.white} borderRadius="15px">
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
                        <IconButton size="small" onClick={() => jobBoardsService.selectJobBoardOrSequenceOrBoostAction(jobBoard.id)}>
                          <img alt="delete jobboard" src="/images/icon_remove.svg" />
                        </IconButton>
                        <Typography fontSize={14} fontWeight="300" flex={1} lineHeight={1.25}>
                          {jobBoard.name}
                        </Typography>
                        <Typography fontSize={14} fontWeight="500">
                          {selectedItemsWithCredits.some((x) => x.id === jobBoard.id)
                            ? t("boostRequest.selection.myself.credits.amount", { count: 1 })
                            : `${jobBoard.price}${getAppCurrency()} ${t("global.exclTax")}`}
                        </Typography>
                      </Stack>
                    </Box>
                  ))}
                  {selectedBoostActions.map((boostAction) => (
                    <Box key={`selected ${boostAction.id}`} p="14px" bgcolor={Colors.white} borderRadius="15px">
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
                        <IconButton size="small" onClick={() => jobBoardsService.selectJobBoardOrSequenceOrBoostAction(boostAction.id)}>
                          <img alt="delete sequence" src="/images/icon_remove.svg" />
                        </IconButton>
                        <Typography fontSize={14} fontWeight="300" flex={1} lineHeight={1.25}>
                          {boostAction.name}
                        </Typography>
                        <Typography fontSize={14} fontWeight="500">
                          {selectedItemsWithCredits.some((x) => x.id === boostAction.id)
                            ? t("boostRequest.selection.myself.credits.amount", { count: 1 })
                            : `${boostAction.price}${getAppCurrency()} ${t("global.exclTax")}`}
                        </Typography>
                      </Stack>
                    </Box>
                  ))}
                  {selectedSchools.map((school) => (
                    <Box key={`selected ${school.id}`} p="14px" bgcolor={Colors.white} borderRadius="15px">
                      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
                        <IconButton size="small" onClick={() => jobBoardsService.selectJobBoardOrSequenceOrBoostAction(school.id)}>
                          <img alt="delete sequence" src="/images/icon_remove.svg" />
                        </IconButton>
                        <Typography fontSize={14} fontWeight="300" flex={1} lineHeight={1.25}>
                          {school.name}
                        </Typography>
                        <Typography fontSize={14} fontWeight="500">
                          {selectedItemsWithCredits.some((x) => x.id === school.id)
                            ? t("boostRequest.selection.myself.credits.amount", { count: 1 })
                            : `${school.price}${getAppCurrency()} ${t("global.exclTax")}`}
                        </Typography>
                      </Stack>
                    </Box>
                  ))}
                </Stack>
              </Box>
            </Stack>
            <Box height="50px" px="30px" bgcolor={Colors.background} borderRadius="15px">
              <Stack height="100%" direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
                <Typography fontSize={14} fontWeight="300">
                  {t("boostRequest.selection.myself.selection.summary.total")}
                </Typography>
                <Typography fontSize={14} fontWeight="500">
                  {(jobBoardsTotal + sequencesTotal + boostActionsTotal + schoolsTotal).toFixed(2)}
                  {getAppCurrency()} {t("global.exclTax")}
                </Typography>
              </Stack>
            </Box>
            {!!selectedItemsWithCredits.length && (
              <Box height="50px" px="30px" bgcolor={Colors.background} borderRadius="15px">
                <Stack height="100%" direction="row" alignItems="center" justifyContent="space-between" spacing={1.5}>
                  <Typography fontSize={14} fontWeight="300">
                    {t("boostRequest.selection.myself.selection.summary.totalCredits")}
                  </Typography>
                  <Typography fontSize={14} fontWeight="500">
                    {selectedItemsWithCredits.length}
                  </Typography>
                </Stack>
              </Box>
            )}
          </Stack>
        </Stack>
      }
      actions={
        <AIOButtonComponent
          title={t("boostRequest.selection.myself.selection.button")}
          variant="contained"
          ariaLabel="modal"
          disabled={loading || (!selectedJobBoards.length && !selectedSequences.length && !selectedBoostActions.length && !selectedSchools.length)}
          onClick={handleValidate}
        />
      }
    />
  );
};

export default ManualBoostRequestModal;
