import { select } from "@ngneat/elf";
import { selectAllEntitiesApply, UIEntitiesRef } from "@ngneat/elf-entities";
import { selectPaginationData } from "@ngneat/elf-pagination";

import { adsStore } from "@store/ads/ads.store";
import { getAdsDataSource, getAdsStatsDataSource, getPaginatedAdsDataSource } from "@store/ads/ads.requests";

export class AdsQuery {
  store = adsStore;

  stats$ = getAdsStatsDataSource.data$();

  ads$ = getAdsDataSource.data$();
  paginatedAds$ = getPaginatedAdsDataSource.data$();
  adsPaginationData$ = this.store.pipe(selectPaginationData());

  selectedAds$ = this.store.pipe(
    selectAllEntitiesApply({
      mapEntity: (e) => e.id,
      filterEntity: (e) => e.selected,
      ref: UIEntitiesRef,
    })
  );

  filters$ = this.store.pipe(select((state) => state.filters));
  sort$ = this.store.pipe(select((state) => state.sort));
}

export const adsQuery = new AdsQuery();
