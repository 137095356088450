import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { aiPromptsStore } from "./aiPrompts.store";

export const getAIPromptsDataSource = createRequestDataSource({
  store: aiPromptsStore,
  data$: () => aiPromptsStore.pipe(selectAllEntities()),
  requestKey: "getAIPrompts",
  dataKey: "aiPrompts",
});
