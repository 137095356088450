import { alpha, Avatar, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

import { sessionQuery, sessionService } from "@store/session";
import { EntityTypeEnum } from "@store/entities/entities.model";
import { entityDetailsQuery, entityDetailsService } from "@store/entities/details";
import { affiliateDetailsService } from "@store/entities/afiliates/details";
import { UserRoleEnum } from "@store/users";

import { Colors } from "@constants/colors.constant";
import { AffiliateRoutes, ClientRoutes, ClientTabRoutes, Pages } from "@utils/routes.utils";

import AIORoutesTabComponent from "@components/AIORoutesTab.component";
import EntityMenu from "@screens/auth/admin/entities/components/Entity.menu";
import { companyDetailsService, companytDetailsQuery } from "@store/ai-o/companies/details";

interface EntityDetailsProps {
  type: EntityTypeEnum;
}

const EntityDetails = (props: EntityDetailsProps) => {
  const { type } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { entityId } = useParams();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [{ entityDetails: entity }] = useObservable(entityDetailsQuery.details$(type));
  const [{ companyDetails: company }] = useObservable(companytDetailsQuery.details$);

  useEffect(() => {
    entityDetailsService.resetStore(type);

    if (!!entityId) {
      entityDetailsService.getEntityDetails(type, entityId).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }

    return () => {
      entityDetailsService.resetStore(type);
    };
  }, [enqueueSnackbar, entityId, type]);

  useEffect(() => {
    if (entity && type === EntityTypeEnum.AFFILIATE) {
      companyDetailsService.getReattachedCompany(entity.id).subscribe({
        error: (err) => undefined,
      });
    }
  }, [entity, enqueueSnackbar, type]);

  const switchToClientView = () => {
    if (!entity?.id || entity.type !== EntityTypeEnum.AFFILIATE) return;
    sessionService.switchToClientView(entity?.id).subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
      next: () => navigate(`/`),
    });
  };

  return (
    <Stack flex={1} justifyContent="flex-start" overflow="hidden">
      <Stack
        justifyContent="center"
        px={breakpointDownSM ? "10px" : "20px"}
        pt={"30px"}
        pb={breakpointDownSM ? "20px" : "30px"}
        bgcolor="white"
        sx={{
          backgroundImage: "linear-gradient(to left, rgba(232, 97, 77, 0.05) 0%, rgba(232, 97, 77, 0) 100%)",
          position: "relative",
        }}
        boxShadow={`0 0px 50px 0 ${alpha(Colors.black, 0.1)}`}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button
            variant="text"
            color="inherit"
            startIcon={<img alt="back" src="/images/arrow_back.svg" />}
            onClick={() => navigate(type === EntityTypeEnum.CLIENT ? `../${ClientTabRoutes.LIST}` : "../..")}>
            <Typography fontWeight="300" color="secondary">
              {t("clients.title")}
            </Typography>
          </Button>

          {entity?.type === EntityTypeEnum.AFFILIATE && sessionQuery.role !== UserRoleEnum.ADMIN && !sessionQuery.buyerMode && (
            <EntityMenu entity={entity} isInDetails />
          )}
          {sessionQuery.role === UserRoleEnum.ADMIN && !!entity && (
            <Stack direction="row" alignItems="center">
              <Button
                onClick={() => {
                  const affiliates = type === EntityTypeEnum.CLIENT ? entity?.affiliates : [entity!];
                  if (!!affiliates?.length) {
                    sessionService.setAffiliateIds(
                      affiliates.map((a) => ({
                        label: a.name,
                        value: a.id,
                      }))
                    );
                    navigate(`/${Pages.ADS}`);
                  }
                }}
                variant="text"
                color="primary"
                startIcon={<img alt="" src="/images/icon_doublearrow_red.svg" style={{ width: "15px" }} />}>
                <Typography>{t("clients.details.clientAds")}</Typography>
              </Button>
              {entity.type === EntityTypeEnum.AFFILIATE && (
                <>
                  <Button
                    onClick={switchToClientView}
                    variant="text"
                    color="primary"
                    startIcon={<img alt="" src="/images/button_customer_view.svg" />}>
                    <Typography>{t("clients.details.clientView")}</Typography>
                  </Button>
                  <Button
                    variant="text"
                    color={entity.isFavorite ? "primary" : "secondary"}
                    onClick={() => {
                      if (!entityId) return;
                      affiliateDetailsService.setAffiliateFavorite(entityId, !entity.isFavorite).subscribe({
                        error: (err) => enqueueSnackbar(err.text, err.options),
                      });
                    }}
                    startIcon={<img alt="" src={`/images/favorites_${entity.isFavorite ? "red" : "grey"}.svg`} />}>
                    <Typography>{t("clients.details.favorite")}</Typography>
                  </Button>
                </>
              )}
              <EntityMenu entity={entity} isInDetails />
            </Stack>
          )}
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2} alignSelf="center">
          <Avatar
            sizes="contains"
            sx={{
              border: "none",
              borderRadius: "10px !important",
              backgroundColor: entity?.picture ? Colors.white : alpha(Colors.lightpurple, 0.6),
              color: Colors.secondary,
              height: 44,
              width: 44,
            }}
            src={entity?.picture}>
            {entity?.name?.[0]}
          </Avatar>
          {!!entity && (
            <Typography textAlign="center" fontSize="25px" fontWeight={700}>
              {entity?.name}
            </Typography>
          )}
        </Stack>
        <Stack alignItems="center" pt={breakpointDownSM ? "10px" : "30px"}>
          <AIORoutesTabComponent
            variant={"standard"}
            routes={(type === EntityTypeEnum.CLIENT ? ClientRoutes : AffiliateRoutes).routes
              .slice(0, sessionQuery.role === UserRoleEnum.ADMIN ? undefined : type === EntityTypeEnum.CLIENT ? 1 : 2)
              .concat(company ? AffiliateRoutes.careerRoute : [])}
          />
        </Stack>
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default EntityDetails;
