import React, { Dispatch, SetStateAction, useState } from "react";
import { Stack, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { jobBoardsQuery } from "@store/jobBoards";
import { ManageEntityParametersModel } from "@store/entities/details";

import { Colors } from "@constants/colors.constant";

import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import { SelectItem } from "@components/input/Select.component";

interface JobBoardsConfigComponentProps {
  isActivated?: boolean;
  selectedJobBoards: SelectItem[];
  updateParameters: Dispatch<SetStateAction<ManageEntityParametersModel>>;
}

const JobBoardsConfigComponent = (props: JobBoardsConfigComponentProps) => {
  const {isActivated = false, selectedJobBoards, updateParameters} = props;

  const { t } = useTranslation();

  const [jobBoards] = useState<SelectItem[]>(jobBoardsQuery.jobBoards.map((j) => ({
      label: j.name,
      value: j.id,
    }))
  );

  return (
    <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
        <Typography fontSize={16} fontWeight="700">
          {t("clients.details.parametersTab.jobBoards.jobBoards")}
        </Typography>
        <Switch
          checked={isActivated}
          disabled={isActivated}
          onChange={(evt, checked) =>
            updateParameters((state) => ({...state, prefilteredJobBoardsActivated: checked}))
          }/>
      </Stack>
      {isActivated && (
        <Stack>
          <Typography fontSize="12px" fontWeight="500" color={Colors.secondaryText}>
            {t("clients.details.parametersTab.jobBoards.prefilter")}
          </Typography>
          <MultiSelectWithSearchComponent
            placeholder={t("clients.details.parametersTab.jobBoards.noFilter")}
            handleChange={(prefilteredJobBoards) => {
              updateParameters((state) => ({
                ...state,
                prefilteredJobBoards
              }));
            }}
            multiSelectedLabel={(count) => t("clients.details.parametersTab.jobBoards.jobBoardsNb", { count })}
            items={jobBoards}
            values={selectedJobBoards} />
        </Stack>
      )}
    </Stack>
  );
};

export default JobBoardsConfigComponent;
