import axios, { AxiosRequestConfig } from "axios";
import { sessionQuery, sessionService } from "@store/session";

const AIOAPIAxios = axios.create({
  baseURL: process.env.REACT_APP_AIO_API_URL || "",
});

AIOAPIAxios.interceptors.request.use(
  async (config) => {
    const token = sessionQuery.token;

    try {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      return config;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  (err) => Promise.reject(err)
);

AIOAPIAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error.response.data.message === "TOKEN_EXPIRED" ||
      error.response.data.message === "DISABLED_ACCOUNT" ||
      error.response.data.message === "USER_DOES_NOT_EXIST"
    ) {
      await sessionService.logout();
    }
    return Promise.reject(error);
  }
);

export const AIOAPIRoutes = {
  /* AI.O */

  /* PROMPT */
  GETPrompts: (): AxiosRequestConfig => ({ method: "GET", url: `/prompts/all` }),
  PUTPrompt: (promptId: string): AxiosRequestConfig => ({ method: "PUT", url: `/prompts/${promptId}/update-prompt` }),

  /* TONE TO USE */
  GETTones: (): AxiosRequestConfig => ({ method: "GET", url: `/tones-to-use/all` }),
  PUTTone: (toneId: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/tones-to-use/${toneId}/update-tone`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  POSTTone: (): AxiosRequestConfig => ({ method: "POST", url: `/tones-to-use`, headers: { "Content-Type": "multipart/form-data" } }),
  DELETETone: (toneId: string): AxiosRequestConfig => ({ method: "DELETE", url: `/tones-to-use/${toneId}/delete-tone` }),

  /* PERSONALITY */
  GETPersonalities: (): AxiosRequestConfig => ({ method: "GET", url: `/personality/personalities` }),
  PUTPersonality: (personalityId: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/personality/${personalityId}/update-personality`,
  }),
  POSTPersonality: (): AxiosRequestConfig => ({
    method: "POST",
    url: `/personality`,
  }),
  DELETEPersonality: (personalityId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/personality/${personalityId}/delete-personality`,
  }),
  PATCHPersonalityTitle: (): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/personality-title`,
  }),
  GETPersonalitiesTitle: (): AxiosRequestConfig => ({
    method: "GET",
    url: `/personality-title`,
  }),

  /* TEMPLATE */
  PUTTemplate: (templateId: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/template/${templateId}/update-name-template`,
  }),
  GETTemplates: (): AxiosRequestConfig => ({ method: "GET", url: `/template/all` }),

  /* COMPANY */
  GETCompanies: (): AxiosRequestConfig => ({ method: "GET", url: `/company/companies-lights-reattach` }),
  GETCompanyDetail: (companyId: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/company/${companyId}/company-detail`,
  }),
  GETReattachedCompanyDetail: (): AxiosRequestConfig => ({
    method: "GET",
    url: `/company/reattached-company-detail`,
  }),
  GETCompanyStats: (companyId: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/company/${companyId}/stats-company`,
  }),
  PATCHReattachCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/reattach`,
  }),
  PATCHBanCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/ban`,
  }),
  PATCHUnbanCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/unban`,
  }),
  PATCHInformationCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/information-company@update-company`,
  }),
  PATCHSocialMediaCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/social-media@update-company`,
  }),
  PATCHValueCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/value-company@update-company`,
  }),
  DELETEValueCompany: (companyId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/company/${companyId}/value-company@update-company`,
  }),
  PATCHCoverPictureCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/cover-picture@update-company`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  PATCHLogoCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/logo@update-company`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  PATCHMediasCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/media@update-company`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  DELETEMediaCompany: (companyId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/company/${companyId}/media@update-company`,
  }),
  POSTTestimonialCompany: (companyId: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/company/${companyId}/testimonial@update-company`,
  }),
  PATCHTestimonialCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/testimonial@update-company`,
  }),
  DELETETestimonialCompany: (companyId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/company/${companyId}/testimonial@update-company`,
  }),
  POSTLightInfoCompany: (companyId: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/company/${companyId}/light-info@update-company`,
  }),
  PATCHLightInfoCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/light-info@update-company`,
  }),
  DELETELightInfoCompany: (companyId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/company/${companyId}/light-info@update-company`,
  }),
  PATCHYoutubeVideoCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/youtube-video@update-company`,
  }),
  DELETEYoutubeVideoCompany: (companyId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/company/${companyId}/youtube-video@update-company`,
  }),
  PATCHColorThemeCompany: (companyId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/company/${companyId}/color-theme@update-company`,
  }),

  /* CANDIDATE */
  GETCandidates: (): AxiosRequestConfig => ({ method: "GET", url: `/candidate` }),
  GETNotReattachedCompaniesWithApplicationsCount: (): AxiosRequestConfig => ({
    method: "GET",
    url: "/company/not-reattach-company-with-candidate-count",
  }),
  GETNotReattachedCompaniesWithApplications: (): AxiosRequestConfig => ({
    method: "GET",
    url: "/company/not-reattach-company-with-candidate",
  }),
  PATCHReattachAnnouncement: (candidateId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/candidate/${candidateId}/reattach-announcement`,
  }),

  /* ------- */
};

export default AIOAPIAxios;
