import React, { useEffect, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { RegisterUserModel, registerUsersService } from "@store/registerUsers";
import { affiliatesService } from "@store/entities/afiliates";
import { clientsService } from "@store/entities/clients";

import { Colors } from "@constants/colors.constant";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import AsyncSelectWithSearchComponent from "@components/input/AsyncSelectWithSearch.component";
import { SelectItem } from "@components/input/Select.component";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import EntityModal from "../Entity.modal";
import { EntityTypeEnum } from "@store/entities/entities.model";

interface AcceptUserModalProps {
  handleClose: () => void;
  registerUser: RegisterUserModel;
}

const AcceptUserModal = (props: AcceptUserModalProps) => {
  const { handleClose, registerUser } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [addClient, setAddClient] = useState(false);
  const [affiliates, setAffiliates] = useState<SelectItem[]>([]);

  const [selectedClient, setSelectedClient] = useState<SelectItem | undefined>(
    registerUser.allInOneAffiliates?.[0]?.client
      ? { label: registerUser.allInOneAffiliates?.[0]?.client.name, value: registerUser.allInOneAffiliates?.[0]?.client.id }
      : undefined
  );
  const [selectedAffiliates, setSelectedAffiliates] = useState<SelectItem[]>(
    registerUser.allInOneAffiliates?.map((a) => ({ label: a.name, value: a.id })) ?? []
  );

  useEffect(() => {
    if (selectedClient) {
      affiliatesService.searchAffiliates(undefined, selectedClient.value).then(setAffiliates);
    } else setAffiliates([]);
  }, [selectedClient]);

  const handleAccept = () => {
    if (!selectedClient || !selectedAffiliates.length) return;

    setLoading(true);
    registerUsersService
      .acceptRegisterUser(
        registerUser.id,
        selectedClient.value,
        selectedAffiliates.map((a) => a.value)
      )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("usersValidation.accept.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <>
      <ModalComponent
        maxWidth="xs"
        titleLeft
        title={t("usersValidation.accept.title")}
        handleClose={handleClose}
        content={
          <Stack py="10px" spacing="20px">
            <Typography fontSize="14px" fontWeight="300" lineHeight={1.25} color={Colors.greyLight}>
              {t("usersValidation.accept.description")}
            </Typography>
            <Stack>
              <Typography fontSize="12px" color={Colors.secondaryText}>
                {t("usersValidation.accept.client")} *
              </Typography>
              <AsyncSelectWithSearchComponent
                placeholder={t("global.search")}
                searchIfEmpty
                handleChange={async (item) => {
                  setSelectedClient(item);
                  setSelectedAffiliates([]);
                }}
                value={selectedClient}
                getOptions={clientsService.searchClients}
                noResultComponent={
                  <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} margin="15px 15px">
                    <Typography fontSize="14px" color={Colors.primaryText} textAlign="center">
                      {t("usersValidation.accept.noResult")}
                    </Typography>
                    <Typography fontSize="14px" color={Colors.primaryText} textAlign="center" fontWeight="bold">
                      <span style={{ cursor: "pointer", color: Colors.primary }} onClick={() => setAddClient(true)}>
                        {t("usersValidation.accept.createClient")}
                      </span>
                    </Typography>
                  </Stack>
                }
              />
            </Stack>
            <Stack>
              <Typography fontSize="12px" color={Colors.secondaryText}>
                {t("usersValidation.accept.affiliates")} *
              </Typography>
              <MultiSelectWithSearchComponent
                disabled={!selectedClient}
                placeholder={t("global.select")}
                items={affiliates}
                handleChange={setSelectedAffiliates}
                values={selectedAffiliates}
              />
            </Stack>
          </Stack>
        }
        actions={
          <AIOButtonComponent
            title={t("global.accept")}
            ariaLabel="modal"
            disabled={loading || !selectedClient || !selectedAffiliates.length}
            onClick={handleAccept}
            variant="contained"
            color="secondary"
          />
        }
      />
      {addClient && (
        <EntityModal
          defaultEntity={{
            name: registerUser.missingCompanyName || registerUser.AI_OCompany?.name || '',
            isAlsoAffiliate: true }}
          isCreation
          forceIsAlsoAffiliate
          handleClose={() => setAddClient(false)}
          type={EntityTypeEnum.CLIENT}
          onCreation={(newEntity) => {
            setAddClient(false);
            setSelectedClient({label: newEntity.name, value: newEntity.id});
            if (!!newEntity.affiliates?.length) setSelectedAffiliates(newEntity.affiliates.map((a) => ({
              label: a.name,
              value: a.id,
            })))
          }}
        />
      )}
    </>
  );
};

export default AcceptUserModal;
