import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import TagManager from "react-gtm-module";

try {
  if (process.env.REACT_APP_GTM_ID) {
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GTM_ID || "",
    };

    TagManager.initialize(tagManagerArgs);
  }
} catch (e) {
  console.log("GTM error", e);
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
