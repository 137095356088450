import { Avatar, Stack } from "@mui/material";
import { Timeline, TimelineConnector, TimelineContent, timelineItemClasses, TimelineSeparator } from "@mui/lab";
import { Action } from "@store/common/actions.model";
import { InfiniteScrollContainer, PaginatedMetaData } from "@utils/infinitescroll.utils";
import HistoryCard from "./card/History.card";
import { Colors } from "@constants/colors.constant";

interface ActionsTimelineComponentProps {
  actions: Action[];
  setCurrentPage?: (value: React.SetStateAction<number>) => void;
  paginatedMetaData?: PaginatedMetaData;
  breakpointDownSM?: boolean;
}

const ActionsTimelineComponent = (props: ActionsTimelineComponentProps) => {
  const { actions, setCurrentPage, paginatedMetaData } = props;

  const handleNextPage = () => {
    if (paginatedMetaData && setCurrentPage && paginatedMetaData.pageCount >= paginatedMetaData.page) {
      setCurrentPage(paginatedMetaData.page + 1);
    }
  };

  return (
    <Timeline
      sx={{
        margin: 0,
        padding: 0,
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
      <InfiniteScrollContainer
        nextPageHandler={handleNextPage}
        itemsPerPage={12}
        componentType="Timeline"
        listItems={actions?.map((item, index) => (
          <Stack direction="row" key={item.id} width="100%">
            <TimelineSeparator>
              <TimelineConnector sx={{ width: index === 0 ? "0px" : "1px", marginBottom: "6px" }} />
              <img width={"30px"} src={Action.icon(item.type)} alt="last action icon" />
              <TimelineConnector sx={{ width: actions.length > index + 1 ? "1px" : "0px", marginTop: "6px" }} />
            </TimelineSeparator>
            <TimelineContent flex={1} sx={{ pr: 0 }}>
              <HistoryCard action={item} />
            </TimelineContent>
          </Stack>
        ))}
      />
    </Timeline>
  );
};

export default ActionsTimelineComponent;
