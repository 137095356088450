import { Observable } from "rxjs";

import { EntityTypeEnum } from "@store/entities/entities.model";
import {
  EntityDetailsModel,
  ManageEntityDetailsModel,
  ManageEntityParametersModel
} from "@store/entities/details/entityDetails.model";
import { clientDetailsService } from "@store/entities/clients/details";
import { affiliateDetailsService } from "@store/entities/afiliates/details";

import { PaginatedData } from "@utils/infinitescroll.utils";
import { MentionItem } from "react-mentions";

export class EntityDetailsService {
  resetStore = (type: EntityTypeEnum) => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsService.resetStore();
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsService.resetStore();
    }
  };

  getEntityDetails = (type: EntityTypeEnum, entityId: string | number): Observable<EntityDetailsModel> => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsService.getClientDetails(entityId);
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsService.getAffiliateDetails(entityId);
    }
  };

  createEntity = (type: EntityTypeEnum, entity: ManageEntityDetailsModel, clientId?: string | number): Observable<EntityDetailsModel> => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsService.createClient(entity);
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsService.createAffiliate(entity, clientId);
    }
  };

  updateEntityDetails = (type: EntityTypeEnum, entityId: string | number, entity: ManageEntityDetailsModel): Observable<EntityDetailsModel> => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsService.updateClientDetails(entityId, entity);
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsService.updateAffiliateDetails(entityId, entity);
    }
  };

  updateEntityParameters = (
    type: EntityTypeEnum,
    entityId: string | number,
    parameters: ManageEntityParametersModel
  ): Observable<EntityDetailsModel> => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsService.updateClientParameters(entityId, parameters);
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsService.updateAffiliateParameters(entityId, parameters);
    }
  };

  getEntityHistory = (type: EntityTypeEnum, entityId: string | number, page: number = 1, take: number = 24): Observable<PaginatedData<any[]>> => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsService.getClientHistory(entityId, page, take);
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsService.getAffiliateHistory(entityId, page, take);
    }
  };

  addComments = (type: EntityTypeEnum, entityId: string | number, comment: string, mentions: MentionItem[]) => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsService.addComment(entityId, comment, mentions);
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsService.addComment(entityId, comment, mentions);
    }
  };

  updateComment = (type: EntityTypeEnum, entityId: string | number, commentId: string, comment: string, mentions: MentionItem[]) => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsService.updateComment(entityId, commentId, comment, mentions);
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsService.updateComment(entityId, commentId, comment, mentions);
    }
  };

  getEntityComments = (type: EntityTypeEnum, entityId: string | number) => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsService.getClientComments(entityId);
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsService.getAffiliateComments(entityId);
    }
  };
}

export const entityDetailsService = new EntityDetailsService();
