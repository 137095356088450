import { catchError, from, map, Observable, tap } from "rxjs";

import { AxiosError, AxiosResponse } from "axios";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { connectorsStore } from "./connectors.store";
import { ConnectorModel, ManageConnector } from "./connectors.model";
import { deleteEntities, setEntities, upsertEntities } from "@ngneat/elf-entities";
import { getConnectorsDataSource } from "./connectors.requests";

export class ConnectorsService {
  store = connectorsStore;

  resetStore = () => this.store.reset();

  getUserAffiliatesConnectors = (): Observable<ConnectorModel[]> => {
    return from(APIAxios(APIRoutes.GETUserAffiliatesConnectors())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ConnectorModel[]>) => {
        return response.data;
      }),
      tap((connectors) => {
        this.store.update(setEntities(connectors), getConnectorsDataSource.setSuccess());
      }),
      getConnectorsDataSource.trackRequestStatus()
    );
  };

  getAffiliateConnectors = (affiliateId: string): Observable<ConnectorModel[]> => {
    return from(APIAxios(APIRoutes.GETAffiliateConnectors(affiliateId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ConnectorModel[]>) => {
        return response.data;
      }),
      tap((connectors) => {
        this.store.update(setEntities(connectors), getConnectorsDataSource.setSuccess());
      }),
      getConnectorsDataSource.trackRequestStatus()
    );
  };

  activateConnector = (manageConnector: ManageConnector): Observable<ConnectorModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTActivateConnector(),
        data: { ...manageConnector },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ConnectorModel>) => {
        return response.data;
      }),
      tap((connector) => this.store.update(upsertEntities(connector)))
    );
  };

  deactivateConnector = (connectorId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.POSTDeactivateConnector(connectorId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        return response.data;
      }),
      tap(() => this.store.update(deleteEntities(connectorId)))
    );
  };

  updateConnector = (connectorId: string, manageConnector: ManageConnector): Observable<ConnectorModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHConnector(connectorId),
        data: { login: manageConnector.login, password: manageConnector.password, company: manageConnector.company, url: manageConnector.url },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ConnectorModel>) => {
        return response.data;
      }),
      tap((connector) => this.store.update(upsertEntities(connector)))
    );
  };
}

export const connectorsService = new ConnectorsService();
