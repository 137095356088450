import { catchError, from, map, Observable, switchMap, tap } from "rxjs";
import { companyDetailsStore } from "./companyDetails.store";
import { AxiosError, AxiosResponse } from "axios";
import SnackError from "@utils/error.utils";
import { CompanyDetailsDetailsModel, CompanyStats, ManageCompanyInformation, ManageCompanySocialMedia } from "./companyDetails.model";
import { getCompanyDetailsDetailsDataSource, getCompanyDetailsStatsDataSource } from "./companyDetails.requests";
import AIOAPIAxios, { AIOAPIRoutes } from "@api/aio-axios.api";
import APIAxios, { APIRoutes } from "@api/axios.api";

export class CompanyDetailsService {
  store = companyDetailsStore;

  resetStore = () => this.store.reset();

  getCompany = (companyId: string): Observable<CompanyDetailsDetailsModel> => {
    return from(AIOAPIAxios(AIOAPIRoutes.GETCompanyDetail(companyId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<CompanyDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        this.store.update(
          (state) => ({
            ...state,
            details,
          }),
          getCompanyDetailsDetailsDataSource.setSuccess()
        );
      }),
      getCompanyDetailsDetailsDataSource.trackRequestStatus()
    );
  };

  getReattachedCompany = (affiliateId: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      AIOAPIAxios({
        ...AIOAPIRoutes.GETReattachedCompanyDetail(),
        params: {
          affiliateId,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<CompanyDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        this.store.update(
          (state) => ({
            ...state,
            details,
          }),
          getCompanyDetailsDetailsDataSource.setSuccess()
        );
      }),
      getCompanyDetailsDetailsDataSource.trackRequestStatus()
    );
  };

  getCompanyStats = (companyId: string): Observable<CompanyStats> => {
    return from(AIOAPIAxios(AIOAPIRoutes.GETCompanyStats(companyId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<CompanyStats>) => {
        return response.data;
      }),
      tap((stats) => {
        this.store.update(
          (state) => ({
            ...state,
            stats,
          }),
          getCompanyDetailsStatsDataSource.setSuccess()
        );
      }),
      getCompanyDetailsStatsDataSource.trackRequestStatus()
    );
  };

  reattachCompany = (companyId: string, affiliateId: string, affiliateName: string): Observable<CompanyDetailsDetailsModel> => {
    return from(AIOAPIAxios({ ...AIOAPIRoutes.PATCHReattachCompany(companyId), data: { affiliateId, affiliateName } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  banCompany = (companyId: string): Observable<CompanyDetailsDetailsModel> => {
    return from(AIOAPIAxios(AIOAPIRoutes.PATCHBanCompany(companyId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  unbanCompany = (companyId: string): Observable<CompanyDetailsDetailsModel> => {
    return from(AIOAPIAxios(AIOAPIRoutes.PATCHUnbanCompany(companyId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  updateInformationCompany = (companyId: string, data: ManageCompanyInformation): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.PATCHInformationCompany(companyId),
          companyId,
          body: {
            location: data.location,
            description: data.description,
            creationDate: data.creationDate,
            nbCollaborates: data.nbCollaborates,
            turnover: data.turnover,
            telework: data.telework,
            website: data.website,
          },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  updateSocialMediaCompany = (companyId: string, data: ManageCompanySocialMedia): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.PATCHSocialMediaCompany(companyId),
          companyId,
          body: {
            linkedin: data.linkedin,
            twitter: data.twitter,
            youtube: data.youtube,
            instagram: data.instagram,
            facebook: data.facebook,
            tiktok: data.tiktok,
          },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  addValueCompany = (companyId: string, name: string, icon: string, description?: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.PATCHValueCompany(companyId),
          companyId,
          body: { name, icon, description },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  removeValueCompany = (companyId: string, companyValueId: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.DELETEValueCompany(companyId),
          companyId,
          body: { valueCompanyId: companyValueId },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  updateCoverPictureCompany = (companyId: string, file: File): Observable<CompanyDetailsDetailsModel> => {
    const formData = new FormData();

    formData.append("coverPicture", file);
    formData.append("url", AIOAPIRoutes.PATCHCoverPictureCompany(companyId).url!);
    formData.append("method", AIOAPIRoutes.PATCHCoverPictureCompany(companyId).method!);
    formData.append("companyId", companyId);

    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSiteFormData(),
        data: formData,
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  updateCompanyLogo = (companyId: string, file: File): Observable<CompanyDetailsDetailsModel> => {
    const formData = new FormData();
    formData.append("logo", file);
    formData.append("url", AIOAPIRoutes.PATCHLogoCompany(companyId).url!);
    formData.append("method", AIOAPIRoutes.PATCHLogoCompany(companyId).method!);
    formData.append("companyId", companyId);

    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSiteFormData(),
        data: formData,
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  updateMediasCompany = (companyId: string, type: "image" | "video", files: File[]): Observable<CompanyDetailsDetailsModel> => {
    const formData = new FormData();

    files.forEach((f) => {
      if (type === "image") formData.append("photos[]", f);
      if (type === "video") formData.append("videos[]", f);
    });

    formData.append("url", AIOAPIRoutes.PATCHMediasCompany(companyId).url!);
    formData.append("method", AIOAPIRoutes.PATCHMediasCompany(companyId).method!);
    formData.append("companyId", companyId);

    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSiteFormData(),
        data: formData,
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  removeMediaCompany = (companyId: string, companyMediaId: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.DELETEMediaCompany(companyId),
          companyId,
          body: { mediaCompanyId: companyMediaId },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  addTestimonialCompany = (companyId: string, title: string, name: string, job: string, content: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.POSTTestimonialCompany(companyId),
          companyId,
          body: { title, name, job, content },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  patchTestimonialCompany = (
    companyId: string,
    id: string,
    title: string,
    name: string,
    job: string,
    content: string
  ): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.PATCHTestimonialCompany(companyId),
          companyId,
          body: { id, title, name, job, content },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  deleteTestimonialCompany = (companyId: string, testimonialId: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.DELETETestimonialCompany(companyId),
          companyId,
          body: { testimonialId },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  addLightInfoCompany = (companyId: string, info: string, description: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.POSTLightInfoCompany(companyId),
          companyId,
          body: {
            info,
            description,
          },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  patchLightInfoCompany = (companyId: string, id: string, info: string, description: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.PATCHLightInfoCompany(companyId),
          companyId,
          body: { id, info, description },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  deleteLightInfoCompany = (companyId: string, lightInfoId: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.DELETELightInfoCompany(companyId),
          companyId,
          body: { lightInfoId },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  addYoutubeVideo = (companyId: string, link: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.PATCHYoutubeVideoCompany(companyId),
          companyId,
          body: { link },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  deleteYoutubeVideo = (companyId: string, link: string): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.DELETEYoutubeVideoCompany(companyId),
          companyId,
          body: { link },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };

  patchColorTheme = (companyId: string, theme: { primary: string; secondary: string; tertiary: string }): Observable<CompanyDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTUpdateAIOCareerSite(),
        data: {
          ...AIOAPIRoutes.PATCHColorThemeCompany(companyId),
          companyId,
          body: { ...theme },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getCompany(companyId))
    );
  };
}

export const companyDetailsService = new CompanyDetailsService();
