import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { ManagePersonality, personalitiesService, Personality } from "@store/ai-o/personalities";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";

interface PersonalityModalProps {
  handleClose: () => void;
  personality?: Personality;
}

const PersonalityModal = (props: PersonalityModalProps) => {
  const { handleClose, personality} = props

  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ManagePersonality>(personality ?? {});

  const updateData = (name: string) => (value: any) => setData((state) => ({...state, [name]: value}));

  const errors = {
    content: getFieldError(data.content, FieldValidationType.REQUIRED_STRING),
    gptSentence: getFieldError(data.gptSentence, FieldValidationType.REQUIRED_STRING),
  };

  const handleSaveModal = () => {
    if (!!personality) {
      setLoading(true);
      personalitiesService.updatePersonality(personality.id, data).pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("aio.personality.success.edit"), {variant: "success"});
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      setLoading(true);
      personalitiesService.createPersonality(data).pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("aio.personality.success.edit"), {variant: "success"});
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  }

  return (
    <ModalComponent
      maxWidth="sm"
      handleClose={handleClose}
      title={t(`aio.personality.${personality ? 'editTitle' : 'addTitle'}`)}
      titleLeft
      actions={(
        <AIOButtonComponent
          disabled={checkIfErrors(errors) || loading}
          title={t('global.validate')}
          variant="contained"
          onClick={handleSaveModal} />
      )}
      content={(
        <Stack py="20px" spacing={3}>
          <AIOTextfieldComponent
            required
            multiline
            value={data.content ?? ''}
            error={errors.content}
            onChange={updateData('content')}
            title={t('aio.personality.fields.contentLabel')}
            placeholder={t('aio.personality.fields.contentPlaceholder')} />
          <AIOTextfieldComponent
            required
            value={data.gptSentence ?? ''}
            error={errors.gptSentence}
            onChange={updateData('gptSentence')}
            title={t('aio.personality.fields.gtpElements')}
            placeholder={t('aio.personality.fields.elements')} />
        </Stack>
      )} />
  )
}

export default PersonalityModal