import React, { useState } from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { FieldValidationType, getFieldError } from "@utils/yup.utils";

import { Personae, personaeService } from "@store/personae";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";

interface ConfigAssociatedAdsModalProps {
  handleClose: () => void;
  handleSave: (personae: Personae) => void;
  associatedTitles: string[];
}

const ConfigAssociatedAdsModal = (props: ConfigAssociatedAdsModalProps) => {
  const { handleClose, handleSave, associatedTitles } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { personaeId } = useParams();

  const [loading, setLoading] = useState(false);
  const [keyWords, setKeyWords] = useState<string[]>(associatedTitles);

  const handleUpdate = () => {
    if (!personaeId) return;
    setLoading(true);
    personaeService
      .updatePersonaeProfileField(personaeId, { associatedTitles: keyWords })
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (np) => {
          enqueueSnackbar(t("personae.manage.success"), { variant: "success" });
          handleSave(np);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const updateKeyWords = (index: number) => (value: string) => {
    setKeyWords((prv) => [
      ...prv.map((keyWord, i) => {
        return i === index ? value : keyWord;
      }),
    ]);
  };

  const errors = {
    keyWord: keyWords.map((keyWord) => getFieldError(keyWord, FieldValidationType.REQUIRED_STRING)),
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      maxWidth="xs"
      content={
        <Stack py="15px" spacing={3}>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Typography fontSize="18px" fontWeight="800">
              {t("personae.manage.manageKeyWords")}
            </Typography>
            <IconButton onClick={() => setKeyWords((prv) => [...prv, ""])}>
              <img src="/images/icon_add.svg" alt="" />
            </IconButton>
          </Stack>
          {keyWords.map((keyWord, index) => (
            <Stack key={index} direction="row" alignItems="center" justifyContent="flex-start" width="100%">
              <IconButton
                sx={{ mt: "14px", mr: "10px" }}
                onClick={() =>
                  setKeyWords((prv) => {
                    return [...prv.filter((k, i) => i !== index)];
                  })
                }>
                <img src="/images/icon_remove.svg" alt="" />
              </IconButton>
              <Stack flex={1}>
                <AIOTextfieldComponent
                  title={t("personae.manage.keyWord")}
                  placeholder={t("personae.manage.keyWord")}
                  onChange={updateKeyWords(index)}
                  value={keyWord}
                  error={errors.keyWord[index]}
                />
              </Stack>
            </Stack>
          ))}
        </Stack>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          disabled={loading || errors.keyWord.some((k) => k)}
          onClick={handleUpdate}
          title={t("global.confirm")}
        />
      }
    />
  );
};

export default ConfigAssociatedAdsModal;
