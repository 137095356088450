import React, { FormEvent, useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { ManageSubscriptionParameters, subscriptionsService } from "@store/subscriptions";
import { sessionQuery } from "@store/session";

import { Colors } from "@constants/colors.constant";
import { SelectItem } from "@components/input/Select.component";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import { useObservable } from "@ngneat/react-rxjs";

interface SubscriptionContactModalProps {
  handleClose: () => void;
  isYearly: boolean;
  subscriptionParameters: ManageSubscriptionParameters
}

const SubscriptionContactModal = (props: SubscriptionContactModalProps) => {
  const { handleClose, isYearly, subscriptionParameters } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [affiliate, setAffiliate] = useState<SelectItem | undefined>(undefined);

  const defaultSelected = useMemo(() => {
    return clientViewClient
      ? { value: clientViewClient.id, label: clientViewClient.name}
      : (sessionQuery.affiliatesItems.length === 1 ? sessionQuery.affiliatesItems[0] : undefined);
  }, [clientViewClient]);

  const handleSubmit = (ev: FormEvent) => {
    ev.preventDefault();

    if (!affiliate && !defaultSelected) return;

    setLoading(true);
    subscriptionsService
      .sendQuotationRequest(affiliate?.value ?? defaultSelected?.value, isYearly, subscriptionParameters)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("subscriptions.recontact.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => {
          enqueueSnackbar(err.text, err.options);
        }
      });
  };

  const errors = {
    affiliate: getFieldError(defaultSelected ?? affiliate, FieldValidationType.REQUIRED_SELECT_ITEM)
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("subscriptions.recontact.title")}
      maxWidth="xs"
      content={
        <Stack spacing={3} pb="20px" py="5px">
          <Typography
            textAlign="center"
            flex={1}
            color={Colors.secondaryText}
            lineHeight="1.25"
            fontWeight={300}
            mt="5px">
            {t("subscriptions.recontact.subtitle")}
          </Typography>
          <form onSubmit={handleSubmit} noValidate>
            <Stack pt="10px">
              <SelectWithSearchComponent
                handleChange={setAffiliate}
                startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                placeholder={t("global.select")}
                items={defaultSelected ? [defaultSelected] : sessionQuery.affiliatesItems}
                value={defaultSelected ?? affiliate ?? ''}
                readOnly={!!defaultSelected}
              />
              <Stack direction="row" justifyContent="center" pt="40px">
                <AIOButtonComponent
                  title={t("global.confirm")}
                  variant="contained"
                  color="secondary"
                  disabled={loading || checkIfErrors(errors)}
                  isSubmit
                />
              </Stack>
            </Stack>
          </form>
        </Stack>
      }
      paddingButton="10px 0px 40px 0px" />
  );
};

export default SubscriptionContactModal;