import { Colors } from "@constants/colors.constant";
import { Button, Grid, Stack, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import PreviewImage from "./PreviewImage.component";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

interface AIODropZoneProps {
  setFiles: (files: File[]) => void;
}

const AIODropZone = (props: AIODropZoneProps) => {
  const { setFiles } = props;

  const { t } = useTranslation();

  const [previewImages, setPreviewImages] = useState<{ id: string; src: string; file: File }[]>([]);
  const [myFiles, setMyFiles] = useState<File[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setMyFiles([...myFiles, ...acceptedFiles.filter((f) => !myFiles.find((mf) => mf.name + mf.size === f.name + f.size))]);
    },
    [myFiles]
  );

  useEffect(() => {
    myFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = function(e) {
        if (e.target?.result && typeof e.target?.result === "string") {
          setPreviewImages((prevState) => [
            ...prevState.filter((img) => img.file.name !== file.name),
            { id: Math.random().toString(), src: "/images/pdf-thumbnail.png", file }
          ]);
        }
      };
      reader.readAsDataURL(file);
    });
    setFiles(myFiles);
  }, [myFiles, setFiles]);

  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    multiple: true,
    accept: {
      "application/pdf": [".pdf"]
    }
  });

  const handleRemoveFile = (file: File) => {
    setPreviewImages((prevState) => prevState.filter((p) => p.file !== file));
    setMyFiles((prevState) => prevState.filter((f) => f !== file));
  };

  return (
    <Stack spacing={2} alignItems="center">
      <div {...getRootProps()} style={{ width: "100%" }}>
        <Button
          component="label"
          sx={{
            padding: "30px",
            backgroundColor: isDragActive ? Colors.lightBlue : Colors.lightPrimary,
            border: isDragActive ? "solid 1px transparent" : "dashed 1px",
            width: "100%"
          }}
          onClick={open}>
          <Stack spacing={2} alignItems="center">
            <Typography color={Colors.primary}>{t("global.importFiles")}</Typography>
            <img width="20px" alt="upload" src="/images/download_media.svg" />
          </Stack>
        </Button>
        <input {...getInputProps()} hidden type="file" multiple accept="application/pdf, image/png, image/jpeg" />
      </div>

      {!!previewImages.length && (
        <Grid container spacing={"20px"} marginLeft="-20px !important" width="100%" mb="20px !important">
          {previewImages.map((img) => (
            <PreviewImage key={img.id} img={img} handleRemoveFile={handleRemoveFile} label={img.file.name} />
          ))}
        </Grid>
      )}
    </Stack>
  );
};

export default AIODropZone;
