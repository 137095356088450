import React, { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { AppCountryEnum, getAppCountry } from "@store/common/country.model";
import { sessionService } from "@store/session";

import PrivacyPolicyUS from "@screens/legalNotice/US/PrivacyPolicy";
import PrivacyPolicyFR from "@screens/legalNotice/FR/PrivacyPolicy";
import CGUUS from "@screens/legalNotice/US/CGU";
import CGUFR from "@screens/legalNotice/FR/CGU";
import AIOButtonComponent from "@components/Button.component";
import CheckboxComponent from "@components/Checkbox.component";

const MAX_STEP = 2;

const AcceptRGPDAndCGUModal = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [step, setStep] = useState(1);

  const [acceptPolicy, setAcceptPolicy] = useState<boolean>(false);
  const [acceptCGU, setAcceptCGU] = useState<boolean>(false);

  return (
    <Dialog
      open={true}
      fullScreen
      scroll="paper"
      PaperProps={{
        style: {
          borderRadius: 15,
          margin: breakpointDownSM ? "8px" : undefined,
          width: breakpointDownSM ? "100%" : undefined
        }
      }}>
      <DialogTitle>
        <Stack alignItems="center">
          <Typography
            style={{ wordBreak: "break-word" }}
            whiteSpace="pre-line"
            lineHeight="1"
            pt="20px"
            fontSize="30px"
            fontWeight="700">
            {step === 1 && t('global.policy')}
            {step === 2 && t('global.cgu')}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent sx={{overflow: 'hidden', '& > div': { pb: '20px' }}}>
        {step === 1 && (
          <>
            {getAppCountry() === AppCountryEnum.US ? <PrivacyPolicyUS hideHeader /> : <PrivacyPolicyFR hideHeader />}
          </>
        )}
        {step === 2 && (
          <>
            {getAppCountry() === AppCountryEnum.US ? <CGUUS hideHeader /> : <CGUFR hideHeader />}
          </>
        )}
      </DialogContent>
      <DialogActions style={{ justifyContent: "center"}}>
        <Stack spacing="20px" alignItems="center">
          {step === 1 && (
            <CheckboxComponent
              handleChange={() => setAcceptPolicy((state) => !state)}
              label={`${t("auth.registering.agree")} ${t("global.policy")}`}/>
          )}
          {step === 2 && (
            <CheckboxComponent
              handleChange={() => setAcceptCGU((state) => !state)}
              label={`${t("auth.registering.agreePlural")} ${t("global.cgu")}`}/>
          )}
          <Box width="200px">
            <AIOButtonComponent
              fullWidth
              variant="contained"
              disabled={(step === 1 && !acceptPolicy) || (step === 2 && !acceptCGU)}
              onClick={() => {
                if (step < MAX_STEP) return setStep((state) => state + 1);
                sessionService.acceptRGPDAndCGU().subscribe({
                  error: (err) => enqueueSnackbar(err.text, err.options),
                });
              }}
              title={t(`global.${step < MAX_STEP ? 'next' : 'validate'}`)}
            />
          </Box>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}

export default AcceptRGPDAndCGUModal;