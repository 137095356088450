import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";

import AIOAPIAxios, { AIOAPIRoutes } from "@api/aio-axios.api";
import SnackError from "@utils/error.utils";
import { tonesStore } from "./tones.store";
import { ManageTone, Tone } from "./tones.model";
import { getTonesDataSource } from "./tones.requests";

export class TonesService {
  store = tonesStore;

  getTones = (): Observable<Tone[]> => {
    return from(AIOAPIAxios(AIOAPIRoutes.GETTones())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Tone[]>) => {
        return response.data;
      }),
      tap((tones) => {
        this.store.update(setEntities(tones), getTonesDataSource.setSuccess());
      }),
      getTonesDataSource.trackRequestStatus()
    );
  };

  updateTone = (toneId: string, tone: ManageTone): Observable<Tone> => {
    const formData = new FormData();
    if (tone.title) formData.append("title", tone.title);
    if (tone.description) formData.append("description", tone.description);
    if (tone.logoFile) formData.append("logo", tone.logoFile);

    return from(AIOAPIAxios({ ...AIOAPIRoutes.PUTTone(toneId), data: formData })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Tone>) => {
        return response.data;
      }),
      tap((tone) => {
        this.store.update(updateEntities(toneId, tone));
      })
    );
  };

  createTone = (tone: ManageTone): Observable<Tone> => {
    const formData = new FormData();
    if (tone.title) formData.append("title", tone.title);
    if (tone.description) formData.append("description", tone.description);
    if (tone.logoFile) formData.append("logo", tone.logoFile);

    return from(AIOAPIAxios({ ...AIOAPIRoutes.POSTTone(), data: formData })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Tone>) => {
        return response.data;
      }),
      tap((tone) => {
        this.store.update(addEntities(tone));
      })
    );
  };

  deleteTone = (toneId: string): Observable<AxiosResponse> => {
    return from(AIOAPIAxios(AIOAPIRoutes.DELETETone(toneId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      tap(() => {
        this.store.update(deleteEntities(toneId));
      })
    );
  };

}

export const tonesService = new TonesService();
