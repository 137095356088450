import React, { useMemo, useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { BoostItemEnum, BoostItemTypeEnumFunctions, ManageBoostCreditsModel } from "@store/common/boostCredits.model";
import { jobBoardsQuery } from "@store/jobBoards";

import { Colors } from "@constants/colors.constant";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";

interface BoostCreditsAddCreditsModalProps {
  handleAdd: (boostCredits: ManageBoostCreditsModel) => void;
  handleClose: () => void;
}

const BoostCreditsAddCreditsModal = (props: BoostCreditsAddCreditsModalProps) => {
  const { handleAdd, handleClose } = props;

  const { t } = useTranslation();

  const [data, setData] = useState<ManageBoostCreditsModel>({});

  const updateData = (name: string) => (value: any) => setData((state) => ({ ...state, [name]: value }));

  const boostItems = useMemo(() => {
    switch (data.type?.value) {
      case BoostItemEnum.BOOST_ACTION:
        return jobBoardsQuery.boostActionsSelectItems;
      case BoostItemEnum.JOBBOARD:
        return jobBoardsQuery.jobBoardsSelectItems;
      case BoostItemEnum.SEQUENCE:
        return jobBoardsQuery.sequencesSelectItems;
      case BoostItemEnum.SCHOOL:
        return jobBoardsQuery.schoolsSelectItems;
      default:
        return [];
    }
  }, [data.type]);

  return (
    <ModalComponent
      maxWidth="xs"
      title={t("clients.details.parametersTab.boostCredits.addCredits")}
      titleLeft
      content={
        <Stack px="30px" spacing="15px" py="10px">
          <Stack>
            <Typography fontSize="12px" fontWeight="500" color={Colors.secondaryText}>
              {t("clients.details.parametersTab.boostCredits.type")}
            </Typography>
            <SelectWithSearchComponent
              placeholder={t("clients.details.parametersTab.boostCredits.type")}
              items={BoostItemTypeEnumFunctions.selectItems}
              handleChange={(type) => setData((state) => ({...state, type, boostItem: undefined, credits: undefined}))}
              value={data.type ?? ''}
            />
          </Stack>
          {data.type && (
            <Stack>
              <Typography fontSize="12px" fontWeight="500" color={Colors.secondaryText}>
                {data.type.label}
              </Typography>
              <SelectWithSearchComponent
                placeholder={data.type.label}
                items={boostItems}
                handleChange={updateData("boostItem")}
                value={data.boostItem ?? ''}
              />
            </Stack>
          )}
          {data.boostItem && (
            <Stack>
              <AIOTextfieldComponent
                title={t("clients.details.parametersTab.boostCredits.credits")}
                placeholder={t("clients.details.parametersTab.boostCredits.credits")}
                pattern={new RegExp(/^\d*$/)}
                value={data.credits?.toString() || ""}
                onChange={(credits) => updateData("credits")(credits ? +credits : undefined)}
              />
            </Stack>
          )}
        </Stack>
      }
      actions={(
        <AIOButtonComponent
          disabled={!data.type || !data.boostItem || data.credits === undefined || data.credits < 1}
          onClick={() => handleAdd(data)}
          variant="contained"
          title={t("global.add")}
        />
      )}
      handleClose={handleClose}
    />
  );
};

export default BoostCreditsAddCreditsModal;
