import { createRequestDataSource } from "@ngneat/elf-requests";

import { clientsStore } from "@store/entities/clients/clients.store";
import { selectAllEntities } from "@ngneat/elf-entities";

export const getClientsDataSource = createRequestDataSource({
  store: clientsStore,
  data$: () => clientsStore.pipe(selectAllEntities()),
  requestKey: "getClients",
  dataKey: "clients",
});
