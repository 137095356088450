import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";

import { UsersFilters, UsersSort } from "@store/users/users.model";
import { usersService } from "@store/users/users.service";

interface SearchUsersParams {
  filters?: UsersFilters;
  sort?: UsersSort;
}

export const searchUsersEffect = createEffectFn((params$: Observable<SearchUsersParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({filters, sort}) => usersService.getUsers(filters, sort)),
  );
});