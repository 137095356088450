import { catchError, from, map, Observable, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";

import { LinkedinPostModel } from "@store/linkedin/linkedin.model";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { sessionService } from "@store/session";
import { FeatureCreditsTypeEnum } from "@store/subscriptions";

export class LinkedinService {
  checkLinkedinConnection = (): Observable<boolean> => {
    return from(APIAxios(APIRoutes.GETCheckLinkedinConnection())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<boolean>) => {
        return response.data;
      })
    );
  };

  generateLinkedinPost = (adId: string | number, removeCredit?: boolean): Observable<string> => {
    return from(APIAxios(APIRoutes.GETGenerateLinkedinPost(adId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<string>) => {
        return response.data;
      }),
      tap(() => {
        if (removeCredit) {
          sessionService.subtractCredit(FeatureCreditsTypeEnum.LINKEDIN_POST);
        }
      }),
    );
  };

  publishLinkedinPost = (linkedinPost: LinkedinPostModel): Observable<AxiosResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTPublishLinkedinPost(),
        data: { ...linkedinPost },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  connectToLinkedin = (redirectRoute: string, state: string) => {
    window.open(
      `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${
        process.env.REACT_APP_LINKEDIN_CLIENT_ID
      }&redirect_uri=${encodeURIComponent(
        `${new URL(window.location.href).origin}/${redirectRoute}`
      )}&state=${state}&scope=r_liteprofile,w_member_social`,
      "_self"
    );
  };
}

export const linkedinService = new LinkedinService();
