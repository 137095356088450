import { useState } from "react";
import styled from "@emotion/styled";
import { alpha, Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Subscription, subscriptions, SubscriptionTypeEnum, SubscriptionTypeEnumFunctions } from "@store/subscriptions/subscriptions.model";

import { Colors } from "@constants/colors.constant";
import AIOButtonComponent from "@components/Button.component";
import SmallAmountChoiceComponent from "@components/subscriptions/SmallAmountChoice.component";
import ShowMoreModal from "@components/subscriptions/modal/ShowMore.modal";

interface SubscriptionCardProps {
  color?: string;
  background: "light" | "dark";
  selected?: "true" | "false";
  gradient: string;
}

const SubscriptionCard = styled(Stack)((props: SubscriptionCardProps) => ({
  background: props.background === "light" ? Colors.subscriptions.lightBackground : Colors.subscriptions.darkBackground,
  backgroundImage: props.gradient,
  boxShadow: props.selected === "true" ? `0 0 0 1px ${props.color}` : "0 0 0 1px transparent",
  borderRadius: "10px",
  color: props.background === "light" ? Colors.secondary : Colors.white,
  marginBottom: "15px !important",
  marginTop: "5px !important",
  marginLeft: "5px !important",
  marginRight: "5px !important",
  overflow: "hidden",
  paddingTop: "30px",
  position: "relative",
  width: "262px",
  "& > div > .MuiTypography-root:first-of-type": {
    color: props.background === "light" ? Colors.grey : alpha(Colors.white, 0.6),
  },
}));

interface SubscriptionSmallColumnComponentProps {
  amount?: number;
  handleAmountChange?: (amount?: number, unlimited?: boolean) => void;
  handleSelect?: () => void;
  isSelected: boolean;
  isYearly: boolean;
  subscription: Subscription;
  unlimited?: boolean;
}

const SubscriptionSmallColumnComponent = (props: SubscriptionSmallColumnComponentProps) => {
  const { amount, handleAmountChange, handleSelect, isSelected, isYearly, subscription, unlimited } = props;

  const { t } = useTranslation();

  const [showMoreModal, setShowMoreModal] = useState(false);

  return (
    <>
      <SubscriptionCard
        alignItems="center"
        selected={isSelected ? "true" : "false"}
        position="relative"
        color={SubscriptionTypeEnumFunctions.colors[subscription.type]}
        background={SubscriptionTypeEnumFunctions.backgroundColors[subscription.type]}
        gradient={SubscriptionTypeEnumFunctions.gradientColors[subscription.type]}>
        {subscription.type === SubscriptionTypeEnum.COMPANY ? (
          <Tooltip title={t("auth.registering.companyOfferDescription")} arrow placement="top">
            <img alt="information" src="/images/icon_informations.svg" style={{ position: "absolute", top: "18px", right: "18px" }} />
          </Tooltip>
        ) : (
          <IconButton sx={{ position: "absolute", top: "10px", right: "10px" }} onClick={() => setShowMoreModal(true)}>
            <img alt="information" src="/images/icon_informations.svg" />
          </IconButton>
        )}
        <Stack px="38px" alignItems="center">
          <Stack direction="row" alignItems="center" spacing="7px">
            {subscription.icon}
            <Typography fontSize="20px" fontWeight={500}>
              {subscription.name}
            </Typography>
          </Stack>
          <Typography fontSize="12px" fontWeight={500} lineHeight="1.25" textAlign="center" mt="7px">
            {subscription.subtitle}
          </Typography>
        </Stack>
        <Stack alignItems="center" mt="27px" mb="50px">
          <SmallAmountChoiceComponent
            amount={amount}
            unlimited={unlimited}
            isYearly={isYearly}
            subscription={subscription}
            handleAmountChange={handleAmountChange}
          />
        </Stack>
        <Box width="180px" mt="auto" mb="40px">
          <AIOButtonComponent variant="contained" fullWidth disabled={isSelected} onClick={handleSelect} title={t("subscriptions.chooseOffer")} />
        </Box>
      </SubscriptionCard>
      {showMoreModal && <ShowMoreModal subscriptions={subscriptions} handleClose={() => setShowMoreModal(false)} />}
    </>
  );
};

export default SubscriptionSmallColumnComponent;
