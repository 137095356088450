import { Comment } from "@store/common/comments.model";
import { Action } from "@store/common/actions.model";

import I18nUtils from "@utils/i18n.utils";
import { JobBoard } from "@store/jobBoards";
import { AffiliateLightModel } from "@store/entities/afiliates";
import { CandidateMailType } from "@store/common/mail.model";
import { TwilioAiCall } from "@store/twilioAiCalls";

export enum ApplicationsListEnum {
  TO_BE_PROCESSED = "TO_BE_PROCESSED",
  UNDER_REVIEW = "UNDER_REVIEW",
  TO_BE_CALLED = "TO_BE_CALLED",
  NOT_SELECTED_BY_AI = "NOT_SELECTED_BY_AI",
  NOT_SELECTED = "NOT_SELECTED",
  FIRST_INTERVIEW_PENDING = "FIRST_INTERVIEW_PENDING",
  SECOND_INTERVIEW_PENDING = "SECOND_INTERVIEW_PENDING",
  HIRED = "HIRED",
}

export namespace ApplicationsListEnumFunctions {
  export const label = (list?: ApplicationsListEnum) => {
    return I18nUtils.t(`ads.details.applyTab.lists.${list || "UNKNOWN"}`);
  };

  export const emailTypes: { [key: string]: CandidateMailType } = {
    [ApplicationsListEnum.NOT_SELECTED]: CandidateMailType.REJECTED_APPLICATION,
  };
}

export enum EvaluationStatusEnum {
  LOADING = "LOADING",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
}

export interface AiApplicationEvaluationResponse {
  error: boolean;
  application?: Application;
  erroredApplicationId?: string;
  analysis?: string;
  mark?: number;
  applicationList?: ApplicationsListEnum;
}

export interface AiApplicationInEvaluation extends Omit<Application, "aiEvaluation"> {
  aiEvaluation?: AiApplicationEvaluationResponse;
  aiEvaluationStatus: EvaluationStatusEnum;
}

export interface SendCandidateEmail {
  type?: CandidateMailType;
  text?: string;
  email?: string;
  saveText?: boolean;
}

export interface ApplicationsFilters {
  search?: string;
  affiliateIds?: string[];
}

export interface AdApplicationAdInfo {
  id: string;
  name: string;
  description: string;
  reference: string;
  location: string;
  managedByTwinin: boolean;
}

export interface AdApplicationCandidate {
  picture: string;
  fullName: string;
  firstname: string;
  lastname: string;
  email: string;
  city: string;
}

export interface AiEvaluation {
  mark: number;
  analysis: string;
  applicationList: ApplicationsListEnum;
}

export interface ApplicationDetails extends Application {
  comment: Comment[];
  lastActions?: Action[];
}

export interface Application {
  id: string;
  announcement: AdApplicationAdInfo;
  affiliate: AffiliateLightModel;
  name: string;
  date: Date;
  customName?: string;
  commentNumber?: number;
  viewNumber?: number;
  jobboard: JobBoard;
  aioApplicationId?: string;
  candidate?: AdApplicationCandidate;
  linkedinProfileURL?: string;
  list: string;
  inCVTheque?: boolean;
  aiEvaluation?: AiEvaluation;
  twilioAiCall?: TwilioAiCall;
  phoneNumber?: string;
}
