import { alpha, Box, Button, Grid, Stack, styled, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CompanyDetailsDetailsModel, companyDetailsService } from "@store/ai-o/companies/details";

import { Colors, isDark } from "@constants/colors.constant";

import ConfirmModal from "@components/modal/Confirm.modal";
import ModifyColorThemeModal from "./ModifyColorTheme.modal";
import UnderlinedTitle from "@components/UnderlinedTitle";

const ColorLitleCard = styled(Box)({
  borderRadius: "20px",
  width: "100%",
  padding: "15px",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
});

interface ColorThemeBlockProps {
  company: CompanyDetailsDetailsModel;
}

const ColorThemeBlock = (props: ColorThemeBlockProps) => {
  const { company } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openAdd, setOpenAdd] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<string | false>(false);

  const handleDelete = () => {
    if (!confirmDelete) return;
    companyDetailsService.removeValueCompany(company.id, confirmDelete).subscribe({
      next: () => {
        enqueueSnackbar(t("aio.clients.details.colorTheme.delete.success"), { variant: "success" });
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("aio.clients.details.colorTheme.title")}
          </Typography>
          <Box sx={{ "& > .MuiButton-root": { padding: "5px 10px" } }}>
            <Button variant="text" color="primary" onClick={() => setOpenAdd(true)}>
              {t("global.modify")}
            </Button>
          </Box>
        </Stack>
        <Box>
          <Grid container spacing="10px">
            <Grid item xs={4}>
              <ColorLitleCard bgcolor={company.theme ? company.theme.primary : "#FFD66E"}>
                <UnderlinedTitle
                  title={t("aio.clients.details.colorTheme.primary")}
                  color={isDark(company.theme ? company.theme.primary : "#FFD66E") ? alpha(Colors.white, 0.3) : alpha(Colors.white, 0.7)}
                  textColor={isDark(company.theme ? company.theme.primary : "#FFD66E") ? Colors.white : Colors.black}
                  fontSizePx={14}
                  fontHeight={600}
                />
              </ColorLitleCard>
            </Grid>
            <Grid item xs={4}>
              <ColorLitleCard bgcolor={company.theme ? company.theme.secondary : "#AFCAFF"}>
                <UnderlinedTitle
                  title={t("aio.clients.details.colorTheme.secondary")}
                  color={isDark(company.theme ? company.theme.secondary : "#AFCAFF") ? alpha(Colors.white, 0.3) : alpha(Colors.white, 0.7)}
                  textColor={isDark(company.theme ? company.theme.secondary : "#AFCAFF") ? Colors.white : Colors.black}
                  fontSizePx={14}
                  fontHeight={600}
                />
              </ColorLitleCard>
            </Grid>
            <Grid item xs={4}>
              <ColorLitleCard bgcolor={company.theme ? company.theme.tertiary : "#E9624D"}>
                <UnderlinedTitle
                  title={t("aio.clients.details.colorTheme.tertiary")}
                  color={isDark(company.theme ? company.theme.tertiary : "#E9624D") ? alpha(Colors.white, 0.3) : alpha(Colors.white, 0.7)}
                  textColor={isDark(company.theme ? company.theme.tertiary : "#E9624D") ? Colors.white : Colors.black}
                  fontSizePx={14}
                  fontHeight={600}
                />
              </ColorLitleCard>
            </Grid>
          </Grid>
        </Box>
      </Stack>
      {openAdd && (
        <ModifyColorThemeModal
          handleClose={() => setOpenAdd(false)}
          companyId={company.id}
          theme={
            company.theme || { primary: Colors.careerSite.primary, secondary: Colors.careerSite.secondary, tertiary: Colors.careerSite.tertiary }
          }
        />
      )}
      {!!confirmDelete && (
        <ConfirmModal
          handleConfirm={handleDelete}
          confirmMsg={t("aio.clients.details.colorTheme.delete.description")}
          modalTitle={t("aio.clients.details.colorTheme.delete.title")}
          handleClose={() => setConfirmDelete(false)}
        />
      )}
    </Stack>
  );
};

export default ColorThemeBlock;
