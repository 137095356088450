import AIOButtonComponent from "@components/Button.component";
import {
  Checkbox,
  IconButton,
  Pagination,
  PaginationItem,
  Rating,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Colors } from "@constants/colors.constant";
import { DataGrid, GridColDef, GridFooterContainer } from "@mui/x-data-grid";
import { Application, applicationsQuery, applicationsService, searchApplicationsEffect } from "@store/applications";
import { emitOnce } from "@ngneat/elf";
import React, { useEffect, useState } from "react";
import { useEffectFn } from "@ngneat/effects-hooks";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import dayjsUtils from "@utils/dayjs.utils";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import { filesService } from "@store/files";
import { ApplicationsListEnumFunctions } from "@store/applications/applications.model";
import { Outlet, useNavigate } from "react-router-dom";
import { AdRoutes, Pages } from "@utils/routes.utils";
import { switchMap } from "rxjs";
import ConfirmModal from "@components/modal/Confirm.modal";
import { getLocalizedDateInputFormat } from "@store/common/country.model";

const StyledDataGrid = styled(DataGrid)({
  border: "none !important",
  "& .MuiDataGrid-virtualScroller": {
    backgroundColor: "white",
    border: "1px solid #EFEFEF",
    borderRadius: "15px",
    overflowX: "hidden",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: "none !important",
  },
  "& .MuiDataGrid-withBorder": {
    borderRight: "none !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row": {
    minHeight: "61px !important",
  },
  "& .MuiDataGrid-footerContainer": {
    border: "none",
    justifyContent: "flex-end",
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
  "& .MuiPaginationItem-root:not([disabled]) svg": {
    fill: Colors.primary,
  },
  "& .MuiDataGrid-cell > div": {
    maxWidth: "100%",
  },
});

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: Colors.gold,
  },
  "& .MuiRating-iconEmpty": {
    color: Colors.gold,
  },
});

const HeaderTypography = styled(Typography)({
  fontSize: "12px",
  color: Colors.lightGrey,
  fontWeight: 300,
});

const CVTheque = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const searchApplications = useEffectFn(searchApplicationsEffect);

  const [filters] = useObservable(applicationsQuery.filters$);

  const [{ applications, error, loading }] = useObservable(applicationsQuery.paginatedApplications$);
  const [applicationsPaginationData] = useObservable(applicationsQuery.applicationsPaginationData$);

  const [checkedApplications, setCheckedApplications] = useState<string[]>([]);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  useEffect(() => applicationsService.resetStore(), []);

  useEffect(() => {
    if (error) enqueueSnackbar((error as any).text, (error as any).options);
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    searchApplications({ filters, page: currentPage, take: itemsPerPage });
  }, [filters, searchApplications, itemsPerPage, currentPage]);

  useEffect(() => {
    emitOnce(() => {
      applicationsService.deleteEntities();
      applicationsService.deleteAllPages();
    });
    setCurrentPage(1);
  }, [filters]);

  const handleDownload = (application: Application, removeCredit?: boolean) => {
    if (application.linkedinProfileURL) {
      return filesService.openUrlInNewTab(application.linkedinProfileURL);
    }

    applicationsService.downloadCVWithPreview(application, removeCredit).subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handleDeleteCvs = () => {
    applicationsService
      .deleteCVs(checkedApplications, true)
      .pipe(switchMap(() => applicationsService.getApplications(filters, currentPage, itemsPerPage)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("cvtheque.success.deleted"), { variant: "success" });
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleOnCellClick = (params: any) => {
    if (params.field === "offer" && params.row.announcement.id) {
      navigate(`/${Pages.ADS}/${params.row.announcement.id}/${AdRoutes.DETAIL}}`);
    } else if (params.field === "name") {
      navigate(`${AdRoutes.APPLYING}/${params.row.id}`);
    }
  };

  const columns: GridColDef[] = [
    {
      field: "checkbox",
      renderHeader: () => <></>,
      minWidth: 60,
      width: 60,
      sortable: false,
      renderCell: (params) => (
        <Checkbox
          sx={{ marginLeft: "10px" }}
          size="small"
          onChange={(evt, checked) => {
            setCheckedApplications((prv) => (checked ? [...prv, params.row.id] : prv.filter((id) => id !== params.row.id)));
          }}
          checked={checkedApplications.includes(params.row.id)}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    },
    {
      field: "name",
      renderHeader: () => <HeaderTypography>{t("cvtheque.table.name")}</HeaderTypography>,
      minWidth: 200,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Stack sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }}>
          <Typography fontSize="14px" fontWeight={500} noWrap>
            {params.row.candidate?.lastname
              ? `${params.row.candidate.firstname} ${params.row.candidate.lastname}`
              : params.row.customName ?? params.row.name}
          </Typography>
        </Stack>
      ),
    },
    {
      field: "email",
      renderHeader: () => <HeaderTypography>{t("cvtheque.table.email")}</HeaderTypography>,
      minWidth: 200,
      sortable: false,
      renderCell: (params) => (
        <Typography fontSize="14px" fontWeight={300} noWrap>
          {params.row.candidate?.email ?? "-"}
        </Typography>
      ),
    },
    {
      field: "offer",
      renderHeader: () => <HeaderTypography>{t("cvtheque.table.offer")}</HeaderTypography>,
      flex: 1,
      minWidth: 250,
      sortable: false,
      renderCell(params) {
        return (
          <Stack>
            <Typography
              sx={
                params.row.announcement.id
                  ? {
                      cursor: "pointer",
                      "&:hover": { textDecoration: "underline" },
                    }
                  : {
                      color: Colors.error,
                      textDecoration: "line-through",
                    }
              }
              fontSize="14px"
              fontWeight={300}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              lineHeight={1.1}>
              {params.row.announcement.name ?? "-"}
            </Typography>
            <Stack direction="row" spacing={"6px"} alignItems="center">
              <img alt="location" height={"12px"} src="/images/location.svg" />
              <Typography fontSize="14px" color={Colors.lightGrey} fontWeight={300}>
                {params.row.announcement.location ?? "-"}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "reference",
      renderHeader: () => <HeaderTypography>{t("cvtheque.table.reference")}</HeaderTypography>,
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Typography fontSize="14px" fontWeight={300}>
          {params.row.announcement.reference ?? "-"}
        </Typography>
      ),
    },
    {
      field: "column",
      renderHeader: () => <HeaderTypography>{t("cvtheque.table.column")}</HeaderTypography>,
      width: 140,
      sortable: false,
      renderCell: (params) => (
        <Typography fontSize="14px" fontWeight={300}>
          {params.row.list ? ApplicationsListEnumFunctions.label(params.row.list) : "-"}
        </Typography>
      ),
    },
    // {
    //   field: "score",
    //   renderHeader: () => <HeaderTypography>{t("cvtheque.table.score")}</HeaderTypography>,
    //   width: 130,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return true ? <StyledRating readOnly value={3.5} precision={0.5} size="small" /> : "-";
    //   },
    // },
    {
      field: "date",
      renderHeader: () => <HeaderTypography>{t("cvtheque.table.date")}</HeaderTypography>,
      width: 100,
      sortable: false,
      renderCell: (params) => (
        <Typography fontSize="14px" fontWeight={300}>
          {dayjsUtils(params.row.date).format(getLocalizedDateInputFormat())}
        </Typography>
      ),
    },
    {
      field: "cv",
      headerName: "",
      minWidth: 60,
      width: 60,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => {
            handleDownload(params.row);
          }}>
          <img alt="icon cv red" src="/images/file_cv_red.svg" />
        </IconButton>
      ),
    },
  ];

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Pagination
          color="primary"
          count={applicationsPaginationData.lastPage}
          page={currentPage}
          onChange={(event, newPage) => {
            if (applicationsPaginationData.lastPage >= newPage) setCurrentPage(newPage);
          }}
          renderItem={(item) => {
            if (item.type === "page" && item.selected)
              return (
                <Stack direction="row" alignItems="row" justifyContent="center">
                  <Typography color="primary" fontWeight={500}>
                    {item.page}
                  </Typography>
                  <Typography fontWeight={300}>/{applicationsPaginationData.lastPage}</Typography>
                </Stack>
              );
            if (item.type !== "page" && item.type !== "end-ellipsis" && item.type !== "start-ellipsis") return <PaginationItem {...item} />;
          }}
        />
      </GridFooterContainer>
    );
  };

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : "60px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 0 : 4}
      flex={1}
      width="100%"
      overflow="auto">
      <Stack spacing="10px">
        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"}>
          <Typography fontSize="35px" fontWeight="700">
            {t("cvtheque.title")}
          </Typography>
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <AIOButtonComponent
              title={t("cvtheque.delete")}
              disabled={!checkedApplications.length}
              icon={
                !checkedApplications.length ? (
                  <img alt="delete" src="/images/icon_delete_grey.svg" style={{ width: "13px" }} />
                ) : (
                  <img alt="delete" src="/images/icon_delete.svg" style={{ width: "13px" }} />
                )
              }
              onClick={() => setOpenDeleteConfirmation(true)}
              variant="text"
              color="primary"
            />
          </Stack>
        </Stack>
        <Stack marginTop={"10px"}>
          <Typography fontSize="14px" fontWeight="500" color={Colors.secondaryText}>
            {t("cvtheque.subtitle1")}
          </Typography>
          <Typography fontSize="14px" fontWeight="500" color={Colors.secondaryText}>
            {t("cvtheque.subtitle2")}
          </Typography>
        </Stack>
      </Stack>
      <Stack>
        {/* <AIOTextfieldComponent // TODO: uncomment when AI is ready
        multiline
        startAdornment={<AutoFixHighIcon fontSize={"small"} />}
        color="secondary"
        placeholder={t("cvtheque.askAI")}
        onChange={debounce((value?: string) => {
          applicationsService.setFilters({ ...filters, search: value });
        }, 400)}
      // /> */}
        <AIOSearchComponent
          placeholder={t("cvtheque.searchApplication")}
          fullWidth
          width="100%"
          onChange={(value) => applicationsService.setFilters({ ...filters, search: value })}
        />
      </Stack>
      <Stack direction="row" width="100%" height="100%" justifyContent="center" alignItems="center">
        <Stack sx={{ height: "100%", width: "100%" }}>
          <StyledDataGrid
            rows={applications}
            loading={loading}
            rowsPerPageOptions={[]}
            pagination
            disableColumnMenu
            disableSelectionOnClick
            paginationMode="server"
            filterMode="server"
            rowCount={applicationsPaginationData.total}
            page={currentPage - 1}
            pageSize={applicationsPaginationData.perPage}
            headerHeight={30}
            rowHeight={61}
            columns={columns}
            onCellClick={handleOnCellClick}
            components={{
              Footer: CustomFooter,
            }}
          />
        </Stack>
      </Stack>
      {openDeleteConfirmation && (
        <ConfirmModal
          modalTitle={t("cvtheque.deleteModal.title")}
          confirmMsg={t("cvtheque.deleteModal.description")}
          handleClose={() => setOpenDeleteConfirmation(false)}
          handleConfirm={() => handleDeleteCvs()}
        />
      )}
      <Outlet />
    </Stack>
  );
};

export default CVTheque;
