import React, { useEffect } from "react";
import { Box, CircularProgress, Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";

import { registerUsersQuery, registerUsersService } from "@store/registerUsers";

import ModalComponent from "@components/modal/Modal.component";
import UserValidationCard from "@components/card/UserValidation.card";

interface UsersWaitingValidationModalProps {
  handleClose: () => void;
}

const UsersWaitingValidationModal = (props: UsersWaitingValidationModalProps) => {
  const {handleClose} = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [{registerUsers, loading}] = useObservable(registerUsersQuery.registerUsers$);

  useEffect(() => {
    registerUsersService.getRegisterUsers().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  return (
    <ModalComponent
      maxWidth="lg"
      title={t("usersValidation.title", {count: registerUsers.length})}
      titleLeft
      handleClose={handleClose}
      content={(
        <Box py="20px" height="70vh">
          {loading ? (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <CircularProgress/>
            </Stack>
          ) : (
            <Grid container spacing={2}>
              {registerUsers.map((user) => (
                <Grid item xs={12} sm={6} md={4}>
                  <UserValidationCard key={user.id} user={user}/>
                </Grid>
              ))}
            </Grid>
          )}
        </Box>
      )}/>
  );
};

export default UsersWaitingValidationModal;
