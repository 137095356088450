import { Button, Stack, Typography } from "@mui/material";
import { BoldTitle, Content } from "../legalStyle";
import AIOLinkInTxt from "@components/AIOLinkInTxt";
import { useResponsive } from "@utils/useResponsive";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutesUnAuth } from "@utils/routes.utils";
import { sessionQuery } from "@store/session";
import { useObservable } from "@ngneat/react-rxjs";

interface PrivacyPolicyFRProps {
  hideHeader?: boolean;
}

const PrivacyPolicyFR = (props: PrivacyPolicyFRProps) => {
  const {hideHeader} = props;

  const { isMobileOrTablet } = useResponsive();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [{ user }] = useObservable(sessionQuery.isAuthenticated$);
  const isAuthenticated = !!user;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && containerRef.current.parentElement) containerRef.current.parentElement.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Stack
      px={isMobileOrTablet ? "16px" : "40px"}
      pb={isMobileOrTablet ? "5px" : "60px"}
      height="100%"
      width="100%"
      overflow="auto"
      ref={containerRef}>
      {!hideHeader && (
        <>
          <Stack width={"100%"} direction={"row"} mt={isMobileOrTablet ? "15px" : "40px"}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<img alt="back" src="/images/arrow_back.svg" />}
              onClick={() => (isAuthenticated ? navigate(-1) : navigate(RoutesUnAuth.LOGIN))}>
              <Typography fontWeight="300" color="secondary">
                {t(`global.back`)}
              </Typography>
            </Button>
          </Stack>
          <Stack mt={"14px"} alignSelf={"center"} direction={"row"}>
            <Typography fontWeight={700} fontSize={"30px"}>
              Politique de Confidentialité
            </Typography>
          </Stack>
        </>
      )}
      <Stack mt={"45px"} alignSelf={"center"} spacing={3}>
        <Content>
          Dans le cadre de son exploitation des Services accessibles sur la Plateforme ALLInOne (ci-après la {`<< Données Plateforme >>`}), TWININ est
          amenée à traiter des données à caractère personnel.
        </Content>
        <Content>Le Recruteur et les Utilisateurs sont en conséquence informés de ce qui suit.</Content>
        <BoldTitle>Identité du responsable de traitement </BoldTitle>
        <Content>
          TWININ, société par actions simplifiée, immatriculée au RCS de Paris sous le numéro 520 301 557, dont le siège social est situé 229 rue
          Saint-Honoré - 75001 Paris, représentée par la société RUDSI INVEST (RCS 813 960 739), elle-même représentée par Monsieur Jérémy Duris, en
          sa qualité de Président, met en œuvre des traitements de données à caractère personnel dans le cadre de sa relation avec les Recruteurs et
          les Utilisateurs qui utilisent les services de la Plateforme.
        </Content>
        <Content>
          Elle endosse à cet égard la qualité de responsable de traitement au regard du règlement (UE) 2016/679 du Parlement européen et du Conseil du
          27 avril 2016 (RGPD).
        </Content>
        <Stack>
          <Content>Délégué à la protection des données de TWININ:</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- Monsieur Jérémy DURIS</Content>
            <Content>
              - Adresse email de contact: <AIOLinkInTxt label="info@twinin.fr" url={"mailto:info@twinin.fr"} />
            </Content>
          </Stack>
        </Stack>
        <BoldTitle>Données collectées</BoldTitle>
        <Content>Les données à caractère personnel traitées sont les suivantes (ci-après les {`<< Données Personnelles >>`}) :</Content>
        <Stack>
          <Content>Données d'identification</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- Nom, prénom</Content>
            <Content>- Adresse email</Content>
            <Content>- Situation professionnelle</Content>
            <Content>- Photo d'identité</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Données d’utilisation </Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- Adresse de protocole Internet (par exemple, l'adresse IP) de l’appareil utilisé</Content>
            <Content>- Type et la version du navigateur</Content>
            <Content>- Pages de la Plateforme visitées</Content>
            <Content>- Heure et la date de la visite et temps passé sur ces pages</Content>
            <Content>- Cookies collectant des données personnelles</Content>
          </Stack>
        </Stack>
        <Content> Les Données personnelles se rapportent aux collaborateurs concernés du Recruteur.</Content>
        <Content>
          En application du principe de minimisation des données ou {`<< PrivacyPolicy by default >>`}, TWININ veille à collecter uniquement les
          données strictement nécessaires au regard de la finalité pour laquelle elles sont traitées.
        </Content>
        <BoldTitle>Finalités des traitements et fondements</BoldTitle>
        <Content>Ces Données sont traitées pour répondre aux finalités suivantes et disposent des fondements suivants :</Content>
        <Stack>
          <Content>Finalité(s)</Content>
          <Stack spacing={1} pl={"20px"}>
            <Stack>
              <Content>- Inscription aux Services et conclusion des contrats</Content>
              <Content>- Accès, exécution et gestion des Services proposés sur la Plateforme</Content>
              <Content>- Facturation et paiement des Services</Content>
              <Content>- Relations avec les Recruteurs / Utilisateurs</Content>
            </Stack>
            <Stack>
              <Content>- Gestion des demandes, des réclamations et des litiges éventuels (précontentieux et contentieux)</Content>
            </Stack>
            <Stack>
              <Content>
                - Suivi et gestion de la relation, à savoir notamment mener des enquêtes de satisfaction facultatives sur les services de TWININ
              </Content>
              <Content>- Effectuer des statistiques commerciales et d’utilisation des services de TWININ</Content>
              <Content>- Actions de prospection et de sollicitation commerciale</Content>
              <Content>- Envoi de newsletters</Content>
            </Stack>
            <Stack>
              <Content>Finalités liées au dép^t de cookies, à savoir :</Content>
              <Stack spacing={1} pl={"20px"}>
                <Content>
                  - Personnaliser et améliorer l’expérience du Recruteur / de l’Utilisateur sur la Plateforme (cookies de personnalisation)
                </Content>
                <Content>- Réaliser des statistiques et des mesures d’audience (cookies statistiques)</Content>
                <Content>- Proposer des publicités ciblées (cookies publicitaires)</Content>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Content>Fondement(s)</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- Exécution du contrat qui lie le Recruteur à TWININ (conditions générales)</Content>
            <Content>- Exécution du contrat qui lie le Recruteur à TWININ (conditions générales) et respect d'obligations légales</Content>
            <Content>- Intérêt légitime de TWININ</Content>
            <Content>- Consentement</Content>
          </Stack>
        </Stack>
        <BoldTitle>Destinataires</BoldTitle>
        <Stack>
          <Content>Les Données traitées sont destinées, en fonction des nécessités :</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- aux services habilités de TWININ</Content>
            <Content>- aux services chargés du contrôle (expert comptable et commissaire aux comptes notamment)</Content>
            <Content>- au personnel habilité des sous-traitants éventuels de TWININ, en tant que de besoin</Content>
            <Content>- à des organismes publics, lorsque cela est imposé par la loi</Content>
            <Content>
              - Les tiers susceptibles de déposer des cookies sur les terminaux du du Recruteur / des Utilisateurs (ordinateurs, tablettes, téléphones
              portables…) lorsqu'il y consent.
            </Content>
          </Stack>
        </Stack>
        <BoldTitle>Absence de cession et transfert</BoldTitle>
        <Content>
          Les Données Personnelles collectées ne feront l'objet d'aucune cession, location ou échange au bénéfice d'un pays tiers ou d'une
          organisation internationale.
        </Content>
        <Content>
          Toutefois, si elles l’étaient, TWININ garantit la personne concernée que tout transfert interviendrait dans le respect des dispositions du
          RGPD et notamment :
        </Content>
        <Stack spacing={1} pl={"20px"}>
          <Content>
            - Que ces transferts seraient réalisés au profit d’un pays ou un État dont la législation sur la protection des données est jugée comme
            suffisamment adéquat par la Commission européenne
          </Content>
          <Content>
            - A défaut, que les transferts seraient accompagnés par des outils de transferts permettant d’assurer leur conformité au RGPD
          </Content>
        </Stack>
        <BoldTitle>Exercice des droits des personnes concernées</BoldTitle>
        <Stack>
          <Content>Droit à l'accès aux données</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- La personne concernée a le droit d'obtenir de TWININ l'accès à ses Données Personnelles collectées par elle.</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Droit à la rectification des données</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>
              - La personne concernée a le droit d'obtenir de TWININ, dans les meilleurs délais, la rectification des Données Personnelles la
              concernant qui seraient inexactes. Elle a notamment le droit d'obtenir que les Données Personnelles incomplètes soient complétées.
            </Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Droit à l'effacement des données</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>
              - Dans le cadre de l'exercice de son droit à l'oubli, la personne concernée a le droit de solliciter l'effacement des Données
              Personnelles la concernant.
            </Content>
            <Stack>
              <Content>TWININ a l'obligation de procéder à l'effacement des Données Personnelles dans les situations suivantes :</Content>
              <Stack spacing={1} pl={"20px"}>
                <Content>
                  - Lorsque les Données Personnelles ne sont plus nécessaires au regard des finalités pour lesquelles elles ont été collectées
                </Content>
                <Content>
                  - En cas de retrait du consentement de la personne concernée sur lequel est fondé le traitement (et lorsqu'il n'existe pas d'autre
                  fondement juridique au traitement)
                </Content>
                <Content>
                  - En cas d'opposition au traitement des Données Personnelles par la personne concernée (et lorsqu'il n'existe pas de motif légitime
                  impérieux pour le traitement)
                </Content>
                <Content>- Lorsque les Données Personnelles font l'objet d'un traitement illicite</Content>
                <Content>- Lorsque les Données Personnelles doivent être effacées aux fins de respect d'une obligation légale.</Content>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Content>Droit à la limitation du traitement</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>
              La personne concernée pourra, dans les cas suivants, solliciter de TWININ la limitation du traitement de ses Données Personnelles, c'est
              à dire que ses données soient temporairement rendues inaccessibles :
            </Content>
            <Stack spacing={1} pl={"20px"}>
              <Content>- Lorsqu'elle conteste l'exactitude de l'une ou de plusieurs de ses Données Personnelles</Content>
              <Content>
                - Lorsque le traitement est illicite et que la personne concernée s'oppose à l'effacement des données et exige à la place une
                limitation de leur utilisation
              </Content>
              <Content>
                - Lorsque la personne concernée a besoin de ses données pour la constatation, l'exercice ou la défense de droits en justice et que
                TWININ n'en a plus besoin aux fins de traitement
              </Content>
              <Content>
                - Lorsque la personne concernée s'est opposée au traitement : dans ce cas, la limitation sera effective le temps de savoir si les
                motifs légitimes poursuivis par TWININ prévalent sur ceux de la personne concernée. TWININ s'engage à informer la personne concernée
                avant de rendre ses données de nouveau accessibles.
              </Content>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Content>Droit de s'opposer au traitement</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- La personne concernée a le droit de s'opposer, à tout moment, au traitement des Données Personnelles la concernant</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Droit à la portabilité des données</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>
              - La personne concernée peut demander à TWININ (i) de récupérer les Données Personnelles la concernant qu'elle lui a fournie ou (ii) de
              les transmettre à une autre responsable de traitement, dans la mesure où le traitement de ces données est effectué à l'aide de processus
              automatisés. En cas d'exercice de ce droit, la remise ou la transmission se fera dans un format structuré, couramment utilisé et lisible
              par machine.
            </Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Droit de réclamation</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- La personne concernée peut introduire une réclamation auprès d'une autorité de contrôle ou judiciaire.</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Droit de donner des directives en cas de décès</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- La personne concernée dispose du droit de donner des directives s'agissant du sort de ses données en cas de décès.</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Droit de retirer son consentement</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>
              - Si le traitement est fondé sur le consentement de la personne concernée, celle-ci peut retirer son consentement à tout moment, sans
              porter atteinte à la licéité du traitement fondé sur le consentement effectué avant le retrait de celui-ci.
            </Content>
          </Stack>
        </Stack>
        <Content>
          Pour exercer l'ensemble de ces droits, la personne concernée est tenue d'adresser un email à TWININ à l'adresse suivante :{" "}
          <AIOLinkInTxt label="info@twinin.fr" url={"mailto:info@twinin.fr"} />.
        </Content>
        <Content>
          Chaque demande doit être accompagnée d'un justificatif d'identité. En cas de doute raisonnable quant à l'identité de la personne requérante,
          il pourra lui être demandé des informations supplémentaires nécessaires pour confirmer son identité.
        </Content>
        <Content>En cas de demandes manifestement infondées, excessives ou répétées, TWININ se réserve le droit de refuser d'y donner suite.</Content>
        <BoldTitle>Durée de conservation des données</BoldTitle>
        <Content>Données relatives aux Recruteurs / Utilisateurs :</Content>
        <Content>
          Les Données Personnelles sont conservées pendant toute la durée de la relation contractuelle entre le Recruteur et TWININ et pendant un (1)
          an au-delà.
        </Content>
        <Content>Elles sont par la suite archivées pendant une durée de cinq (5) ans, à des fins probatoires.</Content>
        <Content>Données relatives à la dépose de cookies:</Content>
        <Content>
          Les cookies sont stockés sur le terminal du Recruteur / de l'Utilisateur pour une durée de treize (13) mois maximum et ce, sous réserve de
          ses choix. Pour plus d'information à ce sujet, le Recruteur ou l'Utilisateur est invité à prendre connaissance de la politique de gestion
          des cookies.
        </Content>
        <BoldTitle>Sécurité des données</BoldTitle>
        <Content>
          TWININ assure la sécurité des Données Personnelles en mettant en place des mesures organisationnelles et techniques appropriées afin de
          garantir un niveau de sécurité adapté au risque pour les droits et libertés des personnes concernées.
        </Content>
      </Stack>
    </Stack>
  );
};

export default PrivacyPolicyFR;
