import { Box, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";

import { companyDetailsService } from "@store/ai-o/companies/details";

import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import ModalComponent from "@components/modal/Modal.component";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

interface AddVideoModalProps {
  companyId: string;
  handleClose: () => void;
}

const AddVideoModal = (props: AddVideoModalProps) => {
  const { companyId, handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [link, setLink] = useState<string>("");

  const handleAdd = () => {
    setLoading(true);
    companyDetailsService
      .addYoutubeVideo(companyId, link)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.video.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const errors = {
    link: getFieldError(link, FieldValidationType.REQUIRED_YOUTUBE_LINK),
  };

  return (
    <ModalComponent
      title={t("aio.clients.details.video.addTitle")}
      titleLeft
      maxWidth="xs"
      content={
        <Box p="10px">
          <Grid container columnSpacing="30px" rowSpacing="20px">
            <Grid item xs={12}>
              <AIOTextfieldComponent
                required
                title={t("aio.clients.details.video.fields.link")}
                placeholder={t("aio.clients.details.video.fields.linkExample")}
                value={link || ""}
                onChange={setLink}
                error={errors.link}
              />
              {link ? (
                <Box mt={errors.link ? "40px" : "20px"}>
                  <embed
                    width="100%"
                    height="200"
                    src={`https://www.youtube.com/embed/${link.split("https://www.youtube.com/watch?v=")[1]}`}
                    style={{ borderRadius: "10px" }}
                  />
                </Box>
              ) : null}
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          ariaLabel="modal"
          disabled={loading || checkIfErrors(errors)}
          onClick={handleAdd}
          title={t("global.add")}
        />
      }
      handleClose={handleClose}
    />
  );
};

export default AddVideoModal;
