import { catchError, from, map, Observable, switchMap, tap } from "rxjs";
import { personaeStore } from "@store/personae/personae.store";
import { Personae, PersonaeFilters, PersonaeSort } from "@store/personae/personae.model";
import { getPersonaeDataSource, getPersonaeQuizDataSource } from "@store/personae/personae.requests";
import { setEntities } from "@ngneat/elf-entities";
import APIAxios, { APIRoutes } from "@api/axios.api";
import { AxiosError, AxiosResponse } from "axios";
import SnackError from "@utils/error.utils";
import { sessionQuery } from "@store/session";
import { ManagePersonaeQuizAnswer, PersonaeQuiz, PersonaeQuizCategory } from "@store/personae/personaeQuiz.model";

export class PersonaeService {
  store = personaeStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<PersonaeFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  setSort = (sort: Partial<PersonaeSort>) =>
    this.store.update((state) => ({
      ...state,
      sort: {
        ...state.sort,
        ...sort,
      },
    }));

  getAllPersonae = (filters?: PersonaeFilters, sort?: PersonaeSort): Observable<Personae[]> => {
    const affiliateIds = filters?.affiliateIds
      ? filters.affiliateIds.map((c) => c.value)
      : sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined;

    return from(
      APIAxios({
        ...APIRoutes.GETAllPersonae(),
        params: {
          search: filters?.search || undefined,
          affiliateIds,
          favorite: sessionQuery.affiliateIds?.some((c) => c.value === "FAVORITES") || undefined,
          sort: sort?.field,
          order: sort?.field ? 1 : undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Personae[]>) => {
        return response.data;
      }),
      tap((personae) => {
        this.store.update(setEntities(personae), getPersonaeDataSource.setSuccess());
      }),
      getPersonaeDataSource.trackRequestStatus()
    );
  };

  getPersonaeQuiz = (affiliateId?: string): Observable<PersonaeQuiz> => {
    return from(APIAxios({
      ...APIRoutes.GETPersonaeQuiz(),
      params: { affiliateId },
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PersonaeQuiz>) => {
        return response.data;
      }),
      tap((quiz) => {
        this.store.update((state) => ({ ...state, quiz }), getPersonaeQuizDataSource.setSuccess());
      }),
      getPersonaeQuizDataSource.trackRequestStatus()
    );
  };

  addPersonaeQuizAnswer = (answer: ManagePersonaeQuizAnswer, category: PersonaeQuizCategory): Observable<PersonaeQuiz> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTPersonaeQuizAnswer(),
        data: {
          type: category,
          name: answer.name,
          affiliates: answer.affiliates?.map((a) => a.value),
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getPersonaeQuiz())
    );
  };

  updatePersonaeQuizAnswer = (answerId: string, answer: ManagePersonaeQuizAnswer): Observable<PersonaeQuiz> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHPersonaeQuizAnswer(answerId),
        data: {
          type: answer.type,
          name: answer.name,
          affiliates: answer.affiliates?.map((a) => a.value),
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getPersonaeQuiz())
    );
  };

  createPersonae = (affiliateId: string, personaeOptions: PersonaeQuiz): Observable<Personae[]> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTPersonaeProfile(affiliateId),
        data: {
          list: Object.keys(personaeOptions).reduce((list, category) => {
            return list.concat(personaeOptions[category]?.map((o) => o.id) ?? []);
          }, [] as string[]),
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => this.getAllPersonae(this.store.getValue().filters, this.store.getValue().sort))
    );
  };

  getPersonaeProfile = (personaeId: string): Observable<Personae> => {
    return from(APIAxios(APIRoutes.GETPersonaeProfile(personaeId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Personae>) => {
        return response.data;
      })
    );
  };

  updatePersonaeProfileOptions = (personaeId: string, personaeOptions: PersonaeQuiz): Observable<Personae> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHPersonaeProfile(personaeId),
        data: {
          list: Object.keys(personaeOptions).reduce((list, category) => {
            return list.concat(personaeOptions[category]?.map((o) => o.id) ?? []);
          }, [] as string[]),
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Personae>) => {
        return response.data;
      })
    );
  };

  updatePersonaeProfileField = (personaeId: string, data: Partial<Personae>): Observable<Personae> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHPersonaeProfile(personaeId),
        data: {
          experience: data.experience || undefined,
          location: data.location || undefined,
          associatedTitles: data.associatedTitles || undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Personae>) => {
        return response.data;
      })
    );
  };

  validatePersonaeProfile = (personaeId: string): Observable<Personae> => {
    return from(
      APIAxios({
        ...APIRoutes.PATCHPersonaeProfile(personaeId),
        data: { isFinished: true },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Personae>) => {
        return response.data;
      })
    );
  };
}

export const personaeService = new PersonaeService();
