import ModalComponent from "@components/modal/Modal.component";
import { alpha, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import AIOButtonComponent from "@components/Button.component";
import { sessionQuery, sessionService } from "@store/session";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AvatarPickerComponent from "@components/input/AvatarPicker.component";
import { Colors } from "@constants/colors.constant";
import ModifyPasswordModal from "@components/modal/ModifyPassword.modal";
import { Pages } from "@utils/routes.utils";
import { manageUserFromUser, ManageUserModel } from "@store/users";
import { linkedinService } from "@store/linkedin";

interface ProfileModalProps {
  handleClose: () => void;
}

const ProfileModal = (props: ProfileModalProps) => {
  const { handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openSelectAvatar, setOpenSelectAvatar] = useState(false);
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [user, setUser] = useState<ManageUserModel>({});

  useEffect(() => {
    sessionService.fetchCurrentUser().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });

    sessionQuery.user$.subscribe((u) => setUser(u ? manageUserFromUser(u) : {}));
  }, [enqueueSnackbar]);

  if (!user?.id) return null;

  const handleSynchronizeWithLinkedin = () => {
    linkedinService.connectToLinkedin(Pages.LINKEDIN, window.location.pathname);
  };

  const showPostalCode = process.env.REACT_APP_COUNTRY === "US";

  const errors = {
    firstname: getFieldError(user?.firstname, FieldValidationType.REQUIRED_STRING),
    lastname: getFieldError(user?.lastname, FieldValidationType.REQUIRED_STRING),
    position: getFieldError(user?.position, FieldValidationType.REQUIRED_STRING),
    ...(showPostalCode ? { postalCode: getFieldError(user.postalCode, FieldValidationType.REQUIRED_STRING) } : {}),
  };

  const updateUser = (name: string) => (value: any) => setUser((state) => ({ ...state, [name]: value }));

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        title={t("account.title")}
        titleLeft
        content={
          <Stack width="100%" spacing={3} pb="20px" pt="20px">
            <Stack alignItems="center">
              <Grid item width="min-content" xs={1}>
                <IconButton
                  aria-label="avatar-circle"
                  onClick={() => setOpenSelectAvatar(true)}
                  sx={{
                    background:
                      user.pictureFile || user.picture
                        ? `linear-gradient(${alpha(Colors.black, 0.4)}, ${alpha(Colors.black, 0.4)}), url(${
                            user.pictureFile ? URL.createObjectURL(user.pictureFile) : user.picture
                          })`
                        : "",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}>
                  <img alt="" src="/images/camera.svg" />
                </IconButton>
              </Grid>
            </Stack>
            <Stack spacing={3} width="100%">
              <Stack flex={1} direction="row" spacing={3} justifyContent="space-between">
                <Stack width="100%">
                  <AIOTextfieldComponent error={errors.lastname} title={t("global.name")} value={user?.lastname} onChange={updateUser("lastname")} />
                </Stack>
                <Stack width="100%">
                  <AIOTextfieldComponent
                    error={errors.firstname}
                    title={t("global.firstName")}
                    onChange={updateUser("firstname")}
                    value={user?.firstname}
                  />
                </Stack>
              </Stack>
              <Stack flex={1} direction="row" spacing={3} justifyContent="space-between">
                <Stack width="100%">
                  {showPostalCode ? (
                    <AIOTextfieldComponent
                      title={t("userDetails.postalCode")}
                      value={user.postalCode ?? ""}
                      error={errors.postalCode}
                      pattern={/[0-9]+/}
                      onChange={updateUser("postalCode")}
                    />
                  ) : (
                    <AIOTextfieldComponent readonly title={t("global.mail")} value={user?.email} />
                  )}
                </Stack>
                <Stack width="100%">
                  <AIOTextfieldComponent
                    error={errors.position}
                    title={t("global.jobTitle")}
                    onChange={updateUser("position")}
                    value={user?.position}
                  />
                </Stack>
              </Stack>
              {showPostalCode && (
                <Stack flex={1} direction="row" spacing={3} justifyContent="space-between">
                  <Stack width="100%">
                    <AIOTextfieldComponent readonly title={t("global.mail")} value={user?.email} />
                  </Stack>
                  <Stack width="100%"></Stack>
                </Stack>
              )}
              <Stack spacing={1}>
                <Typography>{t("account.security")}</Typography>
                <Stack direction="row" alignItems="center" spacing={3} justifyContent="space-between">
                  <Stack width="100%">
                    <AIOTextfieldComponent readonly title={t("global.password")} type="password" value="*********" />
                  </Stack>
                  <Stack width="100%">
                    <Typography color="primary" sx={{ cursor: "pointer", marginTop: "14px" }} onClick={() => setOpenResetPassword(true)}>
                      {t("account.modifyPassword")}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Stack spacing={1}>
                <Typography>{t("linkedin.connect")}</Typography>
                <Stack direction="row" alignItems="center" spacing={3} justifyContent="space-between">
                  <Stack width="100%">
                    {user.linkedinTag ? (
                      <AIOTextfieldComponent readonly title={t("linkedin.linkAccount")} type="text" value={user.linkedinTag} />
                    ) : (
                      <AIOTextfieldComponent
                        ariaLabel="textfield-button"
                        readonly
                        title={t("linkedin.linkAccount")}
                        type="text"
                        value={t("linkedin.syncronize")}
                        onClick={handleSynchronizeWithLinkedin}
                      />
                    )}
                  </Stack>
                  <Stack width="100%">
                    {user.linkedinTag && (
                      <Typography color="primary" sx={{ cursor: "pointer", marginTop: "14px" }} onClick={handleSynchronizeWithLinkedin}>
                        {t("linkedin.syncronize")}
                      </Typography>
                    )}
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        }
        maxWidth="sm"
        actions={
          <AIOButtonComponent
            variant="contained"
            disabled={checkIfErrors(errors)}
            onClick={() => {
              if (!user) return;

              sessionService.updateUser(user).subscribe({
                next: () => {
                  enqueueSnackbar(t("snackbar.userUpdated"), { variant: "success" });
                  handleClose();
                },
                error: (err) => enqueueSnackbar(err.text, err.options),
              });
            }}
            title={t("account.save")}
          />
        }
      />
      {openSelectAvatar && (
        <AvatarPickerComponent
          picture={user.picture}
          pictureFile={user.pictureFile}
          handleChangePicture={(file, avatar) => {
            setUser((state) => ({ ...state, pictureFile: file, picture: avatar }));
          }}
          handleClose={() => setOpenSelectAvatar(false)}
        />
      )}
      {openResetPassword && <ModifyPasswordModal handleClose={() => setOpenResetPassword(false)} />}
    </>
  );
};

export default ProfileModal;
