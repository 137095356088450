import { PaginatedMetaData } from "@utils/infinitescroll.utils";
import { EntityDetailsModel, ManageEntityDetailsModel, ManageEntityParametersModel } from "@store/entities/details/entityDetails.model";
import { sessionQuery } from "@store/session";
import { UserModel, UserRoleEnum } from "@store/users";
import { Action } from "@store/common/actions.model";
import { Comment } from "@store/common/comments.model";

export interface ManageClientDetailsModel extends ManageEntityDetailsModel {}

export const manageClientDetailsToFormData = (client: ManageClientDetailsModel) => {
  const formData = new FormData();

  if (client.name) formData.append("name", client.name);
  if (client.address) formData.append("address", client.address);
  if (client.city) formData.append("city", client.city);
  formData.append("description", client.description ?? "");
  formData.append("otherInformation", client.otherInformation ?? "");
  formData.append("contacts", JSON.stringify(client.contacts ?? []));

  if (client.isAlsoAffiliate && sessionQuery.role === UserRoleEnum.ADMIN) {
    formData.append("isAlsoAffiliate", client.isAlsoAffiliate.toString());
    formData.append("twinnerIds", JSON.stringify(client.twinners?.filter((t) => !!t)?.map((t) => t!.value) ?? []));
  }

  if (client.pictureFile) formData.append("picture", client.pictureFile);
  if (client.pictureURL) formData.append("pictureURL", client.pictureURL);

  return formData;
};

export interface ManageClientParametersModel extends ManageEntityParametersModel {}

export interface ClientDetailsDetailsModel extends EntityDetailsModel {}

export interface ClientDetailsModel {
  details?: ClientDetailsDetailsModel;
  comments?: Comment[];
  users?: UserModel[];
  actions?: Action[];
  actionsPaginatedMeta?: PaginatedMetaData;
}
