import React, { useState } from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import AIORoutesTabComponent from "@components/AIORoutesTab.component";
import { AdminAioRoutes } from "@utils/routes.utils";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AIOButtonComponent from "@components/Button.component";
import AioTutorialModal from "./AioTutorialModal";

const Aio = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [openTutorial, setOpenTutorial] = useState(false);

  return (
    <Stack px={breakpointDownSM ? "10px" : "40px"} flex={1} width="100%" overflow="auto">
      <Stack direction="row" justifyContent="space-between" alignItems="center" py={breakpointDownSM ? "20px" : "40px"}>
        <Typography fontSize={breakpointDownSM ? "30px" : "35px"} fontWeight="700" mr="20px">
          {t("aio.title")}
        </Typography>
        <AIORoutesTabComponent routes={AdminAioRoutes.routes} />
        <AIOButtonComponent title={t("aio.tutorial")} variant="contained" ariaLabel="modal" onClick={() => setOpenTutorial(true)} />
      </Stack>
      {openTutorial && <AioTutorialModal handleClose={() => setOpenTutorial(false)} />}
      <Outlet />
    </Stack>
  );
};

export default Aio;
