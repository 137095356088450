import { Box, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { Observable } from "rxjs";

import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import CheckCircle from "@mui/icons-material/CheckCircle";

import { ActionTimeline, TimelineEmailTemplateEnum } from "@store/entities/entities.model";
import { affiliateDetailsService } from "@store/entities/afiliates/details";

import { Colors } from "@constants/colors.constant";

import UsersChoiceForMailing from "./UsersChoiceForMailing.modal";

interface SortableActionRowProps {
  action: ActionTimeline;
  handleDeleteAction: (actionTitleToDelete: string) => void;
  handleEditAction: (editedAction: ActionTimeline) => void;
}

const SortableActionRow = (props: SortableActionRowProps) => {
  const { action, handleDeleteAction, handleEditAction } = props;

  const { t } = useTranslation();
  const { entityId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: action.name });

  const [openChoseUserModal, setOpenChoseUserModal] = useState(false);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 10 : 1,
    opacity: isDragging ? 0.7 : 1,
  };

  const validateObservable = (emails?: string[]): Observable<any> | undefined => {
    if (!entityId) return;
    return affiliateDetailsService.validateAffiliateTimelineAction(entityId, action.name, emails);
  };

  const handleValidate = () => {
    if (action.emailTemplateName && action.emailTemplateName !== TimelineEmailTemplateEnum.NO_TEMPLATE) {
      setOpenChoseUserModal(true);
      return;
    }
    validateObservable()?.subscribe({ error: (err) => enqueueSnackbar(err.text, err.options) });
  };

  return (
    <>
      <div ref={setNodeRef} style={style}>
        <Stack spacing={"12px"} direction="row" justifyContent="center" alignItems="center">
          <IconButton {...attributes} {...listeners} style={{ touchAction: "none" }}>
            <img src="/images/icon_button_move_action.svg" alt="" />
          </IconButton>
          <Stack
            sx={{ width: "100%" }}
            bgcolor={Colors.greyCardBg}
            padding={3}
            borderRadius={"15px"}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            justifySelf={"stretch"}
            boxShadow={isDragging ? 3 : 0}>
            <Stack direction={"row"} spacing={"8px"} alignItems={"center"}>
              <Tooltip title={action.strategy || t("subscriptions.noStrategy")} arrow placement="top">
                <img alt="information" src="/images/icon_informations.svg" />
              </Tooltip>
              <Typography fontSize="16px" fontWeight={700}>
                {action.name}
              </Typography>
            </Stack>
            <Typography fontSize="16px" fontWeight={700}>
              {`${t("subscriptions.dayAbbreviation")}+${action.j}`}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <IconButton onClick={() => handleDeleteAction(action.name)}>
              <img height="23px" src="/images/icon_delete.svg" alt="delete" />
            </IconButton>
            <IconButton onClick={() => handleEditAction(action)}>
              <img height="23px" src="/images/edit.svg" alt="edit" />
            </IconButton>
            {action.isPassed ? (
              <IconButton disabled sx={{ opacity: 1 }}>
                <CheckCircle htmlColor={Colors.green} />
              </IconButton>
            ) : (
              <IconButton onClick={handleValidate}>
                <CheckCircleOutline style={{ width: 26 }} color="primary" />
              </IconButton>
            )}
          </Stack>
        </Stack>
      </div>
      {openChoseUserModal && (
        <UsersChoiceForMailing handleValidate={(emails) => validateObservable(emails)} handleCloseModal={() => setOpenChoseUserModal(false)} />
      )}
    </>
  );
};
export default SortableActionRow;
