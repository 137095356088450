import { select } from "@ngneat/elf";
import { getPersonalitiesDataSource } from "./personalities.requests";
import { personalitiesStore } from "./personalities.store";

export class PersonalitiesQuery {
  store = personalitiesStore;

  personalities$ = getPersonalitiesDataSource.data$();

  title$ = this.store.pipe(select((state) => state.title));
}

export const personalitiesQuery = new PersonalitiesQuery();
