import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { CompaniesFilters, CompanyLight, CompanySortEnum, CompanySortModel } from "./companies.model";
import { withPagination } from "@ngneat/elf-pagination";

interface CompaniesStoreProps {
  notReattachedWithCandidatesCount?: number;
  filters: CompaniesFilters;
  sort: CompanySortModel;
}

export const companiesStore = createStore(
  { name: "companies" },
  withProps<CompaniesStoreProps>({
    filters: {},
    sort: { field: CompanySortEnum.NAME },
  }),
  withEntities<CompanyLight>(),
  withRequestsStatus(),
  withPagination()
);
