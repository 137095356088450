import { CircularProgress, Container, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { adDetailsQuery, adDetailsService } from "@store/ads/details";
import ActionsTimelineComponent from "@components/ActionsTimeline.component";
import BackToTopButton from "@components/BackToTopButton.component";

const AdsHistoricTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { adId } = useParams();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12;
  const scrollableRef = useRef<HTMLDivElement | null>(null);

  const [{ adActions: actions, loading }] = useObservable(adDetailsQuery.actions$);
  const [paginatedMetaData] = useObservable(adDetailsQuery.actionsPaginatedMeta$);

  useEffect(() => {
    if (adId) {
      adDetailsService.getAdHistory(adId, currentPage, itemsPerPage).subscribe({
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [adId, enqueueSnackbar, currentPage, itemsPerPage]);

  const backToTop = () => {
    if (scrollableRef.current)
      scrollableRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  return (
    <Stack width="100%" alignItems="center" py={breakpointDownSM ? "20px" : "40px"} overflow="auto" className="scrollable" ref={scrollableRef}>
      <Container maxWidth="md" sx={{ px: breakpointDownSM ? "4px" : undefined }}>
        <Stack width="100%">
          {loading ? (
            <CircularProgress sx={{ alignSelf: "center" }} size={20} />
          ) : (
            <ActionsTimelineComponent
              actions={actions ?? []}
              setCurrentPage={setCurrentPage}
              paginatedMetaData={paginatedMetaData}
              breakpointDownSM={breakpointDownSM}
            />
          )}
        </Stack>
      </Container>
      <Stack direction="row" width="100%" justifyContent="center" mt="20px" height={"80px"} alignItems="center">
        {loading && <CircularProgress size={30} />}
      </Stack>
      <BackToTopButton onClick={backToTop} posRight={breakpointDownSM ? "25px" : "60px"} />
    </Stack>
  );
};

export default AdsHistoricTab;
