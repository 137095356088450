import React from 'react'
import {Chip} from '@mui/material';

interface Props {
  label: string;
  color?: "primary" | "secondary";
  variant?: "diffusion" | "filled" | "outlined";
}

const ChipComponent = ({color = "primary", variant = "diffusion", label}: Props) => {
  return (
    <Chip
      color={color}
      variant={variant}
      label={label}/>
  )
}

export default ChipComponent