import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";
import { Outlet, useNavigate } from "react-router-dom";

import { bannersQuery, bannersService, searchBannersEffect } from "@store/banners";

import AIOSearchComponent from "@components/input/AIOSearch.component";
import ClientsFilterComponent from "@components/input/ClientsFilterComponent";
import { SelectItem } from "@components/input/Select.component";
import AIOButtonComponent from "@components/Button.component";
import BannerCard from "@components/card/Banner.card";

const AdminBannerTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const searchBanners = useEffectFn(searchBannersEffect);

  const [{ banners, error, loading }] = useObservable(bannersQuery.banners$);

  const [filters] = useObservable(bannersQuery.filters$);
  const [selectedAffiliates, setSelectedAffiliates] = useState<SelectItem[]>([]);

  useEffect(() => {
    bannersService.resetStore();
  }, []);

  useEffect(() => {
    if (error) enqueueSnackbar((error as any).text, (error as any).options);
  }, [enqueueSnackbar, error]);

  useEffect(() => {
    searchBanners({ filters });
  }, [filters, searchBanners]);

  return (
    <Stack px={breakpointDownSM ? "10px" : "40px"} flex={1} width="100%" overflow="auto">
      <Stack py={breakpointDownSM ? "20px" : undefined} style={{overflowX: 'visible', overflowY: 'auto'}}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" p="3px">
          <Stack
            direction={breakpointDownSM ? "column-reverse" : "row"}
            width="100%"
            alignItems="center"
            spacing={breakpointDownSM ? 0 : 3}
            justifyContent={breakpointDownSM ? "flex-start" : "flex-end"}
            mb={breakpointDownSM ? "20px" : 0}>
            <AIOSearchComponent
              width="100%"
              placeholder={t("adminParameters.banners.search")}
              value={filters.search}
              onChange={(value) => bannersService.setFilters({ search: value })}
            />
            <Box width="250px" flexShrink={0}>
              <ClientsFilterComponent
                noStorageUpdate
                handleChange={(values) => {
                  setSelectedAffiliates(values);
                  bannersService.setFilters({ affiliates: values });
                }}
                values={selectedAffiliates}
              />
            </Box>

            <Box minWidth={"200px"} mb={breakpointDownSM ? "20px" : "0px"}>
              <AIOButtonComponent
                fullWidth
                variant="contained"
                color="secondary"
                onClick={() => navigate("add")}
                title={t("adminParameters.banners.addBanner")}
              />
            </Box>
          </Stack>
        </Stack >
        {loading ? (
          <Stack width={"100%"} justifyContent={"center"} alignItems={"center"} mt="50px !important">
            <CircularProgress size={20} />
          </Stack>
        ) : (
          <Box
            overflow={breakpointDownSM ? undefined : "auto"}
            className={breakpointDownSM ? undefined : "scrollable"}
            py={breakpointDownSM ? "0px" : "30px"}
            px="10px">
            <Stack spacing={"15px"}>
              {banners.map((banner) => (
                <Grid item xs={6} sm={4} lg={3} key={banner.id}>
                  <BannerCard banner={banner} onClick={() => navigate(`${banner.id}`)} />
                </Grid>
              ))}
            </Stack>
          </Box>
        )}
      </Stack>
      <Outlet/>
    </Stack>
  );
};

export default AdminBannerTab;
