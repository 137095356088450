import { CircularProgress, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";
import { useEffect } from "react";
import { useSnackbar } from "notistack";

import { reportsQuery, reportsService, searchReportsEffect } from "@store/reports";
import { sessionQuery } from "@store/session";

import AIOSearchComponent from "@components/input/AIOSearch.component";
import DatePickerComponent from "@components/input/DatePicker.component";
import ReportCard from "@components/card/Report.card";
import { emitOnce } from "@ngneat/elf";

const FileReportsTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const breakpointDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const searchReports = useEffectFn(searchReportsEffect);

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);
  const [filters] = useObservable(reportsQuery.filters$);

  const [{ reports, error: reportsError, loading: reportsLoading }] = useObservable(reportsQuery.reports$);

  useEffect(() => {
    emitOnce(() => {
      reportsService.resetStore();
      if (clientViewClient) {
        reportsService.setFilters({ affiliateIds: [{ value: clientViewClient.id, label: "" }] });
      }
    });
  }, [clientViewClient]);

  useEffect(() => {
    if (reportsError) enqueueSnackbar((reportsError as any).text, (reportsError as any).options);
  }, [reportsError, enqueueSnackbar]);

  useEffect(() => {
    if ((filters.from && filters.to) || (!filters.from && !filters.to)) {
      searchReports({ filters });
    }
  }, [filters, searchReports]);

  return (
    <Stack spacing={breakpointDownSM ? 0 : 4} flex={1} width="100%" overflow="hidden">
      <Stack overflow="auto" className={breakpointDownSM ? "scrollable" : undefined}>
        <Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent={breakpointDownMD ? "center" : "space-between"}
            flexWrap={breakpointDownMD ? "wrap" : "nowrap"}>
            <Stack width={breakpointDownMD ? "100%" : "auto"} mr={breakpointDownMD ? 0 : "20px"} mb={breakpointDownMD ? "20px" : 0}>
              <AIOSearchComponent
                width={breakpointDownMD ? "100%" : breakpointDownLG ? "25vw" : "40vw"}
                placeholder={t("reports.searchPlaceholder")}
                onChange={(value) => reportsService.setFilters({ search: value })}
              />
            </Stack>
            <Stack alignItems="center" direction="row" spacing={breakpointDownMD ? 1 : 3}>
              <Typography fontWeight={300}>{t("ads.export.from")}</Typography>
              <DatePickerComponent
                placeholder={t("global.select")}
                color="secondary"
                handleChange={(from) => reportsService.setFilters({ from: from })}
                value={filters.from}
              />
              <Typography fontWeight={300}>{t("ads.export.to")}</Typography>
              <DatePickerComponent
                placeholder={t("global.select")}
                color="secondary"
                handleChange={(to) => reportsService.setFilters({ to: to })}
                value={filters.to}
              />
            </Stack>
          </Stack>
        </Stack>
        {reportsLoading ? (
          <CircularProgress style={{ alignSelf: "center" }} size={20} />
        ) : (
          <Stack
            mt="10px"
            py={breakpointDownSM ? "20px" : "25px"}
            spacing={2}
            px="10px"
            overflow={breakpointDownSM ? undefined : "auto"}
            className={breakpointDownSM ? undefined : "scrollable"}>
            {reports.map((report) => (
              <ReportCard key={report.id} small={breakpointDownSM} report={report} />
            ))}
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

export default FileReportsTab;
