import React, { useState } from "react";
import { finalize } from "rxjs";
import { useTranslation } from "react-i18next";
import { Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";

import { FieldErrors } from "@utils/yup.utils";

import { sessionService } from "@store/session";

import { forgotPasswordValidation } from "@screens/unauth/forgotPassword/forgotPassword.yup";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";

interface ForgotPasswordModalProps {
  handleClose: () => void;
}

const ForgotPasswordModal = (props: ForgotPasswordModalProps) => {
  const { handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<FieldErrors>({});

  const handleValidate = () => {
    const errors = forgotPasswordValidation(email);

    setErrors(errors);
    if (!!Object.keys(errors).length) return;

    setLoading(true);
    sessionService
      .forgotPassword(email)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("auth.forgotPassword.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
  };

  return (
    <ModalComponent
      maxWidth={breakpointDownSM ? "xs" : false}
      fullWidth={breakpointDownSM}
      handleClose={handleClose}
      title={t("auth.forgotPassword.title")}

      content={
        <Stack width={breakpointDownSM ? "auto" : "350px"} pt="30px" spacing={3}>
          <Typography lineHeight={1.25} textAlign="center" alignSelf="center">
            {t("auth.forgotPassword.helper")}
          </Typography>
          <AIOTextfieldComponent
            title={t("auth.email")}
            placeholder={t("auth.email")}
            onKeyDown={(e) => (e.key === "Enter" ? handleValidate() : undefined)}
            onChange={setEmail}
            value={email}
            error={errors.email}
          />
        </Stack>
      }
      actions={
        <Button variant="contained" color="secondary" disabled={!email || loading} onClick={handleValidate}>
          {t("global.validate")}
        </Button>
      }
    />
  );
};

export default ForgotPasswordModal;
