import { Colors } from "@constants/colors.constant";
import I18nUtils from "@utils/i18n.utils";
import { Action } from "@store/common/actions.model";
import { BoostModel } from "@store/boosts";
import { AdModel } from "@store/ads";
import { SelectItem } from "@components/input/Select.component";

interface ClientApplicationsDistributionSource {
  applicationNbr: number;
}

export interface ClientApplicationsDistribution {
  [source: string]: ClientApplicationsDistributionSource;
}

export enum DashboardStat {
  ONLINE_ADS = "ONLINE_ADS",
  QUALITY_RATE = "QUALITY_RATE",
  APPLICATIONS = "APPLICATIONS",
  INEFFICIENT = "INEFFICIENT",
  CLIENTS = "CLIENTS",
  BOOSTS = "BOOSTS",
  ADMIN_NEW_ADS = "ADMIN_NEW_ADS",
  ADMIN_NEW_BOOSTS = "ADMIN_NEW_BOOSTS",
  ADMIN_NEW_SUBSCRIPTION = "ADMIN_NEW_SUBSCRIPTION"
}

export namespace DashboardStat {
  export const bgColor = (stat: DashboardStat) => {
    switch (stat) {
      case DashboardStat.ONLINE_ADS:
        return Colors.lightYellow;
      case DashboardStat.QUALITY_RATE:
        return Colors.lightpurple;
      case DashboardStat.APPLICATIONS:
        return Colors.lightPink;
      case DashboardStat.INEFFICIENT:
        return Colors.lightGreen;
      case  DashboardStat.CLIENTS:
        return Colors.lightpurple;
      case DashboardStat.BOOSTS:
        return Colors.lightBlue;
      default:
        return "transparent";
    }
  };

  export const label = (stat: DashboardStat, modal?: boolean) => {
    switch (stat) {
      case DashboardStat.ONLINE_ADS:
        return I18nUtils.t(`dashboard.${modal ? "adsList" : "stats"}.onlineAds`);
      case DashboardStat.QUALITY_RATE:
        return I18nUtils.t(`dashboard.${modal ? "adsList" : "stats"}.qualityRate`);
      case DashboardStat.APPLICATIONS:
        return I18nUtils.t(`dashboard.${modal ? "adsList" : "stats"}.applications`);
      case DashboardStat.INEFFICIENT:
        return I18nUtils.t(`dashboard.${modal ? "adsList" : "stats"}.inefficientAds`);
      case DashboardStat.CLIENTS:
        return I18nUtils.t(`dashboard.${modal ? "adsList" : "stats"}.clients`);
      case DashboardStat.BOOSTS:
        return I18nUtils.t(`dashboard.${modal ? "adsList" : "stats"}.boostsInProgress`);
      default:
        return "";
    }
  };

  export const icon = (stat: DashboardStat) => {
    switch (stat) {
      case DashboardStat.ONLINE_ADS:
        return "/images/icon_jobs_online.svg";
      case DashboardStat.QUALITY_RATE:
        return "/images/icon_good_jobs.svg";
      case DashboardStat.APPLICATIONS:
        return "/images/icon_inconspicuous_jobs.svg";
      case DashboardStat.INEFFICIENT:
        return "/images/icon_unattractive_jobs.svg";
      case DashboardStat.CLIENTS:
        return "/images/icon_customer.png";
      case DashboardStat.BOOSTS:
        return "/images/icon_boost_rocket.svg";
      default:
        return "";
    }
  };
}

export interface StatsFilters {
  affiliateIds?: SelectItem[];
}

export interface AffiliatesStats {
  announcementNbr: number;
  aioApplicationNbr: number;
  powerBiApplicationNbr: number;
  quality: number;
  inefficient: AdModel[];
  boosts: BoostModel[];
  lastActions: Action[];
}

export interface AdminStats {
  announcementNbr: number;
  affiliateNbr: number;
  boosts: BoostModel[];
  newBoost: BoostModel[];
  lastActions: Action[];
}

export interface GlobalStatistics {
  filters: StatsFilters;
  affiliates?: AffiliatesStats;
  admin?: AdminStats;
}
