import { EntityFavoritesFilterEnum, EntityTypeEnum } from "@store/entities/entities.model";

import I18nUtils from "@utils/i18n.utils";

import { SelectItem } from "@components/input/Select.component";

export enum EntitiesSortFieldEnum {
  NAME = "name",
  CITY = "city",
}

export namespace EntitiesSortFieldEnumFunctions {
  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("clients.sort.byName"), value: EntitiesSortFieldEnum.NAME },
    { label: I18nUtils.t("clients.sort.byLocation"), value: EntitiesSortFieldEnum.CITY },
  ];
}

export interface EntitiesSortModel {
  field: EntitiesSortFieldEnum;
}

export interface EntitiesFiltersModel {
  search?: string;
  city?: string;
  type?: EntityTypeEnum;
  favorites?: EntityFavoritesFilterEnum;
}

