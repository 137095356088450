import styled from "@emotion/styled";
import { Stack } from "@mui/system";
import { useTranslation } from "react-i18next";
import React, { useCallback, useState } from "react";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";
import { CircularProgress, IconButton, Tooltip, Typography } from "@mui/material";

import {
  UnipileInvitationModel,
  unipileInvitationsService,
  UnipileInvitationStatusEnum,
  UnipileInvitationStatusEnumFunctions
} from "@store/unipile/unipileInvitations";
import { PotentialProfile } from "@store/ads/details";

import { Colors } from "@constants/colors.constant";

import PotentialProfileCard from "@components/card/PotentialProfileCard.card";

const BadgeStatus = styled(Stack)`
  border-radius: 5px;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 500;
  padding: 2px 8px;
  position: absolute;
  top: -8px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  white-space: nowrap;
`;

const ResendInvitationButtonContainerStyled = styled(Stack)({
  borderRadius: "5px",
  fontSize: "12px",
  position: "absolute",
  top: "8px",
  right: "8px",
  zIndex: 1,
  whiteSpace: "nowrap",
  "& .MuiButtonBase-root": {
    backgroundColor: Colors.primary,
    color: Colors.white,
    width: "30px",
    height: "30px",
  },
});

interface UnipileLinkedinInvitationCardProps {
  invitation: UnipileInvitationModel;
}

const UnipileLinkedinInvitationCard = (props: UnipileLinkedinInvitationCardProps) => {
  const { invitation } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loadingResend, setLoadingResend] = useState(false);

  const resendInvitation = useCallback(() => {
    setLoadingResend(true);
    unipileInvitationsService.resendUnipileLinkedinInvitation(invitation.id).pipe(finalize(() => setLoadingResend(false)))
      .subscribe({
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
  }, [enqueueSnackbar, invitation.id]);

  return (
    <Stack position="relative">
      <BadgeStatus
        id="status-container"
        bgcolor={UnipileInvitationStatusEnumFunctions.color(invitation.status)}
        alignItems="center"
        justifyContent="center">
        {UnipileInvitationStatusEnumFunctions.selectItems.find((r) => r.value === invitation.status)?.label ?? ""}
      </BadgeStatus>
      {[UnipileInvitationStatusEnum.LIMIT_REACHED, UnipileInvitationStatusEnum.ERROR].includes(invitation.status) && (
        <Tooltip
          title={(
            <Typography fontSize={12} fontWeight={500} textAlign="center">
              {t("ads.details.potentialProfilesTab.resendInvitation")}
            </Typography>
          )}
          arrow
          placement="top">
          <ResendInvitationButtonContainerStyled id="resend-invite-btn-container" alignItems="center" justifyContent="center">
            {loadingResend && <CircularProgress size={20} />}
            {!loadingResend && (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  resendInvitation();
                }}
                size="small">
                <img alt="" src="/images/icon_refresh_white.svg"/>
              </IconButton>
            )}
          </ResendInvitationButtonContainerStyled>
        </Tooltip>
      )}
      <PotentialProfileCard profile={invitation as PotentialProfile}/>
    </Stack>
  );
};
export default UnipileLinkedinInvitationCard;