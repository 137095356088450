import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import { finalize, forkJoin, switchMap } from "rxjs";
import { IntercomProvider } from "react-use-intercom";
import { useSearchParams } from "react-router-dom";

import { sessionQuery, sessionService } from "@store/session";
import { jobBoardsService } from "@store/jobBoards";
import { toolboxService } from "@store/toolbox";
import { UserRoleEnum } from "@store/users";

import UnAuth from "@screens/unauth/UnAuth";
import Auth from "@screens/auth/Auth";
import { Pages, ParametersRoutes, RoutesUnAuth } from "@utils/routes.utils";

const Screens = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);

  const [{ user }] = useObservable(sessionQuery.isAuthenticated$);

  useEffect(() => {
    if (user?.role === UserRoleEnum.ADMIN) return ;

    const redirect = searchParams.get('redirect');

    if (redirect?.includes("subscription_")) {
      if (user) {
        window.location.href = `/${Pages.PARAMETERS}/${ParametersRoutes.SUBSCRIPTION}`;
      } else if (window.location.pathname !== `/${RoutesUnAuth.LOGIN}`) {
        window.location.href = `/${RoutesUnAuth.LOGIN}?redirect=${redirect}`;
      }
    }
  }, [searchParams, user]);

  useEffect(() => {
    if (sessionQuery.token) {
      sessionService
        .fetchCurrentUser()
        .pipe(
          switchMap(() =>
            forkJoin([
              jobBoardsService.getJobBoards(),
              jobBoardsService.getSequences(),
              jobBoardsService.getBoostActions(),
              jobBoardsService.getSchools(),
              toolboxService.getToolboxes(),
            ])
          ),
          finalize(() => setLoading(false)),
        )
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      setLoading(false);
    }
  }, [enqueueSnackbar]);

  const isAuthenticated = !!user;

  if (loading) return null;

  if (isAuthenticated)
    return (
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID ?? ""}>
        <Auth />
      </IntercomProvider>
    );

  return <UnAuth />;
};

export default Screens;
