import { SelectItem } from "@components/input/Select.component";
import I18nUtils from "@utils/i18n.utils";

export enum CandidateSortEnum {
  NAME = "jobName",
  DATE = "createdAt",
}

export namespace CandidateSortEnumFunctions {
  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("aio.applications.sort.byName"), value: CandidateSortEnum.NAME },
    { label: I18nUtils.t("aio.applications.sort.byDate"), value: CandidateSortEnum.DATE },
  ];
}

export interface CandidateSortModel {
  field: CandidateSortEnum;
}

export interface CandidatesFilters {
  search?: string;
  notAttachedAnnouncement?: boolean;
  notAttachedCompany?: boolean;
}

export namespace CandidatesFiltersFunctions {
  export const companyStateSelectItems: SelectItem[] = [
    { label: I18nUtils.t("aio.applications.companyState.reattached"), value: true },
    { label: I18nUtils.t("aio.applications.companyState.notReattached"), value: false },
  ];

  export const announcementStateSelectItems: SelectItem[] = [
    { label: I18nUtils.t("aio.applications.announcementState.associated"), value: true },
    { label: I18nUtils.t("aio.applications.announcementState.notAssociated"), value: false },
  ];
}

export interface CandidateInfo {
  firstName: string;
  lastName: string;
}

export interface CandidateCompanyInfo {
  companyId: string;
  companyName: string;
  companyLogoURL?: string;
  companyDescription?: string;
  companyLocation?: string;
}

export interface CandidateJob  {
  id: string;
  name: string;
  location: string;
  reference: string;
  fromPEAPI: boolean;
}

export interface Candidate extends CandidateCompanyInfo {
  id: string;
  companyAffiliateId: string;
  companyAffiliateName: string;
  announcementId: string;
  announcementRef: string;
  jobName: string;
  creationDate: Date;
  candidateInfo: CandidateInfo;
  coverLetterKey: string;
  coverLetterURL: string;
  cvKey: string;
  cvURL: string;
  linkedin: string;
  job: CandidateJob
}
