import React, { useState } from "react";
import { alpha, Box, IconButton, Stack, styled, Switch, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import {
  ConnectorMediaEnum,
  ConnectorMetaData,
  ConnectorModel,
  connectorsService,
  ConnectorTypeEnum
} from "@store/connectors";

import { Colors } from "@constants/colors.constant";

import ConfirmModal from "@components/modal/Confirm.modal";
import ActivateConnectorModal from "@screens/auth/client/connectors/components/ActivateConnector.modal";

const ConnectorCardStyled = styled(Stack)(
  (props) => `
  background-color: ${Colors.white};
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(34,34,64, 0.05);
  flex: 1;
  height: 200px;
  opacity: 1;
  position: relative;
  width: 250px;
  &:hover {
    background-color: ${alpha(Colors.primary, 0.1)};
  }
`);


interface ConnectorCardProps {
  affiliateId: string;
  connector?: ConnectorModel;
  connectorMetaData: ConnectorMetaData;
}

const ConnectorCard = (props: ConnectorCardProps) => {
  const { affiliateId, connector, connectorMetaData } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [openActivate, setOpenActivate] = useState<string | boolean>(false);
  const [openDeactivate, setOpenDeactivate] = useState(false);

  const handleDeactivate = () => {
    if (!connector) return;

    setLoading(true);
    connectorsService
      .deactivateConnector(connector.id)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t('connectors.success.deactivated'), {variant: 'success'});
          setOpenDeactivate(false);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <>
      <ConnectorCardStyled>
        <Stack position="relative" alignItems="center" justifyContent="center" height="100%">
          <Stack
            direction="row"
            position="absolute"
            right="15px"
            top="10px"
            alignItems="center"
            spacing="5px"
            height="30px">
            <Tooltip
              title={
                <Stack spacing={0.25}>
                  <Typography fontSize={12} fontWeight="500">
                    {connectorMetaData.name}
                  </Typography>
                  <Typography fontSize={12} fontWeight="300">
                    {connectorMetaData.description}
                  </Typography>
                </Stack>
              }
              arrow
              placement="top">
              <img alt="information" height="20px" width="20px" src="/images/icon_informations.svg" />
            </Tooltip>
            <Switch
              checked={!!connector || connectorMetaData.media === ConnectorMediaEnum.AIO_JOBS}
              disabled={loading || connectorMetaData.media === ConnectorMediaEnum.AIO_JOBS}
              onChange={(_, checked) => checked ? setOpenActivate(true) : setOpenDeactivate(true)} />
          </Stack>
          <Stack width="100%" alignItems="center" justifyContent="center" p="40px 20px">
            <Stack height="70px" alignItems="center" justifyContent="center">
              {connectorMetaData.logo}
            </Stack>
            <Stack spacing="5px" alignItems="center">
              <Stack direction="row" alignItems="center" position="relative">
                <Typography fontSize={16} fontWeight="500" lineHeight={1} noWrap>
                  {connectorMetaData.name}
                </Typography>
                {!!connector && connectorMetaData.type !== ConnectorTypeEnum.STANDALONE && (
                  <Box position="absolute" right={0} sx={{transform: 'translateX(100%)'}}>
                    <IconButton size="small" onClick={() => setOpenActivate(connector.id)}>
                      <img alt="reset" height="15px" width="15px" src="/images/icon_edit_fill_black.svg"/>
                    </IconButton>
                  </Box>
                )}
              </Stack>
              {!!connector?.emailSentDate && (
                  <Typography
                    fontSize={12}
                    fontWeight="400"
                    lineHeight={1}
                    color={Colors.secondaryText}
                    mb={!!connector?.emailSentDate ? "-17px !important" : undefined}
                    noWrap>
                    {t("global.requestSentThe", {date: new Date(connector.emailSentDate)})}
                  </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </ConnectorCardStyled>
      {!!openActivate && (
        <ActivateConnectorModal
          affiliateId={affiliateId}
          connectorId={openActivate !== true ? openActivate : undefined}
          isCreation={openActivate === true}
          connectorMetaData={connectorMetaData}
          handleClose={() => setOpenActivate(false)}/>
      )}
      {openDeactivate && (
        <ConfirmModal
          closeOnDemand
          logo={connectorMetaData.logo}
          handleClose={() => setOpenDeactivate(false)}
          handleConfirm={handleDeactivate}
          confirmMsg={t('connectors.confirmModal.messageDeactivate')}
          modalTitle={connectorMetaData.name}
          hideCancel/>
      )}
    </>
  );
};

export default ConnectorCard;
