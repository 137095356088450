import { AxiosError, AxiosResponse } from "axios";
import { catchError, from, map, Observable, tap } from "rxjs";
import { setEntities, updateEntities } from "@ngneat/elf-entities";

import { unipileInvitationsStore } from "./unipileInvitations.store";
import {
  UnipileInvitationFiltersModel,
  UnipileInvitationModel,
  UnipileInvitationStatusEnum
} from "./unipileInvitations.model";
import { getUnipileInvitationsDataSource } from "@store/unipile/unipileInvitations/unipileInvitations.requests";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";

export class UnipileInvitationsService {
  store = unipileInvitationsStore;

  resetStore = () => this.store.reset();

  setFilters = (filters: Partial<UnipileInvitationFiltersModel>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getUnipileLinkedinInvitations = (adId: string, filters?: UnipileInvitationFiltersModel): Observable<UnipileInvitationModel[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETUnipileInvitations(),
        params: {
          announcementId: adId,
          search: filters?.search,
          status: !!filters?.status ? filters?.status : undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UnipileInvitationModel[]>) => {
        return response.data;
      }),
      tap((unipileInvitations) => {
        this.store.update(setEntities(unipileInvitations), getUnipileInvitationsDataSource.setSuccess());
      }),
      getUnipileInvitationsDataSource.trackRequestStatus()
    );
  };

  sendUnipileLinkedinInvitations = (adId: string, profileIds: string[], message: string): Observable<AxiosResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTSendUnipileLinkedinInvitations(),
        data: { announcementId: adId, potentialProfileIds: profileIds, message },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  resendUnipileLinkedinInvitation = (unipileInvitationId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.POSTResendUnipileInvitation(unipileInvitationId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      tap(() => {
        this.store.update(updateEntities(unipileInvitationId, { status: UnipileInvitationStatusEnum.PENDING_SEND }));
      })
    );
  };

  refreshUnipileInvitationList = (adId: string): Observable<UnipileInvitationModel[]> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTRefreshUnipileInvitations(),
        data: { announcementId: adId },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<UnipileInvitationModel[]>) => {
        return response.data;
      }),
      tap((unipileInvitations) => {
        this.store.update(setEntities(unipileInvitations), getUnipileInvitationsDataSource.setSuccess());
      }),
      getUnipileInvitationsDataSource.trackRequestStatus()
    );
  };
}

export const unipileInvitationsService = new UnipileInvitationsService();
