import { createRequestDataSource } from "@ngneat/elf-requests";
import { select } from "@ngneat/elf";

import { candidatesStore } from "./candidates.store";
import { Candidate } from "@store/ai-o/candidates/candidates.model";

export const getPaginatedCandidatesDataSource = createRequestDataSource({
  store: candidatesStore,
  data$: () => candidatesStore.pipe( select((state) => {
    const entities = Object.values(state.entities);
    return state.pagination.pages[state.pagination.currentPage]?.map(id => entities.find(e => e.id === id.toString()) as Candidate).filter(e => !!e) || [];
  })),
  requestKey: "getCandidates",
  dataKey: "candidates",
});