import styled from "@emotion/styled";
import { alpha, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import {
  Subscription,
  SubscriptionTypeEnum,
  SubscriptionTypeEnumFunctions
} from "@store/subscriptions/subscriptions.model";

import { Colors } from "@constants/colors.constant";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { getAppCurrency, getToLocalStringCode } from "@store/common/country.model";

const PriceBadge = styled(Stack)((props: { bgcolor?: string; height: string }) => ({
  height: props.height,
  width: "166px",
  borderRadius: "10px",
  backgroundColor: props.bgcolor ?? "transparent",
}));

const StyledNumberChoiceTextfield = styled(Stack)({
  width: "155px",
  marginTop: "2px",
  "& .MuiFormControl-root": {
    backgroundColor: alpha(Colors.purple, 0.1),
  },
  "& input": {
    textAlign: "center",
    backgroundColor: "transparent",
    color: Colors.secondary,
    border: `1px solid ${alpha(Colors.white, 0.2)}`,
    borderRadius: "10px",
    padding: "14px 22px",
  },
});

const StyledCheckBox = styled(Stack)({
  width: "140px",
  marginTop: "12px",
  "& label": { marginRight: 0 },
  "& label .MuiTypography-root": {
    color: Colors.lightGrey,
    paddingLeft: "4px",
  },
});

interface SmallAmountChoiceComponentProps {
  amount?: number;
  handleAmountChange?: (amount?: number, unlimited?: boolean) => void;
  isYearly: boolean;
  subscription: Subscription;
  unlimited?: boolean;
}

const SmallAmountChoiceComponent = (props: SmallAmountChoiceComponentProps) => {
  const { amount, handleAmountChange, isYearly, subscription, unlimited } = props;

  const { t } = useTranslation();

  return subscription.type === SubscriptionTypeEnum.COMPANY ? (
    <PriceBadge height={subscription.type === SubscriptionTypeEnum.COMPANY ? "auto" : "100px"} alignItems="center" justifyContent="center">
      <Stack justifyContent="center" alignItems="center" height="100%" spacing="6px">
        <Typography fontSize="40px" fontWeight={500} lineHeight={0.8}>
          {(!isYearly ? subscription.price : subscription.price * 0.9).toLocaleString(getToLocalStringCode())}{getAppCurrency()}
        </Typography>
        <Typography fontSize="10px" fontWeight={300}>
          / {t("subscriptions.jobOffer")} / {t("subscriptions.month")}
        </Typography>
        <Stack>
          <StyledNumberChoiceTextfield>
            <AIOTextfieldComponent
              placeholder={t("subscriptions.enterNumber")}
              onChange={(amount) => handleAmountChange?.(+amount, undefined)}
              value={unlimited ? t("subscriptions.unlimitedOffer") : amount?.toString() || ""}
              pattern={unlimited ? undefined : new RegExp(/^\d*$/)}
              color="secondary"
              readonly={!!unlimited}
              fullWidth
            />
          </StyledNumberChoiceTextfield>
          <StyledCheckBox alignItems="center" justifyContent="center" direction="row" spacing="5px">
            <FormControlLabel
              sx={{ width: "fit-content" }}
              label={
                <Typography fontSize="12px" fontWeight="300">
                  {t("subscriptions.unlimitedOffer")}
                </Typography>
              }
              onChange={(evt, checked) => handleAmountChange?.(undefined, checked)}
              checked={!!unlimited}
              control={
                <Checkbox
                  size="small"
                  icon={<img width="13px" src="/images/icon_checkbox_empty.svg" alt="unchecked" />}
                  checkedIcon={<img width="13px" src="/images/icon_checkbox_filled.svg" alt="checked" />}
                />
              }
            />
          </StyledCheckBox>
        </Stack>
      </Stack>
    </PriceBadge>
  ) : (
    <PriceBadge
      height="100px"
      bgcolor={alpha(SubscriptionTypeEnumFunctions.colors[subscription.type], 0.1)}
      alignItems="center"
      justifyContent="center">
      <Stack alignItems="center" spacing="4px">
        <Typography fontWeight={500} fontSize="25px" lineHeight={1}>
          {!subscription.price
            ? t("subscriptions.free")
            : `${(!isYearly ? subscription.price : subscription.price * 0.9).toLocaleString(getToLocalStringCode())}${getAppCurrency()}`}
          {!!subscription.price && (
            <Typography component="span" fontSize="10px" fontWeight={300}>
              &nbsp;/ {t("subscriptions.month")}
            </Typography>
          )}
        </Typography>
        <img width="130px" src={`/images/subscription/underline_${!subscription.price ? "purple" : "orange"}.svg`} alt="underline" />
      </Stack>
    </PriceBadge>
  );
};

export default SmallAmountChoiceComponent;
