import React from "react";
import { Navigate, Route, Routes } from "react-router";

import Login from "@screens/unauth/login/Login.screen";

import { RoutesUnAuth, Pages } from "@utils/routes.utils";
import LostPassword from "./forgotPassword/LostPassword.screen";
import SignUp from "./signUp/SignUp.screen";
import { AppCountryEnum, getAppCountry } from "@store/common/country.model";
import PrivacyPolicyFR from "@screens/legalNotice/FR/PrivacyPolicy";
import PrivacyPolicyUS from "@screens/legalNotice/US/PrivacyPolicy";
import CGUUS from "@screens/legalNotice/US/CGU";
import CGUFR from "@screens/legalNotice/FR/CGU";

const UnAuth = () => {
  return (
    <Routes>
      <Route path={RoutesUnAuth.LOGIN} element={<Login />} />
      <Route path={RoutesUnAuth.LOST_PASSWORD} element={<LostPassword />} />
      <Route path={RoutesUnAuth.SIGN_UP} element={<SignUp />} />
      <Route path={Pages.PRIVACY} element={getAppCountry() === AppCountryEnum.US ? <PrivacyPolicyUS /> : <PrivacyPolicyFR />} />
      <Route path={Pages.CGU} element={getAppCountry() === AppCountryEnum.US ? <CGUUS /> : <CGUFR />} />
      <Route path="*" element={<Navigate to={RoutesUnAuth.LOGIN} replace />} />
    </Routes>
  );
};

export default UnAuth;
