import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { alpha, Box, Stack, Typography } from "@mui/material";
import { finalize } from "rxjs";

import { EntityDetailsModel } from "@store/entities/details";
import { EntityTypeEnum } from "@store/entities/entities.model";
import {
  manageUserFromUser,
  UserAffiliateRoleEnum,
  UserAffiliateRoleEnumFunctions,
  UserModel,
  UserRoleEnum,
  usersService
} from "@store/users";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import { Colors } from "@constants/colors.constant";
import { SelectItem } from "@components/input/Select.component";

import UserModal from "@components/modal/User.modal";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import AsyncSelectWithSearchComponent from "@components/input/AsyncSelectWithSearch.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import { sessionQuery } from "@store/session";

interface AddUserModalProps {
  entity: EntityDetailsModel;
  handleClose: () => void;
}

const AddUserModal = (props: AddUserModalProps) => {
  const { entity, handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openCreateUser, setOpenCreateUser] = useState(sessionQuery.role === UserRoleEnum.ADMIN && entity.type === EntityTypeEnum.CLIENT);

  const [loading, setLoading] = useState(false);

  const [selectedUser, setSelectedUser] = useState<SelectItem | undefined>();
  const [role, setRole] = useState<UserAffiliateRoleEnum | undefined>();
  const [affiliate, setAffiliate] = useState<SelectItem | undefined>();

  const clientId = entity.type === EntityTypeEnum.AFFILIATE ? entity.client!.id : entity.id;

  if (openCreateUser) {
    return (
      <UserModal
        entityType={entity.type}
        isCreation={UserRoleEnum.CLIENT}
        handleClose={handleClose} />
    );
  }

  const errors = {
    role: entity.type === EntityTypeEnum.AFFILIATE ? getFieldError(role, FieldValidationType.REQUIRED_STRING) : undefined,
    affiliate: entity.type === EntityTypeEnum.AFFILIATE && sessionQuery.role === UserRoleEnum.CLIENT
      ? getFieldError(affiliate, FieldValidationType.REQUIRED_SELECT_ITEM)
      : undefined,
  };

  const handleSave = () => {
    if (!selectedUser || !role || (sessionQuery.role === UserRoleEnum.CLIENT && !affiliate)) return;
    setLoading(true);

    const user = manageUserFromUser(selectedUser.data as UserModel);

    if (user.affiliates?.some((a) => {
      if (entity.type === EntityTypeEnum.CLIENT && sessionQuery.role === UserRoleEnum.CLIENT)
        return a.affiliate?.value === affiliate!.value;
      return a.affiliate?.value === entity.id;
    })) {
      return enqueueSnackbar(t("clients.details.usersTab.warning"), { variant: "warning" });
    }

    usersService
      .updateUser(selectedUser.value, {
        affiliates: [
          ...(user.affiliates ?? []),
          {
            affiliate: entity.type === EntityTypeEnum.CLIENT && sessionQuery.role === UserRoleEnum.CLIENT
              ? affiliate
              : { label: entity.name, value: entity.id },
            role,
          },
        ],
      })
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("clients.details.usersTab.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <ModalComponent
      maxWidth="xs"
      titleLeft
      title={t("clients.details.usersTab.title")}
      handleClose={handleClose}
      content={(
        <Stack px="20px" spacing={3} py="25px" alignItems="center">
          <AsyncSelectWithSearchComponent
            placeholder={t("clients.details.usersTab.selectUser")}
            getOptions={usersService.searchUsers({ limit: 10, clientIds: [clientId], roles: [UserRoleEnum.CLIENT] })}
            searchIfEmpty
            handleChange={setSelectedUser}
            value={selectedUser} />
          {!!selectedUser && (
            <SelectWithSearchComponent
              error={errors.role}
              placeholder={t("userDetails.selectRole")}
              items={UserAffiliateRoleEnumFunctions.allItems}
              value={role ? { label: UserAffiliateRoleEnumFunctions.label(role), value: role } : ""}
              handleChange={(item) => setRole(item?.value)} />
          )}
          {entity.type === EntityTypeEnum.CLIENT && !!selectedUser && sessionQuery.role === UserRoleEnum.CLIENT && (
            <SelectWithSearchComponent
              error={errors.affiliate}
              placeholder={t("userDetails.selectAffiliate")}
              items={sessionQuery.affiliatesItems.filter((i) => i.data.role === UserAffiliateRoleEnum.RESPONSIBLE)}
              value={affiliate ?? ""}
              handleChange={setAffiliate} />
          )}
          <Typography textAlign="center">{t("clients.details.usersTab.or")}</Typography>
          <Box
            sx={{
              "& .MuiButton-root": {
                backgroundColor: alpha(Colors.primary, 0.1),
                height: 35,
                borderRadius: "7px",
              },
            }}>
            <AIOButtonComponent
              color="primary"
              onClick={() => setOpenCreateUser(true)}
              icon={<img alt="" src="/images/button_add_more.svg" />}
              title={t("clients.details.usersTab.createUser")} />
          </Box>
        </Stack>
      )}
      actions={(
        <AIOButtonComponent
          title={t("global.add")}
          ariaLabel="modal"
          disabled={loading || !selectedUser || checkIfErrors(errors)}
          onClick={handleSave}
          variant="contained"
          color="secondary" />
      )} />
  );
};

export default AddUserModal;