import { Box, CircularProgress, Grid, IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";
import { useNavigate } from "react-router-dom";

import { sessionQuery } from "@store/session";
import { personaeQuery, personaeService, PersonaeSortField, searchPersonaeEffect } from "@store/personae";
import { UserRoleEnum } from "@store/users";

import AIOButtonComponent from "@components/Button.component";
import ClientsFilterComponent from "@components/input/ClientsFilterComponent";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import AIOSelectComponent, { SelectItem } from "@components/input/Select.component";
import PersonaeCard from "@components/card/Personae.card";
import PersonaeHelpModal from "@screens/auth/client/personae/modals/PersonaeHelp.modal";
import AddProfileModal from "@screens/auth/client/personae/modals/AddProfile.modal";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import { emitOnce } from "@ngneat/elf";

const Personae = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const searchPersonae = useEffectFn(searchPersonaeEffect);

  const [openHelp, setOpenHelp] = useState(false);
  const [addProfile, setAddProfile] = useState(false);

  const [{ personae, error, loading }] = useObservable(personaeQuery.personae$);
  const [personaeCount] = useObservable(personaeQuery.personaeCount$);

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [affiliateIds] = useObservable(sessionQuery.affiliateIds$);
  const [filters] = useObservable(personaeQuery.filters$);

  const [affiliates] = useState<SelectItem[]>(sessionQuery.affiliatesItems);

  const [sort] = useObservable(personaeQuery.sort$);

  useEffect(() => {
    emitOnce(() => {
      personaeService.resetStore();
      if (clientViewClient) {
        personaeService.setFilters({ affiliateIds: [{ value: clientViewClient.id, label: "" }] });
      }
    });
  }, [affiliateIds, clientViewClient]);

  useEffect(() => {
    if (error) enqueueSnackbar((error as any).text, (error as any).options);
  }, [error, enqueueSnackbar]);

  useEffect(() => {
    searchPersonae({ filters, sort });
  }, [affiliateIds, filters, searchPersonae, sort]);

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : "60px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 0 : 4}
      flex={1}
      width="100%"
      overflow="auto">
      <Stack
        direction={breakpointDownSM ? "column" : "row"}
        alignItems={breakpointDownSM ? "flex-start" : "center"}
        spacing={2}
        justifyContent="space-between">
        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} width={breakpointDownSM ? "100%" : undefined}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography fontSize={breakpointDownSM ? "30px" : "35px"} fontWeight={700} pb="4px" whiteSpace="nowrap">
              {t(`personae.${sessionQuery.role === UserRoleEnum.ADMIN ? "title" : "yourTypes"}`)}
            </Typography>
            {!breakpointDownSM && sessionQuery.role !== UserRoleEnum.ADMIN && (
              <IconButton sx={{ p: 0 }} onClick={() => setOpenHelp(true)}>
                <img src="/images/question.svg" alt="help" />
              </IconButton>
            )}
          </Stack>
          {breakpointDownSM && (
            <Box sx={{ "& .MuiButton-root": { height: "35px !important" } }}>
              {sessionQuery.role !== UserRoleEnum.ADMIN && (
                <AIOButtonComponent variant="contained" color="secondary" onClick={() => setAddProfile(true)} title={t(`personae.addNewProfile`)} />
              )}
            </Box>
          )}
        </Stack>
        {!breakpointDownSM && (
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent={breakpointDownSM ? "center" : undefined}
            width={breakpointDownSM ? "100%" : undefined}
            sx={{ "& .MuiButton-root": { height: "35px !important" } }}>
            {sessionQuery.role !== UserRoleEnum.ADMIN && (
              <AIOButtonComponent variant="contained" color="secondary" onClick={() => setAddProfile(true)} title={t(`personae.addNewProfile`)} />
            )}
            {(sessionQuery.role === UserRoleEnum.ADMIN || affiliates.length > 1) && (
              <Box width="250px">
                {sessionQuery.role !== UserRoleEnum.ADMIN ? (
                  <MultiSelectWithSearchComponent
                    handleChange={(values) => personaeService.setFilters({ affiliateIds: values })}
                    startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                    multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
                    placeholder={t("global.filterByCompanies")}
                    items={affiliates}
                    values={filters.affiliateIds ?? []}
                  />
                ) : (
                  <ClientsFilterComponent values={affiliateIds} />
                )}
              </Box>
            )}
          </Stack>
        )}
      </Stack>
      <Stack
        overflow="auto"
        className={breakpointDownSM ? "scrollable" : undefined}
        spacing={breakpointDownSM ? 2 : 0}
        py={breakpointDownSM ? "20px" : undefined}>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%" spacing={2}>
          <Stack
            direction={breakpointDownSM ? "column" : "row"}
            width="100%"
            alignItems="center"
            spacing={breakpointDownSM ? 0 : 3}
            justifyContent={breakpointDownSM ? "flex-start" : "flex-end"}>
            <Stack spacing={1.5} width="100%" alignItems="flex-end">
              {breakpointDownSM && (sessionQuery.role === UserRoleEnum.ADMIN || affiliates.length > 1) && (
                <Box width="100%">
                  {sessionQuery.role !== UserRoleEnum.ADMIN ? (
                    <MultiSelectWithSearchComponent
                      handleChange={(values) => personaeService.setFilters({ affiliateIds: values })}
                      startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                      multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
                      placeholder={t("global.filterByCompanies")}
                      items={affiliates}
                      values={filters.affiliateIds ?? []}
                    />
                  ) : (
                    <ClientsFilterComponent values={affiliateIds} />
                  )}
                </Box>
              )}
              <AIOSearchComponent placeholder={t("personae.searchPlaceholder")} onChange={(value) => personaeService.setFilters({ search: value })} />
            </Stack>
            {sessionQuery.role === UserRoleEnum.ADMIN && (
              <Stack direction="row">
                <AIOSelectComponent
                  items={PersonaeSortField.selectItems}
                  handleChange={(value) => personaeService.setSort({ field: value })}
                  value={sort.field}
                />
              </Stack>
            )}
          </Stack>
          {breakpointDownSM && sessionQuery.role !== UserRoleEnum.ADMIN && (
            <IconButton sx={{ p: 0 }} onClick={() => setOpenHelp(true)}>
              <img src="/images/question.svg" alt="help" />
            </IconButton>
          )}
        </Stack>
        <Box
          overflow={breakpointDownSM ? undefined : "auto"}
          className={breakpointDownSM ? undefined : "scrollable"}
          py={breakpointDownSM ? "0px" : "30px"}
          px="10px">
          <Stack spacing={2}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <Typography fontSize="16px" fontWeight="700">
                {loading ? "-" : ""}
                {!loading && t(`personae.amount`, { count: personaeCount ?? 0 })}
              </Typography>
              {loading && <CircularProgress size={20} />}
            </Stack>
            <Box>
              <Grid container spacing={3}>
                {personae.map((p) => (
                  <Grid item xs={6} sm={4} lg={3} key={p.id}>
                    <PersonaeCard personae={p} handleClick={() => navigate(`${p.id}`)} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </Box>
      </Stack>
      {openHelp && <PersonaeHelpModal handleClose={() => setOpenHelp(false)} />}
      {addProfile && <AddProfileModal handleClose={() => setAddProfile(false)} />}
    </Stack>
  );
};

export default Personae;
