import { createRequestDataSource } from "@ngneat/elf-requests";
import { select } from "@ngneat/elf";

import { affiliateDetailsStore } from "@store/entities/afiliates/details/affiliateDetails.store";

export const getAffiliateDetailsDataSource = createRequestDataSource({
  store: affiliateDetailsStore,
  data$: () => affiliateDetailsStore.pipe(select((state) => state.details)),
  requestKey: "getAffiliateDetails",
  dataKey: "entityDetails",
});

export const getAffiliateActionsDataSource = createRequestDataSource({
  store: affiliateDetailsStore,
  data$: () => affiliateDetailsStore.pipe(select((state) => state.actions)),
  requestKey: "getAffiliateActions",
  dataKey: "entityActions",
});

export const getAffiliateDetailsToolboxToolsDataSource = createRequestDataSource({
  store: affiliateDetailsStore,
  data$: () => affiliateDetailsStore.pipe(select((state) => state.affiliateToolboxTools)),
  requestKey: "getAffiliateToolboxTools",
  dataKey: "affiliateToolboxTools",
});

export const getAffiliateCommentsDataSource = createRequestDataSource({
  store: affiliateDetailsStore,
  data$: () => affiliateDetailsStore.pipe(select((state) => state.comments)),
  requestKey: "getAffiliateDetailsComments",
  dataKey: "entityComments",
});
