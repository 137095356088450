import { SelectItem } from "@components/input/Select.component";
import I18nUtils from "@utils/i18n.utils";
import { Colors } from "@constants/colors.constant";
import { PersonaeQuiz } from "@store/personae/personaeQuiz.model";
import { AffiliateLightModel } from "@store/entities/afiliates";
import { AdModel } from "@store/ads";

export enum PersonaeSortField {
  NAME = "name",
  CLIENT = "client",
}

export namespace PersonaeSortField {
  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("personae.sort.byName"), value: PersonaeSortField.NAME },
    { label: I18nUtils.t("personae.sort.byClient"), value: PersonaeSortField.CLIENT },
  ];
}

export const personaeBackgroundColors: string[] = [
  Colors.personae.lightPurple,
  Colors.personae.lightGreen,
  Colors.personae.lightPrimary,
  Colors.personae.lightOrange,
  Colors.personae.lightBlue,
];

export interface PersonaeSort {
  field: PersonaeSortField;
}

export interface PersonaeFiltersValues {
  company: string[];
}

export interface PersonaeFilters {
  search?: string;
  affiliateIds?: SelectItem[];
}

export enum PersonaeExperience {
  YOUNG_GRADUATE = "YOUNG_GRADUATE",
  JUNIOR = "JUNIOR",
  CONFIRMED = "CONFIRMED",
  SENIOR = "SENIOR",
}

export namespace PersonaeExperience {
  export const experiences: PersonaeExperience[] = [
    PersonaeExperience.YOUNG_GRADUATE,
    PersonaeExperience.JUNIOR,
    PersonaeExperience.CONFIRMED,
    PersonaeExperience.SENIOR,
  ];

  export const label = (experience?: PersonaeExperience) => {
    if (!experience) return "-";
    return I18nUtils.t(`personae.experiences.${experience}`);
  };
}

export interface Personae {
  id: string;
  name: string;
  experience: PersonaeExperience;
  location: string;
  isFinished: boolean;
  backgroundNumber: number;
  pictureNumber: number;
  associatedTitles: string[];
  announcements?: AdModel[];
  affiliate: AffiliateLightModel;
  list: PersonaeQuiz;
}
