import { useParams, useSearchParams } from "react-router-dom";
import { map } from "rxjs";
import React from "react";
import { useSnackbar } from "notistack";
import { MentionItem } from "react-mentions";

import { adDetailsService } from "@store/ads/details";
import { ApplicationDetails } from "@store/applications";
import { affiliateDetailsService } from "@store/entities/afiliates/details";
import { UserRoleEnum } from "@store/users";

import CommentsComponent from "@components/input/Comments.component";

interface ApplicationCommentsTabProps {
  application: ApplicationDetails;
  handleCommentsAdded: (application: ApplicationDetails) => void;
}

const ApplicationCommentsTab = (props: ApplicationCommentsTabProps) => {
  const {application, handleCommentsAdded} = props;

  const { enqueueSnackbar } = useSnackbar();
  const { adId, applicationId } = useParams();
  const [searchParams] = useSearchParams();

  const commentId = searchParams.get("commentId");

  const handleAddComment = (comment: string, mentions: MentionItem[]) => {
    if (!applicationId) return;
    adDetailsService.adApplicationComment(applicationId, comment, mentions, adId).subscribe({
      next: handleCommentsAdded,
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <CommentsComponent
      comments={application?.comment || []}
      shouldOverflow
      handleAddComment={handleAddComment}
      selectedCommentId={commentId ?? undefined}
      fetchTags={() =>
        affiliateDetailsService.getAffiliateUsers(application.affiliate.id)
          .pipe(map((users) => users
            .filter((user) => user.role !== UserRoleEnum.ADMIN)
            .map((user) => ({
              id: user.email,
              display: `${user.firstname} ${user.lastname}`,
            }))
          ))
      } />
  );
};

export default ApplicationCommentsTab;
