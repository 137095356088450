import { catchError, from, Observable } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";

import { ManageSubscriptionParameters } from "@store/subscriptions/subscriptions.model";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";

export class SubscriptionsService {

  sendQuotationRequest = (affiliateId: number | string, isYearly: boolean, subscription: ManageSubscriptionParameters): Observable<AxiosResponse> => {
    return from(APIAxios({
      ...APIRoutes.POSTRecontactRequest(affiliateId),
      data: { isYearly, amount: subscription.amount ?? 0, unlimitedOffer: subscription.unlimitedOffer ?? false },
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };
}

export const subscriptionsService = new SubscriptionsService();
