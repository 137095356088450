import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import {
  UnipileInvitationFiltersModel,
  UnipileInvitationModel
} from "@store/unipile/unipileInvitations/unipileInvitations.model";

interface UnipileInvitationsStoreProps {
  filters: UnipileInvitationFiltersModel;
}

export const unipileInvitationsStore = createStore(
  { name: "unipile-invitations" },
  withProps<UnipileInvitationsStoreProps>({
    filters: {},
  }),
  withEntities<UnipileInvitationModel>(),
  withRequestsStatus(),
);
