import React from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { useNavigate } from "react-router-dom";

import { DashboardStat, globalStatisticsQuery } from "@store/globalStatistics";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";

import { Pages } from "@utils/routes.utils";

import ModalComponent from "@components/modal/Modal.component";
import BoostCard from "@components/card/Boost.card";

interface BoostsListModalProps {
  handleClose: () => void;
  stat: DashboardStat;
  title: string;
}

const BoostsListModal = (props: BoostsListModalProps) => {
  const { handleClose, stat, title } = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const [boosts] = useObservable(globalStatisticsQuery.boostsByStat$(stat));

  return (
    <ModalComponent
      maxWidth={breakpointDownSM ? "xs" : false}
      fullWidth={breakpointDownSM}
      handleClose={handleClose}
      titleLeft
      content={
        <Box py="20px" className="scrollable" width={breakpointDownSM ? "100%" : "700px"}
             maxHeight="60vh" overflow="auto" px="10px">
          <Grid container spacing={3}>
            {boosts.map((boost) => (
              <Grid key={boost.id} item xs={12} sm={6}>
                <BoostCard
                  boost={boost}
                  handleClick={sessionQuery.role === UserRoleEnum.ADMIN ? () => navigate(`/${Pages.BOOST}/${boost.id}`) : undefined} />
              </Grid>
            ))}
          </Grid>
        </Box>
      }
      title={title}
    />
  );
};

export default BoostsListModal;
