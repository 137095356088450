import React, { useState } from "react";
import { alpha, Avatar, CardActionArea, IconButton, Menu, MenuItem, Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import BlockIcon from "@mui/icons-material/Block";
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';

import { companiesService, CompanyLight } from "@store/ai-o/companies";

import { Colors } from "@constants/colors.constant";
import ReattachAffiliateModal from "@screens/auth/admin/aio/components/ReattachAffiliate.modal";
import { Observable, tap } from "rxjs";
import ConfirmModal from "@components/modal/Confirm.modal";
import { companyDetailsService } from "@store/ai-o/companies/details";
import { useSnackbar } from "notistack";

const CompanyCardStyled = styled(Stack)`
  background-color: ${Colors.white};
  border-radius: 15px;
  height: 100%;
  flex: 1;
  opacity: 1;
  position: relative;
  width: 100%;
  & > *:first-of-type:not([aria-label="options"]) {
    align-items: center;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(34, 34, 64, 0.05);
    padding: 40px 22px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
  }
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    }
  }
`;

const Badges = styled(Stack)`
  position: absolute;
  top: -8px;
  z-index: 1;
`;
const BadgeType = styled(Stack)`
  border-radius: 5px;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 500;
  height: 15px;
  padding: 0 8px;
`;

interface CompanyAioCardProps {
  company: CompanyLight;
  handleClick?: () => void;
  handleReattachSuccess?: () => Observable<any>;
}

const CompanyAioCard = (props: CompanyAioCardProps) => {
  const { company, handleClick, handleReattachSuccess } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openReattachAffiliate, setOpenReattachAffiliate] = useState(false);
  const [openBanOrUnbanConfirm, setOpenBanOrUnbanConfirm] = useState<"ban" | "unban" | false>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleBanOrUnbanCompany = (companyId: string, action: "ban" | "unban") => {
    companyDetailsService[action === "ban" ? "banCompany" : "unbanCompany"](companyId)
      .pipe(
        tap(() => {
          companiesService.updateCompanyBanned(companyId, action === "ban");
        })
      )
      .subscribe({
        next: () => {
          enqueueSnackbar(t(`aio.clients.confirm.${action}.success`), { variant: "success" });
        },
      });
  };

  return (
    <CompanyCardStyled>
      <Stack component={!handleClick ? Stack : CardActionArea} onClick={handleClick}>
        <Badges direction={"row"} spacing={1}>
          {company.banned && (
            <BadgeType spacing={0.5} bgcolor={Colors.error} direction="row" alignItems="center" justifyContent="center">
              <BlockIcon sx={{ height: "12px", width: "12px", color: "white" }} />
              <span>{t("aio.clients.banned")}</span>
            </BadgeType>
          )}
          <BadgeType
            spacing={0.5}
            bgcolor={!!company.affiliateId ? Colors.affiliate : Colors.greyLight}
            direction="row"
            alignItems="center"
            justifyContent="center">
            {!!company.affiliateId && <img alt="logo aio" src="/images/logo_allinone_small.svg" />}
            <span>{t(`aio.clients.${!!company.affiliateId ? "reattached" : "notReattached"}`)}</span>
          </BadgeType>
        </Badges>

        <Stack direction="row" alignItems="center" width="100%" spacing={1.5}>
          <Avatar
            sx={{
              border: "none",
              borderRadius: "10px !important",
              backgroundColor: company.logo ? Colors.white : alpha(Colors.lightpurple, 0.6),
              color: Colors.secondary,
              height: 53,
              width: 53,
            }}
            src={company.logo}>
            {company.name[0]}
          </Avatar>
          <Stack maxWidth={"70%"}>
            <Typography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineClamp: 2,
                WebkitLineClamp: 2,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              }}
              fontSize={16}
              fontWeight="500"
              lineHeight={1}>
              {company.name}
            </Typography>
            <Stack direction="row" alignItems="center" spacing={1}>
              <img alt="location" src="/images/location.svg" />
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                color={Colors.secondaryText}
                fontWeight={500}
                pt="1px">
                {company.location ?? "-"}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack aria-label="options" direction="row" alignItems="center" position="absolute" right="10px" top="10px">
        <IconButton onClick={handleOpenMenu} size="small" sx={{ alignSelf: "flex-start" }}>
          <MoreVertIcon htmlColor={Colors.black} />
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          open={openMenu}
          onClose={handleCloseMenu}
          aria-label="user-menu"
          anchorOrigin={{ vertical: "center", horizontal: "right" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}>
          {!company.affiliateId && (
            <MenuItem
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenReattachAffiliate(true);
                handleCloseMenu();
              }}>
              <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                <img alt="download" src="/images/icon_doublearrow_red.svg" />
              </Stack>
              {t("aio.clients.reattachToClientAio")}
            </MenuItem>
          )}
          <MenuItem
            onClick={(evt) => {
              evt.stopPropagation();
              setOpenBanOrUnbanConfirm(company.banned ? "unban" : "ban");
              handleCloseMenu();
            }}>
            <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
              {company.banned ? <VerifiedOutlinedIcon color="primary" /> : <BlockIcon color="primary" />}
            </Stack>
            {t(`aio.clients.${company.banned ? "unban" : "ban"}`)}
          </MenuItem>
        </Menu>
      </Stack>

      {openReattachAffiliate && (
        <ReattachAffiliateModal
          candidateCompanyInfo={{
            companyId: company.id,
            companyName: company.name,
            companyDescription: company.description,
            companyLocation: company.location,
            companyLogoURL: company.logo,
          }}
          handleSuccess={handleReattachSuccess}
          shouldUpdateCompanies
          handleClose={() => setOpenReattachAffiliate(false)}
        />
      )}
      {openBanOrUnbanConfirm && (
        <ConfirmModal
          confirmMsg={t(`aio.clients.confirm.${openBanOrUnbanConfirm}.description`)}
          handleClose={() => {
            setOpenBanOrUnbanConfirm(false);
          }}
          handleConfirm={() => {
            handleBanOrUnbanCompany(company.id, openBanOrUnbanConfirm);
          }}
          modalTitle={t(`aio.clients.confirm.${openBanOrUnbanConfirm}.title`)}
        />
      )}
    </CompanyCardStyled>
  );
};

export default CompanyAioCard;
