import React, { ChangeEvent, useRef, useState } from "react";
import { Box, Button, CircularProgress, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { AffiliateMailType } from "@store/common/mail.model";
import { affiliateDetailsService } from "@store/entities/afiliates/details";
import BatchAssignJobBoardsModal from "@screens/auth/admin/entities/components/BatchAssignJobBoards.modal";

interface EntityParametersActionsComponentProps {
  disabled?: boolean;
  entityId: string;
}

const EntityParametersActionsComponent = (props: EntityParametersActionsComponentProps) => {
  const { disabled, entityId } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [assignJobBoards, setAssignJobBoards] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const actionsPopup = usePopupState({ variant: "popover", popupId: "parameters-actions" });

  const handleRefresh = () => {
    setLoading(true);
    affiliateDetailsService
      .refreshAts(entityId)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => enqueueSnackbar(t("clients.details.success.refreshATS"), { variant: "success" }),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleImportPowerBI = (evt: ChangeEvent<HTMLInputElement>) => {
    const file = evt.target.files?.[0];
    if (!file) return;
    setLoading(true);
    affiliateDetailsService
      .importPowerBIFile(entityId, file)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => enqueueSnackbar(t("clients.details.success.powerBI"), { variant: "success" }),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    evt.target.value = "";
  };

  const handleSendMailWarning = (mailType: AffiliateMailType.EXTRACT_WARNING_LOW | AffiliateMailType.EXTRACT_WARNING_HIGH) => {
    setLoading(true);
    affiliateDetailsService
      .sendMailToAffiliate(entityId, mailType)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => enqueueSnackbar(t("clients.details.success.sendMailWarning"), { variant: "success" }),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleGenerateTimeline = () => {
    setLoading(true);
    affiliateDetailsService
      .generateTimelineWithAi(entityId)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => enqueueSnackbar(t("clients.details.success.generatedTimeline"), { variant: "success" }),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <Box width="120px">
      <Button
        {...bindTrigger(actionsPopup)}
        aria-label="modal"
        startIcon={loading ? <CircularProgress sx={{ color: "white" }} size="15px" /> : undefined}
        endIcon={<img alt="" src="/images/arrow_dropdownlist_white.svg" />}
        disabled={disabled}
        fullWidth
        variant="contained"
        color="secondary">
        <Typography lineHeight={1.25}>{t("global.actions")}</Typography>
      </Button>
      <input accept=".xlsx" hidden={true} ref={inputRef} type="file" onChange={(evt) => handleImportPowerBI(evt)} />
      <Menu
        {...bindMenu(actionsPopup)}
        aria-label="user-menu"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}>
        <MenuItem
          onClick={() => {
            handleRefresh();
            actionsPopup.close();
          }}>
          <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
            <img alt="account" src="/images/icon_refresh.svg" />
          </Stack>
          {t("clients.details.parametersTab.actions.refreshATS")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAssignJobBoards(true);
            actionsPopup.close();
          }}>
          <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
            <img alt="account" src="/images/urgent.svg" />
          </Stack>
          {t("clients.details.parametersTab.actions.assignJobBoardsToAds")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            inputRef.current?.click();
            actionsPopup.close();
          }}>
          <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
            <img alt="account" src="/images/download_report.svg" />
          </Stack>
          {t("clients.details.parametersTab.actions.importPowerBI")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSendMailWarning(AffiliateMailType.EXTRACT_WARNING_LOW);
            actionsPopup.close();
          }}>
          <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
            <img alt="account" src="/images/alert.svg" />
          </Stack>
          {t("clients.details.parametersTab.actions.extractWarning48Hours")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleSendMailWarning(AffiliateMailType.EXTRACT_WARNING_HIGH);
            actionsPopup.close();
          }}>
          <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
            <img alt="account" src="/images/alert.svg" />
          </Stack>
          {t("clients.details.parametersTab.actions.extractWarning7Days")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleGenerateTimeline();
            actionsPopup.close();
          }}>
          <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
            <img alt="account" src="/images/icon_refresh.svg" />
          </Stack>

          {t("clients.details.parametersTab.actions.generateTimeline")}
        </MenuItem>
      </Menu>
      {assignJobBoards && <BatchAssignJobBoardsModal entityId={entityId} handleClose={() => setAssignJobBoards(false)} />}
    </Box>
  );
};

export default EntityParametersActionsComponent;
