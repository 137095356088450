import { getAllEntities } from "@ngneat/elf-entities";

import { toolboxStore } from "@store/toolbox/toolbox.store";

export class ToolboxQuery {
  store = toolboxStore;

  get toolboxes() {
    return this.store.query(getAllEntities());
  }
}

export const toolboxQuery = new ToolboxQuery();
