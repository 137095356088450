import { getTemplatesDataSource } from "./templates.requests";
import { templatesStore } from "./templates.store";

export class TemplateQuery {
  store = templatesStore;

  template$ = getTemplatesDataSource.data$();
}

export const templatesQuery = new TemplateQuery();
