import React, { useState } from "react";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { BoostStatus, boostsService } from "@store/boosts";
import { BoostDetailsDetailsModel, boostDetailsService } from "@store/boosts/details";

import { Colors } from "@constants/colors.constant";

import CardBackgroundComponent from "@components/cardBackground/CardBackground.component";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";

interface TwininRequestCardProps {
  boost: BoostDetailsDetailsModel;
}

const TwininRequestCard = (props: TwininRequestCardProps) => {
  const { boost } = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleFinish = () => {
    if (!boost.id) return;
    setLoading(true);
    boostDetailsService.manageBoostRequest(boost.id, BoostStatus.FINISHED).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => setOpenConfirm(false),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <>
      <CardBackgroundComponent img="/images/background_campaign.svg">
        <Stack>
          <Stack
            direction="row"
            spacing={0.5}
            overflow="hidden"
            borderRadius="10px 10px 0 0"
            height="10px">
            {Array.from({length: 3}, (_, idx) => (
              <Box
                key={idx}
                height="6px"
                flex={1 / 3}
                bgcolor={boostsService.getBoostProgress(boost) > idx ? Colors.purple : Colors.stepperDisabled}/>
            ))}
          </Stack>
          <Stack direction={breakpointDownSM ? "column" : "row"} alignItems="center" justifyContent="space-between" p="20px">
            <Stack direction="row" alignItems="center" spacing={2}>
              <Stack height="41px" width="41px" bgcolor={Colors.lightpurple} borderRadius="50%" alignItems="center"
                     justifyContent="center">
                <img alt="boost" src="/images/icon_boost_rocket.svg" style={{height: 20, width: 20}}/>
              </Stack>
              <Stack direction="row" color={Colors.white} spacing={0.5}>
                <Typography fontSize={14} fontWeight="700">
                  {t("boost.details.detailsTab.twinin.status")}
                </Typography>
                <Typography fontSize={14} fontWeight="300">
                  {t(`boost.details.detailsTab.twinin.state.${boost.state}`)}
                </Typography>
              </Stack>
            </Stack>
            {boost.state === BoostStatus.VALIDATED && (
              <Box sx={{"& .MuiButton-root": {borderRadius: "10px", height: "40px"}}}>
                <AIOButtonComponent
                  title={t("global.finish")}
                  onClick={() => setOpenConfirm(true)}
                  color="secondary"
                  variant="contained"/>
              </Box>
            )}
          </Stack>
        </Stack>
      </CardBackgroundComponent>
      {openConfirm && (
        <ModalComponent
          handleClose={() => setOpenConfirm(false)}
          title={t("boost.details.detailsTab.twinin.confirmation.title")}
          maxWidth="xs"
          titleLeft
          content={(
            <Stack spacing={3} pb="20px">
              <Typography flex={1} color={Colors.secondaryText} lineHeight="1.25" fontWeight={300}>
                {t("boost.details.detailsTab.twinin.confirmation.description")}
              </Typography>
            </Stack>
          )}
          paddingButton="10px 0px 40px 0px"
          actions={(
            <AIOButtonComponent
              title={t('global.confirm')}
              variant="contained"
              disabled={loading}
              ariaLabel="modal"
              onClick={handleFinish}/>
          )}/>
      )}
    </>
  );
}

export default TwininRequestCard;