import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { Template, templatesService } from "@store/ai-o/templates";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";

interface CvTemplateModalProps {
  handleClose: () => void;
  template: Template;
}

const CvTemplateModal = (props: CvTemplateModalProps) => {
  const { handleClose, template} = props

  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(template?.title ?? "");

  const errors = {
    name: getFieldError(name, FieldValidationType.REQUIRED_STRING),
  };

  const handleSaveModal = () => {
    setLoading(true);
    templatesService.updateTemplate(template.id, name).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.templates.success"), {variant: "success"});
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }

  return (
    <ModalComponent
      maxWidth="xs"
      handleClose={handleClose}
      title={t("aio.templates.editTitle")}
      titleLeft
      actions={(
        <AIOButtonComponent
          disabled={checkIfErrors(errors) || loading}
          title={t('global.validate')}
          variant="contained"
          onClick={handleSaveModal} />
      )}
      content={(
        <Stack py="20px">
          <AIOTextfieldComponent
            required
            multiline
            value={name}
            error={errors.name}
            onChange={setName}
            title={t('aio.templates.fields.name')}
            placeholder={t('aio.templates.fields.name')} />
        </Stack>
      )} />
  )
}

export default CvTemplateModal