import React, { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import { Personae, PersonaeExperience, personaeService } from "@store/personae";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";

interface ManageProfileFieldModalProps {
  data: any;
  field: "experience" | "location";
  handleClose: () => void;
  handleSave: (personae: Personae) => void;
}

const ManageProfileFieldModal = (props: ManageProfileFieldModalProps) => {
  const { data: dataProps, field, handleClose, handleSave } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { personaeId } = useParams();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Partial<Personae>>({ [field]: dataProps });

  const handleUpdate = () => {
    if (!personaeId || !Object.keys(data).length || !Object.values(data).some((x) => !!x)) return;
    setLoading(true);
    personaeService
      .updatePersonaeProfileField(personaeId, data)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (np) => {
          enqueueSnackbar(t("personae.manage.success"), { variant: "success" });
          handleSave(np);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("personae.manage.titleField")}
      maxWidth="xs"
      content={
        <Stack py="15px" spacing={3}>
          {field === "experience" && (
            <SelectWithSearchComponent
              value={
                data.experience
                  ? {
                      label: PersonaeExperience.label(data.experience),
                      value: data.experience,
                    }
                  : ""
              }
              items={PersonaeExperience.experiences.map((e) => ({ label: PersonaeExperience.label(e), value: e }))}
              placeholder={t("personae.manage.experience")}
              handleChange={(value) => setData({ experience: value?.value })}
            />
          )}
          {field === "location" && (
            <AIOTextfieldComponent
              fullWidth
              value={data.location ?? ""}
              placeholder={t("personae.manage.location")}
              onChange={(value) => setData({ location: value })}
            />
          )}
        </Stack>
      }
      actions={<AIOButtonComponent variant="contained" disabled={loading || !data} onClick={handleUpdate} title={t("global.modify")} />}
    />
  );
};

export default ManageProfileFieldModal;
