import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";

import { sessionQuery } from "@store/session";
import { globalStatisticsQuery, globalStatisticsService } from "@store/globalStatistics";

import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import { SelectItem } from "@components/input/Select.component";
import AffiliateDashboardComponent from "@screens/auth/common/dashboard/AffiliateDashboard.component";

const Dashboard = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [affiliates] = useState<SelectItem[]>(sessionQuery.affiliatesItems);

  const [filters] = useObservable(globalStatisticsQuery.filters$);

  useEffect(() => {
    globalStatisticsService
      .getAffiliatesStats({
        ...filters,
        affiliateIds: clientViewClient ? [{ value: clientViewClient.id, label: "" }] : filters.affiliateIds,
      })
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }, [clientViewClient, enqueueSnackbar, filters]);

  let affiliate = undefined;
  if (clientViewClient) {
    affiliate = clientViewClient;
  } else if (affiliates.length === 1) {
    affiliate = affiliates[0].data.affiliate;
  } else if (!!filters.affiliateIds && filters.affiliateIds.length === 1) {
    affiliate = filters.affiliateIds[0].data.affiliate;
  }

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : "60px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 0 : 2}
      height="100%"
      width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={breakpointDownSM ? "30px" : "35px"} fontWeight="700">
          {t("dashboard.title")}
        </Typography>
        <Stack direction="row" alignItems="center" spacing={2}>
          {affiliates.length > 1 && (
            <Box width="250px">
              <MultiSelectWithSearchComponent
                handleChange={(values) => globalStatisticsService.setFilters({ affiliateIds: values })}
                startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
                placeholder={t("global.filterByCompanies")}
                items={affiliates}
                values={filters.affiliateIds ?? []}
              />
            </Box>
          )}
        </Stack>
      </Stack>
      <AffiliateDashboardComponent affiliate={affiliate} />
    </Stack>
  );
};

export default Dashboard;
