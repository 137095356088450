import { CircularProgress, Container, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { MentionItem } from "react-mentions";
import { map } from "rxjs";

import { adDetailsQuery, adDetailsService } from "@store/ads/details";
import { affiliateDetailsService } from "@store/entities/afiliates/details";
import { UserRoleEnum } from "@store/users";

import CommentsComponent from "@components/input/Comments.component";

const AdsCommentTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { adId } = useParams();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchParams] = useSearchParams();

  const [{ adComments: comments, loading: commentsLoading }] = useObservable(adDetailsQuery.comments$);
  const [{ adDetails: ad }] = useObservable(adDetailsQuery.details$);

  const commentId = searchParams.get("commentId");

  useEffect(() => {
    if (adId) {
      adDetailsService.getAdComments(adId).subscribe({
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [adId, enqueueSnackbar]);

  const handleAddComment = (comment: string, mentions: MentionItem[]) => {
    if (!adId) return;
    adDetailsService.addComment(adId, comment, mentions).subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handleUpdateComment = (commentId: string, comment: string, mentions: MentionItem[]) => {
    if (!adId) return;
    adDetailsService.updateComment(adId, commentId, comment, mentions).subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  };

  if (!ad) return null;

  return (
    <Stack width="100%" alignItems="center" py={breakpointDownSM ? "20px" : "40px"} overflow="auto" className="scrollable">
      <Container maxWidth="md" sx={{ px: breakpointDownSM ? "4px" : undefined }}>
        <Stack width="100%">
          {commentsLoading ? (
            <CircularProgress sx={{ alignSelf: "center" }} size={20} />
          ) : (
            <CommentsComponent
              handleAddComment={handleAddComment}
              handleUpdateComment={handleUpdateComment}
              selectedCommentId={commentId ?? undefined}
              comments={comments}
              fetchTags={() =>
                affiliateDetailsService.getAffiliateUsers(ad.affiliate.id).pipe(
                  map((users) =>
                    users
                      .filter((user) => user.role !== UserRoleEnum.ADMIN)
                      .map((user) => ({
                        id: user.email,
                        display: `${user.firstname} ${user.lastname}`,
                      }))
                  )
                )
              }
            />
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

export default AdsCommentTab;
