import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { promptsStore } from "./prompts.store";

export const getPromptsDataSource = createRequestDataSource({
  store: promptsStore,
  data$: () => promptsStore.pipe(selectAllEntities()),
  requestKey: "getPrompts",
  dataKey: "prompts",
});
