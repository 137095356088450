import { Avatar, Box, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";

import { entityDetailsQuery } from "@store/entities/details";
import { EntityTypeEnum } from "@store/entities/entities.model";
import { sessionQuery } from "@store/session";

import { Colors } from "@constants/colors.constant";
import { AffiliateRoutes, ClientRoutes, Pages } from "@utils/routes.utils";

import AIOButtonComponent from "@components/Button.component";
import EntityModal from "@screens/auth/admin/entities/components/Entity.modal";
import EntityCard from "@components/card/Entity.card";
import { UserRoleEnum } from "@store/users";
import EntityCommentTab from "./EntityComments.tab";

export interface EntityDetailsTabProps {
  type: EntityTypeEnum;
}

const EntityDetailsTab = (props: EntityDetailsTabProps) => {
  const { type } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [addAffiliate, setAddAffiliate] = useState(false);

  const [entity] = useObservable(entityDetailsQuery.entity$(type));

  if (!entity) return null;

  const affiliates = entity.affiliates?.filter((a) => entity.type === EntityTypeEnum.CLIENT || a.id !== entity.id);

  return (
    <>
      <Stack
        width="100%"
        alignItems="center"
        py={breakpointDownSM ? "20px" : "40px"}
        overflow="auto"
        className="scrollable">
        <Container maxWidth="md" sx={{ px: breakpointDownSM ? "4px" : undefined }}>
          <Stack
            spacing={breakpointDownSM ? 2 : 4}
            direction={breakpointDownSM ? "column" : "row"}
            justifyContent="space-between">
            <Stack spacing={breakpointDownSM ? 2 : 4} width="100%">
              {!!entity.description && (
                <Stack spacing={breakpointDownSM ? 1 : 2}>
                  <Typography fontSize={20} fontWeight="700">
                    {t("clients.details.description")}
                  </Typography>
                  <Typography fontSize={14} fontWeight="300" textAlign="justify" color={Colors.grey}>
                    {entity.description}
                  </Typography>
                </Stack>
              )}
              {!!entity.otherInformation && (
                <Stack spacing={breakpointDownSM ? 1 : 2}>
                  <Typography fontSize={20} fontWeight="700">
                    {t("clients.details.otherInformation")}
                  </Typography>
                  <Typography fontSize={14} fontWeight="300" textAlign="justify" color={Colors.grey}>
                    {entity.otherInformation}
                  </Typography>
                </Stack>
              )}
              <Stack spacing={breakpointDownSM ? 1 : 2}>
                <Typography fontSize={20} fontWeight="700">
                  {t("clients.details.address")}
                </Typography>
                <Typography fontSize={14} fontWeight="300" color={Colors.grey}>
                  {entity.address}
                  {entity.city ? `, ${entity.city}` : ""}
                </Typography>
              </Stack>
              {!!entity.contacts.length && (
                <Stack spacing={breakpointDownSM ? 1 : 2}>
                  <Typography fontSize={20} fontWeight="700">
                    {t("clients.details.mainContactTitle")}
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      {entity.contacts.map((contact, index) => (
                        <Grid item xs={12} md={6} key={`contact ${index}`}>
                          <Stack direction="row" alignItems="center" flex={0.5} spacing={1.5}>
                            <Box p="1px" bgcolor={Colors.greyLight} borderRadius="50%">
                              <Avatar sx={{ border: `1.5px solid ${Colors.white}`, height: 50, width: 50 }}>
                                {contact.firstname?.[0]}
                                {contact.lastname?.[0]}
                              </Avatar>
                            </Box>
                            <Stack spacing={-0.5}>
                              <Typography fontSize={16} fontWeight="500">
                                {contact.firstname} {contact.lastname}
                              </Typography>
                              <Typography fontSize={12} fontWeight="300" color={Colors.grey}>
                                {contact.email}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Stack>
              )}
              {entity.type === EntityTypeEnum.AFFILIATE && !!entity.twinners?.length && (
                <Stack spacing={breakpointDownSM ? 1 : 2}>
                  <Typography fontSize={20} fontWeight="700">
                    {t("clients.details.twinnerTitle")}
                  </Typography>
                  <Box>
                    <Grid container spacing={2}>
                      {entity.twinners.map((twinner, index) => (
                        <Grid item xs={6} key={twinner.id}>
                          <Stack direction="row" alignItems="center" flex={0.5} spacing={1.5}>
                            <Box p="1px" bgcolor={Colors.greyLight} borderRadius="50%">
                              <Avatar src={twinner.picture} sx={{ border: `1.5px solid ${Colors.white}`, height: 50, width: 50 }}>
                                {twinner.firstname?.[0]}
                                {twinner.lastname?.[0]}
                              </Avatar>
                            </Box>
                            <Stack spacing={-0.5}>
                              <Typography fontSize={16} fontWeight="500">
                                {twinner.firstname} {twinner.lastname}
                              </Typography>
                              <Typography fontSize={12} fontWeight="300" color={Colors.grey}>
                                {twinner.email}
                              </Typography>
                            </Stack>
                          </Stack>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Stack>
              )}
              {sessionQuery.role === UserRoleEnum.ADMIN && (
                <EntityCommentTab type={type} />
              )}
            </Stack>
            <Stack width={breakpointDownSM ? "auto" : "300px"} minWidth={breakpointDownSM ? undefined : "300px"} spacing={breakpointDownSM ? 2 : 4}>
              {entity.type === EntityTypeEnum.AFFILIATE && "client" in entity && !!entity.client && (
                <Stack spacing={1}>
                  <Typography fontSize={20} fontWeight="700">
                    {t("clients.details.detailsTab.client")}
                  </Typography>
                  <EntityCard
                    hideType
                    hideMenu
                    entity={entity.client}
                    handleClick={!sessionQuery.buyerMode ? () => navigate(`../../../${entity.client!.id}/${ClientRoutes.DETAILS}`) : undefined}
                  />
                </Stack>
              )}
              {(entity.type === EntityTypeEnum.CLIENT || !!affiliates?.length) && (
                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" justifyContent="space-between" mr="-15px" maxWidth="100%">
                    <Typography fontSize={20} fontWeight="700">
                      {t("clients.details.detailsTab.affiliates")}
                    </Typography>
                    {entity.type === EntityTypeEnum.CLIENT && !entity.isAlsoAffiliate && sessionQuery.role === UserRoleEnum.ADMIN && (
                      <AIOButtonComponent
                        title={t("clients.details.detailsTab.addAffiliate")}
                        onClick={() => setAddAffiliate(true)}
                        variant="text"
                        color="primary"
                      />
                    )}
                  </Stack>
                  <Stack spacing={2}>
                    {affiliates?.map((affiliate) => (
                      <EntityCard
                        key={affiliate.id}
                        hideType
                        hideMenu
                        entity={affiliate}
                        handleClick={
                          !sessionQuery.buyerMode
                            ? () =>
                                navigate(
                                  `${type === EntityTypeEnum.AFFILIATE ? "../.." : `../../${Pages.AFFILIATES}`}/${affiliate.id}/${
                                    AffiliateRoutes.DETAILS
                                  }`
                                )
                            : undefined
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              )}
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Outlet />
      {addAffiliate && <EntityModal isCreation handleClose={() => setAddAffiliate(false)} type={EntityTypeEnum.AFFILIATE} />}
    </>
  );
};

export default EntityDetailsTab;
