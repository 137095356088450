import React, { useEffect, useMemo, useState } from "react";
import { CardActionArea, Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { personaeQuery, PersonaeQuiz, PersonaeQuizCategory, personaeService } from "@store/personae";
import { sessionQuery } from "@store/session";

import { Colors } from "@constants/colors.constant";

import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import { SelectItem } from "@components/input/Select.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";

const AnswerStyled = styled(Stack)(
  (props) => `
  background-color: transparent;
  border-radius: 15px;
  flex: 1;
  height: 100%;
  opacity: 1;
  width: 100%;
  & > *:first-of-type {
    align-items: flex-start;
    background-color: ${Colors.white};
    border: 1px solid ${props["aria-selected"] ? Colors.primary : Colors.background};
    border-radius: 15px;
    height: 100%;
    justify-content: space-between;
    padding: 14px 18px;
  };
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    };
  };
`
);

interface AddProfileModalProps {
  handleClose: () => void;
}

const AddProfileModal = (props: AddProfileModalProps) => {
  const { handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState<number | undefined>(undefined);

  const [createLoading, setCreateLoading] = useState(false);

  const [{ personaeQuiz, loading }] = useObservable(personaeQuery.personaeQuiz$);
  const [selectedOptions, setSelectedOptions] = useState<PersonaeQuiz>({});
  const [selectedAffiliate, setSelectedAffiliate] = useState<SelectItem | undefined>(undefined);

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const defaultSelected = useMemo(() => {
    return clientViewClient
      ? { value: clientViewClient.id, label: clientViewClient.name}
      : (sessionQuery.affiliatesItems.length === 1 ? sessionQuery.affiliatesItems[0] : undefined);
  }, [clientViewClient]);

  useEffect(() => {
    if (!selectedAffiliate && !defaultSelected) return;
    personaeService.getPersonaeQuiz(defaultSelected?.value || selectedAffiliate?.value || '').subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [defaultSelected, enqueueSnackbar, selectedAffiliate]);

  if (step === undefined) {
    return (
      <ModalComponent
        maxWidth="xs"
        icon={<img src="/images/new_persona.svg" alt="" />}
        title={t("personae.add.title")}
        handleClose={handleClose}
        content={
          <Stack spacing={"20px"} px="20px" py="10px">
            <Typography color={Colors.greyLight}>{t("personae.add.description")}</Typography>
            <SelectWithSearchComponent
              placeholder={t("personae.add.selectAffiliate")}
              value={defaultSelected ?? selectedAffiliate}
              items={defaultSelected ? [defaultSelected] : sessionQuery.affiliatesItems}
              handleChange={setSelectedAffiliate}
              readOnly={!!defaultSelected}
            />
          </Stack>
        }
        actions={(
          <AIOButtonComponent
            variant="contained"
            disabled={!defaultSelected && !selectedAffiliate}
            onClick={() => setStep(0)}
            title={t("personae.add.buttons.start")} />
        )}
      />
    );
  }

  if (!selectedAffiliate && !defaultSelected) return null;

  const handleCreate = () => {
    if (Object.keys(selectedOptions).some((key) => !selectedOptions[key].length)) return;
    setCreateLoading(true);
    personaeService
      .createPersonae(defaultSelected?.value || selectedAffiliate?.value || "", selectedOptions)
      .pipe(finalize(() => setCreateLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("personae.add.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const currentCategory = PersonaeQuizCategory.categories[step];
  const isSingleSelectionCategory = PersonaeQuizCategory.singleSelectionCategories.includes(currentCategory);

  return (
    <ModalComponent
      maxWidth="sm"
      titleLeft
      title={t("personae.add.quiz")}
      handleClose={handleClose}
      stepper={{ step, totalStep: PersonaeQuizCategory.categories.length }}
      content={
        <Stack py="10px" spacing={1} height="60vh">
          <Typography fontSize={14} fontWeight="500" lineHeight={1.25}>
            {PersonaeQuizCategory.question(currentCategory)}
          </Typography>
          <Stack spacing={1} overflow="auto" className="scrollable" py="20px" pr="5px">
            {personaeQuiz[PersonaeQuizCategory.fieldKey(currentCategory)]?.map((answer) => {
              const selected = selectedOptions[currentCategory]?.some((o) => o.id === answer.id);

              return (
                <AnswerStyled
                  key={answer.id}
                  aria-selected={selected}
                  onClick={() => {
                    if (selected) {
                      setSelectedOptions((state) => ({
                        ...state,
                        [currentCategory]: isSingleSelectionCategory ? [] : (state[currentCategory] ?? []).filter((o) => o.id !== answer.id),
                      }));
                    } else {
                      setSelectedOptions((state) => ({
                        ...state,
                        [currentCategory]: isSingleSelectionCategory ? [answer] : (state[currentCategory] ?? []).concat(answer),
                      }));
                    }
                  }}>
                  <Stack component={CardActionArea}>
                    <Typography fontSize={14} fontWeight="300" lineHeight={1.25}>
                      {answer.name}
                    </Typography>
                  </Stack>
                </AnswerStyled>
              );
            })}
          </Stack>
        </Stack>
      }
      actions={
        <>
          {step !== 0 && (
            <AIOButtonComponent
              variant="contained"
              disabled={loading || createLoading}
              onClick={() => setStep((state) => (state ?? 1) - 1)}
              title={t("personae.add.buttons.previous")}
            />
          )}
          <AIOButtonComponent
            variant="contained"
            disabled={loading || createLoading || !selectedOptions[currentCategory]?.length}
            onClick={step < PersonaeQuizCategory.categories.length - 1 ? () => setStep((state) => (state ?? 0) + 1) : handleCreate}
            title={t(`personae.add.buttons.${step < PersonaeQuizCategory.categories.length - 1 ? "next" : "finish"}`)}
          />
        </>
      }
    />
  );
};

export default AddProfileModal;