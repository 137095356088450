import { Button, Stack, Typography } from "@mui/material";
import { BoldTitle, Content } from "../legalStyle";
import AIOLinkInTxt from "@components/AIOLinkInTxt";
import { useResponsive } from "@utils/useResponsive";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutesUnAuth } from "@utils/routes.utils";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery } from "@store/session";

interface CGUUSProps {
  hideHeader?: boolean;
}

const CGUUS = (props: CGUUSProps) => {
  const {hideHeader} = props;

  const { isMobileOrTablet } = useResponsive();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [{ user }] = useObservable(sessionQuery.isAuthenticated$);
  const isAuthenticated = !!user;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && containerRef.current.parentElement) containerRef.current.parentElement.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Stack
      px={isMobileOrTablet ? "16px" : "40px"}
      pb={isMobileOrTablet ? "5px" : "60px"}
      height="100%"
      width="100%"
      overflow="auto"
      ref={containerRef}>
      {!hideHeader && (
        <>
          <Stack width={"100%"} direction={"row"} mt={isMobileOrTablet ? "15px" : "40px"}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<img alt="back" src="/images/arrow_back.svg" />}
              onClick={() => (isAuthenticated ? navigate(-1) : navigate(RoutesUnAuth.LOGIN))}>
              <Typography fontWeight="300" color="secondary">
                {t(`global.back`)}
              </Typography>
            </Button>
          </Stack>

          <Stack mt={"14px"} alignSelf={"center"} direction={"row"}>
            <Typography fontWeight={700} fontSize={"30px"}>
              General Terms and Conditions of ALLInOne
            </Typography>
          </Stack>
        </>
      )}
      <Stack mt={"45px"} alignSelf={"center"} spacing={3}>
        <BoldTitle>Article 1: Presentation of ALLInOne</BoldTitle>
        <Content>
          TWININ operates a software solution accessible via the website{" "}
          <AIOLinkInTxt label="https://aio.twinin.fr/login" url={"https://aio.twinin.fr/login"} /> (hereinafter the "Platform"), through which it
          makes available to any person seeking to recruit personnel (hereinafter the "Recruiter") tools to automate and thus improve their
          recruitment campaigns and processes (hereinafter the "Services").
        </Content>
        <Content>TWININ and the Recruiter are hereinafter referred to together or individually as a "Party".</Content>
        <Content>
          These general terms and conditions (hereinafter the "General Conditions") are intended to set the terms and conditions by which TWININ
          provides the Recruiters with the Services, as well as to define the rights and obligations of the Parties in this context.
        </Content>
        <Content>
          They express the entirety of the agreement of the Parties and cancel and replace any previous agreement, letter, offer, or other written or
          oral document having the same object.
        </Content>
        <Content>They are accessible and printable at any time by a direct link on the homepage of the Platform.</Content>

        <BoldTitle>Article 2: Operator of the Platform and Services</BoldTitle>
        <Content>
          The Platform and the Services are operated by the company TWININ, a simplified joint-stock company, registered in the Paris Trade and
          Companies Register under number 520 301 557, whose registered office is located at 229 rue Saint-Honoré, 75001 Paris.
        </Content>
        <Content>TWININ can be contacted at the following coordinates:</Content>
        <Stack spacing={1} pl={"20px"}>
          <Content>Email address: info@twinin.fr</Content>
          <Content>VAT number: FR83520301557</Content>
        </Stack>

        <BoldTitle>Article 3: Acceptance of General Conditions</BoldTitle>
        <Content>
          The Recruiter declares, by subscribing to the Services under the conditions provided for hereinafter, to have taken note of these General
          Conditions and to accept them expressly.
        </Content>
        <Content>
          This acceptance, which is materialized by a checkbox in the registration form, can only be full and entire, without modification of any
          kind.
        </Content>
        <Content>
          Any conditional membership is considered null and void. The Recruiter who does not accept to be bound by these General Conditions must not
          subscribe to the Services, nor access the Platform.
        </Content>
        <BoldTitle>Article 4: Access to the Platform and Services</BoldTitle>
        <Content>
          The Platform and the Services are accessible:
          <ul>
            <li>To any natural person with full legal capacity to commit under these General Conditions and at least eighteen (18) years old;</li>
            <li>To any legal entity acting through a natural person with the powers to contract in the name and on behalf of the legal entity.</li>
          </ul>
        </Content>
        <Content>The Services are intended for professional Recruiters acting within the framework of their activity.</Content>

        <BoldTitle>Article 5: Registration to the Platform and Services</BoldTitle>
        <Content>
          <BoldTitle>
            5.1 To access the Services and subscribe to them, the Recruiter is required to open an account on the Platform, according to the procedure
            described below (hereinafter the "Account").
          </BoldTitle>
        </Content>
        <Content>
          For the purpose of opening their Account, the Recruiter must:
          <ul>
            <li>
              Fill in the form provided for this purpose by providing all the required information, including their name, first name, email address,
              profile picture, and job title;
            </li>
            <li>Choose a password, which can be changed at any time. It is recommended to change the password regularly;</li>
            <li>Choose the desired subscription formula;</li>
            <li>
              Indicate the number of users to whom the Recruiter wishes to give access to the Platform (hereinafter the "User"); each User will have
              an Account allowing them to use the Services;
            </li>
            <li>
              Check, on the registration form, the boxes (i) "I have read and accept the general conditions of ALLInOne" and (ii) "I have read and
              accept the general conditions of use of the payment service provider Stripe".
            </li>
          </ul>
        </Content>
        <Content>
          The Recruiter must provide all the information marked as mandatory, which they acknowledge and accept. Any incomplete registration will not
          be validated, which the Recruiters acknowledge and accept.
        </Content>
        <Content>
          The Recruiter is informed that TWININ implements a process of verification of the Account created by them, in particular to ensure their
          identity and/or their contact details.
        </Content>
        <Content>
          The Recruiter acknowledges and accepts that registration for the Services entails automatic registration for transaction-related emails from
          the Platform (received message, payment accepted, etc.).
        </Content>
        <Content>
          <BoldTitle>5.2 The registration of Recruiters entails, after validation by TWININ, the opening of:</BoldTitle>
          <ul>
            <li>An Account in their name, on the back office of the Platform (hereinafter the "Administrator Account");</li>
            <li>As many User Account(s) as indicated in the registration form (hereinafter the "User Account(s)").</li>
          </ul>
        </Content>
        <Content>
          Each Account provides access to a personal space (hereinafter the "Personal Space") which allows the person responsible for it to manage
          their use of the Services in a form and according to the technical means that TWININ deems most appropriate to provide the Services.
        </Content>
        <Content>
          The Recruiter and/or the Users access their Personal Space at any time after identifying themselves using their login and password.
        </Content>
        <Content>
          The Recruiter guarantees that all the information they enter in the registration form is accurate, up-to-date, and sincere, and is not
          tainted with any misleading character.
        </Content>
        <Content>
          They undertake to update these informations in their Personal Space without delay in case of modifications, so that they always meet the
          aforementioned criteria.
        </Content>
        <Content>
          Recruiters are informed and accept that the information entered for the creation or updating of the Accounts is proof of the identity of the
          people who use it. The information entered by the Recruiters binds them upon their validation.
        </Content>
        <BoldTitle>Article 6: Strictly Personal Use</BoldTitle>
        <Content>
          The Recruiter undertakes to ensure that only people, members of their staff, mandated for this purpose, use the Administrator Account and
          the User Accounts. They thus commit not to allow any third party, not expressly mandated, to use the Accounts or the Services in place of or
          on behalf of the person expressly mandated, except to bear full responsibility for it.
        </Content>
        <Content>
          The Recruiter is also responsible for maintaining the confidentiality of the identifiers and password and expressly recognizes that any use
          of the Services from the Accounts will be deemed to have been carried out by the person responsible for it (the Administrator or the
          concerned User).
        </Content>
        <Content>
          In the event that the Recruiter notices that one of the Accounts has been used by an unauthorized person, they undertake to notify TWININ as
          soon as possible, at the coordinates mentioned in the article "Operator of the Platform and Services". They acknowledge TWININ's right to
          take all appropriate measures in such a case.
        </Content>

        <BoldTitle>Article 7: Non-Commercial Use</BoldTitle>
        <Content>
          The Recruiter is prohibited from monetizing, selling, granting, exchanging, and more generally, negotiating all or part of the access to the
          Services or the Platform, as well as the information and/or texts, images, and other content used by TWININ on the Platform as part of the
          use of the Services.
        </Content>
        <BoldTitle>Article 8: Description of Services</BoldTitle>
        <Content>
          The Recruiter has access to the following Services, depending on the subscription formula chosen, in a form and according to the technical
          means that TWININ deems most appropriate.
        </Content>

        <BoldTitle>"Freemium" Formula</BoldTitle>
        <Content>Through this formula, TWININ provides the Recruiter with the following Services and functionalities:</Content>
        <Stack spacing={1} pl={"20px"}>
          <Content>Broadcasting and management, from their Account, of job offers to different job sites;</Content>
          <Content>Reception, on their Account, of all applications sent on the various job sites;</Content>
          <Content>
            Marketing boost; this Service, dedicated to improving the visibility of job offers, allows the Recruiter to benefit from an increase in
            the number of CVs received per job offer;
          </Content>
          <Content>
            AI Finder; this Service allows creating a "matching" or a correspondence between candidate profiles on LinkedIn and job offers published
            on the Platform;
          </Content>
          <Content>
            Publishing job offers via AI on LinkedIn; through this Service, the Platform allows generating, via artificial intelligence and on behalf
            of the Recruiter, a targeted and personalized post on LinkedIn;
          </Content>
          <Content>Direct communication with candidates;</Content>
          <Content>Building, accessing, and managing a candidate database (CV database).</Content>
        </Stack>

        <BoldTitle>"Professional" Formula</BoldTitle>
        <Content>
          This formula allows the Recruiter to benefit from all the Services offered as part of the aforementioned "Freemium" formula, but also the
          following additional Services:
        </Content>
        <Stack spacing={1} pl={"20px"}>
          <Content>Creation of a career site or site, for the Recruiter, dedicated to recruitment by AI;</Content>
          <Content>
            Synthetic analysis or "parsing" of CVs (unlimited); through this Service, the Recruiter has access to a tool for scanning and selecting
            all the information contained in a CV.
          </Content>
        </Stack>

        <BoldTitle>"Enterprise" Formula</BoldTitle>
        <Content>
          Through this formula, the Recruiter benefits from the Services offered as part of the "Professional" formula, but also the following
          additional Services:
        </Content>
        <Stack spacing={1} pl={"20px"}>
          <Content>
            Implementation of automated sourcing scenarios; this corresponds to the "Marketing Boost" Service which are additional paid services upon
            triggering;
          </Content>
          <Content>
            Deployment of an automatic media plan by artificial intelligence to optimize the distribution of the Recruiter's job offers.
          </Content>
        </Stack>

        <BoldTitle>"Employer Brand" Formula</BoldTitle>
        <Content>Through this formula, the Recruiter has access to the following Services and functionalities:</Content>
        <Stack spacing={1} pl={"20px"}>
          <Content>Employee audit.</Content>
          <Content>A/B Testing.</Content>
          <Content>Creation.</Content>
          <Content>Declination.</Content>
          <Content>Brand book.</Content>
        </Stack>

        <Content>
          TWININ reserves the right to offer any other Service that it deems useful, in a form and according to the functionalities and technical
          means it deems most appropriate to provide these Services.
        </Content>

        <BoldTitle>Article 9: Financial Conditions</BoldTitle>

        <BoldTitle>9.1 Price of Services</BoldTitle>
        <Content>
          The price of Services is based on the chosen subscription formula, as detailed below:
          <ul>
            <li>"Freemium" Formula: Services available free of charge;</li>
            <li>"Professional" Formula: Services accessible upon payment of a monthly sum of 79 euros per User.</li>
            <li>"Enterprise" Formula: Services accessible upon payment of a monthly sum of 99 euros per job offer published on the Platform.</li>
            <li>"Employer Brand" Formula: Services accessible from the payment of a lump sum of 9,990 euros.</li>
          </ul>
        </Content>
        <Content>
          TWININ offers the possibility for any Recruiter who wishes to contact them to obtain additional information on the Services provided as part
          of this offer, as well as on the payment terms of its price.
        </Content>
        <Content>Unless otherwise stated, prices are indicated in euros and exclude taxes.</Content>
        <Content>
          It is specified that TWININ applies a 10% discount for any subscription to these offers as part of an annual fixed-term subscription (see:
          “Duration” article below).
        </Content>

        <BoldTitle>9.2 Payment Terms</BoldTitle>
        <Content>
          Payment of Subscriptions is made by automatic debit from the bank account whose details were provided at the time of subscription to the
          Services (hereafter the "Direct Debit"), which the Recruiter acknowledges and accepts.
        </Content>
        <Content>
          The Direct Debit of the Subscription price occurs on the date subscribed in the first month, then on that same date every subsequent month,
          unless otherwise indicated on the Platform at the time of subscription (hereafter the "Debit Date").
        </Content>
        <Content>The Recruiter undertakes to take all necessary measures to ensure that the Direct Debit can be made.</Content>
        <Content>
          The Direct Debit is carried out by the following secure payment solution provider STRIPE (hereafter the "Payment Provider"), to whom TWININ
          subcontracts the implementation of the payment and who alone retains the Recruiter's bank details for this purpose.
        </Content>
        <Content>The Recruiter is informed that TWININ does not keep any bank details.</Content>
        <Content>The Recruiter makes the payment using one of the secure payment methods offered on the Platform.</Content>
        <Content>The Recruiter guarantees TWININ that he has the necessary authorizations to use the chosen payment method.</Content>
        <Content>
          TWININ sends the Recruiter an invoice corresponding to the price of the Services it makes available to the Recruiter within the Personal
          Space of his Administrator Account.
        </Content>

        <BoldTitle>9.3 Price Revision</BoldTitle>
        <Content>The price of Services may be revised by TWININ at any time and at its sole discretion.</Content>
        <Content>
          The Recruiter will be informed of any price change by any useful written means, particularly by email, at least one and a half months before
          the new rates take effect.
        </Content>
        <Content>The new prices apply at the renewal of the Recruiter’s Subscription.</Content>
        <Content>
          The Recruiter who does not accept the new prices must denounce his Subscription under the conditions of the "Recruiter Unsubscription"
          article. Otherwise, the Recruiter is deemed to have accepted the new rates.
        </Content>

        <BoldTitle>9.4 Banking Information</BoldTitle>
        <Content>
          The banking information of Recruiters in connection with their use of the Services is subject to automated data processing, for which the
          Payment Provider is responsible.
        </Content>

        <BoldTitle>9.5 Default of Payment</BoldTitle>
        <Content>
          The Recruiter is expressly informed and accepts that any delay in payment of all or part of the sums due at their due date will
          automatically result, without prejudice to the provisions of the "Termination for Breach" article, without prior notice:
          <ul>
            <li>The forfeiture of the term of all sums due by the Recruiter and their immediate exigibility;</li>
            <li>The immediate suspension of the Services in progress until the complete payment of all sums due by the Recruiter;</li>
            <li>
              The billing in favor of TWININ of late interest at the rate of three (3) times the legal interest rate, based on the amount of all sums
              due by the Recruiter, and a lump sum indemnity of forty (40) euros for recovery costs.
            </li>
          </ul>
        </Content>
        <BoldTitle>Article 10: Duration</BoldTitle>
        <BoldTitle>10.1 Duration of Services</BoldTitle>
        <Content>The Services are provided to Recruiters for the duration for which they have subscribed.</Content>
        <Content>
          In this regard, the Recruiter may choose to commit, under these terms, as part of:
          <ul>
            <li>
              Either a fixed-term subscription of one (1) month, tacitly renewable for successive periods of the same duration, subject to any
              denunciation by the Recruiter under the conditions of the "Unsubscription" article below;
            </li>
            <li>Or a fixed-term subscription of twelve (12) months, without tacit renewal.</li>
          </ul>
        </Content>
        <Content>The choice for the duration of the Subscription is made by the Recruiter at the time of subscription to the Services.</Content>
        <BoldTitle>10.2 Unsubscription</BoldTitle>
        <BoldTitle>Subscriptions renewable by tacit agreement</BoldTitle>
        <Content>
          The Recruiter who has subscribed to a fixed-term Subscription of one (1) month renewable by tacit agreement may unsubscribe (hereafter the
          "Unsubscription"), at his choice:
          <ul>
            <li>
              Either by sending to TWININ, by email to the coordinates mentioned in the "Operation of the Platform and Services" article at the latest
              2 days before the renewal date, a request to this effect (hereafter the "Unsubscription");
            </li>
            <li>Or by denouncing his Subscription directly from the Platform, through his Account, at the latest 1 month before the renewal date.</li>
          </ul>
        </Content>
        <Content>If the Recruiter fails to unsubscribe under these conditions, he is re-engaged, at least for the following month.</Content>
        <BoldTitle>Subscriptions without tacit renewal</BoldTitle>
        <Content>
          The Recruiter cannot unsubscribe from a fixed-term Subscription of twelve (12) months, which he acknowledges and accepts. He is thus bound
          to go to the end of the duration of his Subscription.
        </Content>
        <Content>The Recruiter receives an email confirmation of his Unsubscription as soon as possible.</Content>

        <BoldTitle>10.3 Termination for Breach</BoldTitle>
        <Content>
          By exception, in case of a breach by the Recruiter of any of his obligations under these General Conditions, which is not remedied within
          thirty (30) days from the receipt of a notice to remedy said breach, TWININ may, automatically and without judicial formality, terminate
          these General Conditions and close his or their Account(s).
        </Content>
        <Content>
          The termination of these is without prejudice to any damages to which TWININ could claim due to the breach of the Recruiter and any penalty
          that may be due to it.
        </Content>

        <BoldTitle>10.4 Consequences of the End of Services</BoldTitle>
        <Content>
          The end of Services does not result in the deletion of the Recruiter's Account(s), who will continue to have access to it, without however
          being able to use the Services.
        </Content>
        <Content>
          The Recruiter is expressly informed and accepts that all information accessible via his or their Account(s) may remain accessible on it or
          them, for a maximum of three (3) months. Thus, the Recruiter will manage to recover the data in the format of his choice.
        </Content>
        <Content>
          Beyond this period, TWININ is free to delete any access to the Accounts and/or the data hosted there, which the Recruiter acknowledges and
          accepts.
        </Content>
        <BoldTitle>Article 11: Service Level Guarantee</BoldTitle>
        <Content>
          TWININ will make its best efforts so that the Platform operates without interruption 24 hours a day, 7 days a week, except in case of force
          majeure.
        </Content>
        <Content>
          If necessary, TWININ reserves the possibility to limit or suspend access to the Platform, to carry out any maintenance and/or improvement
          operations.
        </Content>
        <Content>
          In this case, TWININ undertakes to make its best efforts to inform in advance by a general informative message on the homepage of the
          Platform of these maintenance and/or improvement operations.
        </Content>
        <Content>
          As part of these maintenance and/or evolution operations, TWININ undertakes to make its best efforts to perform backups of the contents
          hosted there, to allow their restoration, as is, on the date of the maintenance and/or evolution operations.
        </Content>
        <Content>
          TWININ's liability cannot in any case be engaged in case of loss of contents during maintenance and/or evolution operations.
        </Content>
        <Content>
          TWININ also undertakes to take all necessary precautions, in view of the nature of the data and the risks presented by the automated data
          processing implemented for the needs of the Services, to preserve the security of the data, and in particular to prevent them from being
          distorted, damaged or accessed by unauthorized third parties.
        </Content>
        <BoldTitle>Article 12: TWININ's Data</BoldTitle>
        <Content>
          The Recruiter expressly acknowledges and accepts:
          <ul>
            <li>
              that the data collected on the Platform and the computer equipment of TWININ are proof of the reality of the operations carried out
              under these terms;
            </li>
            <li>that these data constitute an accepted mode of proof between the parties.</li>
          </ul>
        </Content>

        <BoldTitle>Article 13: Obligations of the Recruiter</BoldTitle>
        <Content>Without prejudice to other obligations provided for herein, the Recruiter undertakes to respect the following obligations.</Content>
        <Content>
          (i) The Recruiter undertakes, in his use of the Services, to respect the laws and regulations in force and not to infringe the rights of
          third parties or public order.
        </Content>
        <Content>
          (ii) The Recruiter acknowledges having taken note of the characteristics and constraints, particularly technical, of all the Services. He is
          solely responsible for his use of the Services and/or those of his Users, particularly concerning the relationships he/they may establish
          with candidates and the information he/they communicate to them within the framework of the Services. He/they must exercise appropriate
          caution and discernment in these relationships and communications. The Recruiter also undertakes, in his exchanges with candidates, to
          respect the usual rules of politeness and courtesy. He is responsible for ensuring that Users respect these obligations and more generally,
          all those incumbent upon them in the execution of these terms.
        </Content>
        <Content>
          (iii) The Recruiter is solely responsible for the content of any kind (editorial, graphic, audio, audiovisual or other, including the name
          and/or image possibly chosen by him or a User to identify himself on the Platform) that he disseminates on his Accounts (Administrator and
          Users) in the framework of the Services (hereafter referred to as "Content"). He guarantees to TWININ that he has all the rights and
          permissions necessary for the dissemination of these Contents. He undertakes to ensure that these Contents are lawful, do not infringe
          public order, good morals or the rights of third parties, do not violate any legislative or regulatory provisions and more generally, are
          not likely to engage the civil or criminal liability of TWININ. The Recruiter thus refrains from disseminating, in particular and without
          this list being exhaustive:
          <ul>
            <li>
              pornographic, obscene, indecent, shocking or unsuitable for a family audience, defamatory, insulting, violent, racist, xenophobic or
              revisionist contents,
            </li>
            <li>counterfeit contents,</li>
            <li>contents infringing on the image of a third party,</li>
            <li>misleading or deceptive contents or proposing or promoting illegal, fraudulent or deceptive activities,</li>
            <li>contents harmful to the computer systems of third parties (such as viruses, worms, Trojan horses, etc.),</li>
            <li>
              and more generally, contents likely to infringe the rights of third parties or to be harmful to third parties, in any way and in any
              form whatsoever.
            </li>
          </ul>
        </Content>
        <Content>
          (iv) The Recruiter acknowledges that the Services offer him an additional, not alternative, solution to automate and thus improve his
          recruitment campaigns and processes and that this solution cannot replace other means that he may have at his disposal to achieve the same
          goal.
        </Content>
        <Content>
          (v) The Recruiter acknowledges and accepts that he must take the necessary measures to back up by his own means the information from the
          Personal Spaces of his Accounts that he deems necessary, of which no copy will be provided to him.
        </Content>
        <Content>
          (vi) The Recruiter undertakes to provide TWININ with all documents, elements, data and information necessary for the proper execution of the
          Services. He also undertakes to ensure that the information communicated is accurate, complete and up-to-date. The Recruiter remains solely
          responsible for the consequences that may result from the provision of false or incorrect information to TWININ.
        </Content>
        <Content>
          (vii) More generally, the Recruiter undertakes to actively cooperate with TWININ in order to ensure the proper execution of these terms and
          to inform it of any difficulties related to this execution.
        </Content>
        <Content>
          (viii) The Recruiter is informed and accepts that the implementation of the Services requires him to be connected to the internet and that
          the quality of the Services directly depends on this connection, for which he is solely responsible.
        </Content>

        <Content>
          It is strictly prohibited for any Recruiter and/or User to copy and/or divert, for his own ends or for those of third parties, the concept,
          the technologies or any other element of the Platform.
        </Content>
        <Content>
          The following are also strictly prohibited: (i) all behaviors likely to interrupt, suspend, slow down or prevent the continuity of the
          Services, (ii) all intrusions or attempts to intrude into TWININ's systems, (iii) all diversions of the Platform's system resources, (iv)
          all actions likely to impose a disproportionate load on the infrastructures of the latter, (v) all acts likely to infringe the financial,
          commercial or moral rights and interests of TWININ and finally, more generally, (vii) any breach of these General Conditions.
        </Content>
        <Content>The Recruiter guarantees TWININ against any behavior, action or omission, of a User contrary to these terms.</Content>
        <BoldTitle>Article 14: Complaints and Claims</BoldTitle>
        <Content>
          The Recruiter has the option to transmit a complaint or claim to TWININ at the coordinates mentioned in the "Operator of the Platform and
          Services" article.
        </Content>
        <Content>TWININ undertakes to respond to any claim as soon as possible.</Content>

        <BoldTitle>Article 15: Sanctions for Breaches</BoldTitle>
        <Content>
          In case of a breach of any of the provisions of these General Conditions, or more generally, of infringement of the laws and regulations by
          the Recruiter or one of his Users, TWININ reserves the right to:
        </Content>
        <Stack spacing={1} pl={"20px"}>
          <Content>
            (i) temporarily or permanently suspend, without delay, the access of the Recruiter and/or the User who committed the breach or the
            offense, or participated in it;
          </Content>
          <Content>(ii) delete any content related to the breach or offense considered, in whole or in part;</Content>
          <Content>(iii) take all appropriate measures and initiate any legal action;</Content>
          <Content>
            (iv) if necessary, warn the competent authorities, cooperate with them and provide them with all the useful information for the
            investigation and prosecution of illegal or illicit activities.
          </Content>
        </Stack>
        <BoldTitle>Article 16: Limitation of Liability</BoldTitle>
        <Content>
          TWININ commits to make its best efforts to provide the Services diligently and according to the rules of the art, it being specified that it
          has an obligation of means, to the exclusion of any obligation of result, which the Recruiter expressly acknowledges and accepts.
        </Content>
        <Content>
          Its responsibility is exclusively limited to the provision of the Services according to the terms described herein, excluding any other
          provision. The Recruiter further acknowledges and accepts that the Services are provided as is without any warranty of any kind, express or
          implied.
        </Content>
        <Content>
          Notably, TWININ does not guarantee that:
          <ul>
            <li>
              the Services, subjected to constant research to improve performance and progress, will be totally free of errors, defects, or defects;
            </li>
            <li>
              the Services, being standard and not offered solely for the intention of the Recruiter according to his own personal constraints, will
              specifically meet his needs and expectations;
            </li>
            <li>
              the Platform will operate uninterrupted, TWININ reserving the possibility to interrupt access momentarily, particularly for maintenance
              reasons as provided in the "Service Level Guarantee" article, and cannot in any case be held responsible for disruptions or failures of
              the internet network or telecommunications network, and more generally that would have their origin in circumstances that are external
              to it or resulting from force majeure.
            </li>
          </ul>
        </Content>
        <Content>
          The Recruiter acknowledges and accepts that TWININ acts as a simple intermediary between him and the candidates. It is, therefore, not a
          party to the agreements concluded and/or the relationships established between the candidates and the Recruiters. It also cannot guarantee
          the quality of the applications received.
        </Content>
        <Content>
          Its responsibility is limited to making available to Recruiters the technical means enabling them to connect with candidates for job offers
          published through the Platform. Its responsibility is limited to the provision of these means and this, under the conditions provided
          herein.
        </Content>
        <Content>
          TWININ can therefore not be held responsible for any dispute or litigation that would occur between a candidate and the Recruiter. The
          Recruiter agrees to use the Platform at his own risk and assumes full responsibility for his actions on the Platform.
        </Content>
        <Content>
          Moreover, TWININ is not aware of the Contents uploaded by Recruiters or Users as part of their use of the Services, over which it performs
          no moderation, selection, verification or control of any kind and with respect to which it intervenes only as a hosting provider.
        </Content>
        <Content>Consequently, TWININ cannot be held responsible for the Content published through the Platform.</Content>
        <Content>
          TWININ and the Recruiter are independent parties, each acting in their own name and on their own behalf. The General Conditions do not
          create any link of subordination of any kind or employer/employee relationship between TWININ and the Recruiters.
        </Content>
        <BoldTitle>Article 17: Intellectual Property</BoldTitle>
        <Content>
          <BoldTitle>17.1 Intellectual Property of TWININ</BoldTitle>
          The General Conditions do not confer any intellectual property rights of any kind on the Recruiter over the Platform, as well as on all the
          texts, images, content exploited by TWININ on the Platform, including these General Conditions, trademarks, trade names and logos, software,
          structures, infrastructures and databases used by TWININ within the Platform, which remain the exclusive, full and entire property of
          TWININ.
        </Content>
        <Content>
          Any act of disassembly, decompilation, decryption, extraction, reuse, and more generally any reproduction, representation, distribution,
          adaptation, marketing of the Site and/or of the texts, images, content exploited by TWININ on the Platform, not in accordance with the
          provisions of these General Conditions, are prohibited and may be subject to legal proceedings.
        </Content>
        <Content>
          <BoldTitle>17.2 Intellectual Property of the Recruiter</BoldTitle>
          In the event that the Recruiter could claim a copyright on the contents that he would transmit to TWININ via the Platform, he grants,
          hereby, to TWININ, a free license, for the whole world, and for the entire legal duration of the copyright rights, to use, reproduce,
          represent, publish, make available, communicate, modify, adapt, display, on any support (including any physical or digital support), by any
          means, all or part of the contents of which he would be the author, protected by copyright.
        </Content>
        <Content>
          <BoldTitle>17.3 Right to the Recruiter's Image</BoldTitle>
          By communicating, through the Platform, the photo of his Administrator and/or Users (hereafter the "Photo"), the Recruiter declares and
          guarantees that the persons whose image is provided to TWININ expressly authorize it to use, by way of fixing, representation, reproduction,
          adaptation, and diffusion their image as appearing on the Photo (hereafter the "Image").
        </Content>
        <Content>
          This authorization is granted free of charge and for the whole world; it carries the possibility, for TWININ, to add to the Photo any
          addition, deletion, modification it would deem useful, provided that these modifications do not lead to any alteration of the personality
          and the Image.
        </Content>
        <Content>
          By "Image," it is understood the attributes of the personality of the person who is the subject of it, that are, his image, his name, his
          first name.
        </Content>
        <Content>This authorization is granted for the entire duration of the Recruiter's access to the Services.</Content>
        <BoldTitle>Article 18: Insurance</BoldTitle>
        <Content>
          TWININ has subscribed to an insurance policy with the company MAAF, SA registered with the RCS Niort under the number 542 073 580 - Code APE
          6512 Z, whose headquarters is located in Chaban 79180 CHAURAY.
        </Content>
        <Content>This contract is applicable throughout the territory of France.</Content>
        <BoldTitle>Article 19: Force Majeure</BoldTitle>
        <Content>
          TWININ cannot be held responsible towards the Recruiter in the event that the execution of its obligations is delayed, restricted or made
          impossible due to the occurrence of a force majeure event. Cases of force majeure are notably considered as those usually recognized by the
          case law and the French courts.
        </Content>
        <BoldTitle>Article 20: Nullity</BoldTitle>
        <Content>
          If any provision of these General Conditions were to be declared null or inapplicable due to a law, a regulation, or as a result of a final
          decision rendered by a competent jurisdiction, only this provision would be nullified, the other provisions will retain all their force and
          scope.
        </Content>

        <BoldTitle>Article 21: Modifications of the General Conditions</BoldTitle>
        <Content>
          TWININ reserves the right to modify these General Conditions, but also to modify and/or stop offering all or part of the Services.
        </Content>
        <Content>Recruiters will be informed of any change by electronic mail, at least one and a half months before they take effect.</Content>
        <Content>The modified General Conditions apply at the renewal of the Recruiter’s Subscription.</Content>
        <Content>
          The Recruiter who would not accept the modified General Conditions is required to denounce his Subscription under the conditions of the
          "Recruiter Unsubscription" article. Otherwise, the Recruiter is deemed to have accepted the modified General Conditions.
        </Content>

        <BoldTitle>Article 22: Personal Data</BoldTitle>
        <Content>
          TWININ takes the protection of personal data very seriously. For more information on how we process your data, please consult our personal
          data policy.
        </Content>

        <BoldTitle>Article 23: Notifications</BoldTitle>
        <Content>
          For any request for information, TWININ can be contacted at the coordinates mentioned in the "Operator of the Platform and Services" article
          of these terms.
        </Content>

        <BoldTitle>Article 24: Language</BoldTitle>
        <Content>
          In the event of a translation of these General Conditions into one or more languages, the language of interpretation will be French in case
          of contradiction or dispute on the meaning of a term or a provision.
        </Content>

        <BoldTitle>Article 25: Applicable Law and Jurisdiction</BoldTitle>
        <Content>These General Conditions are governed by French law.</Content>
        <Content>
          In the event of a dispute over the validity, interpretation, and/or execution of these General Conditions, the Parties agree that the courts
          of Paris will have exclusive jurisdiction to judge it, unless mandatory procedural rules to the contrary.
        </Content>

        <BoldTitle>Article 26: Employment Offer Broadcasting Mandate</BoldTitle>
        <Content>ALLinOne is a recruitment platform that has developed expertise and a deep competence in designing, broadcasting, and optimizing recruitment campaigns.</Content>
        <Content>From this day forward, the Client entrusts ALLinOne with the broadcasting and management of the Client's recruitment advertisements.</Content>
        <Content>
          The mandate aims to entrust ALLinOne with the broadcasting, management, and optimization of the client's job offers. This mission is reflected in :
          <ul>
            <li>
              The management of free and paid spaces for the broadcasting of recruitment advertisements ("Client Advertisements") provided by the Client on appropriate mediums. To this end, ALLinOne will be entrusted with the passwords and identifiers of employment sites or will be named "administrator" on employment platforms for a complete takeover of the job offers broadcasting.
            </li>
            <li>
              The purchase of spaces for the broadcasting of Contents to ensure their communication to the public, and the redirection of internet users to the ALLinOne platform under the conditions defined hereafter.
            </li>
          </ul>
        </Content>
        <Content>
          Thus, the Client mandates ALLinOne to post, on its behalf and account, its job offers with all the media, supports, HR-type media offers, negotiation missions, signing of the space purchase agreement for placing its Contents.
        </Content>
      </Stack>
    </Stack>
  );
};

export default CGUUS;
