import { affiliatesStore } from "@store/entities/afiliates/affiliates.store";
import { getAffiliatesDataSource } from "@store/entities/afiliates/affiliates.requests";
import { selectPaginationData } from "@ngneat/elf-pagination";

export class AffiliatesQuery {
  store = affiliatesStore;

  affiliates$ = getAffiliatesDataSource.data$();
  affiliatesPaginationData$ = this.store.pipe(selectPaginationData());
}

export const affiliatesQuery = new AffiliatesQuery();
