import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";

import { UnipileInvitationFiltersModel } from "./unipileInvitations.model";
import { unipileInvitationsService } from "@store/unipile/unipileInvitations/unipileInvitations.service";

interface SearchUnipileInvitationsParams {
  filters?: UnipileInvitationFiltersModel;
  adId: string;
}

export const searchUnipileInvitationsEffect = createEffectFn((params$: Observable<SearchUnipileInvitationsParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ adId, filters, }) =>
      unipileInvitationsService.getUnipileLinkedinInvitations(adId, filters)
    ),
  );
});
