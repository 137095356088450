import React, { useEffect, useState } from "react";
import { Box, Button, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";

import { sessionQuery } from "@store/session";
import {
  entitiesFiltersQuery,
  entitiesFiltersService,
  EntitiesSortFieldEnumFunctions
} from "@store/entities/entitiesFilters";
import {
  EntityFavoritesFilterEnum,
  EntityFavoritesFilterEnumFunctions,
  EntityTypeEnum
} from "@store/entities/entities.model";

import { ClientTabRoutes, useRouteMatch } from "@utils/routes.utils";

import AIOButtonComponent from "@components/Button.component";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import AIOSelectComponent from "@components/input/Select.component";
import EntityModal from "@screens/auth/admin/entities/components/Entity.modal";
import { UserRoleEnum } from "@store/users";
import { Colors } from "@constants/colors.constant";
import UsersWaitingValidationModal
  from "@screens/auth/admin/entities/components/usersWaitingValidation/UsersWaitingValidation.modal";
import { registerUsersService } from "@store/registerUsers";
import AIORoutesTabComponent from "@components/AIORoutesTab.component";

export const getEntityType = (route: string | null) => {
  switch (route) {
    case ClientTabRoutes.AFFILIATES:
      return EntityTypeEnum.AFFILIATE;
    case ClientTabRoutes.LIST:
      return EntityTypeEnum.CLIENT;
    default:
      return EntityTypeEnum.AFFILIATE;
  }
};

const Entities = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const breakpointDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const [addClient, setAddClient] = useState(false);
  const [manageUsersValidation, setManageUsersValidation] = useState(false);

  const currentRoute = useRouteMatch(ClientTabRoutes.routes.map((route) => route.path));

  const [filters] = useObservable(entitiesFiltersQuery.filters$);
  const [sort] = useObservable(entitiesFiltersQuery.sort$);

  const [registerUsersCount, setRegisterUsersCount] = useState<number>(0);

  useEffect(() => {
    if (sessionQuery.role === UserRoleEnum.ADMIN) {
      registerUsersService.getRegisterUsersCount().subscribe({
        next: setRegisterUsersCount,
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [enqueueSnackbar]);

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : sessionQuery.role === UserRoleEnum.ADMIN || sessionQuery.buyerMode ? "60px" : "0px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 0 : 4}
      flex={1}
      width="100%"
      overflow="auto">
      {(sessionQuery.role === UserRoleEnum.ADMIN || sessionQuery.buyerMode) && (
        <Stack direction={breakpointDownMD ? "column" : "row"} alignItems="center" justifyContent="space-between">
          <Typography fontSize={breakpointDownSM ? "30px" : "35px"} fontWeight={700}>
            {t("clients.title")}
            {sessionQuery.buyerMode ? ` ${t("clients.buyerClient")}` : ""}
          </Typography>
          {sessionQuery.role === UserRoleEnum.ADMIN && (
            <Stack
              direction={breakpointDownSM ? "column" : "row"}
              alignItems={"center"}
              spacing={2}
              marginTop={breakpointDownMD ? "20px" : undefined}>
              <Box sx={{ "& .MuiButton-root": { height: 35 } }}>
                <Button
                  variant="contained"
                  sx={{
                    bgcolor: "#FADFDB",
                    "&:hover": { bgcolor: "#FADFDB", color: Colors.primary },
                  }}
                  endIcon={
                    <Stack height="19px" width="19px" bgcolor={Colors.primary} borderRadius="50%" alignItems="center">
                      <Typography color="white" fontSize="12px" fontWeight="500">
                        {registerUsersCount}
                      </Typography>
                    </Stack>
                  }
                  onClick={() => setManageUsersValidation(true)}>
                  {t("usersValidation.button")}
                </Button>
              </Box>
              <Box sx={{ "& .MuiButton-root": { height: 35 } }}>
                <AIOButtonComponent variant="contained" color="secondary" onClick={() => setAddClient(true)} title={t("clients.addButton")} />
              </Box>
            </Stack>
          )}
        </Stack>
      )}
      <Stack
        overflow="auto"
        className={breakpointDownSM ? "scrollable" : undefined}
        spacing={breakpointDownSM ? 2 : 0}
        py={breakpointDownSM ? "20px" : undefined}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" alignSelf={breakpointDownMD ? "center" : undefined} width={"100%"}>
          <Stack
            direction={breakpointDownSM ? "column" : "row"}
            alignItems="center"
            justifyContent={breakpointDownMD ? "center" : breakpointDownSM ? "flex-start" : "flex-end"}
            spacing={breakpointDownSM ? 0 : breakpointDownLG ? 1 : 3}
            width="100%">
            {!breakpointDownMD && (
              <Box sx={{ marginRight: "auto" }}>
                <AIORoutesTabComponent variant="standard" routes={ClientTabRoutes.routes} />
              </Box>
            )}
            {sessionQuery.role === UserRoleEnum.ADMIN && (
              <>
                <Box width={breakpointDownSM ? "90%" : undefined} ml={"5px !important"}>
                  <AIOSearchComponent
                    placeholder={t("clients.searchPlaceholder")}
                    width={breakpointDownSM ? "100%" : breakpointDownMD ? "300px" : breakpointDownLG ? "260px" : "400px"}
                    onChange={(value) => entitiesFiltersService.setFilters({ search: value })}
                  />
                </Box>

                <Stack direction="row" flexShrink={0} justifyContent={breakpointDownSM ? "center" : "flex-end"} flexWrap="wrap">
                  {getEntityType(currentRoute) !== EntityTypeEnum.CLIENT && (
                    <AIOSelectComponent
                      items={EntityFavoritesFilterEnumFunctions.selectItems}
                      handleChange={(value) => entitiesFiltersService.setFilters({ favorites: value })}
                      value={filters.favorites ?? EntityFavoritesFilterEnum.ALL}
                    />
                  )}
                  <AIOSelectComponent
                    items={EntitiesSortFieldEnumFunctions.selectItems}
                    handleChange={(value) => entitiesFiltersService.setSort({ field: value })}
                    value={sort.field}
                  />
                </Stack>
              </>
            )}
          </Stack>
        </Stack>
        {breakpointDownMD && (
          <Box alignSelf={"center"} mt={"32px"}>
            <AIORoutesTabComponent variant="standard" routes={ClientTabRoutes.routes} />
          </Box>
        )}
        <Outlet />
      </Stack>
      {addClient && <EntityModal isCreation handleClose={() => setAddClient(false)} type={EntityTypeEnum.CLIENT} />}
      {manageUsersValidation && (
        <UsersWaitingValidationModal
          handleClose={() => {
            setManageUsersValidation(false);
            registerUsersService.getRegisterUsersCount().subscribe({
              next: setRegisterUsersCount,
              error: (err) => enqueueSnackbar(err.text, err.options),
            });
          }}
        />
      )}
    </Stack>
  );
};

export default Entities;
