import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { BannerModel, BannersFilters } from "./banners.model";

interface BannersStoreProps {
  filters: BannersFilters;
}

export const bannersStore = createStore(
  { name: "banners" },
  withProps<BannersStoreProps>({
    filters: {},
  }),
  withEntities<BannerModel>(),
  withRequestsStatus()
);
