import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import { finalize } from "rxjs";

import { UserRoleEnum, usersQuery, usersService } from "@store/users";
import { sessionQuery } from "@store/session";
import { ManageSubscriptionParameters, subscriptionsQuery } from "@store/subscriptions";

import { Colors } from "@constants/colors.constant";

import ModalComponent from "@components/modal/Modal.component";
import UserCard from "@components/card/User.card";
import AIOButtonComponent from "@components/Button.component";
import OrderPreviewModal from "@components/modal/OrderPreview.modal";

interface ChooseProSubscriptionModalProps {
  handleClose: () => void;
  isYearly: boolean;
  selectedSubscription: ManageSubscriptionParameters;
}

const ChooseProSubscriptionModal = (props: ChooseProSubscriptionModalProps) => {
  const { handleClose, isYearly, selectedSubscription } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [usersLoading, setUsersLoading] = useState(false);

  const [users] = useObservable(usersQuery.usersWithoutSubscription$);
  const [selectedUsers, setSelectedUsers] = useState<string[]>(
    !!sessionQuery.user && !subscriptionsQuery.sessionUserSubscriptionOngoing ? [sessionQuery.user.id] : []
  );

  const [showOrderPreview, setShowOrderPreview] = useState(false);

  useEffect(() => {
    const affiliateIds = sessionQuery.user?.affiliates?.map((a) => a.affiliate.id) ?? [];
    setUsersLoading(true);
    usersService
      .getUsers({ affiliateIds, roles: [UserRoleEnum.CLIENT] })
      .pipe(finalize(() => setUsersLoading(false)))
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }, [enqueueSnackbar]);

  if (showOrderPreview) {
    return (
      <OrderPreviewModal
        selectedSubscription={selectedSubscription}
        handleClose={() => setShowOrderPreview(false)}
        isYearly={isYearly}
        userIds={selectedUsers}
      />
    );
  }

  return (
    <ModalComponent
      maxWidth="lg"
      title={t("subscriptions.selectUsers.professionalOffer")}
      handleClose={handleClose}
      titleLeft
      content={
        <Stack direction="column" spacing={1} overflow="auto" height="60vh" mb={1} py="10px" width="100%">
          <Stack alignItems="flex-end" spacing={1}>
            <Stack direction="row" alignSelf="flex-end" alignItems="center" spacing={1}>
              <Typography fontSize={14} fontWeight="300">
                {t("subscriptions.selectUsers.usersIncluded")}
              </Typography>
              <Typography fontSize="16px" fontWeight="500" color={Colors.primary}>
                {(selectedSubscription.amount ?? 0) - selectedUsers.length}/{selectedSubscription.amount ?? 0}
              </Typography>
            </Stack>
          </Stack>
          <Box overflow="auto" className="scrollable" py="20px" px="5px">
            <Grid container direction="row" alignItems="stretch" spacing={3}>
              {users.map((user) => {
                const selected = selectedUsers.includes(user.id);

                return (
                  <Grid key={user.id} item xs={12} sm={6} md={4}>
                    <UserCard
                      user={user}
                      selected={selected}
                      handleClick={() => {
                        if ((selectedSubscription.amount ?? 0) <= selectedUsers.length && !selected) return;

                        setSelectedUsers((state) => (selected ? state.filter((uId) => uId !== user.id) : state.concat(user.id)));
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Stack>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          title={t("global.validate")}
          onClick={() => setShowOrderPreview(true)}
          disabled={usersLoading || !selectedUsers.length || selectedUsers.length < (selectedSubscription.amount ?? 0)}
        />
      }
    />
  );
};

export default ChooseProSubscriptionModal;
