import { catchError, from, map, Observable, switchMap, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";

import {
  BoostBoostAction,
  BoostDecision,
  BoostDetailsDetailsModel,
  BoostJobBoard,
  BoostSchool,
  BoostSequence
} from "@store/boosts/details/boostDetails.model";
import { boostDetailsStore } from "@store/boosts/details/boostDetails.store";
import { getBoostDetailsDetailsDataSource } from "@store/boosts/details/boostDetails.requests";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { BoostStatus } from "@store/boosts";

export class BoostDetailsService {
  store = boostDetailsStore;

  resetStore = () => this.store.reset();

  getBoostDetails = (boostId: string | number): Observable<BoostDetailsDetailsModel> => {
    return from(APIAxios(APIRoutes.GETBoostDetails(boostId)))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError((err.response as any)?.data?.message, "error");
        }),
        map((response: AxiosResponse<BoostDetailsDetailsModel>) => {
          return response.data;
        }),
        tap((details) => {
          this.store.update(
            (state) => ({
              ...state,
              details
            }),
            getBoostDetailsDetailsDataSource.setSuccess()
          );
        }),
        getBoostDetailsDetailsDataSource.trackRequestStatus()
      );
  };

  updateBoostPublications = (boostId: string | number, jobBoards: BoostJobBoard[], sequences: BoostSequence[], boostActions: BoostBoostAction[], schools: BoostSchool[]): Observable<BoostDetailsDetailsModel> => {
    return from(APIAxios({
      ...APIRoutes.PUTBoost(boostId),
      data: {
        jobBoards: jobBoards.map((j) => ({ jobBoardId: j.jobBoard.id, isPublished: j.isPublished })),
        sequences: sequences.map((s) => ({ sequenceId: s.sequence.id, isPublished: s.isPublished })),
        boostActions: boostActions.map((s) => ({ boostActionId: s.boostAction.id, isPublished: s.isPublished })),
        schools: schools.map((s) => ({ schoolId: s.school.id, isPublished: s.isPublished }))
      }
    }))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError((err.response as any)?.data?.message, "error");
        }),
        switchMap(() => this.getBoostDetails(boostId))
      );
  };

  manageBoostRequest = (boostId: string | number, decision: BoostDecision, reason?: string): Observable<BoostDetailsDetailsModel> => {
    return from(APIAxios({
      ...APIRoutes.PUTBoost(boostId),
      data: {
        state: decision,
        rejectReason: decision === BoostStatus.REJECTED ? reason ?? undefined : undefined
      }
    }))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError((err.response as any)?.data?.message, "error");
        }),
        switchMap(() => this.getBoostDetails(boostId))
      );
  };

  // finishBoostRequest = (boostId: string | number): Observable<BoostDetailsDetails> => {
  //   return from(APIAxios(APIRoutes.POSTFinishBoostRequest(boostId)))
  //     .pipe(
  //       catchError((err: AxiosError) => {
  //         throw new SnackError((err.response as any)?.data?.message, "error");
  //       }),
  //       switchMap(() => this.getBoostDetails(boostId))
  //     );
  // };
  //
}

export const boostDetailsService = new BoostDetailsService();
