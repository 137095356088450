import { alpha, CardActionArea, Stack, styled, Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Personae, personaeBackgroundColors } from "@store/personae/personae.model";
import { PersonaeQuizCategory } from "@store/personae";

const AdCardStyled = styled(Stack)(
  (props) => `
  background-color: ${Colors.white};
  border-radius: 15px;
  flex: 1;
  height: 100%;
  opacity: 1;
  width: 100%;
  & > *:first-of-type {
    align-items: flex-start;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: ${props["aria-selected"] ? `inset 0px 0px 0px 1px ${Colors.primary},` : ""} 0px 0px 20px rgba(34,34,64, 0.05);  
    height: 100%;
    padding: 22px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
  };
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    };
  };
`
);

interface PersonaeCardProps {
  handleClick?: () => void;
  personae: Personae;
  selected?: boolean;
}

const PersonaeCard = (props: PersonaeCardProps) => {
  const { handleClick, personae, selected } = props;

  const job = personae.list?.[PersonaeQuizCategory.fieldKey(PersonaeQuizCategory.JOB_CATEGORY)]?.[0]?.name;

  return (
    <AdCardStyled aria-selected={selected}>
      <Stack component={!handleClick ? Stack : CardActionArea} onClick={handleClick}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%" pb="16px">
          <Stack alignSelf="flex-start">
            <Stack height="15px" width="15px" alignItems="center" justifyContent="center">
              <img
                alt={personae.isFinished ? "Completed" : "Not completed"}
                src={personae.isFinished ? "/images/persona_completed.svg" : "/images/persona_not_completed.svg"} />
            </Stack>
          </Stack>
          <Stack
            height="77px"
            width="77px"
            bgcolor={personaeBackgroundColors[personae.backgroundNumber]}
            borderRadius="50px"
            alignItems="center"
            justifyContent="center">
            <img alt={"avatar"} src={`/images/personaeAvatars/personae${personae.pictureNumber}.svg`}
                 style={{ height: "50px", width: "50px" }} />
          </Stack>
          <Stack alignSelf="flex-start" sx={{ opacity: 0 }}>
            <MoreVertIcon />
          </Stack>
        </Stack>
        <Stack textAlign="center" alignItems="center" width="100%">
          <Typography noWrap fontSize={16} fontWeight={700}>
            {personae.name}
          </Typography>
          {job && (
            <Typography noWrap fontSize={14} fontWeight={500} color={Colors.greyLight}>
              {job}
            </Typography>
          )}
          <Typography noWrap pt="5px" fontSize={14} fontWeight={300} color={Colors.greyLight}>
            {personae.affiliate?.name ?? "-"}
          </Typography>
        </Stack>
      </Stack>
    </AdCardStyled>
  );
};

export default PersonaeCard;
