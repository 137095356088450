import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";

import { Colors } from "@constants/colors.constant";

import { usersQuery } from "@store/users";
import { subscriptionsQuery, UserSubscription } from "@store/subscriptions";

import CardBackground from "@components/cardBackground/CardBackground.component";
import ProSubscriptionDetailsModal
  from "@screens/auth/admin/entities/components/subscription/modal/ProSubscriptionDetails.modal";

const ProSubscriptionsCard = () => {
  const {t} = useTranslation();

  const [{ users }] = useObservable(usersQuery.users$);

  const [seeAll, setSeeAll] = useState(false);

  const userSubscriptions = Object.values(users.reduce((subscriptions, user) => {
    if (!subscriptionsQuery.subscriptionOngoing(user)) return subscriptions;

    subscriptions[user.subscription!.users.map((us) => us.id).join(',')] = user.subscription!;
    return subscriptions;
  }, {} as {[key: string]: UserSubscription}));

  return (
    <>
      <CardBackground img="/images/background_campaign.svg" height="100%">
        <Stack p="16px" pb="20px" spacing="15px">
          <Stack direction="row" alignItems="center" spacing="10px" justifyContent="space-between">
            <Stack direction="row" spacing="5px" alignItems="center">
              <img height="14px" src="/images/subscription/icon_subscription_professional.svg" alt="professional" />
              <Typography color={Colors.subscriptions.professional} fontSize="16px" fontWeight={500}>
                {t("subscriptions.details.professional.name")}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing="5px">
              <img height="14px" src={!userSubscriptions.length ? '/images/persona_not_completed.svg' : '/images/persona_completed.svg'} alt="" />
              <Typography fontSize="18px" fontWeight="500" color={Colors.white}>
                {userSubscriptions.length}
              </Typography>
              <Typography fontSize="11px" fontWeight="300" color={Colors.subscriptions.currentOffers} pt="4px">
                {t("subscriptions.currentSubscriptionNbr", {count: userSubscriptions.length})}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing="10px">
            {!!userSubscriptions.length && (
              <Box minWidth="100px">
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setSeeAll(true)}
                  sx={{bgcolor: Colors.subscriptions.seeAll, borderRadius: "15px", height: '35px'}}>
                  {t("global.seeAll")}
                </Button>
              </Box>
            )}
          </Stack>
        </Stack>
      </CardBackground>
      {seeAll && (
        <ProSubscriptionDetailsModal
          handleClose={() => setSeeAll(false)}
          userSubscriptions={userSubscriptions}/>
      )}
    </>
  );
};

export default ProSubscriptionsCard;
