import AIOSearchComponent from "@components/input/AIOSearch.component";
import AIOSelectComponent, { SelectItem } from "@components/input/Select.component";
import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";
import { useTranslation } from "react-i18next";
import BoostCard from "@components/card/Boost.card";
import { useSnackbar } from "notistack";
import {
  boostsQuery,
  boostsService,
  BoostStatus,
  BoostType,
  searchBoostsEffect,
  yearsSelectItems
} from "@store/boosts";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ClientsFilterComponent from "@components/input/ClientsFilterComponent";
import { sessionQuery } from "@store/session";
import { useNavigate } from "react-router-dom";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import { InfiniteScrollContainer, VirtualScrollItem } from "@utils/infinitescroll.utils";
import BackToTopButton from "@components/BackToTopButton.component";
import { emitOnce } from "@ngneat/elf";
import { UserRoleEnum } from "@store/users";
import { Colors } from "@constants/colors.constant";

const Boosts = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const searchBoost = useEffectFn(searchBoostsEffect);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;
  const scrollableBoostsRef = useRef<HTMLDivElement | null>(null);
  const scrollableMobileBoostsRef = useRef<HTMLDivElement | null>(null);

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [affiliateIds] = useObservable(sessionQuery.affiliateIds$);
  const [filters] = useObservable(boostsQuery.filters$);

  const [affiliates] = useState<SelectItem[]>(sessionQuery.affiliatesItems);
  const [buyerMode] = useObservable(sessionQuery.buyerMode$);

  const [{ boosts, error: boostError, loading: boostLoading }] = useObservable(boostsQuery.boosts$);
  const [boostsPaginationData] = useObservable(boostsQuery.boostsPaginationData$);

  useEffect(() => {
    emitOnce(() => {
      boostsService.resetStore();
      if (clientViewClient) {
        boostsService.setFilters({ affiliateIds: [{ value: clientViewClient.id, label: "" }] });
      }
    });
  }, [clientViewClient]);

  useEffect(() => {
    if (boostError) enqueueSnackbar((boostError as any).text, (boostError as any).options);
  }, [boostError, enqueueSnackbar]);

  useEffect(() => {
    searchBoost({ filters, buyerMode, page: currentPage, take: itemsPerPage });
  }, [affiliateIds, buyerMode, filters, searchBoost, itemsPerPage, currentPage]);

  useEffect(() => {
    emitOnce(() => {
      boostsService.deleteEntities();
      boostsService.deleteAllPages();
    });
    setCurrentPage(1);
  }, [filters, buyerMode, affiliateIds]);

  const handleNextPage = useCallback(() => {
    if (boostsPaginationData.lastPage >= boostsPaginationData.currentPage) {
      setCurrentPage(boostsPaginationData.currentPage + 1);
    }
  }, [boostsPaginationData.currentPage, boostsPaginationData.lastPage]);

  const backToTop = () => {
    if (scrollableBoostsRef.current)
      scrollableBoostsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    if (scrollableMobileBoostsRef.current)
      scrollableMobileBoostsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : "60px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 0 : 4}
      flex={1}
      width="100%"
      overflow="auto">
      <Stack
        direction={breakpointDownSM ? "column" : "row"}
        alignItems={breakpointDownSM ? "flex-start" : "center"}
        spacing={2}
        justifyContent="space-between">
        <Stack alignItems="flex-start" justifyContent="space-between" width={breakpointDownSM ? "100%" : undefined}>
          <Typography fontSize={breakpointDownSM ? "30px" : "35px"} fontWeight={700}>
            {t(`boost.${sessionQuery.role === UserRoleEnum.ADMIN || sessionQuery.buyerMode ? "adminTitle" : "title"}`)}
          </Typography>
          {(sessionQuery.role !== UserRoleEnum.ADMIN) && (
            <Stack marginTop={"10px"}>
            <Typography fontSize="14px" fontWeight="500" color={Colors.secondaryText}>
              {t("boost.subtitle1")}
            </Typography>
            <Typography fontSize="14px" fontWeight="500" color={Colors.secondaryText}>
              {t("boost.subtitle2")}
            </Typography>
            </Stack>
          )}
        </Stack>
        {!breakpointDownSM && (sessionQuery.role === UserRoleEnum.ADMIN || affiliates.length > 1) && (
          <Stack spacing={2} direction="row" alignItems="center">
            <Box width="250px">
              {sessionQuery.role !== UserRoleEnum.ADMIN ? (
                <MultiSelectWithSearchComponent
                  handleChange={(values) => boostsService.setFilters({ affiliateIds: values })}
                  startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                  multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
                  placeholder={t("global.filterByCompanies")}
                  items={affiliates}
                  values={filters.affiliateIds ?? []}
                />
              ) : (
                <ClientsFilterComponent values={affiliateIds} />
              )}
            </Box>
          </Stack>
        )}
      </Stack>
      <Stack
        overflow="auto"
        className={breakpointDownSM ? "scrollable" : undefined}
        spacing={breakpointDownSM ? 2 : 0}
        py={breakpointDownSM ? "20px" : undefined}
        ref={scrollableMobileBoostsRef}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          {!sessionQuery.buyerMode && (
            <Stack
              direction={breakpointDownMD ? "column" : "row"}
              width="100%"
              alignItems="center"
              spacing={breakpointDownMD ? 0 : 2}
              justifyContent={breakpointDownMD ? "flex-start" : "flex-end"}>
              <Stack spacing={1.5} width="100%" alignItems="flex-end">
                {breakpointDownSM && (sessionQuery.role === UserRoleEnum.ADMIN || affiliates.length > 1) && (
                  <Box width="100%">
                    {sessionQuery.role !== UserRoleEnum.ADMIN ? (
                      <MultiSelectWithSearchComponent
                        handleChange={(values) => boostsService.setFilters({ affiliateIds: values })}
                        startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                        multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
                        placeholder={t("global.filterByCompanies")}
                        items={affiliates}
                        values={filters.affiliateIds ?? []}
                      />
                    ) : (
                      <ClientsFilterComponent values={affiliateIds} />
                    )}
                  </Box>
                )}
                <AIOSearchComponent
                  placeholder={t("boost.searchPlaceholder")}
                  fullWidth
                  width={breakpointDownMD ? "100%" : undefined}
                  onChange={(value) => boostsService.setFilters({ search: value })}
                />
              </Stack>
              <Stack direction="row" flexWrap={breakpointDownMD ? "wrap" : undefined} justifyContent={breakpointDownMD ? "center" : "flex-end"}>
                <AIOSelectComponent
                  placeholder={t("boost.filterPerStatus")}
                  items={BoostStatus.selectStatusItems}
                  handleChange={(value) => boostsService.setFilters({ status: value })}
                  value={filters.status}
                />
                <AIOSelectComponent
                  placeholder={t("boost.filterPerType")}
                  items={BoostType.selectTypesItems}
                  handleChange={(value) => boostsService.setFilters({ type: value })}
                  value={filters.type}
                />
                <AIOSelectComponent
                  items={yearsSelectItems}
                  handleChange={(value) => boostsService.setFilters({ year: value })}
                  value={filters.year}
                />
              </Stack>
            </Stack>
          )}
        </Stack>
        <Stack
          spacing={2}
          overflow={breakpointDownSM ? undefined : "auto"}
          className={breakpointDownSM ? undefined : "scrollable"}
          py={breakpointDownSM ? "0px" : "30px"}
          px="10px"
          ref={scrollableBoostsRef}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Typography fontSize="16px" fontWeight={700}>
              {boostLoading ? "-" : ""}
              {!boostLoading && t(`boost.amount`, { count: boostsPaginationData.total ?? 0 })}
            </Typography>
            {boostLoading && <CircularProgress size={20} />}
          </Stack>
          <Box>
            <Grid container spacing={3}>
              <InfiniteScrollContainer
                nextPageHandler={handleNextPage}
                itemsPerPage={itemsPerPage}
                componentType="Grid"
                listItems={boosts.map((boost) => (
                  <VirtualScrollItem
                    key={boost.id}
                    height={300}
                    children={
                      <BoostCard
                        boost={boost}
                        handleClick={sessionQuery.role === UserRoleEnum.ADMIN || sessionQuery.buyerMode ? () => navigate(`${boost.id}`) : undefined} />
                    } />
                ))}/>
            </Grid>
          </Box>
          <Stack direction="row" width="100%" justifyContent="center" mt="20px" height={"80px"} alignItems="center">
            {boostLoading && <CircularProgress size={30} />}
          </Stack>
          <BackToTopButton onClick={backToTop} posRight={breakpointDownSM ? "25px" : "60px"} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Boosts;
