import { alpha, Box, Button, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { CompanyDetailsDetailsModel, companyDetailsService } from "@store/ai-o/companies/details";
import { Colors } from "@constants/colors.constant";
import AddTestimonial from "./AddTestimonial.modal";
import { useSnackbar } from "notistack";

interface TestimonialBlockProps {
  company: CompanyDetailsDetailsModel;
}

const TestimonialBlock = (props: TestimonialBlockProps) => {
  const { company } = props;
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  const [modifyId, setModifyId] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setModifyId(null);
    setOpenEdit(false);
  };

  const handleDelete = (id: string) => {
    companyDetailsService.deleteTestimonialCompany(company.id, id).subscribe({
      next: () => {
        enqueueSnackbar(t("aio.clients.details.testimonial.deleteSuccess"), { variant: "success" });
        handleClose();
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("aio.clients.details.testimonial.title")}
          </Typography>
          <Box sx={{ "& > .MuiButton-root": { padding: "5px 10px" } }}>
            <Button variant="text" color="primary" onClick={() => setOpenEdit(true)}>
              {t("global.add")}
            </Button>
          </Box>
        </Stack>
        <Stack spacing="15px">
          {company.testimonials?.map((testimonial, index) => (
            <Stack
              key={index}
              p="15px"
              borderRadius="10px"
              spacing="8px"
              sx={{
                backgroundColor: alpha(Colors.primary, 0.1),
                position: "relative",
              }}
              fontStyle={"italic"}>
              <Stack position="relative" flexDirection={"row"} alignItems="center" justifyContent="space-between">
                <Typography fontSize={14} fontWeight="600" color={Colors.primary}>
                  {testimonial.title}
                </Typography>
              </Stack>
              <Typography fontSize={14} fontWeight="400" color={Colors.secondaryText} fontStyle={"italic"}>
                "{testimonial.content}"
              </Typography>
              <Typography fontSize={14} fontWeight="600" color={Colors.secondaryText} fontStyle={"italic"}>
                {testimonial.name}: {testimonial.job}
              </Typography>
              <Stack sx={{ position: "absolute", right: "12px", top: "5px", flexDirection: "row" }} gap={1}>
                <Stack
                  onClick={() => {
                    setModifyId(testimonial.id);
                    setOpenEdit(true);
                  }}
                  sx={{ cursor: "pointer" }}>
                  <img alt="modify" src={`/images/icon_edit_fill_primary.svg`} height={"15px"} />
                </Stack>
                <Stack onClick={() => handleDelete(testimonial.id)} sx={{ cursor: "pointer" }}>
                  <img alt="delete" src={`/images/icon_delete.svg`} height={"15px"} />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      {openEdit && <AddTestimonial company={company} modifyId={modifyId ?? undefined} handleClose={handleClose} />}
    </Stack>
  );
};

export default TestimonialBlock;
