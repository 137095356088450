import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";
import { BoostFilters } from "./boosts.model";
import { boostsService } from "@store/boosts/boosts.service";

interface SearchBoostsParams {
  buyerMode?: boolean;
  filters?: BoostFilters;
  page?: number;
  take?: number;
}

export const searchBoostsEffect = createEffectFn((params$: Observable<SearchBoostsParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ buyerMode, filters, page, take }) =>
      buyerMode ? boostsService.getBuyerBoosts(filters, page, take) : boostsService.getBoosts(filters, page, take)
    )
  );
});
