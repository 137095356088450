import React from "react";
import AdminPromptsComponent from "./components/AdminPrompts.component";
import { Stack } from "@mui/material";

const AdminPromptsTab = () => {
  return (
    <Stack p="0 20px" overflow="auto">
      <AdminPromptsComponent />
    </Stack>
  );
};

export default AdminPromptsTab;
