import React, { useRef, useState } from "react";
import dayjs from "dayjs";
import ModalComponent from "@components/modal/Modal.component";
import { IconButton, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import { useTranslation } from "react-i18next";
import { NewReport, reportsService } from "@store/reports";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import DatePickerComponent from "@components/input/DatePicker.component";
import { useParams } from "react-router-dom";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";
import { Colors } from "@constants/colors.constant";

interface AddReportModalProps {
  handleClose: () => void;
}

const AddReportModal = (props: AddReportModalProps) => {
  const { handleClose } = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { entityId } = useParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const [loading, setLoading] = useState(false);
  const [newReport, setNewReport] = useState<Partial<NewReport>>({
    file: undefined,
    name: "",
    affiliateId: entityId,
    from: dayjs().subtract(1, "month").toDate(),
    to: new Date()
  });

  const updateNewReport = (name: string) => (value: any) => setNewReport((state) => ({ ...state, [name]: value }));

  const errors = {
    name: getFieldError(newReport.name, FieldValidationType.REQUIRED_STRING),
    file: getFieldError(newReport.file, FieldValidationType.REQUIRED_FILE)
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!!files?.length) {
      setNewReport((state) => ({
        ...state,
        file: files[0]
      }));
      e.target.value = "";
    }
  };

  const handleSend = () => {
    setLoading(true);
    reportsService
      .addReport(newReport as NewReport)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => handleClose(),
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
  };

  return (
    <ModalComponent
      title={t("reports.addReport")}
      handleClose={handleClose}
      titleLeft
      maxWidth={breakpointDownSM ? "xs" : false}
      fullWidth={breakpointDownSM}
      paddingButton="10px 0px 40px 0px"
      content={(
        <Stack spacing={4} py="10px" alignItems="center" width={breakpointDownSM ? undefined : "300px"}>
          <Stack alignSelf="stretch">
            <AIOTextfieldComponent
              required
              title={t("reports.name")}
              placeholder={t("reports.name")}
              onChange={updateNewReport("name")}
              value={newReport.name}
              error={errors.name}
              fullWidth
            />
          </Stack>

          <Stack width="100%" spacing={2}>
            <DatePickerComponent
              title={t("ads.export.from")}
              placeholder={t("global.select")}
              color="secondary"
              handleChange={(from) =>
                setNewReport((state) => ({
                  ...state,
                  to: state.to && dayjs(state.to).isBefore(from) ? from : state.to,
                  from: from
                }))
              }
              value={newReport.from} />
            <DatePickerComponent
              title={t("ads.export.to")}
              placeholder={t("global.select")}
              color="secondary"
              handleChange={(to) =>
                setNewReport((state) => ({
                  ...state,
                  from: state.from && dayjs(state.from).isAfter(to) ? to : state.from,
                  to
                }))
              }
              value={newReport.to} />
          </Stack>
          <Stack spacing={2} alignItems="center">
            <Stack alignItems="center" spacing={0}>
              <input ref={inputRef} hidden onChange={handleSelectFile} accept="application/pdf" type="file" />
              <Stack alignItems="center" spacing={2}>
                <IconButton aria-label="avatar-rounded" onClick={() => inputRef.current?.click()}>
                  <img alt="import file" src="/images/download_report.svg" style={{ height: 25, width: 25 }} />
                </IconButton>
                <Typography color={errors.file ? "primary" : Colors.greyLight}>
                  {errors.file || newReport.file?.name}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      )}
      actions={(
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          onClick={handleSend}
          title={t("dashboard.contact.send")}
          disabled={!entityId || checkIfErrors(errors) || loading} />
      )} />
  );
};

export default AddReportModal;
