import { select } from "@ngneat/elf";

import { getRegisterUsersDataSource } from "@store/registerUsers/registerUsers.requests";
import { registerUsersStore } from "@store/registerUsers/registerUsers.store";

export class RegisterUsersQuery {
  store = registerUsersStore;

  registerUsers$ = getRegisterUsersDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));

  get filters() {
    return this.store.getValue().filters;
  }
}

export const registerUsersQuery = new RegisterUsersQuery();
