import React, { useState } from "react";
import { Avatar, Box, Button, Divider, Grid, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { DashboardStat, globalStatisticsQuery } from "@store/globalStatistics";
import { Colors } from "@constants/colors.constant";
import dayjs from "dayjs";
import { useObservable } from "@ngneat/react-rxjs";
import { useTranslation } from "react-i18next";
import BoostsListModal from "@screens/auth/common/dashboard/modals/BoostsList.modal";
import DashboardCard from "@components/card/Dashboard.card";
import { Action } from "@store/common/actions.model";
import { Pages } from "@utils/routes.utils";
import { useNavigate } from "react-router-dom";
import BoostCard from "@components/card/Boost.card";

const ContainerLastActions = styled(Stack)`
  background-color: ${Colors.background};
  border-radius: 15px;
  padding: 24px 30px 0px;
`;

const AdminDashboardComponent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const breakpointDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const [openNewRequestsBoost, setOpenNewRequestsBoost] = useState<boolean>(false);

  const [{ adminStats, loading }] = useObservable(globalStatisticsQuery.adminStatistics$);

  return (
    <Stack
      direction={breakpointDownMD ? "column" : "row"}
      alignItems={breakpointDownMD ? "center" : "flex-start"}
      spacing={breakpointDownLG ? 3 : 5.625}
      flex={1}
      py={breakpointDownMD ? "20px" : "30px"}
      overflow="auto"
      className="scrollable"
      width="100%">
      <Stack direction="column" flex={breakpointDownMD ? undefined : 1} spacing={breakpointDownLG ? 3 : 6.25} width="100%">
        <Box>
          <Grid container spacing={breakpointDownLG ? 1 : 3}>
            <Grid item xs={12} lg={4}>
              <DashboardCard loading={loading} stat={DashboardStat.ONLINE_ADS} disableClick value={adminStats.announcementNbr} />
            </Grid>
            <Grid item xs={6} lg={4}>
              <DashboardCard loading={loading} disableClick stat={DashboardStat.CLIENTS} value={adminStats.affiliateNbr} />
            </Grid>
            <Grid item xs={6} lg={4}>
              <DashboardCard
                loading={loading}
                stat={DashboardStat.BOOSTS}
                disableClick={!(adminStats.boosts?.length ?? 0)}
                value={(adminStats.boosts ?? []).length}
              />
            </Grid>
          </Grid>
        </Box>

        <Stack direction="column" spacing={2}>
          {!!adminStats.newBoost?.length && (
            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={3}>
                <Typography fontSize={20} fontWeight={700} lineHeight={1.25}>
                  {t("dashboard.stats.newRequestsBoost", { count: (adminStats.newBoost ?? []).length })}
                </Typography>
                {(adminStats.newBoost ?? []).length > (breakpointDownLG ? 2 : 3) && (
                  <Box sx={{ "& .MuiButton-root": { whiteSpace: "nowrap" } }}>
                    <Button onClick={() => setOpenNewRequestsBoost(true)}>{t("dashboard.seeAll")}</Button>
                  </Box>
                )}
              </Stack>
              <Box px="5px">
                <Grid container spacing={3} alignItems="stretch">
                  {(adminStats.newBoost ?? []).slice(0, breakpointDownLG ? 2 : 3).map((boost) => (
                    <Grid key={boost.id} item xs={12} sm={6} lg={4}>
                      <BoostCard boost={boost} withoutProgress handleClick={() => navigate(`../${Pages.BOOST}/${boost.id}`)} />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </>
          )}
        </Stack>
      </Stack>

      <ContainerLastActions width={breakpointDownMD ? "100%" : "25vw"} minHeight={breakpointDownMD ? undefined : "100%"} spacing="7px">
        <Typography fontSize="16px" fontWeight="700">
          {t("dashboard.actions.lastActions", { count: (adminStats.lastActions ?? []).length })}
        </Typography>
        <Stack spacing={2} py="20px">
          {(adminStats.lastActions ?? []).map((item, index) => (
            <React.Fragment key={index + "lastActions"}>
              <Stack>
                <Stack spacing={-0.25}>
                  <Typography fontSize="14px" fontWeight="300" color={Colors.secondaryText}>
                    {t(dayjs(item.date).isToday() ? `global.todayAndHour` : `global.dateAndHour`, { date: new Date(item.date) })}
                  </Typography>

                  <Typography fontSize="14px" fontWeight={500} lineHeight={1.25} dangerouslySetInnerHTML={{ __html: Action.description(item) }} />
                </Stack>
                <Stack direction="row" alignItems="center" spacing={1} mt="4px">
                  <Avatar style={{ height: 16, width: 16, backgroundColor: Colors.avatarBackground }} src={item.user?.picture} />
                  <Typography fontSize="14px" fontWeight="300" color={Colors.secondaryText} lineHeight={1.25}>
                    {`${item.user?.firstname} ${item.user?.lastname}`}
                    {item.affiliate ? ` - ${item.affiliate.name}` : ""}
                  </Typography>
                </Stack>
              </Stack>
              <Divider />
            </React.Fragment>
          ))}
        </Stack>
      </ContainerLastActions>

      {openNewRequestsBoost && (
        <BoostsListModal
          handleClose={() => setOpenNewRequestsBoost(false)}
          title={t("dashboard.stats.newRequestsBoost", { count: (adminStats.newBoost ?? []).length })}
          stat={DashboardStat.ADMIN_NEW_BOOSTS}
        />
      )}
    </Stack>
  );
};

export default AdminDashboardComponent;
