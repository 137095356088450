import {alpha, createTheme} from "@mui/material";
import palette from "./palette.theme";
import drawer from "./drawer.theme";
import typography from "./typography.theme";
import button from "./button.theme";
import fonts from "@fonts/index";
import {Colors} from "@constants/colors.constant";
import modal from "./modal.theme";
import textfield from "./input.theme";
import formControlLabel from "./formControlLabel.theme";
import checkbox from "./checkbox.theme";
import paper from "./paper.theme";
import chip from "./chip.theme";
import tabs from "./tabs.theme";
import menu from "./menu.theme";
import tooltip from "./tooltip.theme";
import timeline from "./timeline.theme";
import iconButton from "./iconButton.theme";

const theme = createTheme({
  palette,
  typography: {
    fontFamily: "YoutubeSans, sans-serif"
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `${fonts}
      html {
          height: 100%;
          -webkit-font-smoothing: auto;
        }
        body {
          height: 100%;
          scrollbar-color: ${Colors.primary} ${alpha(Colors.primary, 0.1)};
          scrollbar-width: thin;
        }
        .scrollable {
          mask-image: linear-gradient(
            to bottom,
            transparent,
            #f8f8f8 20px,
            #f8f8f8 calc(100% - 20px),
            transparent
          );
        }
        .scrollable-horizontal {
          mask-image: linear-gradient(
            to right,
            transparent,
            white 20px,
            white calc(100% - 20px),
            transparent
          );
        }
				div#root {
					height: 100%;
				}
        *::-webkit-scrollbar {
          height: 8px;
          width: 8px;
        }
        *::-webkit-scrollbar-track {
          background-color: ${alpha(Colors.primary, 0.1)};
          border-radius: 4px;
          margin: 15px;
        }
        *::-webkit-scrollbar-thumb {
          background-color: ${Colors.primary};
          border-radius: 4px;`
    },
    ...drawer,
    ...typography,
    ...button,
    ...modal,
    ...textfield,
    ...formControlLabel,
    ...checkbox,
    ...chip,
    ...paper,
    ...tabs,
    ...menu,
    ...timeline,
    ...tooltip,
    ...iconButton
  }
});

export default theme;
