import { catchError, from, map, Observable, tap } from "rxjs";
import { deleteAllEntities, deleteEntities, upsertEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";

import { getClientsDataSource } from "@store/entities/clients/clients.requests";
import { clientsStore } from "@store/entities/clients/clients.store";
import { ClientModel } from "@store/entities/clients/clients.model";
import { entitiesFiltersQuery, EntitiesSortFieldEnum } from "@store/entities/entitiesFilters";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { SelectItem } from "@components/input/Select.component";
import { PaginatedData } from "@utils/infinitescroll.utils";
import { deleteAllPages, setPage, updatePaginationData } from "@ngneat/elf-pagination";

export class ClientsService {
  store = clientsStore;

  resetStore = () => this.store.reset();
  deleteEntities = () => this.store.update(deleteAllEntities());
  deleteAllPages = () => this.store.update(deleteAllPages());

  getClients = (page?: number, take?: number): Observable<PaginatedData<ClientModel[]>> => {
    const {
      filters: { city, search },
      sort: { field },
    } = entitiesFiltersQuery.params;

    return from(
      APIAxios({
        ...APIRoutes.GETClients(),
        params: {
          search: search || undefined,
          city: city || undefined,
          sort: field,
          page,
          take,
          disablePagination: !page && !take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<ClientModel[]>>) => {
        return response.data;
      }),
      tap((clients) => {
        this.store.update(
          upsertEntities(clients.data),
          updatePaginationData({
            currentPage: clients.meta.page,
            perPage: clients.meta.take,
            total: clients.meta.itemCount,
            lastPage: clients.meta.pageCount,
          }),
          setPage(
            clients.meta.page,
            clients.data.map((client) => client.id)
          ),
          getClientsDataSource.setSuccess()
        );
      }),
      getClientsDataSource.trackRequestStatus()
    );
  };

  searchClients = async (search?: string): Promise<SelectItem[]> => {
    try {
      const response: AxiosResponse<PaginatedData<ClientModel[]>> = await APIAxios({
        ...APIRoutes.GETClients(),
        params: {
          search: search ?? undefined,
          take: 20,
          page: 1,
          sort: EntitiesSortFieldEnum.NAME,
        },
      });
      return response.data.data.map((e) => ({ label: e.name, value: e.id, data: e }));
    } catch (err: any) {
      return [];
    }
  };

  deleteClient = (clientId: string): Observable<AxiosResponse> => {
    return from(APIAxios(APIRoutes.DELETEClient(clientId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AxiosResponse>) => {
        return response.data;
      }),
      tap(() => this.store.update(deleteEntities(clientId)))
    );
  };
}

export const clientsService = new ClientsService();
