import { createStore, withProps } from "@ngneat/elf";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { ClientDetailsModel } from "@store/entities/clients/details/index";

export const clientDetailsStore = createStore(
  {name: "client-details"},
  withProps<ClientDetailsModel>({}),
  withRequestsStatus(),
);
