import { Colors } from "@constants/colors.constant";
import styled from "@emotion/styled";
import { alpha, Grid, IconButton, Typography } from "@mui/material";

const ImageGridItem = styled(Grid)(
  (props) => `
  position: relative;
  &:hover {
    & .MuiIconButton-root {
      opacity: 1 !important;
    };
  };
`
);

interface PreviewImageProps {
  handleRemoveFile: (file: File) => void;
  img: { id: string; src: string; file: File };
  label?: string;
  icon?: React.ReactNode;
}

const PreviewImage = (props: PreviewImageProps) => {
  const { handleRemoveFile, img, label, icon } = props;

  return (
    <ImageGridItem item xs={3}>
      {icon && (
        <div
          style={{
            position: "absolute",
            top: "26px",
            left: "25px",
          }}>
          {icon}
        </div>
      )}
      <IconButton
        aria-label="close"
        onClick={() => handleRemoveFile(img.file)}
        style={{
          position: "absolute",
          top: "25px",
          right: "5px",
          borderRadius: "50%",
          border: "solid 1px " + Colors.primary,
          backgroundColor: "RGB(229, 229, 232)",
          height: "25px",
          width: "25px",
          opacity: 0,
        }}>
        <img alt="close" src="/images/icon_close.svg" style={{ height: "8px", width: "8px" }} />
      </IconButton>
      {label && (
        <Typography
          fontSize={10}
          style={{
            maxWidth: "80px",
            marginLeft: "20px",
            position: "absolute",
            bottom: "-12px",
            left: 0,
            right: 0,
            textAlign: "center",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}>
          {label}
        </Typography>
      )}
      <img
        style={{
          width: "100%",
          aspectRatio: "1/1",
          objectFit: "cover",
          borderRadius: "15px",
          border: "solid 1px " + Colors.primary,
          boxShadow: `0 0px 10px 0px ${alpha("#03053D", 0.1)}`,
        }}
        key={img.id}
        src={
          img.src.includes("data:video")
            ? "/images/icon_play_round.svg"
            : img.file.type === "application/pdf"
            ? "/images/pdf-thumbnail.png"
            : URL.createObjectURL(img.file)
        }
        alt="preview"
      />
    </ImageGridItem>
  );
};

export default PreviewImage;
