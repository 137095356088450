import I18nUtils from "@utils/i18n.utils";

export enum BudgetLimitationPeriodEnum {
  MONTH = "MONTH",
  CUSTOM = "CUSTOM",
}

export namespace BudgetLimitationPeriodEnumFunctions {
  export const selectItems = [
    { label: I18nUtils.t("clients.details.parametersTab.budgetLimitation.month"), value: BudgetLimitationPeriodEnum.MONTH },
    { label: I18nUtils.t("clients.details.parametersTab.budgetLimitation.custom"), value: BudgetLimitationPeriodEnum.CUSTOM },
  ];
}

export interface ManageBudgetLimitationModel extends Partial<BudgetLimitationModel> {
  duration?: BudgetLimitationPeriodEnum;
}

export interface BudgetLimitationModel {
  from: Date;
  to: Date;
  amount: number;
}