import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";

import { Template, templatesQuery, templatesService, TemplateTypeEnumFunctions } from "@store/ai-o/templates";

import PromptsCard from "@components/card/Prompts.card";
import CvTemplateModal from "@components/aio-prompts/modals/CvTemplate.modal";

const CvTemplatesComponent = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [{templates}] = useObservable(templatesQuery.template$);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | undefined>();

  useEffect(() => {
    templatesService.getTemplates().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  const handleClose = () => {
    setIsModalOpen(false);
    setSelectedTemplate(undefined);
  }

  return (
    <>
      <PromptsCard title={t("aio.templates.title")}>
        <Box m="15px 0 42px">
          <Grid container spacing="20px">
            {templates.map((template) => (
              <Grid item key={template.id}>
                <Stack position="relative" width={"170px"} height={"220px"}>
                  <img
                    src={TemplateTypeEnumFunctions.images[template.model]}
                    alt={template.title}
                    style={{
                      borderRadius: "10px",
                      boxShadow: "0px 0px 20px rgba(0,0,0, 0.12)",
                    }}
                  />
                  <Box position="absolute" right="10px" top="10px">
                    <IconButton
                      onClick={() => {
                        setIsModalOpen(true);
                        setSelectedTemplate(template);
                      }}
                      size="small">
                      <img alt="edit" src="/images/edit.svg" style={{height: '18px'}} />
                    </IconButton>
                  </Box>
                  <Typography mt="11px" alignSelf="center" fontWeight={500} fontSize="14px">
                    {template.title}
                  </Typography>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Box>
      </PromptsCard>
      {isModalOpen && !!selectedTemplate && (
        <CvTemplateModal
          template={selectedTemplate}
          handleClose={handleClose} />
      )}
    </>
  );
}

export default CvTemplatesComponent