import { catchError, from, map, Observable, switchMap, tap } from "rxjs";
import { addEntities, deleteAllEntities, getEntitiesIds, UIEntitiesRef, upsertEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";
import { deleteAllPages, setPage, updatePaginationData } from "@ngneat/elf-pagination";

import { PaginatedData } from "@utils/infinitescroll.utils";
import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { sessionQuery } from "@store/session";
import { adsStore } from "@store/ads/ads.store";
import { AdModel, AdsFilters, AdsSort, AdStats } from "@store/ads/ads.model";
import { getAdsDataSource, getAdsStatsDataSource, getPaginatedAdsDataSource } from "@store/ads/ads.requests";
import { SelectItem } from "@components/input/Select.component";
import { Candidate, candidatesService } from "@store/ai-o/candidates";

export class AdsService {
  store = adsStore;

  resetStore = () => this.store.reset();
  deleteEntities = () => this.store.update(deleteAllEntities());
  deleteAllPages = () => this.store.update(deleteAllPages());

  setFilters = (filters: Partial<AdsFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));
  setSort = (sort: Partial<AdsSort>) =>
    this.store.update((state) => ({
      ...state,
      sort: {
        ...state.sort,
        ...sort,
      },
    }));

  getAds = (filters?: AdsFilters, sort?: AdsSort, page: number = 1, take: number = 24): Observable<PaginatedData<AdModel[]>> => {
    const affiliateIds = filters?.affiliateIds
      ? filters.affiliateIds.map((c) => c.value)
      : sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined;

    return from(
      APIAxios({
        ...APIRoutes.GETAds(),
        params: {
          search: filters?.search || undefined,
          aioCandidateJobName: filters?.aioCandidateJobName || undefined,
          aioCandidateJobLocation: filters?.aioCandidateJobLocation || undefined,
          aioCandidateJobReference: filters?.aioCandidateJobReference || undefined,
          affiliateIds,
          favorite: sessionQuery.affiliateIds?.some((c) => c.value === "FAVORITES") || undefined,
          sort: sort?.field,
          order: sort?.field ? "ASC" : undefined,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<AdModel[]>>) => {
        return response.data;
      }),
      tap((ads) => {
        this.store.update(
          upsertEntities(ads.data),
          updatePaginationData({
            currentPage: ads.meta.page,
            perPage: ads.meta.take,
            total: ads.meta.itemCount,
            lastPage: ads.meta.pageCount,
          }),
          setPage(
            ads.meta.page,
            ads.data.map((ad) => ad.id)
          ),
          upsertEntities(
            ads.data
              .filter((ad) => !this.store.query(getEntitiesIds()).includes(ad.id))
              .map((ad) => ({
                ...ad,
                selected: false,
              })),
            { ref: UIEntitiesRef }
          ),
          getAdsDataSource.setSuccess()
        );
      }),
      getAdsDataSource.trackRequestStatus()
    );
  };

  getAdsWithStats = (filters?: AdsFilters, sort?: AdsSort, page: number = 1, take: number = 24): Observable<PaginatedData<AdModel[]>> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdsWithStats(),
        params: {
          search: filters?.search || undefined,
          affiliateIds: filters?.affiliateIds?.map((c) => c.value),
          sort: sort?.field,
          order: sort?.field ? "ASC" : undefined,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<AdModel[]>>) => {
        return response.data;
      }),
      tap((ads) => {
        this.store.update(
          addEntities(ads.data.filter((ad) => !this.store.query(getEntitiesIds()).includes(ad.id))),
          updatePaginationData({
            currentPage: ads.meta.page,
            perPage: ads.meta.take,
            total: ads.meta.itemCount,
            lastPage: ads.meta.pageCount,
          }),
          setPage(
            ads.meta.page,
            ads.data.map((ad) => ad.id)
          ),
          getPaginatedAdsDataSource.setSuccess()
        );
      }),
      getPaginatedAdsDataSource.trackRequestStatus()
    );
  };

  getAdsWithStatsNoStore = (filters?: AdsFilters, sort?: AdsSort, page: number = 1, take: number = 24): Observable<PaginatedData<AdModel[]>> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdsWithStats(),
        params: {
          search: filters?.search || undefined,
          affiliateIds: filters?.affiliateIds?.map((c) => c.value),
          sort: sort?.field,
          order: sort?.field ? "ASC" : undefined,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<AdModel[]>>) => {
        return response.data;
      })
    );
  };

  getAdsReportsCSV = (filters?: AdsFilters): Observable<Blob> => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdsReportsCSV(),
        params: {
          search: filters?.search || undefined,
          affiliateIds: filters?.affiliateIds?.map((c) => c.value),
        },
        responseType: "blob",
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      })
    );
  };

  getAdsStats = (filters?: AdsFilters): Observable<AdStats> => {
    const affiliateIds = filters?.affiliateIds
      ? filters.affiliateIds.map((c) => c.value)
      : sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined;

    return from(
      APIAxios({
        ...APIRoutes.GETAdsStats(),
        params: {
          affiliateIds,
          favorite: sessionQuery.affiliateIds?.some((c) => c.value === "FAVORITES") || undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdStats>) => {
        return response.data;
      }),
      tap((stats) => {
        this.store.update(
          (state) => ({
            ...state,
            stats,
          }),
          getAdsStatsDataSource.setSuccess()
        );
      }),
      getAdsStatsDataSource.trackRequestStatus()
    );
  };

  updateAdsDiffusionList = (adReferences: string[], jobBoards: SelectItem[], affiliateId: string): Observable<{ modifiedCount: number }> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAdsDiffusion(),
        data: { adReferences, jobBoards: jobBoards.map((j) => j.value), affiliateId },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<{ modifiedCount: number }>) => {
        return response.data;
      })
    );
  };

  createAIOApplication = (announcementId: string, announcementRef: string, candidate: Candidate): Observable<AxiosResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTAIOApplication(),
        data: {
          announcement: announcementId,
          aioId: candidate.id,
          candidate: candidate.candidateInfo ? `${candidate.candidateInfo.firstName} ${candidate.candidateInfo.lastName}` : undefined,
          applicationDate: candidate.creationDate,
          cvKey: candidate.cvKey,
          linkedinProfileURL: candidate.linkedin,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => candidatesService.reattachAnnouncement(candidate.id, announcementId, announcementRef))
    );
  };
}

export const adsService = new AdsService();
