import styled from "@emotion/styled";
import { alpha, Stack, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Colors } from "@constants/colors.constant";

const CustomSwitchLabel = styled(Typography)((props: { active: "true" | "false" }) => ({
  fontWeight: props.active === "true" ? "500" : "300",
}));

const DiscountTag = styled(Typography)({
  backgroundColor: alpha(Colors.purple, 0.1),
  padding: "5px 7px",
  borderRadius: "10px",
  color: Colors.purple,
  position: "absolute",
  right: 8,
  top: -37,
});

const StyledSwitch = styled(Switch)({
  filter: `drop-shadow(0px 0px 20px ${Colors.lightMarine})`,
  "& .MuiSwitch-thumb": {
    color: Colors.purple,
  },
  "& .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb": {
    color: Colors.purple,
  },
  "& .MuiSwitch-track": {
    backgroundColor: "white",
    border: '1px solid rgba(0, 0, 0, 0.1)',
  },
  "& .MuiButtonBase-root.Mui-checked+.MuiSwitch-track": {
    backgroundColor: "white !important",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: Colors.primary,
  },
});

const DrawnArrow = styled("img")({
  position: "absolute",
  right: -19,
  top: -22,
});

interface SubscriptionPeriodSwitchProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
}

const SubscriptionPeriodSwitch = (props: SubscriptionPeriodSwitchProps) => {
  const { checked, onChange } = props;

  const { t } = useTranslation();

  return (
    <Stack direction="row" alignItems="center" spacing="10px" position="relative" mt="60px">
      <DiscountTag fontSize="12px" fontWeight={500}>
        {t("subscriptions.discount")}
      </DiscountTag>
      <DrawnArrow src="/images/subscription/arrow_purple.svg" alt="drawn arrow"/>
      <CustomSwitchLabel active={!checked ? "true" : "false"}>{t("subscriptions.monthly")}</CustomSwitchLabel>
      <StyledSwitch checked={checked} onChange={(evt, checked) => onChange(checked)} />
      <CustomSwitchLabel active={checked ? "true" : "false"}>{t("subscriptions.yearly")}</CustomSwitchLabel>
    </Stack>
  );
};

export default SubscriptionPeriodSwitch;