import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import ModalComponent from "@components/modal/Modal.component";
import { Box, Grid } from "@mui/material";
import { CompanyDetailsDetailsModel, companyDetailsService } from "@store/ai-o/companies/details";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";

interface AddLightInfoProps {
  company: CompanyDetailsDetailsModel;
  modifyId?: string;
  handleClose: () => void;
}

const AddLightInfo = (props: AddLightInfoProps) => {
  const { company, handleClose, modifyId } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState<string>(modifyId ? company.lightInfos?.find((t) => t.id === modifyId)?.info ?? "" : "");
  const [description, setDescription] = useState<string>(modifyId ? company.lightInfos?.find((t) => t.id === modifyId)?.description ?? "" : "");

  const handleSave = () => {
    setLoading(true);
    companyDetailsService
      .addLightInfoCompany(company.id, info, description)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.lightInfos.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleModify = () => {
    setLoading(true);
    if (!modifyId) return;
    companyDetailsService
      .patchLightInfoCompany(company.id, modifyId, info, description)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.lightInfos.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleCLick = modifyId ? handleModify : handleSave;

  const errors = {
    info: getFieldError(info, FieldValidationType.REQUIRED_STRING),
    description: getFieldError(description, FieldValidationType.REQUIRED_STRING),
  };

  return (
    <ModalComponent
      title={t("aio.clients.details.lightInfos.addTitle")}
      titleLeft
      content={
        <Box p="10px">
          <Grid container columnSpacing="30px" rowSpacing="20px">
            <Grid item xs={6}>
              <AIOTextfieldComponent
                required
                title={t("aio.clients.details.lightInfos.fields.info")}
                placeholder={t("aio.clients.details.lightInfos.fields.infoExample")}
                value={info ?? ""}
                onChange={setInfo}
                pattern={/^[0-9\-\+\(\)]+$/}
                error={errors.info}
              />
            </Grid>
            <Grid item xs={6}>
              <AIOTextfieldComponent
                required
                error={errors.description}
                title={t("aio.clients.details.lightInfos.fields.description")}
                placeholder={t("aio.clients.details.lightInfos.fields.descriptionExample")}
                value={description ?? ""}
                onChange={setDescription}
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          ariaLabel="modal"
          disabled={loading || checkIfErrors(errors)}
          onClick={handleCLick}
          title={t("global.save")}
        />
      }
      handleClose={handleClose}
    />
  );
};

export default AddLightInfo;
