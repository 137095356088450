import React from "react";
import { Stack } from "@mui/material";

import PromptsComponent from "@components/aio-prompts/Prompts.component";
import TonesComponent from "@components/aio-prompts/Tones.component";
import PersonalitiesComponent from "@components/aio-prompts/Personalities.component";
import CvTemplatesComponent from "@components/aio-prompts/CvTemplates.component";

const AioPromptsTab = () => {
  return (
    <Stack overflow="auto" py="20px" className="scrollable">
      <PromptsComponent />
      <TonesComponent />
      <PersonalitiesComponent />
      <CvTemplatesComponent />
    </Stack>
  );
};

export default AioPromptsTab;
