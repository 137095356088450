import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { Report, ReportsFilters } from "@store/reports/reports.model";

interface ReportsStoreProps {
  filters: ReportsFilters;
}

export const reportsStore = createStore(
  { name: "reports" },
  withProps<ReportsStoreProps>({
    filters: {},
  }),
  withEntities<Report>(),
  withRequestsStatus()
);
