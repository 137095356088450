import dayjsUtils from "@utils/dayjs.utils";
import { sessionQuery } from "@store/session";
import { UserModel } from "@store/users";
import { EntitiesModel } from "@store/entities/entities.model";

export class SubscriptionsQuery {
  get sessionUserSubscriptionOngoing() {
    const user = sessionQuery.user;

    if (!user) return false;

    return !!user.subscription && (!user.subscription.endDate || dayjsUtils().isBefore(user.subscription.endDate));
  }

  subscriptionOngoing = (user: UserModel) => {
    return !!user.subscription && (!user.subscription.endDate || dayjsUtils().isBefore(user.subscription.endDate));
  }

  affiliateSubscriptionOnGoing = (affiliate?: EntitiesModel) => {
    if (!affiliate) return false;
    return !!affiliate.subscriptions && affiliate.subscriptions.some(
      (s) => !dayjsUtils().isBefore(s.startDate) && !dayjsUtils().isAfter(s.endDate)
    );
  }

  get nextCreditsRenewDate() {
    const user = sessionQuery.user;

    if (!user || !user.subscription || !this.sessionUserSubscriptionOngoing) {
      return dayjsUtils().add(1, "month").startOf("month").toDate();
    }

    return new Date(user.subscription.currentPeriodEnd);
  }
}

export const subscriptionsQuery = new SubscriptionsQuery();
