import { Button, Stack, Typography } from "@mui/material";
import { BoldTitle, Content } from "../legalStyle";
import AIOLinkInTxt from "@components/AIOLinkInTxt";
import { useResponsive } from "@utils/useResponsive";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery } from "@store/session";
import { RoutesUnAuth } from "@utils/routes.utils";

interface PrivacyPolicyUSProps {
  hideHeader?: boolean;
}

const PrivacyPolicyUS = (props: PrivacyPolicyUSProps) => {
  const {hideHeader} = props;

  const { isMobileOrTablet } = useResponsive();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [{ user }] = useObservable(sessionQuery.isAuthenticated$);
  const isAuthenticated = !!user;

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && containerRef.current.parentElement) containerRef.current.parentElement.scrollTo({ top: 0, left: 0 });
  }, []);

  return (
    <Stack
      px={isMobileOrTablet ? "16px" : "40px"}
      pb={isMobileOrTablet ? "5px" : "60px"}
      height="100%"
      width="100%"
      overflow="auto"
      ref={containerRef}>
      {!hideHeader && (
        <>
          <Stack width={"100%"} direction={"row"} mt={isMobileOrTablet ? "15px" : "40px"}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<img alt="back" src="/images/arrow_back.svg" />}
              onClick={() => (isAuthenticated ? navigate(-1) : navigate(RoutesUnAuth.LOGIN))}>
              <Typography fontWeight="300" color="secondary">
                {t(`global.back`)}
              </Typography>
            </Button>
          </Stack>
          <Stack mt={"14px"} alignSelf={"center"} direction={"row"}>
            <Typography fontWeight={700} fontSize={"30px"}>
              Privacy Policy
            </Typography>
          </Stack>
        </>
      )}
      <Stack mt={"45px"} alignSelf={"center"} spacing={3}>
        <Content>
          In the course of operating the Services available on the ALLInOne Platform (hereinafter the
          {` << Plateform >>`}), TWININ processes personal data.
        </Content>
        <Content>Recruiters and Users are therefore informed of the following.</Content>
        <BoldTitle>Identity of the data controller</BoldTitle>
        <Content>
          TWININ, a simplified joint-stock company, registered in the Paris RCS under number 520 301 557, with its head office located at 229 rue
          Saint-Honoré - 75001 Paris, represented by the company RUDSI INVEST (RCS 813 960 739), itself represented by Mr. Jérémy Duris, as President,
          implements personal data processing in the context of its relationship with Recruiters and Users who use the services of the Platform.
        </Content>
        <Content>
          In this regard, it assumes the role of data controller in accordance with Regulation (EU) 2016/679 of the European Parliament and of the
          Council of 27 April 2016 (GDPR).
        </Content>
        <Stack>
          <Content>Data Protection Officer of TWININ:</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- Mr. Jérémy DURIS</Content>
            <Content>
              - Contact email address: <AIOLinkInTxt label="info@twinin.fr" url={"mailto:info@twinin.fr"} />
            </Content>
          </Stack>
        </Stack>
        <BoldTitle>Collected Data</BoldTitle>
        <Content>The following personal data are processed (hereinafter {`<< Personal Data >>`}):</Content>
        <Stack>
          <Content>Identification data</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- Name, first name</Content>
            <Content>- Email address</Content>
            <Content>- Professional situation</Content>
            <Content>- Identity photo</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Usage Data </Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- Internet Protocol address (for example, the IP address) of the device used</Content>
            <Content>- Type and version of the browser</Content>
            <Content>- Pages de la Plateforme visitées</Content>
            <Content>- Time and date of the visit and time spent on these pages</Content>
            <Content>- Cookies collecting personal data</Content>
          </Stack>
        </Stack>
        <Content> Personal Data relates to the relevant collaborators of the Recruiter.</Content>
        <Content>
          In application of the data minimization principle or {`<< PrivacyPolicy by default >>`}, TWININ ensures to collect only the data strictly
          necessary with regard to the purpose for which they are processed.
        </Content>
        <BoldTitle>Purposes of processing and legal basis</BoldTitle>
        <Content>These Data are processed for the following purposes and have the following legal basis:</Content>
        <Stack>
          <Content>Purpose(s)</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>Legal Basis</Content>
            <Stack>
              <Content>- Registration for Services and conclusion of contracts</Content>
              <Content>- Access, execution, and management of the Services offered on the Platform</Content>
              <Content>- Billing and payment of Services</Content>
              <Content>- Relations with Recruiters/Users</Content>
            </Stack>
            <Content>Execution of the contract binding the Recruiter to TWININ (general conditions)</Content>
            <Stack>
              <Content>- Management of requests, claims, and potential disputes (pre-litigation and litigation)</Content>
            </Stack>
            <Content>Execution of the contract binding the Recruiter to TWININ (general conditions) and compliance with legal obligations</Content>
            <Stack>
              <Content>- Monitoring and managing the relationship, namely conducting optional satisfaction surveys on TWININ services</Content>
              <Content>- Performing commercial statistics and usage of TWININ services</Content>
              <Content>- Prospecting and commercial solicitation actions</Content>
              <Content>- Sending newsletters</Content>
            </Stack>
            <Content>TWININ's legitimate interest</Content>
            <Stack>
              <Content>Purposes related to cookie placement, namely:</Content>
              <Stack spacing={1} pl={"20px"}>
                <Content>- Personalizing and improving the Recruiter/User experience on the Platform (personalization cookies)</Content>
                <Content>- Producing statistics and audience measurements (statistical cookies)</Content>
                <Content>- Proposing targeted advertisements (advertising cookies)</Content>
              </Stack>
            </Stack>
            <Content>Consent</Content>
          </Stack>
        </Stack>
        <BoldTitle>Recipients</BoldTitle>
        <Stack>
          <Content>Depending on the necessities, the Processed Data are intended for:</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- authorized services of TWININ</Content>
            <Content>- services in charge of control (accountant and auditor in particular)</Content>
            <Content>- authorized personnel of possible subcontractors of TWININ, as needed</Content>
            <Content>- public bodies, when imposed by law</Content>
            <Content>
              - third parties likely to place cookies on the terminals of the Recruiter/Users (computers, tablets, mobile phones...) when they
              consent.
            </Content>
          </Stack>
        </Stack>
        <BoldTitle>No transfer and transfer</BoldTitle>
        <Content>
          The Personal Data collected should not be subject to transfer, rental, or exchange for the benefit of a third country or an international
          organization.
        </Content>
        <Content>
          However, if they were to be transferred, TWININ guarantees the concerned person that any transfer would take place respecting the provisions
          of the GDPR and in particular:
        </Content>
        <Stack spacing={1} pl={"20px"}>
          <Content>
            - that these transfers would be carried out for the benefit of a country or a state whose data protection legislation is deemed
            sufficiently adequate by the European Commission
          </Content>
          <Content>- Alternatively, that the transfers would be accompanied by transfer tools ensuring their compliance with the GDPR</Content>
        </Stack>
        <BoldTitle>Exercise of rights of the persons concerned</BoldTitle>
        <Stack>
          <Content>Right of access to data</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- The concerned person has the right to obtain from TWININ access to his/her Personal Data collected by it</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Right to rectification of data</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>
              - The concerned person has the right to obtain from TWININ, as soon as possible, the rectification of Personal Data concerning him/her
              that is inaccurate. In particular, he/she has the right to have incomplete Personal Data completed.
            </Content>
          </Stack>
        </Stack>
        <Stack>
          <Stack>
            <Content>Right to erasure of data</Content>
            <Stack spacing={1} pl={"20px"}>
              <Content>
                - As part of the exercise of his/her right to be forgotten, the concerned person has the right to request the erasure of Personal Data
                concerning him/her.
              </Content>
              <Stack>
                <Content>TWININ is obliged to erase Personal Data in the following situations:</Content>
                <Stack spacing={1} pl={"20px"}>
                  <Content>- When the Personal Data are no longer necessary in relation to the purposes for which they were collected</Content>
                  <Content>
                    - In the event of withdrawal of the consent of the concerned person on which the processing is based (and when there is no other
                    legal basis for the processing)
                  </Content>
                  <Content>
                    - In case of opposition to the processing of Personal Data by the concerned person (and when there is no compelling legitimate
                    reason for the processing)
                  </Content>
                  <Content>- When the Personal Data have been unlawfully processed</Content>
                  <Content>- When the Personal Data must be erased to comply with a legal obligation</Content>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
          <Stack>
            <Content>Right to restriction of processing</Content>
            <Stack spacing={1} pl={"20px"}>
              <Content>
                The concerned person may, in the following cases, request TWININ to restrict the processing of his/her Personal Data, that is, that
                his/her data be temporarily made inaccessible:
              </Content>
              <Stack spacing={1} pl={"20px"}>
                <Content>- When he/she disputes the accuracy of one or more of his/her Personal Data</Content>
                <Content>
                  - When the processing is unlawful and the concerned person opposes the erasure of the data and requests instead a restriction of
                  their use
                </Content>
                <Content>
                  - When the concerned person needs his/her data for the establishment, exercise, or defense of legal claims and TWININ no longer
                  needs them for processing purposes
                </Content>
                <Content>
                  - When the concerned person has objected to the processing: in this case, the restriction will be effective while determining
                  whether the legitimate grounds pursued by TWININ take precedence over those of the concerned person. TWININ undertakes to inform the
                  concerned person before making his/her data accessible again.
                </Content>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Stack>
          <Content>Right to object to processing</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>The concerned person has the right to object, at any time, to the processing of Personal Data concerning him/her.</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Right to data portability</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>
              - The concerned person may request TWININ (i) to retrieve the Personal Data concerning him/her that he/she has provided or (ii) to
              transmit them to another data controller, to the extent that the processing of these data is carried out using automated processes. In
              case of exercise of this right, the delivery or transmission will be done in a structured, commonly used, and machine-readable format.
            </Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Right to lodge a complaint</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- The concerned person may file a complaint with a supervisory or judicial authority.</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Right to issue directives in the event of death</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>- The concerned person has the right to issue directives regarding the fate of his/her data in the event of death.</Content>
          </Stack>
        </Stack>
        <Stack>
          <Content>Right to withdraw consent</Content>
          <Stack spacing={1} pl={"20px"}>
            <Content>
              - If the processing is based on the consent of the concerned person, he/she may withdraw his/her consent at any time, without affecting
              the lawfulness of processing based on consent before its withdrawal.
            </Content>
          </Stack>
        </Stack>
        <Content>
          To exercise all these rights, the concerned person is required to send an email to TWININ at the following address:{" "}
          <AIOLinkInTxt label="info@twinin.fr" url={"mailto:info@twinin.fr"} />.
        </Content>
        <Content>
          Each request must be accompanied by proof of identity. In case of reasonable doubt about the identity of the requesting person, additional
          information necessary to confirm his/her identity may be requested.
        </Content>
        <Content>
          In case of manifestly unfounded, excessive, or repetitive requests, TWININ reserves the right to refuse to comply with them.
        </Content>
        <BoldTitle>Duration of data retention</BoldTitle>
        <Content>Data relating to Recruiters/Users:</Content>
        <Content>
          Personal Data is kept for the duration of the contractual relationship between the Recruiter and TWININ and for one (1) year thereafter.
        </Content>
        <Content>They are subsequently archived for a period of five (5) years for evidentiary purposes.</Content>
        <Content>Data related to cookie placement:</Content>
        <Content>
          Cookies are stored on the Recruiter/User's terminal for a maximum period of thirteen (13) months, subject to his/her choices. For more
          information on this subject, the Recruiter or User is invited to read the cookie management policy.
        </Content>
        <BoldTitle>Data Security</BoldTitle>
        <Content>
          TWININ ensures the security of Personal Data by implementing appropriate organizational and technical measures to guarantee a level of
          security appropriate to the risk for the rights and freedoms of the concerned persons.
        </Content>
        <Content>
          Please note that legal terms and privacy policy language can be very specific and sensitive to context. It is always recommended to have
          such translations reviewed by a legal professional, especially when used for official purposes.
        </Content>
      </Stack>
    </Stack>
  );
};

export default PrivacyPolicyUS;
