import { catchError, from, map, Observable, tap } from "rxjs";
import { setEntities, updateEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";

import SnackError from "@utils/error.utils";
import { AIPromptModel, ManageAIPromptModel } from "./aiPrompts.model";
import { aiPromptsStore } from "./aiPrompts.store";
import { getAIPromptsDataSource } from "./aiPrompts.requests";
import APIAxios, { APIRoutes } from "@api/axios.api";

export class AIPromptsService {
  store = aiPromptsStore;

  getPrompts = (): Observable<AIPromptModel[]> => {
    return from(APIAxios(APIRoutes.GETAIPrompts())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AIPromptModel[]>) => {
        return response.data;
      }),
      tap((prompts) => {
        this.store.update(setEntities(prompts), getAIPromptsDataSource.setSuccess());
      }),
      getAIPromptsDataSource.trackRequestStatus()
    );
  };

  updatePrompt = (promptId: string, prompt: ManageAIPromptModel): Observable<AIPromptModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTAIPrompt(promptId),
        data: { systemContent: prompt.systemContent, model: prompt.model, userContent: prompt.userContent },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AIPromptModel>) => {
        return response.data;
      }),
      tap((prompt) => {
        this.store.update(updateEntities(promptId, prompt));
      })
    );
  };
}

export const aiPromptsService = new AIPromptsService();
