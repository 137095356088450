import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Action } from "@store/common/actions.model";

import { Colors } from "@constants/colors.constant";
import dayjsUtils from "@utils/dayjs.utils";

interface HistoryCardProps {
  action: Action;
}

const HistoryCard = (props: HistoryCardProps) => {
  const { action } = props;

  const {t} = useTranslation();

  return (
    <Box flex={1} p="12px 0px 14px 21px" sx={{ border: 1, borderRadius: 4, borderColor: "rgb(239, 239, 239)", backgroundColor: "white" }}>
      <Stack direction="row" justifyContent="space-between" pr="20px">
        <Stack>
          <Stack spacing={-0.25}>
            <Typography fontSize="14px" fontWeight="300" color={Colors.secondaryText}>
              {t(dayjsUtils(action.date).isToday() ? `global.todayAndHour` : `global.dateAndHour`, { date: new Date(action.date) })}
            </Typography>
            <Typography fontSize="14px" fontWeight={500} lineHeight={1.25} dangerouslySetInnerHTML={{ __html: Action.description(action) }} />
          </Stack>
          {action.user && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <Avatar style={{ height: 16, width: 16, backgroundColor: Colors.avatarBackground }} src={action.user?.picture} />
              <Typography fontSize="14px" fontWeight="300" color={Colors.secondaryText}>
                {`${action.user?.firstname} ${action.user?.lastname}`}
                {action.affiliate ? ` - ${action.affiliate.name}` : ""}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default HistoryCard;