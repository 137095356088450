import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/material";
import { useState } from "react";

import { ToolboxColumn } from "@store/toolbox";
import { affiliateDetailsService } from "@store/entities/afiliates/details";
import { EntitiesModel } from "@store/entities/entities.model";

import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";

interface ToolRequestModalProps {
  entity: EntitiesModel;
  handleClose: () => void;
  toolboxColumns: ToolboxColumn[];
}

const AddToolboxColumnModal = (props: ToolRequestModalProps) => {
  const { entity, handleClose, toolboxColumns } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const columnItems = toolboxColumns
    .filter((t) => !entity.toolboxColumns?.find((c) => c.name === t.name))
    .map((c) => ({ label: c.name, value: c.id, data: c }));

  const [columnSelected, setColumnSelected] = useState<ToolboxColumn | undefined>();

  const handleConfirm = () => {
    if (!columnSelected) return;
    affiliateDetailsService.addToolboxColumn(entity.id, columnSelected.name).subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
    handleClose();
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("toolbox.addColumn")}
      titleLeft
      content={(
        <Stack mt="20px">
          <SelectWithSearchComponent
            placeholder={t("toolbox.theme")}
            items={columnItems}
            handleChange={(v) => setColumnSelected(v?.data)}
            value={columnSelected ? { label: columnSelected.name, value: columnSelected.id, data: columnSelected } : undefined} />
        </Stack>
      )}
      maxWidth="xs"
      paddingButton="10px 0px 40px 0px"
      actions={(
        <AIOButtonComponent
          title={t("global.confirm")}
          variant="contained"
          ariaLabel="modal"
          onClick={handleConfirm} />
      )} />
  );
};

export default AddToolboxColumnModal;
