import React, { Dispatch, SetStateAction, useState } from "react";
import { alpha, Box, IconButton, Stack, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ManageEntityParametersModel } from "@store/entities/details";
import { ManageGoogleAnalyticsModel } from "@store/common/googleAnalytics.model";

import { Colors } from "@constants/colors.constant";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import GoogleAnalyticsAddUrlModal from "@screens/auth/admin/entities/parameters/GoogleAnalyticsAddUrl.modal";

interface GoogleAnalyticsConfigComponentProps {
  errors: { [key: string]: any };
  googleAnalytics: ManageGoogleAnalyticsModel;
  isActivated?: boolean;
  updateParameters: Dispatch<SetStateAction<ManageEntityParametersModel>>;
}

const GoogleAnalyticsConfigComponent = (props: GoogleAnalyticsConfigComponentProps) => {
  const { errors, googleAnalytics, isActivated = false, updateParameters } = props;

  const { t } = useTranslation();

  const [openAddFilter, setOpenAddFilter] = useState<number | false>(false);

  const updateGoogleAnalytics = (name: string) => (value: any) => {
    updateParameters((state) => ({
      ...state,
      googleAnalytics: {
        ...state.googleAnalytics,
        [name]: value,
      },
    }))
  };

  return (
    <Stack flex={0.5} width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
        <Typography fontSize={16} fontWeight="700">
          {t("clients.details.parametersTab.googleAnalytics.analytics")}
        </Typography>
        <Switch
          checked={isActivated}
          disabled={isActivated}
          onChange={(evt, checked) =>
            updateParameters((state) => ({...state, googleAnalyticsActivated: checked}))
          }/>
      </Stack>
      {isActivated && (
        <Stack spacing={3}>
          <AIOTextfieldComponent
            title={t("clients.details.parametersTab.googleAnalytics.propertyId")}
            placeholder={t("clients.details.parametersTab.googleAnalytics.propertyId")}
            onChange={updateGoogleAnalytics("propertyId")}
            value={googleAnalytics.propertyId ?? ""}
            color="secondary"
            error={errors.googleAnalytics.propertyId} />
          <Stack spacing="10px">
            <Typography fontSize="14px" fontWeight="700">
              {t("clients.details.parametersTab.googleAnalytics.companyNamesTitle")}
            </Typography>
            <Stack spacing="20px">
              {googleAnalytics.companyNames?.map((c, index) => (
                <Stack key={`company name ${c.companyName}`} bgcolor={Colors.white} borderRadius="10px" px="20px" py="15px">
                  <Typography fontSize="14px" fontWeight="700">
                    {t("clients.details.parametersTab.googleAnalytics.searchByAffiliate", {companyName: c.companyName?.toString()})}
                  </Typography>
                  <Stack mt="10px" spacing="10px">
                    <AIOTextfieldComponent
                      title={t("clients.details.parametersTab.googleAnalytics.companyNameFilter")}
                      placeholder={t("clients.details.parametersTab.googleAnalytics.companyNameFilter")}
                      onChange={(companyNameFilter) => {
                        updateParameters((state) => ({
                          ...state,
                          googleAnalytics: {
                            ...state.googleAnalytics,
                            companyNames: (state.googleAnalytics?.companyNames || []).map((c, companyNameIndex) =>
                              companyNameIndex !== index ? c : {...c, companyNameFilter}
                            ),
                          },
                        }));
                      }}
                      value={c.companyNameFilter ?? ""} />
                    <Stack>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography fontSize={14} fontWeight="700">
                          {t("clients.details.parametersTab.googleAnalytics.searchByAd")}
                        </Typography>
                        <Box mr="-15px" sx={{"& > .MuiButton-root": { height: '30px' }}}>
                          <AIOButtonComponent
                            color="primary"
                            variant="text"
                            title={t("clients.details.parametersTab.googleAnalytics.addSearchByAdd")}
                            onClick={() => setOpenAddFilter(index)}/>
                        </Box>
                      </Stack>
                      <Stack spacing="10px">
                        {c.adsFilters?.map((filter, filterIndex) => (
                          <Stack key={filterIndex} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} width="calc(100% + 10px)%" ml="-10px !important">
                            <IconButton
                              onClick={() => {
                                updateParameters((state) => ({
                                  ...state,
                                  googleAnalytics: {
                                    ...state.googleAnalytics,
                                    companyNames: (state.googleAnalytics?.companyNames || []).map((c, companyNameIndex) =>
                                      companyNameIndex !== index ? c : {...c, adsFilters: (c.adsFilters || []).filter((_, i) => i !== filterIndex)}
                                    ),
                                  },
                                }));
                              }}>
                              <img src="/images/icon_remove.svg" alt="" />
                            </IconButton>
                            <Box flex={1} bgcolor={alpha(Colors.primary, 0.05)} borderRadius="10px" px="15px" py="8px">
                              <Typography fontSize="14px" fontWeight="300" sx={{wordBreak: 'break-word'}}>
                                {t("clients.details.parametersTab.googleAnalytics.url")} : {filter}
                              </Typography>
                            </Box>
                          </Stack>
                        ))}
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      )}
      {openAddFilter !== false && (
        <GoogleAnalyticsAddUrlModal
          handleAdd={(url) => {
            updateParameters((state) => ({
              ...state,
              googleAnalytics: {
                ...state.googleAnalytics,
                companyNames: (state.googleAnalytics?.companyNames || []).map((c, companyNameIndex) =>
                  companyNameIndex !== openAddFilter ? c : {...c, adsFilters: (c.adsFilters || []).concat(url)}
                ),
              },
            }));
            setOpenAddFilter(false);
          }}
          handleClose={() => setOpenAddFilter(false)}/>
      )}
    </Stack>
  );
};

export default GoogleAnalyticsConfigComponent;
