import { useEffect, useState, useRef } from "react";

const ImageWithRetryFeature = ({
  src,
  alt,
  interval = 300,
  maxRetries = 5,
  style,
  fallbackImageSrc,
  retryIntervalMultiplier = 1.5,
}: {
  src: string;
  alt: string;
  interval?: number;
  maxRetries?: number;
  style?: React.CSSProperties;
  fallbackImageSrc?: string;
  retryIntervalMultiplier?: number;
}) => {
  const getSrcWithCacheId = (srcURL: string) => {
    return `${srcURL}?${new Date().getTime()}`;
  };

  const [imageSrc, setImageSrc] = useState(getSrcWithCacheId(src));
  const [retryCount, setRetryCount] = useState(0);
  const [retryInterval, setRetryInterval] = useState(interval);
  const retryCountRef = useRef(retryCount);
  const retryIntervalRef = useRef(retryInterval);
  const loadImageTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    retryCountRef.current = retryCount;
  }, [retryCount]);

  useEffect(() => {
    retryIntervalRef.current = retryInterval;
  }, [retryInterval]);

  useEffect(() => {
    const loadImage = () => {
      const img = new Image();

      img.onload = () => setImageSrc(getSrcWithCacheId(src));
      img.onerror = () => {
        if (retryCountRef.current < maxRetries) {
          console.log("error loading image with retry number : ", retryCountRef.current);
          setRetryCount((prevRetryCount) => prevRetryCount + 1);
          setRetryInterval((prevRetryInterval) => prevRetryInterval * retryIntervalMultiplier);
          loadImageTimeoutRef.current = setTimeout(loadImage, retryIntervalRef.current);
        } else {
          console.log("fallback : ");
          if (fallbackImageSrc) setImageSrc(fallbackImageSrc);
          else setImageSrc(getSrcWithCacheId(src));
        }
      };

      img.src = getSrcWithCacheId(src);
    };

    loadImage();

    return () => {
      if (loadImageTimeoutRef.current) {
        clearTimeout(loadImageTimeoutRef.current);
      }
    };
  }, [src, maxRetries, retryIntervalMultiplier, fallbackImageSrc]);

  return <img style={style} src={imageSrc} alt={alt} />;
};

export default ImageWithRetryFeature;
