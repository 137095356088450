import { Box, SxProps, Theme } from '@mui/material'

interface AIOLinkInTxtProps {
  url: string
  label: string
  sx?: SxProps<Theme>
}

const AIOLinkInTxt = ({ label, url, sx }: AIOLinkInTxtProps) => {

  const handleOnClick = () => { window.open(url, "_blank") }

  return (
    <Box
      component={'span'}
      sx={{
        ...sx,
        textDecoration: "underline",
        margin: '0 5px 0 5px',
        '&:hover': {
          cursor: "pointer",
        }

      }}
      onClick={handleOnClick}>
      {label}
    </Box>
  )
}

export default AIOLinkInTxt