import { SelectItem } from "@components/input/Select.component";
import { PotentialProfile } from "@store/ads/details";
import I18nUtils from "@utils/i18n.utils";
import { Colors } from "@constants/colors.constant";

export enum UnipileInvitationStatusEnum {
  LIMIT_REACHED = "LIMIT_REACHED",
  ERROR = "ERROR",
  PENDING_SEND = "PENDING_SEND",
  PENDING_ACCEPTATION = "PENDING_ACCEPTATION",
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
}

export namespace UnipileInvitationStatusEnumFunctions {
  export const selectItems: SelectItem[] = [
    {
      label: I18nUtils.t(`ads.details.potentialProfilesTab.invitationStatus.${UnipileInvitationStatusEnum.LIMIT_REACHED}`),
      value: UnipileInvitationStatusEnum.LIMIT_REACHED,
    },
    {
      label: I18nUtils.t(`ads.details.potentialProfilesTab.invitationStatus.${UnipileInvitationStatusEnum.ERROR}`),
      value: UnipileInvitationStatusEnum.ERROR,
    },
    {
      label: I18nUtils.t(`ads.details.potentialProfilesTab.invitationStatus.${UnipileInvitationStatusEnum.PENDING_SEND}`),
      value: UnipileInvitationStatusEnum.PENDING_SEND,
    },
    {
      label: I18nUtils.t(`ads.details.potentialProfilesTab.invitationStatus.${UnipileInvitationStatusEnum.PENDING_ACCEPTATION}`),
      value: UnipileInvitationStatusEnum.PENDING_ACCEPTATION,
    },
    {
      label: I18nUtils.t(`ads.details.potentialProfilesTab.invitationStatus.${UnipileInvitationStatusEnum.ACCEPTED}`),
      value: UnipileInvitationStatusEnum.ACCEPTED,
    },
    {
      label: I18nUtils.t(`ads.details.potentialProfilesTab.invitationStatus.${UnipileInvitationStatusEnum.DECLINED}`),
      value: UnipileInvitationStatusEnum.DECLINED,
    },
  ];
  export const color = (type: UnipileInvitationStatusEnum) => Colors.unipileInvitationStatus[type];
}

export interface UnipileInvitationFiltersModel {
  search?: string;
  status?: SelectItem[];
}

export interface UnipileInvitationModel extends PotentialProfile {
  id: string;
  status: UnipileInvitationStatusEnum;
}
