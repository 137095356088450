import { catchError, from, map, Observable, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";
import { deleteEntities, setEntities } from "@ngneat/elf-entities";

import {
  manageRegistertUserToFormData,
  ManageRegisterUserModel,
  RegisterUserModel,
  RegisterUsersFilters
} from "./registerUsers.model";
import { registerUsersStore } from "./registerUsers.store";
import { getRegisterUsersDataSource } from "./registerUsers.requests";

import SnackError from "@utils/error.utils";
import APIAxios, { APIRoutes } from "@api/axios.api";

class RegisterUsersService {
  store = registerUsersStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<RegisterUsersFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getRegisterUsers = (filters?: RegisterUsersFilters): Observable<RegisterUserModel[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETRegisterUsers(),
        params: {
          search: filters?.search || undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<RegisterUserModel[]>) => {
        return response.data;
      }),
      tap((registerUsers) => {
        this.store.update(setEntities(registerUsers), getRegisterUsersDataSource.setSuccess());
      }),
      getRegisterUsersDataSource.trackRequestStatus()
    );
  };

  getRegisterUsersCount = (): Observable<number> => {
    return from(APIAxios(APIRoutes.GETRegisterUsersCount())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<number>) => {
        return response.data;
      }),
    );
  };

  createRegisterUser = (requestUser: ManageRegisterUserModel): Observable<RegisterUserModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTCreateRegisterUser(),
        data: manageRegistertUserToFormData(requestUser),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<RegisterUserModel>) => {
        return response.data;
      })
    );
  };

  acceptRegisterUser = (registerUserId: string, clientId: string, affiliatesIds: string[]) => {
    return from(
      APIAxios({
        ...APIRoutes.DELETEAcceptRegisterUser(registerUserId),
        data: { client: clientId, affiliates: affiliatesIds },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      tap(() => this.store.update(deleteEntities(registerUserId))),
    )
  }

  rejectRegisterUser = (registerUserId: string) => {
    return from(
      APIAxios(APIRoutes.DELETERejectRegisterUser(registerUserId))
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      tap(() => this.store.update(deleteEntities(registerUserId))),
    )
  }
}

export const registerUsersService = new RegisterUsersService();
