import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";

import { ApplicationsFilters } from "./applications.model";
import { applicationsService } from "./applications.service";

interface SearchApplicationsParams {
  filters?: ApplicationsFilters;
  page?: number;
  take?: number;
}

export const searchApplicationsEffect = createEffectFn((params$: Observable<SearchApplicationsParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ filters, page, take }) => applicationsService.getApplications(filters, page, take))
  );
});
