import { Colors } from "@constants/colors.constant";
import { Stack, Typography } from "@mui/material";

interface UnderlinedTitleProps {
  title: string;
  color: string;
  textColor?: string;
  fontSizePx: number;
  fontHeight: number;
}

const UnderlinedTitle = (props: UnderlinedTitleProps) => {
  const { title, color, fontSizePx, fontHeight, textColor = Colors.black } = props;
  return (
    <Stack
      position={"relative"}
      sx={{
        width: "fit-content",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
      <Stack
        sx={{
          backgroundColor: color,
          height: fontSizePx * 0.5 + "px",
          width: title.length * (fontSizePx * 0.3) + "px",
          position: "absolute",
          bottom: fontSizePx * 0.1 + "px",
          right: -fontSizePx * 0.3 + "px",
          borderRadius: "5px",
        }}
      />
      <Typography fontSize={fontSizePx + "px"} fontWeight={fontHeight} zIndex={1000} color={textColor}>
        {title}
      </Typography>
    </Stack>
  );
};

export default UnderlinedTitle;
