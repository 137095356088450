import { Stack } from "@mui/material";
import React from "react";

import { ApplicationDetails } from "@store/applications";

import HistoryCard from "@components/card/History.card";

interface ApplicationHistoryTabProps {
  application: ApplicationDetails;
}

const ApplicationHistoryTab = (props: ApplicationHistoryTabProps) => {
  const {application} = props;

  return (
    <Stack spacing={3} pb="20px">
      {application.lastActions?.map((action, index) => (
        <HistoryCard key={index} action={action} />
      ))}
    </Stack>
  );
};

export default ApplicationHistoryTab;
