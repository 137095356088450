import { alpha, Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CompanyDetailsDetailsModel, companyDetailsService } from "@store/ai-o/companies/details";

import { Colors } from "@constants/colors.constant";

import ConfirmModal from "@components/modal/Confirm.modal";
import { getValueIcon } from "@store/ai-o/companies/companies.model";
import AddCompanyValueModal from "@screens/auth/admin/aio/customerDetails/profileBlocks/AddCompanyValue.modal";

interface CompanyValuesBlockProps {
  company: CompanyDetailsDetailsModel;
}

const CompanyValuesBlock = (props: CompanyValuesBlockProps) => {
  const { company } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openAdd, setOpenAdd] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<string | false>(false);

  const handleDelete = () => {
    if (!confirmDelete) return;
    companyDetailsService.removeValueCompany(company.id, confirmDelete).subscribe({
      next: () => {
        enqueueSnackbar(t("aio.clients.details.companyValues.delete.success"), { variant: "success" });
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("aio.clients.details.companyValues.title")}
          </Typography>
          <Box sx={{ "& > .MuiButton-root": { padding: "5px 10px" } }}>
            <Button variant="text" color="primary" onClick={() => setOpenAdd(true)} disabled={company.values && company.values.length >= 6}>
              {t("global.add")}
            </Button>
          </Box>
        </Stack>
        {company.values ? (
          <Box>
            <Grid container spacing="10px">
              {company.values.map((v, index) => (
                <Grid item key={v.id}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={1}
                    sx={{ borderRadius: "15px", bgcolor: alpha(Colors.primary, 0.1), color: "white", p: "5px 10px" }}
                    justifyContent="space-between">
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {getValueIcon(v.icon)}
                      <Typography fontSize={14} color={Colors.primary}>
                        {v.name}
                      </Typography>
                      {v.description ? (
                        <Typography fontSize={12} color={Colors.secondaryText}>
                          {v.description}
                        </Typography>
                      ) : null}
                    </Stack>
                    <IconButton size="small" onClick={() => setConfirmDelete(v.id)}>
                      <img alt="delete company value" src="/images/icon_close_primary.svg" />
                    </IconButton>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : null}
      </Stack>
      {openAdd && <AddCompanyValueModal handleClose={() => setOpenAdd(false)} companyId={company.id} />}
      {!!confirmDelete && (
        <ConfirmModal
          handleConfirm={handleDelete}
          confirmMsg={t("aio.clients.details.companyValues.delete.description")}
          modalTitle={t("aio.clients.details.companyValues.delete.title")}
          handleClose={() => setConfirmDelete(false)}
        />
      )}
    </Stack>
  );
};

export default CompanyValuesBlock;
