import React from "react";
import { Colors } from "@constants/colors.constant";
import { alpha, Avatar, Box, Card, CardActionArea, Divider, LinearProgress, Stack, styled, Tooltip, Typography } from "@mui/material";
import { BoostModel, BoostStatus, BoostType } from "@store/boosts";
import { useTranslation } from "react-i18next";
import dayjsUtils from "@utils/dayjs.utils";

const BoostCardStyled = styled(Card)`
  background-color: ${Colors.white};
  border-radius: 15px;
  box-shadow: 0px 0px 20px rgba(34, 34, 64, 0.05);
  flex: 1;
  height: 100%;
  opacity: 1;
  width: 100%;
  & > *:first-of-type {
    align-items: flex-start;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(34, 34, 64, 0.05);
    height: 100%;
    justify-content: flex-start;
    padding-bottom: 22px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
  }
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    }
  }
`;

interface BoostCardProps {
  boost: BoostModel;
  handleClick?: () => void;
  withoutProgress?: boolean;
}

const BoostCard = (props: BoostCardProps) => {
  const { boost, handleClick, withoutProgress } = props;

  const { t } = useTranslation();

  let progress = (0 / 3) * 100;
  if (boost.state === BoostStatus.IN_PROGRESS) progress = (1 / 3) * 100;
  else if (boost.state === BoostStatus.VALIDATED) progress = (2 / 3) * 100;
  else if (boost.state === BoostStatus.FINISHED) progress = (3 / 3) * 100;

  return (
    <BoostCardStyled>
      <Stack component={!handleClick ? Stack : CardActionArea} onClick={handleClick}>
        {boost.state === BoostStatus.REJECTED || withoutProgress || boost.isNew ? (
          <Box height="6px" sx={{ flexShrink: 0 }} />
        ) : (
          <LinearProgress
            variant="determinate"
            color="secondary"
            value={progress > 100 ? 100 : progress}
            sx={{
              backgroundColor: "#e6e6e6",
              height: "6px",
              width: "100%",
              flexShrink: 0,
            }}
          />
        )}
        <Stack px="22px" width="100%">
          <Stack width={"100%"} direction="row" spacing={1} alignItems="center" height="22px" my="22px">
            <Stack
              height="22px"
              width="fit-content"
              bgcolor={BoostStatus.backgroundColor(
                boost.isNew && (boost.state === BoostStatus.IN_PROGRESS || boost.state === BoostStatus.WAITING_FOR_VALIDATION)
                  ? BoostStatus.NEW
                  : boost.state
              )}
              borderRadius="4px"
              alignItems="center"
              justifyContent="center">
              <Typography
                mx={"10px"}
                color={BoostStatus.labelColor(
                  boost.isNew && (boost.state === BoostStatus.IN_PROGRESS || boost.state === BoostStatus.WAITING_FOR_VALIDATION)
                    ? BoostStatus.NEW
                    : boost.state
                )}
                fontWeight={500}
                fontSize={11}>
                {BoostStatus.label(
                  boost.isNew && (boost.state === BoostStatus.IN_PROGRESS || boost.state === BoostStatus.WAITING_FOR_VALIDATION)
                    ? BoostStatus.NEW
                    : boost.state
                )}
              </Typography>
            </Stack>
            {boost.announcement.managedByTwinin && (
              <Stack height="22px" width="22px" bgcolor={alpha(Colors.gold, 0.2)} borderRadius="4px" alignItems="center" justifyContent="center">
                <img alt="twininT" src="/images/icon_star_3d.svg" height="12px" />
              </Stack>
            )}
            {boost.state === BoostStatus.REJECTED && (
              <Stack alignItems="center" justifyContent="center">
                <Tooltip
                  title={
                    <Stack spacing={0.25}>
                      <Typography fontSize={12} fontWeight="500">
                        {t("boost.cardError.rejectTitle")}
                      </Typography>
                      <Typography fontSize={12} fontWeight="300" lineHeight={1.25} whiteSpace="pre-line">
                        {boost.refusal.reason}
                      </Typography>
                      {!!boost.refusal.user && (
                        <Stack direction="row" alignItems="center" pt="7px" spacing={1}>
                          <Avatar sx={{ height: 22, width: 22 }} />
                          <Typography lineHeight={1.25} whiteSpace="pre-line">
                            {boost.refusal.user.firstname} {boost.refusal.user.lastname}
                          </Typography>
                        </Stack>
                      )}
                    </Stack>
                  }
                  arrow
                  placement="top">
                  <img alt="alert" src="/images/icon_alert.svg" style={{ alignSelf: "flex-end" }} />
                </Tooltip>
              </Stack>
            )}
            <Stack flex={1} />
            {boost.state === BoostStatus.VALIDATED && (
              <Stack alignItems="center" justifyContent="center">
                <Typography color={Colors.boostCard.secondaryText} fontWeight={300} fontSize={12}>
                  {dayjsUtils().to(boost.endDate)}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack direction={"row"} mb={"15px"}>
            <Stack height="29px" width="29px" bgcolor={Colors.lightpurple} borderRadius="15px" alignItems="center" justifyContent="center">
              <img alt="boost" src="/images/icon_boost_rocket.svg" style={{ height: "14px", width: "14px" }} />
            </Stack>
            <Typography alignSelf={"center"} pl={"10px"} color={Colors.boostCard.primaryText} fontSize={"16px"}>
              {BoostType.selectTypesItems.find((t) => t.value === boost.type)?.label ?? "-"}
            </Typography>
          </Stack>
          <Stack component={Divider} width={"100%"} />
          <Stack pt={"16px"} pb="2px" width="100%">
            <Typography fontSize={13} fontWeight="300" color={Colors.boostCard.secondaryText}>
              {t(`global.reference`, { reference: boost.announcement.reference || "-" })}
            </Typography>
            <Typography
              fontSize={14}
              color={Colors.boostCard.secondaryText}
              fontWeight={500}
              lineHeight={1.25}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineClamp: 1,
                WebkitLineClamp: 1,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              }}>
              {boost.announcement.name}
            </Typography>
            {!!boost.affiliate && <Typography fontWeight={300}>{boost.affiliate.name}</Typography>}
            <Stack spacing={1} alignItems="center" direction="row" pb={"16px"}>
              <img alt="location" src="/images/location.svg" />
              <Typography color={Colors.boostCard.secondaryText} fontWeight={500} pt="1px">
                {boost.announcement.location || "-"}
              </Typography>
            </Stack>
            <Stack component={Divider} width={"100%"} />
            {boost.requestedBy && (
              <Stack direction="column">
                <Stack direction="row" alignItems="center" spacing={1} pt={"16px"}>
                  <Avatar style={{ height: 16, width: 16, backgroundColor: Colors.avatarBackground }} src={boost.requestedBy.picture} />
                  <Typography fontSize="14px" fontWeight="300" color={Colors.secondaryText}>
                    {`${boost.requestedBy.firstname} ${boost.requestedBy.lastname}`}
                  </Typography>
                </Stack>
                <Typography fontSize="12px" fontWeight="300" color={Colors.secondaryText}>
                  {t("global.requested", { date: new Date(boost.createdAt) })}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </BoostCardStyled>
  );
};

export default BoostCard;
