import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Container, Grid, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";

import { sessionQuery, sessionService } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { adDetailsQuery } from "@store/ads/details";
import { subscriptionsQuery, SubscriptionTypeEnum } from "@store/subscriptions";
import { unipileService } from "@store/unipile";
import {
  searchUnipileInvitationsEffect,
  unipileInvitationsQuery,
  unipileInvitationsService,
  UnipileInvitationStatusEnumFunctions
} from "@store/unipile/unipileInvitations";
import UnipileLinkedinInvitationCard from "@components/card/UnipileLinkedinInvitation.card";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import AIOButtonComponent from "@components/Button.component";
import WrongSubscriptionModal from "@components/modal/WrongSubscription.modal";
import AIOSelectComponent from "@components/input/Select.component";

const AdsLinkedinInvitationsTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const { adId } = useParams();

  const searchUnipileInvitations = useEffectFn(searchUnipileInvitationsEffect);

  const [{ unipileInvitations, loading: invitationsLoading }] = useObservable(unipileInvitationsQuery.unipileInvitations$);
  const [{ loading: unipileAccountStatusLoading }] = useObservable(sessionQuery.unipileAccountStatus$);

  const [filters] = useObservable(unipileInvitationsQuery.filters$);

  const [checkSubscription, setCheckSubscription] = useState(false);

  const canAccessUnipileFeature = useMemo(() => {
    return sessionQuery.user?.role === UserRoleEnum.ADMIN || adDetailsQuery.managedByTwinin || subscriptionsQuery.sessionUserSubscriptionOngoing;
  }, []);

  useEffect(() => {
    if (!canAccessUnipileFeature) return;

    sessionService.checkUnipileAccountStatus().subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  }, [canAccessUnipileFeature, enqueueSnackbar]);

  useEffect(() => {
    if (!adId) return;
    searchUnipileInvitations({ filters, adId });
  }, [adId, filters, searchUnipileInvitations]);

  const handleRefreshInvitations = useCallback(() => {
    if (!adId) return;
    unipileInvitationsService.refreshUnipileInvitationList(adId).subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  }, [adId, enqueueSnackbar]);

  const handleConnectWithUnipileLinkedin = useCallback(() => {
    unipileService.getUnipileHostedAuthURL(window.location.href).subscribe({
      next: (response) => (window.location.href = response),
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  return (
    <>
      <Container sx={{alignSelf: 'center'}}>
        <Stack width="100%" justifyContent="center" alignItems="center">
          <Stack width="100%">
            <Stack width="100%" direction="row" alignItems="center" spacing="20px" mb="30px">
              <AIOSearchComponent
                placeholder={t("ads.details.potentialProfilesTab.searchInvitations")}
                fullWidth
                onChange={(value) => unipileInvitationsService.setFilters({ search: value })}
              />
              <Box width="180px">
                <AIOSelectComponent
                  placeholder={t("ads.details.potentialProfilesTab.invitationStatus.ALL")}
                  items={UnipileInvitationStatusEnumFunctions.selectItems}
                  handleChange={(value) => unipileInvitationsService.setFilters({ status: value })}
                  fullWidth
                  value={filters.status}
                />
              </Box>
              {!unipileAccountStatusLoading && canAccessUnipileFeature && !sessionQuery.isConnectedToUnipile && (
                <Box width="fit-content" whiteSpace="nowrap">
                  <AIOButtonComponent
                    ariaLabel="linkedin"
                    title={t("ads.details.potentialProfilesTab.loginWithUnipile")}
                    variant="contained"
                    onClick={() => handleConnectWithUnipileLinkedin()}
                  />
                </Box>
              )}
              {(!canAccessUnipileFeature || (!unipileAccountStatusLoading && sessionQuery.isConnectedToUnipile)) && (
                <AIOButtonComponent
                  title={t("ads.details.potentialProfilesTab.refreshStatus")}
                  variant="contained"
                  disabled={invitationsLoading}
                  onClick={() => {
                    if (!canAccessUnipileFeature) return setCheckSubscription(true);
                    handleRefreshInvitations();
                  }}
                />
              )}
            </Stack>
            <Box>
              <Grid container spacing="30px">
                {unipileInvitations.map((invitation) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={invitation.id}>
                    <UnipileLinkedinInvitationCard invitation={invitation}/>
                  </Grid>
                ))}
              </Grid>
              <Stack direction="row" width="100%" justifyContent="center" mt="20px" height={"80px"} alignItems="center">
                {invitationsLoading && <CircularProgress size={30} />}
              </Stack>
            </Box>
          </Stack>
        </Stack>
      </Container>
      {checkSubscription && (
        <WrongSubscriptionModal
          description={t('ads.details.potentialProfilesTab.notForFreemium')}
          title={t('ads.details.potentialProfilesTab.refreshStatus')}
          subscriptionsRequired={[SubscriptionTypeEnum.PROFESSIONAL, SubscriptionTypeEnum.COMPANY]}
          handleClose={() => setCheckSubscription(false)}
        />
      )}
    </>
  );
}

export default AdsLinkedinInvitationsTab;