import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { AffiliateSubscription } from "@store/entities/afiliates/details";

import { Colors } from "@constants/colors.constant";

import CardBackground from "@components/cardBackground/CardBackground.component";
import AddCompanySubscriptionModal
  from "@screens/auth/admin/entities/components/subscription/modal/AddCompanySubscription.modal";
import CompanySubscriptionDetailsModal
  from "@screens/auth/admin/entities/components/subscription/modal/CompanySubscriptionDetails.modal";
import { UserRoleEnum } from "@store/users";
import { sessionQuery } from "@store/session";

interface CompanySubscriptionsCardProps {
  subscriptions: AffiliateSubscription[];
}

const CompanySubscriptionsCard = (props: CompanySubscriptionsCardProps) => {
  const {subscriptions} = props;

  const {t} = useTranslation();

  const [openAddSubscription, setOpenAddSubscription] = useState(false);
  const [seeAll, setSeeAll] = useState(false);

  return (
    <>
      <CardBackground img="/images/background_campaign.svg">
        <Stack p="16px" pb="20px" spacing="15px">
          <Stack direction="row" alignItems="center" spacing="10px" justifyContent="space-between">
            <Stack direction="row" spacing="5px" alignItems="center">
              <img height="14px" src="/images/subscription/icon_subscription_company.svg" alt="company" />
              <Typography color={Colors.subscriptions.company} fontSize="16px" fontWeight={500}>
                {t("subscriptions.details.company.name")}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing="5px">
              <img height="14px" src={!subscriptions.length ? '/images/persona_not_completed.svg' : '/images/persona_completed.svg'} alt="" />
              <Typography fontSize="18px" fontWeight="500" color={Colors.white}>
                {subscriptions.length}
              </Typography>
              <Typography fontSize="11px" fontWeight="300" color={Colors.subscriptions.currentOffers} pt="4px">
                {t("subscriptions.currentSubscriptionNbr", {count: subscriptions.length})}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing="10px">
            {!!subscriptions.length && (
              <Box minWidth="100px">
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setSeeAll(true)}
                  sx={{bgcolor: Colors.subscriptions.seeAll, borderRadius: "15px", height: '35px'}}>
                  {t("global.seeAll")}
                </Button>
              </Box>
            )}
            {sessionQuery.role === UserRoleEnum.ADMIN && (
              <Box minWidth="100px">
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => setOpenAddSubscription(true)}
                  sx={{borderRadius: "15px", height: '35px'}}>
                  {t("global.add")}
                </Button>
              </Box>
            )}
          </Stack>
        </Stack>
      </CardBackground>
      {openAddSubscription && (
        <AddCompanySubscriptionModal handleClose={() => setOpenAddSubscription(false)}/>
      )}
      {seeAll && (
        <CompanySubscriptionDetailsModal
          handleClose={() => setSeeAll(false)}
          affiliateSubscriptions={subscriptions}/>
      )}
    </>
  );
};

export default CompanySubscriptionsCard;
