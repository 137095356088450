import AIOButtonComponent from "@components/Button.component";
import { Colors } from "@constants/colors.constant";
import {
  FREEMIUM_CANDIDATE_DATABASE_CREDITS,
  FREEMIUM_LINKEDIN_POSTING_CREDITS,
  FREEMIUM_POTENTIAL_SEARCH_CREDITS,
  PROFESSIONAL_POTENTIAL_SEARCH_CREDITS
} from "@constants/subscriptions.constant";
import { alpha, Stack, styled, Tooltip, Typography } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery } from "@store/session";
import { Pages, ParametersRoutes } from "@utils/routes.utils";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { subscriptionsQuery } from "@store/subscriptions";
import dayjsUtils from "@utils/dayjs.utils";

const StyledContainer = styled(Stack)(() => ({
  borderRadius: "15px",
  backgroundColor: alpha(Colors.black, 0.05),
  width: "100%",
  padding: "14px",
}));

interface CreditsCountProps {
  countLeft: number;
  countMax: number
  title: string;
  tooltipTitle: string;
  tooltipDescription: string;
}

const CreditsCount = ({ title, countLeft, countMax, tooltipDescription, tooltipTitle }: CreditsCountProps) => {
  return (
    <Tooltip
      title={(
        <Stack spacing={0.25}>
          <Typography fontSize={12} fontWeight="500">
            {tooltipTitle}
          </Typography>
          <Typography fontSize={12} fontWeight="300">
            {tooltipDescription}
          </Typography>
        </Stack>
      )}
      arrow
      placement="bottom">
      <Stack alignItems="center">
        <Typography fontWeight={500} fontSize={"10px"}>
          {title}
        </Typography>
        <Stack direction="row">
          <Typography fontWeight={300}>{countLeft}</Typography>
          <Typography fontWeight={300} color={Colors.grey}>
            /
          </Typography>
          <Typography fontWeight={300} color={Colors.grey}>
            {countMax}
          </Typography>
        </Stack>
      </Stack>
    </Tooltip>
  );
};

const CreditsRecap = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [user] = useObservable(sessionQuery.user$);

  if (!user) return null;

  const isUnlimitedUser = user.affiliates
    .map((a) => a.affiliate)
    .every((a) => a.subscriptions.some((s) => !dayjsUtils().isBefore(s.startDate) && !dayjsUtils().isAfter(s.endDate) && s.unlimited));

  if (isUnlimitedUser) return null;

  const isFreemium = !subscriptionsQuery.subscriptionOngoing(user);

  return (
    <StyledContainer spacing={"24px"}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing="5px">
          <img
            height="14px"
            src={`/images/subscription/icon_subscription_${isFreemium ? 'freemium' : 'professional'}.svg`}
            alt={isFreemium ? 'freemium' : 'professional'}/>
          <Typography fontSize={"15px"} fontWeight={500}>
            {t(`subscriptions.creditsRecap.${isFreemium ? 'freemium' : 'professional'}`)}
          </Typography>
        </Stack>
        <Tooltip
          title={
            <Stack spacing={0.25}>
              <Typography fontSize={12} fontWeight="500">
                {t("subscriptions.creditsRecap.tooltips.info.title")}
              </Typography>
              <Typography fontSize={12} fontWeight="300">
                {t("subscriptions.creditsRecap.tooltips.info.description")}
              </Typography>
            </Stack>
          }
          arrow
          placement="top">
          <img alt="information" src="/images/icon_informations.svg" />
        </Tooltip>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent={isFreemium ? "space-between" : "center"}>
        <CreditsCount
          title={t("subscriptions.creditsRecap.aiFinder")}
          tooltipTitle={t(`subscriptions.creditsRecap.tooltips.${isFreemium ? 'FREEMIUM_POTENTIAL_SEARCH_CREDITS' : 'PROFESSIONAL_POTENTIAL_SEARCH_CREDITS'}.title`)}
          tooltipDescription={t(`subscriptions.creditsRecap.tooltips.${isFreemium ? 'FREEMIUM_POTENTIAL_SEARCH_CREDITS' : 'PROFESSIONAL_POTENTIAL_SEARCH_CREDITS'}.description`)}
          countLeft={user.featureCredits.potentialProfileSearch}
          countMax={isFreemium ? FREEMIUM_POTENTIAL_SEARCH_CREDITS : PROFESSIONAL_POTENTIAL_SEARCH_CREDITS}
        />
        {isFreemium && (
          <>
            <CreditsCount
              title={t("subscriptions.creditsRecap.Linkedin")}
              tooltipTitle={t(`subscriptions.creditsRecap.tooltips.FREEMIUM_LINKEDIN_POSTING_CREDITS.title`)}
              tooltipDescription={t(`subscriptions.creditsRecap.tooltips.FREEMIUM_LINKEDIN_POSTING_CREDITS.description`)}
              countLeft={user.featureCredits.linkedinPosting}
              countMax={FREEMIUM_LINKEDIN_POSTING_CREDITS}
            />
            <CreditsCount
              title={t("subscriptions.creditsRecap.applications")}
              tooltipTitle={t(`subscriptions.creditsRecap.tooltips.FREEMIUM_CANDIDATE_DATABASE_CREDITS.title`)}
              tooltipDescription={t(`subscriptions.creditsRecap.tooltips.FREEMIUM_CANDIDATE_DATABASE_CREDITS.description`)}
              countLeft={user?.featureCredits.candidateDatabase}
              countMax={FREEMIUM_CANDIDATE_DATABASE_CREDITS}
            />
          </>
        )}
      </Stack>
      <AIOButtonComponent
        variant="contained"
        color="secondary"
        onClick={() => navigate(`/${Pages.PARAMETERS}/${ParametersRoutes.SUBSCRIPTION}`)}
        title={t("subscriptions.creditsRecap.evolve")}
      />
    </StyledContainer>
  );
};

export default CreditsRecap;
