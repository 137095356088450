import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { reportsStore } from "@store/reports/reports.store";

export const getReportsDataSource = createRequestDataSource({
  store: reportsStore,
  data$: () => reportsStore.pipe(selectAllEntities()),
  requestKey: "getReports",
  dataKey: "reports"
});
