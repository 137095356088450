import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { companyDetailsService } from "@store/ai-o/companies/details";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOSelectComponent from "@components/input/Select.component";
import { getValueIcon, ValuesIcons } from "@store/ai-o/companies/companies.model";
import { Colors } from "@constants/colors.constant";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

interface AddCompanyValueModalProps {
  companyId: string;
  handleClose: () => void;
}

const AddCompanyValueModal = (props: AddCompanyValueModalProps) => {
  const { companyId, handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState<string>("");
  const [icon, setIcon] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const handleAdd = () => {
    setLoading(true);
    companyDetailsService
      .addValueCompany(companyId, value, icon, description)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.companyValues.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const errors = {
    value: getFieldError(value, FieldValidationType.REQUIRED_STRING),
    icon: getFieldError(icon, FieldValidationType.REQUIRED_STRING),
  };

  return (
    <ModalComponent
      title={t("aio.clients.details.companyValues.addTitle")}
      titleLeft
      maxWidth="xs"
      content={
        <Box p="10px" pb="15px">
          <Grid container columnSpacing="30px" rowSpacing="20px">
            <Grid item xs={12}>
              <AIOTextfieldComponent
                required
                title={t("aio.clients.details.companyValues.fields.value")}
                placeholder={t("aio.clients.details.companyValues.fields.value")}
                value={value.charAt(0).toUpperCase() + value.slice(1)}
                onChange={setValue}
                error={errors.value}
              />
            </Grid>
            <Grid item xs={12}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.companyValues.fields.description")}
                placeholder={t("aio.clients.details.companyValues.fields.description")}
                value={description.charAt(0).toUpperCase() + description.slice(1)}
                onChange={setDescription}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="12px" fontWeight="500" color={Colors.grey}>
                {t("aio.clients.details.companyValues.fields.icon")} *
              </Typography>
              <Stack
                sx={{
                  backgroundColor: Colors.fields,
                  borderRadius: "10px",
                }}>
                <AIOSelectComponent
                  fullWidth
                  color="primary"
                  placeholder={t("aio.clients.details.companyValues.fields.icon")}
                  items={ValuesIcons.map((icon) => ({ label: icon.label, value: icon.label, icon: getValueIcon(icon.label) }))}
                  handleChange={(value) => setIcon(value)}
                  value={icon}
                  error={errors.icon}
                />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          ariaLabel="modal"
          disabled={loading || checkIfErrors(errors)}
          onClick={handleAdd}
          title={t("global.add")}
        />
      }
      handleClose={handleClose}
    />
  );
};

export default AddCompanyValueModal;
