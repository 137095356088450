import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { personalitiesStore } from "./personalities.store";

export const getPersonalitiesDataSource = createRequestDataSource({
  store: personalitiesStore,
  data$: () => personalitiesStore.pipe(selectAllEntities()),
  requestKey: "getPersonalities",
  dataKey: "personalities",
});
