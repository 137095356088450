export interface ManagePrompt extends Partial<Prompt> {
}

export enum PromptsTypeEnum {
  PRESENTATION_CANDIDATE = "presentationCandidate",
  REFORMULATION_OF_SCRAPPED_AD = "reformulationOfScrappedAd",
  REFORMULATION_OF_COVER_LETTER = "reformulationOfCoverLetter",
}

export namespace PromptsTypeEnumFunctions {
  export const variables = {
    [PromptsTypeEnum.PRESENTATION_CANDIDATE]: [
      'nom',
      'prenom',
      'numero_telephone',
      'email',
      'situation_professionnelle_actuelle',
      'depuis_combien_de_temps',
      'titre_diplome',
      'niveau_etude',
      'etablissement_of_diplome',
      'date_obtention_diplome',
      'dernier_emploi',
      'entreprise_precedente',
      'metier_precedent',
      'experience',
      'ton_a_employer',
      'profil_process_com',
      'entreprise_du_job',
      'description_entreprise',
      'description_du_job',
      'nom_du_job',
    ],
    [PromptsTypeEnum.REFORMULATION_OF_COVER_LETTER]: [
      'nom',
      'prenom',
      'numero_telephone',
      'email',
      'situation_professionnelle_actuelle',
      'depuis_combien_de_temps',
      'titre_diplome',
      'niveau_etude',
      'etablissement_of_diplome',
      'date_obtention_diplome',
      'dernier_emploi',
      'entreprise_precedente',
      'metier_precedent',
      'experience',
      'ton_a_employer',
      'profil_process_com',
      'entreprise_du_job',
      'description_entreprise',
      'description_du_job',
      'nom_du_job',
    ],
    [PromptsTypeEnum.REFORMULATION_OF_SCRAPPED_AD]: [
      'description_du_job',
      'nom_du_job',
      "entreprise_du_job",
      "description_entreprise"
    ],
  }
}

export interface Prompt {
  id: string;
  content: string;
  typePrompt: PromptsTypeEnum;
  modelPrompt: string;
}
