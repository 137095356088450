import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";

import AIOAPIAxios, { AIOAPIRoutes } from "@api/aio-axios.api";
import SnackError from "@utils/error.utils";
import { personalitiesStore } from "./personalities.store";
import { getPersonalitiesDataSource } from "./personalities.requests";
import { ManagePersonality, Personality, PersonalityTitle } from "./personalities.model";

export class PersonalitiesService {
  store = personalitiesStore;

  setTitle = (title: string) =>
    this.store.update((state) => ({
      ...state,
      title,
    }));

  getPersonalities = (): Observable<Personality[]> => {
    return from(AIOAPIAxios(AIOAPIRoutes.GETPersonalities())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Personality[]>) => {
        return response.data;
      }),
      tap((personalities) => {
        this.store.update(setEntities(personalities), getPersonalitiesDataSource.setSuccess());
      }),
      getPersonalitiesDataSource.trackRequestStatus()
    );
  };

  getPersonalitiesTitle = (): Observable<PersonalityTitle> => {
    return from(AIOAPIAxios(AIOAPIRoutes.GETPersonalitiesTitle())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PersonalityTitle>) => {
        return response.data;
      }),
      tap((personalityTitle) => {
        this.store.update((state) => ({
          ...state,
          title: personalityTitle.title,
        }));
      })
    );
  };

  updatePersonality = (personalityId: string, personality: ManagePersonality): Observable<Personality> => {
    return from(
      AIOAPIAxios({
        ...AIOAPIRoutes.PUTPersonality(personalityId),
        data: { content: personality.content, gptSentence: personality.gptSentence },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Personality>) => {
        return response.data;
      }),
      tap((personality) => {
        this.store.update(updateEntities(personalityId, personality));
      })
    );
  };

  updatePersonalitiesTitle = (title: string): Observable<string> => {
    return from(
      AIOAPIAxios({
        ...AIOAPIRoutes.PATCHPersonalityTitle(),
        data: { title },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<string>) => {
        return response.data;
      }),
      tap((title) => {
        this.store.update((state) => ({
          ...state,
          title,
        }));
      })
    );
  };

  createPersonality = (personality: ManagePersonality): Observable<Personality> => {
    return from(
      AIOAPIAxios({
        ...AIOAPIRoutes.POSTPersonality(),
        data: { content: personality.content, gptSentence: personality.gptSentence },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Personality>) => {
        return response.data;
      }),
      tap((personality) => {
        this.store.update(addEntities(personality));
      })
    );
  };

  deletePersonality = (personalityId: string): Observable<AxiosResponse> => {
    return from(AIOAPIAxios(AIOAPIRoutes.DELETEPersonality(personalityId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      tap(() => {
        this.store.update(deleteEntities(personalityId));
      })
    );
  };
}

export const personalitiesService = new PersonalitiesService();
