import { alpha, Box, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";

import { companyDetailsService } from "@store/ai-o/companies/details";

import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import ModalComponent from "@components/modal/Modal.component";
import UnderlinedTitle from "@components/UnderlinedTitle";
import { Colors, isDark } from "@constants/colors.constant";
import { FieldValidationType, getFieldError } from "@utils/yup.utils";
import { HexColorPicker } from "react-colorful";

interface ColorCardProps {
  color: string;
  selected?: boolean;
  title: string;
  setSelected?: () => void;
  setUnselected?: () => void;
}

export const ColorCard = (props: ColorCardProps) => {
  const { color, selected, setSelected, setUnselected, title } = props;
  return (
    <Stack
      onClick={selected ? setUnselected : setSelected}
      sx={{
        borderRadius: "15px",
        backgroundColor: color,
        padding: "15px",
        width: "100%",
        height: "100%",
        cursor: "pointer",
        transition: "all 0.3s",
        "&:hover": {
          transform: "scale(1.05)",
        },
        boxShadow: selected ? "0px 0px 10px 0px" + color : "none",
        transform: selected ? "scale(1.05)" : "none",
      }}>
      <Stack alignItems="center" justifyContent="center" spacing={1}>
        <UnderlinedTitle
          title={title}
          textColor={isDark(color) ? Colors.white : Colors.black}
          color={isDark(color) ? alpha(Colors.white, 0.3) : alpha(Colors.white, 0.7)}
          fontSizePx={18}
          fontHeight={700}
        />
        <Typography fontSize={12} fontWeight="400" color={isDark(color) ? Colors.white : Colors.black} textAlign={"left"}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit.
        </Typography>
      </Stack>
    </Stack>
  );
};

interface ModifyColorThemeModalProps {
  companyId: string;
  theme: { primary: string; secondary: string; tertiary: string };
  handleClose: () => void;
}

const ModifyColorThemeModal = (props: ModifyColorThemeModalProps) => {
  const { companyId, theme, handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState<number | null>(null);
  const [color, setColor] = useState("#FFFFFF");
  const [primary, setPrimary] = useState(theme.primary);
  const [secondary, setSecondary] = useState(theme.secondary);
  const [tertiary, setTertiary] = useState(theme.tertiary);
  const [rgb, setRgb] = useState({ r: 0, g: 0, b: 0 });

  const handleSelect = (index: number) => {
    setSelected(index);
    switch (index) {
      case 0:
        setColor(primary);
        break;
      case 1:
        setColor(secondary);
        break;
      case 2:
        setColor(tertiary);
        break;
    }
  };

  const handleUnselect = () => {
    setSelected(null);
    setColor("#FFFFFF");
  };

  useEffect(() => {
    if (selected !== null) {
      switch (selected) {
        case 0:
          setPrimary(color);
          break;
        case 1:
          setSecondary(color);
          break;
        case 2:
          setTertiary(color);
          break;
      }
    }
  }, [color]);

  const handleAdd = () => {
    setLoading(true);
    companyDetailsService
      .patchColorTheme(companyId, { primary, secondary, tertiary })
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.colorTheme.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const errors = {
    hex: getFieldError(color, FieldValidationType.HEX_COLOR),
    rgb: getFieldError(color, FieldValidationType.REQUIRED_NUMBER),
  };

  function getRgbFromHex(hex: string) {
    let r = 0,
      g = 0,
      b = 0;

    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16);
      g = parseInt(hex[2] + hex[2], 16);
      b = parseInt(hex[3] + hex[3], 16);
    }

    if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16);
      g = parseInt(hex[3] + hex[4], 16);
      b = parseInt(hex[5] + hex[6], 16);
    }

    return { r, g, b };
  }

  function rgbToHex(r: number, g: number, b: number) {
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }

  useEffect(() => {
    if (rgb.r < 0 || rgb.r > 255 || rgb.g < 0 || rgb.g > 255 || rgb.b < 0 || rgb.b > 255) {
      setRgb({ r: 0, g: 0, b: 0 });
      return;
    }
    setColor(rgbToHex(rgb.r, rgb.g, rgb.b));
  }, [rgb]);

  return (
    <ModalComponent
      title={t("aio.clients.details.colorTheme.modifyTitle")}
      titleLeft
      maxWidth="xs"
      content={
        <Box p="10px">
          <Typography fontSize="16px" fontWeight={700} mb={2}>
            {t("aio.clients.details.colorTheme.preview")}
          </Typography>
          <Grid container columnSpacing="10px" rowSpacing="20px">
            <Grid item xs={4}>
              <ColorCard
                color={primary}
                selected={selected === 0}
                setSelected={() => handleSelect(0)}
                setUnselected={() => handleUnselect()}
                title={t("aio.clients.details.colorTheme.primary")}
              />
            </Grid>
            <Grid item xs={4}>
              <ColorCard
                color={secondary}
                selected={selected === 1}
                setSelected={() => handleSelect(1)}
                setUnselected={() => handleUnselect()}
                title={t("aio.clients.details.colorTheme.secondary")}
              />
            </Grid>
            <Grid item xs={4}>
              <ColorCard
                color={tertiary}
                selected={selected === 2}
                setSelected={() => handleSelect(2)}
                setUnselected={() => handleUnselect()}
                title={t("aio.clients.details.colorTheme.tertiary")}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                ".react-colorful": { width: "100%", height: "100%" },
              }}>
              <Typography fontSize="16px" fontWeight={700} mb={2}>
                {t("aio.clients.details.colorTheme.colorPicker")}
              </Typography>
              <Stack display="flex" flexDirection="row" justifyContent="center" gap={2}>
                <Stack flex={5}>
                  <HexColorPicker color={color} onChange={setColor} />
                </Stack>
                <Stack flex={3} spacing={1}>
                  <AIOTextfieldComponent
                    title={t("aio.clients.details.colorTheme.fields.hexColor")}
                    placeholder={t("aio.clients.details.colorTheme.fields.hexColor")}
                    error={errors.hex}
                    value={color}
                    onChange={setColor}
                  />
                  <AIOTextfieldComponent
                    title={t("aio.clients.details.colorTheme.fields.red")}
                    placeholder={t("aio.clients.details.colorTheme.fields.red")}
                    // error={errors.rgb}
                    value={getRgbFromHex(color).r.toString()}
                    onChange={(v) => setRgb({ ...rgb, r: +v })}
                  />
                  <AIOTextfieldComponent
                    title={t("aio.clients.details.colorTheme.fields.green")}
                    placeholder={t("aio.clients.details.colorTheme.fields.green")}
                    // error={errors.rgb}
                    value={getRgbFromHex(color).g.toString()}
                    onChange={(v) => setRgb({ ...rgb, g: +v })}
                  />
                  <AIOTextfieldComponent
                    title={t("aio.clients.details.colorTheme.fields.blue")}
                    placeholder={t("aio.clients.details.colorTheme.fields.blue")}
                    // error={errors.rgb}
                    value={getRgbFromHex(color).b.toString()}
                    onChange={(v) => setRgb({ ...rgb, b: +v })}
                  />
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          ariaLabel="modal"
          disabled={loading || (primary === theme.primary && secondary === theme.secondary && tertiary === theme.tertiary)}
          onClick={handleAdd}
          title={t("global.modify")}
        />
      }
      handleClose={handleClose}
    />
  );
};

export default ModifyColorThemeModal;
