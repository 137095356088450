import React, { useMemo, useState } from "react";
import { alpha, Box, Button, CardActionArea, Stack, Tooltip, tooltipClasses, TooltipProps, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TabletMacIcon from "@mui/icons-material/TabletMac";
import LaptopIcon from "@mui/icons-material/Laptop";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import { CompanyDetailsDetailsModel, MediaSizeFormatEnum, companyDetailsService } from "@store/ai-o/companies/details";

import { Colors } from "@constants/colors.constant";

import AddMediasModal from "@screens/auth/admin/aio/customerDetails/profileBlocks/AddMediasModal";
import ConfirmModal from "@components/modal/Confirm.modal";
import styled from "@emotion/styled";

export const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => <Tooltip {...props} classes={{ popper: className }} />)({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
  },
});

interface PicturesBlockProps {
  company: CompanyDetailsDetailsModel;
  type: "image" | "video";
}

const MediaBlock = (props: PicturesBlockProps) => {
  const { company, type } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openAdd, setOpenAdd] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<string | false>(false);
  console.log("company.medias : ", company.medias);
  const handleDelete = () => {
    if (!confirmDelete) return;
    companyDetailsService.removeMediaCompany(company.id, confirmDelete).subscribe({
      next: () => {
        enqueueSnackbar(t(`aio.clients.details.${type}.delete.success`), { variant: "success" });
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const hasMobileImageSize = useMemo(
    () => company.medias.some((m) => m.type === "image" && m.sizeFormat === MediaSizeFormatEnum.MOBILE),
    [company.medias]
  );
  const hasTabletImageSize = useMemo(
    () => company.medias.some((m) => m.type === "image" && m.sizeFormat === MediaSizeFormatEnum.TABLET),
    [company.medias]
  );
  const hasDesktopImageSize = useMemo(
    () => company.medias.some((m) => m.type === "image" && m.sizeFormat === MediaSizeFormatEnum.DESKTOP),
    [company.medias]
  );

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" pt="25px" pb="20px" px="5px" bgcolor={Colors.background} borderRadius="15px" spacing="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)" px="20px">
          <Typography fontSize={16} fontWeight="700">
            {t(`aio.clients.details.${type}.title`)}
          </Typography>
          <Box sx={{ "& > .MuiButton-root": { padding: "5px 10px" } }}>
            <Button variant="text" color="primary" onClick={() => setOpenAdd(true)}>
              {t("global.add")}
            </Button>
          </Box>
        </Stack>
        <Stack direction="row" spacing={4} alignItems="center" justifyContent="center" pb="10px">
          <Stack direction="row" spacing={1} alignItems="center">
            <NoMaxWidthTooltip
              title={
                <Typography sx={{ whiteSpace: "nowrap" }} fontSize="14px">
                  {t("aio.clients.details.image.tooltips.mobileImageSize")}
                </Typography>
              }
              placement="top">
              <PhoneIphoneIcon />
            </NoMaxWidthTooltip>
            {hasMobileImageSize ? (
              <CheckCircleIcon style={{ color: "green", fontSize: "20px" }} />
            ) : (
              <Tooltip title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.noMobileImageSize")}</Typography>} placement="top">
                <CancelIcon style={{ color: "red", fontSize: "20px" }} />
              </Tooltip>
            )}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <NoMaxWidthTooltip
              title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.tabletImageSize")}</Typography>}
              placement="top">
              <TabletMacIcon />
            </NoMaxWidthTooltip>
            {hasTabletImageSize ? (
              <CheckCircleIcon style={{ color: "green", fontSize: "20px" }} />
            ) : (
              <NoMaxWidthTooltip
                title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.noTabletImageSize")}</Typography>}
                placement="top">
                <CancelIcon style={{ color: "red", fontSize: "20px" }} />
              </NoMaxWidthTooltip>
            )}
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <NoMaxWidthTooltip
              title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.desktopImageSize")}</Typography>}
              placement="top">
              <LaptopIcon />
            </NoMaxWidthTooltip>
            {hasDesktopImageSize ? (
              <CheckCircleIcon style={{ color: "green", fontSize: "20px" }} />
            ) : (
              <NoMaxWidthTooltip
                title={<Typography fontSize="14px">{t("aio.clients.details.image.tooltips.noDesktopImageSize")}</Typography>}
                placement="top">
                <CancelIcon style={{ color: "red", fontSize: "20px" }} />
              </NoMaxWidthTooltip>
            )}
          </Stack>
        </Stack>
        <Box width="100%" height="100%" style={{ overflowX: "auto" }} pb="5px">
          <Stack direction="row" alignItems="center" spacing="15px" width="100%" height="100%">
            {company.medias
              .filter((m) => m.type === type)
              .map((m) => (
                <Stack key={m.id} minWidth="100%" minHeight="100%" position="relative" px="20px">
                  {(m.sizeFormat === MediaSizeFormatEnum.MOBILE ||
                    m.sizeFormat === MediaSizeFormatEnum.TABLET ||
                    m.sizeFormat === MediaSizeFormatEnum.DESKTOP) && (
                    <Stack
                      sx={{
                        position: "absolute",
                        top: "5px",
                        left: "25px",
                        background: Colors.white,
                        borderRadius: "5px",
                        padding: "5px",
                        zIndex: 1,
                      }}>
                      <Stack>
                        {m.sizeFormat === MediaSizeFormatEnum.MOBILE ? (
                          <PhoneIphoneIcon fontSize="small" />
                        ) : m.sizeFormat === MediaSizeFormatEnum.TABLET ? (
                          <TabletMacIcon fontSize="small" />
                        ) : m.sizeFormat === MediaSizeFormatEnum.DESKTOP ? (
                          <LaptopIcon fontSize="small" />
                        ) : null}
                      </Stack>
                    </Stack>
                  )}
                  <Stack
                    component={CardActionArea}
                    sx={{
                      background: `linear-gradient(${alpha(Colors.black, 0.4)} 0%, ${alpha(Colors.black, 0.4)} 100%),
                    url(${type === "video" ? "/images/icon_play_round.svg" : m.downloadUrl})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                      minWidth: "100%",
                      height: "450px",
                    }}
                    onClick={() => setConfirmDelete(m.id)}
                    minWidth="100%"
                    minHeight="100%"
                    borderRadius="7px">
                    <img alt="delete" src="/images/icon_delete_white.svg" />
                  </Stack>
                  {type === "video" && (
                    <Tooltip title={m.fileName}>
                      <Typography fontSize="12px" fontWeight="500" textAlign="center" lineHeight="1.25" noWrap>
                        {m.fileName}
                      </Typography>
                    </Tooltip>
                  )}
                </Stack>
              ))}
          </Stack>
        </Box>
      </Stack>
      {openAdd && <AddMediasModal type={type} companyId={company.id} handleClose={() => setOpenAdd(false)} />}
      {!!confirmDelete && (
        <ConfirmModal
          handleConfirm={handleDelete}
          confirmMsg={t(`aio.clients.details.${type}.delete.description`)}
          modalTitle={t(`aio.clients.details.${type}.delete.title`)}
          handleClose={() => setConfirmDelete(false)}
        />
      )}
    </Stack>
  );
};

export default MediaBlock;
