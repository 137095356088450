import ModalComponent from "@components/modal/Modal.component";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Colors } from "@constants/colors.constant";

interface ToolRequestModalProps {
  handleClose: () => void;
  toolTitle: string;
}

const ToolRequestModal = (props: ToolRequestModalProps) => {
  const { handleClose, toolTitle } = props;

  const { t } = useTranslation();

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("toolbox.requestTitle")}
      icon={<img alt="" src={"/images/illus_check.svg"} style={{ height: "50px", width: "50px", alignSelf: "center", marginBottom: "10px" }} />}
      content={
        <Typography
          textAlign="center"
          style={{ wordBreak: "break-word", paddingBottom: "30px" }}
          color={Colors.secondaryText}
          lineHeight="1.25"
          mt="6px">
          {t("toolbox.requestDesc", { name: toolTitle })}
        </Typography>
      }
      maxWidth="xs"
    />
  );
};

export default ToolRequestModal;
