import { Colors } from "@constants/colors.constant";
import { alpha, Box, Button, Stack, Typography } from "@mui/material";
import { CompanyDetailsDetailsModel, companyDetailsService } from "@store/ai-o/companies/details";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AddLightInfo from "./AddLightInfo.modal";

interface LightInfosBlockProps {
  company: CompanyDetailsDetailsModel;
}

const LightInfosBlock = (props: LightInfosBlockProps) => {
  const { company } = props;
  const { t } = useTranslation();
  const [openEdit, setOpenEdit] = useState(false);
  const [modifyId, setModifyId] = useState<string | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setModifyId(null);
    setOpenEdit(false);
  };

  const handleDelete = (id: string) => {
    companyDetailsService.deleteLightInfoCompany(company.id, id).subscribe({
      next: () => {
        enqueueSnackbar(t("aio.clients.details.lightInfos.delete"), { variant: "success" });
        handleClose();
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("aio.clients.details.lightInfos.title")}
          </Typography>
          <Box sx={{ "& > .MuiButton-root": { padding: "5px 10px" } }}>
            <Button variant="text" color="primary" onClick={() => setOpenEdit(true)} disabled={company.lightInfos && company.lightInfos?.length > 3}>
              {t("global.add")}
            </Button>
          </Box>
        </Stack>
        <Stack spacing="15px">
          {company.lightInfos?.map((info, index) => (
            <Stack
              key={index}
              sx={{
                backgroundColor: alpha(Colors.primary, 0.1),
                position: "relative",
                flexDirection: "row",
                gap: "10px",
                borderRadius: "10px",
                padding: "15px",
              }}
              fontStyle={"italic"}>
              <Stack alignItems="center" justifyContent="center" bgcolor={Colors.primary} borderRadius="10px" padding="3px 12px" width="fit-content">
                <Typography fontSize={14} fontWeight="600" color={Colors.white}>
                  {info.info}
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" width="100%">
                <Stack maxWidth={"200px"}>
                  <Typography
                    fontSize={14}
                    fontWeight="400"
                    color={Colors.secondaryText}
                    fontStyle={"italic"}
                    sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth: "100%" }}>
                    {info.description}
                  </Typography>
                </Stack>
              </Stack>
              <Stack sx={{ flexDirection: "row", gap: "10px", position: "absolute", right: "15px", top: "20px" }}>
                <Stack
                  onClick={() => {
                    setModifyId(info.id);
                    setOpenEdit(true);
                  }}
                  sx={{ cursor: "pointer" }}>
                  <img alt="modify" src={`/images/icon_edit_fill_primary.svg`} height={"15px"} />
                </Stack>
                <Stack onClick={() => handleDelete(info.id)} sx={{ cursor: "pointer" }}>
                  <img alt="modify" src={`/images/icon_delete.svg`} height={"15px"} />
                </Stack>
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack>
      {openEdit && <AddLightInfo company={company} modifyId={modifyId ?? undefined} handleClose={handleClose} />}
    </Stack>
  );
};

export default LightInfosBlock;
