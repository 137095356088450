import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";

import { sessionQuery } from "@store/session";
import {
  affiliateDetailsQuery,
  affiliateDetailsService,
  AffiliateToolboxTool,
  AffiliateToolboxToolStatusEnum
} from "@store/entities/afiliates/details";

import { Colors } from "@constants/colors.constant";

import { SelectItem } from "@components/input/Select.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import ToolboxToolCell from "@screens/auth/client/toolbox/components/ToolboxToolCell.component";
import ToolRequestModal from "@screens/auth/client/toolbox/components/ToolRequest.modal";
import ToolboxToolGroupedCells from "@screens/auth/client/toolbox/components/ToolboxToolGroupedCells.component";

const AffiliatesToolbox = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [openRequestAcceptedModal, setOpenRequestAcceptedModal] = useState(false);
  const [requestedToolTitle, setRequestedToolTitle] = useState("");

  const [{ affiliateToolboxTools }] = useObservable(affiliateDetailsQuery.toolboxTools$);

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [affiliates] = useState<SelectItem[]>(sessionQuery.affiliatesItems);

  const [selectedAffiliate, setSelectedAffiliate] = useState<SelectItem | undefined>(affiliates[0]);

  useEffect(() => {
    if (selectedAffiliate?.value || clientViewClient?.id) {
      affiliateDetailsService.getAffiliateToolboxTools(clientViewClient?.id || selectedAffiliate?.value || "").subscribe({
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [enqueueSnackbar, selectedAffiliate?.value, clientViewClient]);

  const details = clientViewClient
    ? clientViewClient
    : sessionQuery.affiliatesItems.find((a) => a.value === selectedAffiliate?.value)?.data.affiliate;

  if (!details) return null;

  const groupedByDocAffiliateToolboxTools: { [key: string]: AffiliateToolboxTool[] } = {};
  details?.toolboxColumns?.forEach((toolbox) => {
    if (affiliateToolboxTools) {
      affiliateToolboxTools
        .filter((ct) => ct.column.name === toolbox.name)
        .sort((a) => {
          return a.status === AffiliateToolboxToolStatusEnum.REQUESTED ? -1 : 1;
        })
        .forEach((ct) => {
          if (!groupedByDocAffiliateToolboxTools[ct.column.name + (ct.category ?? "") + ct.title]) {
            groupedByDocAffiliateToolboxTools[ct.column.name + (ct.category ?? "") + ct.title] = [ct];
          } else {
            groupedByDocAffiliateToolboxTools[ct.column.name + (ct.category ?? "") + ct.title].push(ct);
          }
        });
    }
  });

  return (
    <>
      <Stack pt={breakpointDownSM ? "10px" : "60px"} spacing={breakpointDownSM ? 1 : 4} flex={1} width="100%" style={{ overflowX: "hidden" }}>
        <Stack direction="row" alignItems={"center"} justifyContent={"space-between"} px={breakpointDownSM ? "10px" : "40px"}>
          <Typography fontSize="35px" fontWeight="700">
            {t("toolbox.title")}
          </Typography>
          {affiliates.length > 1 && (
            <Box width="250px">
              <SelectWithSearchComponent
                handleChange={(value) => {
                  if (value) setSelectedAffiliate(value);
                }}
                startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                items={affiliates}
                value={selectedAffiliate}
              />
            </Box>
          )}
        </Stack>
        <Stack
          height="100%"
          pb={breakpointDownSM ? "10px" : "20px"}
          flex={1}
          direction="row"
          style={{ overflow: "auto" }}
          px={breakpointDownSM ? "10px" : "40px"}
          className="scrollable-horizontal">
          {!details?.toolboxColumns?.length && (
            <Stack width="100%" height="100%">
              <Typography fontWeight="700" fontSize="16px">
                {t("toolbox.noColumn")}
              </Typography>
            </Stack>
          )}
          {details?.toolboxColumns?.map((toolbox, index) => (
            <Box
              key={toolbox.name}
              height="100%"
              overflow="auto"
              minWidth="300px"
              maxWidth="333px"
              pr={index < (details?.toolboxColumns?.length ?? 1) - 1 ? "20px" : breakpointDownSM ? "5px" : "40px"}>
              <Stack bgcolor={Colors.greyCardBg} borderRadius="15px" height="100%" style={{ overflow: "auto" }}>
                <Stack direction="row" alignItems="center" justifyContent="space-between" p={breakpointDownSM ? "15px" : "25px"} pb="0px">
                  <Typography fontWeight="700" fontSize="16px">
                    {toolbox.name}
                  </Typography>
                </Stack>
                <Stack overflow="auto" className="scrollable" p={breakpointDownSM ? "15px" : "25px"} spacing={3}>
                  {affiliateToolboxTools &&
                    groupedByDocAffiliateToolboxTools &&
                    Object.values(groupedByDocAffiliateToolboxTools)
                      .filter((g) => g[0].column.name === toolbox.name)
                      .map((tools) => {
                        if (tools.length > 1) {
                          return <ToolboxToolGroupedCells key={tools[0].id} tools={tools} entity={details} columnId={toolbox.id} />;
                        } else {
                          return (
                            <ToolboxToolCell
                              key={tools[0].id}
                              tool={{ category: tools[0].category ?? undefined, title: tools[0].title }}
                              affiliateToolboxTool={tools[0]}
                              entity={details}
                              columnId={toolbox.id}
                            />
                          );
                        }
                      })
                      .concat(
                        toolbox.tools
                          .filter(
                            (t) =>
                              !affiliateToolboxTools.some(
                                (ct) =>
                                  ((!ct.category && !t.category) || ct.category === t.category) &&
                                  ct.title === t.title &&
                                  ct.column.name === toolbox.name
                              )
                          )
                          .map((tool) => {
                            return (
                              <ToolboxToolCell
                                key={toolbox.name + tool.title + (tool.category ?? "")}
                                tool={tool}
                                entity={details}
                                columnId={toolbox.id}
                                setOpenRequestAcceptedModal={setOpenRequestAcceptedModal}
                                setRequestedToolTitle={setRequestedToolTitle}
                              />
                            );
                          })
                      )}
                </Stack>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Stack>
      {openRequestAcceptedModal && <ToolRequestModal handleClose={() => setOpenRequestAcceptedModal(false)} toolTitle={requestedToolTitle} />}
    </>
  );
};

export default AffiliatesToolbox;
