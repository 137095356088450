import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { finalize, Observable } from "rxjs";
import { useObservable } from "@ngneat/react-rxjs";

import { usersQuery } from "@store/users";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import { SelectItem } from "@components/input/Select.component";

interface UsersChoiceForMailingModalProps {
  handleCloseModal: () => void;
  handleValidate: (emails: string[]) => Observable<any> | undefined;
}

const UsersChoiceForMailingModal = (props: UsersChoiceForMailingModalProps) => {
  const { handleCloseModal, handleValidate } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { entityId } = useParams();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [{ users }] = useObservable(usersQuery.users$);

  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<SelectItem[]>([]);

  const usersItem: SelectItem[] = users.map((user) => ({
    label: user.firstname + " " + user.lastname,
    value: user.id,
    data: user.email,
  }));

  const handleConfirm = () => {
    if (!entityId) return ;
    setLoading(true);
    handleValidate(
    selectedUsers.length ? selectedUsers.map((u) => u.data) : users.map((u) => u.email) // on envoie une liste d'emails, on envoie à tous les users si aucun n'est sélectionné
    )?.pipe(finalize(() => setLoading(false))).subscribe({
      next: () => handleCloseModal(),
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <ModalComponent
      handleClose={handleCloseModal}
      title={t("subscriptions.timelineEmail.userToSendEmail")}
      titleLeft
      content={
        <Stack spacing={3} py="30px" width={breakpointDownSM ? "100%" : "300px"}>
          <MultiSelectWithSearchComponent
            handleChange={setSelectedUsers}
            startIcon={<img alt="" src="/images/icon_select_company.svg" />}
            multiSelectedLabel={(count) => t("subscriptions.timelineEmail.usersCount", { count }).toString()}
            placeholder={t(usersItem.length ? "subscriptions.timelineEmail.allUsers" : "subscriptions.timelineEmail.noUser")}
            items={usersItem}
            loading={loading}
            readOnly={!usersItem.length}
            values={selectedUsers}
          />
        </Stack>
      }
      maxWidth={false}
      fullWidth={false}
      paddingButton="10px 0px 40px 0px"
      actions={!!usersItem.length ? (
        <AIOButtonComponent
          title={t("global.confirm")}
          disabled={loading}
          variant="contained"
          ariaLabel="modal"
          onClick={handleConfirm} />
      ) : undefined} />
  );
};

export default UsersChoiceForMailingModal;
