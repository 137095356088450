import { CircularProgress, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { MentionItem } from "react-mentions";
import { map } from "rxjs";
import { useTranslation } from "react-i18next";

import { usersService } from "@store/users";

import CommentsComponent from "@components/input/Comments.component";
import { entityDetailsQuery, entityDetailsService } from "@store/entities/details";
import { EntityTypeEnum } from "@store/entities/entities.model";

interface EntityDetailsTabProps {
  type: EntityTypeEnum;
}

const EntityCommentTab = (props: EntityDetailsTabProps) => {
  const { type } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { entityId } = useParams();
  const {t} = useTranslation();

  const [searchParams] = useSearchParams();

  const [{ entityComments: comments, loading: commentsLoading }] = useObservable(entityDetailsQuery.comments$(type));

  const commentId = searchParams.get("commentId");

  useEffect(() => {
    if (entityId) {
      entityDetailsService.getEntityComments(type, entityId).subscribe({
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [entityId, enqueueSnackbar, type]);

  const handleAddComment = (comment: string, mentions: MentionItem[]) => {
    if (!entityId) return;
    entityDetailsService.addComments(type, entityId, comment, mentions).subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handleUpdateComment = (commentId: string, comment: string, mentions: MentionItem[]) => {
    if (!entityId) return;
    entityDetailsService.updateComment(type, entityId, commentId, comment, mentions).subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  };

  if (!entityId) return <></>;

  return (
    <Stack spacing={2}>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography fontSize="20px" fontWeight={700}>
          {commentsLoading ? "-" : ""}
          {!commentsLoading && t("ads.details.detailTab.comments", { count: comments?.length ?? 0 })}
        </Typography>
        {commentsLoading && <CircularProgress size={20} />}
      </Stack>
      <CommentsComponent
        shouldNotIncludeTwinners
        handleAddComment={handleAddComment}
        handleUpdateComment={handleUpdateComment}
        selectedCommentId={commentId ?? undefined}
        comments={comments}
        fetchTags={() =>
          usersService.getAdminUsers().pipe(
            map((users) =>
              users
                .map((user) => ({
                  id: user.email,
                  display: `${user.firstname} ${user.lastname}`,
                }))
            )
          )
        }
      />
    </Stack>
  );
};

export default EntityCommentTab;
