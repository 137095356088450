import { alpha, Avatar, CardActionArea, Stack, styled, Typography } from "@mui/material";
import React from "react";

import { EntitiesModel, EntityTypeEnumFunctions } from "@store/entities/entities.model";

import { Colors } from "@constants/colors.constant";

import EntityMenu from "@screens/auth/admin/entities/components/Entity.menu";

const EntityCardStyled = styled(Stack)`
  background-color: ${Colors.white};
  border-radius: 15px;
  height: 100%;
  flex: 1;
  opacity: 1;
  position: relative;
  width: 100%;
  & > *:first-of-type:not([aria-label="options"]) {
    align-items: center;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(34, 34, 64, 0.05);
    padding: 40px 22px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
  }
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    }
  }
`;

const BadgeType = styled(Stack)`
  border-radius: 5px;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 500;
  height: 15px;
  padding: 0 8px;
  position: absolute;
  top: -8px;
  z-index: 1;
`;

interface EntityCardProps {
  entity: EntitiesModel;
  handleClick?: () => void;
  hideMenu?: boolean;
  hideType?: boolean;
}

const EntityCard = (props: EntityCardProps) => {
  const { entity, handleClick, hideMenu, hideType } = props;

  return (
    <EntityCardStyled>
      <Stack component={!handleClick ? Stack : CardActionArea} onClick={handleClick}>
        {!hideType && (
          <BadgeType bgcolor={EntityTypeEnumFunctions.color(entity.type)} alignItems="center" justifyContent="center">
            {EntityTypeEnumFunctions.selectItems.find((r) => r.value === entity.type)?.label ?? ""}
          </BadgeType>
        )}
        <Stack alignItems="center" direction="row" width="100%" spacing={1.5}>
          <Avatar
            sx={{
              border: "none",
              borderRadius: "10px !important",
              backgroundColor: entity.picture ? Colors.white : alpha(Colors.lightpurple, 0.6),
              color: Colors.secondary,
              height: 53,
              width: 53,
            }}
            src={entity.picture}>
            {entity.name[0]}
          </Avatar>
          <Stack>
            <Typography
              fontSize={16}
              fontWeight="500"
              lineHeight={1}
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                lineClamp: 2,
                WebkitLineClamp: 2,
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
              }}>
              {entity.name}
            </Typography>
            <Stack spacing={1} alignItems="center" direction="row">
              <img alt="location" src="/images/location.svg" />
              <Typography color={Colors.secondaryText} fontWeight={500} pt="1px">
                {entity.city}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      {!hideMenu && (
        <Stack aria-label="options" direction="row" alignItems="center" spacing="4px" position="absolute" right="10px" top="10px">
          {entity.isFavorite && <img alt="" src="/images/favorites_red.svg" />}
          <EntityMenu entity={entity} />
        </Stack>
      )}
    </EntityCardStyled>
  );
};

export default EntityCard;
