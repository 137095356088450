import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteAllEntities, getEntitiesIds } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";
import { deleteAllPages, setPage, updatePaginationData } from "@ngneat/elf-pagination";

import { applicationsStore } from "@store/applications/applications.store";
import { Application, ApplicationsFilters } from "@store/applications/applications.model";
import { getPaginatedApplicationsDataSource } from "@store/applications/applications.requests";

import { PaginatedData } from "@utils/infinitescroll.utils";
import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { filesService } from "@store/files";
import { sessionQuery, sessionService } from "@store/session";
import { FeatureCreditsTypeEnum } from "@store/subscriptions";

export class ApplicationsService {
  store = applicationsStore;

  resetStore = () => this.store.reset();
  deleteEntities = () => this.store.update(deleteAllEntities());
  deleteAllPages = () => this.store.update(deleteAllPages());

  setFilters = (filters: Partial<ApplicationsFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getApplications = (filters?: ApplicationsFilters, page: number = 1, take: number = 24): Observable<PaginatedData<Application[]>> => {
    const affiliateIds = sessionQuery.clientViewClient ? [sessionQuery.clientViewClient.id] : filters?.affiliateIds;

    return from(
      APIAxios({
        ...APIRoutes.GETApplications(),
        params: {
          search: filters?.search || undefined,
          affiliateIds: affiliateIds || undefined,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<Application[]>>) => {
        return response.data;
      }),
      tap((applications) => {
        this.store.update(
          addEntities(applications.data.filter((application) => !this.store.query(getEntitiesIds()).includes(application.id))),
          updatePaginationData({
            currentPage: applications.meta.page,
            perPage: applications.meta.take,
            total: applications.meta.itemCount,
            lastPage: applications.meta.pageCount,
          }),
          setPage(
            applications.meta.page,
            applications.data.map((application) => application.id)
          ),
          getPaginatedApplicationsDataSource.setSuccess()
        );
      }),
      getPaginatedApplicationsDataSource.trackRequestStatus()
    );
  };

  downloadCVWithPreview = (application: Application, removeCredit?: boolean): Observable<Blob> => {
    return from(APIAxios(APIRoutes.GETDownloadCV(application.id))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Blob>) => {
        return response.data;
      }),
      tap((file) => {
        if (removeCredit) {
          sessionService.subtractCredit(FeatureCreditsTypeEnum.APPLICATIONS);
        }

        filesService.previewPDF(file);
      })
    );
  };

  deleteCVs = (applicationIds: string[], fromCVTheque: boolean): Observable<PaginatedData<Application[]>> => {
    return from(APIAxios({ ...APIRoutes.DELETEApplications(), data: { applicationIds, fromCVTheque } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<Application[]>>) => {
        return response.data;
      })
    );
  };
}

export const applicationsService = new ApplicationsService();
