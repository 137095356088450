import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { Candidate, CandidatesFilters, CandidateSortEnum, CandidateSortModel } from "./candidates.model";
import { withPagination } from "@ngneat/elf-pagination";

interface CandidatesStoreProps {
  filters: CandidatesFilters;
  sort: CandidateSortModel;
}

export const candidatesStore = createStore(
  { name: "candidates" },
  withProps<CandidatesStoreProps>({
    filters: {},
    sort: { field: CandidateSortEnum.DATE },
  }),
  withEntities<Candidate>(),
  withRequestsStatus(),
  withPagination()
);
