import { Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { NumericFormat } from "react-number-format";

function NumberFormatSeparator(props: any) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator=" "
      isNumericString
      prefix=""
    />
  );
}

interface AIOTextfieldProps {
  title?: string;
  placeholder?: string;
  value?: string;
  readonly?: boolean;
  onChange?: (value: string) => void;
  onClick?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  multiline?: boolean;
  required?: boolean;
  minRows?: number;
  size?: "small" | "medium";
  color?: "primary" | "secondary";
  error?: string;
  type?: string;
  ariaLabel?: string;
  fullWidth?: boolean;
  autoComplete?: string;
  startAdornment?: JSX.Element;
  endAdornment?: JSX.Element;
  onBlur?: (e: any) => void;
  sx?: SxProps<Theme>;
  maxLength?: number;
  pattern?: RegExp;
  rows?: number;
  inputNumberFormatSeparator?: boolean;
}

const AIOTextfieldComponent = (props: AIOTextfieldProps) => {
  const {
    title,
    required,
    placeholder,
    value,
    size,
    color,
    readonly,
    onChange,
    onClick,
    onKeyDown,
    multiline,
    minRows,
    error,
    type,
    ariaLabel,
    fullWidth,
    autoComplete,
    startAdornment,
    endAdornment,
    onBlur,
    sx,
    maxLength,
    pattern,
    rows,
    inputNumberFormatSeparator,
  } = props;

  return (
    <Stack>
      {title && (
        <Typography fontSize="12px" color={Colors.secondaryText}>
          {title} {required ? "*" : undefined}
        </Typography>
      )}
      <TextField
        aria-label={ariaLabel}
        onKeyDown={onKeyDown}
        size={size ?? "small"}
        fullWidth={fullWidth}
        placeholder={placeholder}
        color={color}
        onBlur={onBlur}
        onChange={(evt) => {
          let value = evt.target.value;

          if (maxLength && value.length > maxLength) return
          if (pattern && value && !pattern.test(value.toString())) {
            evt.target.value = value.slice(0, value.length - 1);
            return;
          }

          onChange?.(value);
        }}
        onClick={onClick}
        InputProps={{
          readOnly: readonly,
          startAdornment,
          endAdornment,
          inputComponent: inputNumberFormatSeparator ? NumberFormatSeparator : undefined,
        }}
        multiline={multiline}
        rows={rows}
        minRows={minRows}
        value={value}
        error={!!error}
        helperText={error}
        type={type}
        autoComplete={autoComplete || ""}
        sx={sx}
      />
    </Stack>
  );
};

export default AIOTextfieldComponent;
