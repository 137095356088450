import { createStore, withProps } from "@ngneat/elf";
import { withEntities, withUIEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { withPagination } from "@ngneat/elf-pagination";

import { AdModel, AdsFilters, AdsSort, AdsSortField, AdStats, AdUi } from "@store/ads/ads.model";

interface AdsStoreProps {
  stats: AdStats;
  filters: AdsFilters;
  sort: AdsSort;
}

export const adsStore = createStore(
  { name: "ads" },
  withProps<AdsStoreProps>({
    stats: {},
    filters: {},
    sort: { field: AdsSortField.NAME },
  }),
  withEntities<AdModel>(),
  withUIEntities<AdUi>(),
  withRequestsStatus(),
  withPagination()
);
