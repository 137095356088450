import { alpha, Button, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import React, { useEffect, useMemo } from "react";

import { adDetailsQuery, adDetailsService, ContractTypeEnum, ContractTypeFunctions } from "@store/ads/details";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { getAppCurrency, getCurrencyLogo } from "@store/common/country.model";

import { Colors } from "@constants/colors.constant";
import { AdRoutes } from "@utils/routes.utils";

import AIORoutesTabComponent from "@components/AIORoutesTab.component";
import AdMenu from "@screens/auth/common/ads/components/Ad.menu";
import dayjs from "dayjs";

const AdsDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { adId } = useParams();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [{ adDetails: ad }] = useObservable(adDetailsQuery.details$);

  useEffect(() => {
    adDetailsService.resetStore();

    if (adId) {
      adDetailsService.getAdDetails(adId).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
      adDetailsService.getAdComments(adId).subscribe({
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
    }

    return () => {
      adDetailsService.resetStore();
    };
  }, [adId, enqueueSnackbar]);

  const showCustomAdMenuButton = !!ad?.isCustom && !ad?.lastSeen

  const deletionDate = useMemo(() => {
    if (!ad || !ad?.lastSeen) return undefined;

    return dayjs(ad.lastSeen).add(7, 'd');
  }, [ad]);

  return (
    <Stack flex={1} justifyContent="flex-start" overflow="hidden">
      <Stack
        justifyContent="center"
        px={breakpointDownSM ? "10px" : "20px"}
        pt="20px"
        pb={breakpointDownSM ? "20px" : "30px"}
        bgcolor="white"
        sx={{
          backgroundImage: "linear-gradient(to left, rgba(232, 97, 77, 0.05) 0%, rgba(232, 97, 77, 0) 100%)",
        }}
        boxShadow={`0 0px 50px 0 ${alpha(Colors.black, 0.1)}`}>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Button variant="text" color="inherit" startIcon={<img alt="back" src="/images/arrow_back.svg" />} onClick={() => navigate("..")}>
            <Typography fontWeight="300" color="secondary">
              {t(`ads.${sessionQuery.role === UserRoleEnum.ADMIN ? "adminTitle" : "title"}`)}
            </Typography>
          </Button>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Typography color={Colors.greyLight} fontWeight={300}>
              {t(`global.reference`, { reference: ad?.reference || "-" })}
            </Typography>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1} height="22px">
              {!!deletionDate && (
                <Tooltip
                  title={
                    <Typography fontSize={12} fontWeight={500} textAlign={"center"}>
                      {t("ads.cardTooltip.isLastSeen", {
                        days: deletionDate.fromNow(),
                        date: deletionDate.toDate(),
                      })}
                    </Typography>
                  }
                  arrow
                  placement="top">
                  <Stack height="22px" width="22px" bgcolor={Colors.lightError} borderRadius="4px" alignItems="center" justifyContent="center">
                    <img alt="boost" src="/images/icon_clock.png" style={{ height: "12px", width: "12px" }} />
                  </Stack>
                </Tooltip>
              )}
              {ad?.managedByTwinin && (
                <Stack height="22px" width="22px" bgcolor={alpha(Colors.gold, 0.2)} borderRadius="4px" alignItems="center" justifyContent="center">
                  <img alt="twininT" src="/images/icon_star_3d.svg" height="12px" />
                </Stack>
              )}
              {(ad?.applications?.aioApplications ?? 0) > 0 && (
                <Stack height="22px" width="22px" bgcolor={Colors.applicationsOrange} borderRadius="4px" alignItems="center" justifyContent="center">
                  <img alt="boost" src="/images/logoAIO.svg" style={{ height: "25px", width: "25px" }} />
                </Stack>
              )}
              {ad?.isBoosted && (
                <Stack height="22px" width="22px" bgcolor={Colors.lightpurple} borderRadius="4px" alignItems="center" justifyContent="center">
                  <img alt="boost" src="/images/icon_boost_rocket.svg" style={{ height: "12px", width: "12px" }} />
                </Stack>
              )}
              {!!ad && showCustomAdMenuButton && <AdMenu ad={ad} isInDetails />}
            </Stack>
          </Stack>
        </Stack>
        <Typography
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          overflow="hidden"
          textAlign="center"
          fontSize="25px"
          fontWeight={700}
          lineHeight={1.25}
          height="35px">
          {ad?.name}
        </Typography>
        <Stack direction={breakpointDownMD ? "column" : "row"} alignItems="center" justifyContent="center" spacing={breakpointDownMD ? 1 : 4}>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <img alt="location" src="/images/location.svg" />
              <Typography color={Colors.greyLight} pt="1px">
                {!!ad?.department && !!ad?.location && `${ad.department} - ${ad.location}`}
                {!!ad?.department && !ad?.location && ad.department}
                {!ad?.department && !!ad?.location && ad.location}
              </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" spacing={1}>
              <img alt="business" src="/images/business_case.svg" />
              <Typography color={Colors.greyLight} pt="1px">
                {ad?.isCustom
                  ? ContractTypeFunctions.label(ad.contractType as ContractTypeEnum)
                  : ad?.contractType || '-'}{ad?.workingTime ? ` - ${ad?.workingTime}` : ""}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" spacing={4}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <img alt="calendar" src="/images/calendar_grey.svg" />
              <Typography color={Colors.greyLight} pt="1px" lineHeight={1.25}>
                {ad?.publicationDate ? t("global.published", { date: new Date(ad.publicationDate) }) : "-"}
              </Typography>
            </Stack>
          </Stack>
          {(!!ad?.minSalary || ad?.minSalary === 0 || !!ad?.maxSalary || ad?.maxSalary === 0) && (
            <Stack direction="row" alignItems="center" spacing={1}>
              <img alt="calendar" src={getCurrencyLogo()} />
              <Typography color={Colors.greyLight} pt="1px" lineHeight={1.25}>
                {`${ad.minSalary / 1000}k - ${ad.maxSalary / 1000}k${getAppCurrency()}`}
              </Typography>
            </Stack>
          )}
        </Stack>
        <Stack alignSelf="center" maxWidth="100%" alignItems="center" pt={breakpointDownSM ? "10px" : "30px"}>
          <AIORoutesTabComponent routes={AdRoutes.routes} />
        </Stack>
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default AdsDetails;
