import { SelectItem } from "@components/input/Select.component";
import { AffiliateLightModel } from "@store/entities/afiliates";

export interface ReportsFilters {
  search?: string;
  from?: Date;
  to?: Date;
  affiliateIds?: SelectItem[];
}

export interface NewReport {
  name: string;
  from: Date;
  to: Date;
  affiliateId: string;
  file: File | undefined;
}

export interface DownloadReportResponse {
  url: string;
}

export interface Report {
  id: string;
  name: string;
  from: Date;
  to: Date;
  isNew: boolean;
  downloads: number;
  documentKey: string;
  downloadURL: string;
  affiliate: AffiliateLightModel;
}

export namespace ManageNewReport {
  export const toFormData = (newReport: NewReport) => {
    const formData = new FormData();

    formData.append("name", newReport.name);
    formData.append("from", newReport.from.toString());
    formData.append("to", newReport.to.toString());
    formData.append("file", newReport.file!, newReport.file!.name);

    return formData;
  };
}