import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import { finalize } from "rxjs";

import { sessionQuery } from "@store/session";
import { getAppCurrency } from "@store/common/country.model";
import {
  adDetailsService,
  adToManageAd,
  ContractTypeEnum,
  ContractTypeFunctions,
  DomainFunctions,
  ManageAdDetailsModel
} from "@store/ads/details";
import { SelectItem } from "@components/input/Select.component";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import { Colors } from "@constants/colors.constant";
import { RELOAD_ADS } from "@constants/events.constant";
import { AdRoutes } from "@utils/routes.utils";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";

interface ManageAdModalProps {
  adId?: string;
  handleClose: () => void;
  isCreation?: boolean;
  isInDetails?: boolean;
  selectedAffiliate?: SelectItem;
}

const ManageAdModal = (props: ManageAdModalProps) => {
  const { adId, handleClose, isCreation, isInDetails, selectedAffiliate } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);

  const [newAd, setNewAd] = useState<Omit<ManageAdDetailsModel, 'jobBoards'>>({
    affiliate: selectedAffiliate,
  });

  useEffect(() => {
    if (!isCreation && !!adId) {
      adDetailsService.getAdDetails(adId).subscribe({
        next: (a) => setNewAd(adToManageAd(a, selectedAffiliate)),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [adId, enqueueSnackbar, isCreation, selectedAffiliate]);

  const updateNewJob = (name: string) => (value: any) => setNewAd((state) => ({ ...state, [name]: value }));

  const handleSave = () => {
    if (isCreation) {
      setLoading(true);
      adDetailsService.createCustomAd(newAd).pipe(finalize(() => setLoading(false))).subscribe({
        next: (ad) => {
          enqueueSnackbar(t("ads.manageCustom.success.created"), { variant: "success" });
          navigate(`${ad.id}/${AdRoutes.DETAIL}`);
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    } else {
      if (!adId) return;
      setLoading(true);
      adDetailsService.updateCustomAd(adId, newAd).pipe(finalize(() => setLoading(false))).subscribe({
        next: () => {
          enqueueSnackbar(t("ads.manageCustom.success.edited"), { variant: "success" });
          if (!isInDetails) window.dispatchEvent(new Event(RELOAD_ADS));
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  };

  const userAffiliatesItem = useMemo(() => {
    return sessionQuery.user?.affiliates.map((x) => ({ label: x.affiliate.name, value: x.affiliate.id })) ?? [];
  }, []);

  const showContractDuration = useMemo(() => {
    return (
      newAd.contractType?.value === ContractTypeEnum.CDD ||
      newAd.contractType?.value === ContractTypeEnum.INTERIM ||
      newAd.contractType?.value === ContractTypeEnum.SEASONAL ||
      newAd.contractType?.value === ContractTypeEnum.FIXED_TERM_CONTRACT ||
      newAd.contractType?.value === ContractTypeEnum.TEMPORARY_EMPLOYMENT_CONTRACT ||
      newAd.contractType?.value === ContractTypeEnum.SEASONAL_EMPLOYMENT_CONTRACT
    );
  }, [newAd.contractType]);

  if (!isCreation && !newAd.id) return null;

  const errors = {
    name: getFieldError(newAd.name, FieldValidationType.REQUIRED_STRING),
    location: getFieldError(newAd.location, FieldValidationType.REQUIRED_STRING),
    contractType: getFieldError(newAd.contractType, FieldValidationType.REQUIRED_SELECT_ITEM),
    ...(showContractDuration ? { contractDuration: getFieldError(newAd.contractDuration, FieldValidationType.REQUIRED_STRING) } : {}),
    domain: getFieldError(newAd.domain, FieldValidationType.REQUIRED_SELECT_ITEM),
    minSalary: getFieldError(newAd.minSalary, FieldValidationType.REQUIRED_STRING),
    maxSalary: getFieldError(newAd.maxSalary, FieldValidationType.REQUIRED_STRING),
    description: getFieldError(newAd.description, FieldValidationType.REQUIRED_STRING),
    profile: getFieldError(newAd.profile, FieldValidationType.REQUIRED_STRING),
    companyInformation: getFieldError(newAd.companyInformation, FieldValidationType.REQUIRED_STRING),
    affiliate: isCreation ? getFieldError(newAd.affiliate, FieldValidationType.REQUIRED_SELECT_ITEM) : undefined,
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      titleLeft
      title={t(`ads.manageCustom.title.${!isCreation ? 'add' : 'edit'}`)}
      content={
        <Stack spacing={3} py="20px" px="4px" pr="5px">
          <Box>
            <Grid container spacing={3.5}>
              {isCreation && (
                <Grid item xs={breakpointDownSM ? 12 : 6}>
                  <Stack>
                    <Typography fontSize="12px" color={Colors.secondaryText}>
                      {t("ads.manageCustom.labels.affiliate")} *
                    </Typography>
                    <SelectWithSearchComponent
                      readOnly={loading}
                      handleChange={updateNewJob("affiliate")}
                      items={userAffiliatesItem}
                      placeholder={t("ads.manageCustom.placeholders.affiliate")}
                      value={newAd.affiliate ?? ''}
                      error={errors.affiliate} />
                  </Stack>
                </Grid>
              )}
              <Grid item xs={breakpointDownSM ? 12 : 6}>
                <AIOTextfieldComponent
                  required
                  readonly={loading}
                  title={t("ads.manageCustom.labels.name")}
                  placeholder={t("ads.manageCustom.placeholders.name")}
                  onChange={updateNewJob("name")}
                  value={newAd.name ?? ""}
                  error={errors.name} />
              </Grid>
              {!isCreation && !breakpointDownSM && <Grid item xs={6}/>}
              <Grid item xs={breakpointDownSM ? 12 : 4}>
                <AIOTextfieldComponent
                  required
                  readonly={loading}
                  title={t("ads.manageCustom.labels.city")}
                  placeholder={t("ads.manageCustom.placeholders.city")}
                  onChange={updateNewJob("location")}
                  value={newAd.location ?? ""}
                  error={errors.location} />
              </Grid>
              <Grid item xs={breakpointDownSM ? 12 : 4}>
                <AIOTextfieldComponent
                  readonly={loading}
                  title={t("ads.manageCustom.labels.department")}
                  placeholder={t("ads.manageCustom.placeholders.department")}
                  onChange={updateNewJob("department")}
                  value={newAd.department ?? ""}/>
              </Grid>
              <Grid item xs={breakpointDownSM ? 12 : 4}>
                <AIOTextfieldComponent
                  readonly={loading}
                  title={t("ads.manageCustom.labels.region")}
                  placeholder={t("ads.manageCustom.placeholders.region")}
                  onChange={updateNewJob("region")}
                  value={newAd.region ?? ""} />
              </Grid>
              <Grid item xs={breakpointDownSM ? 12 : 4}>
                <Stack>
                  <Typography fontSize="12px" color={Colors.secondaryText}>
                    {t("ads.manageCustom.labels.contractType")} *
                  </Typography>
                  <SelectWithSearchComponent
                    readOnly={loading}
                    handleChange={updateNewJob("contractType")}
                    items={ContractTypeFunctions.selectItems}
                    placeholder={t("ads.manageCustom.placeholders.contractType")}
                    value={newAd.contractType ? newAd.contractType : ""}
                    error={errors.contractType} />
                </Stack>
              </Grid>
              {showContractDuration && (
                <Grid item xs={breakpointDownSM ? 12 : 4}>
                  <AIOTextfieldComponent
                    required
                    readonly={loading}
                    title={t("ads.manageCustom.labels.contractDuration")}
                    placeholder={t("ads.manageCustom.placeholders.contractDuration")}
                    onChange={updateNewJob("contractDuration")}
                    value={newAd.contractDuration ?? ""}
                    error={errors.contractDuration} />
                </Grid>
              )}
              <Grid item xs={breakpointDownSM ? 12 : 4}>
                <AIOTextfieldComponent
                  readonly={loading}
                  title={t("ads.manageCustom.labels.workingTime")}
                  placeholder={t("ads.manageCustom.placeholders.workingTime")}
                  onChange={updateNewJob("workingTime")}
                  value={newAd.workingTime?.toString() ?? ""} />
              </Grid>
              {!showContractDuration && !breakpointDownSM && <Grid item xs={4}/>}
              {!breakpointDownSM && (
                <Grid item xs={breakpointDownSM ? 12 : 4}>
                  <Stack>
                    <Typography fontSize="12px" color={Colors.secondaryText}>
                      {t("ads.manageCustom.labels.domain")} *
                    </Typography>
                    <SelectWithSearchComponent
                      readOnly={loading}
                      handleChange={updateNewJob("domain")}
                      items={DomainFunctions.selectItems}
                      placeholder={t("ads.manageCustom.placeholders.domain")}
                      value={newAd.domain ?? ""}
                      error={errors.domain} />
                  </Stack>
                </Grid>
              )}
              <Grid item xs={breakpointDownSM ? 12 : 4}>
                <AIOTextfieldComponent
                  required
                  readonly={loading}
                  title={t("ads.manageCustom.labels.minSalary")}
                  placeholder={t("ads.manageCustom.placeholders.minSalary")}
                  onChange={(value) => updateNewJob("minSalary")(value ? parseInt(value) : undefined)}
                  value={newAd.minSalary?.toString() ?? ''}
                  pattern={/^[0-9]*$/}
                  error={errors.minSalary}
                  endAdornment={<Typography variant="body2">{getAppCurrency()}</Typography>} />
              </Grid>
              <Grid item xs={breakpointDownSM ? 12 : 4}>
                <AIOTextfieldComponent
                  required
                  readonly={loading}
                  title={t("ads.manageCustom.labels.maxSalary")}
                  placeholder={t("ads.manageCustom.placeholders.maxSalary")}
                  onChange={(value) => updateNewJob("maxSalary")(value ? parseInt(value) : undefined)}
                  value={newAd.maxSalary?.toString() ?? ''}
                  pattern={/^[0-9]*$/}
                  error={errors.maxSalary}
                  endAdornment={<Typography variant="body2">{getAppCurrency()}</Typography>} />
              </Grid>
              <Grid item xs={12}>
                <AIOTextfieldComponent
                  required
                  readonly={loading}
                  title={t("ads.manageCustom.labels.description")}
                  placeholder={t("ads.manageCustom.placeholders.description")}
                  onChange={updateNewJob("description")}
                  value={newAd.description ?? ""}
                  multiline
                  minRows={4}
                  error={errors.description} />
              </Grid>
              <Grid item xs={12}>
                <AIOTextfieldComponent
                  required
                  readonly={loading}
                  title={t("ads.manageCustom.labels.profile")}
                  placeholder={t("ads.manageCustom.placeholders.profile")}
                  onChange={updateNewJob("profile")}
                  value={newAd.profile ?? ""}
                  error={errors.profile}
                  multiline
                  minRows={4} />
              </Grid>
              <Grid item xs={12}>
                <AIOTextfieldComponent
                  required
                  readonly={loading}
                  title={t("ads.manageCustom.labels.companyInformation")}
                  placeholder={t("ads.manageCustom.placeholders.companyInformation")}
                  onChange={updateNewJob("companyInformation")}
                  value={newAd.companyInformation ?? ""}
                  multiline
                  minRows={4}
                  error={errors.companyInformation} />
              </Grid>
              <Grid item xs={12}>
                <AIOTextfieldComponent
                  readonly={loading}
                  title={t("ads.manageCustom.labels.advantages")}
                  placeholder={t("ads.manageCustom.placeholders.advantages")}
                  onChange={updateNewJob("advantages")}
                  value={newAd.advantages ?? ""}
                  multiline
                  minRows={4} />
              </Grid>
            </Grid>
          </Box>
        </Stack>
      }
      maxWidth="md"
      actions={
        loading ? (
          <Stack height={"40px"} justifyContent={"center"}>
            <CircularProgress sx={{ alignSelf: "center" }} size={20} />
          </Stack>
        ) : (
          <AIOButtonComponent
            title={!isCreation ? t("global.modify") : t("global.add")}
            ariaLabel="modal"
            disabled={checkIfErrors(errors)}
            onClick={handleSave}
            variant="contained"
            color="secondary"
          />
        )
      }
    />
  );
};

export default ManageAdModal;