import { select } from "@ngneat/elf";

import { sessionStore } from "@store/session/session.store";
import { getUnipileAccountStatusDataSource, getUserDataSource } from "@store/session/session.requests";
import { UserAffiliateRoleEnum, UserModel, UserRoleEnum } from "@store/users";
import { UnipileAccountConnectionStatusEnum } from "@store/unipile";

export class SessionQuery {
  store = sessionStore;

  isAuthenticated$ = getUserDataSource.data$();
  unipileAccountStatus$ = getUnipileAccountStatusDataSource.data$();

  user$ = this.store.pipe(select((state) => state.user));

  affiliateIds$ = this.store.pipe(select((state) => state.affiliateIds));

  buyerMode$ = this.store.pipe(select((state) => state.buyerMode));

  clientViewClient$ = this.store.pipe(select((state) => state.clientViewClient));

  get affiliateIds() {
    return this.store.getValue().affiliateIds;
  }

  get user(): UserModel {
    return this.store.getValue().user!;
  }

  get hasUnipileAccount(): boolean {
    return this.store.getValue().user?.hasUnipileAccount ?? false;
  }

  get isConnectedToUnipile(): boolean {
    return this.hasUnipileAccount && this.store.getValue().unipileAccountStatus === UnipileAccountConnectionStatusEnum.CONNECTED;
  }

  get userPicture() {
    return this.store.getValue().user?.picture;
  }

  get affiliatesItems() {
    const affiliates = this.store.getValue().user?.affiliates?.map((a) =>({
      label: a.affiliate.name,
      value: a.affiliate.id,
      data: a,
    })) ?? [];

    if (this.buyerMode) return affiliates.filter((a) => a.data.role === UserAffiliateRoleEnum.BUYER);

    return affiliates;
  }

  get token() {
    return this.store.getValue().accessToken;
  }

  get role() {
    if (this.store.getValue().clientViewClient) return UserRoleEnum.CLIENT;
    return this.store.getValue().user?.role;
  }

  get buyerMode() {
    return this.store.getValue().buyerMode;
  }

  get clientViewClient() {
    return this.store.getValue().clientViewClient;
  }
}

export const sessionQuery = new SessionQuery();
