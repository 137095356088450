import React, { useEffect, useState } from "react";
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Doughnut } from "react-chartjs-2";
import { Colors } from "@constants/colors.constant";
import { alpha, Box, CircularProgress, Grid, Stack, Tooltip as MuiTooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import { finalize, Observable } from "rxjs";
import { ClientApplicationsDistribution } from "@store/globalStatistics";
import { useSnackbar } from "notistack";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const DataColors = [
  Colors.chart.redDataSet,
  Colors.chart.orangeDataSet,
  Colors.chart.purpleDataSet,
  Colors.chart.pinkDataSet,
  Colors.chart.yellowDataSet,
  Colors.chart.greenDataSet
];

interface AdDistributionChartProps {
  getChartData?: () => Observable<ClientApplicationsDistribution>;
  title: string;
  totalApplications: number;
}

const AdDistributionChart = (props: AdDistributionChartProps) => {
  const { getChartData, title, totalApplications } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<ClientApplicationsDistribution>({});

  useEffect(() => {
    if (!!getChartData) {
      setLoading(true);
      getChartData().pipe(finalize(() => setLoading(false))).subscribe({
        next: setData,
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
    }
  }, [enqueueSnackbar, getChartData]);

  const chartOptions: any = {
    layout: {
      padding: {
        top: 20,
        left: 0,
        bottom: 30
      }
    },
    plugins: {
      datalabels: { display: false },
      legend: {
        display: false,
        position: "bottom"
      },
      title: { display: false },
      tooltip: { enabled: false }
    },
    aspectRatio: 1.6,
    cutout: "80%",
    borderWidth: 5,
    borderColor: Colors.lightBackground,
    hoverBorderColor: Colors.lightBackground
  };

  const labels = Object.keys(data).sort((a, b) => {
    if (a === "Twinin") return -1;
    if (b === "Twinin") return 1;
    return data[b].applicationNbr - data[a].applicationNbr;
  });
  const values = labels.slice(0, 5).map((key) => data[key].applicationNbr ?? 0);
  const others = labels.slice(5).reduce((res, key) => res + (data[key].applicationNbr ?? 0), 0);

  const total = labels.reduce((res, key) => res + (data[key].applicationNbr ?? 0), 0);

  return (
    <Stack direction="column" pb="20px" position="relative">
      <Typography fontSize={20} fontWeight="700" lineHeight={1.25}>{title}</Typography>
      <Stack direction="column" alignItems="center">
        <Stack position="relative" width="100%" alignItems="center">
          <Doughnut
            options={chartOptions}
            plugins={[ChartDataLabels] as any}
            data={{
              labels: labels.slice(0, 5).concat(t("global.others")),
              datasets: [
                {
                  data: values.concat(others),
                  backgroundColor: DataColors,
                  hoverOffset: 2
                }
              ]
            }} />
          <Stack position="absolute" height="100%" justifyContent="center" alignItems="center">
            <Typography fontSize="40px" color={Colors.black} fontWeight={500} lineHeight={1}>
              {total}
            </Typography>
            <Typography fontSize="12px" color={Colors.grey} fontWeight={300}>
              {t("charts.applications", { count: total })}
            </Typography>
          </Stack>
        </Stack>
        <Box>
          <Grid container direction="row" spacing={1}>
            {values.map((value, index) => (
              <React.Fragment key={index}>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <Stack direction="row" alignItems="center" spacing={.5}>
                    <Brightness1Icon sx={{ fontSize: 11, color: DataColors[index] }} />
                    <Typography fontSize="12px" fontWeight="500">
                      {labels.slice(0, 5).concat(t("global.others"))[index]}
                    </Typography>
                    <Typography fontSize="12px" fontWeight="500" color={Colors.grey}>({value})</Typography>
                  </Stack>
                </Grid>
              </React.Fragment>
            ))}
            {labels.length > 5 && (
              <React.Fragment>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <Stack direction="row" alignItems="center" spacing={.5}>
                    <Brightness1Icon sx={{ fontSize: 11, color: DataColors[5] }} />
                    <Typography fontSize="12px" fontWeight="500">
                      {t("global.others")}
                    </Typography>
                    <Typography fontSize="12px" fontWeight="500" color={Colors.grey}>({others})</Typography>
                    <MuiTooltip
                      title={(
                        <Stack spacing={0.25}>
                          {labels.slice(5).map((label) => (
                            <Stack direction="row" alignItems="center" spacing={0.5} key={label}>
                              <Typography fontSize={12} fontWeight="500">
                                {label} :
                              </Typography>
                              <Typography fontSize={12} fontWeight="300">
                                {data[label].applicationNbr}
                              </Typography>
                            </Stack>
                          ))}
                        </Stack>
                      )}
                      arrow
                      placement="top">
                      <img alt="information" src="/images/icon_informations.svg" style={{ height: 14, width: 14 }} />
                    </MuiTooltip>
                  </Stack>
                </Grid>
              </React.Fragment>
            )}
          </Grid>
        </Box>
      </Stack>
      {loading && (
        <Stack
          position="absolute"
          bgcolor={alpha(Colors.background, 0.5)}
          height="calc(100% - 10px)"
          width="100%"
          justifyContent="center"
          alignItems="center">
          <CircularProgress size={40} />
        </Stack>
      )}
    </Stack>
  );
};

export default AdDistributionChart;
