import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/material";
import { finalize } from "rxjs";

import { adsService } from "@store/ads";
import { jobBoardsQuery } from "@store/jobBoards";

import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import { SelectItem } from "@components/input/Select.component";
import { affiliateDetailsQuery } from "@store/entities/afiliates/details";

interface BatchAssignJobBoardsModalProps {
  entityId: string;
  handleClose: () => void;
}

const BatchAssignJobBoardsModal = (props: BatchAssignJobBoardsModalProps) => {
  const { entityId, handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [jobBoards] = useState<SelectItem[]>(
    (affiliateDetailsQuery.prefilteredJobBoards.length ? affiliateDetailsQuery.prefilteredJobBoards : jobBoardsQuery.jobBoards).map((j) => ({
      label: j.name,
      value: j.id,
    }))
  );

  const [loading, setLoading] = useState(false);

  const [references, setReferences] = useState("");

  const [selectedJobBoards, setSelectedJobBoards] = useState<SelectItem[]>([]);

  const handleChangeReferences = (value: string) => {
    setReferences(value);
  };

  const handleAssign = () => {
    setLoading(true);
    const extractedReferences = references.replace(/\s/g, "").split(",");
    adsService.updateAdsDiffusionList(extractedReferences, selectedJobBoards, entityId)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (result) => {
          enqueueSnackbar(t("clients.details.success.diffusionUpdated", { count: result.modifiedCount }), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <ModalComponent
      maxWidth="xs"
      titleLeft
      title={t("clients.details.parametersTab.jobBoardsAssign.title")}
      handleClose={handleClose}
      content={(
        <Stack px="30px" spacing={3} py="25px" alignItems="stretch" width="100%">
          <MultiSelectWithSearchComponent
            placeholder={t("clients.details.parametersTab.jobBoardsAssign.noJobBoard")}
            handleChange={setSelectedJobBoards}
            multiSelectedLabel={(count) => t("clients.details.parametersTab.jobBoardsAssign.jobBoardsNb", { count })}
            items={jobBoards}
            values={selectedJobBoards} />
          <AIOTextfieldComponent
            title={t("clients.details.parametersTab.jobBoardsAssign.adReferences")}
            placeholder={t("clients.details.parametersTab.jobBoardsAssign.adReferencesFormat")}
            onChange={handleChangeReferences}
            value={references}
            fullWidth />
        </Stack>
      )}
      actions={(
        <AIOButtonComponent
          title={t("global.assign")}
          ariaLabel="modal"
          disabled={loading || selectedJobBoards.length === 0 || references.length === 0}
          onClick={handleAssign}
          variant="contained"
          color="secondary" />
      )} />
  );
};

export default BatchAssignJobBoardsModal;
