import { createRequestDataSource } from "@ngneat/elf-requests";

import { affiliatesStore } from "@store/entities/afiliates/affiliates.store";
import { selectAllEntities } from "@ngneat/elf-entities";

export const getAffiliatesDataSource = createRequestDataSource({
  store: affiliatesStore,
  data$: () => affiliatesStore.pipe(selectAllEntities()),
  requestKey: "getAffiliates",
  dataKey: "affiliates",
});
