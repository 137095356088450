import { catchError, from, map, Observable, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";

import AIOAPIAxios, { AIOAPIRoutes } from "@api/aio-axios.api";
import SnackError from "@utils/error.utils";
import { companiesStore } from "./companies.store";
import { BanStatusEnum, CompaniesFilters, CompanyLight, CompanySortEnum, CompanySortModel, ReattachStatusEnum } from "./companies.model";
import { addEntities, deleteAllEntities, getEntitiesIds, updateEntities } from "@ngneat/elf-entities";
import { PaginatedData } from "@utils/infinitescroll.utils";
import { deleteAllPages, setPage, updatePaginationData } from "@ngneat/elf-pagination";
import { getCompaniesDataSource } from "./companies.requests";
import { SelectItem } from "@components/input/Select.component";

export class CompaniesService {
  store = companiesStore;

  resetStore = () => this.store.reset();
  deleteCompanies = () => this.store.update(deleteAllEntities());
  deleteAllPages = () => this.store.update(deleteAllPages());

  updateCompanyReattached = (companyId: string, affiliateId: string) => {
    this.store.update(updateEntities(companyId, { affiliateId }));
  };

  updateCompanyBanned = (companyId: string, banned: boolean) => {
    this.store.update(updateEntities(companyId, { banned }));
  };

  setFilters = (filters: Partial<CompaniesFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  setSort = (sort: Partial<CompanySortModel>) =>
    this.store.update((state) => ({
      ...state,
      sort: {
        ...state.sort,
        ...sort,
      },
    }));

  getCompanies = (
    filters?: CompaniesFilters,
    sort?: CompanySortModel,
    page: number = 1,
    take: number = 24
  ): Observable<PaginatedData<CompanyLight[]>> => {
    return from(
      AIOAPIAxios({
        ...AIOAPIRoutes.GETCompanies(),
        params: {
          search: filters?.search || undefined,
          reattach:
            filters?.reattach === ReattachStatusEnum.REATTACHED ? true : filters?.reattach === ReattachStatusEnum.NOT_REATTACHED ? false : undefined,
          banned: filters?.banned === BanStatusEnum.BANNED ? true : filters?.banned === BanStatusEnum.NOT_BANNED ? false : undefined,
          sort: sort?.field,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<CompanyLight[]>>) => {
        return response.data;
      }),
      tap((companies) => {
        this.store.update(
          addEntities(companies.data.filter((company) => !this.store.query(getEntitiesIds()).includes(company.id))),
          updatePaginationData({
            currentPage: companies.meta.page,
            perPage: companies.meta.take,
            total: companies.meta.itemCount,
            lastPage: companies.meta.pageCount,
          }),
          setPage(
            companies.meta.page,
            companies.data.map((company) => company.id)
          ),
          getCompaniesDataSource.setSuccess()
        );
      }),
      getCompaniesDataSource.trackRequestStatus()
    );
  };

  searchCompanies = async (search?: string): Promise<SelectItem[]> => {
    try {
      const response: AxiosResponse<PaginatedData<CompanyLight[]>> = await AIOAPIAxios({
        ...AIOAPIRoutes.GETCompanies(),
        params: {
          search: search ?? undefined,
          sort: CompanySortEnum.NAME,
          page: 1,
          take: 10,
        },
      });
      return response.data.data.map((e) => ({ label: e.name, value: e.id, data: e }));
    } catch (err: any) {
      return [];
    }
  };

  getNotReattachedCompaniesWithApplicationsCount = (): Observable<number> => {
    return from(AIOAPIAxios(AIOAPIRoutes.GETNotReattachedCompaniesWithApplicationsCount())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<number>) => {
        return response.data;
      }),
      tap((count) => {
        this.store.update((state) => ({
          ...state,
          notReattachedWithCandidatesCount: count,
        }));
      })
    );
  };

  getNotReattachedCompaniesWithApplications = (): Observable<CompanyLight[]> => {
    return from(AIOAPIAxios(AIOAPIRoutes.GETNotReattachedCompaniesWithApplications())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<CompanyLight[]>) => {
        return response.data;
      }),
      tap((companies) => {
        this.store.update((state) => ({
          ...state,
          notReattachedWithCandidatesCount: companies.length,
        }));
      })
    );
  };
}

export const companiesService = new CompaniesService();
