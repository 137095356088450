import ModalComponent from "@components/modal/Modal.component";
import { Colors } from "@constants/colors.constant";
import { Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface CallConfirmationModalProps {
  handleConfirmCall: () => void;
  handleClose: () => void;
  recontact?: boolean;
}

const CallConfirmationModal = (props: CallConfirmationModalProps) => {
  const { handleConfirmCall, handleClose, recontact } = props;
  const { t } = useTranslation();
  return (
    <ModalComponent
      maxWidth={"xs"}
      handleClose={handleClose}
      content={
        <Stack spacing={3} alignItems={"center"}>
          <img width="80px" src="/images/img_ia_phone.png" alt="phone ia" />
          <Typography fontSize={"20px"} fontWeight={"700"} textAlign="center" alignSelf="center">
            {t("ads.details.applyTab.aiCalls.toBeCalledByAi")}
          </Typography>
          <Typography fontSize={"14px"} fontWeight={"400"} textAlign="center" alignSelf="center" color={Colors.lightGrey}>
            {recontact ? t("ads.details.applyTab.aiCalls.recontact") : t("ads.details.applyTab.aiCalls.confirmCall", { count: 1 })}
          </Typography>
        </Stack>
      }
      actions={
        <Button variant="contained" color="secondary" onClick={handleConfirmCall}>
          {t("global.confirm")}
        </Button>
      }
    />
  );
};

export default CallConfirmationModal;
