import { SelectItem } from "@components/input/Select.component";
import I18nUtils from "@utils/i18n.utils";
import WheelChair from "@mui/icons-material/Accessible";
import Infinity from "@mui/icons-material/AllInclusive";
import Microscope from "@mui/icons-material/Biotech";
import Trophee from "@mui/icons-material/EmojiEvents";
import Heart from "@mui/icons-material/FavoriteBorder";
import Fingerprint from "@mui/icons-material/Fingerprint";
import Flower from "@mui/icons-material/LocalFlorist";
import Medal from "@mui/icons-material/MilitaryTech";
import People from "@mui/icons-material/People";
import Recycling from "@mui/icons-material/Recycling";
import Eye from "@mui/icons-material/RemoveRedEye";
import SignLanguage from "@mui/icons-material/SignLanguage";
import Sport from "@mui/icons-material/SportsBasketball";
import Star from "@mui/icons-material/StarBorder";
import Volunteer from "@mui/icons-material/VolunteerActivism";
import { Colors } from "@constants/colors.constant";

interface ValuesIconsProps {
  label: string;
  icon: any;
}

export const ValuesIcons: ValuesIconsProps[] = [
  { label: "wheelchair", icon: WheelChair },
  { label: "infinity", icon: Infinity },
  { label: "microscope", icon: Microscope },
  { label: "trophee", icon: Trophee },
  { label: "heart", icon: Heart },
  { label: "fingerprint", icon: Fingerprint },
  { label: "flower", icon: Flower },
  { label: "medal", icon: Medal },
  { label: "people", icon: People },
  { label: "recycling", icon: Recycling },
  { label: "eye", icon: Eye },
  { label: "signLanguage", icon: SignLanguage },
  { label: "sport", icon: Sport },
  { label: "star", icon: Star },
  { label: "volunteer", icon: Volunteer },
];

export function getValueIcon(label: string) {
  switch (label) {
    case "wheelchair":
      return <WheelChair style={{ fontSize: "20px", color: Colors.primary }} />;
    case "infinity":
      return <Infinity style={{ fontSize: "20px", color: Colors.primary }} />;
    case "microscope":
      return <Microscope style={{ fontSize: "20px", color: Colors.primary }} />;
    case "trophee":
      return <Trophee style={{ fontSize: "20px", color: Colors.primary }} />;
    case "heart":
      return <Heart style={{ fontSize: "20px", color: Colors.primary }} />;
    case "fingerprint":
      return <Fingerprint style={{ fontSize: "20px", color: Colors.primary }} />;
    case "flower":
      return <Flower style={{ fontSize: "20px", color: Colors.primary }} />;
    case "medal":
      return <Medal style={{ fontSize: "20px", color: Colors.primary }} />;
    case "people":
      return <People style={{ fontSize: "20px", color: Colors.primary }} />;
    case "recycling":
      return <Recycling style={{ fontSize: "20px", color: Colors.primary }} />;
    case "eye":
      return <Eye style={{ fontSize: "20px", color: Colors.primary }} />;
    case "signLanguage":
      return <SignLanguage style={{ fontSize: "20px", color: Colors.primary }} />;
    case "sport":
      return <Sport style={{ fontSize: "20px", color: Colors.primary }} />;
    case "star":
      return <Star style={{ fontSize: "20px", color: Colors.primary }} />;
    case "volunteer":
      return <Volunteer style={{ fontSize: "20px", color: Colors.primary }} />;
    default:
      return <Star style={{ fontSize: "20px", color: Colors.primary }} />;
  }
}

export enum CompanySortEnum {
  NAME = "name",
  LOCATION = "location",
}

export namespace CompanySortEnumFunctions {
  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("aio.clients.sort.byName"), value: CompanySortEnum.NAME },
    { label: I18nUtils.t("aio.clients.sort.byLocation"), value: CompanySortEnum.LOCATION },
  ];
}

export interface CompanySortModel {
  field: CompanySortEnum;
}

export enum ReattachStatusEnum {
  REATTACHED = "REATTACHED",
  NOT_REATTACHED = "NOT_REATTACHED",
}

export enum BanStatusEnum {
  BANNED = "BANNED",
  NOT_BANNED = "NOT_BANNED",
}

export interface CompaniesFilters {
  search?: string;
  reattach?: ReattachStatusEnum;
  banned?: BanStatusEnum;
}

export namespace CompaniesFiltersFunctions {
  export const stateSelectItems: SelectItem[] = [
    { label: I18nUtils.t("aio.clients.state.reattached"), value: ReattachStatusEnum.REATTACHED },
    { label: I18nUtils.t("aio.clients.state.notReattached"), value: ReattachStatusEnum.NOT_REATTACHED },
    { label: I18nUtils.t("aio.clients.banned"), value: BanStatusEnum.BANNED },
    { label: I18nUtils.t("aio.clients.notBanned"), value: BanStatusEnum.NOT_BANNED },
  ];
}

export interface CompanyLight {
  id: string;
  name: string;
  logo?: string;
  description?: string;
  location: string;
  affiliateId?: string;
  banned?: boolean;
}
