import { catchError, from, map, Observable, switchMap, tap } from "rxjs";
import { boostsStore } from "./boosts.store";
import { BoostStatus, BoostType } from "./boosts.model";
import APIAxios, { APIRoutes } from "@api/axios.api";
import { AxiosError, AxiosResponse } from "axios";
import SnackError from "@utils/error.utils";
import { AdDetailsDetailsModel, adDetailsService } from "@store/ads/details";
import { BoostFilters, BoostModel } from "@store/boosts/index";
import { PaginatedData } from "@utils/infinitescroll.utils";
import { sessionQuery } from "@store/session";
import { addEntities, deleteAllEntities, getEntitiesIds } from "@ngneat/elf-entities";
import { deleteAllPages, setPage, updatePaginationData } from "@ngneat/elf-pagination";
import { getBoostsDataSource } from "@store/boosts/boosts.requests";
import { BoostDetailsDetailsModel } from "./details";

export class BoostsService {
  store = boostsStore;

  resetStore = () => this.store.reset();
  deleteEntities = () => this.store.update(deleteAllEntities());
  deleteAllPages = () => this.store.update(deleteAllPages());

  setFilters = (filters: Partial<BoostFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getBoosts = (filters?: BoostFilters, page: number = 1, take: number = 24): Observable<PaginatedData<BoostModel[]>> => {
    const affiliateIds = filters?.affiliateIds
      ? filters.affiliateIds.map((c) => c.value)
      : sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined;

    return from(
      APIAxios({
        ...APIRoutes.GETBoosts(),
        params: {
          search: filters?.search || undefined,
          type: filters?.type || undefined,
          state: filters?.status || undefined,
          year: filters?.year || undefined,
          affiliateIds,
          favorite: sessionQuery.affiliateIds?.some((c) => c.value === "FAVORITES") || undefined,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<BoostModel[]>>) => {
        return response.data;
      }),
      tap((boosts) => {
        this.store.update(
          addEntities(boosts.data.filter((boost) => !this.store.query(getEntitiesIds()).includes(boost.id))),
          updatePaginationData({
            currentPage: boosts.meta.page,
            perPage: boosts.meta.take,
            total: boosts.meta.itemCount,
            lastPage: boosts.meta.pageCount,
          }),
          setPage(
            boosts.meta.page,
            boosts.data.map((boost) => boost.id)
          ),
          getBoostsDataSource.setSuccess()
        );
      }),
      getBoostsDataSource.trackRequestStatus()
    );
  };

  getBuyerBoosts = (filters?: BoostFilters, page: number = 1, take: number = 24): Observable<PaginatedData<BoostModel[]>> => {
    return from(
      APIAxios({
        ...APIRoutes.GETBuyerBoosts(),
        params: {
          affiliateIds: filters?.affiliateIds ? filters.affiliateIds.map((c) => c.value) : undefined,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<BoostModel[]>>) => {
        return response.data;
      }),
      tap((boosts) => {
        this.store.update(
          addEntities(boosts.data),
          updatePaginationData({
            currentPage: boosts.meta.page,
            perPage: boosts.meta.take,
            total: boosts.meta.itemCount,
            lastPage: boosts.meta.pageCount,
          }),
          setPage(
            boosts.meta.page,
            boosts.data.map((boost) => boost.id)
          ),
          getBoostsDataSource.setSuccess()
        );
      }),
      getBoostsDataSource.trackRequestStatus()
    );
  };

  createTwininBoostRequest = (adId: string | number): Observable<AdDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTBoostRequest(adId),
        data: { type: BoostType.TWININ },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => adDetailsService.getAdDetails(adId))
    );
  };

  createManualBoostRequest = (
    adId: string | number,
    sequences: string[],
    jobBoards: string[],
    boostActions: string[],
    schools: string[]
  ): Observable<AdDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTBoostRequest(adId),
        data: {
          type: BoostType.MANUAL,
          data: {
            sequenceIds: sequences,
            jobBoardIds: jobBoards,
            boostActionIds: boostActions,
            schoolIds: schools,
          },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => adDetailsService.getAdDetails(adId))
    );
  };

  getBoostProgress = (boost: BoostDetailsDetailsModel) => {
    let progress = 1;
    if (boost.state === BoostStatus.VALIDATED) progress = 2;
    else if (boost.state === BoostStatus.FINISHED) progress = 3;
    return progress;
  };
}

export const boostsService = new BoostsService();
