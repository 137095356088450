import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { Personality } from "./personalities.model";

interface personalitiesProps {
  title: string;
}

export const personalitiesStore = createStore(
  { name: "personalities" },
  withProps<personalitiesProps>({
    title: "",
  }),
  withEntities<Personality>(),
  withRequestsStatus()
);
