import { alpha, Avatar, Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";

import { useTranslation } from "react-i18next";
import { Colors } from "@constants/colors.constant";
import { sessionQuery, sessionService } from "@store/session";
import { useObservable } from "@ngneat/react-rxjs";
import { Connector, connectorsQuery, connectorsService } from "@store/connectors";
import { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { subscriptionsQuery, SubscriptionTypeEnum } from "@store/subscriptions";
import { UserRoleEnum } from "@store/users";
import ConnectorCard from "@components/card/Connector.card";
import ConnectorsOnboardingModal from "./modals/ConnectorsOnboarding.modal";
import { OnboardingTypeEnum } from "@store/common/onboarding.model";

const Connectors = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [user] = useObservable(sessionQuery.user$);
  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const isFreemium = !!user && !subscriptionsQuery.subscriptionOngoing(user);

  const [{ connectors: activatedConnectors }] = useObservable(connectorsQuery.connectors$);

  const [loading, setLoading] = useState(true);
  const [openOnboarding, setOpenOnboarding] = useState(!user?.savedOnboardings?.includes(OnboardingTypeEnum.CONNECTORS_ENTRY));

  useEffect(() => {
    if (!user && !clientViewClient) return;

    setLoading(true);

    if (clientViewClient) {
      connectorsService.getAffiliateConnectors(clientViewClient.id).subscribe({
        next: () => setLoading(false),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    } else {
      connectorsService.getUserAffiliatesConnectors().subscribe({
        next: () => setLoading(false),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [enqueueSnackbar, user, clientViewClient]);

  const handeCloseOnboarding = (dontShowAgain: boolean) => {
    setOpenOnboarding(false);
    if (dontShowAgain) {
      sessionService.saveOnboarding(OnboardingTypeEnum.CONNECTORS_ENTRY).subscribe();
    }
  };

  const affiliates = clientViewClient ? [clientViewClient] : user?.affiliates ? user.affiliates.map(({ affiliate }) => affiliate) : [];

  return (
    <Stack pt={breakpointDownSM ? "10px" : "60px"} px={breakpointDownSM ? "0px" : "30px"} spacing={1} flex={1} width="100%" overflow="auto">
      <Stack alignItems="flex-start" justifyContent="space-between" width={breakpointDownSM ? "100%" : undefined} px="10px">
        <Typography fontSize={breakpointDownSM ? "30px" : "35px"} fontWeight={700}>
          {t("connectors.title")}
        </Typography>

        <Stack marginTop="10px" spacing="10px">
          <Typography fontSize="14px" fontWeight="500" color={Colors.secondaryText} whiteSpace="pre-line">
            {t("connectors.subtitle")}
          </Typography>
        </Stack>
      </Stack>
      <Stack alignItems="center" width="100%" pt="30px" overflow="auto" px="10px" className="scrollable">
        <Stack width="100%" spacing={5}>
          {loading ? (
            <Stack width="100%" alignItems="center">
              <CircularProgress size={30} />
            </Stack>
          ) : (
            affiliates.map((affiliate) => (
              <Stack spacing={4} key={affiliate.id}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Avatar
                    sx={{
                      border: "none",
                      borderRadius: "10px !important",
                      backgroundColor: affiliate.picture ? Colors.white : alpha(Colors.lightpurple, 0.6),
                      color: Colors.secondary,
                      height: 53,
                      width: 53,
                    }}
                    src={affiliate.picture}>
                    {affiliate.name[0]}
                  </Avatar>
                  <Typography fontWeight={500} fontSize="22px">
                    {affiliate.name}
                  </Typography>
                </Stack>

                <Box>
                  <Grid container spacing={3} columns={10} justifyContent={breakpointDownSM ? "center" : undefined}>
                    {Connector.getConnectorsMetaData()
                      .filter((connectorMetadata) => {
                        if (user?.role === UserRoleEnum.ADMIN) return true;

                        const currentSubscription = subscriptionsQuery.affiliateSubscriptionOnGoing(affiliate)
                          ? SubscriptionTypeEnum.COMPANY
                          : isFreemium
                          ? SubscriptionTypeEnum.FREEMIUM
                          : SubscriptionTypeEnum.PROFESSIONAL;
                        return connectorMetadata.subscriptionType.includes(currentSubscription);
                      })
                      .map((connectorMetaData, index) => (
                        <Grid key={index} item>
                          <ConnectorCard
                            affiliateId={affiliate.id}
                            connector={activatedConnectors?.find(
                              (connector) => connectorMetaData.media === connector.media && connector.affiliate.id === affiliate.id
                            )}
                            connectorMetaData={connectorMetaData}
                          />
                        </Grid>
                      ))}
                  </Grid>
                </Box>
                <Stack spacing={4}></Stack>
              </Stack>
            ))
          )}
        </Stack>
      </Stack>
      {openOnboarding && <ConnectorsOnboardingModal handleClose={handeCloseOnboarding} />}
    </Stack>
  );
};

export default Connectors;
