import React, { useRef, useEffect, ReactNode } from "react";

interface ClickOutsideComponentProps {
  children: ReactNode;
  onClickOutside: () => void;
}

function ClickOutsideComponent(props: ClickOutsideComponentProps) {
  const { children, onClickOutside } = props;
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClickOutside, ref]);

  return (
    <div ref={ref} style={{ position: "relative" }}>
      {children}
    </div>
  );
}

export default ClickOutsideComponent;
