import { Chip, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import { Colors } from "@constants/colors.constant";
import { Box } from "@mui/system";

interface BtnProps {
  title: string;
  isSelected?: boolean;
  onClick: () => void;
}
const CustomButton = (props: BtnProps) => {
  const { title, isSelected, onClick } = props;

  const { t } = useTranslation();

  return (
    <Stack spacing={1} sx={{ position: "relative" }}>
      <Chip
        label={title}
        onClick={onClick}
        sx={{
          background: Colors.prompts.btnBg,
          borderRadius: "7px",
          border: isSelected ? `1px solid ${Colors.primary} !important` : "1px solid transparent ! important",
          transition: "border 0.5s ease",
        }}
      />

      <Stack
        sx={{ position: "absolute", bottom: "-25px", opacity: isSelected ? 1 : 0, transition: "opacity 0.5s ease" }}
        alignSelf="center"
        direction="row"
        alignItems="center"
        spacing="2px">
        <ContentCopyIcon sx={{ width: "11px" }} color="primary" />
        <Typography fontSize="11px" fontWeight={300} color={Colors.primary}>
          {t("aio.prompts.copied")}
        </Typography>
      </Stack>
    </Stack>
  );
};

interface VariablesComponentProps {
  variables: string[];
}

const VariablesComponent = (props: VariablesComponentProps) => {
  const { variables } = props;

  const [selectedVariable, setSelectedVariable] = useState<String>("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setSelectedVariable("");
    }, 1500);
    return () => {
      clearTimeout(timer);
    };
  }, [selectedVariable]);

  const handleBtnClick = (variable: string) => {
    setSelectedVariable(variable);
    navigator.clipboard.writeText(`[${variable}]`).then(() => undefined);
  };

  return (
    <Box>
      <Grid container spacing={1}>
        {variables.map((variable) => (
          <Grid item key={variable}>
            <CustomButton onClick={() => handleBtnClick(variable)} title={variable} isSelected={selectedVariable === variable} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default VariablesComponent;
