import { createStore, withProps } from "@ngneat/elf";
import { initializeAsPending, withRequestsStatus } from "@ngneat/elf-requests";

import { AdDetailsModel } from "@store/ads/details/adDetails.model";

export const adDetailsStore = createStore(
  {name: "ad-details"},
  withProps<AdDetailsModel>({}),
  withRequestsStatus(initializeAsPending('getAdDetailsPotentialProfiles')),
);
