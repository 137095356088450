export class FilesService {
  openUrlInNewTab = (url: string) => {
    if (!url) return;

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  downloadDocument = (data: any, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.setAttribute("download", filename);
    link.click();
    link.parentNode?.removeChild(link);
  };

  previewPDF = (data: any) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: "application/pdf" }));
    window.open(url, "_blank", "noopener,noreferrer");
  };
}

export const filesService = new FilesService();
