import { personalitiesStore } from "../personalities";
import { getTonesDataSource } from "./tones.requests";

export class ToneQuery {
  store = personalitiesStore;

  tone$ = getTonesDataSource.data$();
}

export const tonesQuery = new ToneQuery();
