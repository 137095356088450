import { useMediaQuery, useTheme } from "@mui/material";
export const useResponsive = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only("xs"));
  const isTablet = useMediaQuery(theme.breakpoints.only("sm"));
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const isMobileOrTablet = isMobile || isTablet;

  return { isMobile, isTablet, isDesktop, isMobileOrTablet };
}
