import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { ManagePersonaeQuizAnswer, PersonaeQuizAnswer, PersonaeQuizCategory, personaeService } from "@store/personae";
import { affiliatesService } from "@store/entities/afiliates";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import AIOButtonComponent from "@components/Button.component";
import { SelectItem } from "@components/input/Select.component";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

interface ManageQuizAnswerModalProps {
  answer?: PersonaeQuizAnswer;
  category: PersonaeQuizCategory;
  handleClose: () => void;
  isCreation?: boolean;
}

const ManageQuizAnswerModal = (props: ManageQuizAnswerModalProps) => {
  const { answer: answerProps, category, handleClose, isCreation } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [answer, setAnswer] = useState<ManagePersonaeQuizAnswer>(answerProps ? ManagePersonaeQuizAnswer.fromPersonaeQuizAnswer(answerProps) : {});

  const [affiliates, setAffiliates] = useState<SelectItem[]>([]);

  useEffect(() => {
    affiliatesService.getAffiliates().subscribe({
      next: (af) => setAffiliates(af.data.map((a) => ({ label: a.name, value: a.id}))),
      error: (err) => enqueueSnackbar(err.text, err.options)
    });
  }, [enqueueSnackbar]);

  const updateAnswer = (name: string) => (value: any) => setAnswer((state) => ({ ...state, [name]: value }));

  const handleSave = () => {
    if (isCreation) {
      setLoading(true);
      personaeService.addPersonaeQuizAnswer(answer, category).pipe(finalize(() => setLoading(false))).subscribe({
        next: () => {
          enqueueSnackbar(t("personae.manageQuizAnswer.success.add"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
    } else {
      if (!answer.id) return;
      setLoading(true);
      personaeService.updatePersonaeQuizAnswer(answer.id, answer).pipe(finalize(() => setLoading(false))).subscribe({
        next: () => {
          enqueueSnackbar(t("personae.manageQuizAnswer.success.edit"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
    }
  };

  const errors = {
    name: getFieldError(answer.name, FieldValidationType.REQUIRED_STRING)
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t(`personae.manageQuizAnswer.title.${isCreation ? "add" : "edit"}`)}
      maxWidth="xs"
      content={(
        <Stack py="15px" spacing={3}>
          <AIOTextfieldComponent
            fullWidth
            value={answer.name ?? ""}
            placeholder={t("personae.manageQuizAnswer.option")}
            onChange={updateAnswer("name")}
            error={errors.name} />
          <Box>
            <MultiSelectWithSearchComponent
              handleChange={updateAnswer("affiliates")}
              startIcon={<img alt="" src="/images/icon_select_company.svg" />}
              multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
              placeholder={t("global.filterByCompanies")}
              items={affiliates}
              values={answer.affiliates ?? []} />
          </Box>
        </Stack>
      )}
      actions={(
        <AIOButtonComponent
          variant="contained"
          disabled={loading || checkIfErrors(errors)}
          onClick={handleSave}
          title={t(`global.${isCreation ? "add" : "modify"}`)} />
      )} />
  );
};

export default ManageQuizAnswerModal;
