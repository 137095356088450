import React, { Dispatch, SetStateAction, useState } from "react";
import { alpha, Box, IconButton, Stack, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ManageEntityParametersModel } from "@store/entities/details";
import { ManagePowerBIModel } from "@store/common/powerbi.model";

import { Colors } from "@constants/colors.constant";

import PowerBIAddUrlModal from "@screens/auth/admin/entities/parameters/PowerBIAddUrl.modal";

interface PowerBIConfigComponentProps {
  isActivated?: boolean;
  powerBI?: ManagePowerBIModel[];
  updateParameters: Dispatch<SetStateAction<ManageEntityParametersModel>>;
}

const PowerBIConfigComponent = (props: PowerBIConfigComponentProps) => {
  const { isActivated = false, powerBI, updateParameters } = props;

  const { t } = useTranslation();

  const [openAddLink, setOpenAddLink] = useState(false);

  const addPowerBI = (data: ManagePowerBIModel) => {
    updateParameters((state) => ({
      ...state,
      powerBI: (state.powerBI ?? []).concat(data),
    }));
  };

  const removePowerBI = (index: number) => {
    updateParameters((state) => ({
      ...state,
      powerBI: (state.powerBI ?? []).filter((_, dIndex) => dIndex !== index),
    }));
  };

  return (
    <Stack flex={0.5} width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
        <Typography fontSize={16} fontWeight="700">
          {t("clients.details.parametersTab.powerBI.powerBI")}
        </Typography>
        <Switch
          checked={isActivated}
          disabled={isActivated}
          onChange={(evt, checked) =>
            updateParameters((state) => ({...state, powerBIActivated: checked}))
          }/>
      </Stack>
      {isActivated && (
        <Stack>
          <Stack spacing="20px">
            {(powerBI ?? []).map((p, index) => (
              <Stack key={`Power BI ${index + 1}`}>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" ml="-10px !important">
                  <IconButton onClick={() => removePowerBI(index)}>
                    <img src="/images/icon_remove.svg" alt="" />
                  </IconButton>
                  <Typography fontSize={14} fontWeight="700">
                    {t("clients.details.parametersTab.powerBI.powerBICount", {count: index + 1})}
                  </Typography>
                </Stack>
                <Stack bgcolor={Colors.white} borderRadius="10px" px="20px" pt="15px" pb="20px" spacing="15px">
                  <Stack>
                    <Typography fontSize="12px" color={Colors.secondaryText}>
                      {t("clients.details.parametersTab.powerBI.label")}
                    </Typography>
                    <Box flex={1} bgcolor={alpha(Colors.primary, 0.05)} borderRadius="10px" px="15px" py="8px">
                      <Typography fontSize="14px" fontWeight="300">
                        {p.label}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack>
                    <Typography fontSize="12px" color={Colors.secondaryText}>
                      {t("clients.details.parametersTab.powerBI.url")}
                    </Typography>
                    <Box flex={1} bgcolor={alpha(Colors.primary, 0.05)} borderRadius="10px" px="15px" py="8px">
                      <Typography fontSize="14px" fontWeight="300" sx={{wordBreak: 'break-word'}}>
                        {p.url}
                      </Typography>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            ))}
            <Stack alignItems="center">
              <IconButton onClick={() => setOpenAddLink(true)}>
                <img src="/images/icon_add.svg" alt="" />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      )}
      {openAddLink && (
        <PowerBIAddUrlModal
          handleAdd={(data) => {
            addPowerBI(data);
            setOpenAddLink(false);
          }}
          handleClose={() => setOpenAddLink(false)}/>
      )}
    </Stack>
  );
};

export default PowerBIConfigComponent;
