import React, { useRef, useState } from "react";
import { alpha, Box, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";

import { Colors } from "@constants/colors.constant";

const avatars: string[] = [
  "/images/avatar1.svg", "/images/avatar2.svg", "/images/avatar3.svg",
  "/images/avatar4.svg", "/images/avatar12.svg", "/images/avatar5.svg",
  "/images/avatar6.svg", "/images/avatar10.svg", "/images/avatar11.svg",
  "/images/avatar7.svg", "/images/avatar8.svg", "/images/avatar9.svg"
];

interface AvatarPickerProps {
  disableCustom?: boolean;
  handleClose: () => void;
  handleChangePicture: (file?: File, avatar?: string) => void;
  picture?: string;
  pictureFile?: File;
}

const AvatarPicker = (props: AvatarPickerProps) => {
  const { disableCustom, handleClose, handleChangePicture, picture, pictureFile } = props;

  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);
  const pictureRef = useRef<HTMLButtonElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | undefined>(pictureFile);
  const [selectedAvatar, setSelectedAvatar] = useState<string>(picture && avatars.includes(picture) ? picture : "");

  const handleValidated = () => {
    handleChangePicture(selectedFile, selectedAvatar);
    handleClose();
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      setSelectedFile(files[0]);
      setSelectedAvatar("");
      e.target.value = "";
    }
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("account.modifyAvatar")}
      fullWidth={false}
      maxWidth={false}
      content={(
        <Grid container columns={10} width="625px" rowSpacing={2.5} mt="15px">
          {!disableCustom && (
            <Grid item xs={2}>
              <input ref={inputRef} hidden onChange={handleSelectFile} accept="image/*" type="file" />
              <IconButton
                ref={pictureRef}
                aria-label="avatar-rounded"
                onClick={() => inputRef.current?.click()}
                sx={{
                  background: !selectedAvatar && (selectedFile || picture) ? `linear-gradient(${alpha(Colors.black, 0.4)}, ${alpha(Colors.black, 0.4)}), url(${selectedFile ? URL.createObjectURL(selectedFile) : picture})` : "",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  m: "auto"
                }}>
                <img alt="pick" src="/images/camera.svg" />
              </IconButton>
            </Grid>
          )}
          {avatars.map((avatar, idx) =>
            <Grid item key={`avatar ${idx}`} xs={2}>
              <IconButton
                aria-label="avatar-rounded"
                sx={{
                  background: selectedAvatar === avatar ? `linear-gradient(${alpha(Colors.black, 0.4)}, ${alpha(Colors.black, 0.4)}), url(${avatar})` : `url(${avatar})`,
                  m: "auto"
                }}
                onClick={() => {
                  setSelectedAvatar(avatar);
                  setSelectedFile(undefined);
                }}>
                {selectedAvatar === avatar && (
                  <img alt="selected" src="/images/icon_check_white.svg" style={{ height: 35, width: 35 }} />
                )}
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
      actions={(
        <Box my="20px">
          <AIOButtonComponent
            variant="contained"
            aria-label="modal"
            disabled={!selectedAvatar && !selectedFile}
            onClick={handleValidated}
            title={t("global.modify")} />
        </Box>
      )} />
  );
};

export default AvatarPicker;
