import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripePaymentElementOptions } from "@stripe/stripe-js";
import { CircularProgress, styled } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { getAppCurrency } from "@store/common/country.model";

const StyledFormContainer = styled("div")`
  #root {
    display: flex;
    align-items: center;
  }

  body {
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
  }

  form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: none;
    border-radius: 7px;
  }

  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }

  #payment-element {
    margin-bottom: 24px;
  }

  /* Buttons and links */
  button {
    background: ${Colors.twinin};
    border: none;
    border-radius: 15px;
    box-shadow: none;
    color: ${Colors.white};
    font-size: 16px;
    font-family: "YoutubeSans", sans-serif;
    font-weight: 600;
    line-height: 1.25;
    padding: 16px;
    transition: all 0.2s ease;
    width: 100%;
  }

  button:hover {
    filter: contrast(115%);
  }

  button:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
    }
  }
`;

interface StripeCheckoutFormProps {
  price: number;
  setHasError: (hasError: string | false) => void;
  returnUrl?: string;
}

const StripeCheckoutForm = (props: StripeCheckoutFormProps) => {
  const { price, setHasError, returnUrl } = props;

  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    setLoading(true);

    if (price === 0) {
      const {error} = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: returnUrl || window.location.href,
        },
      })
      setHasError(error?.message ?? false);
    } else {
      const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: returnUrl || window.location.href,
        },
      });
      setHasError(error?.message ?? false);
    }

    setLoading(false);
  };

  const paymentElementOptions: StripePaymentElementOptions = {
    layout: "tabs",
    paymentMethodOrder: ["card"],
  };

  return (
    <StyledFormContainer>
      <form id="payment-form" onSubmit={handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button disabled={loading || !stripe || !elements} id="submit" style={{ cursor: "pointer" }}>
          {loading ? <CircularProgress size={16} sx={{ color: Colors.white }} /> : `${t("stripe.payNow")} (${(price / 100).toFixed(2)}${getAppCurrency()})`}
        </button>
      </form>
    </StyledFormContainer>
  );
};

export default StripeCheckoutForm;
