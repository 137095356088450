import React, { Dispatch, SetStateAction, useState } from "react";
import { alpha, Box, IconButton, Stack, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { ManageEntityParametersModel } from "@store/entities/details";
import { ManageBoostCreditsModel } from "@store/common/boostCredits.model";

import { Colors } from "@constants/colors.constant";

import BoostCreditsAddCreditsModal from "./BoostCreditsAddCredits.modal";

interface BoostCreditsConfigComponentProps {
  isActivated?: boolean;
  boostCredits?: ManageBoostCreditsModel[];
  updateParameters: Dispatch<SetStateAction<ManageEntityParametersModel>>;
}

const BoostCreditsConfigComponent = (props: BoostCreditsConfigComponentProps) => {
  const { boostCredits, isActivated = false, updateParameters } = props;

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [openAddCredits, setOpenAddCredits] = useState(false);

  const addBoostCredits = (data: ManageBoostCreditsModel) => {
    updateParameters((state) => {
      const boostCreditExist = state.boostCredits?.find((bc) => !!bc.boostItem && bc.boostItem.value === data.boostItem!.value);

      return {
        ...state,
        boostCredits: boostCreditExist
          ? (state.boostCredits ?? []).map((bc) => bc.boostItem?.value === data.boostItem!.value
            ? {...bc, credits: (bc.credits ?? 0) + (data.credits ?? 0)}
            : bc
          )
          : (state.boostCredits ?? []).concat(data),
      };
    });
  };

  const removeBoostCredits = (boostCreditToRemove: ManageBoostCreditsModel) => {
    if (boostCreditToRemove.creditsWaitingForValidation && boostCreditToRemove.creditsWaitingForValidation > 0) {
      return enqueueSnackbar(t("errors.cannotDeleteCreditsWaitingForValidation"), { variant: "warning" });
    }

    updateParameters((state) => ({
      ...state,
      boostCredits: (state.boostCredits ?? []).filter((boostCredit) => !!boostCredit.boostItem && boostCredit.boostItem.value !== boostCreditToRemove.boostItem!.value),
    }));
  };

  return (
    <Stack flex={0.5} width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
        <Typography fontSize={16} fontWeight="700">
          {t("clients.details.parametersTab.boostCredits.boostMyself")}
        </Typography>
        <Switch
          checked={isActivated}
          disabled={isActivated}
          onChange={(_, checked) => updateParameters((state) => ({ ...state, boostCreditsActivated: checked }))}
        />
      </Stack>
      {isActivated && (
        <Stack>
          <Stack spacing="20px">
            {(boostCredits ?? []).map((b, index) =>
              b.type && b.boostItem ? (
                <Stack key={`Boost Credits ${index + 1}`}>
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" ml="-10px !important">
                    <IconButton onClick={() => removeBoostCredits(b)}>
                      <img src="/images/icon_remove.svg" alt="" />
                    </IconButton>
                    <Typography fontSize={14} fontWeight="700">
                      {b.boostItem.label}
                    </Typography>
                  </Stack>
                  <Stack bgcolor={Colors.white} borderRadius="10px" px="20px" pt="15px" pb="20px" spacing="15px">
                    <Stack>
                      <Typography fontSize="12px" color={Colors.secondaryText}>
                        {t("clients.details.parametersTab.boostCredits.credits")}
                      </Typography>
                      <Box flex={1} bgcolor={alpha(Colors.primary, 0.05)} borderRadius="10px" px="15px" py="8px">
                        <Typography fontSize="14px" fontWeight="300">
                          {b.credits}
                          {!!b.creditsWaitingForValidation && b.creditsWaitingForValidation > 0 && (
                            <Typography fontSize="14px" fontWeight="300" component="span" fontStyle="italic">
                              {` ${t("clients.details.parametersTab.boostCredits.creditsWaitingForValidation", { count: b.creditsWaitingForValidation })}`}
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
              ) : null
            )}
            <Stack alignItems="center">
              <IconButton onClick={() => setOpenAddCredits(true)}>
                <img src="/images/icon_add.svg" alt="" />
              </IconButton>
            </Stack>
          </Stack>
        </Stack>
      )}
      {openAddCredits && (
        <BoostCreditsAddCreditsModal
          handleAdd={(data) => {
            addBoostCredits(data);
            setOpenAddCredits(false);
          }}
          handleClose={() => setOpenAddCredits(false)}
        />
      )}
    </Stack>
  );
};

export default BoostCreditsConfigComponent;
