import { getConnectorsDataSource } from "./connectors.requests";
import { connectorsStore } from "./connectors.store";

export class ConnectorsQuery {
  store = connectorsStore;

  connectors$ = getConnectorsDataSource.data$();
}

export const connectorsQuery = new ConnectorsQuery();
