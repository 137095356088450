import React from "react";
import { Stack } from "@mui/material";

import { AffiliateSubscription } from "@store/entities/afiliates/details";
import { subscriptions, SubscriptionTypeEnum } from "@store/subscriptions";

import ModalComponent from "@components/modal/Modal.component";
import CompanySubscriptionCard from "@components/card/subscriptions/CompanySubscription.card";

interface CompanySubscriptionDetailsModalProps {
  affiliateSubscriptions: AffiliateSubscription[];
  handleClose: () => void;
}

const CompanySubscriptionDetailsModal = (props: CompanySubscriptionDetailsModalProps) => {
  const {affiliateSubscriptions, handleClose} = props;

  return (
    <ModalComponent
      handleClose={handleClose}
      title="Offres « Entreprise » en cours"
      titleLeft
      maxWidth="md"
      content={(
        <Stack py="30px" alignItems="center" spacing="20px">
          {affiliateSubscriptions.map((subscription, index) => (
              <CompanySubscriptionCard
                key={`subscription - ${index + 1}`}
                affiliateSubscription={subscription}
                subscription={subscriptions.find((s) => s.type === SubscriptionTypeEnum.COMPANY)}
              />
            ))}
        </Stack>
      )}
    />
  );
};

export default CompanySubscriptionDetailsModal;
