import { Colors } from "@constants/colors.constant";
import { Stack } from "@mui/system";

interface BackToTopButtonProps {
  onClick: () => void;
  posRight: string;
}

const BackToTopButton = (props: BackToTopButtonProps) => {
  const { onClick, posRight } = props;
  return (
    <Stack
      sx={{
        p: "10px",
        height: "34px",
        bgcolor: Colors.lightPink,
        borderRadius: "15px",
        position: "fixed",
        bottom: "20px",
        right: posRight,
        cursor: "pointer",
      }}
      justifyContent="center"
      alignItems="center"
      onClick={onClick}>
      <img width="18px" src="/images/arrow_up.svg" alt="back to top arrow" />
    </Stack>
  );
};

export default BackToTopButton;
