import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { Colors } from "@constants/colors.constant";

interface ModalComponentProps {
  actions?: JSX.Element | JSX.Element[];
  content?: JSX.Element | JSX.Element[];
  customHeader?: boolean;
  icon?: JSX.Element;
  handleClose: () => void;
  fullWidth?: boolean;
  contentHeight?: string;
  paddingButton?: string;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
  scroll?: "body" | "paper";
  stepper?: { step: number, totalStep: number };
  titleLeft?: boolean;
  title?: string;
  subtitle?: string;
  ariaLabel?: string;
  textAlign?: "center" | "inherit" | "justify" | "left" | "right";
  backgroundColor?: string;
}

const ModalComponent = (props: ModalComponentProps) => {
  const {
    actions,
    content,
    customHeader,
    fullWidth,
    handleClose,
    contentHeight,
    titleLeft,
    ariaLabel,
    textAlign = "center",
    icon,
    maxWidth = "md",
    scroll = "paper",
    stepper,
    paddingButton,
    title,
    subtitle,
    backgroundColor = Colors.white
  } = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Dialog
      open={true}
      maxWidth={maxWidth}
      aria-label={ariaLabel}
      fullWidth={fullWidth === undefined ? true : fullWidth}
      scroll={scroll}
      PaperProps={{
        style: {
          borderRadius: 15,
          margin: breakpointDownSM ? "8px" : undefined,
          width: breakpointDownSM ? "100%" : undefined,
          backgroundColor: backgroundColor
        }
      }}
      onClose={handleClose}>
      {!!stepper && (
        <Stack
          direction="row"
          sx={{ width: "100%", height: "6px", backgroundColor: "#00022F0F", position: "aboslute", top: "0px" }}>
          <Stack sx={{ backgroundColor: "#00022F", width: `${((stepper.step + 1) / stepper.totalStep) * 100}%` }} />
        </Stack>
      )}
      <DialogTitle
        sx={
          customHeader
            ? {
              backgroundImage: `url(/images/background_campaign.svg)`,
              backgroundSize: "cover",
              borderRadius: "0 0 15px 15px",
              pb: `${breakpointDownSM ? 30 : 40}px !important`
            }
            : undefined
        }>
        <Stack alignItems={titleLeft ? "flex-start" : "center"}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{
              alignSelf: "flex-end",
              borderRadius: "50%",
              backgroundColor: "RGB(229, 229, 232)",
              height: "25px",
              width: "25px"
            }}>
            <img alt="close" src="/images/icon_close.svg" style={{ height: "8px", width: "8px" }} />
          </IconButton>
          <Stack
            direction={customHeader ? "row" : undefined}
            alignItems="center"
            justifyContent={customHeader ? "center" : undefined}
            spacing={3}
            color={customHeader ? "white" : undefined}
            px={customHeader ? (breakpointDownSM ? "10px" : "40px") : undefined}>
            {!!icon && <Stack sx={customHeader ? {
              borderRadius: "50%",
              bgcolor: Colors.lightpurple,
              p: "14px"
            } : undefined}>{icon}</Stack>}
            <Stack alignItems={titleLeft ? "flex-start" : undefined}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography
                  style={{ wordBreak: "break-word" }}
                  whiteSpace="pre-line"
                  lineHeight="1"
                  textAlign={textAlign}
                  fontSize="20px"
                  fontWeight="700">
                  {title}
                </Typography>
                {!!stepper && (
                  <Typography fontSize={14} fontWeight="500" sx={{ opacity: 0.47 }} pt="2px">
                    ({stepper.step + 1}/{stepper.totalStep})
                  </Typography>
                )}
              </Stack>
              {subtitle && (
                <Typography
                  style={{ wordBreak: "break-word", opacity: 0.47 }}
                  whiteSpace="pre-line"
                  lineHeight="1.25"
                  mt="5px"
                  fontSize="14px"
                  fontWeight="500">
                  {subtitle}
                </Typography>
              )}
            </Stack>
          </Stack>
        </Stack>
      </DialogTitle>
      {!!content && (
        <DialogContent className="scrollable" sx={{ height: contentHeight }}>
          {content}
        </DialogContent>
      )}
      {!!actions && <DialogActions
        style={{ justifyContent: "center", padding: paddingButton ?? "36px 0 50px 0" }}>{actions}</DialogActions>}
    </Dialog>
  );
};

export default ModalComponent;