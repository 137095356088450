import axios, { AxiosRequestConfig } from "axios";
import { sessionQuery, sessionService } from "@store/session";
import { errorTexts } from "@utils/error.utils";

const APIAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "",
  withCredentials: true,
});

APIAxios.interceptors.request.use(
  async (config) => {
    const token = sessionQuery.token;

    try {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
      return config;
    } catch (err) {
      return Promise.reject(err);
    }
  },
  (err) => Promise.reject(err)
);

APIAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (
      error.response.data.message === "LINKEDIN_TOKEN_EXPIRED" ||
      error.response.data.message === "TOKEN_EXPIRED" ||
      error.response.data.message === "DISABLED_ACCOUNT" ||
      error.response.data.message === "USER_DOES_NOT_EXIST" ||
      error.response.data.message === "INVALID_SESSION"
    ) {
      await sessionService.logout({ msg: errorTexts[error.response.data.message], variant: "error" });
    }
    return Promise.reject(error);
  }
);

export const APIRoutes = {
  /* AUTH */
  POSTLogin: (): AxiosRequestConfig => ({ method: "POST", url: "/auth/login" }),
  POSTLoginWithLinkedin: (): AxiosRequestConfig => ({ method: "POST", url: "/auth/login-with-linkedin" }),
  POSTLostPassword: (): AxiosRequestConfig => ({ method: "POST", url: "/auth/lost-password" }),
  POSTResetPassword: (): AxiosRequestConfig => ({ method: "POST", url: "/auth/generate-new-lost-password" }),
  GETCurrentUser: (): AxiosRequestConfig => ({ method: "GET", url: "/users/me" }),
  PUTUserPassword: (): AxiosRequestConfig => ({ method: "PUT", url: "/users/password" }),
  POSTSyncLinkedin: (): AxiosRequestConfig => ({ method: "POST", url: `/users/sync-linkedin` }),
  PUTAcceptRGPDAndCGU: (): AxiosRequestConfig => ({ method: "PUT", url: "/users/rgpd-cgu" }),
  /* ---- */

  /* SUBSCRIPTIONS */
  POSTRecontactRequest: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/${affiliateId}/subscription-quotation`,
  }),
  /* ------------- */

  /* CLIENTS */
  GETClients: (): AxiosRequestConfig => ({ method: "GET", url: `/client` }),
  POSTClient: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/client",
    headers: { "Content-Type": "multipart/form-data" },
  }),
  GETClientDetails: (clientId: number | string): AxiosRequestConfig => ({ method: "GET", url: `/client/${clientId}` }),
  PUTClientDetails: (clientId: number | string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/client/${clientId}`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  GETClientHistory: (clientId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/client/${clientId}/history`,
  }),
  DELETEClient: (clientId: number | string): AxiosRequestConfig => ({ method: "DELETE", url: `/client/${clientId}` }),
  GETClientComments: (clientId: number | string): AxiosRequestConfig => ({ method: "GET", url: `/client/${clientId}/comments` }),
  POSTClientComment: (clientId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/client/${clientId}/comment`,
  }),
  PUTClientComment: (clientId: number | string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/client/${clientId}/comment`,
  }),
  /* ------- */

  /* AFFILIATES */
  GETAffiliates: (): AxiosRequestConfig => ({ method: "GET", url: `/affiliate` }),
  POSTAffiliate: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/affiliate",
    headers: { "Content-Type": "multipart/form-data" },
  }),
  GETAffiliateDetails: (affiliateId: number | string): AxiosRequestConfig => ({ method: "GET", url: `/affiliate/${affiliateId}` }),
  GETAffiliateComments: (affiliateId: number | string): AxiosRequestConfig => ({ method: "GET", url: `/affiliate/${affiliateId}/comments` }),
  POSTAffiliateComment: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/${affiliateId}/comment`,
  }),
  PUTAffiliateComment: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/affiliate/${affiliateId}/comment`,
  }),
  POSTAffiliateFavorite: (clientId: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/${clientId}/set-favorite`,
  }),
  POSTAffiliateMail: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/${affiliateId}/send-email`,
  }),
  PUTAffiliateDetails: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/affiliate/${affiliateId}`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  PUTValidateActionTimeline: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/affiliate/${affiliateId}/validate-action`,
  }),
  GETAffiliateTimelineImage: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/affiliate/${affiliateId}/timeline-img`,
  }),
  GETAffiliatePlanMediaFile: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/affiliate/${affiliateId}/plan-media-file`,
  }),
  GETAffiliateHistory: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/affiliate/${affiliateId}/history`,
  }),
  POSTAffiliateRefreshATS: (affiliateId: number | string) => ({
    method: "POST",
    url: `/affiliate/${affiliateId}/refresh-ats`,
  }),
  PUTAffiliateSetAIOFlux: (affiliateId: number | string) => ({
    method: "PUT",
    url: `/affiliate/${affiliateId}/set-aio-flux`,
  }),
  POSTAffiliatePowerBI: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/${affiliateId}/power-bi`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  POSTAffiliateSubscription: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/${affiliateId}/subscription`,
  }),
  POSTGenerateTimelineWithAi: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/${affiliateId}/generate-timeline-with-ai`,
  }),
  PATCHAddAffilateSubscriptionAds: (affiliateId: number | string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/affiliate/${affiliateId}/subscription-ads`,
  }),
  DELETEAffiliate: (affiliateId: number | string): AxiosRequestConfig => ({ method: "DELETE", url: `/affiliate/${affiliateId}` }),
  /* ---------- */

  /* AIOPROMPT */
  GETAIPrompts: (): AxiosRequestConfig => ({ method: "GET", url: `/prompt` }),
  PUTAIPrompt: (promptId: string): AxiosRequestConfig => ({ method: "PUT", url: `/prompt/${promptId}` }),
  /* --------- */

  /* ADS */
  GETAds: (): AxiosRequestConfig => ({ method: "GET", url: "/announcement" }),
  GETAdsWithStats: (): AxiosRequestConfig => ({ method: "GET", url: "/announcement/with-stats" }),
  GETAdsStats: (): AxiosRequestConfig => ({ method: "GET", url: "/announcement/stats" }),
  GETAdDetails: (adId: number | string): AxiosRequestConfig => ({ method: "GET", url: `/announcement/${adId}` }),
  PUTAd: (adId: number | string): AxiosRequestConfig => ({ method: "PUT", url: `/announcement/${adId}` }),
  PUTAdsDiffusion: (): AxiosRequestConfig => ({ method: "PUT", url: `/announcement/ads-diffusion` }),

  POSTCreateCustomAd: (): AxiosRequestConfig => ({ method: "POST", url: `/announcement/create-custom` }),
  PUTUpdateCustomAd: (adId: number | string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/announcement/${adId}/update-custom`,
  }),
  DELETECustomAd: (adId: number | string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/announcement/${adId}/delete-custom`,
  }),

  GETAdStats: (adId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/announcement/${adId}/stats`,
  }),
  GETAdApplicationsStats: (adId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/announcement/${adId}/applications-stats`,
  }),

  GETAdApplications: (adId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/announcement/${adId}/application`,
  }),
  POSTAddAdApplication: (adId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/announcement/${adId}/application`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  GETAdApplicationDetails: (applicationId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/application/${applicationId}`,
  }),
  PATCHAdApplication: (applicationId: number | string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/application/${applicationId}/list`,
  }),
  PATCHApplicationCustomName: (applicationId: number | string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/application/${applicationId}/custom-name`,
  }),
  POSTAddApplicationComment: (applicationId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/application/${applicationId}/comment`,
  }),
  GETDownloadCV: (applicationId: string | number): AxiosRequestConfig => ({
    method: "GET",
    url: `/application/${applicationId}/download`,
    responseType: "blob",
  }),
  GETDownloadCVsHasZip: (): AxiosRequestConfig => ({
    method: "GET",
    url: `/application/bulk/download`,
    responseType: "blob",
  }),
  DELETEApplications: (): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/application`,
  }),
  PATCHMoveApplicationsToCVTheque: (): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/application/move-to-cvtheque`,
  }),
  GETAiApplicationEvaluation: (applicationId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/application/${applicationId}/ai-evaluate`,
  }),

  GETPotentialProfiles: (adId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/announcement/${adId}/potential-profiles`,
  }),
  POSTGeneratePotentialProfiles: (adId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/announcement/${adId}/potential-profiles`,
  }),

  GETAdSlogans: (adId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/announcement/${adId}/ai-slogans`,
  }),
  GETAdTags: (adId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/announcement/${adId}/ai-hashtags`,
  }),

  GETAdComments: (adId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/announcement/${adId}/comments`,
  }),
  POSTAddAdComment: (adId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/announcement/${adId}/comment`,
  }),
  PUTAddComment: (adId: number | string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/announcement/${adId}/comment`,
  }),

  GETAdHistory: (adId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/announcement/${adId}/history`,
  }),
  /* --- */

  /* CVTHEQUE / APPLICATIONS */
  GETApplications: (): AxiosRequestConfig => ({ method: "GET", url: "/application/cv-database" }),
  POSTSendEmailToCandidate: (applicationId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/application/${applicationId}/email-candidate`,
  }),
  /* ----------------------- */

  /* TWILIO AI CALL */
  GETAiCalls: (announcementId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/twilio-ai-call/get-all-by-announcement/${announcementId}`,
  }),
  GETAiCall: (applicationId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/twilio-ai-call/get-one/${applicationId}`,
  }),
  GETDownloadCall: (callSid: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/twilio-ai-call/get-recording/${callSid}`,
  }),
  POSTMakeAiCall: (): AxiosRequestConfig => ({ method: "POST", url: "/twilio-ai-call/make-ai-call" }),
  /* -------------- */

  /* BOOSTS */
  GETBoosts: (): AxiosRequestConfig => ({ method: "GET", url: "/boost" }),
  GETBuyerBoosts: (): AxiosRequestConfig => ({ method: "GET", url: "/boost/need-action" }),
  GETBoostDetails: (boostId: number | string): AxiosRequestConfig => ({ method: "GET", url: `/boost/${boostId}` }),
  POSTBoostRequest: (adId: number | string): AxiosRequestConfig => ({
    method: "POST",
    url: `/announcement/${adId}/boost`,
  }),
  POSTGetManualBoostRequestClientSecret: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/stripe/get-manual-boost-client-secret",
  }),
  PUTBoost: (boostId: number | string): AxiosRequestConfig => ({ method: "PUT", url: `/boost/${boostId}` }),
  /* ------ */

  /* PERSONAE */
  GETAllPersonae: (): AxiosRequestConfig => ({ method: "GET", url: `/affiliate/personae` }),
  GETPersonaeQuiz: (): AxiosRequestConfig => ({ method: "GET", url: "/personae-form" }),
  POSTPersonaeQuizAnswer: (): AxiosRequestConfig => ({ method: "POST", url: `/personae-form` }),
  PATCHPersonaeQuizAnswer: (answerId: string | number): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/personae-form/${answerId}`,
  }),
  POSTPersonaeProfile: (affiliateId: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/${affiliateId}/personae/`,
  }),
  GETPersonaeProfile: (personaeId: string): AxiosRequestConfig => ({ method: "GET", url: `/personae/${personaeId}` }),
  PATCHPersonaeProfile: (personaeId: string): AxiosRequestConfig => ({
    method: "PATCH",
    url: `/personae/${personaeId}`,
  }),
  /* -------- */

  /* REPORTS */
  POSTReport: (affiliateId: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/${affiliateId}/reports`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  GETReports: (): AxiosRequestConfig => ({ method: "GET", url: `/affiliate/reports` }),
  PUTReportIncrementDownload: (reportId: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/reports/${reportId}/increment-download`,
  }),
  DELETEReport: (reportId: string): AxiosRequestConfig => ({ method: "DELETE", url: `/reports/${reportId}/delete` }),
  GETAdsReportsCSV: (): AxiosRequestConfig => ({ method: "GET", url: `/announcement/reports-csv` }),
  /* ------- */

  /* USERS */
  GETUsers: (): AxiosRequestConfig => ({ method: "GET", url: "/users" }),
  POSTUser: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/users",
    headers: { "Content-Type": "multipart/form-data" },
  }),
  GETUserDetails: (userId: number | string): AxiosRequestConfig => ({ method: "GET", url: `/users/${userId}` }),
  PUTUser: (): AxiosRequestConfig => ({
    method: "PUT",
    url: "/users",
    headers: { "Content-Type": "multipart/form-data" },
  }),
  DELETEUser: (userId: string): AxiosRequestConfig => ({ method: "DELETE", url: `/users/${userId}` }),
  POSTGetStripeProSubClientSecret: (): AxiosRequestConfig => ({ method: "POST", url: "/stripe/get-subscription-client-secret" }),
  POSTCancelStripeSubscription: (): AxiosRequestConfig => ({ method: "POST", url: "/stripe/cancel-subscription" }),
  GETStripeProducts: (): AxiosRequestConfig => ({ method: "GET", url: "/stripe/products" }),
  POSTStripeProduct: (): AxiosRequestConfig => ({ method: "POST", url: `/stripe/products/` }),
  PUTStripeProducts: (): AxiosRequestConfig => ({ method: "PUT", url: `/stripe/products/` }),
  GETUsersCsv: (): AxiosRequestConfig => ({ method: "GET", url: `/users/csv`, responseType: "blob" }),
  POSTSaveOnboarding: (): AxiosRequestConfig => ({ method: "POST", url: "/users/save-onboarding" }),
  /* ----- */

  /* TOOLBOX */
  GETToolboxes: (): AxiosRequestConfig => ({ method: "GET", url: "/toolbox" }),
  PUTRequestToolboxTool: (affiliateId: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/affiliate/${affiliateId}/toolbox/request`,
  }),
  GETClientToolboxTools: (affiliateId: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/affiliate/${affiliateId}/toolbox/tool`,
  }),
  PUTAddToolboxColumn: (affiliateId: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/affiliate/${affiliateId}/toolbox/column`,
  }),
  DELETEToolboxColumn: (affiliateId: string): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/affiliate/${affiliateId}/toolbox/column`,
  }),
  DELETEToolboxTool: (): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/affiliate/toolbox/tool`,
  }),
  POSTIncDownloadToolboxDocument: (): AxiosRequestConfig => ({
    method: "POST",
    url: `/affiliate/toolbox/increment-download`,
  }),
  PUTUploadTooboxDoucument: (affiliateId: string): AxiosRequestConfig => ({
    method: "PUT",
    url: `/affiliate/${affiliateId}/toolbox/upload-tool`,
    headers: { "Content-Type": "multipart/form-data" },
  }),
  GETAffiliateToolboxTools: (affiliateId: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/affiliate/${affiliateId}/toolbox/tool`,
  }),
  /* ------- */

  /* LINKEDIN */
  GETCheckLinkedinConnection: (): AxiosRequestConfig => ({
    method: "GET",
    url: `/linkedin/check-connected`,
  }),
  GETGenerateLinkedinPost: (adId: number | string): AxiosRequestConfig => ({
    method: "GET",
    url: `/announcement/${adId}/linkedin-post`,
  }),
  POSTPublishLinkedinPost: (): AxiosRequestConfig => ({
    method: "POST",
    url: `/announcement/linkedin-post`,
  }),
  /* -------- */

  /* DASHBOARD */
  GETAdminDashboardStats: (): AxiosRequestConfig => ({ method: "GET", url: "/admin/dashboard/stats" }),
  GETAffiliateDashboardStats: (): AxiosRequestConfig => ({ method: "GET", url: `/affiliate/stats` }),
  GETAffiliatesApplicationsStats: (): AxiosRequestConfig => ({ method: "GET", url: `/affiliate/applications-stats` }),
  GETAffiliatesApplicationsDistribution: (): AxiosRequestConfig => ({
    method: "GET",
    url: `/affiliate/applications-distribution`,
  }),
  /* --------- */

  /* JOBBOARDS */
  GETJobBoards: (): AxiosRequestConfig => ({ method: "GET", url: "/job-board" }),
  GETSequences: (): AxiosRequestConfig => ({ method: "GET", url: "/sequences" }),
  GETBoostActions: (): AxiosRequestConfig => ({ method: "GET", url: "/boost-action" }),
  GETSchools: (): AxiosRequestConfig => ({ method: "GET", url: "/school" }),
  /* --------- */

  /* BANNERS */
  GETBanners: (): AxiosRequestConfig => ({ method: "GET", url: "/banner" }),
  GETBannerForUser: (): AxiosRequestConfig => ({ method: "GET", url: "/banner/for-user" }),
  GETBanner: (bannerId: string): AxiosRequestConfig => ({ method: "GET", url: `/banner/${bannerId}` }),
  POSTBanner: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/banner",
  }),
  PATCHBanner: (bannerId: string): AxiosRequestConfig => ({ method: "PATCH", url: `/banner/${bannerId}` }),
  DELETEBanner: (bannerId: string): AxiosRequestConfig => ({ method: "DELETE", url: `/banner/${bannerId}` }),
  /* --------- */

  /* AIO */
  POSTAIOApplication: (): AxiosRequestConfig => ({ method: "POST", url: "/application/aio" }),
  POSTAssociateAutomatically: (): AxiosRequestConfig => ({
    method: "POST",
    url: `/announcement/associate-automatically`,
  }),
  POSTUpdateAIOCareerSite: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/affiliate/update-aio-career-site",
  }),
  POSTUpdateAIOCareerSiteFormData: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/affiliate/update-aio-career-site-formdata",
    headers: { "Content-Type": "multipart/form-data" },
  }),
  /* --- */

  /* REGISTER USERS */
  GETRegisterUsers: (): AxiosRequestConfig => ({ method: "GET", url: "/register-users" }),
  GETRegisterUsersCount: (): AxiosRequestConfig => ({ method: "GET", url: "/register-users/count" }),
  POSTCreateRegisterUser: (): AxiosRequestConfig => ({
    method: "POST",
    url: "/register-users",
    headers: { "Content-Type": "multipart/form-data" },
  }),
  DELETEAcceptRegisterUser: (registerUserId: string | number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/register-users/${registerUserId}/accept`,
  }),
  DELETERejectRegisterUser: (registerUserId: string | number): AxiosRequestConfig => ({
    method: "DELETE",
    url: `/register-users/${registerUserId}/reject`,
  }),
  /* --- */

  /* CONNECTORS */
  GETUserAffiliatesConnectors: (): AxiosRequestConfig => ({ method: "GET", url: "/connector" }),
  GETAffiliateConnectors: (affiliateId: string): AxiosRequestConfig => ({ method: "GET", url: `/connector/by-affiliate/${affiliateId}` }),
  POSTActivateConnector: (): AxiosRequestConfig => ({ method: "POST", url: "/connector/activate" }),
  POSTDeactivateConnector: (connectorId: string): AxiosRequestConfig => ({ method: "POST", url: `/connector/${connectorId}/deactivate` }),
  PATCHConnector: (connectorId: string): AxiosRequestConfig => ({ method: "PATCH", url: `/connector/${connectorId}` }),
  /* --------- */

  /* UNIPILE */
  POSTUnipileHostedAuthURL: (): AxiosRequestConfig => ({ method: "POST", url: `/unipile/hosted-auth-url` }),
  GETUnipileAccountStatus: (): AxiosRequestConfig => ({ method: "GET", url: `/unipile/account-status` }),
  /* ------- */

  /* UNIPILE INVITATIONS */
  GETUnipileInvitations: (): AxiosRequestConfig => ({ method: "GET", url: `/unipile-invitations` }),
  POSTSendUnipileLinkedinInvitations: (): AxiosRequestConfig => ({
    method: "POST",
    url: `/unipile-invitations/send-linkedin-invitations`,
  }),
  POSTResendUnipileInvitation: (unipileInvitationId: string): AxiosRequestConfig => ({
    method: "POST",
    url: `/unipile-invitations/resend-linkedin-invitation/${unipileInvitationId}`,
  }),
  POSTRefreshUnipileInvitations: (): AxiosRequestConfig => ({
    method: "POST",
    url: `/unipile-invitations/refresh-linkedin-invitations-status`,
  }),
  /* ------------------- */

  /* STRIPE */
  GETCoupon: (couponName: string): AxiosRequestConfig => ({
    method: "GET",
    url: `/stripe/coupon/${couponName}`,
  }),
  /* ------ */
};

export default APIAxios;
