import { Box, CircularProgress, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffectFn } from "@ngneat/effects-hooks";

import { entityDetailsQuery } from "@store/entities/details";
import { EntityTypeEnum } from "@store/entities/entities.model";
import { searchUsersEffect, UserRoleEnum, usersQuery, usersService } from "@store/users";

import AIOButtonComponent from "@components/Button.component";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import UsersListComponent from "@screens/auth/admin/entities/components/UsersList.component";
import AddUserModal from "@screens/auth/admin/entities/components/AddUser.modal";
import UsersAccordionComponent from "@screens/auth/admin/entities/components/UsersAccordion.component";
import { emitOnce } from "@ngneat/elf";

interface EntityUsersTabProps {
  type: EntityTypeEnum;
}

const EntityUsersTab = (props: EntityUsersTabProps) => {
  const { type } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { entityId } = useParams();
  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const searchUsers = useEffectFn(searchUsersEffect);

  const [{ entityDetails: entity }] = useObservable(entityDetailsQuery.details$(type));
  const [{ users, error: usersError, loading: usersLoading }] = useObservable(usersQuery.users$);

  const [usersCount] = useObservable(usersQuery.usersCount$);

  const [filters] = useObservable(usersQuery.filters$);

  const [openAddUser, setOpenAddUser] = useState(false);

  useEffect(() => {
    emitOnce(() => {
      usersService.resetStore();
      usersService.setFilters({
        affiliateIds: type === EntityTypeEnum.AFFILIATE && entityId ? [entityId] : undefined,
        clientIds: type === EntityTypeEnum.CLIENT && entityId ? [entityId] : undefined,
        roles: [UserRoleEnum.CLIENT],
      });
    });
  }, [entityId, type]);

  useEffect(() => {
    if (usersError) enqueueSnackbar((usersError as any).text, (usersError as any).options);
  }, [usersError, enqueueSnackbar]);

  useEffect(() => {
    searchUsers({ filters });
  }, [filters, searchUsers]);

  if (!entity) return null;

  return (
    <Stack
      width="100%"
      alignItems="center"
      px={breakpointDownSM ? "0px" : "40px"}
      py={breakpointDownSM ? "20px" : "40px"}
      overflow="auto"
      className="scrollable">
      <Stack width={"100%"} px={breakpointDownSM ? "10px" : undefined}>
        <Stack width="100%">
          {usersLoading ? (
            <CircularProgress sx={{ alignSelf: "center" }} size={20} />
          ) : (
            <Stack spacing={4}>
              <Stack direction={breakpointDownSM ? "column" : "row"} justifyContent={"space-between"} alignItems={"center"}>
                <Typography fontSize={16} fontWeight="500" mb={breakpointDownSM ? "20px" : "0px"}>
                  {t("clients.details.usersTab.amount", { count: usersCount ?? 0 })}
                </Typography>
                <Stack
                  width={breakpointDownSM ? "100%" : breakpointDownMD ? "70%" : "60%"}
                  direction={breakpointDownSM ? "column-reverse" : "row"}
                  alignItems="center"
                  spacing={"20px"}>
                  <AIOSearchComponent
                    placeholder={t("clients.details.usersTab.search")}
                    fullWidth
                    width={"100%"}
                    onChange={(value) => usersService.setFilters({ search: value })} />
                  <Box minWidth={"180px"}>
                    <AIOButtonComponent
                      title={t("clients.details.usersTab.addUser")}
                      onClick={() => setOpenAddUser(true)}
                      variant="contained"
                      color="secondary"
                      fullWidth
                    />
                  </Box>
                </Stack>
              </Stack>
              {entity.type === EntityTypeEnum.AFFILIATE && (
                <UsersListComponent users={users || []} affiliateId={entity.id} />
              )}
              {entity.type === EntityTypeEnum.CLIENT && (
                <>
                  {(entity.affiliates || []).map((a) => (
                    <UsersAccordionComponent
                      key={a.id}
                      entity={a}
                      users={(users || []).filter((u) =>
                        u.affiliates.find((ua) => ua.affiliate.id === a.id)
                      )} />
                  ))}
                  <UsersAccordionComponent
                    isUnassignedUsers
                    entity={entity}
                    users={(users || []).filter((u) =>
                      !u.affiliates.find((ua) =>
                        (entity.affiliates ?? []).map((a) => a.id).includes(ua.affiliate.id)
                      )
                    )} />
                </>
              )}
            </Stack>
          )}
        </Stack>
      </Stack>
      {openAddUser && <AddUserModal entity={entity} handleClose={() => setOpenAddUser(false)} />}
      <Outlet />
    </Stack>
  );
};

export default EntityUsersTab;
