import { alpha, Components } from "@mui/material";
import { Colors } from "@constants/colors.constant";

const textfield: Components = {
  MuiTextField: {
    styleOverrides: {
      root: (props) => ({
        backgroundColor: Colors.background,
        borderRadius: 10,
        "& .MuiOutlinedInput-input": {
          fontSize: 14,
          fontWeight: 500,
        },
        "& fieldset": { border: "none" },
        ...(props.ownerState?.["aria-label"] === "textfield-button"
          ? {
              backgroundColor: Colors.lightPrimary,
              "& .MuiOutlinedInput-input": {
                cursor: "pointer",
                color: Colors.primary,
                fontSize: 14,
                fontWeight: 500,
              },
            }
          : {}),
      }),
    },
    variants: [
      {
        props: { color: "secondary" },
        style: {
          backgroundColor: "white",
          boxShadow: `0 0 20px 0 ${alpha("#000000", 0.05)}`,
        },
      },
      {
        props: { "aria-label": "comment" },
        style: {
          backgroundColor: Colors.background,
          width: "100%",
        },
      },
    ],
  },
  MuiSelect: {
    variants: [
      {
        props: { color: "secondary" },
        style: {
          backgroundColor: "white",
          borderRadius: 10,
        },
      },
    ],
    styleOverrides: {
      select: (props) => ({
        fontWeight: 500,
        fontSize: 14,
        color: Colors.secondaryText,
        height: "fit-content",
        backgroundColor: "transparent",
        padding: "9px 14px",
      }),
      icon: {
        bottom: 0,
        margin: "auto",
        top: 0,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        fontSize: 11,
        marginBottom: -22,
      },
    },
  },
  MuiSwitch: {
    variants: [
      {
        props: { color: "primary" },
        style: ({ theme }) => ({
          height: 24,
          padding: 0,
          width: 42,
          "& .MuiSwitch-switchBase": {
            margin: 2.5,
            padding: 0,
            transitionDuration: "300ms",
            "&.Mui-checked": {
              color: Colors.green,
              transform: "translateX(16.5px)",
              "& + .MuiSwitch-track": {
                backgroundColor: Colors.green,
                border: 0,
                opacity: 1,
              },
              "& > .MuiSwitch-thumb": {
                color: Colors.white,
              },
            },
            "&.Mui-focusVisible .MuiSwitch-thumb": {
              border: `6px solid ${Colors.white}`,
              color: Colors.green,
            },
          },
          "& .MuiSwitch-thumb": {
            color: Colors.white,
            boxSizing: "border-box",
            height: 19,
            width: 19,
          },
          "& .MuiSwitch-track": {
            backgroundColor: Colors.greyLight,
            borderRadius: 24 / 2,
            opacity: 1,
            transition: (theme as any).transitions.create(["background-color"], {
              duration: 500,
            }),
          },
        }),
      },
    ],
  },
};

export default textfield;
