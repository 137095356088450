import { select } from "@ngneat/elf";

import { adDetailsStore } from "@store/ads/details/adDetails.store";
import {
  getAdActionsDataSource,
  getAdDetailsApplicationsDataSource,
  getAdDetailsCommentsDataSource,
  getAdDetailsDetailsDataSource,
  getAdDetailsPotentialProfilesDataSource,
  getAdDetailsSlogansDataSource,
  getAdDetailsStatsDataSource,
  getAdDetailsTagsDataSource
} from "@store/ads/details/adDetails.requests";

export class AdDetailsQuery {
  store = adDetailsStore;

  details$ = getAdDetailsDetailsDataSource.data$();
  stats$ = getAdDetailsStatsDataSource.data$();
  applications$ = getAdDetailsApplicationsDataSource.data$();
  slogans$ = getAdDetailsSlogansDataSource.data$();
  tags$ = getAdDetailsTagsDataSource.data$();
  potentialProfiles$ = getAdDetailsPotentialProfilesDataSource.data$();
  comments$ = getAdDetailsCommentsDataSource.data$();
  actions$ = getAdActionsDataSource.data$();
  actionsPaginatedMeta$ = this.store.pipe(select((state) => state.actionsPaginatedMeta));

  adDetails$ = this.store.pipe(select((state) => state.details))

  get adName() {
    return this.store.getValue().details?.name;
  }

  get managedByTwinin() {
    return !!this.store.getValue().details?.managedByTwinin;
  }

  get jobBoards() {
    return this.store.getValue().details?.jobBoards ?? [];
  }

  get affiliatePrefilteredJobBoards() {
    return this.store.getValue().details?.affiliate.prefilteredJobBoards || [];
  }

  get currentSlogan() {
    return this.store.getValue().details?.slogan;
  }
}

export const adDetailsQuery = new AdDetailsQuery();
