import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { registerUsersStore } from "./registerUsers.store";

export const getRegisterUsersDataSource = createRequestDataSource({
  store: registerUsersStore,
  data$: () => registerUsersStore.pipe(selectAllEntities()),
  requestKey: "getRegisterUsers",
  dataKey: "registerUsers",
});
