import React, { useState } from "react";
import { Stack } from "@mui/material";
import { useTranslation } from "react-i18next";

import { GoogleAnalyticsVariablesEnumFunctions } from "@store/common/googleAnalytics.model";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import AIOButtonComponent from "@components/Button.component";

interface GoogleAnalyticsAddUrlModalProps {
  handleAdd: (url: string) => void;
  handleClose: () => void;
}

const GoogleAnalyticsAddUrlModal = (props: GoogleAnalyticsAddUrlModalProps) => {
  const {handleAdd, handleClose} = props;

  const {t} = useTranslation();

  const [url, setUrl] = useState("");

  return (
    <ModalComponent
      maxWidth="xs"
      title={t("clients.details.parametersTab.googleAnalytics.addSearchByAddUrl")}
      titleLeft
      content={(
        <Stack px="30px" spacing="15px" py="10px">
          <AIOTextfieldComponent
            title={t("clients.details.parametersTab.googleAnalytics.url")}
            placeholder={t("clients.details.parametersTab.googleAnalytics.url")}
            onChange={setUrl}
            value={url}/>
          <SelectWithSearchComponent
            placeholder={t("global.select")}
            handleChange={(selectedItem) => setUrl((state) => state + (selectedItem?.value || ""))}
            items={GoogleAnalyticsVariablesEnumFunctions.selectItems}/>
        </Stack>
      )}
      actions={(
        <AIOButtonComponent
          disabled={!url}
          onClick={() => handleAdd(url)}
          variant="contained"
          title={t("global.add")}/>
      )}
      handleClose={handleClose}/>
  );
};

export default GoogleAnalyticsAddUrlModal;
