import React, { useState } from "react";
import { Box, Button, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import { Colors } from "@constants/colors.constant";

import { clientsService } from "@store/entities/clients";
import { EntitiesModel, EntityTypeEnum } from "@store/entities/entities.model";
import { affiliatesService } from "@store/entities/afiliates";

import ConfirmModal from "@components/modal/Confirm.modal";
import EntityModal from "@screens/auth/admin/entities/components/Entity.modal";
import EntityParametersModal from "@screens/auth/admin/entities/components/EntityParameters.modal";
import { affiliateDetailsService } from "@store/entities/afiliates/details";
import { UserRoleEnum } from "@store/users";
import { sessionQuery } from "@store/session";

interface EntityMenuProps {
  entity: EntitiesModel;
  isInDetails?: boolean;
}

const EntityMenu = (props: EntityMenuProps) => {
  const { entity, isInDetails } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [openParameters, setOpenParameters] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const openMenu = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleDeleteClient = () => {
    switch (entity.type) {
      case EntityTypeEnum.CLIENT:
        return clientsService.deleteClient(entity.id).subscribe({
          next: () => {
            enqueueSnackbar(t("clients.delete.CLIENT.success"), { variant: "success" });
            if (isInDetails) navigate("..");
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
      case EntityTypeEnum.AFFILIATE:
        return affiliatesService.deleteAffiliate(entity.id).subscribe({
          next: () => {
            enqueueSnackbar(t("clients.delete.AFFILIATE.success"), { variant: "success" });
            if (isInDetails) navigate("../..");
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  };

  return (
    <Box>
      {isInDetails && entity?.type === EntityTypeEnum.AFFILIATE && sessionQuery.role !== UserRoleEnum.ADMIN && !sessionQuery.buyerMode && (
        <Button onClick={() => setOpenEdit(true)} startIcon={<img alt="delete" src={`/images/icon_edit_fill_primary.svg`} />}>
          {t("clients.details.updateButton")}
        </Button>
      )}
      {!isInDetails && entity?.type === EntityTypeEnum.AFFILIATE && sessionQuery.role !== UserRoleEnum.ADMIN && !sessionQuery.buyerMode && (
        <IconButton onClick={() => setOpenEdit(true)}>
          <img alt="delete" src={`/images/icon_edit_fill_primary.svg`} />
        </IconButton>
      )}
      {sessionQuery.role === UserRoleEnum.ADMIN && (
        <Box>
          <IconButton onClick={handleOpenMenu} size="small" sx={{ alignSelf: "flex-start" }}>
            <MoreVertIcon htmlColor={Colors.black} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            aria-label="user-menu"
            onClick={(evt) => evt.stopPropagation()}
            anchorOrigin={{ vertical: "center", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}>
            {!entity.isAlsoAffiliate && (
              <Box>
                <MenuItem
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setOpenEdit(true);
                    handleCloseMenu();
                  }}>
                  <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                    <img alt="delete" src={`/images/icon_edit_fill_black.svg`} />
                  </Stack>
                  {t("clients.details.updateButton")}
                </MenuItem>
                <MenuItem
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setOpenParameters(true);
                    handleCloseMenu();
                  }}>
                  <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                    <img alt="delete" src="/images/icon_settings.svg" />
                  </Stack>
                  {t("clients.details.parameters")}
                </MenuItem>
              </Box>
            )}
            {entity.type === EntityTypeEnum.AFFILIATE && !isInDetails && (
              <MenuItem
                onClick={(evt) => {
                  evt.stopPropagation();
                  affiliateDetailsService.setAffiliateFavorite(entity.id, !entity.isFavorite).subscribe({
                    error: (err) => enqueueSnackbar(err.text, err.options),
                  });
                  handleCloseMenu();
                }}>
                <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                  <img alt="delete" src={`/images/favorites_${entity.isFavorite ? "black" : "red"}.svg`} />
                </Stack>
                {t(entity.isFavorite ? "clients.removeFromFavorites" : "clients.addToFavorites")}
              </MenuItem>
            )}

            <MenuItem
              color="primary"
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenDeleteConfirmation(true);
                handleCloseMenu();
              }}>
              <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                <img alt="delete" src="/images/icon_delete.svg" />
              </Stack>
              {t(`clients.delete.${entity.type}.title`)}
            </MenuItem>
          </Menu>
        </Box>
      )}
      {openDeleteConfirmation && (
        <ConfirmModal
          modalTitle={t(`clients.delete.${entity.type}.title`)}
          confirmMsg={t(`clients.delete.${entity.type}.description`, { clientName: entity.name })}
          handleClose={() => setOpenDeleteConfirmation(false)}
          handleConfirm={() => handleDeleteClient()}
        />
      )}
      {openEdit && <EntityModal isInDetails={isInDetails} entityId={entity.id} handleClose={() => setOpenEdit(false)} type={entity.type} />}
      {openParameters && <EntityParametersModal entityId={entity.id} handleClose={() => setOpenParameters(false)} type={entity.type} />}
    </Box>
  );
};

export default EntityMenu;
