import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";

import { personalitiesQuery, personalitiesService, Personality } from "@store/ai-o/personalities";

import { Colors } from "@constants/colors.constant";

import PromptsCard from "@components/card/Prompts.card";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import ConfirmModal from "@components/modal/Confirm.modal";
import PersonalityModal from "@components/aio-prompts/modals/Personality.modal";
import AIOButtonComponent from "@components/Button.component";

export interface PersonalitySentenceModel {
  sentence: string;
  GPTElements: string;
}

const PersonalitiesComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [{ personalities }] = useObservable(personalitiesQuery.personalities$);
  const [title] = useObservable(personalitiesQuery.title$);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedPersonality, setSelectedPersonality] = useState<Personality | undefined>();

  useEffect(() => {
    personalitiesService.getPersonalities().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  useEffect(() => {
    personalitiesService.getPersonalitiesTitle().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  const handleClose = () => {
    setConfirmDelete(false);
    setIsModalOpen(false);
    setSelectedPersonality(undefined);
  };

  const handleDeletePersonality = () => {
    if (!selectedPersonality) return;
    personalitiesService.deletePersonality(selectedPersonality.id).subscribe({
      next: () => {
        enqueueSnackbar(t("aio.personality.delete.success"), { variant: "success" });
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handleSavePersonalitiesTitle = () => {
    if (!title) return;
    personalitiesService.updatePersonalitiesTitle(title).subscribe({
      next: () => {
        enqueueSnackbar(t("aio.personality.success.title"), { variant: "success" });
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <>
      <PromptsCard title={t("aio.personality.title")} hasAddBtn handleAddBtn={() => setIsModalOpen(true)}>
        <Stack direction="row" pt="10px" alignItems={"flex-end"} spacing={"10px"} width={"100%"}>
          <Box width={"100%"}>
            <AIOTextfieldComponent title={t("aio.personality.aioTitle")} value={title ?? ""} onChange={personalitiesService.setTitle} fullWidth />
          </Box>
          <AIOButtonComponent onClick={handleSavePersonalitiesTitle} title={t("global.save")} variant="contained" />
        </Stack>
        <Grid container mt="25px" mb="32px" spacing={2}>
          {personalities.map((personality) => (
            <Grid item key={personality.id} sm={6}>
              <Stack borderRadius="7px" bgcolor="rgba(232, 97, 77, 0.1)" p="15px" direction="row" justifyContent="space-between">
                <Typography color={Colors.primary} fontWeight={500} fontSize="14px" lineHeight="1.25">
                  {personality.content}
                </Typography>
                <Stack direction="row" alignItems="center">
                  <IconButton
                    onClick={() => {
                      setIsModalOpen(true);
                      setSelectedPersonality(personality);
                    }}
                    size="small">
                    <img alt="edit" src="/images/edit.svg" style={{ height: "18px" }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setConfirmDelete(true);
                      setSelectedPersonality(personality);
                    }}
                    size="small">
                    <img alt="delete" src="/images/delete.svg" style={{ height: "17px" }} />
                  </IconButton>
                </Stack>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </PromptsCard>
      {isModalOpen && <PersonalityModal handleClose={handleClose} personality={selectedPersonality} />}
      {!!confirmDelete && (
        <ConfirmModal
          handleConfirm={handleDeletePersonality}
          confirmMsg={t("aio.personality.delete.description")}
          modalTitle={t("aio.personality.delete.title")}
          handleClose={handleClose}
        />
      )}
    </>
  );
};

export default PersonalitiesComponent;
