import { Components } from "@mui/material";
import { Colors } from "@constants/colors.constant";

const tabs: Components = {
  MuiTabs: {
    styleOverrides: {
      scroller: {
        alignSelf: "center"
      }
    }
  },
  MuiTab: {
    styleOverrides: {
      root: {
        textTransform: "none",
        borderRadius: 15,
        marginLeft: 7.5,
        marginRight: 7.5,
        minHeight: 20,
        fontSize: "15px",
        fontWeight: 500,
        padding: "8px 20px",
        "&.Mui-selected": {
          backgroundColor: Colors.primary,
          color: "white"
        }
      }
    }
  }
};

export default tabs;
