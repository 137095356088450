import { createStore, withProps } from "@ngneat/elf";
import { entitiesPropsFactory, withEntities, withUIEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { BoostAction, JobBoard, JobBoardAndSequenceAndBoostActionAndSchoolUI, JobBoardsFilters, School, Sequence } from "./jobBoards.model";

export const { withSequencesEntities } = entitiesPropsFactory("sequences");
export const { withBoostActionsEntities } = entitiesPropsFactory("boostActions");
export const { withSchoolsEntities } = entitiesPropsFactory("schools");

interface JobBoardsStoreProps {
  filters: JobBoardsFilters;
}

export const jobBoardsStore = createStore(
  { name: "job-boards" },
  withProps<JobBoardsStoreProps>({
    filters: { search: "" },
  }),
  withEntities<JobBoard>(),
  withUIEntities<JobBoardAndSequenceAndBoostActionAndSchoolUI>(),
  withSequencesEntities<Sequence>(),
  withBoostActionsEntities<BoostAction>(),
  withSchoolsEntities<School>(),
  withRequestsStatus()
);
