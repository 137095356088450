import React, { useEffect, useState } from "react";
import { CardActionArea, Stack, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { finalize } from "rxjs";

import { Personae, personaeQuery, PersonaeQuiz, PersonaeQuizCategory, personaeService } from "@store/personae";

import { Colors } from "@constants/colors.constant";

import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";

const AnswerStyled = styled(Stack)((props) => `
  background-color: transparent;
  border-radius: 15px;
  flex: 1;
  height: 100%;
  opacity: 1;
  width: 100%;
  & > *:first-of-type {
    align-items: flex-start;
    background-color: ${Colors.white};
    border: 1px solid ${props["aria-selected"] ? Colors.primary : Colors.background};
    border-radius: 15px;
    height: 100%;
    justify-content: space-between;
    padding: 14px 18px;
  };
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    };
  };
`);

interface ManageProfileModalProps {
  category: PersonaeQuizCategory;
  handleClose: () => void;
  handleSave: (personae: Personae) => void;
  selectedOptions?: PersonaeQuiz;
}

const ManageProfileModal = (props: ManageProfileModalProps) => {
  const { category, handleClose, handleSave, selectedOptions: selectedOptionsProps } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { personaeId } = useParams();

  const [updateLoading, setUpdateLoading] = useState(false);

  const [{ personaeQuiz, loading }] = useObservable(personaeQuery.personaeQuiz$);
  const [selectedOptions, setSelectedOptions] = useState<PersonaeQuiz>(selectedOptionsProps ?? {});

  useEffect(() => {
    personaeService.getPersonaeQuiz().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options)
    });
  }, [enqueueSnackbar]);

  if (!personaeId) return null;

  const categoryFieldKey = PersonaeQuizCategory.fieldKey(category);

  const handleUpdate = () => {
    if (!personaeId || !selectedOptions[categoryFieldKey].length) return;
    setUpdateLoading(true);
    personaeService.updatePersonaeProfileOptions(personaeId, selectedOptions).pipe(finalize(() => setUpdateLoading(false))).subscribe({
      next: (np) => {
        enqueueSnackbar(t("personae.manage.success"), { variant: "success" });
        handleSave(np);
      },
      error: (err) => enqueueSnackbar(err.text, err.options)
    });
  };

  const isSingleSelectionCategory = PersonaeQuizCategory.singleSelectionCategories.includes(category);

  return (
    <ModalComponent
      maxWidth="sm"
      titleLeft
      title={t("personae.manage.title")}
      handleClose={handleClose}
      content={(
        <Stack py="10px" spacing={1} height="60vh">
          <Typography fontSize={14} fontWeight="500" lineHeight={1.25}>
            {PersonaeQuizCategory.question(category)}
          </Typography>
          <Stack spacing={1} overflow="auto" className="scrollable" py="20px" pr="5px">
            {personaeQuiz[categoryFieldKey]?.map((answer) => {
              const selected = selectedOptions[categoryFieldKey]?.some((o) => o.id === answer.id);

              return (
                <AnswerStyled
                  key={answer.id}
                  aria-selected={selected}
                  onClick={() => {
                    if (selected) {
                      setSelectedOptions((state) => ({
                        ...state,
                        [categoryFieldKey]: isSingleSelectionCategory
                          ? []
                          : (state[categoryFieldKey] ?? []).filter((o) => o.id !== answer.id)
                      }));
                    } else {
                      setSelectedOptions((state) => ({
                        ...state,
                        [categoryFieldKey]: isSingleSelectionCategory
                          ? [answer]
                          : (state[categoryFieldKey] ?? []).concat(answer)
                      }));
                    }
                  }}>
                  <Stack component={CardActionArea}>
                    <Typography fontSize={14} fontWeight="300" lineHeight={1.25}>{answer.name}</Typography>
                  </Stack>
                </AnswerStyled>
              );
            })}
          </Stack>
        </Stack>
      )}
      actions={(
        <>
          <AIOButtonComponent
            variant="contained"
            disabled={loading || updateLoading || !selectedOptions[categoryFieldKey]?.length}
            onClick={handleUpdate}
            title={t("global.modify")} />
        </>
      )} />
  );
};

export default ManageProfileModal;