import { MouseEvent, useEffect, useRef, useState } from "react";
import { Colors } from "@constants/colors.constant";
import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { Editor, EditorState, Modifier, RichUtils } from "draft-js";
import ClickOutsideComponent from "../ClickOutside.component";
import { useTranslation } from "react-i18next";
import { BannerFontColorPalette } from "@store/banners";

const EditorContainer = styled("div")((props: { bgcolor?: string }) => ({
  border: `1px solid ${Colors.greyCardBg}`,
  borderRadius: "10px",
  borderTopLeftRadius: "0px",
  padding: "11px 15px",
  fontSize: "12px !important",
  height: "226px",
  width: "100%",
  backgroundColor: props.bgcolor,
}));

const FontOptions = styled(Stack)({
  border: `1px solid ${Colors.greyCardBg}`,
  borderRadius: "10px",
  borderBottomLeftRadius: "0px",
  borderBottomRightRadius: "0px",
  borderBottom: "none",
});

const FontButtons = styled(Stack)({
  padding: "9px 10px 9px 18px",
  cursor: "pointer",
});

const FontSizeButton = styled(Typography)((props: { selected: boolean }) => ({
  padding: "5px",
  fontWeight: props.selected ? 900 : 300,
  cursor: "pointer",
}));

const FontStyleButtons = styled(Stack)({
  padding: "9px 10px",
  borderRight: `1px solid ${Colors.greyCardBg}`,
  borderLeft: `1px solid ${Colors.greyCardBg}`,
});

const FontStyleButton = styled("button")({
  border: "none",
  outline: "none",
  cursor: "pointer",
  borderRadius: "4px",
});

const SelectedColorCircle = styled("div")((props: { color: string }) => ({
  width: "16px",
  height: "16px",
  borderRadius: "50%",
  backgroundColor: props.color,
  boxShadow: "0px 0px 0px 0.3px rgb(204, 204, 204)",
}));

const ColorCircle = styled("div")((props: { color: string; selected: boolean }) => ({
  width: "23px",
  height: "23px",
  borderRadius: "50%",
  backgroundColor: props.color,
  boxShadow: props.selected
    ? "0px 0px 0px 0.3px rgb(204, 204, 204), 0px 0px 0px 2px white, 0px 0px 0px 3px black"
    : "0px 0px 0px 0.3px rgb(204, 204, 204)",
  cursor: "pointer",
}));

const SelectPopup = styled(Stack)({
  position: "absolute",
  top: "43px",
  left: 0,
  borderRadius: "15px",
  backgroundColor: Colors.white,
  boxShadow: "0px 0px 20px rgba(34, 34, 64, 0.05)",
  padding: "12px",
  zIndex: 100,
  cursor: "default",
});

const fontSizes = [9, 10, 11, 12, 14, 16];

const fontSizeStyleMap = fontSizes.reduce((acc, size) => ({ ...acc, [`FONTSIZE_${size}`]: { fontSize: `${size}px` } }), {});
const textColorStyleMap = BannerFontColorPalette.reduce((acc, color) => ({ ...acc, [`TEXTCOLOR_${color}`]: { color } }), {});

export const styleMap: { [key: string]: { fontSize: string } } = { ...fontSizeStyleMap, ...textColorStyleMap };

interface AIOTextEditorProps {
  backgroundColor?: string;
  colorPalette: string[];
  editorState: EditorState;
  onContentChange: (editorState: EditorState) => void;
}

const AIOTextEditor = (props: AIOTextEditorProps) => {
  const { backgroundColor, colorPalette, editorState, onContentChange } = props;

  const { t } = useTranslation();

  const editorRef = useRef<Editor>(null);

  const [boldIsActive, setBoldIsActive] = useState(false);
  const [italicIsActive, setItalicIsActive] = useState(false);
  const [underlineIsActive, setUnderlineIsActive] = useState(false);
  const [selectedFontSize, setSelectedFontSize] = useState(12);
  const [openFontSizeSelect, setOpenFontSizeSelect] = useState(false);
  const [openTextColorSelect, setOpenTextColorSelect] = useState(false);

  const [selectedTextColor, setSelectedTextColor] = useState(BannerFontColorPalette[0]);

  useEffect(() => {
    setBoldIsActive(editorState.getCurrentInlineStyle().has("BOLD"));
    setItalicIsActive(editorState.getCurrentInlineStyle().has("ITALIC"));
    setUnderlineIsActive(editorState.getCurrentInlineStyle().has("UNDERLINE"));
  }, [editorState]);

  const toggleStyle = (evt: MouseEvent<HTMLButtonElement | HTMLSpanElement, globalThis.MouseEvent>, style: string) => {
    evt.preventDefault();
    onContentChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  const applyTextColor = (editorState: EditorState, toggledColor: string) => {
    const selection = editorState.getSelection();

    // Let's just allow one color at a time. Turn off all active colors.
    const nextContentState = Object.keys(textColorStyleMap).reduce((contentState, color) => {
      return Modifier.removeInlineStyle(contentState, selection, color);
    }, editorState.getCurrentContent());

    let nextEditorState = EditorState.push(editorState, nextContentState, "change-inline-style");

    const currentStyle = editorState.getCurrentInlineStyle();

    // Unset style override for current color.
    if (selection.isCollapsed()) {
      nextEditorState = currentStyle.reduce((state, color) => {
        return state && color ? RichUtils.toggleInlineStyle(state, color) : editorState;
      }, nextEditorState);
    }

    // If the color is being toggled on, apply it.
    if (!currentStyle.has(toggledColor)) {
      nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, toggledColor);
    }

    onContentChange(nextEditorState);
  };

  return (
    <Stack>
      <FontOptions direction={"row"} alignSelf={"flex-start"}>
        <ClickOutsideComponent onClickOutside={() => setOpenFontSizeSelect(false)}>
          <FontButtons
            direction={"row"}
            alignItems={"center"}
            spacing={"3px"}
            onMouseDown={(evt) => {
              evt.preventDefault();
              setOpenFontSizeSelect(true);
            }}>
            <Typography width={"10px"} fontWeight={300}>
              {selectedFontSize}
            </Typography>
            <ArrowDropDownIcon />
          </FontButtons>
          {openFontSizeSelect && (
            <SelectPopup>
              <Stack direction={"row"} alignItems={"center"} spacing="15px">
                {fontSizes.map((s) => (
                  <FontSizeButton
                    key={s}
                    selected={s === selectedFontSize}
                    onMouseDown={(evt) => {
                      evt.preventDefault();
                      toggleStyle(evt, `FONTSIZE_${s}`);
                      setSelectedFontSize(s);
                      setOpenFontSizeSelect(false);
                    }}>
                    {s}
                  </FontSizeButton>
                ))}
              </Stack>
            </SelectPopup>
          )}
        </ClickOutsideComponent>
        <FontStyleButtons direction={"row"} alignItems={"center"} spacing={"5px"}>
          <FontStyleButton
            onMouseDown={(evt) => {
              toggleStyle(evt, "BOLD");
            }}
            style={{ backgroundColor: boldIsActive ? Colors.greyCardBg : Colors.white }}>
            <Typography fontWeight={700}>B</Typography>
          </FontStyleButton>
          <FontStyleButton
            onMouseDown={(evt) => {
              toggleStyle(evt, "ITALIC");
            }}
            style={{ backgroundColor: italicIsActive ? Colors.greyCardBg : Colors.white }}>
            <Typography fontWeight={300} fontStyle={"italic"}>
              B
            </Typography>
          </FontStyleButton>
          <FontStyleButton
            onMouseDown={(evt) => {
              toggleStyle(evt, "UNDERLINE");
            }}
            style={{ backgroundColor: underlineIsActive ? Colors.greyCardBg : Colors.white }}>
            <Typography fontWeight={300} sx={{ textDecoration: "underline" }}>
              B
            </Typography>
          </FontStyleButton>
        </FontStyleButtons>
        <ClickOutsideComponent onClickOutside={() => setOpenTextColorSelect(false)}>
          <FontButtons
            direction={"row"}
            alignItems={"center"}
            spacing={"3px"}
            position="relative"
            onMouseDown={(evt) => {
              evt.preventDefault();
              setOpenTextColorSelect(true);
            }}>
            <SelectedColorCircle color={selectedTextColor} />
            <ArrowDropDownIcon />
          </FontButtons>
          {openTextColorSelect && (
            <SelectPopup>
              <Stack direction={"row"} alignItems={"center"} spacing="15px">
                {colorPalette.map((c) => (
                  <ColorCircle
                    key={c}
                    color={c}
                    selected={c === selectedTextColor}
                    onMouseDown={(evt) => {
                      evt.preventDefault();
                      applyTextColor(editorState, `TEXTCOLOR_${c}`);
                      setSelectedTextColor(c);
                      setOpenTextColorSelect(false);
                    }}
                  />
                ))}
              </Stack>
            </SelectPopup>
          )}
        </ClickOutsideComponent>
      </FontOptions>
      <EditorContainer bgcolor={backgroundColor}>
        <Editor
          ref={editorRef}
          editorState={editorState}
          onChange={onContentChange}
          customStyleMap={styleMap}
          placeholder={t("adminParameters.banners.textToShow")}
        />
      </EditorContainer>
    </Stack>
  );
};

export default AIOTextEditor;
