import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { connectorsStore } from "./connectors.store";

export const getConnectorsDataSource = createRequestDataSource({
  store: connectorsStore,
  data$: () => connectorsStore.pipe(selectAllEntities()),
  requestKey: "getConnectors",
  dataKey: "connectors",
});
