import { AppCountryEnum, getAppCountry } from "@store/common/country.model";
import { AffiliateLightModel } from "@store/entities/afiliates";
import { SubscriptionTypeEnum } from "@store/subscriptions";
import i18n from "@utils/i18n.utils";
import { ReactNode } from "react";

export enum ConnectorMediaEnum {
  AIO_JOBS = "AIO_JOBS",
  INDEED = "INDEED",
  FRANCE_TRAVAIL = "FRANCE_TRAVAIL",
  APEC = "APEC",
  L_EDUDIANT = "L_EDUDIANT",
  TALENT_COM = "TALENT_COM",
  LINKEDIN = "LINKEDIN",
  METEOJOB = "METEOJOB",
  META = "META",
  GOOGLE_FOR_JOBS = "GOOGLE_FOR_JOBS",
  TIKTOK = "TIKTOK",
}
export interface ConnectorMetaData {
  media: ConnectorMediaEnum;
  type: ConnectorTypeEnum;
  name: string;
  logo: ReactNode;
  description: string;
  legalConfirm: string;
  subscriptionType: SubscriptionTypeEnum[];
}

export enum ConnectorTypeEnum {
  PASSWORD = "PASSWORD",
  URL = "URL",
  STANDALONE = "STANDALONE",
}

const connectorMedias =
  getAppCountry() === AppCountryEnum.FR
    ? Object.values(ConnectorMediaEnum)
    : Object.values(ConnectorMediaEnum).filter(
        (connectorMedia) =>
          connectorMedia !== ConnectorMediaEnum.FRANCE_TRAVAIL &&
          connectorMedia !== ConnectorMediaEnum.APEC &&
          connectorMedia !== ConnectorMediaEnum.L_EDUDIANT
      );

const getConnectorMetaData = (connectorMedia: ConnectorMediaEnum): ConnectorMetaData => {
  switch (connectorMedia) {
    case ConnectorMediaEnum.AIO_JOBS:
      return {
        media: connectorMedia,
        name: "Aio-jobs.com",
        logo: <img alt="" height="50px" src="/images/connectors/aio-jobs.png"/>,
        type: ConnectorTypeEnum.STANDALONE,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.FREEMIUM, SubscriptionTypeEnum.PROFESSIONAL, SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.INDEED:
      return {
        media: connectorMedia,
        name: "Indeed",
        logo: <img alt="" height="50px" src="/images/connectors/indeed.png"/>,
        type: ConnectorTypeEnum.PASSWORD,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.FRANCE_TRAVAIL:
      return {
        media: connectorMedia,
        name: "France Travail",
        logo: <img alt="" height="50px" src="/images/connectors/france-travail.png"/>,
        type: ConnectorTypeEnum.PASSWORD,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.FREEMIUM, SubscriptionTypeEnum.PROFESSIONAL, SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.APEC:
      return {
        media: connectorMedia,
        name: "Apec",
        logo: <img alt="" height="70px" src="/images/connectors/apec.png"/>,
        type: ConnectorTypeEnum.STANDALONE,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.PROFESSIONAL, SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.L_EDUDIANT:
      return {
        media: connectorMedia,
        name: "L'Étudiant",
        logo: <img alt="" height="50px" src="/images/connectors/letudiant.png"/>,
        type: ConnectorTypeEnum.STANDALONE,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.PROFESSIONAL, SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.TALENT_COM:
      return {
        media: connectorMedia,
        name: "Talent.com",
        logo: <img alt="" height="50px" src="/images/connectors/talent.png"/>,
        type: ConnectorTypeEnum.STANDALONE,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.PROFESSIONAL, SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.LINKEDIN:
      return {
        media: connectorMedia,
        name: "LinkedIn",
        logo: <img alt="" height="50px" src="/images/connectors/linkedin.png"/>,
        type: ConnectorTypeEnum.URL,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.METEOJOB:
      return {
        media: connectorMedia,
        name: "Meteojob",
        logo: <img alt="" height="70px" src="/images/connectors/meteojob.png"/>,
        type: ConnectorTypeEnum.STANDALONE,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.META:
      return {
        media: connectorMedia,
        name: "Meta",
        logo: <img alt="" height="30px" src="/images/connectors/meta.png"/>,
        type: ConnectorTypeEnum.STANDALONE,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.GOOGLE_FOR_JOBS:
      return {
        media: connectorMedia,
        name: "Google for Jobs",
        logo: <img alt="" height="50px" src="/images/connectors/google-for-jobs.png"/>,
        type: ConnectorTypeEnum.STANDALONE,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.COMPANY],
      };
    case ConnectorMediaEnum.TIKTOK:
      return {
        media: connectorMedia,
        name: "TikTok",
        logo: <img alt="" height="50px" src="/images/connectors/tiktok.png"/>,
        type: ConnectorTypeEnum.STANDALONE,
        description: i18n.t(`connectors.description.${connectorMedia}`),
        legalConfirm: i18n.t(`connectors.legalConfirm.${connectorMedia}`),
        subscriptionType: [SubscriptionTypeEnum.COMPANY],
      };
  }
};

export namespace Connector {
  export const getConnectorsMetaData = () => {
    return connectorMedias.map((connectorMedia) => getConnectorMetaData(connectorMedia));
  };
}

export interface ManageConnector {
  login?: string;
  password?: string;
  company?: string;
  url?: string;
  affiliateId: string;
  media: ConnectorMediaEnum;
  type: ConnectorTypeEnum;
}

export interface ConnectorModel {
  id: string;
  media: ConnectorMediaEnum;
  type: ConnectorTypeEnum;
  emailSentDate: Date;
  affiliate: AffiliateLightModel;
  company?: string;
  url?: string;
}
