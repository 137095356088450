import { useState } from "react";
import styled from "@emotion/styled";
import { alpha, Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";

import {
  ManageSubscriptionParameters,
  Subscription,
  SubscriptionTypeEnum,
  SubscriptionTypeEnumFunctions
} from "@store/subscriptions/subscriptions.model";
import { subscriptionsQuery } from "@store/subscriptions";

import { Colors } from "@constants/colors.constant";

import AmountChoiceComponent from "./AmountChoice.component";
import SubscriptionContactModal from "./modal/SubscriptionContact.modal";
import AIOButtonComponent from "@components/Button.component";
import ChooseProSubscriptionModal from "@components/subscriptions/modal/ChooseProSubscription.modal";
import { sessionQuery } from "@store/session";
import { stripeService } from "@store/stripe/stripe.service";
import { useSnackbar } from "notistack";
import ConfirmModal from "@components/modal/Confirm.modal";
import ModalComponent from "@components/modal/Modal.component";

interface SubscriptionCardProps {
  color?: string;
  fullheight: "true" | "false";
  background: "light" | "dark";
  iscurrentsubscription?: "true" | "false";
  gradient: string;
}

const SubscriptionCard = styled(Stack)((props: SubscriptionCardProps) => ({
  alignSelf: props.fullheight === "false" ? undefined : "stretch",
  background: props.background === "light" ? Colors.subscriptions.lightBackground : Colors.subscriptions.darkBackground,
  backgroundImage: props.gradient,
  boxShadow: props.iscurrentsubscription === "true" ? `0 0 0 1px ${props.color}` : "0 0 0 1px transparent",
  borderRadius: "10px",
  color: props.background === "light" ? Colors.secondary : Colors.white,
  marginBottom: "15px !important",
  overflow: "hidden",
  paddingTop: "30px",
  position: "relative",
  width: "262px",
  "& > div > .MuiTypography-root:first-of-type": {
    color: props.background === "light" ? Colors.grey : alpha(Colors.white, 0.6),
  },
}));

const GreenCheckIcon = styled(CheckIcon)({
  color: Colors.checkIcon,
});

const ShowMore = styled(Stack)({
  width: "100%",
  backgroundColor: alpha(Colors.secondary, 0.04),
  padding: "14px 0",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: alpha(Colors.secondary, 0.1),
  },
  transition: "all 0.2s ease",
  marginTop: "20px",
});

interface SubscriptionColumnComponentProps {
  handleShowModal: () => void;
  isYearly: boolean;
  subscription: Subscription;
}

const SubscriptionColumnComponent = (props: SubscriptionColumnComponentProps) => {
  const { handleShowModal, isYearly, subscription } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [subscriptionParameters, setSubscriptionParameters] = useState<ManageSubscriptionParameters>({
    subscription: subscription,
    amount: subscription.type === SubscriptionTypeEnum.COMPANY ? undefined : 1,
  });

  const [confirmCancelSubscription, setConfirmCancelSubscription] = useState(false);
  const [openChooseOffer, setOpenChooseOffer] = useState(false);
  const [openEmpoyerBrand, setOpenEmployerBrand] = useState(false);

  const isSpecialSubscription = subscription.type === SubscriptionTypeEnum.EMPLOYER_BRAND || subscription.type === SubscriptionTypeEnum.COMPANY;

  const isCurrentSubscription = () => {
    if (subscriptionsQuery.sessionUserSubscriptionOngoing) {
      return subscription.type === SubscriptionTypeEnum.PROFESSIONAL;
    } else {
      return subscription.type === SubscriptionTypeEnum.FREEMIUM;
    }
  };

  const getSubscriptionButton = () => {
    const userSubscription = sessionQuery.user.subscription;

    switch (subscription.type) {
      case SubscriptionTypeEnum.PROFESSIONAL:
        if (subscriptionsQuery.sessionUserSubscriptionOngoing) {
          if (!!userSubscription?.endDate) {
            return (
              <Typography textAlign="center" fontSize="14px" lineHeight="1.25" style={{ color: Colors.subscriptions.professional }}>
                {t("subscriptions.endSub", { date: new Date(userSubscription.endDate) })}
              </Typography>
            );
          } else if (userSubscription!.buyer.id !== sessionQuery.user.id) {
            return null;
          } else {
            return (
              <AIOButtonComponent
                variant="outlined"
                color="primary"
                fullWidth
                onClick={() => setConfirmCancelSubscription(true)}
                title={t("subscriptions.unsubscribe")}
              />
            );
          }
        }

        return <AIOButtonComponent variant="contained" fullWidth onClick={() => setOpenChooseOffer(true)} title={t("subscriptions.chooseOffer")} />;
      case SubscriptionTypeEnum.COMPANY:
        return (
          <AIOButtonComponent
            variant="contained"
            fullWidth
            disabled={!subscriptionParameters.amount && !subscriptionParameters.unlimitedOffer}
            onClick={() => setOpenChooseOffer(true)}
            title={t("subscriptions.contactAIO")}
          />
        );
      case SubscriptionTypeEnum.EMPLOYER_BRAND:
        return <AIOButtonComponent variant="contained" fullWidth onClick={() => setOpenEmployerBrand(true)} title={t("subscriptions.discover")} />;
      default:
        return null;
    }
  };

  return (
    <SubscriptionCard
      alignItems="center"
      iscurrentsubscription={isCurrentSubscription() ? "true" : "false"}
      mr={breakpointDownMD ? "0px" : "20px"}
      position="relative"
      color={SubscriptionTypeEnumFunctions.colors[subscription.type]}
      fullheight={subscription.type !== SubscriptionTypeEnum.EMPLOYER_BRAND ? "true" : "false"}
      background={SubscriptionTypeEnumFunctions.backgroundColors[subscription.type]}
      gradient={SubscriptionTypeEnumFunctions.gradientColors[subscription.type]}>
      <Stack px="38px" alignItems="center">
        <Stack direction="row" alignItems="center" spacing="7px">
          {subscription.icon}
          <Typography fontSize="20px" fontWeight={500}>
            {subscription.name}
          </Typography>
        </Stack>
        <Typography fontSize="12px" fontWeight={500} lineHeight="1.25" textAlign="center" mt="7px">
          {subscription.subtitle}
        </Typography>
      </Stack>
      <Stack alignItems="center" mt="27px">
        <AmountChoiceComponent
          isYearly={isYearly}
          subscription={subscription}
          setSubscriptionParameters={setSubscriptionParameters}
          subscriptionParameters={subscriptionParameters}
        />
      </Stack>
      {subscription.type === SubscriptionTypeEnum.COMPANY ? (
        <Stack px="20px" my="25px" width="100%" pb="80px">
          <Typography fontSize="14px" fontWeight={300} textAlign="center">
            <div dangerouslySetInnerHTML={{ __html: t("subscriptions.companyFeatures", { highlightColor: Colors.subscriptions.professional }) }} />
          </Typography>
        </Stack>
      ) : (
        <Stack spacing="6px" px="20px" mb="27px" width="100%">
          {subscription.features?.map((feature, index) => (
            <Stack key={index} direction="row" spacing="5px" mt="30px" alignItems="center">
              <GreenCheckIcon />
              <Typography fontSize="14px" fontWeight={feature.isBold ? 500 : 300} lineHeight="1.25">
                {t(`subscriptions.featuresLabel.${feature.type}`)}
              </Typography>
            </Stack>
          ))}
        </Stack>
      )}
      <Box width="180px" mt="auto" mb={isSpecialSubscription ? "33px" : undefined}>
        {getSubscriptionButton()}
      </Box>
      {!isSpecialSubscription && (
        <ShowMore alignItems="center" onClick={handleShowModal}>
          {t("subscriptions.showMore")}
        </ShowMore>
      )}
      {openChooseOffer && subscription.type === SubscriptionTypeEnum.COMPANY && (
        <SubscriptionContactModal handleClose={() => setOpenChooseOffer(false)} isYearly={isYearly} subscriptionParameters={subscriptionParameters} />
      )}
      {openChooseOffer && subscription.type === SubscriptionTypeEnum.PROFESSIONAL && (
        <ChooseProSubscriptionModal isYearly={isYearly} handleClose={() => setOpenChooseOffer(false)} selectedSubscription={subscriptionParameters} />
      )}
      {confirmCancelSubscription && (
        <ConfirmModal
          handleClose={() => setConfirmCancelSubscription(false)}
          handleConfirm={() => {
            stripeService.cancelSubscription().subscribe({
              next: () => enqueueSnackbar(t("subscriptions.cancelSubscription.success"), { variant: "success" }),
              error: (err) => enqueueSnackbar(err.text, err.options),
            });
          }}
          modalTitle={t("subscriptions.cancelSubscription.title")}
          confirmMsg={t("subscriptions.cancelSubscription.description")}
        />
      )}
      {openEmpoyerBrand && (
        <ModalComponent
          maxWidth="xs"
          icon={<img alt="" src="/images/illus_time.svg" />}
          title={t("subscriptions.employerBrand.title")}
          content={
            <Stack py="10px">
              <Typography textAlign="center" fontSize="14px" fontWeight="500" color={Colors.lightGrey}>
                {t("subscriptions.employerBrand.description")}
              </Typography>
            </Stack>
          }
          handleClose={() => setOpenEmployerBrand(false)}
        />
      )}
    </SubscriptionCard>
  );
};

export default SubscriptionColumnComponent;
