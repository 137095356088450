import React, { useState } from "react";
import { alpha, Avatar, Box, Card, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { sessionQuery } from "@store/session";
import { MentionItem, OnChangeHandlerFunc } from "react-mentions";
import { UserTagItem } from "@store/users";
import AIOTaggableTextfieldComponent from "./AIOTaggableTextfield.component";
import { Observable } from "rxjs";
import { Comment } from "@store/common/comments.model";
import MoreVertIcon from "@mui/icons-material/MoreVert";

interface CommentsProps {
  comments?: Comment[];
  fetchTags?: () => Observable<UserTagItem[]>;
  handleAddComment?: (comment: string, mentions: MentionItem[]) => void;
  handleUpdateComment?: (commentId: string, comment: string, mentions: MentionItem[]) => void;
  selectedCommentId?: string;
  shouldNotIncludeTwinners?: boolean;
  shouldOverflow?: boolean;
}

const CommentsComponent = (props: CommentsProps) => {
  const { comments = [], fetchTags, handleAddComment, selectedCommentId, shouldOverflow, shouldNotIncludeTwinners, handleUpdateComment } = props;

  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [popoverComment, setPopoverComment] = useState<Comment>();

  //CREATE
  const [newComment, setNewComment] = useState("");
  const [plainTextComment, setPlainTextComment] = useState("");
  const [mentions, setMentions] = useState<MentionItem[]>([]);

  //UPDATE
  const [updatedComment, setUpdatedComment] = useState("");
  const [plainTextUpdatedComment, setPlainTextUpdatedComment] = useState("");
  const [mentionsUpdated, setMentionsUpdated] = useState<MentionItem[]>([]);
  const [updateMode, setUpdateMode] = useState<string>();

  const picture = sessionQuery.userPicture;

  const onChangeNewComment: OnChangeHandlerFunc = (event, newValue, newPlainTextValue, mentions) => {
    setNewComment(newValue);
    setPlainTextComment(newPlainTextValue);
    setMentions(mentions);
  };
  const onChangeExistingComment: OnChangeHandlerFunc = (event, newValue, newPlainTextValue, mentions) => {
    setUpdatedComment(newValue);
    setPlainTextUpdatedComment(newPlainTextValue);
    setMentionsUpdated(mentions);
  };

  const handleClick = (event: React.MouseEvent, comment: Comment) => {
    setAnchorEl(event.currentTarget as any);
    setPopoverComment(comment);
  };

  const handleEditComment = () => {
    if (popoverComment) {
      setUpdateMode(popoverComment.id);
      setUpdatedComment(popoverComment.content);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Stack flex={1} overflow={shouldOverflow ? "auto" : undefined}>
      {!!handleAddComment && (
        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar style={{ width: 36, height: 36 }} src={picture} />
          <Stack width="100%">
            <AIOTaggableTextfieldComponent
              value={newComment}
              fetchTags={(query, callback: (userTagItems: UserTagItem[]) => void) => {
                if (fetchTags) {
                  fetchTags().subscribe({
                    next: (userTagItems) => {
                      if (!shouldNotIncludeTwinners) userTagItems.unshift({ id: "@twinin@", display: "Twinin" });
                      callback(userTagItems);
                    },
                  });
                } else {
                  callback([]);
                }
              }}
              onChange={onChangeNewComment}
              placeholder={t(`global.addComment`)}
              ariaLabel="comment"
              onKeyDown={(e) => {
                if (e.key === "Enter" && e.shiftKey) {
                } else if (e.keyCode === 13 && newComment && newComment.trim()) {
                  handleAddComment?.(plainTextComment, mentions);
                  setNewComment("");
                  e.preventDefault();
                }
              }}
            />
          </Stack>
        </Stack>
      )}
      <Box
        overflow={shouldOverflow ? "auto" : undefined}
        className={shouldOverflow ? "scrollable" : undefined}
        px={shouldOverflow ? "5px" : 0}
        py="25px">
        <Stack spacing={3}>
          {comments.map((comment) => (
            <Card
              key={comment.id}
              elevation={0}
              sx={{
                borderRadius: 5,
                overflow: 'visible',
                border: `1px solid ${selectedCommentId === comment.id ? "transparent" : "#EFEFEF"}`,
                backgroundColor: selectedCommentId === comment.id ? alpha(Colors.primary, 0.05) : undefined,
              }}>
              <Stack spacing={2} p="23px 23px 28px 23px">
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" spacing={1.5}>
                    <Avatar style={{ width: 36, height: 36 }} src={comment.author.picture} />
                    <Stack spacing={-0.25}>
                      <Typography>{`${comment.author.firstname} ${comment.author.lastname}`}</Typography>
                      <Typography fontSize="12px" fontWeight={300} color={Colors.secondaryText}>
                        {comment.author.position}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction={"row"} spacing={2}>
                    <Typography fontWeight={300} color={Colors.grey}>
                      {dayjs(comment?.updatedAt).fromNow(true)} {!dayjs(comment.createdAt).isSame(comment.updatedAt) ? t('global.updatedComment') : ''}
                    </Typography>
                    {comment.author.id === sessionQuery.user.id && (
                      <>
                        <MoreVertIcon style={{ width: "16px", cursor: "pointer" }} onClick={(e) => handleClick(e, comment)} />
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          aria-label="user-menu"
                          onClick={(evt) => evt.stopPropagation()}
                          anchorOrigin={{ vertical: "center", horizontal: "right" }}
                          transformOrigin={{ vertical: "top", horizontal: "right" }}>
                          <MenuItem
                            color="primary"
                            onClick={(evt) => {
                              evt.stopPropagation();
                              handleEditComment();
                              handleClose();
                            }}>
                            <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                              <img alt="delete" src="/images/icon_edit_fill_primary.svg" />
                            </Stack>
                            {t("global.updateComment")}
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </Stack>
                </Stack>
                {updateMode === comment.id ? (
                  <AIOTaggableTextfieldComponent
                    value={updatedComment}
                    fetchTags={(query, callback: (userTagItems: UserTagItem[]) => void) => {
                      if (fetchTags) {
                        fetchTags().subscribe({
                          next: (userTagItems) => {
                            if (!shouldNotIncludeTwinners) userTagItems.unshift({ id: "@twinin@", display: "Twinin" });
                            callback(userTagItems);
                          },
                        });
                      } else {
                        callback([]);
                      }
                    }}
                    onChange={onChangeExistingComment}
                    placeholder={t(`global.updateComment`)}
                    ariaLabel="comment"
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && e.shiftKey) {
                      } else if (e.keyCode === 13 && updatedComment && updatedComment.trim()) {
                        handleUpdateComment?.(comment.id, plainTextUpdatedComment, mentionsUpdated);
                        setUpdatedComment("");
                        setUpdateMode(undefined);
                        e.preventDefault();
                      }
                    }}
                  />
                ) : (
                  <>
                    <Typography fontWeight={300} color={Colors.black} lineHeight="1.25">
                      {comment.content}
                    </Typography>
                  </>
                )}
              </Stack>
            </Card>
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export default CommentsComponent;
