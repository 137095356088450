import { Colors } from "@constants/colors.constant";
import { alpha, Autocomplete, InputLabel, Paper, TextField } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectItem } from "./Select.component";
import styled from "@emotion/styled";

const StyledTextField = styled(TextField)((props: any) => ({
  backgroundColor: props.disabled ? alpha(Colors.primary, 0.05) : alpha(Colors.primary, 0.1),
  borderRadius: "7px",
  "& .MuiSvgIcon-root": {
    fill: Colors.primary,
  },
  "& .MuiAutocomplete-endAdornment": {
    display: props.disabled ? "none" : "block",
  },
  height: "35px",
  "& .MuiInputBase-root": {
    height: "35px",
    padding: "0 13px",
    color: Colors.primary,
  },
  "& .MuiInputBase-input::placeholder": {
    fontWeight: 500,
    fontSize: "0.875rem",
    opacity: 1,
  },
})) as typeof TextField;

const StyledPaper = styled(Paper)((props: any) => ({
  borderRadius: "15px",
  boxShadow: `0 8px 20px 0 ${alpha(Colors.black, 0.1)}`,
  "& ul": {
    margin: "10px 0",
    padding: 0,
    listStyleType: "none",
  },
  "& li": { padding: "5px 15px", cursor: "pointer", "&:hover": { backgroundColor: alpha(Colors.primary, 0.1) } },
}));

interface AsyncSelectWithSearchComponentProps {
  ariaLabel?: "large-primary";
  disabled?: boolean;
  error?: boolean;
  getOptions: (search?: string) => Promise<SelectItem[]>;
  handleChange?: (value?: SelectItem) => void;
  label?: string;
  minimumSearchLength?: number;
  noResultComponent?: JSX.Element;
  placeholder?: string;
  readOnly?: boolean;
  required?: boolean;
  searchIfEmpty?: boolean;
  size?: "large" | "normal";
  value?: SelectItem | "";
}

const AsyncSelectWithSearchComponent = (props: AsyncSelectWithSearchComponentProps) => {
  const {
    ariaLabel,
    disabled,
    error,
    getOptions,
    handleChange,
    label,
    minimumSearchLength,
    noResultComponent,
    placeholder,
    readOnly,
    required,
    searchIfEmpty,
    value = "",
  } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<SelectItem[]>([]);

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let active = true;

    if ((!searchIfEmpty && inputValue === "") || (!!minimumSearchLength && inputValue.length < minimumSearchLength)) {
      setOptions(value ? [value] : []);
      return undefined;
    }

    if (active && (!minimumSearchLength || inputValue.length >= minimumSearchLength)) {
      setLoading(true);
      (async () => {
        const results = await getOptions(inputValue);

        let newOptions: SelectItem[] = [];
        if (!!value) newOptions = [value];
        if (results) newOptions = [...newOptions, ...results];

        setOptions(newOptions);
        setLoading(false);
      })();
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, getOptions, minimumSearchLength, searchIfEmpty]);

  return (
    <Box display="flex" flexDirection="column" width="100%">
      {!!label && <InputLabel error={error}>{label + (required ? "*" : "")}</InputLabel>}
      <Autocomplete
        getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value || null}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        loading={loading}
        disabled={disabled}
        open={minimumSearchLength && inputValue.length < minimumSearchLength ? false : open}
        onChange={(event: any, newValue: SelectItem | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          handleChange?.(newValue || undefined);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        PaperComponent={(params) => {
          return (
            <StyledPaper {...params}>
              {!!(params.children as any)?.[0] && <Box style={{ padding: "5px 15px" }} {...(params.children as any)[0].props}>{(params.children as any)[0].props.children}</Box>}
              {!noResultComponent && !!(params.children as any)?.[1] && (
                <Box style={{ padding: "5px 15px" }} {...(params.children as any)[1].props}>
                  {(params.children as any)[1].props.children}
                </Box>
              )}
              <Box {...(params.children as any)[2]?.props} className="MuiAutocomplete-listbox" onMouseDown={(evt: any) => evt.preventDefault()}>
                {!!(params.children as any)?.[2] && (params.children as any)[2].props.children}
                {noResultComponent}
              </Box>
            </StyledPaper>
          );
        }}
        noOptionsText={t("global.noOptions")}
        loadingText={t("global.loading")}
        popupIcon={<img alt="" src="/images/arrow_dropdownlist_primary.svg" />}
        renderInput={(params) => (
          <StyledTextField
            {...params}
            variant="outlined"
            color="primary"
            focused={readOnly ? false : undefined}
            placeholder={placeholder}
            disabled={disabled}
            error={!!error && !disabled}
            aria-label={ariaLabel}
          />
        )}
      />
    </Box>
  );
};

export default AsyncSelectWithSearchComponent;
