import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { companiesService, CompanyLight } from "@store/ai-o/companies";

import ModalComponent from "@components/modal/Modal.component";
import CompanyAioCard from "@components/card/CompanyAio.card";
import { tap } from "rxjs";

interface ClientsNotReattachedModalProps {
  handleClose: () => void;
}

const ClientsNotReattachedModal = (props: ClientsNotReattachedModalProps) => {
  const {handleClose} = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [companies, setCompanies] = useState<CompanyLight[]>([]);

  useEffect(() => {
    companiesService.getNotReattachedCompaniesWithApplications().subscribe({
      next: setCompanies,
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("aio.applications.companiesNotReattached")}
      titleLeft
      content={(
        <Grid container spacing={4} pt={5}>
          {companies.map((company) => {
            return (
              <Grid item xs={4} key={company.id}>
                <CompanyAioCard
                  handleReattachSuccess={() =>
                    companiesService.getNotReattachedCompaniesWithApplications().pipe((tap(setCompanies)))
                  }
                  company={company} />
              </Grid>
            )}
          )}
        </Grid>
      )} />
  );
};

export default ClientsNotReattachedModal;
