import { select } from "@ngneat/elf";

import {
  getClientActionsDataSource,
  getClientCommentsDataSource,
  getClientDetailsDataSource
} from "@store/entities/clients/details/clientDetails.requests";
import { clientDetailsStore } from "@store/entities/clients/details/clientDetails.store";

export class ClientDetailsQuery {
  store = clientDetailsStore;

  details$ = getClientDetailsDataSource.data$();
  comments$ = getClientCommentsDataSource.data$();
  actions$ = getClientActionsDataSource.data$();
  actionsPaginatedMeta$ = this.store.pipe(select((state) => state.actionsPaginatedMeta));

  clientDetails$ = this.store.pipe(select((state) => state.details))
}

export const clientDetailsQuery = new ClientDetailsQuery();
