import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";
import { affiliatesService } from "@store/entities/afiliates";

import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import { SelectItem } from "@components/input/Select.component";
import { AFFILIATES_FILTER_STORAGE_KEY } from "@constants/localStorage.constant";
import { STORAGE } from "@constants/events.constant";

interface ClientsFilterComponentProps {
  handleChange?: (values: SelectItem[]) => void;
  noStorageUpdate?: boolean;
  values?: SelectItem[];
}

const ClientsFilterComponent = (props: ClientsFilterComponentProps) => {
  const { handleChange, noStorageUpdate, values } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [affiliates, setAffiliates] = useState<SelectItem[]>([]);

  useEffect(() => {
    affiliatesService.getAffiliates().pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (affiliates) => {
          setAffiliates(
            affiliates.data.map((a) => ({
              label: a.name,
              value: a.id,
              data: { groupName: a.client.name, parentClientId: a.client.id },
            }))
              .sort((a, b) => a.data.groupName.localeCompare(b.data.groupName))
          );
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }, [enqueueSnackbar]);

  return (
    <MultiSelectWithSearchComponent
      handleChange={(values) => {
        const isFavorites = values.length && values[values.length - 1].value === "FAVORITES";

        if (!noStorageUpdate) {
          if (!values.length) localStorage.removeItem(AFFILIATES_FILTER_STORAGE_KEY);
          else if (isFavorites) {
            localStorage.setItem(AFFILIATES_FILTER_STORAGE_KEY, JSON.stringify(values.filter((v) => v.value === "FAVORITES")));
          } else {
            localStorage.setItem(AFFILIATES_FILTER_STORAGE_KEY, JSON.stringify(values.filter((v) => v.value !== "FAVORITES")));
          }
          window.dispatchEvent(new Event(STORAGE));
        }

        handleChange?.(values.filter((v) => (isFavorites ? v.value === "FAVORITES" : v.value !== "FAVORITES")));
      }}
      loading={loading}
      startIcon={<img alt="" src="/images/icon_select_company.svg" />}
      multiSelectedLabel={(count) => t("global.companiesCount", { count }).toString()}
      placeholder={t("global.filterByCompanies")}
      items={[{ label: t("global.favorites"), value: "FAVORITES" }, ...affiliates]}
      values={values}
      handleGroupBy={(option) => {
        if (!option.data) return "";
        return option.data.groupName;
      }}
    />
  );
};

export default ClientsFilterComponent;
