import { select } from "@ngneat/elf";
import { selectPaginationData } from "@ngneat/elf-pagination";

import { candidatesStore } from "./candidates.store";
import { getPaginatedCandidatesDataSource } from "./candidates.requests";

export class CandidatesQuery {
  store = candidatesStore;

  candidates$ = getPaginatedCandidatesDataSource.data$();
  candidatesPaginationData$ = this.store.pipe(selectPaginationData());

  filters$ = this.store.pipe(select((state) => state.filters));
  
  sort$ = this.store.pipe(select((state) => state.sort));
}

export const candidatesQuery = new CandidatesQuery();
