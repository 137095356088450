import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { stripeProductsStore } from "./stripeProducts.store";

export const getStripeProductsDataSource = createRequestDataSource({
  store: stripeProductsStore,
  data$: () => stripeProductsStore.pipe(selectAllEntities()),
  requestKey: "getStripeProducts",
  dataKey: "stripeProducts",
});
