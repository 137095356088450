import { Stack, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";

import { AdminParametersRoutes } from "@utils/routes.utils";

import AIORoutesTabComponent from "@components/AIORoutesTab.component";

const AdminParametersTsx = () => {
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack width="100%" spacing={1} flex={1} overflow="auto">
      <Stack alignItems="center" py={breakpointDownSM ? "20px" : "40px"}>
        <AIORoutesTabComponent routes={AdminParametersRoutes.routes} />
      </Stack>
      <Outlet />
    </Stack>
  );
};

export default AdminParametersTsx;
