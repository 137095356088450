import BackToTopButton from "@components/BackToTopButton.component";
import EntityCard from "@components/card/Entity.card";
import { Box, CircularProgress, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import usePaginatedEntitiesStore from "@store/entities/usePaginatedEntitiesStore";
import { InfiniteScrollContainer, VirtualScrollItem } from "@utils/infinitescroll.utils";
import { AffiliateRoutes, ClientRoutes } from "@utils/routes.utils";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EntityTypeEnum } from "@store/entities/entities.model";

interface EntitiesListProps {
  entityType: EntityTypeEnum;
  notInTab?: boolean;
}

const EntitiesList = (props: EntitiesListProps) => {
  const { entityType, notInTab } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const { entities, entitiesPaginationData, loading, itemsPerPage, handleNextPage } = usePaginatedEntitiesStore(entityType);

  const backToTop = () => {
    scrollableContainerRef?.current?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box
      overflow={breakpointDownSM ? undefined : "auto"}
      className={breakpointDownSM ? undefined : "scrollable"}
      py={breakpointDownSM ? "0px" : "30px"}
      px={notInTab ? (breakpointDownSM ? "0px" : "20px") : "10px"}
      ref={scrollableContainerRef}>
      <Stack spacing={2}>
        <Typography fontSize="16px" fontWeight="700">
          {t(`clients.${entityType === EntityTypeEnum.AFFILIATE ? "affiliate" : "client"}`, { count: entitiesPaginationData.total })}
        </Typography>
        <Box>
          <Grid container spacing={3}>
            <InfiniteScrollContainer
              nextPageHandler={handleNextPage}
              itemsPerPage={itemsPerPage}
              componentType="Grid"
              listItems={entities.map((entity) => (
                <VirtualScrollItem
                  key={entity.id}
                  height={133}
                  children={
                    <EntityCard
                      entity={entity}
                      handleClick={() =>
                        entityType === EntityTypeEnum.AFFILIATE
                          ? navigate(`${entity.id}/${AffiliateRoutes.DETAILS}`)
                          : navigate(`../${entity.id}/${ClientRoutes.DETAILS}`)
                      }
                    />
                  }
                />
              ))}
            />
          </Grid>
        </Box>
        <Stack direction="row" width="100%" justifyContent="center" mt="20px" height={"80px"} alignItems="center">
          {loading && <CircularProgress size={30} />}
        </Stack>
        <BackToTopButton onClick={backToTop} posRight={breakpointDownSM ? "25px" : "60px"} />
      </Stack>
    </Box>
  );
};

export default EntitiesList;
