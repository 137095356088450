import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { RegisterUserModel, RegisterUsersFilters } from "./registerUsers.model";

interface RegisterUsersStoreProps {
  filters: RegisterUsersFilters;
}

export const registerUsersStore = createStore(
  { name: "register-users" },
  withProps<RegisterUsersStoreProps>({
    filters: {},
  }),
  withEntities<RegisterUserModel>(),
  withRequestsStatus()
);
