import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { UserModel, UsersFilters, UsersSort, UsersSortField } from "@store/users/users.model";

interface UsersStoreProps {
  filters: UsersFilters;
  sort: UsersSort;
}

export const usersStore = createStore(
  {name: "users"},
  withProps<UsersStoreProps>({
    filters: {},
    sort: {field: UsersSortField.LASTNAME},
  }),
  withEntities<UserModel>(),
  withRequestsStatus(),
);
