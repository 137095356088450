import React from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";

import { ParametersRoutes } from "@utils/routes.utils";

import AIORoutesTabComponent from "@components/AIORoutesTab.component";

const ClientParameters = () => {
  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : sessionQuery.role === UserRoleEnum.ADMIN ? "30px" : "60px"}
      px={breakpointDownSM ? "10px" : "40px"}
      spacing={breakpointDownSM ? 1 : 2}
      width="100%"
      overflow="hidden"
      height="100%">
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography fontSize={breakpointDownMD ? "30px" : "35px"} fontWeight={700} lineHeight={1.25}>
          {t(`parameters.title`)}
        </Typography>
      </Stack>
      <AIORoutesTabComponent
        routes={ParametersRoutes.routes}
        disableCenter />
      <Outlet />
    </Stack>
  );
};

export default ClientParameters;
