import { useState, useEffect, useCallback } from "react";

interface WindowSize {
  height: number;
}

const useWindowHeight = (): WindowSize => {
  const [windowSize, setWindowHeight] = useState<WindowSize>({
    height: window.innerHeight,
  });

  const handleResize = useCallback(() => {
    if (window.innerHeight !== windowSize.height) {
      setWindowHeight({
        height: window.innerHeight,
      });
    }
  }, [windowSize.height]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return windowSize;
};

export default useWindowHeight;
