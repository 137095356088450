import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { StripeProductModel, StripeProductsFilters } from "./stripeProducts.model";

interface StripeProductsStoreProps {
  filters: StripeProductsFilters;
}

export const stripeProductsStore = createStore(
  { name: "stripe-products" },
  withProps<StripeProductsStoreProps>({ filters: {} }),
  withEntities<StripeProductModel>(),
  withRequestsStatus(),
);
