import { EntityTypeEnum } from "@store/entities/entities.model";
import { clientDetailsQuery } from "@store/entities/clients/details";
import { affiliateDetailsQuery } from "@store/entities/afiliates/details";

export class EntityDetailsQuery {
  comments$ = (type: EntityTypeEnum) => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsQuery.comments$;
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsQuery.comments$;
    }
  };

  details$ = (type: EntityTypeEnum) => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsQuery.details$;
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsQuery.details$;
    }
  };
  actions$ = (type: EntityTypeEnum) => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsQuery.actions$;
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsQuery.actions$;
    }
  };
  actionsPaginatedMeta$ = (type: EntityTypeEnum) => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsQuery.actionsPaginatedMeta$;
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsQuery.actionsPaginatedMeta$;
    }
  };

  entity$ = (type: EntityTypeEnum) => {
    switch (type) {
      case EntityTypeEnum.CLIENT:
        return clientDetailsQuery.clientDetails$;
      case EntityTypeEnum.AFFILIATE:
        return affiliateDetailsQuery.affiliateDetails$;
    }
  };
}

export const entityDetailsQuery = new EntityDetailsQuery();
