import { Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useRouteMatch } from "@utils/routes.utils";

interface AIORoutesTabComponentProps {
  disableCenter?: boolean;
  routes: { label: string; path: string }[];
  handleChange?: () => void;
  variant?: "scrollable" | "standard";
}

const AIORoutesTabComponent = (props: AIORoutesTabComponentProps) => {
  const { disableCenter, routes, handleChange, variant } = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const currentTab = useRouteMatch(routes.map((route) => route.path));

  return (
    <Tabs
      sx={{ maxWidth: "100%" }}
      centered={!disableCenter}
      variant={variant ?? (breakpointDownSM ? "scrollable" : "standard")}
      scrollButtons="auto"
      onChange={handleChange}
      value={currentTab || routes[0]?.path}
      TabIndicatorProps={{ style: { display: "none" } }}>
      {routes.map((route, index) => (
        <Tab
          key={`Tab ${index} - ${route.label}`}
          label={route.label}
          component={NavLink}
          to={route.path}
          value={route.path}
          sx={{ px: breakpointDownSM ? "10px" : "20px", py: breakpointDownSM ? "5px" : "8px" }}
        />
      ))}
    </Tabs>
  );
};

export default AIORoutesTabComponent;
