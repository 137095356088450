import { Colors } from "@constants/colors.constant";
import Eye from "@mui/icons-material/RemoveRedEye";
import { alpha, Button, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import CompanyValuesBlock from "@screens/auth/admin/aio/customerDetails/profileBlocks/CompanyValues.block";
import CoverPictureBlock from "@screens/auth/admin/aio/customerDetails/profileBlocks/CoverPicture.block";
import InformationBlock from "@screens/auth/admin/aio/customerDetails/profileBlocks/Information.block";
import MediaBlock from "@screens/auth/admin/aio/customerDetails/profileBlocks/Media.block";
import PresentationBlock from "@screens/auth/admin/aio/customerDetails/profileBlocks/Presentation.block";
import SocialMediaBlock from "@screens/auth/admin/aio/customerDetails/profileBlocks/SocialMedia.block";
import { companytDetailsQuery } from "@store/ai-o/companies/details";
import ColorThemeBlock from "./profileBlocks/ColorTheme.block";
import LightInfosBlock from "./profileBlocks/LightInfos.block";
import TestimonialBlock from "./profileBlocks/Testimonial.block";
import VideoBlock from "./profileBlocks/Video.block";
import { useTranslation } from "react-i18next";
import CompanyLogoBlock from "./profileBlocks/CompanyLogo.block";

const CustomerCompanyProfileTab = () => {
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpoint1275px = useMediaQuery("(max-width: 1275px)");
  const breakpoint1350px = useMediaQuery("(max-width: 1350px)");
  const { t } = useTranslation();

  const [company] = useObservable(companytDetailsQuery.companyDetails$);

  if (!company) return null;

  return (
    <>
      <Stack width="100%" alignItems="center" py={breakpoint1275px ? "20px" : "40px"} overflow="auto" className="scrollable">
        <Container maxWidth="md" sx={{ px: breakpointDownSM ? "10px" : undefined, pb: breakpointDownSM ? 0 : "40px" }}>
          <Stack spacing={breakpoint1275px ? 2 : 0} alignItems="flex-end" position={"relative"} justifyContent={"flex-end"}>
            {breakpoint1275px ? (
              <a target="_blank" rel="noreferrer" href={encodeURI(process.env.REACT_APP_AIO_FRONT_URL + `companies/${company.name}?career=true`)}>
                <Button
                  variant="contained"
                  sx={{
                    width: "100%",
                    backgroundColor: alpha(Colors.primary, 0.8),
                    "&:disabled": {
                      backgroundColor: Colors.background,
                    },
                  }}>
                  <Eye style={{ fontSize: "20px", color: Colors.white }} />
                  <Typography variant="h6" color="white" sx={{ fontWeight: 600, ml: 1 }}>
                    {t("aio.clients.details.preview")}
                  </Typography>
                </Button>
              </a>
            ) : (
              <Stack
                sx={{
                  zIndex: 99,
                  position: breakpointDownSM ? undefined : "fixed",
                  width: breakpointDownSM ? "100%" : undefined,
                  top: breakpointDownSM ? undefined : "265px",
                  transform: breakpointDownSM ? "translateX(0px)" : "translateX(90px)",
                  alignItems: "flex-start",
                  "&:hover": {
                    transform: breakpoint1350px ? "translateX(90px)" : "translateX(130px)",
                  },
                }}>
                <a target="_blank" rel="noreferrer" href={encodeURI(process.env.REACT_APP_AIO_FRONT_URL + `companies/${company.name}?career=true`)}>
                  <Button
                    variant="contained"
                    sx={{
                      zIndex: 100,
                      backgroundColor: Colors.primary,
                      width: "fit-content",
                      "&:hover": {
                        "& .hover-text": {
                          display: breakpoint1350px ? "none" : "block",
                        },
                      },
                    }}>
                    <Eye style={{ fontSize: "20px", color: Colors.white }} />
                    <Typography variant="body2" color="white" sx={{ ml: 1 }} display="none" className="hover-text">
                      Preview
                    </Typography>
                  </Button>
                </a>
              </Stack>
            )}
            <Stack direction={breakpointDownSM ? "column" : "row"} spacing={breakpointDownSM ? 2 : 4} width="100%">
              <Stack spacing={breakpointDownSM ? 2 : 4} width={breakpointDownSM ? "100%" : "50%"}>
                <InformationBlock company={company} />
                <LightInfosBlock company={company} />
                <PresentationBlock company={company} />
                <MediaBlock company={company} type="image" />
                <SocialMediaBlock company={company} />
                <ColorThemeBlock company={company} />
              </Stack>
              <Stack spacing={breakpointDownSM ? 2 : 4} width={breakpointDownSM ? "100%" : "50%"} position={"relative"}>
                <CompanyValuesBlock company={company} />
                <CompanyLogoBlock company={company} />
                <CoverPictureBlock company={company} />
                <VideoBlock company={company} />
                <TestimonialBlock company={company} />
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default CustomerCompanyProfileTab;
