import { Colors } from "@constants/colors.constant";
import {Components} from "@mui/material";

const formControlLabel: Components = {
  MuiFormControlLabel: {
    styleOverrides: {
        label: {
            fontWeight: 300,
            fontSize: "14px",
            color: Colors.secondary,
            paddingLeft: "10.7px"
        },
    },
  },
};

export default formControlLabel;