import React, { FormEvent, useEffect, useState } from "react";
import { IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { useTranslation } from "react-i18next";
import { FieldErrors } from "@utils/yup.utils";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import { finalize } from "rxjs";
import { resetPasswordValidation } from "./forgotPassword.yup";
import { sessionService } from "@store/session";
import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom";
import { RoutesUnAuth } from "@utils/routes.utils";

const ResetPasswordContainer = styled(Stack)({
  backgroundColor: Colors.white,
  borderRadius: "15px",
  width: "365px",
  paddingTop: "37px",
  paddingBottom: "42px",
  boxShadow: "0 0 20px 0 rgba(34,34,64,0.05)"
});

const LostPassword = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({ password: "", passwordConfirmation: "" });
  const [errors, setErrors] = useState<FieldErrors>({});

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const code = searchParams.get("code");

  const updateData = (name: string) => (value: any) => setData({ ...data, [name]: value });

  const handleSubmit = (evt: FormEvent) => {
    evt.preventDefault();
    const errors = resetPasswordValidation(data.password, data.passwordConfirmation);
    setErrors(errors);

    if (!!Object.keys(errors).length) return;

    if (token) {
      setLoading(true);
      sessionService
        .resetPassword(data.password, token)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            navigate({
              pathname: `../${RoutesUnAuth.LOGIN}`,
              search: createSearchParams({
                msg: t("auth.forgotPassword.resetPasswordSuccess"),
                variant: "success"
              }).toString()
            });
          },
          error: (err) => {
            navigate({
              pathname: `../${RoutesUnAuth.LOGIN}`,
              search: createSearchParams({
                msg: t("errors.invalidOrExpiredToken"),
                variant: "error"
              }).toString()
            });
          }
        });
    }
  };

  useEffect(() => {
    if (!token || !code || Date.now() > parseInt(code)) {
      navigate({
        pathname: `../${RoutesUnAuth.LOGIN}`,
        search: createSearchParams({
          msg: t("errors.invalidOrExpiredToken"),
          variant: "error"
        }).toString()
      });
    }
  }, [token, code, navigate, t]);

  return (
    <Stack
      direction="column"
      height="100%"
      justifyContent="center"
      alignItems="center"
      spacing={breakpointDownSM ? 3 : 6}>
      <img alt="aio logo" height={140} width={113} src="/images/logo_txt.svg" />

      <ResetPasswordContainer direction="column" spacing={3} px={3.75}>
        <Typography fontSize="20px" fontWeight="700" mb="11px" textAlign="left">
          {t("auth.resetPassword")}
        </Typography>

        <form onSubmit={handleSubmit} noValidate>
          <Stack direction="column" spacing={3}>
            <AIOTextfieldComponent
              title={t("auth.newPassword")}
              placeholder={t("auth.newPassword")}
              onChange={updateData("password")}
              value={data.password || ""}
              error={errors.password}
              endAdornment={(
                <IconButton size="small" sx={{mr: '-5px'}} onClick={() => setShowPassword((state) => !state)}>
                  <img alt="show password" src={`/images/icon_${showPassword ? 'hide' : 'show'}_pwd_primary.svg`} />
                </IconButton>
              )}
              type={showPassword ? undefined : "password"}
              autoComplete={"off"}
              required
            />

            <Stack direction="column" spacing={1}>
              <AIOTextfieldComponent
                title={t("auth.passwordConfirmation")}
                placeholder={t("auth.passwordConfirmation")}
                onChange={updateData("passwordConfirmation")}
                value={data.passwordConfirmation || ""}
                error={errors.confirmedPassword}
                endAdornment={(
                  <IconButton size="small" sx={{mr: '-5px'}} onClick={() => setShowConfirmPassword((state) => !state)}>
                    <img alt="show password" src={`/images/icon_${showConfirmPassword ? 'hide' : 'show'}_pwd_primary.svg`} />
                  </IconButton>
                )}
                type={showConfirmPassword ? undefined : "password"}
                autoComplete={"off"}
                required
              />
            </Stack>

            <Stack direction="row" justifyContent="center" pt="34px">
              <AIOButtonComponent
                title={t("global.confirm")}
                variant="contained"
                color="secondary"
                disabled={loading}
                isSubmit />
            </Stack>
          </Stack>
        </form>
      </ResetPasswordContainer>
    </Stack>
  );
};

export default LostPassword;
