import { entitiesFiltersStore } from "@store/entities/entitiesFilters/entitiesFilters.store";
import { EntitiesFiltersModel, EntitiesSortModel } from "@store/entities/entitiesFilters/entitiesFilters.model";

export class EntitiesFiltersService {
  store = entitiesFiltersStore;

  resetStore = () => this.store.reset();

  setFilters = (filters: Partial<EntitiesFiltersModel>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  setSort = (sort: Partial<EntitiesSortModel>) =>
    this.store.update((state) => ({
      ...state,
      sort: {
        ...state.sort,
        ...sort,
      },
    }));
}

export const entitiesFiltersService = new EntitiesFiltersService();
