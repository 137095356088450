import { alpha, Box, IconButton, Stack, Typography } from "@mui/material";
import { Report, reportsService } from "@store/reports";
import React, { useState } from "react";
import { Colors } from "@constants/colors.constant";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { filesService } from "@store/files";
import ConfirmModal from "@components/modal/Confirm.modal";
import { finalize } from "rxjs";

interface ReportCardProps {
  report: Report;
  small?: boolean;
  adminView?: boolean;
}

const ReportCard = (props: ReportCardProps) => {
  const { report, small, adminView } = props;
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const handleDownload = () => {
    filesService.openUrlInNewTab(report.downloadURL);
    reportsService.incrementDownload(report.id).subscribe();
  };

  const handleDelete = () => {
    setDeleteLoading(true);
    reportsService.deleteReport(report.id).pipe(finalize(() => setDeleteLoading(false))).subscribe({
      next: () => enqueueSnackbar(t(`reports.deleteSuccess`), { variant: "success" }),
      error: (err) => enqueueSnackbar(err.text, err.options)
    });
  };

  return (
    <>
      <Box
        sx={{
          border: !adminView && report.isNew ? undefined : "1px solid #EFEFEF",
          boxShadow: !adminView && report.isNew ? `0 1px 20px 0 ${alpha(Colors.black, 0.04)}` : undefined,
          borderRadius: "15px"
        }}>
        <Stack
          direction="row"
          alignItems="center"
          bgcolor="white"
          style={{ padding: "15px", borderRadius: "15px" }}
          flexWrap={adminView ? "wrap" : "nowrap"}
          position="relative">
          <Stack
            direction={small ? "column" : "row"}
            alignItems={small ? "flex-start" : "center"}
            flex={1}
            spacing={small ? 0 : 2}
            justifyContent="flex-start"
            mr={!adminView && small ? "10px" : 0}>
            <Typography fontSize={small ? 12 : 14}>{report.name}</Typography>
            <Typography fontSize={small ? 12 : 14} fontWeight={300} color={Colors.secondaryText}
                        textAlign={small ? "right" : "center"}>
              {t("global.fromTo", { from: new Date(report.from), to: new Date(report.to) })}
            </Typography>
          </Stack>

          {!adminView && report.isNew && (
            <Stack
              position="absolute"
              top={"-7px"}
              left={0}
              right={0}
              margin="auto !important"
              height="15px"
              width="fit-content"
              bgcolor={Colors.primary}
              borderRadius="4px"
              alignItems="center"
              justifyContent="center">
              <Typography mx={"10px"} color="white" fontWeight={500} fontSize={11}>
                {t("global.new")}
              </Typography>
            </Stack>
          )}
          <Stack
            direction={small ? "column" : "row"}
            justifyContent={adminView && small ? "space-between" : "flex-start"}
            alignItems={small ? "flex-end" : "center"}
            ml={small ? 0 : "10px"}
            mt={adminView && small ? "5px" : 0}>
            {adminView && (
              <Typography
                mr={!small ? "20px !important" : 0}
                fontSize={small ? 12 : 14}
                fontWeight={300}
                color={Colors.secondaryText}
                whiteSpace="nowrap">
                {`${t("global.download", { count: report.downloads })}`}
              </Typography>
            )}
            <Stack direction="row">
              <IconButton disableRipple disabled={deleteLoading} onClick={handleDownload}>
                <img alt="download" src="/images/icon_download_file.svg" />
              </IconButton>
              {adminView && (
                <IconButton disableRipple disabled={deleteLoading} onClick={() => setOpenDeleteConfirmation(true)}>
                  <img alt="delete" src="/images/icon_delete.svg" />
                </IconButton>
              )}
            </Stack>
          </Stack>
        </Stack>
      </Box>
      {openDeleteConfirmation && (
        <ConfirmModal
          modalTitle={t("reports.deleteTitle")}
          confirmMsg={t("reports.deleteDescription")}
          handleClose={() => setOpenDeleteConfirmation(false)}
          handleConfirm={() => handleDelete()} />
      )}
    </>
  );
};

export default ReportCard;