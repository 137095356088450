import React, { useState } from "react";
import ModalComponent from "@components/modal/Modal.component";
import { IconButton, Stack } from "@mui/material";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { useTranslation } from "react-i18next";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import { sessionService, UpdatePassword } from "@store/session";
import AIOButtonComponent from "@components/Button.component";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

interface ModifyPasswordModalProps {
  handleClose: () => void;
}

const ModifyPasswordModal = (props: ModifyPasswordModalProps) => {
  const { handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<UpdatePassword>({});

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const updateData = (name: string) => (value: any) => setData((state) => ({ ...state, [name]: value }));

  const handleSave = () => {
    setLoading(true);
    sessionService.updateUserPassword(data).pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        enqueueSnackbar(t("auth.updatePassword.success"), { variant: "success" });
        handleClose();
      },
      error: (err) => enqueueSnackbar(err.text, err.options)
    });
  };

  const errors = {
    oldPassword: getFieldError(data.oldPassword, FieldValidationType.REQUIRED_STRING),
    newPassword: getFieldError(data.newPassword, FieldValidationType.REQUIRED_PASSWORD),
    confirmNewPassword: getFieldError(data.confirmNewPassword, FieldValidationType.REQUIRED_STRING) ?? (data.confirmNewPassword !== data.newPassword ? t("errors.passwordMatch") : undefined)
  };

  return (
    <ModalComponent
      maxWidth="xs"
      title={t("auth.updatePassword.title")}
      handleClose={handleClose}
      content={(
        <Stack py="20px" spacing={3}>
          <AIOTextfieldComponent
            required
            title={t("auth.password")}
            endAdornment={(
              <IconButton size="small" sx={{mr: '-5px'}} onClick={() => setShowPassword((state) => !state)}>
                <img alt="show password" src={`/images/icon_${showPassword ? 'hide' : 'show'}_pwd_primary.svg`} />
              </IconButton>
            )}
            type={showPassword ? undefined : "password"}
            onChange={updateData("oldPassword")}
            value={data.oldPassword ?? ""}
            error={errors.oldPassword} />
          <AIOTextfieldComponent
            required
            title={t("auth.newPassword")}
            endAdornment={(
              <IconButton size="small" sx={{mr: '-5px'}} onClick={() => setShowNewPassword((state) => !state)}>
                <img alt="show password" src={`/images/icon_${showNewPassword ? 'hide' : 'show'}_pwd_primary.svg`} />
              </IconButton>
            )}
            type={showNewPassword ? undefined : "password"}
            onChange={updateData("newPassword")}
            value={data.newPassword ?? ""}
            error={errors.newPassword} />
          <AIOTextfieldComponent
            required
            title={t("auth.passwordConfirmation")}
            endAdornment={(
              <IconButton size="small" sx={{mr: '-5px'}} onClick={() => setShowConfirmPassword((state) => !state)}>
                <img alt="show password" src={`/images/icon_${showConfirmPassword ? 'hide' : 'show'}_pwd_primary.svg`} />
              </IconButton>
            )}
            type={showConfirmPassword ? undefined : "password"}
            onChange={updateData("confirmNewPassword")}
            value={data.confirmNewPassword ?? ""}
            error={errors.confirmNewPassword} />
        </Stack>
      )}
      actions={(
        <AIOButtonComponent
          variant="contained"
          disabled={loading || checkIfErrors(errors)}
          onClick={handleSave}
          title={t("account.save")} />
      )} />
  );
};

export default ModifyPasswordModal;