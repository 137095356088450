import { Button, CircularProgress, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffectFn } from "@ngneat/effects-hooks";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import { reportsQuery, reportsService, searchReportsEffect } from "@store/reports";

import ReportCard from "@components/card/Report.card";
import AIOSearchComponent from "@components/input/AIOSearch.component";
import AddReportModal from "@screens/auth/admin/entities/components/AddReport.modal";
import { emitOnce } from "@ngneat/elf";

const EntityFileReportsTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const searchReports = useEffectFn(searchReportsEffect);

  const { entityId } = useParams();

  const [{ reports, error: reportsError, loading: reportsLoading }] = useObservable(reportsQuery.reports$);

  const [filters] = useObservable(reportsQuery.filters$);

  const [addReportModalOpen, setAddReportModalOpen] = useState(false);

  useEffect(() => {
    emitOnce(() => {
      reportsService.resetStore();
      if (entityId) {
        reportsService.setFilters({ affiliateIds: [{ value: entityId, label: ""}]})
      }
    });
  }, [entityId]);

  useEffect(() => {
    if (reportsError) enqueueSnackbar((reportsError as any).text, (reportsError as any).options);
  }, [reportsError, enqueueSnackbar]);

  useEffect(() => {
    searchReports({ filters });
  }, [filters, searchReports]);

  if (!entityId) return null;

  return (
    <>
      <Stack direction="row" alignItems="center" alignSelf="center" justifyContent="space-between" width="100%">
        <Stack width={breakpointDownMD ? "60%" : "100%"} mr={breakpointDownMD ? 0 : "20px"}>
          <AIOSearchComponent
            width="100%"
            placeholder={t("reports.searchPlaceholder")}
            onChange={(value) => reportsService.setFilters({ search: value })}
          />
        </Stack>
        <Stack minWidth="160px">
          <Button variant="text" color="primary" onClick={() => setAddReportModalOpen(true)}>
            <Typography fontWeight={500}>{t("reports.addReport")}</Typography>
          </Button>
        </Stack>
      </Stack>

      {reportsLoading ? (
        <CircularProgress style={{ alignSelf: "center" }} size={20} />
      ) : (
        <Stack py={breakpointDownSM ? "10px" : "20px"} spacing={2} overflow="auto" className="scrollable" px="10px" height="100%">
          {reports.map((report) => (
            <ReportCard key={report.id} small={breakpointDownSM} adminView report={report} />
          ))}
        </Stack>
      )}
      {addReportModalOpen && <AddReportModal handleClose={() => setAddReportModalOpen(false)} />}
    </>
  );
};
export default EntityFileReportsTab;
