import { alpha, Avatar, Box, Button, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import BlockIcon from "@mui/icons-material/Block";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";

import { companyDetailsService, companytDetailsQuery } from "@store/ai-o/companies/details";

import { Colors } from "@constants/colors.constant";
import { AdminAioClientRoutes } from "@utils/routes.utils";

import AIORoutesTabComponent from "@components/AIORoutesTab.component";
import AIOButtonComponent from "@components/Button.component";
import ReattachAffiliateModal from "@screens/auth/admin/aio/components/ReattachAffiliate.modal";
import { companiesService } from "@store/ai-o/companies";
import { tap } from "rxjs";
import ConfirmModal from "@components/modal/Confirm.modal";

const BadgeType = styled(Stack)`
  border-radius: 5px;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 500;
  height: 15px;
  padding: 0 8px;
`;

const CustomerDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { clientId } = useParams();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [{ companyDetails: company }] = useObservable(companytDetailsQuery.details$);

  const [openReattachAffiliate, setOpenReattachAffiliate] = useState(false);
  const [openBanOrUnbanConfirm, setOpenBanOrUnbanConfirm] = useState<"ban" | "unban" | false>(false);

  useEffect(() => {
    companyDetailsService.resetStore();

    if (!!clientId) {
      companyDetailsService.getCompany(clientId).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }

    return () => {
      companyDetailsService.resetStore();
    };
  }, [enqueueSnackbar, clientId]);

  const handleBanOrUnbanCompany = (companyId: string, action: "ban" | "unban") => {
    companyDetailsService[action === "ban" ? "banCompany" : "unbanCompany"](companyId)
      .pipe(
        tap(() => {
          companiesService.updateCompanyBanned(companyId, action === "ban");
        })
      )
      .subscribe({
        next: () => {
          enqueueSnackbar(t(`aio.clients.confirm.${action}.success`), { variant: "success" });
        },
      });
  };

  return (
    <Stack flex={1} justifyContent="flex-start" overflow="hidden">
      <Stack
        justifyContent="center"
        px={breakpointDownSM ? "10px" : "20px"}
        pt={"30px"}
        pb={breakpointDownSM ? "20px" : "30px"}
        bgcolor="white"
        sx={{
          backgroundImage: "linear-gradient(to left, rgba(232, 97, 77, 0.05) 0%, rgba(232, 97, 77, 0) 100%)",
          position: "relative",
        }}
        boxShadow={`0 0px 50px 0 ${alpha(Colors.black, 0.1)}`}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" pr={breakpointDownSM ? undefined : "20px"}>
          <Button variant="text" color="inherit" startIcon={<img alt="back" src="/images/arrow_back.svg" />} onClick={() => navigate("..")}>
            <Typography fontWeight="300" color="secondary">
              {t("aio.clients.details.back")}
            </Typography>
          </Button>
          <Stack direction="row" alignItems="center" spacing={1}>
            {!!company && !company.affiliateId && (
              <AIOButtonComponent
                title={t("aio.clients.reattachToClientAio")}
                color="primary"
                icon={<Box height="10px" component="img" alt="reattach" src="/images/icon_doublearrow_red.svg" />}
                onClick={() => setOpenReattachAffiliate(true)}
              />
            )}
            {!!company && (
              <AIOButtonComponent
                title={t(`aio.clients.${company.banned ? "unban" : "ban"}`)}
                color="primary"
                icon={company.banned ? <VerifiedOutlinedIcon /> : <BlockIcon />}
                onClick={() => setOpenBanOrUnbanConfirm(company.banned ? "unban" : "ban")}
              />
            )}
            {!!company && company.banned && (
              <BadgeType spacing={0.5} bgcolor={Colors.error} direction="row" alignItems="center" justifyContent="center">
                <BlockIcon sx={{ height: "12px", width: "12px", color: "white" }} />
                <span>{t("aio.clients.banned")}</span>
              </BadgeType>
            )}
            {!!company && (
              <BadgeType
                spacing={1}
                bgcolor={!!company.affiliateId ? Colors.affiliate : Colors.greyLight}
                direction="row"
                alignItems="center"
                justifyContent="center">
                {!!company.affiliateId && <img alt="logo aio" src="/images/logo_allinone_small.svg" />}
                <span>{t(`aio.clients.${!!company.affiliateId ? "reattached" : "notReattached"}`)}</span>
              </BadgeType>
            )}
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" spacing={2} alignSelf="center">
          <Avatar
            sizes="contains"
            sx={{
              border: "none",
              borderRadius: "10px !important",
              backgroundColor: company?.logo ? Colors.white : alpha(Colors.lightpurple, 0.6),
              color: Colors.secondary,
              height: 44,
              width: 44,
            }}
            src={company?.logo}>
            {company?.name?.[0]}
          </Avatar>
          {!!company && (
            <Typography textAlign="center" fontSize="25px" fontWeight={700}>
              {company?.name}
            </Typography>
          )}
        </Stack>
        <Stack alignItems="center" pt={breakpointDownSM ? "10px" : "30px"}>
          <AIORoutesTabComponent variant="standard" routes={AdminAioClientRoutes.routes} />
        </Stack>
      </Stack>
      <Outlet />
      {openReattachAffiliate && !!company && (
        <ReattachAffiliateModal
          candidateCompanyInfo={{
            companyId: company.id,
            companyName: company.name,
            companyDescription: company.description,
            companyLocation: company.location,
            companyLogoURL: company.logo,
          }}
          handleClose={() => setOpenReattachAffiliate(false)}
        />
      )}
      {!!company && openBanOrUnbanConfirm && (
        <ConfirmModal
          confirmMsg={t(`aio.clients.confirm.${openBanOrUnbanConfirm}.description`)}
          handleClose={() => {
            setOpenBanOrUnbanConfirm(false);
          }}
          handleConfirm={() => {
            handleBanOrUnbanCompany(company.id, openBanOrUnbanConfirm);
          }}
          modalTitle={t(`aio.clients.confirm.${openBanOrUnbanConfirm}.title`)}
        />
      )}
    </Stack>
  );
};

export default CustomerDetails;
