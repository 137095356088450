import { TimelineItem } from "@mui/lab";
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

export interface PaginatedMetaData {
  itemCount: number;
  take: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  page: number;
  pageCount: number;
}
export interface PaginatedData<D> {
  meta: PaginatedMetaData;
  data: D;
}

interface VirtualProps {
  children: JSX.Element;
  height: number | string;
  customStyle?: React.CSSProperties;
}
export const VirtualScrollItem = (props: VirtualProps) => {
  const { children, height, customStyle } = props;
  const [ref, inView] = useInView();
  const style = {
    height: `${height}px`,
    ...(customStyle ? customStyle : {}),
  };

  return (
    <div ref={ref} style={style}>
      {inView ? children : null}
    </div>
  );
};

interface InfiniteScrollContainerProps {
  listItems: JSX.Element[];
  nextPageHandler: () => void;
  itemsPerPage: number;
  componentType: "Grid" | "Timeline";
}

export const InfiniteScrollContainer = (props: InfiniteScrollContainerProps) => {
  const { listItems, nextPageHandler, componentType, itemsPerPage } = props;
  const [lastRowRef, lastRowInView] = useInView({threshold: 0.9});

  useEffect(() => {
    lastRowInView && nextPageHandler();
  }, [lastRowInView, nextPageHandler]);

  const Elements = listItems.map((listItem, i) => {
    let ref: (node?: Element | null | undefined) => void = () => {};
    if (i === listItems.length - 1 - Math.floor(itemsPerPage * 0.25)) ref = lastRowRef;
    return componentType === "Grid" ? (
      <Grid key={listItem.key} item xs={12} sm={6} md={4} lg={3} ref={ref}>
        {listItem}
      </Grid>
    ) : componentType === "Timeline" ? (
      <TimelineItem key={listItem.key} ref={ref}>
        {listItem}
      </TimelineItem>
    ) : (
      <div key={listItem.key} ref={ref}>
        {listItem}
      </div>
    );
  });

  return <>{Elements}</>;
};
