import { alpha, Components } from "@mui/material";

import { Colors } from "@constants/colors.constant";

const menu: Components = {
  MuiMenu: {
    styleOverrides: {
      root: (props) => ({
        ...(props.ownerState?.["aria-label"] === "user-menu"
          ? {
              "& .MuiMenu-paper": {
                borderRadius: 15,
                padding: "12.5px 10px 12.5px 5px",
              },
              "& .MuiMenuItem-root": {
                fontWeight: 500,
              },
            }
          : {}),
        ...(props.ownerState?.["aria-label"] === "medias-menu"
          ? {
              "& .MuiMenu-paper": {
                borderRadius: '0px 15px 15px 0 !important',
                boxShadow: 'none',
                padding: "10px 0px 10px 20px",
              },
            }
          : {}),
      }),
      paper: (props) => ({
        ...(props.ownerState?.["aria-label"] === "medias-menu" ? { background: Colors.background } : { backgroundColor: "white" }),
        boxShadow: `0 8px 20px 0 ${alpha(Colors.black, 0.1)}`,
        padding: 0,
        margin: 0,
        borderRadius: "15px !important",
      }),
      list: {
        maxHeight: "40vh",
        padding: 0,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: (props) => ({
        flex: 1,
        fontWeight: 300,
        ...(props.ownerState?.["aria-label"] === "medias-menu-item"
          ? {
              height: "auto",
              backgroundColor: "transparent !important",
            }
          : { height: 38 }),
        "&.Mui-selected": {
          fontWeight: 700,
        },
        "&[color=primary]": {
          color: Colors.primary,
        },
      }),
    },
  },
};

export default menu;
