import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import React, { useEffect, useMemo, useState } from "react";
import { useSnackbar } from "notistack";
import { alpha, Box, Button, CircularProgress, Stack, styled, Tooltip, Typography } from "@mui/material";

import { adDetailsService } from "@store/ads/details";
import { ApplicationDetails, applicationsService } from "@store/applications";

import ConfirmModal from "@components/modal/Confirm.modal";
import ApplicationCard from "@components/card/Application.card";
import AIOTabComponent from "@components/AIOTab.component";
import ApplicationCommentsTab from "@screens/auth/common/ads/components/applicationDetails/ApplicationComments.tab";
import ApplicationHistoryTab from "@screens/auth/common/ads/components/applicationDetails/ApplicationHistory.tab";
import ModalComponent from "@components/modal/Modal.component";
import CheckCreditsModal from "@components/modal/CheckCredits.modal";
import { FeatureCreditsTypeEnum, subscriptions, subscriptionsQuery, SubscriptionTypeEnum } from "@store/subscriptions";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { Colors } from "@constants/colors.constant";
import AIOButtonComponent from "@components/Button.component";
import AiApplicationAnalysisModal from "@components/modal/AiApplicationAnalysis.modal";
import { TwilioAiCall, TwilioCallStatusEnum, getCallAnalysisColor, twilioAiCallsService } from "@store/twilioAiCalls";
import { formatLocalDate, formatTime } from "@utils/dayjs.utils";
import CallConfirmationModal from "./CallConfirmation.modal";
import CallChatModal from "./CallChat.modal";
import SeeSubscriptionsModal from "@components/subscriptions/modal/SeeSubscriptions.modal";

const StyledAiEvaluationBanner = styled(Stack)({
  backgroundColor: Colors.greyCardBg,
  borderRadius: "15px",
  padding: "22px",
});

const AiCallStatus: { [key: string]: { status: string; color: string; icon: string; action: string } } = {
  [TwilioCallStatusEnum.IN_PROGRESS]: {
    status: "ads.details.applyTab.aiCalls.inProgress",
    color: Colors.green,
    icon: "/images/icon_phone_ringing.svg",
    action: "ads.details.applyTab.aiCalls.seeCallInProgress",
  },
  [TwilioCallStatusEnum.QUEUED]: {
    status: "ads.details.applyTab.aiCalls.inProgress",
    color: Colors.green,
    icon: "/images/icon_phone_ringing.svg",
    action: "ads.details.applyTab.aiCalls.seeCallInProgress",
  },
  [TwilioCallStatusEnum.RINGING]: {
    status: "ads.details.applyTab.aiCalls.inProgress",
    color: Colors.green,
    icon: "/images/icon_phone_ringing.svg",
    action: "ads.details.applyTab.aiCalls.seeCallInProgress",
  },
  [TwilioCallStatusEnum.COMPLETED]: {
    status: "ads.details.applyTab.aiCalls.success",
    color: Colors.greyLight,
    icon: "/images/icon_phone_completed.svg",
    action: "ads.details.applyTab.aiCalls.consult",
  },
  [TwilioCallStatusEnum.NO_ANSWER]: {
    status: "ads.details.applyTab.aiCalls.noAnswer",
    color: Colors.greyLight,
    icon: "/images/icon_phone_no_answer.svg",
    action: "ads.details.applyTab.aiCalls.retry",
  },
  [TwilioCallStatusEnum.FAILED]: {
    status: "ads.details.applyTab.aiCalls.refused",
    color: Colors.greyLight,
    icon: "/images/icon_phone_refused.svg",
    action: "ads.details.applyTab.aiCalls.retry",
  },
};

interface ApplicationModalProps {
  fromCVTheque?: boolean;
}

const ApplicationModal = (props: ApplicationModalProps) => {
  const { fromCVTheque } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { applicationId } = useParams();

  const [loading, setLoading] = useState(true);

  const [application, setApplication] = useState<ApplicationDetails>();
  const [twilioAiCall, setTwilioAiCall] = useState<TwilioAiCall>();
  const [loadingCall, setLoadingCall] = useState(false);

  const [tabValue, setTabValue] = useState(0);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);
  const [checkUserCredits, setCheckUserCredits] = useState(false);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [openCallConfirmation, setOpenCallConfirmation] = useState(false);
  const [openCallChat, setOpenCallChat] = useState(false);
  const [showFreemiumModal, setShowFreemiumModal] = useState(false);
  const [showSubscriptionsModal, setShowSubscriptionsModal] = useState(false);

  const color = useMemo(() => {
    if (!application?.aiEvaluation) return "";

    if (application.aiEvaluation.mark < 4) {
      return Colors.error;
    } else if (application.aiEvaluation.mark < 7) {
      return Colors.orange;
    }
    return Colors.green;
  }, [application?.aiEvaluation]);

  const ad = useMemo(() => {
    return application?.announcement;
  }, [application]);

  useEffect(() => {
    if (applicationId) {
      adDetailsService
        .getAdApplicationDetails(applicationId)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: setApplication,
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  }, [applicationId, enqueueSnackbar]);

  useEffect(() => {
    if (application) {
      twilioAiCallsService.getAiCall(application.id).subscribe({
        next: (twilioAiCall) => {
          setTwilioAiCall(twilioAiCall);
        },
      });
    }
  }, [application]);

  useEffect(() => {
    const handleTwilioCallChanged = () => {
      if (application) {
        twilioAiCallsService.getAiCall(application.id).subscribe({
          next: (twilioAiCall) => {
            setTwilioAiCall(twilioAiCall);
          },
        });
      }
    };
    const eventName = `twilio_call_changed_${applicationId}`;
    window.addEventListener(eventName, handleTwilioCallChanged);

    return () => {
      window.removeEventListener(eventName, handleTwilioCallChanged);
    };
  }, [application, applicationId]);

  if (!application) return null;

  const handleDeleteCV = () => {
    applicationsService
      .deleteCVs([application.id], !!fromCVTheque)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t(`applications.deleteSuccess`), { variant: "success" });
          navigate("..", { replace: true });
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleMoveToCVTheque = (removeCredit?: boolean) => {
    setLoading(true);
    adDetailsService
      .moveToCVTheque(application.id, application.announcement.id, removeCredit)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t(`applications.moveToCVThequeSuccess`), { variant: "success" });
          navigate("..", { replace: true });
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleConfirmCall = () => {
    setOpenCallConfirmation(false);
    setLoadingCall(true);
    twilioAiCallsService.makeAiCall(application).subscribe({
      next: (twilioAiCall) => {
        twilioAiCallsService.upsertEntities(twilioAiCall);
        twilioAiCallsService.handleSocketListeners([twilioAiCall]);
      },
      error: (err) => {
        enqueueSnackbar(err.text, err.options);
        setLoadingCall(false);
      },
      complete: () => setLoadingCall(false),
    });
  };

  return (
    <ModalComponent
      handleClose={() => navigate("..", { replace: true })}
      titleLeft
      ariaLabel="apply"
      title={t("ads.details.applyTab.titleModal")}
      content={
        <Stack spacing={2} height="75vh" px="5px" pt="10px" sx={{ overflowY: "auto", overflowX: "hidden" }}>
          <Stack direction="row" justifyContent={!application.inCVTheque ? "space-between" : "flex-end"} alignItems={"center"}>
            {!application.inCVTheque && !application.linkedinProfileURL && (
              <Button
                variant="text"
                color="primary"
                sx={{ ml: "-15px" }}
                disabled={loading}
                onClick={(evt) => {
                  evt.stopPropagation();
                  if (
                    sessionQuery.user.role === UserRoleEnum.ADMIN ||
                    subscriptionsQuery.sessionUserSubscriptionOngoing ||
                    application.announcement.managedByTwinin
                  ) {
                    handleMoveToCVTheque();
                  } else {
                    setCheckUserCredits(true);
                  }
                }}>
                <img alt="move to cvtheque" src="/images/cvtheque_primary.svg" />
                <Typography ml="10px" fontWeight={500}>
                  {t("applications.moveToCVthèque")}
                </Typography>
              </Button>
            )}
            <Button
              variant="text"
              color="primary"
              sx={{ mr: "-15px" }}
              disabled={loading}
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenDeleteConfirmation(true);
              }}>
              <img alt="delete" src="/images/icon_delete.svg" />
              <Typography ml="10px" fontWeight={500}>
                {t("applications.deleteApplication")}
              </Typography>
            </Button>
          </Stack>

          <Box>{!loading ? <ApplicationCard applyInfos={application} /> : <CircularProgress sx={{ alignSelf: "center" }} size={20} />}</Box>
          {application.aiEvaluation && (
            <Box>
              {!loading ? (
                <StyledAiEvaluationBanner direction="row" alignItems="center" justifyContent="space-between">
                  <Stack>
                    <Stack direction="row" alignItems="center" spacing="4px">
                      <img width="20px" src="/images/ia_robot.svg" alt="robot" />
                      <Typography fontWeight={500}>{t("ads.details.applyTab.aiSorting.sortedByAi")}</Typography>
                    </Stack>
                    <Typography fontWeight={300} fontSize="12px" color={Colors.greyLight}>
                      {`${t("ads.details.applyTab.lists.TO_BE_PROCESSED")} > ${t(
                        `ads.details.applyTab.lists.${application.aiEvaluation.applicationList}`
                      )}`}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing="10px">
                    <Box py="5px" px="10px" borderRadius="7px" bgcolor={alpha(color, 0.15)}>
                      <Typography fontWeight={400} fontSize="11px" color={color}>
                        {t("ads.details.applyTab.aiSorting.aiMarkOf10", { score: application.aiEvaluation.mark })}
                      </Typography>
                    </Box>
                    <AIOButtonComponent
                      title={t("ads.details.applyTab.aiSorting.seeAnalysis")}
                      variant="text"
                      color="primary"
                      onClick={() => setOpenAnalysisModal(true)}
                    />
                  </Stack>
                </StyledAiEvaluationBanner>
              ) : (
                <CircularProgress sx={{ alignSelf: "center" }} size={20} />
              )}
            </Box>
          )}
          {/* AI Call Status */}
          {application.phoneNumber && (
            <Box>
              {!loading ? (
                !twilioAiCall ? (
                  <StyledAiEvaluationBanner direction="row" alignItems="center" justifyContent="space-between">
                    <Stack>
                      <Stack direction="row" alignItems="center" spacing="4px">
                        <img width="20px" src="/images/ia_sparkle.png" alt="sparkle" />
                        <Typography fontWeight={500}>{t("ads.details.applyTab.aiCalls.calledByAi")}</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing="4px">
                        <img src={loadingCall ? AiCallStatus[TwilioCallStatusEnum.IN_PROGRESS].icon : "/images/icon_phone.svg"} alt="phone" />
                        <Typography fontWeight={300} fontSize="12px" color={Colors.greyLight}>
                          {loadingCall ? t("ads.details.applyTab.aiCalls.inProgress") : t("ads.details.applyTab.aiCalls.noCallMade")}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing="10px">
                      <AIOButtonComponent
                        title={t("ads.details.applyTab.aiCalls.makeCall")}
                        variant="text"
                        color="primary"
                        onClick={() => {
                          if (
                            sessionQuery.user.role === UserRoleEnum.ADMIN ||
                            subscriptionsQuery.sessionUserSubscriptionOngoing ||
                            ad?.managedByTwinin
                          ) {
                            setOpenCallConfirmation(true);
                          } else {
                            setShowFreemiumModal(true);
                          }
                        }}
                      />
                    </Stack>
                  </StyledAiEvaluationBanner>
                ) : (
                  <StyledAiEvaluationBanner direction="row" alignItems="center" justifyContent="space-between">
                    <Stack>
                      <Stack direction="row" alignItems="center" spacing="4px">
                        <img width="20px" src="/images/ia_sparkle.png" alt="sparkle" />
                        <Typography fontWeight={500}>{t("ads.details.applyTab.aiCalls.calledByAi")}</Typography>
                      </Stack>
                      <Stack direction="row" alignItems="center" spacing="4px">
                        <img
                          src={loadingCall ? AiCallStatus[TwilioCallStatusEnum.IN_PROGRESS].icon : AiCallStatus[twilioAiCall.callStatus].icon}
                          alt="phone"
                        />
                        <Typography fontWeight={300} fontSize="12px" color={AiCallStatus[twilioAiCall.callStatus].color}>
                          {loadingCall
                            ? t("ads.details.applyTab.aiCalls.inProgress")
                            : t(AiCallStatus[twilioAiCall.callStatus].status, {
                                date: formatLocalDate(twilioAiCall.createdAt),
                                time: formatTime(twilioAiCall.createdAt),
                              })}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Stack direction="row" alignItems="center" spacing="10px">
                      {twilioAiCall.callStatus !== TwilioCallStatusEnum.IN_PROGRESS && (
                        <Tooltip
                          disableHoverListener={!twilioAiCall.callAnalysis?.analysis}
                          title={
                            <Stack spacing={1}>
                              <Typography fontSize={14} fontWeight={500} textAlign="center">
                                {t("ads.details.applyTab.aiCalls.aiAnalysisTitle")}
                              </Typography>
                              <Typography fontSize={11} fontWeight={400} textAlign="center">
                                {twilioAiCall.callAnalysis?.analysis.split(". ").map((line, index, array) => (
                                  <React.Fragment key={index}>
                                    {line}
                                    {index < array.length - 1 && (
                                      <>
                                        <span>.</span>
                                        <br />
                                      </>
                                    )}
                                  </React.Fragment>
                                ))}
                              </Typography>
                            </Stack>
                          }>
                          <Box
                            py="5px"
                            px="10px"
                            borderRadius="7px"
                            bgcolor={alpha(getCallAnalysisColor(twilioAiCall?.callAnalysis?.mark ?? 0), 0.15)}>
                            <Typography fontWeight={400} fontSize="11px" color={getCallAnalysisColor(twilioAiCall?.callAnalysis?.mark ?? 0)}>
                              {t("ads.details.applyTab.aiSorting.aiMarkOf10", { score: twilioAiCall?.callAnalysis?.mark ?? "X" })}
                            </Typography>
                          </Box>
                        </Tooltip>
                      )}
                      <AIOButtonComponent
                        title={t(AiCallStatus[twilioAiCall.callStatus].action)}
                        variant="text"
                        color="primary"
                        onClick={() => {
                          if (
                            twilioAiCall?.callStatus === TwilioCallStatusEnum.NO_ANSWER ||
                            twilioAiCall?.callStatus === TwilioCallStatusEnum.FAILED ||
                            twilioAiCall?.callStatus === TwilioCallStatusEnum.CANCELED ||
                            twilioAiCall?.callStatus === TwilioCallStatusEnum.BUSY
                          ) {
                            setOpenCallConfirmation(true);
                          } else if (
                            twilioAiCall?.callStatus === TwilioCallStatusEnum.COMPLETED ||
                            twilioAiCall?.callStatus === TwilioCallStatusEnum.IN_PROGRESS ||
                            twilioAiCall?.callStatus === TwilioCallStatusEnum.QUEUED ||
                            twilioAiCall?.callStatus === TwilioCallStatusEnum.RINGING
                          ) {
                            setOpenCallChat(true);
                          }
                        }}
                      />
                    </Stack>
                  </StyledAiEvaluationBanner>
                )
              ) : (
                <CircularProgress sx={{ alignSelf: "center" }} size={20} />
              )}
            </Box>
          )}
          <Stack overflow="auto" flex={1}>
            <AIOTabComponent
              tabs={[t("applications.comments", { count: application?.comment?.length || 0 }), t("applications.history")]}
              handleChange={setTabValue}
              value={tabValue}
            />
            <Stack flex={1} overflow="auto" className="scrollable" pt="20px">
              {tabValue === 0 ? (
                <ApplicationCommentsTab application={application} handleCommentsAdded={setApplication} />
              ) : (
                <ApplicationHistoryTab application={application} />
              )}
            </Stack>
          </Stack>
          {openDeleteConfirmation && (
            <ConfirmModal
              modalTitle={t("applications.deleteApplication")}
              confirmMsg={t("applications.deleteApplicationDescription")}
              handleClose={() => setOpenDeleteConfirmation(false)}
              handleConfirm={() => handleDeleteCV()}
            />
          )}
          {checkUserCredits && (
            <CheckCreditsModal
              handleCreditsValidated={() => {
                setCheckUserCredits(false);
                handleMoveToCVTheque(true);
              }}
              handleCloseCheck={() => setCheckUserCredits(false)}
              type={FeatureCreditsTypeEnum.APPLICATIONS}
            />
          )}
          {openAnalysisModal && application.aiEvaluation && (
            <AiApplicationAnalysisModal aiAnalysis={application.aiEvaluation?.analysis} handleCloseModal={() => setOpenAnalysisModal(false)} />
          )}
          {openCallConfirmation && (
            <CallConfirmationModal
              recontact={
                twilioAiCall?.callStatus === TwilioCallStatusEnum.NO_ANSWER ||
                twilioAiCall?.callStatus === TwilioCallStatusEnum.FAILED ||
                twilioAiCall?.callStatus === TwilioCallStatusEnum.CANCELED ||
                twilioAiCall?.callStatus === TwilioCallStatusEnum.BUSY
              }
              handleConfirmCall={handleConfirmCall}
              handleClose={() => setOpenCallConfirmation(false)}
            />
          )}
          {openCallChat && twilioAiCall && application && (
            <CallChatModal application={application} twilioAiCall={twilioAiCall} handleClose={() => setOpenCallChat(false)} />
          )}
          {showFreemiumModal && (
            <>
              <ModalComponent
                handleClose={() => setShowFreemiumModal(false)}
                title={t(`ads.details.applyTab.aiCalls.makeCall`)}
                icon={<img width={60} alt="warning" src="/images/illus_warning.png" />}
                content={
                  <Stack mt="20px" spacing={3} px="50px" pb="20px">
                    <Typography textAlign="center" lineHeight={1.5}>
                      {t(`ads.details.applyTab.aiCalls.notForFreemium`)}
                    </Typography>
                  </Stack>
                }
                actions={
                  <AIOButtonComponent
                    title={t("subscriptions.seeSubscriptions")}
                    variant="contained"
                    ariaLabel="modal"
                    onClick={() => setShowSubscriptionsModal(true)}
                  />
                }
                maxWidth="sm"
              />
              {showSubscriptionsModal && (
                <SeeSubscriptionsModal
                  subscriptions={subscriptions.filter((s) => s.type === SubscriptionTypeEnum.COMPANY || s.type === SubscriptionTypeEnum.PROFESSIONAL)}
                  handleClose={() => setShowSubscriptionsModal(false)}
                />
              )}
            </>
          )}
        </Stack>
      }
      maxWidth="sm"
    />
  );
};

export default ApplicationModal;
