import { Accordion, AccordionDetails, AccordionSummary, Stack, styled, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { UserModel } from "@store/users";
import { useTranslation } from "react-i18next";

import { EntitiesModel } from "@store/entities/entities.model";

import UsersListComponent from "./UsersList.component";

const AccordionStyled = styled(Accordion)`
  background-color: transparent;
  box-shadow: none;
  margin-top: 16px !important;
  &.Mui-expanded {
    margin: 16px 0 !important;
  }
  & .MuiAccordionDetails-root {
    padding: 0;
  }
  &::before {
    display: none;
  }
  & .MuiAccordionSummary-root {
    border-radius: 15px;
    box-shadow: 0 0 20px 0 rgba(34, 34, 64, 0.05);
    height: 70px;
    min-height 70px;
  }
  & .MuiAccordionSummary-root.Mui-expanded {
    border-radius: 0px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
  }
  & .MuiAccordionSummary-content {
    margin: 20px 0;
  }
`;

interface UserAccordionComponentProps {
  entity: EntitiesModel;
  isUnassignedUsers?: boolean;
  users: UserModel[];
}

const UsersAccordionComponent = (props: UserAccordionComponentProps) => {
  const { entity, isUnassignedUsers = false, users } = props;

  const {t} = useTranslation();

  if (!users.length) return null;

  return (
    <AccordionStyled defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {isUnassignedUsers ? (
          <Stack direction="row" alignItems="center" spacing="10px">
            <Typography fontWeight={800} fontStyle="italic">
              {t("clients.details.usersTab.unassignedUsers")} ({users.length})
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" alignItems="center" spacing="10px">
            {entity.picture && <img width="33px" alt="logo filliale" src={entity.picture} />}
            <Typography fontWeight={800}>
              {`${entity.name} (${users.length})`}
            </Typography>
          </Stack>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <UsersListComponent users={users} affiliateId={entity.id} inAccordion />
      </AccordionDetails>
    </AccordionStyled>
  );
};

export default UsersAccordionComponent;
