import { SelectItem } from "@components/input/Select.component";
import I18n from "@utils/i18n.utils";
import { Colors } from "@constants/colors.constant";
import { ToolboxColumn } from "@store/toolbox";
import { AffiliateSubscription } from "@store/entities/afiliates/details";

export enum EntityTypeEnum {
  CLIENT = "CLIENT",
  AFFILIATE = "AFFILIATE",
}

export namespace EntityTypeEnumFunctions {
  export const selectItems: SelectItem[] = [
    { label: I18n.t("clients.type.client"), value: EntityTypeEnum.CLIENT },
    { label: I18n.t("clients.type.affiliate"), value: EntityTypeEnum.AFFILIATE },
  ];

  export const color = (type: EntityTypeEnum) => {
    switch (type) {
      case EntityTypeEnum.AFFILIATE:
        return Colors.affiliate;
      case EntityTypeEnum.CLIENT:
        return Colors.primary;
    }
  };
}

export enum EntityFavoritesFilterEnum {
  ALL = "ALL",
  FAVORITES = "FAVORITES",
}

export namespace EntityFavoritesFilterEnumFunctions {
  export const selectItems: SelectItem[] = [
    { label: I18n.t("clients.filters.all"), value: EntityFavoritesFilterEnum.ALL },
    { label: I18n.t("clients.filters.favorites"), value: EntityFavoritesFilterEnum.FAVORITES },
  ];
}

export enum TimelineEmailTemplateEnum {
  NO_TEMPLATE = "NO_TEMPLATE",
  ANALYSE_DE_VOS_OFFRES = "ANALYSE_DE_VOS_OFFRES",
  OFFRES_REECRITES = "OFFRES_REECRITES",
  BANNIERES_EN_LIGNE = "BANNIERES_EN_LIGNE",
  DIFFUSION_EN_COURS = "DIFFUSION_EN_COURS",
  ACTIVATION_DU_SEARCH = "ACTIVATION_DU_SEARCH",
  PREPARATION_NEWSLETTER = "PREPARATION_NEWSLETTER",
  ACTIVATION_SEQUENCE = "ACTIVATION_SEQUENCE",
  ACTIVATION_CAMPAGNE_SMS = "ACTIVATION_CAMPAGNE_SMS",
  ACTIVATION_CAMPAGNE_INMAILS = "ACTIVATION_CAMPAGNE_INMAILS",
  IMPROVE_JOBS_SEO = 'IMPROVE_JOBS_SEO',
  JOBS_REFRESH = 'JOBS_REFRESH',
  ACTIVATION_CAMPAGNE_TIKTOK = 'ACTIVATION_CAMPAGNE_TIKTOK',
  BANNIERES_RETARGETING_EN_LIGNE = 'BANNIERES_RETARGETING_EN_LIGNE',
  ACTIVATION_CAMPAGNE_META = 'ACTIVATION_CAMPAGNE_META',
  INDEED_DUPLICATED_JOBS_GEOLOCATION = 'INDEED_DUPLICATED_JOBS_GEOLOCATION',
  INDEED_SPONSORED = 'INDEED_SPONSORED',
  LINKEDIN_SPONSORED = 'LINKEDIN_SPONSORED',
  SUPPLEMENTARY_TALENT_CAMPAIGN = 'SUPPLEMENTARY_TALENT_CAMPAIGN',
}

export namespace TimelineEmailTemplateEnumFunctions {
  export const selectItems = (): SelectItem[] => {
    const templates = [];
    for (const template in TimelineEmailTemplateEnum) {
      if (template !== "selectItems") templates.push(template);
    }

    return templates.map((t) => ({
      label: I18n.t(`subscriptions.timelineEmail.enum.${t}`),
      value: t,
    }));
  };
}

export interface EntityLightModel {
  id: string;
  type: EntityTypeEnum;
  name: string;
  picture: string;
  city: string;
}

export interface EntityContactModel {
  firstname: string;
  lastname: string;
  email: string;
}

export interface ActionTimeline {
  name: string;
  j: number;
  strategy?: string;
  isPassed?: boolean;
  emailTemplateName?: TimelineEmailTemplateEnum;
}

export interface EntityTimeline {
  actions: ActionTimeline[];
  fileKey?: string;
  filename?: string;
}

export interface EntitiesModel {
  id: string;
  isAlsoAffiliate: boolean;
  type: EntityTypeEnum;
  name: string;
  address: string;
  picture: string;
  city: string;
  description: string;
  otherInformation: string;
  twinners: any[];
  contacts: EntityContactModel[];
  isFavorite?: boolean;
  toolboxColumns?: ToolboxColumn[];
  timeline: EntityTimeline;
  subscriptions: AffiliateSubscription[];
}
