import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { ManagePrompt, promptsService, PromptsTypeEnum, PromptsTypeEnumFunctions } from "@store/ai-o/prompts";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

import PromptsCard from "@components/card/Prompts.card";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import VariablesComponent from "@components/aio-prompts/Variables.component";
import { Colors } from "@constants/colors.constant";

const PromptsComponent = () => {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [prompts, setPrompts] = useState<ManagePrompt[]>([]);

  useEffect(() => {
    promptsService.getPrompts().subscribe({
      next: setPrompts,
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  const reformulationOfScrappedAd = prompts.find((p) => p.typePrompt === PromptsTypeEnum.REFORMULATION_OF_SCRAPPED_AD);
  const updateReformulationOfScrappedAd = (name: string) => (value: string) => {
    setPrompts((state) =>
      state.map((p) => p.typePrompt === PromptsTypeEnum.REFORMULATION_OF_SCRAPPED_AD
        ? {...p, [name]: value}
        : p
      )
    );
  };

  const presentationCandidate = prompts.find((p) => p.typePrompt === PromptsTypeEnum.PRESENTATION_CANDIDATE);
  const updatePresentationCandidate = (name: string) => (value: string) => {
    setPrompts((state) =>
      state.map((p) => p.typePrompt === PromptsTypeEnum.PRESENTATION_CANDIDATE
        ? {...p, [name]: value}
        : p
      )
    );
  };

  const reformulationOfCoverLetter = prompts.find((p) => p.typePrompt === PromptsTypeEnum.REFORMULATION_OF_COVER_LETTER);
  const updateReformulationOfCoverLetter = (name: string) => (value: string) => {
    setPrompts((state) =>
      state.map((p) => p.typePrompt === PromptsTypeEnum.REFORMULATION_OF_COVER_LETTER
        ? {...p, [name]: value}
        : p
      )
    );
  };

  const handleSave = (promptType: PromptsTypeEnum) => {
    let prompt;
    switch (promptType) {
      case PromptsTypeEnum.REFORMULATION_OF_SCRAPPED_AD:
        prompt = reformulationOfScrappedAd;
        break;
      case PromptsTypeEnum.PRESENTATION_CANDIDATE:
        prompt = presentationCandidate;
        break;
      case PromptsTypeEnum.REFORMULATION_OF_COVER_LETTER:
        prompt = reformulationOfCoverLetter;
        break;
    }

    if (!prompt || !prompt?.id) return;

    setLoading(true);
    promptsService.updatePrompt(prompt.id, prompt).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => enqueueSnackbar(t("aio.prompts.success"), {variant: "success"}),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const errors = {
    reformulationOfScrappedAd: {
      modelPrompt: getFieldError(reformulationOfScrappedAd?.modelPrompt, FieldValidationType.REQUIRED_STRING),
      content: getFieldError(reformulationOfScrappedAd?.content, FieldValidationType.REQUIRED_STRING),
    },
    presentationCandidate: {
      modelPrompt: getFieldError(presentationCandidate?.modelPrompt, FieldValidationType.REQUIRED_STRING),
      content: getFieldError(presentationCandidate?.content, FieldValidationType.REQUIRED_STRING),
    },
    reformulationOfCoverLetter: {
      modelPrompt: getFieldError(reformulationOfCoverLetter?.modelPrompt, FieldValidationType.REQUIRED_STRING),
      content: getFieldError(reformulationOfCoverLetter?.content, FieldValidationType.REQUIRED_STRING),
    },
  };

  return (
    <>
      {!!reformulationOfScrappedAd && (
        <PromptsCard>
          <Stack mt="29px">
            <AIOTextfieldComponent
              placeholder={t('aio.prompts.modelExample')}
              title={t('aio.prompts.modelChatGPT')}
              onChange={updateReformulationOfScrappedAd("modelPrompt")}
              value={reformulationOfScrappedAd.modelPrompt ?? ''}
              error={errors.reformulationOfScrappedAd.modelPrompt} />
          </Stack>
          <Stack mt="20px">
            <AIOTextfieldComponent
              multiline
              title={t('aio.prompts.reformulateScrapped')}
              placeholder={t('aio.prompts.reformulateScrapped')}
              value={reformulationOfScrappedAd.content ?? ''}
              onChange={updateReformulationOfScrappedAd("content")}
              error={errors.reformulationOfScrappedAd.content} />
          </Stack>
          <Stack mt="20px" spacing="5px">
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {t("aio.prompts.variables")}
            </Typography>
            <VariablesComponent
              variables={PromptsTypeEnumFunctions.variables[PromptsTypeEnum.REFORMULATION_OF_SCRAPPED_AD]}/>
          </Stack>
          <Stack m="20px 0 30px 0" alignSelf="center">
            <AIOButtonComponent
              disabled={checkIfErrors(errors.reformulationOfScrappedAd) || loading}
              onClick={() => handleSave(PromptsTypeEnum.REFORMULATION_OF_SCRAPPED_AD)}
              title={t('global.save')}
              variant="contained" />
          </Stack>
        </PromptsCard>
      )}
      {!!presentationCandidate && (
        <PromptsCard>
          <Stack mt="29px">
            <AIOTextfieldComponent
              placeholder={t('aio.prompts.modelExample')}
              title={t('aio.prompts.modelChatGPT')}
              onChange={updatePresentationCandidate("modelPrompt")}
              value={presentationCandidate.modelPrompt ?? ''}
              error={errors.presentationCandidate.modelPrompt} />
          </Stack>
          <Stack mt="20px">
            <AIOTextfieldComponent
              multiline
              title={t('aio.prompts.presentationCandidateContent')}
              placeholder={t('aio.prompts.presentationCandidateContent')}
              value={presentationCandidate.content ?? ''}
              onChange={updatePresentationCandidate("content")}
              error={errors.presentationCandidate.content} />
          </Stack>
          <Stack mt="20px" spacing="5px">
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {t("aio.prompts.variables")}
            </Typography>
            <VariablesComponent
              variables={PromptsTypeEnumFunctions.variables[PromptsTypeEnum.PRESENTATION_CANDIDATE]}/>
          </Stack>
          <Stack m="20px 0 30px 0" alignSelf="center">
            <AIOButtonComponent
              disabled={checkIfErrors(errors.presentationCandidate) || loading}
              onClick={() => handleSave(PromptsTypeEnum.PRESENTATION_CANDIDATE)}
              title={t('global.save')}
              variant="contained" />
          </Stack>
        </PromptsCard>
      )}
      {!!reformulationOfCoverLetter && (
        <PromptsCard>
          <Stack mt="29px">
            <AIOTextfieldComponent
              placeholder={t('aio.prompts.modelExample')}
              title={t('aio.prompts.modelChatGPT')}
              onChange={updateReformulationOfScrappedAd("modelPrompt")}
              value={reformulationOfCoverLetter.modelPrompt ?? ''}
              error={errors.reformulationOfCoverLetter.modelPrompt} />
          </Stack>
          <Stack mt="20px">
            <AIOTextfieldComponent
              multiline
              title={t('aio.prompts.reformulateCoverLetter')}
              placeholder={t('aio.prompts.reformulateCoverLetter')}
              value={reformulationOfCoverLetter.content ?? ''}
              onChange={updateReformulationOfCoverLetter("content")}
              error={errors.reformulationOfCoverLetter.content} />
          </Stack>
          <Stack mt="20px" spacing="5px">
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {t("aio.prompts.variables")}
            </Typography>
            <VariablesComponent
              variables={PromptsTypeEnumFunctions.variables[PromptsTypeEnum.REFORMULATION_OF_COVER_LETTER]}/>
          </Stack>
          <Stack m="20px 0 30px 0" alignSelf="center">
            <AIOButtonComponent
              disabled={checkIfErrors(errors.reformulationOfCoverLetter) || loading}
              onClick={() => handleSave(PromptsTypeEnum.REFORMULATION_OF_COVER_LETTER)}
              title={t('global.save')}
              variant="contained" />
          </Stack>
        </PromptsCard>
      )}
    </>
  )
}

export default PromptsComponent