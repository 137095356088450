import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { stripeProductsService } from "@store/stripe/products/stripeProducts.service";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";

interface StripeProductsUpdateConfirmModalProps {
  handleClose: () => void;
}

const StripeProductsUpdateConfirmModal = (props: StripeProductsUpdateConfirmModalProps) => {
  const {handleClose} = props;

  const { t } = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleUpdate = () => {
    setLoading(true);
    stripeProductsService.updateStripeProducts().pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        enqueueSnackbar(t("adminParameters.stripeProducts.success.stripeUpdate"), { variant: "success" });
        handleClose();
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("adminParameters.stripeProducts.confirmUpdate.title")}
      content={
        <Stack p="20px" alignItems="center" spacing={5}>
          <Typography textAlign="center">
            {t("adminParameters.stripeProducts.confirmUpdate.description1")}
          </Typography>
          <Typography color="error" textAlign="center">
            {t("adminParameters.stripeProducts.confirmUpdate.description2")}
          </Typography>
          <Typography textAlign="center">
            {t("adminParameters.stripeProducts.confirmUpdate.description3")}
          </Typography>
        </Stack>
      }
      actions={
        <AIOButtonComponent
          disabled={loading}
          onClick={handleUpdate}
          title={t("global.confirm")}
          variant="contained"
          color="secondary" />
      }
    />
  );
};

export default StripeProductsUpdateConfirmModal;
