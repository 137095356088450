import { Avatar, Box, IconButton, Stack, styled, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { NavLink } from "react-router-dom";

import {
  RegisterUserModel,
  registerUsersService,
  RegisterUserTypeEnum,
  RegisterUserTypeEnumFunctions
} from "@store/registerUsers";
import { subscriptions, SubscriptionTypeEnum } from "@store/subscriptions";

import { AdminAioClientRoutes, AdminAioRoutes, AffiliateRoutes, ClientRoutes, Pages } from "@utils/routes.utils";
import { Colors } from "@constants/colors.constant";

import AcceptUserModal from "@screens/auth/admin/entities/components/usersWaitingValidation/AcceptUser.modal";
import ConfirmModal from "@components/modal/Confirm.modal";

const ClientLink = styled(NavLink)(`
  color: ${Colors.white};
`);

const UserValidationCardStyled = styled(Stack)(`
  background-color: ${Colors.white};
  border-radius: 15px;
  flex: 1;
  height: 100%;
  opacity: 1;
  position: relative;
  width: 100%;
  & > *:first-of-type {
    align-items: center;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(34,34,64, 0.05);  
    height: 100%;
    padding: 22px;
  };
`);

interface UserValidationCardProps {
  user: RegisterUserModel;
}

const UserValidationCard = (props: UserValidationCardProps) => {
  const { user } = props;

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [openAccept, setOpenAccept] = useState(false);
  const [openReject, setOpenReject] = useState(false);

  const handleReject = () => {
    registerUsersService.rejectRegisterUser(user.id).subscribe({
      next: () => {
        enqueueSnackbar(t('usersValidation.reject.success'), {variant: 'success'})
        setOpenReject(false);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const getTooltipByType = () => {
    const subscriptionName = subscriptions.find((s) => s.type === user.selectedSubscriptionType)?.name || subscriptions[0].name;
    let subscriptionPeriod = t(`subscriptions.${user.isMonthly ? 'monthly' : 'yearly'}`);
    if (user.selectedSubscriptionType === SubscriptionTypeEnum.FREEMIUM) subscriptionPeriod = '';

    const companySubscriptionSelected = user.selectedSubscriptionType === SubscriptionTypeEnum.COMPANY;
    const offers = user.companyUnlimitedOffers
      ? t('subscriptions.unlimitedOffer')
      : `${user.companyOffersNb ?? 0} ${t('subscriptions.offer', {count: user.companyOffersNb ?? 0})}`;

    switch (user.type) {
      case RegisterUserTypeEnum.AIO:
        return (
          <Stack spacing="5px">
            {!!user.allInOneAffiliates?.length && (
              <Typography fontSize="14px" fontWeight="300" lineHeight={1.25}>
                <span style={{fontWeight: 900}}>{t("usersValidation.user.client")} :</span> <ClientLink to={`${user.allInOneAffiliates[0].client.id}/${ClientRoutes.DETAILS}`}>{user.allInOneAffiliates[0].client.name}</ClientLink>
              </Typography>
            )}
            <Typography fontSize="14px" fontWeight="300" lineHeight={1.25}>
              <span style={{fontWeight: 900}}>{t("usersValidation.user.affiliates")} :</span> {(user.allInOneAffiliates ?? []).map((a, index) => (
                <>
                  {index !== 0 && ` • `}
                  <ClientLink key={a.id} to={`${Pages.AFFILIATES}/${a.id}/${AffiliateRoutes.DETAILS}`}>{a.name}</ClientLink>
                </>
              ))}
            </Typography>
            <Typography fontSize="14px" fontWeight="300" lineHeight={1.25}>
              <span style={{fontWeight: 900}}>{t("usersValidation.user.subscription")} :</span> {subscriptionName}{subscriptionPeriod ? ` • ${subscriptionPeriod}` : ''}{companySubscriptionSelected ? ` • ${offers}` : ''}
            </Typography>
          </Stack>
        );
      case RegisterUserTypeEnum.AI_O:
        return (
          <Stack spacing="5px">
            {!!user.AI_OCompany && (
              <Typography fontSize="14px" fontWeight="300" lineHeight={1.25}>
                <span style={{fontWeight: 900}}>{t("usersValidation.user.companies")} :</span> <ClientLink to={`/${Pages.AIO}/${AdminAioRoutes.CUSTOMERS}/${user.AI_OCompany.id}/${AdminAioClientRoutes.DETAILS}`}>{user.AI_OCompany.name ?? ''}</ClientLink>
              </Typography>
            )}
            {!!user.AI_OCommentary && (
              <Typography fontSize="14px" fontWeight="300" lineHeight={1.25}>
                <span style={{fontWeight: 900}}>{t("usersValidation.user.comment")} :</span> {user.AI_OCommentary ?? ''}
              </Typography>
            )}
            <Typography fontSize="14px" fontWeight="300" lineHeight={1.25}>
              <span style={{fontWeight: 900}}>{t("usersValidation.user.subscription")} :</span> {subscriptionName}{subscriptionPeriod ? ` • ${subscriptionPeriod}` : ''}{companySubscriptionSelected ? ` • ${offers}` : ''}
            </Typography>
          </Stack>
        );
      case RegisterUserTypeEnum.NEITHER_AIO_NOR_AI_O:
        return (
          <Stack spacing="5px">
            <Typography fontSize="14px" fontWeight="300" lineHeight={1.25}>
              <span style={{fontWeight: 900}}>{t("usersValidation.user.companies")} :</span> {user.missingCompanyName ?? ''}
            </Typography>
            {!!user.missingCompanyCommentary && (
              <Typography fontSize="14px" fontWeight="300" lineHeight={1.25}>
                <span style={{fontWeight: 900}}>{t("usersValidation.user.comment")} :</span> {user.missingCompanyCommentary ?? ''}
              </Typography>
            )}
            <Typography fontSize="14px" fontWeight="300" lineHeight={1.25}>
              <span style={{fontWeight: 900}}>{t("usersValidation.user.subscription")} :</span> {subscriptionName}{subscriptionPeriod ? ` • ${subscriptionPeriod}` : ''}{companySubscriptionSelected ? ` • ${offers}` : ''}
            </Typography>
          </Stack>
        );
      default:
        return "";
    }
  }

  return (
    <>
      <UserValidationCardStyled>
        <Stack spacing="15px">
          <Stack alignItems="center" width="100%" spacing={1.5}>
            <Box p="1px" bgcolor={Colors.greyLight} borderRadius="50%">
              <Avatar sx={{ border: `1.5px solid ${Colors.white}`, height: 50, width: 50 }} src={user.pictureUrl} />
            </Box>
            <Stack spacing={-0.5} alignItems="center">
              <Typography fontSize={16} fontWeight="500" lineHeight={1.25} textAlign="center">
                {user.firstname} {user.lastname}
              </Typography>
              <Typography fontSize={12} fontWeight="300" color={Colors.greyLight}>
                {user.email}
              </Typography>
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" spacing="20px">
            <IconButton sx={{p: 0}} onClick={() => setOpenAccept(true)}>
              <img alt="accept" src="/images/icon_button_accept.svg"/>
            </IconButton>
            <IconButton sx={{p: 0}} onClick={() => setOpenReject(true)}>
              <img alt="refuse" src="/images/icon_button_refuse.svg"/>
            </IconButton>
          </Stack>
        </Stack>
        <Box position="absolute" top="10px" right="10px">
          <Tooltip arrow title={getTooltipByType()}>
            <img alt="" src={RegisterUserTypeEnumFunctions.icon(user.type)} />
          </Tooltip>
        </Box>
      </UserValidationCardStyled>
      {openAccept && <AcceptUserModal registerUser={user} handleClose={() => setOpenAccept(false)}/>}
      {openReject && (
       <ConfirmModal
          modalTitle={t("usersValidation.reject.title")}
          confirmMsg={t("usersValidation.reject.description")}
          handleClose={() => setOpenReject(false)}
          handleConfirm={handleReject}
        />
      )}
    </>
  );
};

export default UserValidationCard;
