import { ATSModel, atsToManageATS, ManageATSModel } from "@store/common/ats.model";
import { EntitiesModel, EntityContactModel } from "@store/entities/entities.model";
import { SelectItem } from "@components/input/Select.component";
import { ClientModel } from "@store/entities/clients";
import { AffiliateModel } from "@store/entities/afiliates";
import {
  GoogleAnalyticsModel,
  ManageGoogleAnalyticsByCompanyNameModel,
  ManageGoogleAnalyticsModel
} from "@store/common/googleAnalytics.model";
import { ManageSellsyModel, SellsyModel } from "@store/common/sellsy.model";
import {
  BudgetLimitationModel,
  BudgetLimitationPeriodEnum,
  ManageBudgetLimitationModel
} from "@store/common/budgetLimitation.model";
import { JobBoard, jobBoardsQuery } from "@store/jobBoards";
import { ManagePowerBIModel, PowerBIModel } from "@store/common/powerbi.model";
import {
  BoostCreditsModel,
  BoostItemEnum,
  BoostItemTypeEnumFunctions,
  ManageBoostCreditsModel
} from "@store/common/boostCredits.model";
import { ManagePlanMediaModel, PlanMediaModel } from "@store/common/planMedia.model";

export interface ManageEntityDetailsModel extends Partial<Omit<EntityDetailsModel, "contacts" | "twinners">> {
  pictureFile?: File;
  pictureURL?: string;
  timelineFile?: File;
  contacts?: Partial<EntityContactModel>[];
  twinners?: (SelectItem | undefined)[];
}

export const entityToManageEntity = (entity: EntityDetailsModel) => ({
  ...entity,
  twinners: entity.twinners?.map((t) => ({
    label: `${t.firstname} ${t.lastname}`,
    value: t.id,
  })),
});

export interface EntityDetailsModel extends EntitiesModel, EntityParameters {
  client?: ClientModel;
  affiliates?: AffiliateModel[];
}

export interface ManageEntityParametersModel {
  atsActivated?: boolean;
  ats?: ManageATSModel;
  googleAnalyticsActivated?: boolean;
  googleAnalytics?: ManageGoogleAnalyticsModel;
  sellsyActivated?: boolean;
  sellsy?: ManageSellsyModel;
  budgetLimitationActivated?: boolean;
  budgetLimitation?: ManageBudgetLimitationModel;
  prefilteredJobBoardsActivated?: boolean;
  prefilteredJobBoards?: SelectItem[];
  powerBIActivated?: boolean;
  powerBI?: ManagePowerBIModel[];
  boostCreditsActivated?: boolean;
  boostCredits?: ManageBoostCreditsModel[];
  planMediaActivated?: boolean;
  planMedia?: ManagePlanMediaModel;
}

export const parametersToManageEntityParameters = (parameters: EntityParameters): ManageEntityParametersModel => ({
  atsActivated: !!parameters.ats,
  ats: atsToManageATS(parameters.ats),
  googleAnalyticsActivated: !!parameters.googleAnalytics,
  googleAnalytics: {
    ...parameters.googleAnalytics,
    companyNames:
      parameters.ats?.companyNames?.map((companyName) => {
        const googleAnalyticsConfig = parameters.googleAnalytics?.companyNames?.find((c) => c.companyName === companyName);
        return {
          companyName: companyName,
          companyNameFilter: googleAnalyticsConfig?.companyNameFilter,
          adsFilters: googleAnalyticsConfig?.adsFilters,
        } as ManageGoogleAnalyticsByCompanyNameModel;
      }) || [],
  },
  sellsyActivated: !!parameters.sellsy,
  sellsy: parameters.sellsy,
  budgetLimitationActivated: !!parameters.budgetLimitation,
  budgetLimitation: {
    ...parameters.budgetLimitation,
    duration:
      !!parameters.budgetLimitation?.from && !!parameters.budgetLimitation?.to ? BudgetLimitationPeriodEnum.CUSTOM : BudgetLimitationPeriodEnum.MONTH,
  },
  prefilteredJobBoardsActivated: !!parameters.prefilteredJobBoards?.length,
  prefilteredJobBoards:
    parameters.prefilteredJobBoards?.map((j) => ({
      label: j.name,
      value: j.id,
    })) || [],
  powerBIActivated: !!parameters.powerBI?.length,
  powerBI: parameters.powerBI,
  boostCreditsActivated: !!parameters.boostCredits?.length,
  boostCredits: parameters.boostCredits?.map((bc) => {
    let boostItems: SelectItem[] = [];
    switch (bc.type) {
      case BoostItemEnum.BOOST_ACTION:
        boostItems = jobBoardsQuery.boostActionsSelectItems;
        break;
      case BoostItemEnum.JOBBOARD:
        boostItems = jobBoardsQuery.jobBoardsSelectItems;
        break;
      case BoostItemEnum.SEQUENCE:
        boostItems = jobBoardsQuery.sequencesSelectItems;
        break;
    }

    return {
      type: BoostItemTypeEnumFunctions.selectItems.find((i) => bc.type === i.value),
      boostItem: boostItems.find((i) => bc.boostItemId === i.value),
      credits: bc.credits,
      creditsWaitingForValidation: bc.creditsWaitingForValidation,
    };
  }),
  planMediaActivated: !!(
    parameters.planMedia?.fileKey ||
    parameters.planMedia?.generateTimelineAutomatically ||
    parameters.planMedia?.customPrompt
  ),
  planMedia: parameters.planMedia,
});

export interface EntityParameters {
  ats?: ATSModel;
  googleAnalytics?: GoogleAnalyticsModel;
  sellsy?: SellsyModel;
  budgetLimitation?: BudgetLimitationModel;
  prefilteredJobBoards?: JobBoard[];
  powerBI?: PowerBIModel[];
  boostCredits?: BoostCreditsModel[];
  planMedia?: PlanMediaModel;
}
