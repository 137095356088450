import React, { useState } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { CompanyDetailsDetailsModel } from "@store/ai-o/companies/details";

import { Colors } from "@constants/colors.constant";

import EditInformationModal from "@screens/auth/admin/aio/customerDetails/profileBlocks/EditInformation.modal";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { getAppCurrency } from "@store/common/country.model";

interface InformationBlockProps {
  company: CompanyDetailsDetailsModel;
}

const InformationBlock = (props: InformationBlockProps) => {
  const { company } = props;

  const { t } = useTranslation();

  const [openEdit, setOpenEdit] = useState(false);

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("aio.clients.details.information.title")}
          </Typography>
          <Box sx={{ "& > .MuiButton-root": { padding: "5px 10px" } }}>
            <Button variant="text" color="primary" onClick={() => setOpenEdit(true)}>
              {t("global.modify")}
            </Button>
          </Box>
        </Stack>
        <Stack spacing="5px">
          <Stack direction="row" alignItems="center" spacing="10px">
            <Stack height="20px" width="20px" alignItems="center" justifyContent="center">
              <img alt="location" src="/images/location.svg" />
            </Stack>
            <Typography fontSize="14px" fontWeight="500" color={Colors.greyLight} pt="1px">
              {t("aio.clients.details.information.headOffice", { location: company.location })}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing="10px">
            <Stack height="20px" width="20px" alignItems="center" justifyContent="center">
              <img alt="creation date" src="/images/icon_flag_grey.svg" />
            </Stack>
            <Typography fontSize="14px" fontWeight="500" color={Colors.greyLight} pt="1px">
              {!company.creationDate ? "-" : t("aio.clients.details.information.createdAt", { year: new Date(company.creationDate) })}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing="10px">
            <Stack height="20px" width="20px" alignItems="center" justifyContent="center">
              <img alt="collaborators" src="/images/icon_users_grey.svg" />
            </Stack>
            <Typography fontSize="14px" fontWeight="500" color={Colors.greyLight} pt="1px">
              {t("aio.clients.details.information.collaborators", { count: company.nbCollaborates ?? 0 })}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing="10px">
            <Stack height="20px" width="20px" alignItems="center" justifyContent="center">
              <img alt="turnover" src="/images/icon_euro_grey.svg" />
            </Stack>
            <Typography fontSize="14px" fontWeight="500" color={Colors.greyLight} pt="1px">
              {`${(company.turnover ?? 0).toLocaleString("en-US").replaceAll(",", " ")}${getAppCurrency()} ${t(
                "aio.clients.details.information.turnover"
              )}`}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing="10px">
            <Stack height="20px" width="20px" alignItems="center" justifyContent="center">
              <img alt="telework" src="/images/icon_headset_grey.svg" />
            </Stack>
            <Typography fontSize="14px" fontWeight="500" color={Colors.greyLight} pt="1px">
              {company.telework ? t("aio.clients.details.information.telework") : t("aio.clients.details.information.noTelework")}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing="10px">
            <Stack height="20px" width="20px" alignItems="center" justifyContent="center">
              <AlternateEmailIcon sx={{ color: Colors.greyLight, maxHeight: "15px", maxWidth: "15px" }} />
            </Stack>
            <Typography fontSize="14px" fontWeight="500" color={Colors.greyLight} pt="1px">
              {company.website ? company.website : "-"}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
      {openEdit && <EditInformationModal company={company} handleClose={() => setOpenEdit(false)} />}
    </Stack>
  );
};

export default InformationBlock;
