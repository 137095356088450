import { globalStatisticsStore } from "@store/globalStatistics/globalStatistics.store";
import { catchError, from, map, Observable, tap } from "rxjs";

import {
  getAdminGlobalStatisticsDataSource,
  getAffiliatesGlobalStatisticsDataSource
} from "@store/globalStatistics/globalStatistics.requests";
import APIAxios, { APIRoutes } from "@api/axios.api";
import { sessionQuery } from "@store/session";
import { AxiosError, AxiosResponse } from "axios";
import {
  AdminStats,
  AffiliatesStats,
  ClientApplicationsDistribution,
  globalStatisticsQuery,
  StatsFilters
} from "@store/globalStatistics";
import SnackError from "@utils/error.utils";
import { AdApplicationsStats } from "@store/ads/details";

export class GlobalStatisticsService {
  store = globalStatisticsStore;

  resetStore = () => this.store.reset();

  setFilters = (filters: Partial<StatsFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  getAffiliatesStats = (filters?: StatsFilters) => {
    const affiliateIds = filters?.affiliateIds
      ? filters.affiliateIds.map((c) => c.value)
      : sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined;

    return from(APIAxios({
      ...APIRoutes.GETAffiliateDashboardStats(),
      params: { affiliateIds }
    }))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError((err.response as any)?.data?.message, "error");
        }),
        map((response: AxiosResponse<AffiliatesStats>) => {
          return response.data;
        }),
        tap((affiliatesStatistics) => {
          this.store.update(
            (state) => ({
              ...state,
              affiliates: affiliatesStatistics
            }),
            getAffiliatesGlobalStatisticsDataSource.setSuccess()
          );
        }),
        getAffiliatesGlobalStatisticsDataSource.trackRequestStatus()
      );
  };

  getAffiliatesApplicationsStats = (year: number): Observable<AdApplicationsStats> => {
    const affiliateIdsFilters = sessionQuery.clientViewClient
      ? [sessionQuery.clientViewClient.id]
      : globalStatisticsQuery.filters.affiliateIds?.map((c) => c.value);
    const affiliateIds = affiliateIdsFilters
      ? affiliateIdsFilters
      : sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined;

    return from(APIAxios({
      ...APIRoutes.GETAffiliatesApplicationsStats(),
      params: { affiliateIds, year }
    }))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError((err.response as any)?.data?.message, "error");
        }),
        map((response: AxiosResponse<AdApplicationsStats>) => {
          return response.data;
        })
      );
  };

  getAffiliatesApplicationsDistribution = (): Observable<ClientApplicationsDistribution> => {
    const affiliateIdsFilters = sessionQuery.clientViewClient
      ? [sessionQuery.clientViewClient.id]
      : globalStatisticsQuery.filters.affiliateIds?.map((c) => c.value);
    const affiliateIds = affiliateIdsFilters
      ? affiliateIdsFilters
      : sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined;

    return from(APIAxios({
      ...APIRoutes.GETAffiliatesApplicationsDistribution(),
      params: { affiliateIds },
    }))
      .pipe(
        catchError((err: AxiosError) => {
          throw new SnackError((err.response as any)?.data?.message, "error");
        }),
        map((response: AxiosResponse<ClientApplicationsDistribution>) => {
          return response.data;
        })
      );
  };

  getAdminStats = () => {
    return from(
      APIAxios({
        ...APIRoutes.GETAdminDashboardStats(),
        params: {
          affiliateIds: sessionQuery.affiliateIds?.filter((c) => c.value !== "FAVORITES")?.map((c) => c.value) || undefined,
          favorite: sessionQuery.affiliateIds?.some((c) => c.value === "FAVORITES") || undefined
        }
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AdminStats>) => {
        return response.data;
      }),
      tap((adminStatistics) => {
        this.store.update(
          (state) => ({
            ...state,
            admin: adminStatistics
          }),
          getAdminGlobalStatisticsDataSource.setSuccess()
        );
      }),
      getAdminGlobalStatisticsDataSource.trackRequestStatus()
    );
  };
}

export const globalStatisticsService = new GlobalStatisticsService();
