import { alpha, Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { CompanyDetailsDetailsModel, companyDetailsService } from "@store/ai-o/companies/details";

import { Colors } from "@constants/colors.constant";

import ConfirmModal from "@components/modal/Confirm.modal";
import AddVideoModal from "./AddVideo.modal";

interface VideoBlockProps {
  company: CompanyDetailsDetailsModel;
}

const VideoBlock = (props: VideoBlockProps) => {
  const { company } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openAdd, setOpenAdd] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<string | false>(false);
  const [videoToDelete, setVideoToDelete] = useState<string | false>(false);

  const handleDelete = () => {
    if (!confirmDelete) return;
    if (!videoToDelete) return;
    companyDetailsService.deleteYoutubeVideo(company.id, videoToDelete).subscribe({
      next: () => {
        enqueueSnackbar(t("aio.clients.details.video.delete.success"), { variant: "success" });
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const handleClickDelete = (video: string) => {
    setVideoToDelete(video);
    setConfirmDelete(video);
  };

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing="10px">
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("aio.clients.details.video.title")}
          </Typography>
          <Box sx={{ "& > .MuiButton-root": { padding: "5px 10px" } }}>
            <Button variant="text" color="primary" onClick={() => setOpenAdd(true)}>
              {t("global.add")}
            </Button>
          </Box>
        </Stack>
        {company.youtubeVideos ? (
          <Box>
            <Grid container spacing="10px">
              {company.youtubeVideos.map((link, index) => (
                <Grid item key={index} xs={12}>
                  <Stack
                    sx={{
                      position: "relative",
                      "&:hover .delete": {
                        display: "flex",
                      },
                      "&:hover": {
                        "& > embed": {
                          filter: "brightness(0.7) blur(1px)",
                        },
                      },
                    }}>
                    <embed
                      width="100%"
                      height="200"
                      src={`https://www.youtube.com/embed/${link.split("https://www.youtube.com/watch?v=")[1]}`}
                      style={{ borderRadius: "10px" }}
                    />
                    <Stack
                      display="none"
                      className="delete"
                      onClick={() => handleClickDelete(link)}
                      sx={{
                        cursor: "pointer",
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        backgroundColor: alpha(Colors.black, 0.6),
                        borderRadius: "8px",
                        padding: "5px 10px",
                      }}>
                      <img alt="delete" src={`/images/icon_delete_white.svg`} height={"15px"} />
                    </Stack>
                  </Stack>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : null}
      </Stack>
      {openAdd && <AddVideoModal handleClose={() => setOpenAdd(false)} companyId={company.id} />}
      {!!confirmDelete && (
        <ConfirmModal
          handleConfirm={handleDelete}
          confirmMsg={t("aio.clients.details.video.delete.description")}
          modalTitle={t("aio.clients.details.video.delete.title")}
          handleClose={() => setConfirmDelete(false)}
        />
      )}
    </Stack>
  );
};

export default VideoBlock;
