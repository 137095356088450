import { createStore } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withPagination } from "@ngneat/elf-pagination";
import { withRequestsStatus } from "@ngneat/elf-requests";

import { AffiliateModel } from "@store/entities/afiliates/affiliates.model";

export const affiliatesStore = createStore(
  {name: "affiliates"},
  withEntities<AffiliateModel>(),
  withRequestsStatus(),
  withPagination()
);
