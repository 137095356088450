import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { affiliateDetailsService } from "@store/entities/afiliates/details";
import { AffiliateMailType } from "@store/common/mail.model";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";

const NewMediaComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { entityId } = useParams();

  const [loading, setLoading] = useState(false);
  const [newMediaName, setNewMediaName] = useState<string>("");

  const handleSendNewMedia = () => {
    if (!entityId || !newMediaName) return;
    setLoading(true);
    affiliateDetailsService.sendMailToAffiliate(entityId, AffiliateMailType.ACTIVATION_NOUVEAU_MEDIA, newMediaName)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("clients.details.success.mailSent"), { variant: "success" });
          setNewMediaName("");
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <Stack spacing={2}>
      <Typography fontSize="20px" fontWeight={700}>
        {t("subscriptions.newMediaMail")}
      </Typography>
      <Stack spacing={2} direction="row" alignItems="flex-end">
        <AIOTextfieldComponent
          placeholder={t("subscriptions.newMedia")}
          onChange={setNewMediaName}
          value={newMediaName} />
        <AIOButtonComponent
          title={t("global.send")}
          variant="contained"
          disabled={!newMediaName || loading}
          onClick={handleSendNewMedia} />
      </Stack>
    </Stack>
  );
};

export default NewMediaComponent;
