import { createStore, withProps } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { withPagination } from "@ngneat/elf-pagination";

import { Application, ApplicationsFilters } from "./applications.model";

interface ApplicationsStoreProps {
  filters: ApplicationsFilters;
}

export const applicationsStore = createStore(
  { name: "applications" },
  withProps<ApplicationsStoreProps>({
    filters: {},
  }),
  withEntities<Application>(),
  withRequestsStatus(),
  withPagination()
);
