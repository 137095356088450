import {
  alpha,
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import AIOButtonComponent from "@components/Button.component";
import { Colors } from "@constants/colors.constant";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { personaeService } from "@store/personae/personae.service";
import { useSnackbar } from "notistack";
import { sessionQuery } from "@store/session";
import {
  Personae,
  personaeBackgroundColors,
  PersonaeExperience,
  PersonaeQuizAnswer,
  PersonaeQuizCategory
} from "@store/personae";
import ManageProfileModal from "@screens/auth/common/personae/modals/ManageProfile.modal";
import { finalize } from "rxjs";
import ManageProfileFieldModal from "@screens/auth/common/personae/modals/ManageProfileField.modal";
import ConfigAssociatedAdsModal from "./modals/ConfigAssociatedAds.modal";
import { UserRoleEnum } from "@store/users";
import AdCard from "@components/card/Ad.card";

const ProgileTagStyled = styled(Box)(() => ({
  background: Colors.lightMarine,
  borderRadius: "7px",
  padding: "9px 17px"
}));

const AnswerCardStyled = styled(Stack)(() => ({
  alignItems: "center",
  backgroundColor: "white",
  border: "1px solid rgb(239, 239, 239)",
  borderRadius: "15px",
  height: "100%",
  padding: "25px 20px 25px 28px",
  width: "100%"
}));

const PersonaeProfile = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { personaeId } = useParams();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [fieldToEdit, setFieldToEdit] = useState<"experience" | "location" | false>(false);

  const [loading, setLoading] = useState(false);

  const [categoryToEdit, setCategoryToEdit] = useState<PersonaeQuizCategory | false>(false);

  const [personaeProfile, setPersonaeProfile] = useState<Personae>();

  const [configAssociatedAdsOpen, setConfigAssociatedAdsOpen] = useState(false);

  useEffect(() => {
    if (personaeId) {
      personaeService.getPersonaeProfile(personaeId).subscribe({
        next: setPersonaeProfile,
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
    }
  }, [enqueueSnackbar, personaeId]);

  if (!personaeProfile) return null;

  const handleValidatePersonae = () => {
    if (!personaeId) return;
    setLoading(true);
    personaeService
      .validatePersonaeProfile(personaeId)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (np) => {
          enqueueSnackbar(t("personae.validate.success"), { variant: "success" });
          setPersonaeProfile(np);
        },
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
  };

  const job = personaeProfile.list?.[PersonaeQuizCategory.fieldKey(PersonaeQuizCategory.JOB_CATEGORY)]?.[0]?.name;
  const levelOfStudy = personaeProfile.list?.[PersonaeQuizCategory.fieldKey(PersonaeQuizCategory.LEVEL_OF_STUDY)]?.[0]?.name;
  const status = personaeProfile.list?.[PersonaeQuizCategory.fieldKey(PersonaeQuizCategory.STATUS)]?.[0]?.name;

  return (
    <Stack pt={breakpointDownSM ? "10px" : "60px"} px={breakpointDownSM ? "10px" : "40px"} pb="30px" flex={1}
           width="100%" overflow="auto">
      <Stack direction="row" justifyContent="space-between" mb="10px" width="100%">
        <Button variant="text" color="inherit" startIcon={<img alt="back" src="../images/arrow_back.svg" />}
                onClick={() => navigate("..")}>
          <Typography fontWeight="300" color="secondary">
            {t(`personae.${sessionQuery.role === UserRoleEnum.ADMIN ? "title" : "yourTypes"}`)}
          </Typography>
        </Button>
      </Stack>
      <Stack direction={breakpointDownMD ? "column" : "row"} width="100%" spacing="40px"
             overflow={breakpointDownMD ? "auto" : "hidden"}>
        <Stack spacing={breakpointDownMD ? 0 : 2} width="100%" overflow={breakpointDownMD ? undefined : "auto"}>
          <Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              py={breakpointDownSM ? "25px" : "45px"}
              borderRadius="15px"
              bgcolor="white"
              boxShadow={`0 0px 20px 0 ${alpha(Colors.black, 0.05)}`}>
              <Stack
                height="77px"
                width="77px"
                bgcolor={personaeBackgroundColors[personaeProfile.backgroundNumber]}
                borderRadius="50px"
                alignItems="center"
                justifyContent="center">
                <img
                  alt={"avatar"}
                  src={`/images/personaeAvatars/personae${personaeProfile.pictureNumber}.svg`}
                  style={{ height: "50px", width: "50px" }}
                />
              </Stack>
              <Typography fontWeight="700" lineHeight={1} fontSize={25} color="secondary" mt="13px">
                {personaeProfile.name}
              </Typography>
              {job && (
                <Typography fontWeight="500" lineHeight={1} fontSize={14} color={Colors.greyLight} mt="4px">
                  {job}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Box overflow="auto" py="20px" className="scrollable">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                <AnswerCardStyled spacing={1}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                    <Typography fontWeight="700" lineHeight={1} fontSize={20} color="secondary">
                      {t("personae.personaeCategories.profile")}
                    </Typography>
                  </Stack>
                  <Stack alignItems="flex-start" width="100%" spacing="15px">
                    <Stack spacing="13px" direction="row" justifyContent="flex-start" alignItems="center" width="100%">
                      <Stack justifyContent="center" alignItems="center" height="17px" width="17px">
                        <img src="/images/user_profile.svg" alt="experience" />
                      </Stack>
                      <Typography fontWeight="500" lineHeight={1} fontSize={14}>
                        {PersonaeExperience.label(personaeProfile.experience)}
                      </Typography>
                      <IconButton aria-label="edit" size="small" onClick={() => setFieldToEdit("experience")}>
                        <img width="16px" src="/images/edit.svg" alt="edit" />
                      </IconButton>
                    </Stack>
                    <Stack spacing="13px" direction="row" justifyContent="flex-start" alignItems="center" width="100%">
                      <Stack justifyContent="center" alignItems="center" height="17px" width="17px">
                        <img src="/images/diploma.svg" alt="level of study" />
                      </Stack>
                      <Typography fontWeight="500" lineHeight={1} fontSize={14}>
                        {levelOfStudy ?? "-"}
                      </Typography>
                      <IconButton aria-label="edit" size="small"
                                  onClick={() => setCategoryToEdit(PersonaeQuizCategory.LEVEL_OF_STUDY)}>
                        <img width="16px" src="/images/edit.svg" alt="edit" />
                      </IconButton>
                    </Stack>
                    <Stack spacing="13px" direction="row" justifyContent="flex-start" alignItems="center" width="100%">
                      <Stack justifyContent="center" alignItems="center" height="17px" width="17px">
                        <img src="/images/location-black.svg" alt="location" />
                      </Stack>
                      <Typography fontWeight="500" lineHeight={1} fontSize={14}>
                        {personaeProfile.location || "-"}
                      </Typography>
                      <IconButton aria-label="edit" size="small" onClick={() => setFieldToEdit("location")}>
                        <img width="16px" src="/images/edit.svg" alt="edit" />
                      </IconButton>
                    </Stack>
                    <Stack spacing="13px" direction="row" justifyContent="flex-start" alignItems="center" width="100%">
                      <Stack justifyContent="center" alignItems="center" height="17px" width="17px">
                        <img src="/images/business_case-black.svg" alt="status" />
                      </Stack>
                      <Typography fontWeight="500" lineHeight={1} fontSize={14}>
                        {status ?? "-"}
                      </Typography>
                      <IconButton aria-label="edit" size="small"
                                  onClick={() => setCategoryToEdit(PersonaeQuizCategory.STATUS)}>
                        <img width="16px" src="/images/edit.svg" alt="edit" />
                      </IconButton>
                    </Stack>
                  </Stack>
                </AnswerCardStyled>
              </Grid>
              {Object.keys(personaeProfile.list)
                .map((categoryKey) => PersonaeQuizCategory.categoryFromFieldKey(categoryKey))
                .filter(
                  (category) =>
                    ![PersonaeQuizCategory.JOB_CATEGORY, PersonaeQuizCategory.LEVEL_OF_STUDY, PersonaeQuizCategory.STATUS, ""].includes(category)
                )
                .map((category) => {
                  if (!category) return null;

                  const answers: PersonaeQuizAnswer[] = personaeProfile.list[PersonaeQuizCategory.fieldKey(category)] ?? [];

                  return (
                    <Grid item xs={12} sm={12} md={12} lg={6} xl={4} key={category}>
                      <AnswerCardStyled spacing={1}>
                        <Stack direction="row" justifyContent="space-between" alignItems="center" width={"100%"}>
                          <Typography fontWeight="700" lineHeight={1} fontSize={20} color={"secondary"}>
                            {PersonaeQuizCategory.personaeLabel(category)}
                          </Typography>
                          <IconButton aria-label="edit" size="small" onClick={() => setCategoryToEdit(category)}>
                            <img width={"20px"} src="/images/edit.svg" alt="edit" />
                          </IconButton>
                        </Stack>
                        <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start", width: "100%" }}>
                          <Grid container spacing={1}>
                            {answers.map((option) => (
                              <Grid item key={option.id}>
                                <ProgileTagStyled>
                                  <Typography fontWeight="500" lineHeight={1} fontSize={14} color={"secondary"}>
                                    {option.name}
                                  </Typography>
                                </ProgileTagStyled>
                              </Grid>
                            ))}
                          </Grid>
                        </Box>
                      </AnswerCardStyled>
                    </Grid>
                  );
                })}
            </Grid>
          </Box>
          {sessionQuery.role === UserRoleEnum.ADMIN && !personaeProfile.isFinished && !breakpointDownMD && (
            <Stack justifyContent="center" direction="row">
              <AIOButtonComponent
                title={t("personae.validate.button")}
                ariaLabel="modal"
                disabled={loading}
                variant="contained"
                onClick={handleValidatePersonae}
              />
            </Stack>
          )}
        </Stack>
        {personaeProfile.isFinished && (
          <Stack
            height={breakpointDownMD ? "max-content" : "100%"}
            spacing="26px"
            sx={{
              backgroundColor: Colors.greyCardBg,
              width: "100%",
              maxWidth: breakpointDownMD ? "100%" : "326px",
              minWidth: "250px",
              maxHeight: breakpointDownMD ? "auto" : "calc(100vh - 140px)",
              px: "30px",
              py: "22px",
              alignSelf: breakpointDownMD ? "center" : "flex-start",
              borderRadius: "15px"
            }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Typography fontSize="20px" fontWeight={700}>
                {t("personae.associatedAds")}
              </Typography>

              <IconButton
                size="small"
                sx={{ borderRadius: "50%", bgcolor: Colors.primary, "&:hover": { bgcolor: Colors.primary } }}
                onClick={() => setConfigAssociatedAdsOpen(true)}>
                <img
                  alt="Config associated ads"
                  src="/images/icon_settings_white.svg"
                  style={{ height: 20, width: 20 }} />
              </IconButton>
            </Stack>
            <Stack overflow={"auto"} height="100%" spacing="26px" className="scrollable" py="10px">
              {personaeProfile.announcements?.map((announcement) => (
                <Box key={announcement.id}>
                  <AdCard ad={announcement} hideCandidatesNb />
                </Box>
              ))}
            </Stack>
          </Stack>
        )}
      </Stack>
      {!!fieldToEdit && (
        <ManageProfileFieldModal
          data={personaeProfile[fieldToEdit]}
          field={fieldToEdit}
          handleClose={() => setFieldToEdit(false)}
          handleSave={(personae) => {
            setPersonaeProfile(personae);
            setFieldToEdit(false);
          }}
        />
      )}
      {!!categoryToEdit && (
        <ManageProfileModal
          category={categoryToEdit}
          selectedOptions={personaeProfile.list}
          handleSave={(personae) => {
            setPersonaeProfile(personae);
            setCategoryToEdit(false);
          }}
          handleClose={() => setCategoryToEdit(false)}
        />
      )}
      {configAssociatedAdsOpen && (
        <ConfigAssociatedAdsModal
          associatedTitles={personaeProfile.associatedTitles}
          handleClose={() => setConfigAssociatedAdsOpen(false)}
          handleSave={(personae) => {
            setPersonaeProfile(personae);
            setConfigAssociatedAdsOpen(false);
          }}
        />
      )}
    </Stack>
  );
};

export default PersonaeProfile;
