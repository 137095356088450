import { select } from "@ngneat/elf";

import { entitiesFiltersStore } from "@store/entities/entitiesFilters/entitiesFilters.store";

export class EntitiesFiltersQuery {
  store = entitiesFiltersStore;

  filters$ = this.store.pipe(select((state) => state.filters));
  sort$ = this.store.pipe(select((state) => state.sort));

  get params() {
    return this.store.getValue();
  }
}

export const entitiesFiltersQuery = new EntitiesFiltersQuery();
