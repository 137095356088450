import { loadStripe, PaymentIntentResult, SetupIntentResult, Stripe } from "@stripe/stripe-js";
import { catchError, from, map, Observable, switchMap } from "rxjs";
import { sessionService } from "@store/session";
import { UserModel } from "@store/users";
import SnackError from "@utils/error.utils";
import { AxiosError, AxiosResponse } from "axios";
import APIAxios, { APIRoutes } from "@api/axios.api";
import { BoostType } from "@store/boosts";
import { getStripeElementsLocale } from "@store/common/country.model";
import { Coupon, StripePaymentIntentSecret } from "./stripe.model";

interface ProSubClientSecretParams {
  userIds: string[];
  isYearly?: boolean;
  registerUserEmail?: string;
  registerUserFirstname?: string;
  registerUserLastname?: string;
  registerUserPostalCode?: string;
  couponId?: string;
}

export class StripeService {
  stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!);

  getProSubClientSecret = (params: ProSubClientSecretParams): Observable<StripePaymentIntentSecret> => {
    const { userIds, isYearly, registerUserEmail, registerUserFirstname, registerUserLastname, registerUserPostalCode, couponId } = params;
    return from(
      APIAxios({
        ...APIRoutes.POSTGetStripeProSubClientSecret(),
        data: { userIds, isYearly, registerUserEmail, registerUserFirstname, registerUserLastname, registerUserPostalCode, couponId },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<StripePaymentIntentSecret>) => {
        return response.data;
      })
    );
  };

  getBoostRequestClientSecret = (
    adId: string | number,
    sequences: string[],
    jobBoards: string[],
    boostActions: string[],
    schools: string[]
  ): Observable<StripePaymentIntentSecret | undefined> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTGetManualBoostRequestClientSecret(),
        data: {
          announcementId: adId,
          type: BoostType.MANUAL,
          data: {
            sequenceIds: sequences,
            jobBoardIds: jobBoards,
            boostActionIds: boostActions,
            schoolIds: schools,
          },
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<StripePaymentIntentSecret | undefined>) => {
        return response.data;
      })
    );
  };

  checkPaymentSucceeded = (clientSecret: string, dontFetchCurrentUser?: boolean): Observable<UserModel | string> => {
    return from(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!, { locale: getStripeElementsLocale() })).pipe(
      catchError((err: any) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap((stripe: Stripe | null) => {
        if (!stripe) throw new SnackError("STRIPE_NOT_LOADED", "error");
        return from(stripe.retrievePaymentIntent(clientSecret));
      }),
      switchMap((paymentIntentResult: PaymentIntentResult) => {
        if (paymentIntentResult?.paymentIntent?.status !== "succeeded") {
          throw new SnackError("PAYMENT_FAILED", "error");
        }
        if (dontFetchCurrentUser) return "success";

        return sessionService.fetchCurrentUser();
      })
    );
  };

  checkSetupSucceeded = (clientSecret: string, dontFetchCurrentUser?: boolean): Observable<UserModel | string> => {
    return from(loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY!, { locale: getStripeElementsLocale() })).pipe(
      catchError((err: any) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap((stripe: Stripe | null) => {
        if (!stripe) throw new SnackError("STRIPE_NOT_LOADED", "error");
        return from(stripe.retrieveSetupIntent(clientSecret));
      }),
      switchMap((setupIntentResult: SetupIntentResult) => {
        if (setupIntentResult?.setupIntent?.status !== "succeeded") {
          throw new SnackError("PAYMENT_FAILED", "error");
        }
        if (dontFetchCurrentUser) return "success";

        return sessionService.fetchCurrentUser();
      })
    );
  };

  cancelSubscription = (): Observable<UserModel> => {
    return from(APIAxios(APIRoutes.POSTCancelStripeSubscription())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      switchMap(() => {
        return sessionService.fetchCurrentUser();
      })
    );
  };

  getCoupon = (couponName: string): Observable<Coupon> => {
    return from(APIAxios(APIRoutes.GETCoupon(couponName))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Coupon>) => {
        return response.data;
      })
    );
  };
}

export const stripeService = new StripeService();
