import { SelectItem } from "@components/input/Select.component";

import { AffiliateLightModel } from "@store/entities/afiliates";
import { SubscriptionTypeEnum } from "@store/subscriptions";
import { CompanyLight } from "@store/ai-o/companies";

export interface RegisterUsersFilters {
  search?: string;
}

export enum RegisterUserTypeEnum {
  AIO = "AIO",
  AI_O = "AI_O",
  NEITHER_AIO_NOR_AI_O = "NEITHER_AIO_NOR_AI_O",
}

export namespace RegisterUserTypeEnumFunctions {
  export const registerUserTypes = [
    RegisterUserTypeEnum.AIO,
    RegisterUserTypeEnum.AI_O,
    RegisterUserTypeEnum.NEITHER_AIO_NOR_AI_O,
  ]

  export const icon = (type: RegisterUserTypeEnum) => {
    switch (type) {
      case RegisterUserTypeEnum.AIO:
        return '/images/icon_allinone.svg';
      case RegisterUserTypeEnum.AI_O:
        return '/images/icon_aio.svg';
      case RegisterUserTypeEnum.NEITHER_AIO_NOR_AI_O:
        return '/images/icon_not_defined.svg';
    }
  }
}

export interface ManageRegisterUserModel {
  picture?: string;
  firstname?: string;
  lastname?: string;
  email?: string;
  position?: string;
  pictureFile?: File;
  type?: RegisterUserTypeEnum;
  allInOneAffiliate?: SelectItem;
  affiliateSiblings?: { checked: boolean; item: SelectItem }[];
  AI_OCompany?: SelectItem;
  AI_OCommentary?: string;
  missingCompanyName?: string;
  missingCompanyCommentary?: string;
  selectedSubscriptionType?: SubscriptionTypeEnum;
  isMonthly?: boolean;
  companyUnlimitedOffers?: boolean;
  companyOffersNb?: number;
  postalCode?: string;
}

export const DEFAULT_REGISTER_USER = {
  firstname: "",
  lastname: "",
  email: "",
  position: "",
  picture: "",
  pictureFile: undefined,
  type: RegisterUserTypeEnum.AIO,
  allInOneAffiliate: undefined,
  affiliateSiblings: [],
  AI_OCompany: undefined,
  AI_OCommentary: "",
  missingCompanyName: "",
  missingCompanyCommentary: "",
  selectedSubscriptionType: SubscriptionTypeEnum.FREEMIUM,
  isMonthly: true,
  companyUnlimitedOffers: false,
  companyOffersNb: undefined,
};

export const manageRegistertUserToFormData = (requestUser: ManageRegisterUserModel) => {
  const formData = new FormData();

  if (requestUser.firstname) formData.append("firstname", requestUser.firstname);
  if (requestUser.lastname) formData.append("lastname", requestUser.lastname);
  if (requestUser.email) formData.append("email", requestUser.email);
  if (requestUser.position) formData.append("position", requestUser.position);
  if (requestUser.picture) formData.append("pictureUrl", requestUser.picture);
  if (requestUser.pictureFile) formData.append("picture", requestUser.pictureFile);
  if (requestUser.type) formData.append("type", requestUser.type);
  if (requestUser.postalCode) formData.append("postalCode", requestUser.postalCode);

  if (requestUser.allInOneAffiliate) {
    formData.append(
      "allInOneAffiliates",
      JSON.stringify([
        requestUser.allInOneAffiliate.value,
        ...(requestUser.affiliateSiblings
          ? requestUser.affiliateSiblings.filter((a) => a.checked).map((a) => a.item.value)
          : []
        ),
      ])
    );
  }

  if (requestUser.AI_OCompany) {
    formData.append("AI_OCompany", JSON.stringify({ id: requestUser.AI_OCompany.value, name: requestUser.AI_OCompany.label }));
  }

  if (requestUser.AI_OCommentary) formData.append("AI_OCommentary", requestUser.AI_OCommentary);

  if (requestUser.missingCompanyName) formData.append("missingCompanyName", requestUser.missingCompanyName);

  if (requestUser.missingCompanyCommentary) formData.append("missingCompanyCommentary", requestUser.missingCompanyCommentary);

  if (requestUser.selectedSubscriptionType) formData.append("selectedSubscriptionType", requestUser.selectedSubscriptionType);

  if (requestUser.companyOffersNb && requestUser.selectedSubscriptionType === SubscriptionTypeEnum.COMPANY) {
    formData.append("companyOffersNb", requestUser.companyOffersNb.toString());
  }

  formData.append("isMonthly", (!!requestUser.isMonthly).toString());
  if (requestUser.selectedSubscriptionType === SubscriptionTypeEnum.COMPANY) {
    formData.append("companyUnlimitedOffers", (!!requestUser.companyUnlimitedOffers).toString());
  }

  return formData;
};

export interface RegisterUserModel {
  id: string;
  firstname: string;
  lastname: string;
  position: string;
  email: string;
  pictureUrl: string;
  type: RegisterUserTypeEnum;
  allInOneAffiliates?: AffiliateLightModel[];
  AI_OCompany?: CompanyLight;
  AI_OCommentary?: string;
  missingCompanyName?: string;
  missingCompanyCommentary?: string;
  selectedSubscriptionType?: SubscriptionTypeEnum;
  isMonthly: boolean;
  companyUnlimitedOffers?: boolean;
  companyOffersNb?: number;
  postalCode?: string;
}
