import I18nUtils from "@utils/i18n.utils";
import { SelectItem } from "@components/input/Select.component";
import { Colors } from "@constants/colors.constant";
import dayjsUtils from "@utils/dayjs.utils";
import { UserLightModel } from "@store/users";
import { ClientLightModel } from "@store/entities/clients";
import { AffiliateLightModel } from "@store/entities/afiliates";
import { BoostAdModel } from "@store/ads";

export enum BoostStatus {
  NEW = "NEW",
  WAITING_FOR_VALIDATION = "WAITING_FOR_VALIDATION",
  IN_PROGRESS = "IN_PROGRESS",
  VALIDATED = "VALIDATED",
  REJECTED = "REJECTED",
  FINISHED = "FINISHED",
}

export namespace BoostStatus {
  export const selectStatusItems: SelectItem[] = [
    { label: I18nUtils.t("boost.filter.perStatus.waitingForValidation"), value: BoostStatus.WAITING_FOR_VALIDATION },
    { label: I18nUtils.t("boost.filter.perStatus.inProgress"), value: BoostStatus.IN_PROGRESS },
    { label: I18nUtils.t("boost.filter.perStatus.validated"), value: BoostStatus.VALIDATED },
    { label: I18nUtils.t("boost.filter.perStatus.finished"), value: BoostStatus.FINISHED },
    { label: I18nUtils.t("boost.filter.perStatus.rejected"), value: BoostStatus.REJECTED },
  ];

  export const label = (status: BoostStatus) => {
    switch (status) {
      case BoostStatus.NEW:
        return I18nUtils.t("boost.filter.perStatus.new");
      case BoostStatus.WAITING_FOR_VALIDATION:
        return I18nUtils.t("boost.filter.perStatus.waitingForValidation");
      case BoostStatus.IN_PROGRESS:
        return I18nUtils.t("boost.filter.perStatus.inProgress");
      case BoostStatus.VALIDATED:
        return I18nUtils.t("boost.filter.perStatus.validated");
      case BoostStatus.REJECTED:
        return I18nUtils.t("boost.filter.perStatus.rejected");
      case BoostStatus.FINISHED:
        return I18nUtils.t("boost.filter.perStatus.finished");
    }
  };

  export const backgroundColor = (status: BoostStatus) => {
    switch (status) {
      case BoostStatus.NEW:
        return Colors.primary;
      case BoostStatus.WAITING_FOR_VALIDATION:
        return Colors.boostCard.lightInfo;
      case BoostStatus.IN_PROGRESS:
        return Colors.boostCard.lightWarning;
      case BoostStatus.VALIDATED:
        return Colors.boostCard.lightSucced;
      case BoostStatus.REJECTED:
        return Colors.boostCard.lightError;
      case BoostStatus.FINISHED:
        return Colors.boostCard.lightGrey;
    }
  };

  export const labelColor = (status: BoostStatus) => {
    switch (status) {
      case BoostStatus.NEW:
        return Colors.white;
      case BoostStatus.WAITING_FOR_VALIDATION:
        return Colors.boostCard.info;
      case BoostStatus.IN_PROGRESS:
        return Colors.boostCard.warning;
      case BoostStatus.VALIDATED:
        return Colors.boostCard.succeed;
      case BoostStatus.REJECTED:
        return Colors.boostCard.error;
      case BoostStatus.FINISHED:
        return Colors.boostCard.primaryText;
    }
  };
}

export enum BoostType {
  TWININ = "TWININ",
  MANUAL = "MANUAL",
}

export namespace BoostType {
  export const selectTypesItems: SelectItem[] = [
    { label: I18nUtils.t("boost.filter.perType.twinin"), value: BoostType.TWININ },
    { label: I18nUtils.t("boost.filter.perType.manual"), value: BoostType.MANUAL },
  ];
}

export const yearsSelectItems: SelectItem[] = Array.from(Array(dayjsUtils().year() - 2022 + 1))
  .map((_, index) => ({
    label: I18nUtils.t("boost.sort.byYears", { year: 2022 + index }),
    value: 2022 + index,
  }))
  .sort((a, b) => b.value - a.value);

export interface BoostFilters {
  search?: string;
  status?: BoostStatus;
  type?: BoostType;
  year: number;
  affiliateIds?: SelectItem[];
}

export interface BoostModel {
  id: string;
  isNew: boolean;
  endDate: Date;
  client: ClientLightModel;
  affiliate: AffiliateLightModel;
  announcement: BoostAdModel;
  state: BoostStatus;
  type: BoostType;
  refusal: {
    user: UserLightModel;
    reason: string;
  };
  requestedBy: UserLightModel;
  createdAt: Date;
}
