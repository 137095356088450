import React, { useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { Dialog, IconButton, Slider, Stack, Typography, styled } from '@mui/material'
import { Colors } from '@constants/colors.constant'

const VideoSlider = styled(Slider)({
  color: Colors.white,
  padding: 6,
  height: 4,
  '& .MuiSlider-thumb': {
    width: 10,
    height: 10,
    transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
    '&:before': {
      boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
    },
    '&:hover, &.Mui-focusVisible': {
      boxShadow: `0px 0px 0px 8px ${'rgb(0 0 0 / 16%)'}`,
    },
    '&:focus, &.Mui-active, &.Mui-focusVisible': {
      width: 15,
      height: 15,
      boxShadow: `0px 0px 0px 8px ${'rgb(0 0 0 / 16%)'}`,
    },
  },
  '& .MuiSlider-rail': {
    opacity: 0.55,
    color: Colors.white
  },
  '& .MuiSlider-track': {
    color: Colors.white,
    border: "none",
  }
})

const VideoPlayer = styled(ReactPlayer)({
  "& video": {
    width: "100% !important",
    height: "100% !important",
    maxHeight: "70vh !important",
  }
})


interface AioTutorialModalProps {
  handleClose: () => void
}

const AioTutorialModal = (props: AioTutorialModalProps) => {
  const { handleClose } = props
  const videoPlayerRef = useRef(null);
  const [isPlayerHovering, setIsPlayerHovering] = useState(false);
  const [videoState, setVideoState] = useState({
    progress: 0,
    playing: false,
    duration: 0,
    currentTime: 0,
  })

  const min = Math.floor(videoState.currentTime / 60)
  const sec = Math.floor(videoState.currentTime % 60)

  const totalMin = Math.floor(videoState.duration / 60)
  const totalsec = Math.floor(videoState.duration % 60)

  const handlePlayerMouseEnter = () => {
    setIsPlayerHovering(true);
  };

  const handlePlayerMouseLeave = () => {
    setIsPlayerHovering(false);

  };

  const durationHandler = (duration: any) => {
    setVideoState({ ...videoState, duration });
  }

  const playPauseHandler = (playing: boolean) => {
    setVideoState({ ...videoState, playing });
  };

  const progressHandler = (state: any) => {
    if (!videoState.duration) return

    const currentTime = Number(((videoPlayerRef.current as any).getCurrentTime().toFixed(2)));
    const progress = (currentTime / videoState.duration) * 100;

    if (currentTime !== videoState.currentTime) {
      setVideoState({
        ...videoState,
        currentTime,
        progress
      });
    }
  };

  return (
    <Dialog
      open={true}
      PaperProps={{
        style: {
          borderRadius: 15,
          overflow: "hidden",
          maxWidth: "fit-content",
        }
      }}
      onClose={handleClose} >
      <VideoPlayer
        ref={videoPlayerRef}
        width="none"
        height="none"
        style={{
          maxHeight: "70vh",
        }}
        playing={videoState.playing}
        onDuration={durationHandler}
        onProgress={progressHandler}
        onMouseEnter={handlePlayerMouseEnter}
        onMouseLeave={handlePlayerMouseLeave}
        url='/videos/Tuto_prompts_AI-O.mp4' />

      <IconButton
        aria-label="close"
        onClick={handleClose}
        onMouseEnter={handlePlayerMouseEnter}
        style={{
          position: "absolute",
          zIndex: 0,
          top: "10px",
          right: "10px",
          borderRadius: "50%",
          backgroundColor: "RGB(229, 229, 232)",
          height: "25px",
          width: "25px"
        }}>
        <img alt="close" src="/images/icon_close.svg" style={{ height: "8px", width: "8px" }} />
      </IconButton>

      {isPlayerHovering && (
        <React.Fragment>
          <Stack direction="row"
            spacing={2}
            onMouseEnter={handlePlayerMouseEnter}
            style={{
              position: "absolute",
              zIndex: 0,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {videoState.playing ? (

              <IconButton
                aria-label="pause"
                sx={{ height: "63px", width: "63px" }}
                onClick={() => playPauseHandler(false)}
              >
                <img alt="pause" src="/images/icon_pause_round.svg" />
              </IconButton>
            ) : (
              <IconButton
                aria-label="play"
                sx={{ height: "63px", width: "63px" }}
                onClick={() => playPauseHandler(true)}
              >
                <img alt="play" src="/images/icon_play_round.svg" />
              </IconButton>
            )}
          </Stack>
          <Stack
            onMouseEnter={handlePlayerMouseEnter}
            position="absolute"
            px="35px"
            sx={{
              width: "100%",
              bottom: "25px",
              zIndex: 0,
            }}>
            <Typography
              color={Colors.white}
              fontSize="14px"
              fontWeight={500}
              width="fit-content"
              borderRadius={"5px"}
              px={1}
              bgcolor='rgb(0 0 0 / 16%)'
            >
              {min}:{sec < 10 ? `0${sec}` : sec} / {totalMin}:{totalsec}
            </Typography>
            <VideoSlider
              aria-label="videoSlider"
              value={videoState.progress}
              onChange={(_e, v) => {
                (videoPlayerRef.current as any).seekTo(v as number / 100)
                setVideoState({
                  ...videoState,
                  progress: v as number,
                  currentTime: v !== 100 ? (v as number / 100) * videoState.duration : videoState.duration,
                })
              }}
            />
          </Stack>
        </React.Fragment>
      )}
    </Dialog >
  )
}

export default AioTutorialModal