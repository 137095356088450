import {Components} from "@mui/material";

const modal: Components = {
  MuiDialog: {
    styleOverrides: {
      root: {
        "& .MuiDialogTitle-root": {
          padding: "20px 20px 10px 23px"
        },
        "& .MuiDialogActions-root": {
          padding: "0 0 29px 0 !important"
        },
      },
    },
  },
};

export default modal;
