import {Components} from "@mui/material";

const checkbox: Components = {
  MuiCheckbox: {
    styleOverrides: {
      root: {
        width: "14.7px",
        height: "14.7px",
      }
    },
  },
};

export default checkbox;