import { FormHelperText, MenuItem, Select, Stack, Typography } from "@mui/material";

export interface SelectItem {
  label: string;
  value: any;
  icon?: JSX.Element;
  data?: any;
}

interface AIOSelectProps {
  color?: "primary" | "secondary";
  fullWidth?: boolean;
  placeholder?: string;
  value?: any;
  readOnly?: boolean;
  items?: SelectItem[];
  handleChange?: (value: any) => void;
  maxWidth?: string;
  error?: string;
}

const AIOSelectComponent = (props: AIOSelectProps) => {
  const { color, fullWidth, placeholder, items, readOnly, value, handleChange, maxWidth, error } = props;

  return (
    <>
      <Select
        disabled={readOnly}
        value={value ?? ""}
        size="small"
        fullWidth={fullWidth}
        color={color}
        variant="standard"
        disableUnderline
        displayEmpty
        sx={{ maxWidth }}
        onChange={(evt) => handleChange?.(evt.target.value)}>
        {!!placeholder && (
          <MenuItem value="" style={{ opacity: 0.5 }}>
            {placeholder}
          </MenuItem>
        )}

        {items?.map((item) => (
          <MenuItem key={`${item.label} ${item.value}`} value={item.value}>
            {item.icon ? (
              <Stack direction="row" alignItems="center" gap={1}>
                {item.icon}
                <Typography fontSize="14px">{item.label}</Typography>
              </Stack>
            ) : (
              item.label
            )}
          </MenuItem>
        ))}
      </Select>
      {error ? <FormHelperText sx={{ ml: "14px" }} error>{error}</FormHelperText> : null}
    </>
  );
};

export default AIOSelectComponent;
