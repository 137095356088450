import { Box, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { CompanyDetailsDetailsModel, companyDetailsService } from "@store/ai-o/companies/details";
import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import ModalComponent from "@components/modal/Modal.component";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

interface AddTestimonialProps {
  company: CompanyDetailsDetailsModel;
  modifyId?: string;
  handleClose: () => void;
}

const AddTestimonial = (props: AddTestimonialProps) => {
  const { company, handleClose, modifyId } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState<string>(modifyId ? company.testimonials?.find((t) => t.id === modifyId)?.title ?? "" : "");
  const [name, setName] = useState<string>(modifyId ? company.testimonials?.find((t) => t.id === modifyId)?.name ?? "" : "");
  const [job, setJob] = useState<string>(modifyId ? company.testimonials?.find((t) => t.id === modifyId)?.job ?? "" : "");
  const [testimonial, setTestimonial] = useState<string>(modifyId ? company.testimonials?.find((t) => t.id === modifyId)?.content ?? "" : "");

  const handleSave = () => {
    setLoading(true);
    companyDetailsService
      .addTestimonialCompany(company.id, title, name, job, testimonial)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.testimonial.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleModify = () => {
    setLoading(true);
    if (!modifyId) return;
    companyDetailsService
      .patchTestimonialCompany(company.id, modifyId, title, name, job, testimonial)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.testimonial.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handleCLick = modifyId ? handleModify : handleSave;

  const errors = {
    title: getFieldError(title, FieldValidationType.REQUIRED_STRING),
    name: getFieldError(name, FieldValidationType.REQUIRED_STRING),
    job: getFieldError(job, FieldValidationType.REQUIRED_STRING),
    testimonial: getFieldError(testimonial, FieldValidationType.REQUIRED_STRING),
  };

  return (
    <ModalComponent
      title={t("aio.clients.details.testimonial.addTitle")}
      titleLeft
      content={
        <Box p="10px">
          <Grid container columnSpacing="30px" rowSpacing="20px">
            <Grid item xs={6}>
              <AIOTextfieldComponent
                required
                title={t("aio.clients.details.testimonial.fields.name")}
                placeholder={t("aio.clients.details.testimonial.fields.name")}
                value={name ?? ""}
                onChange={setName}
                error={errors.name}
              />
            </Grid>
            <Grid item xs={6}>
              <AIOTextfieldComponent
                required
                title={t("aio.clients.details.testimonial.fields.job")}
                placeholder={t("aio.clients.details.testimonial.fields.job")}
                value={job ?? ""}
                onChange={setJob}
                error={errors.job}
              />
            </Grid>
            <Grid item xs={12}>
              <AIOTextfieldComponent
                required
                title={t("aio.clients.details.testimonial.fields.title")}
                placeholder={t("aio.clients.details.testimonial.fields.title")}
                value={title ?? ""}
                onChange={setTitle}
                error={errors.title}
              />
            </Grid>
            <Grid item xs={12}>
              <AIOTextfieldComponent
                required
                multiline
                minRows={5}
                title={t("aio.clients.details.testimonial.fields.testimonial")}
                placeholder={t("aio.clients.details.testimonial.fields.testimonial")}
                value={testimonial ?? ""}
                onChange={setTestimonial}
                error={errors.testimonial}
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          ariaLabel="modal"
          disabled={loading || checkIfErrors(errors)}
          onClick={handleCLick}
          title={t("global.save")}
        />
      }
      handleClose={handleClose}
    />
  );
};

export default AddTestimonial;
