import { useObservable } from "@ngneat/react-rxjs";
import { Box, CardActionArea, Grid, Stack, styled, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { JobBoard, JobBoardAndSequenceAndBoostActionAndSchoolUI, jobBoardsQuery, jobBoardsService } from "@store/jobBoards";

import { Colors } from "@constants/colors.constant";
import { BoostCreditsModel } from "@store/common/boostCredits.model";
import ImageWithRetryFeature from "@components/ImageWithRetryFeature.component";

interface JobBoardCardStyledProps {
  breakpointDownSM?: boolean;
  isSelected?: boolean;
}

const JobBoardCardStyled = styled(Stack)(
  (props: JobBoardCardStyledProps) => `
  box-shadow: 0px 0px 20px rgba(34, 34, 64, 0.05);
  background-color: ${Colors.white};
  border: 1px solid ${props.isSelected ? Colors.primary : "transparent"};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  height: ${props.breakpointDownSM ? 130 : 158}px;
  width: ${props.breakpointDownSM ? 130 : 158}px;
  margin: auto;
  opacity: 1;
  flex: 1;
  position: relative;
  & > .MuiCardActionArea-root {
    background-color: ${Colors.white};
    background-repeat: no-repeat;
    background-position: center;
    backdrop-filter: blur(20px);
    align-items: flex-start;
    background-size: cover;
    border-radius: 20px;
    padding: 10px;
    height: 100%;
  }
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    }
  }
`
);

const CreditBadgeStyled = styled(Stack)`
  background-color: ${Colors.lightPrimaryNoAlpha};
  height: 22px;
  width: fit-content;
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 4px;
`;

interface JobBoardsListProps {
  boostCredits?: BoostCreditsModel[];
}

const JobBoardsList = (props: JobBoardsListProps) => {
  const { boostCredits = [] } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [jobBoards] = useObservable(jobBoardsQuery.jobBoards$);

  const [filters] = useObservable(jobBoardsQuery.filters$);

  const jobBoardTypes = jobBoards.reduce((types, jobBoard) => {
    if (!!filters?.search && !jobBoard.name.toLowerCase().includes(filters.search.toLowerCase())) return types;
    const withCredits = boostCredits.find((x) => x.boostItemId === jobBoard.id);
    const typeName = withCredits ? t("boostRequest.selection.myself.credits.withCredits") : jobBoard.type || t("global.others");
    const type = types[typeName] || [];
    type.push(jobBoard);
    return { ...types, [typeName]: type };
  }, {} as { [type: string]: (JobBoard & JobBoardAndSequenceAndBoostActionAndSchoolUI)[] });

  return (
    <Stack minHeight="100%" alignItems="center">
      <Stack spacing={4}>
        {Object.keys(jobBoardTypes)
          .sort((a, b) => {
            if (a === t("boostRequest.selection.myself.credits.withCredits")) return -1;
            if (b === t("boostRequest.selection.myself.credits.withCredits")) return 1;
            return a.localeCompare(b);
          })
          .filter((jobBoardType) => !!jobBoardTypes[jobBoardType].length)
          .map((jobBoardType, index) => (
            <Stack key={`Jobboard type ${index}`} spacing={2}>
              <Typography fontSize={20} fontWeight="700" textAlign="center" lineHeight={1.25}>
                {jobBoardType}
              </Typography>
              <Box>
                <Grid container spacing={3} justifyContent={breakpointDownSM ? "center" : undefined}>
                  {jobBoardTypes[jobBoardType].map((jobBoard) => {
                    const boostCredit = boostCredits.find((x) => x.boostItemId === jobBoard.id);
                    const disabledAction = jobBoard.price <= 0 || (boostCredit && boostCredit.credits - boostCredit.creditsWaitingForValidation <= 0);

                    return (
                      <Grid item key={jobBoard.id}>
                        <JobBoardCardStyled isSelected={jobBoard.selected} breakpointDownSM={breakpointDownSM}>
                          {boostCredit && (
                            <CreditBadgeStyled alignItems={"center"} justifyContent={"center"}>
                              <Typography mx={"10px"} color={Colors.primary} fontWeight={500} fontSize={11}>
                                {t("boostRequest.selection.myself.credits.amount", {
                                  count: boostCredit.credits - boostCredit.creditsWaitingForValidation,
                                })}
                              </Typography>
                            </CreditBadgeStyled>
                          )}
                          <Stack
                            disableRipple={disabledAction}
                            disableTouchRipple={disabledAction}
                            sx={{
                              cursor: disabledAction ? "default" : undefined,
                              opacity: disabledAction ? 0.35 : 1,
                            }}
                            onClick={() => {
                              if (!disabledAction) jobBoardsService.selectJobBoardOrSequenceOrBoostAction(jobBoard.id);
                            }}
                            component={CardActionArea}>
                            <Stack width="100%" height="100%" alignItems="flex-end" position="relative">
                              <Tooltip
                                sx={{ position: "relative" }}
                                title={
                                  <Stack spacing={0.25}>
                                    <Typography fontSize={12} fontWeight="500">
                                      {jobBoard.name}
                                    </Typography>
                                    <Typography fontSize={12} fontWeight="300" lineHeight={1.25} whiteSpace="pre-line">
                                      {jobBoard.description}
                                    </Typography>
                                    {jobBoard.information && (
                                      <Typography fontSize={12} fontWeight="300" fontStyle="italic" lineHeight={1.25} whiteSpace="pre-line">
                                        {jobBoard.information}
                                      </Typography>
                                    )}
                                  </Stack>
                                }
                                arrow
                                placement="top">
                                <img alt="details" style={{ position: "absolute" }} src="/images/icon_informations.svg" />
                              </Tooltip>
                              {jobBoard.picture ? (
                                <ImageWithRetryFeature
                                  src={jobBoard.picture}
                                  alt={jobBoard.name}
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    alignSelf: "center",
                                    margin: "auto",
                                  }}
                                />
                              ) : (
                                <Typography
                                  alignSelf="center"
                                  m="auto"
                                  textAlign="center"
                                  sx={{ wordBreak: "break-word" }}
                                  lineHeight={1.25}
                                  fontSize={18}
                                  fontWeight="700"
                                  color={Colors.grey}>
                                  {jobBoard.name}
                                </Typography>
                              )}
                            </Stack>
                          </Stack>
                        </JobBoardCardStyled>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Stack>
          ))}
      </Stack>
    </Stack>
  );
};

export default JobBoardsList;
