import { alpha, Stack, Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { Mention, MentionsInput, OnChangeHandlerFunc } from "react-mentions";
import { UserTagItem } from "@store/users";

interface AIOTaggableTextfieldProps {
  title?: string;
  fetchTags: (query: string, callback: (userTagItems: UserTagItem[]) => void) => void;
  placeholder?: string;
  value?: string;
  onChange?: OnChangeHandlerFunc;
  onKeyDown?: (e: any) => void;
  ariaLabel?: string;
  onBlur?: (e: any) => void;
  children?: any;
}

const AIOTaggableTextfieldComponent = (props: AIOTaggableTextfieldProps) => {
  const { title, fetchTags, placeholder, value, onChange, onKeyDown, ariaLabel, onBlur } = props;

  return (
    <Stack>
      {title && (
        <Typography fontSize="12px" color={Colors.secondaryText}>
          {title}
        </Typography>
      )}
      <MentionsInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        aria-label={ariaLabel}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        style={{
          control: {
            fontSize: 14,
            fontWeight: 500,
          },

          "&multiLine": {
            control: {
              //   minHeight: 40,
            },
            highlighter: {
              padding: "12px 15px",
              border: "1px solid transparent",
              borderRadius: 10,
              backgroundColor: Colors.greyCardBg,
            },
            input: {
              padding: "12px 15px",
              border: "1px solid transparent",
              borderRadius: 10,
              outline: 0,
            },
          },

          suggestions: {
            backgroundColor: "transparent",

            list: {
              backgroundColor: "white",
              borderRadius: 15,
              overflow: "hidden",
              //   padding: "12.5px 10px 12.5px 10px",
              boxShadow: `0 8px 20px 0 ${alpha(Colors.black, 0.1)}`,
            },
            item: {
              overflow: "hidden",
              padding: "10px 15px",
              "&focused": {
                backgroundColor: alpha(Colors.black, 0.04),
              },
            },
          },
        }}>
        <Mention
          displayTransform={(id, display) => `@${display}`}
          trigger="@"
          data={fetchTags}
          style={{ backgroundColor: alpha(Colors.primary, 0.1) }}
        />
      </MentionsInput>
    </Stack>
  );
};

export default AIOTaggableTextfieldComponent;
