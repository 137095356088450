import { getAIPromptsDataSource } from "./aiPrompts.requests";
import { aiPromptsStore } from "./aiPrompts.store";

export class AIPromptsQuery {
  store = aiPromptsStore;

  prompts$ = getAIPromptsDataSource.data$();
}

export const aiPromptsQuery = new AIPromptsQuery();
