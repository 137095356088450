import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";
import { reportsService } from "@store/reports/reports.service";
import { ReportsFilters } from "@store/reports/reports.model";

interface SearchReportsParams {
  filters?: ReportsFilters;
}

export const searchReportsEffect = createEffectFn((params$: Observable<SearchReportsParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ filters }) => reportsService.getReports(filters))
  );
});
