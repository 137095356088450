import {
  CardActionArea,
  CircularProgress,
  IconButton,
  Stack,
  styled,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { ToolboxTool } from "@store/toolbox";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import {
  affiliateDetailsService,
  AffiliateToolboxTool,
  AffiliateToolboxToolStatusEnum
} from "@store/entities/afiliates/details";
import React, { useState } from "react";
import { Colors } from "@constants/colors.constant";
import CardBackground from "@components/cardBackground/CardBackground.component";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";
import ConfirmModal from "@components/modal/Confirm.modal";
import { filesService } from "@store/files";
import { EntitiesModel } from "@store/entities/entities.model";

const IconButtonStyles = styled(IconButton)`
  background-color: white;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: white !important;
  }
`;

interface ToolboxToolCellProps {
  setOpenRequestAcceptedModal?: React.Dispatch<React.SetStateAction<boolean>>;
  tool: ToolboxTool;
  affiliateToolboxTool?: AffiliateToolboxTool;
  entity: EntitiesModel;
  columnId: string;
  setRequestedToolTitle?: React.Dispatch<React.SetStateAction<string>>;
}

const ToolboxToolCell = (props: ToolboxToolCellProps) => {
  const { setOpenRequestAcceptedModal, tool, affiliateToolboxTool, entity, columnId, setRequestedToolTitle } = props;

  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();

  const isRequested = affiliateToolboxTool?.status === AffiliateToolboxToolStatusEnum.REQUESTED;
  const isUploaded = affiliateToolboxTool?.status === AffiliateToolboxToolStatusEnum.UPLOADED;

  const [requestLoading, setRequestLoading] = useState(false);
  const [confirmRequestModalOpen, setConfirmRequestModalOpen] = useState(false);
  const [confirmDeleteToolModalOpen, setConfirmDeleteToolModalOpen] = useState(false);

  const handleRequestTool = () => {
    setRequestLoading(true);
    affiliateDetailsService
      .requestToolboxTool(entity.id, {
        category: tool.category,
        title: tool.title,
        columnId,
      })
      .subscribe({
        next: () => {
          setRequestLoading(false);
          setRequestedToolTitle?.(tool.title);
          setOpenRequestAcceptedModal?.(true);
        },
        error: (err) => {
          setRequestLoading(false);
          enqueueSnackbar(err.text, err.options);
        },
      });
  };

  const handleDeleteTool = (toolboxToolId: string | undefined, documentKey: string | undefined | null) => {
    if (toolboxToolId && documentKey) {
      setRequestLoading(true);
      affiliateDetailsService.deleteToolboxTool(toolboxToolId, documentKey).subscribe({
        error: (err) => {
          enqueueSnackbar(err.text, err.options);
        },
      });
    }
  };

  const handleDownloadDocument = (URL: string, toolboxToolId: string) => {
    filesService.openUrlInNewTab(URL);
    affiliateDetailsService.incDownloadToolboxTool(toolboxToolId).subscribe();
  };

  return (
    <>
      {isRequested && (
        <Stack spacing="7px" p="15px" bgcolor={Colors.lightPrimary} borderRadius="15px">
          <Stack spacing="10px" direction="row">
            <img width="15px" src="/images/alert.svg" alt="alert" />
            <Typography fontSize="12px" fontWeight="500" lineHeight="1.25" color={Colors.primary}>
              {t("toolbox.request", { toolTitle: `${tool.category ? tool.category + " - " : ""}${tool.title}` })}
            </Typography>
          </Stack>
          <Stack spacing="4px" direction="row">
            <Typography fontSize="12px" fontWeight="300" color={Colors.greyLight}>
              {t("toolbox.requestedBy")}
            </Typography>
            <Typography fontSize="12px" fontWeight="500">
              {`${affiliateToolboxTool?.requestedBy?.firstname} ${affiliateToolboxTool?.requestedBy?.lastname}`}
            </Typography>
          </Stack>
        </Stack>
      )}
      {isUploaded && (
        <CardBackground
          img={affiliateToolboxTool.fileFormat === "pdf" ? "/images/pdf-thumbnail.png" : affiliateToolboxTool.downloadURL ?? ""}
          bgColor="#8E8EA31A"
          height={breakpointDownSM ? "150px" : "206px"}
          children={
            sessionQuery.role === UserRoleEnum.ADMIN ? (
              <Stack direction="row" spacing={1} p="15px" justifyContent="space-between" alignItems="center">
                <Stack px="12px" pt="6px" pb="4px" bgcolor="rgba(0,0,0,0.47)" borderRadius="15px">
                  <Typography color="white" fontSize="10px" fontWeight="300" textAlign="center">
                    {t("toolbox.downloads", { downloadsNb: affiliateToolboxTool.downloads })}
                  </Typography>
                </Stack>
                <IconButtonStyles onClick={() => setConfirmDeleteToolModalOpen(true)}>
                  <img alt="" src="/images/icon_delete.svg" />
                </IconButtonStyles>
              </Stack>
            ) : (
              <Stack direction="row" spacing={1} p="15px" alignItems="center" justifyContent="flex-end">
                <Stack px="12px" pt="6px" pb="4px" bgcolor="rgba(0,0,0,0.47)" borderRadius="15px" mr="auto">
                  <Typography color="white" fontSize="10px" fontWeight="300" textAlign="center">
                    {t("toolbox.downloads", { downloadsNb: affiliateToolboxTool.downloads })}
                  </Typography>
                </Stack>
                <IconButtonStyles
                  onClick={() =>
                    affiliateToolboxTool.downloadURL &&
                    navigator.share({
                      title: affiliateToolboxTool.title,
                      url: affiliateToolboxTool.downloadURL,
                    })
                  }>
                  <img alt="" src="/images/icon_email.svg" />
                </IconButtonStyles>
                <IconButtonStyles
                  onClick={() =>
                    affiliateToolboxTool.downloadURL &&
                    affiliateToolboxTool.documentKey &&
                    handleDownloadDocument(affiliateToolboxTool.downloadURL, affiliateToolboxTool.id)
                  }>
                  <img alt="" src="/images/download_media.svg" />
                </IconButtonStyles>
              </Stack>
            )
          }
        />
      )}
      {!affiliateToolboxTool && sessionQuery.role !== UserRoleEnum.ADMIN && (
        <CardActionArea
          onClick={() => setConfirmRequestModalOpen(true)}
          sx={{ borderRadius: "15px !important" }}>
          <CardBackground
            img={""}
            bgColor={"#8E8EA31A"}
            height={"206px"}
            children={
              <Stack height="100%" spacing={0.5} alignItems={"center"} sx={{ verticalAlign: "center" }} justifyContent={"center"} px="20px">
                {requestLoading ? (
                  <Stack height="100%" width="100%" justifyContent="center" alignItems="center">
                    <CircularProgress size={20} />
                  </Stack>
                ) : (
                  <>
                    <IconButton
                      size="small"
                      component="label"
                      sx={{
                        height: 25,
                        width: 25,
                        backgroundColor: Colors.primary,
                        "&:hover": {
                          backgroundColor: Colors.primary,
                        },
                      }}>
                      <img alt="plus" src="/images/button_add_more_white.svg" />
                    </IconButton>
                    <Typography color={Colors.greyLight} textAlign="center">
                      {(tool.category ? tool.category + " - " : "") + tool.title}
                    </Typography>
                  </>
                )}
              </Stack>
            }
          />
        </CardActionArea>
      )}
      {isUploaded && (
        <Stack direction="row" justifyContent="space-between" mt="10px !important" alignItems="center">
          <Typography maxWidth="65%" noWrap color={Colors.greyLight}>
            {tool.title}
          </Typography>
          <Typography color={Colors.greyLight} fontWeight="300px" fontSize="12px">
            {t("toolbox.formatPic", {
              format: affiliateToolboxTool.fileFormat,
            })}
          </Typography>
        </Stack>
      )}
      {confirmRequestModalOpen && (
        <ConfirmModal
          modalTitle={t("toolbox.confirmRequestToolTitle")}
          confirmMsg={t("toolbox.confirmRequestTool", { title: (tool.category ? tool.category + " - " : "") + tool.title })}
          handleClose={() => setConfirmRequestModalOpen(false)}
          handleConfirm={handleRequestTool}
        />
      )}
      {confirmDeleteToolModalOpen && (
        <ConfirmModal
          modalTitle={t("toolbox.confirmDeleteToolTitle")}
          confirmMsg={t("toolbox.confirmDeleteTool", { title: (tool.category ? tool.category + " - " : "") + tool.title })}
          handleClose={() => setConfirmDeleteToolModalOpen(false)}
          handleConfirm={() => handleDeleteTool(affiliateToolboxTool?.id, affiliateToolboxTool?.documentKey)}
        />
      )}
    </>
  );
};

export default ToolboxToolCell;
