import React, { useState } from "react";
import { Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { finalize, Observable, of, switchMap, tap } from "rxjs";
import { useSnackbar } from "notistack";

import { affiliatesService } from "@store/entities/afiliates";
import { companyDetailsService } from "@store/ai-o/companies/details";
import { companiesService } from "@store/ai-o/companies";
import { affiliateDetailsService } from "@store/entities/afiliates/details";

import { Colors } from "@constants/colors.constant";

import ModalComponent from "@components/modal/Modal.component";
import AsyncSelectWithSearchComponent from "@components/input/AsyncSelectWithSearch.component";
import { SelectItem } from "@components/input/Select.component";
import AIOButtonComponent from "@components/Button.component";

import EntityModal from "@screens/auth/admin/entities/components/Entity.modal";
import { EntityTypeEnum } from "@store/entities/entities.model";
import { EntityDetailsModel } from "@store/entities/details";
import { CandidateCompanyInfo } from "@store/ai-o/candidates";

interface ReattachAffiliateModalProps {
  candidateCompanyInfo: CandidateCompanyInfo;
  handleClose: () => void;
  handleSuccess?: () => Observable<any>;
  shouldUpdateCompanies?: boolean;
}

const ReattachAffiliateModal = (props: ReattachAffiliateModalProps) => {
  const { candidateCompanyInfo, handleClose, handleSuccess, shouldUpdateCompanies } = props;

  const [addClient, setAddClient] = useState(false);

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [affiliate, setAffiliate] = useState<SelectItem | undefined>();

  const handleReattachAffiliate = (affiliateId: string, affiliateName: string) => {
    if (!affiliateId || !affiliateName) return;
    setLoading(true);
    companyDetailsService
      .reattachCompany(candidateCompanyInfo.companyId, affiliateId, affiliateName)
      .pipe(
        switchMap(() => affiliateDetailsService.setAIOFlux(affiliateId, candidateCompanyInfo.companyId, candidateCompanyInfo.companyName)),
        switchMap(() => (handleSuccess ? handleSuccess() : of(undefined))),
        tap(() => {
          if (shouldUpdateCompanies) companiesService.updateCompanyReattached(candidateCompanyInfo.companyId, affiliateId);
        }),
        finalize(() => setLoading(false))
      )
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.reattachAffiliate.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <ModalComponent
      title={t("aio.reattachAffiliate.title")}
      titleLeft
      maxWidth="xs"
      content={
        <Stack py="10px">
          <Typography fontSize="12px" color={Colors.secondaryText}>
            {t("aio.reattachAffiliate.description")}
            <AsyncSelectWithSearchComponent
              placeholder={t("global.select")}
              minimumSearchLength={1}
              handleChange={setAffiliate}
              value={affiliate}
              getOptions={affiliatesService.searchAffiliates}
              noResultComponent={
                <Stack direction="column" justifyContent="center" alignItems="center" spacing={1} margin="15px 15px">
                  <Typography fontSize="14px" color={Colors.primaryText} textAlign="center">
                    {t("aio.reattachAffiliate.noResult")}
                  </Typography>
                  <Typography fontSize="14px" color={Colors.primaryText} textAlign="center" fontWeight="bold">
                    <span style={{ cursor: "pointer", color: Colors.primary }} onClick={() => setAddClient(true)}>
                      {t("aio.reattachAffiliate.createClient")}
                    </span>
                  </Typography>
                </Stack>
              }
            />
          </Typography>
          {addClient && (
            <EntityModal
              defaultEntity={{
                name: candidateCompanyInfo.companyName,
                description: candidateCompanyInfo.companyDescription,
                pictureURL: candidateCompanyInfo.companyLogoURL,
                address: candidateCompanyInfo.companyLocation,
                city: candidateCompanyInfo.companyLocation,
                isAlsoAffiliate: true,
              }}
              isCreation
              forceIsAlsoAffiliate
              handleClose={() => setAddClient(false)}
              type={EntityTypeEnum.CLIENT}
              onCreation={(entity: EntityDetailsModel) => {
                if (!entity.affiliates || entity.affiliates.length === 0) return enqueueSnackbar(t("errors.global"), { variant: "error" });
                handleReattachAffiliate(entity.affiliates[0].id, entity.affiliates[0].name);
                setAddClient(false);
                handleClose();
              }}
            />
          )}
        </Stack>
      }
      actions={
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <AIOButtonComponent
            variant="contained"
            color="secondary"
            ariaLabel="modal"
            disabled={loading || !affiliate}
            onClick={() => affiliate && handleReattachAffiliate(affiliate.value, affiliate.label)}
            title={t("aio.reattachAffiliate.button")}
          />
        </Stack>
      }
      handleClose={handleClose}
    />
  );
};

export default ReattachAffiliateModal;
