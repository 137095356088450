import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";

import { BoostDetailsDetailsModel } from "@store/boosts/details";

import { Colors } from "@constants/colors.constant";

import CardBackgroundComponent from "@components/cardBackground/CardBackground.component";
import ChipComponent from "@components/Chip.component";
import { getAppCurrency } from "@store/common/country.model";
import { BoostType } from "@store/boosts";

interface RejectedRequestCardProps {
  boost: BoostDetailsDetailsModel;
}

const RejectedRequestCard = (props: RejectedRequestCardProps) => {
  const { boost } = props;

  const { t } = useTranslation();

  const hasCreditForBoostItem = useCallback(
    (boostItemId: string) => {
      return boost.affiliate.boostCredits.some((c) => c.boostItemId === boostItemId);
    },
    [boost]
  );

  const jobBoardsWithCredits = useMemo(() => boost.jobBoards.filter((x) => hasCreditForBoostItem(x.jobBoard.id)), [hasCreditForBoostItem, boost]);
  const sequencesWithCredits = useMemo(() => boost.sequences.filter((x) => hasCreditForBoostItem(x.sequence.id)), [hasCreditForBoostItem, boost]);
  const boostActionsWithCredits = useMemo(
    () => boost.boostActions.filter((x) => hasCreditForBoostItem(x.boostAction.id)),
    [hasCreditForBoostItem, boost]
  );
  const totalCredits = useMemo(
    () => jobBoardsWithCredits.length + sequencesWithCredits.length + boostActionsWithCredits.length,
    [jobBoardsWithCredits, sequencesWithCredits, boostActionsWithCredits]
  );

  const jobBoardsTotal = useMemo(
    () =>
      boost.jobBoards
        .filter(({ jobBoard: j }) => j.price && j.price > 0 && !hasCreditForBoostItem(j.id))
        .reduce((prev, current) => prev + current.jobBoard.price, 0),
    [hasCreditForBoostItem, boost]
  );
  const sequencesTotal = useMemo(
    () =>
      boost.sequences
        .filter(({ sequence: s }) => s.price && s.price > 0 && !hasCreditForBoostItem(s.id))
        .reduce((prev, current) => prev + current.sequence.price, 0),
    [hasCreditForBoostItem, boost]
  );
  const boostActionsTotal = useMemo(
    () =>
      boost.boostActions
        .filter(({ boostAction: b }) => b.price && b.price > 0 && !hasCreditForBoostItem(b.id))
        .reduce((prev, current) => prev + current.boostAction.price, 0),
    [hasCreditForBoostItem, boost]
  );

  return (
    <Stack bgcolor={Colors.background} borderRadius="15px">
      <Stack p="30px" spacing={2.5}>
        {!!boost.jobBoards?.length && (
          <Stack spacing={1}>
            <Typography fontSize={16} fontWeight="700">
              {t("boost.details.detailsTab.jobBoardsSelected")}
            </Typography>
            <Box>
              <Grid container spacing={1.5}>
                {boost.jobBoards.map((j) => (
                  <Grid item key={j.jobBoard.id}>
                    <ChipComponent label={j.jobBoard.name} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        )}
        {!!boost.sequences?.length && (
          <Stack spacing={1}>
            <Typography fontSize={16} fontWeight="700">
              {t("boost.details.detailsTab.sequencesSelected")}
            </Typography>
            <Box>
              <Grid container spacing={1.5}>
                {boost.sequences.map((s) => (
                  <Grid item key={s.sequence.id}>
                    <ChipComponent label={s.sequence.name} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        )}
        {!!boost.boostActions?.length && (
          <Stack spacing={1}>
            <Typography fontSize={16} fontWeight="700">
              {t("boost.details.detailsTab.boostActionsSelected")}
            </Typography>
            <Box>
              <Grid container spacing={1.5}>
                {boost.boostActions.map((b) => (
                  <Grid item key={b.boostAction.id}>
                    <ChipComponent label={b.boostAction.name} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        )}
        <Stack spacing={0.25}>
          <Typography fontSize={16} fontWeight="700">
            {t("boost.details.detailsTab.budget")}
          </Typography>
          <Typography>
            {(jobBoardsTotal + sequencesTotal + boostActionsTotal).toFixed(2)}
            {getAppCurrency()} {t("global.exclTax")}
          </Typography>
        </Stack>
        {boost.type === BoostType.MANUAL && !!totalCredits && (
          <Stack spacing={0.25}>
            <Typography fontSize={16} fontWeight="700">
              {t("boost.details.detailsTab.credits")} ({totalCredits})
            </Typography>
            <Typography whiteSpace="pre-line">
              •{" "}
              {[
                ...jobBoardsWithCredits.map((j) => j.jobBoard.name),
                ...sequencesWithCredits.map((s) => s.sequence.name),
                ...boostActionsWithCredits.map((b) => b.boostAction.name),
              ].join(",\n• ")}
            </Typography>
          </Stack>
        )}
        {boost.requestedBy && (
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Avatar src={boost.requestedBy.picture} sx={{ height: 22, width: 22 }} />
            <Typography lineHeight={1.25} whiteSpace="pre-line">
              {boost.requestedBy.firstname} {boost.requestedBy.lastname}
            </Typography>
          </Stack>
        )}
      </Stack>
      <CardBackgroundComponent img="/images/background_campaign.svg">
        <Stack spacing={0.25} px="20px" py="30px" color={Colors.white}>
          <Typography fontSize={14} fontWeight="700">
            {t("boost.cardError.rejectTitle")}
          </Typography>
          <Typography fontSize={14} fontWeight="300" lineHeight={1.25} whiteSpace="pre-line">
            {boost.refusal?.reason}
          </Typography>
          {boost.refusal?.user && (
            <Stack direction="row" alignItems="center" pt="7px" spacing={1.5}>
              <Avatar src={boost.refusal.user.picture} sx={{ height: 22, width: 22 }} />
              <Typography lineHeight={1.25} whiteSpace="pre-line">
                {boost.refusal.user.firstname} {boost.refusal.user.lastname}
              </Typography>
            </Stack>
          )}
        </Stack>
      </CardBackgroundComponent>
    </Stack>
  );
};

export default RejectedRequestCard;
