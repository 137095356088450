import {Components} from "@mui/material";

const timeline: Components = {
  MuiStepper: {
    styleOverrides: {
      root: {
        marginTop: 0,
        '& .MuiStepLabel-alternativeLabel': {
          marginTop: 0
        },
      }
    }
  }
}

export default timeline;
