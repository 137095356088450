import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Typography } from "@mui/material";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";

interface ConfirmModalProps {
  closeOnDemand?: boolean;
  confirmMsg: string;
  description?: string;
  handleClose: () => void;
  handleConfirm: () => void;
  hideCancel?: boolean;
  logo?: ReactNode;
  modalTitle: string;
}

const ConfirmModal = (props: ConfirmModalProps) => {
  const { closeOnDemand, confirmMsg, description, handleClose, handleConfirm , hideCancel, logo, modalTitle} = props;
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  return (
    <ModalComponent
      handleClose={handleClose}
      title={modalTitle}
      content={
        <Stack alignItems={"center"} py="15px" spacing={3}>
          {logo}
          <Typography textAlign="center" fontWeight={500} whiteSpace="pre-line">
            {confirmMsg}
          </Typography>
          {description && (
            <Typography textAlign="center" variant="body2" color="textSecondary">
              {description}
            </Typography>
          )}
        </Stack>
      }
      maxWidth="xs"
      paddingButton="10px 0px 40px 0px"
      actions={
        <>
          {!hideCancel && (
            <AIOButtonComponent title={t("global.cancel")} disabled={loading} ariaLabel="modal" onClick={handleClose} />
          )}
          <AIOButtonComponent
            title={t("global.confirm")}
            ariaLabel="modal"
            variant="contained"
            disabled={loading}
            onClick={() => {
              setLoading(true);
              handleConfirm();
              if (!closeOnDemand) handleClose();
            }}
          />
        </>
      }
    />
  );
};

export default ConfirmModal;
