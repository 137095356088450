import { Box, Container, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import { useObservable } from "@ngneat/react-rxjs";
import { useNavigate } from "react-router-dom";
import parse from "html-react-parser";

import { boostDetailsQuery } from "@store/boosts/details";
import { sessionQuery } from "@store/session";
import { BoostStatus, BoostType } from "@store/boosts";

import { AdRoutes, Pages } from "@utils/routes.utils";
import { Colors } from "@constants/colors.constant";

import AIOButtonComponent from "@components/Button.component";
import PendingRequestCard from "@screens/auth/common/boosts/components/PendingRequest.card";
import RejectedRequestCard from "@screens/auth/common/boosts/components/RejectedRequest.card";
import ManualRequestCard from "@screens/auth/common/boosts/components/ManualRequest.card";
import TwininRequestCard from "@screens/auth/common/boosts/components/TwininRequest.card";

const BoostsDetailsTab = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [boost] = useObservable(boostDetailsQuery.boostsDetails$);

  if (!boost) return null;

  const displayState = () => {
    if (boost.state === BoostStatus.WAITING_FOR_VALIDATION || boost.state === BoostStatus.IN_PROGRESS) {
      return <PendingRequestCard boost={boost} />;
    } else if (boost.state === BoostStatus.REJECTED) {
      return <RejectedRequestCard boost={boost} />;
    } else if (boost.type === BoostType.TWININ) {
      return <TwininRequestCard boost={boost} />;
    } else if (boost.type === BoostType.MANUAL) {
      return <ManualRequestCard boost={boost} />;
    }
    return null;
  };

  return (
    <Stack
      width="100%"
      alignItems="center"
      py={breakpointDownSM ? "20px" : "40px"}
      overflow="auto"
      className="scrollable">
      <Container maxWidth="md" sx={{ px: breakpointDownSM ? "4px" : undefined }}>
        <Stack spacing={4} pb="30px">
          {displayState()}
          {!!boost.announcement && (
            <Stack
              alignItems="center"
              bgcolor={Colors.background}
              borderRadius="15px"
              p="30px"
              direction={breakpointDownSM ? "column" : "row"}
              justifyContent="space-between">
              <Stack mb={breakpointDownSM ? "20px" : 0}>
                <Typography fontSize="14px" color={Colors.greyLight} fontWeight={300}>
                  {t(`global.reference`, { reference: boost.announcement.reference || "-" })}
                </Typography>
                <Typography fontSize="16px" fontWeight={700} lineHeight={1.25}>
                  {boost.announcement.name}
                </Typography>
                <Typography fontSize="14px" color={Colors.greyLight} fontWeight={300}>
                  {boost.affiliate?.name ?? "-"}
                </Typography>
                <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={4}>
                  <Stack spacing={1} alignItems="center" direction="row">
                    <img alt="location" src="/images/location.svg" />
                    <Typography color={Colors.greyLight} fontSize="14px" fontWeight={500} pt="1px">
                      {boost.announcement.location}
                    </Typography>
                  </Stack>
                  <Stack spacing={1} alignItems="center" direction="row">
                    <img alt="calendar" src="/images/calendar_grey.svg" />
                    <Typography color={Colors.greyLight} fontSize="14px" fontWeight={500} pt="1px">
                      {boost.announcement.publicationDate ? t("global.published", { date: new Date(boost.announcement.publicationDate) }) : "-"}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              {sessionQuery.buyerMode ? (
                <Typography
                  fontSize={14}
                  fontWeight="300"
                  color={"rgb(123, 123, 217)"}
                  alignSelf="flex-start">
                  {t(
                    "global.candidates",
                    { count: (boost.announcement.applications?.aioApplications ?? 0) + (boost.announcement.applications?.powerBiApplications ?? 0) }
                  )}
                </Typography>
              ) : (
                <Box sx={{ "& .MuiButton-root": { borderRadius: 15, height: 35 } }}>
                  <AIOButtonComponent
                    title={t("boost.details.detailsTab.consultAd")}
                    color="secondary"
                    onClick={() => navigate(`/${Pages.ADS}/${boost.announcement.id}/${AdRoutes.DETAIL}`)}
                    variant="contained" />
                </Box>
              )}
            </Stack>
          )}
          {!!boost.announcement?.description && (
            <Stack spacing={breakpointDownSM ? 1 : 2} fontSize={14} fontWeight={300} color={Colors.grey}>
              <Typography fontSize="20px" fontWeight={700}>
                {t("boost.details.detailsTab.description")}
              </Typography>
              {parse(boost.announcement.description)}
            </Stack>
          )}
        </Stack>
      </Container>
    </Stack>
  );
};

export default BoostsDetailsTab;
