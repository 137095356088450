import { createRequestDataSource } from "@ngneat/elf-requests";
import { select } from "@ngneat/elf";

import { adDetailsStore } from "@store/ads/details/adDetails.store";

export const getAdDetailsDetailsDataSource = createRequestDataSource({
  store: adDetailsStore,
  data$: () => adDetailsStore.pipe(select((state) => state.details)),
  requestKey: "getAdDetailsDetails",
  dataKey: "adDetails",
});

export const getAdDetailsStatsDataSource = createRequestDataSource({
  store: adDetailsStore,
  data$: () => adDetailsStore.pipe(select((state) => state.stats)),
  requestKey: "getAdDetailsStats",
  dataKey: "adStats",
});

export const getAdDetailsApplicationsDataSource = createRequestDataSource({
  store: adDetailsStore,
  data$: () => adDetailsStore.pipe(select((state) => state.applications)),
  requestKey: "getAdDetailsApplications",
  dataKey: "adApplications",
});

export const getAdDetailsSlogansDataSource = createRequestDataSource({
  store: adDetailsStore,
  data$: () => adDetailsStore.pipe(select((state) => state.slogans)),
  requestKey: "getAdDetailsSlogans",
  dataKey: "adSlogans",
});

export const getAdDetailsTagsDataSource = createRequestDataSource({
  store: adDetailsStore,
  data$: () => adDetailsStore.pipe(select((state) => state.tags)),
  requestKey: "getAdDetailsTags",
  dataKey: "adTags",
});

export const getAdDetailsPotentialProfilesDataSource = createRequestDataSource({
  store: adDetailsStore,
  data$: () => adDetailsStore.pipe(select((state) => state.potentialProfiles)),
  requestKey: "getAdDetailsPotentialProfiles",
  dataKey: "adsPotentialProfiles",
});

export const getAdDetailsCommentsDataSource = createRequestDataSource({
  store: adDetailsStore,
  data$: () => adDetailsStore.pipe(select((state) => state.comments)),
  requestKey: "getAdDetailsComments",
  dataKey: "adComments",
});

export const getAdActionsDataSource = createRequestDataSource({
  store: adDetailsStore,
  data$: () => adDetailsStore.pipe(select((state) => state.actions)),
  requestKey: "getAdActions",
  dataKey: "adActions",
});

