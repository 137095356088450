import { createStore } from "@ngneat/elf";
import { withEntities } from "@ngneat/elf-entities";
import { withRequestsStatus } from "@ngneat/elf-requests";
import { TwilioAiCall } from "@store/twilioAiCalls/twilioAiCalls.model";

export const twilioAiCallsStore = createStore(
  { name: "twilioAiCalls" },

  withEntities<TwilioAiCall>(),
  withRequestsStatus()
);
