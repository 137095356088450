import { object, string, ref } from "yup";

import I18n from "@utils/i18n.utils";
import * as YupUtils from "@utils/yup.utils";

export const forgotPasswordValidation = (email: string) => {
  const requiredString = string().required(I18n.t("errors.required"));

  try {
    const schema = object().shape({
      email: requiredString.email(I18n.t("errors.email")),
    });

    schema.validateSync({ email }, { abortEarly: false });
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
};
export const resetPasswordValidation = (password: string, confirmedPassword: string) => {
  try {
    const schema = object({
      password: string()
        .required(I18n.t("errors.required"))
        .min(8, I18n.t("errors.tooShort", { count: 8 }))
        .test("oneUppercase", I18n.t("errors.oneUppercase"), (value) => !!value && /[A-Z]/.test(value))
        .test("oneLowercase", I18n.t("errors.oneLowercase"), (value) => !!value && /[a-z]/.test(value)),
      confirmedPassword: string().oneOf([ref("password")], I18n.t("errors.passwordMatch")),
    });

    schema.validateSync({ password, confirmedPassword }, { abortEarly: false });
    return {};
  } catch (err) {
    return YupUtils.transformError(err as any);
  }
};
