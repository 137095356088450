import React, { ReactNode, useState } from "react";
import { Button, Typography } from "@mui/material";

interface AIOButtonProps {
  title: string;
  onClick?: (e: React.MouseEvent) => void;
  variant?: "text" | "outlined" | "contained" | undefined;
  icon?: ReactNode;
  endIcon?: ReactNode;
  endIconFullRight?: boolean;
  overIcon?: ReactNode;
  overEndIcon?: ReactNode;
  isTitle?: boolean;
  disabled?: boolean;
  color?: "primary" | "secondary";
  fontSize?: string;
  fontWeight?: string;
  isSubmit?: boolean;
  ariaLabel?: string;
  fullWidth?: boolean;
}

const AIOButtonComponent = (props: AIOButtonProps) => {
  const {
    title,
    onClick,
    variant,
    icon,
    endIcon,
    endIconFullRight,
    overIcon,
    overEndIcon,
    fontSize,
    fontWeight,
    color,
    isTitle,
    disabled,
    ariaLabel,
    isSubmit,
    fullWidth,
  } = props;
  const [isOver, setIsOver] = useState(false);

  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={color ?? "secondary"}
      startIcon={isOver && overIcon && variant !== "contained" ? overIcon : icon}
      endIcon={isOver && overEndIcon && variant !== "contained" ? overEndIcon : endIcon}
      onMouseOver={() => setIsOver(true)}
      onMouseLeave={() => setIsOver(false)}
      aria-label={ariaLabel}
      style={{ width: isTitle ? "150px" : undefined, justifyContent: isTitle ? 'left' : "center" }}
      disabled={disabled}
      fullWidth={fullWidth}
      type={isSubmit ? "submit" : undefined}>
      <Typography
        fontSize={fontSize}
        fontWeight={fontWeight}
        lineHeight={1.25}
        textAlign={endIconFullRight ? "left" : undefined}
        flex={endIconFullRight ? 1 : undefined}>
        {title}
      </Typography>
    </Button>
  );
};

export default AIOButtonComponent;
