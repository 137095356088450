import { UserLightModel } from "@store/users";
import { Colors } from "@constants/colors.constant";
import { alpha } from "@mui/material";
import I18n from "@utils/i18n.utils";

export enum SubscriptionFeatureEnum {
  JOB_OFFER_MANAGEMENT = "JOB_OFFER_MANAGEMENT",
  AIO_APPLICATION_RECEPTION = "AIO_APPLICATION_RECEPTION",
  MARKETING_BOOST = "MARKETING_BOOST",
  AIO_PARTNER_PAGE = "AIO_PARTNER_PAGE",
  POTENTIAL_PROFILE_FINDER = "POTENTIAL_PROFILE_FINDER",
  AI_LINKEDIN_POSTING = "AI_LINKEDIN_POSTING",
  AI_LINKEDIN_POSTING_UNLIMITED = "AI_LINKEDIN_POSTING_UNLIMITED",
  MANUAL_CANDIDATE_COMMUNICATION = "MANUAL_CANDIDATE_COMMUNICATION",
  CANDIDATE_DATABASE = "CANDIDATE_DATABASE",
  AI_CAREER_SITE_CREATION = "AI_CAREER_SITE_CREATION",
  CV_PARSING_UNLIMITED = "CV_PARSING_UNLIMITED",
  COLLABORATOR_AUDIT = "COLLABORATOR_AUDIT",
  AB_TESTING = "AB_TESTING",
  CREATION = "CREATION",
  DECLINATION = "DECLINATION",
  BRAND_BOOK = "BRAND_BOOK",
  GPT_SORT = "GPT_SORT",
}

export enum SubscriptionDetailedFeatureEnum {
  LIVE_CHAT_5_DAYS = "LIVE_CHAT_5_DAYS",
  JOB_OFFER_DATA = "JOB_OFFER_DATA",
  AIO_APPLICATION_RECEPTION = "AIO_APPLICATION_RECEPTION",
  FREE_JOBBOARD_POSTING = "FREE_JOBBOARD_POSTING",
  MARKETING_BOOST = "MARKETING_BOOST",
  CANDIDATE_MANAGEMENT = "CANDIDATE_MANAGEMENT",
  ATS_REDIRECTION = "ATS_REDIRECTION",
  AI_CAREER_SITE_CREATION = "AI_CAREER_SITE_CREATION",
  UNLIMITED_CV_PARSING = "UNLIMITED_CV_PARSING",
  AUTOMATED_SOURCING_SCENARIO = "AUTOMATED_SOURCING_SCENARIO",
  APPLICATION_RECEIPT_REDIRECTION = "APPLICATION_RECEIPT_REDIRECTION",
  CANDIDATE_COMMUNICATION = "CANDIDATE_COMMUNICATION",
  POTENTIAL_PROFILE_FINDER = "POTENTIAL_PROFILE_FINDER",
  AIO_LINKEDIN_POSTING = "AIO_LINKEDIN_POSTING",
  CANDIDATE_DATABASE = "CANDIDATE_DATABASE",
  GPT_SORT = "GPT_SORT",
}

export enum SubscriptionTypeEnum {
  FREEMIUM = "FREEMIUM",
  PROFESSIONAL = "PROFESSIONAL",
  COMPANY = "COMPANY",
  EMPLOYER_BRAND = "EMPLOYER_BRAND",
}

export namespace SubscriptionTypeEnumFunctions {
  export const gradientColors = {
    [SubscriptionTypeEnum.FREEMIUM]: `
      linear-gradient(${alpha(Colors.subscriptions.freemium, 0.22)} 0%, ${alpha("#FFFFFF", 0)} 35%)
    `,
    [SubscriptionTypeEnum.PROFESSIONAL]: `
      linear-gradient(${alpha(Colors.subscriptions.professional, 0.22)} 0%, ${alpha("#FFFFFF", 0)} 35%)
    `,
    [SubscriptionTypeEnum.COMPANY]: `
      linear-gradient(${alpha(Colors.subscriptions.company, 0.22)} 0%, ${alpha("#FFFFFF", 0)} 35%)
    `,
    [SubscriptionTypeEnum.EMPLOYER_BRAND]: `
      linear-gradient(${alpha(Colors.subscriptions.employerBrand, 0.6)} 0%, ${alpha("#242442", 0)} 35%)
    `,
  };

  export const backgroundColors: { [key: string]: "light" | "dark" } = {
    [SubscriptionTypeEnum.FREEMIUM]: "light",
    [SubscriptionTypeEnum.PROFESSIONAL]: "light",
    [SubscriptionTypeEnum.COMPANY]: "light",
    [SubscriptionTypeEnum.EMPLOYER_BRAND]: "dark",
  };

  export const colors = {
    [SubscriptionTypeEnum.FREEMIUM]: Colors.subscriptions.freemium,
    [SubscriptionTypeEnum.PROFESSIONAL]: Colors.subscriptions.professional,
    [SubscriptionTypeEnum.COMPANY]: Colors.subscriptions.company,
    [SubscriptionTypeEnum.EMPLOYER_BRAND]: undefined,
  };
}

export interface ManageSubscriptionParameters {
  subscription: Subscription;
  amount?: number;
  unlimitedOffer?: boolean;
}

export interface UserSubscription {
  startDate: Date;
  endDate: Date;
  currentPeriodEnd: Date;
  isYearly: boolean;
  buyer: UserLightModel;
  users: UserLightModel[];
}

export enum FeatureCreditsTypeEnum {
  POTENTIAL_PROFILES = "POTENTIAL_PROFILES",
  LINKEDIN_POST = "LINKEDIN_POST",
  APPLICATIONS = "APPLICATIONS",
}

export interface FeatureCredits {
  potentialProfileSearch: number;
  linkedinPosting: number;
  candidateDatabase: number;
}

export interface SubscriptionFeatureDetail {
  type: SubscriptionDetailedFeatureEnum;
  included: boolean;
  text?: string;
}

export interface SubscriptionFeature {
  type: SubscriptionFeatureEnum;
  isBold: boolean;
}

export interface Subscription {
  id: string;
  type: SubscriptionTypeEnum;
  name: string;
  subtitle: string;
  features?: SubscriptionFeature[];
  detailedFeatures?: SubscriptionFeatureDetail[];
  icon: JSX.Element;
  price: number;
}

export const subscriptions: Subscription[] = [
  {
    id: "1",
    type: SubscriptionTypeEnum.FREEMIUM,
    name: I18n.t("subscriptions.details.freemium.name"),
    subtitle: I18n.t("subscriptions.details.freemium.subtitle"),
    price: 0,
    icon: <img height="17px" src="/images/subscription/icon_subscription_freemium.svg" alt="freemium" />,
    features: [
      { type: SubscriptionFeatureEnum.JOB_OFFER_MANAGEMENT, isBold: false },
      { type: SubscriptionFeatureEnum.AIO_APPLICATION_RECEPTION, isBold: false },
      { type: SubscriptionFeatureEnum.MARKETING_BOOST, isBold: false },
      { type: SubscriptionFeatureEnum.AIO_PARTNER_PAGE, isBold: false },
      { type: SubscriptionFeatureEnum.POTENTIAL_PROFILE_FINDER, isBold: false },
      { type: SubscriptionFeatureEnum.AI_LINKEDIN_POSTING, isBold: false },
      { type: SubscriptionFeatureEnum.MANUAL_CANDIDATE_COMMUNICATION, isBold: false },
      { type: SubscriptionFeatureEnum.CANDIDATE_DATABASE, isBold: false },
    ],
    detailedFeatures: [
      { type: SubscriptionDetailedFeatureEnum.LIVE_CHAT_5_DAYS, included: true },
      { type: SubscriptionDetailedFeatureEnum.JOB_OFFER_DATA, included: true },
      { type: SubscriptionDetailedFeatureEnum.AIO_APPLICATION_RECEPTION, included: true },
      { type: SubscriptionDetailedFeatureEnum.FREE_JOBBOARD_POSTING, included: true },
      { type: SubscriptionDetailedFeatureEnum.MARKETING_BOOST, included: true },
      { type: SubscriptionDetailedFeatureEnum.CANDIDATE_MANAGEMENT, included: true },
      { type: SubscriptionDetailedFeatureEnum.ATS_REDIRECTION, included: false },
      { type: SubscriptionDetailedFeatureEnum.AI_CAREER_SITE_CREATION, included: false },
      { type: SubscriptionDetailedFeatureEnum.UNLIMITED_CV_PARSING, included: false },
      { type: SubscriptionDetailedFeatureEnum.GPT_SORT, included: false },
      { type: SubscriptionDetailedFeatureEnum.AUTOMATED_SOURCING_SCENARIO, included: false },
      { type: SubscriptionDetailedFeatureEnum.APPLICATION_RECEIPT_REDIRECTION, included: true, text: I18n.t("subscriptions.details.freemium.APPLICATION_RECEIPT_REDIRECTION") },
      { type: SubscriptionDetailedFeatureEnum.CANDIDATE_COMMUNICATION, included: true, text: I18n.t("subscriptions.details.freemium.CANDIDATE_COMMUNICATION") },
      { type: SubscriptionDetailedFeatureEnum.POTENTIAL_PROFILE_FINDER, included: true, text: I18n.t("subscriptions.details.freemium.POTENTIAL_PROFILE_FINDER") },
      { type: SubscriptionDetailedFeatureEnum.AIO_LINKEDIN_POSTING, included: true, text: I18n.t("subscriptions.details.freemium.AIO_LINKEDIN_POSTING") },
      { type: SubscriptionDetailedFeatureEnum.CANDIDATE_DATABASE, included: true, text: I18n.t("subscriptions.details.freemium.CANDIDATE_DATABASE") },
    ],
  },
  {
    id: "2",
    type: SubscriptionTypeEnum.PROFESSIONAL,
    name: I18n.t("subscriptions.details.professional.name"),
    subtitle: I18n.t("subscriptions.details.professional.subtitle"),
    price: 79,
    icon: <img height="17px" src="/images/subscription/icon_subscription_professional.svg" alt="professional" />,
    features: [
      { type: SubscriptionFeatureEnum.JOB_OFFER_MANAGEMENT, isBold: false },
      { type: SubscriptionFeatureEnum.AIO_APPLICATION_RECEPTION, isBold: false },
      { type: SubscriptionFeatureEnum.MARKETING_BOOST, isBold: false },
      { type: SubscriptionFeatureEnum.AIO_PARTNER_PAGE, isBold: false },
      { type: SubscriptionFeatureEnum.POTENTIAL_PROFILE_FINDER, isBold: false },
      { type: SubscriptionFeatureEnum.AI_LINKEDIN_POSTING_UNLIMITED, isBold: false },
      { type: SubscriptionFeatureEnum.MANUAL_CANDIDATE_COMMUNICATION, isBold: false },
      { type: SubscriptionFeatureEnum.CANDIDATE_DATABASE, isBold: false },
      { type: SubscriptionFeatureEnum.AI_CAREER_SITE_CREATION, isBold: true },
      { type: SubscriptionFeatureEnum.CV_PARSING_UNLIMITED, isBold: true },
      { type: SubscriptionFeatureEnum.GPT_SORT, isBold: true },
    ],
    detailedFeatures: [
      { type: SubscriptionDetailedFeatureEnum.LIVE_CHAT_5_DAYS, included: true },
      { type: SubscriptionDetailedFeatureEnum.JOB_OFFER_DATA, included: true },
      { type: SubscriptionDetailedFeatureEnum.AIO_APPLICATION_RECEPTION, included: true },
      { type: SubscriptionDetailedFeatureEnum.FREE_JOBBOARD_POSTING, included: true },
      { type: SubscriptionDetailedFeatureEnum.MARKETING_BOOST, included: true },
      { type: SubscriptionDetailedFeatureEnum.CANDIDATE_MANAGEMENT, included: true },
      { type: SubscriptionDetailedFeatureEnum.ATS_REDIRECTION, included: true },
      { type: SubscriptionDetailedFeatureEnum.AI_CAREER_SITE_CREATION, included: true },
      { type: SubscriptionDetailedFeatureEnum.UNLIMITED_CV_PARSING, included: true },
      { type: SubscriptionDetailedFeatureEnum.GPT_SORT, included: true },
      { type: SubscriptionDetailedFeatureEnum.AUTOMATED_SOURCING_SCENARIO, included: false },
      { type: SubscriptionDetailedFeatureEnum.APPLICATION_RECEIPT_REDIRECTION, included: true, text: I18n.t("subscriptions.details.professional.APPLICATION_RECEIPT_REDIRECTION") },
      { type: SubscriptionDetailedFeatureEnum.CANDIDATE_COMMUNICATION, included: true, text: I18n.t("subscriptions.details.professional.CANDIDATE_COMMUNICATION") },
      { type: SubscriptionDetailedFeatureEnum.POTENTIAL_PROFILE_FINDER, included: true, text: I18n.t("subscriptions.details.professional.POTENTIAL_PROFILE_FINDER")},
      { type: SubscriptionDetailedFeatureEnum.AIO_LINKEDIN_POSTING, included: true, text: I18n.t("subscriptions.details.professional.AIO_LINKEDIN_POSTING")},
      { type: SubscriptionDetailedFeatureEnum.CANDIDATE_DATABASE, included: true, text: I18n.t("subscriptions.details.professional.CANDIDATE_DATABASE")},
    ],
  },
  {
    id: "3",
    type: SubscriptionTypeEnum.COMPANY,
    name: I18n.t("subscriptions.details.company.name"),
    subtitle: I18n.t("subscriptions.details.company.subtitle"),
    price: 99,
    icon: <img width="18px" src="/images/subscription/icon_subscription_company.svg" alt="company" />,
    detailedFeatures: [
      { type: SubscriptionDetailedFeatureEnum.LIVE_CHAT_5_DAYS, included: true },
      { type: SubscriptionDetailedFeatureEnum.JOB_OFFER_DATA, included: true },
      { type: SubscriptionDetailedFeatureEnum.AIO_APPLICATION_RECEPTION, included: true },
      { type: SubscriptionDetailedFeatureEnum.FREE_JOBBOARD_POSTING, included: true },
      { type: SubscriptionDetailedFeatureEnum.MARKETING_BOOST, included: true },
      { type: SubscriptionDetailedFeatureEnum.CANDIDATE_MANAGEMENT, included: true },
      { type: SubscriptionDetailedFeatureEnum.ATS_REDIRECTION, included: true },
      { type: SubscriptionDetailedFeatureEnum.AI_CAREER_SITE_CREATION, included: true },
      { type: SubscriptionDetailedFeatureEnum.UNLIMITED_CV_PARSING, included: true },
      { type: SubscriptionDetailedFeatureEnum.GPT_SORT, included: true },
      { type: SubscriptionDetailedFeatureEnum.AUTOMATED_SOURCING_SCENARIO, included: true },
      { type: SubscriptionDetailedFeatureEnum.APPLICATION_RECEIPT_REDIRECTION, included: true, text: I18n.t("subscriptions.details.company.APPLICATION_RECEIPT_REDIRECTION") },
      { type: SubscriptionDetailedFeatureEnum.CANDIDATE_COMMUNICATION, included: true, text: I18n.t("subscriptions.details.company.CANDIDATE_COMMUNICATION") },
      { type: SubscriptionDetailedFeatureEnum.POTENTIAL_PROFILE_FINDER, included: true, text: I18n.t("subscriptions.details.company.POTENTIAL_PROFILE_FINDER") },
      { type: SubscriptionDetailedFeatureEnum.AIO_LINKEDIN_POSTING, included: true, text: I18n.t("subscriptions.details.company.AIO_LINKEDIN_POSTING") },
      { type: SubscriptionDetailedFeatureEnum.CANDIDATE_DATABASE, included: true, text: I18n.t("subscriptions.details.company.CANDIDATE_DATABASE") },
    ],
  },
  {
    id: "4",
    type: SubscriptionTypeEnum.EMPLOYER_BRAND,
    name: I18n.t("subscriptions.details.employerBrand.name"),
    subtitle: I18n.t("subscriptions.details.employerBrand.subtitle"),
    price: 9990,
    icon: <img width="17px" src="/images/subscription/icon_subscription_employer_brand.svg" alt="employer brand" />,
    features: [
      { type: SubscriptionFeatureEnum.COLLABORATOR_AUDIT, isBold: false },
      { type: SubscriptionFeatureEnum.AB_TESTING, isBold: false },
      { type: SubscriptionFeatureEnum.CREATION, isBold: false },
      { type: SubscriptionFeatureEnum.DECLINATION, isBold: false },
      { type: SubscriptionFeatureEnum.BRAND_BOOK, isBold: false },
    ],
  },
];
