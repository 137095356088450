import React, { useEffect, useState } from "react";
import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { subscriptions } from "@store/subscriptions/subscriptions.model";
import { stripeService } from "@store/stripe/stripe.service";

import { Colors } from "@constants/colors.constant";
import SubscriptionColumnComponent from "@components/subscriptions/SubscriptionColumn.component";
import SubscriptionPeriodSwitch from "./SubscriptionPeriod.switch";
import ShowMoreModal from "@components/subscriptions/modal/ShowMore.modal";
import ModalComponent from "@components/modal/Modal.component";
import { sessionService } from "@store/session";

const SubscriptionPricingComponent = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [showMoreModal, setShowMoreModal] = useState(false);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);

  const [isYearly, setIsYearly] = useState(false);

  useEffect(() => {
    const paymentClientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    const setupClientSecret = new URLSearchParams(window.location.search).get("setup_intent_client_secret");

    if (!paymentClientSecret && !setupClientSecret) return;

    window.history.replaceState({}, "", window.location.pathname); // remove query params from url

    if (paymentClientSecret) {
      stripeService.checkPaymentSucceeded(paymentClientSecret).subscribe({
        next: () => setShowPaymentSuccessModal(true),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    } else if (setupClientSecret) {
      stripeService.checkSetupSucceeded(setupClientSecret).subscribe({
        next: () => setShowPaymentSuccessModal(true),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [enqueueSnackbar]);

  return (
    <Stack overflow="auto" flex={1} className="scrollable" pb="30px">
      <Stack alignItems="center" px="30px">
        <SubscriptionPeriodSwitch checked={isYearly} onChange={setIsYearly} />
        <Stack
          direction={breakpointDownSM ? "column" : "row"}
          alignItems="flex-start"
          justifyContent={breakpointDownSM ? "center" : breakpointDownMD ? "space-around" : "center"}
          position="relative"
          mt="30px"
          flexWrap="wrap">
          {subscriptions.map((subscription) => (
            <SubscriptionColumnComponent
              key={subscription.id}
              isYearly={isYearly}
              subscription={subscription}
              handleShowModal={() => setShowMoreModal(true)}
            />
          ))}
        </Stack>
      </Stack>
      {showMoreModal && <ShowMoreModal subscriptions={subscriptions} handleClose={() => setShowMoreModal(false)} />}
      {showPaymentSuccessModal && (
        <ModalComponent
          handleClose={() => {
            setShowPaymentSuccessModal(false);
            sessionService.fetchCurrentUser().subscribe({});
          }}
          icon={<img alt="check" src="/images/illus_check.svg" />}
          title={t("stripe.paymentSuccess.title")}
          content={
            <Stack px="50px" py="20px">
              <Typography textAlign="center" pb="25px" lineHeight="1.25" color={Colors.secondaryText} fontWeight={300}>
                {t("stripe.paymentSuccess.description.subscription")}
              </Typography>
            </Stack>
          }
          maxWidth="xs"
          paddingButton="10px 0px 40px 0px"
        />
      )}
    </Stack>
  );
};

export default SubscriptionPricingComponent;
