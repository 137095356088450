import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { Locales, LocaleTranslations } from "@constants/locales.constants";
import dayjs from "@utils/dayjs.utils";
import { getAppCountry } from "@store/common/country.model";

const formatTranslation = (value: any, format?: string, locale?: string) => {
  if (value instanceof Date && locale && format) {
    return dayjs(value).locale(locale).format(format);
  }

  if (typeof value === "string" && format === "uppercase") {
    return value.toUpperCase();
  }

  if (typeof value === "string" && format === "lowercase") {
    return value.toLowerCase();
  }

  return value;
};

i18n
  .use(initReactI18next)
  .init({
    resources: LocaleTranslations,
    fallbackLng: Locales.FR,
    lng: getAppCountry(),
    interpolation: {
      escapeValue: false,
      format: formatTranslation,
    },
  })
  .then(() => undefined);

export default i18n;
