import { CardActionArea, Skeleton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import React from "react";
import { useTranslation } from "react-i18next";

interface StatCardProps {
  bgColor: string;
  extra?: string;
  formatValue?: (value?: number) => string | undefined;
  helper?: JSX.Element;
  label: string;
  loading?: boolean;
  progress?: number;
  progressReverted?: boolean;
  value?: number;
  handleClick?: () => void;
}

const StatCardComponent = (props: StatCardProps) => {
  const { bgColor, extra, formatValue, helper, label, loading, progress, progressReverted, value, handleClick } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Stack component={!handleClick ? Stack : CardActionArea} bgcolor={bgColor} flex={1} height="100%" borderRadius="15px" p="20px 20px 31px 31px" spacing={-0.75} onClick={handleClick}>
      <Tooltip sx={{ opacity: helper ? 1 : 0 }} title={helper} arrow placement="top">
        <img alt="information" src="/images/icon_informations.svg" style={{ alignSelf: "flex-end", opacity: helper ? 1 : 0 }} />
      </Tooltip>
      <Stack direction="row" alignItems="center" spacing={2} justifyContent="flex-start" width="100%">
        {loading ? (
          <Skeleton variant="text" height={breakpointDownMD ? "45px" : "60px"} width="60%" />
        ) : (
          <Typography fontSize={breakpointDownMD ? "25px" : "30px"} fontWeight={500}>
            {formatValue?.(value) ?? value ?? t("ads.stats.notCommunicated")}
            {extra}
          </Typography>
        )}
        {!loading && !!progress && (
          <Stack direction="row" alignItems="center" spacing={1} pt="4px">
            {progress > 0 && (
              <img
                alt="increase"
                src={`/images/arrow_increase_${progressReverted ? 'red' : 'green'}.svg`}
                style={{ height: 15, width: 20 }} />
            )}
            {progress < 0 && (
              <img
                alt="decrease"
                src={`/images/arrow_decrease_${progressReverted ? 'green' : 'red'}.svg`}
                style={{ height: 15, width: 20 }} />
            )}
            <Typography
              fontSize={13}
              fontWeight="500"
              color={progress > 0
                ? (progressReverted ? Colors.progress.down : Colors.progress.up)
                : (progressReverted ? Colors.progress.up : Colors.progress.down)
              }>
              {`${progress}%`}
            </Typography>
          </Stack>
        )}
      </Stack>
      <Typography fontSize="13px" fontWeight="500" color={Colors.secondaryText} lineHeight={1.25}>
        {label}
      </Typography>
    </Stack>
  );
};

export default StatCardComponent;
