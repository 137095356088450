import { convertFromRaw, EditorState } from "draft-js";

import { AffiliateLightModel } from "@store/entities/afiliates";

import { SelectItem } from "@components/input/Select.component";
import { Colors } from "@constants/colors.constant";

export const BannerFontColorPalette = [
  Colors.black,
  Colors.white,
  Colors.greyCardBg,
  Colors.primary,
  Colors.green,
  Colors.subscriptions.freemium,
  Colors.gold,
  Colors.orange,
  Colors.chart.pinkDataSet,
  Colors.marine,
];

export const BannerBackgroundColorPalette = BannerFontColorPalette.filter((_, index) => index !== 1);

export interface BannersFilters {
  search?: string;
  affiliates?: SelectItem[];
}

export interface ManageBanner extends Partial<Omit<BannerModel, "affiliates">> {
  bannerColor: string;
  editorContent: EditorState;
  affiliates?: SelectItem[];
}

export const bannerToManageBanner = (banner: BannerModel): ManageBanner => ({
  ...banner,
  bannerColor: banner.bannerColor ?? BannerBackgroundColorPalette[2],
  editorContent: banner.content ? EditorState.createWithContent(convertFromRaw(JSON.parse(banner.content))) : EditorState.createEmpty(),
  affiliates: banner.affiliates?.map((a) => ({ label: a.name, value: a.id })) ?? [],
});

export interface BannerModel {
  id: string;
  title: string;
  affiliates: AffiliateLightModel[];
  content: string;
  buttonLabel: string;
  buttonURL: string;
  bannerColor: string;
}
