import { Components } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { Sizes } from "@constants/sizes.constant";

const drawer: Components = {
  MuiDrawer: {
    styleOverrides: {
      paperAnchorDockedLeft: {
        borderRight: "none",
        backgroundColor: Colors.background,
        width: `${Sizes.leftBar}px`,
      },
      paperAnchorLeft: {
        borderRight: "none",
        backgroundColor: Colors.background,
        width: `${Sizes.leftBar}px`,
      },
      paperAnchorRight: {
        padding: "50px 10px 0px 30px",
        width: 470,
      },
    },
  },
};

export default drawer;
