import { useEffectFn } from "@ngneat/effects-hooks";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { DataGrid, GridColDef, GridFooterContainer } from "@mui/x-data-grid";
import { Box, Button, IconButton, Pagination, PaginationItem, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { emitOnce } from "@ngneat/elf";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";

import { BoostStatus } from "@store/boosts";
import { adsQuery, adsService, AdsSortField, searchAdsEffect } from "@store/ads";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";
import { reportsQuery } from "@store/reports";
import { filesService } from "@store/files";

import { AdRoutes } from "@utils/routes.utils";
import { Colors } from "@constants/colors.constant";
import dayjsUtils from "@utils/dayjs.utils";

import AIOSearchComponent from "@components/input/AIOSearch.component";
import AIOSelectComponent from "@components/input/Select.component";
import { getLocalizedDateInputFormat } from "@store/common/country.model";

const StyledDataGrid = styled(DataGrid)({
  border: "none !important",
  "& .MuiDataGrid-virtualScroller": {
    border: "1px solid #EFEFEF",
    borderRadius: "15px",
    overflowX: "hidden",
  },
  "& .MuiDataGrid-columnHeaders": {
    border: "none !important",
  },
  "& .MuiDataGrid-withBorder": {
    borderRight: "none !important",
  },
  "& .MuiDataGrid-columnSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-row": {
    minHeight: "61px !important",
  },
  "& .MuiDataGrid-footerContainer": {
    border: "none",
    justifyContent: "flex-end",
  },
  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
    outline: "none !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, & .MuiDataGrid-columnHeader:focus": {
    outline: "none !important",
  },
  "& .MuiPaginationItem-root:not([disabled]) svg": {
    fill: Colors.primary,
  },
  "& .MuiDataGrid-cell > div": {
    maxWidth: "100%",
  },
});

const HeaderTypography = styled(Typography)({
  fontSize: "12px",
  color: Colors.lightGrey,
  fontWeight: 300,
});

const AdsReportsTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const searchAds = useEffectFn(searchAdsEffect);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const { entityId } = useParams();

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [reportsFilters] = useObservable(reportsQuery.filters$);
  const [filters] = useObservable(adsQuery.filters$);

  const [sort] = useObservable(adsQuery.sort$);

  const [{ ads, error: adsError, loading: adsLoading }] = useObservable(adsQuery.paginatedAds$);

  const [adsPaginationData] = useObservable(adsQuery.adsPaginationData$);

  useEffect(() => {
    emitOnce(() => {
      adsService.resetStore();

      const affiliateIds =
        sessionQuery.role === UserRoleEnum.ADMIN
          ? entityId
            ? [{ value: entityId, label: "" }]
            : []
          : clientViewClient
          ? [{ value: clientViewClient.id, label: "" }]
          : undefined;

      if (affiliateIds) {
        adsService.setFilters({ affiliateIds });
      }
    });
  }, [clientViewClient, entityId]);

  useEffect(() => {
    if (adsError) enqueueSnackbar((adsError as any).text, (adsError as any).options);
  }, [adsError, enqueueSnackbar]);

  useEffect(() => {
    searchAds({ filters, sort, page: currentPage, take: itemsPerPage, withStatsAndBoostInfo: true });
  }, [currentPage, filters, itemsPerPage, reportsFilters, searchAds, sort]);

  useEffect(() => {
    adsService.deleteEntities();
    adsService.deleteAllPages();
    setCurrentPage(1);
  }, [filters, reportsFilters.affiliateIds, sort]);

  const handleExportCSV = () => {
    adsService.getAdsReportsCSV(filters).subscribe({
      next: (res) => {
        filesService.downloadDocument(res, `announcements_report_${dayjsUtils().format("DD-MM-YYYY")}.csv`);
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  const CustomFooter = () => {
    return (
      <GridFooterContainer>
        <Pagination
          color="primary"
          count={adsPaginationData.lastPage}
          page={currentPage}
          onChange={(event, newPage) => {
            if (adsPaginationData.lastPage >= newPage) setCurrentPage(newPage);
          }}
          renderItem={(item) => {
            if (item.type === "page" && item.selected)
              return (
                <Stack direction="row" alignItems="row" justifyContent="center">
                  <Typography color="primary" fontWeight={500}>
                    {item.page}
                  </Typography>
                  <Typography fontWeight={300}>/{adsPaginationData.lastPage}</Typography>
                </Stack>
              );
            if (item.type !== "page" && item.type !== "end-ellipsis" && item.type !== "start-ellipsis") return <PaginationItem {...item} />;
          }}
        />
      </GridFooterContainer>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "offer",
      renderHeader: () => <HeaderTypography>{t("reports.offer")}</HeaderTypography>,
      flex: 1,
      minWidth: 250,
      renderCell(params) {
        return (
          <Stack my="12px" ml="12px">
            <Typography fontSize="14px" fontWeight={500} overflow="hidden" textOverflow="ellipsis" whiteSpace="pre-wrap" lineHeight={1.25}>
              {params.row.offer.name}
            </Typography>
            <Stack direction="row" spacing={1} alignItems="center">
              <img alt="location" src="/images/location.svg" />
              <Typography fontSize="14px" color={Colors.lightGrey} fontWeight={300}>
                {params.row.offer.location}
              </Typography>
            </Stack>
          </Stack>
        );
      },
    },
    {
      field: "ref",
      renderHeader: () => <HeaderTypography>{t("reports.ref")}</HeaderTypography>,
      width: 110,
      renderCell(params) {
        return (
          <Typography fontSize="14px" fontWeight={300}>
            {params.row.ref}
          </Typography>
        );
      },
    },
    {
      field: "publicationDate",
      renderHeader: () => <HeaderTypography>{t("reports.publicationDate")}</HeaderTypography>,
      width: 130,
      renderCell(params) {
        return (
          <Typography fontSize="14px" fontWeight={300}>
            {dayjsUtils(params.row.offer.publicationDate).format(getLocalizedDateInputFormat())}
          </Typography>
        );
      },
    },
    {
      field: "applicationNb",
      renderHeader: () => <HeaderTypography>{t("reports.applicationNb")}</HeaderTypography>,
      width: 130,
      renderCell(params) {
        return (
          <Typography fontSize="14px" fontWeight={300}>
            {params.row.applicationNb}
          </Typography>
        );
      },
    },
    {
      field: "quality",
      renderHeader: () => <HeaderTypography>{t("reports.quality")}</HeaderTypography>,
      width: 110,
      renderCell(params) {
        return (
          <Typography fontSize="14px" fontWeight={300}>
            {params.row.quality}
          </Typography>
        );
      },
    },
    {
      field: "engagementRate",
      renderHeader: () => <HeaderTypography>{t("reports.engagementRate")}</HeaderTypography>,
      width: 120,
      renderCell(params) {
        return (
          <Typography fontSize="14px" fontWeight={300}>
            {params.row.engagementRate}
          </Typography>
        );
      },
    },
    {
      field: "viewsNb",
      renderHeader: () => <HeaderTypography>{t("reports.viewsNb")}</HeaderTypography>,
      width: 110,
      renderCell(params) {
        return (
          <Typography fontSize="14px" fontWeight={300}>
            {params.row.nbViews}
          </Typography>
        );
      },
    },
    {
      field: "boostStatus",
      align: "center",
      renderHeader: () => <HeaderTypography>{t("reports.boostedAd")}</HeaderTypography>,
      width: 120,
      renderCell(params) {
        return params.row.boostStatus ? (
          <Stack
            height="26px"
            width="26px"
            bgcolor={Colors.lighterPurple}
            borderRadius="8px"
            border="1px solid"
            borderColor={BoostStatus.labelColor(params.row.boostStatus)}
            alignItems="center"
            justifyContent="center">
            <img alt="boost" src="/images/icon_boost_rocket.svg" style={{ height: "14px", width: "14px" }} />
          </Stack>
        ) : (
          <></>
        );
      },
    },
    {
      field: "button",
      headerName: "",
      width: 70,
      renderCell(params) {
        return (
          <IconButton onClick={() => navigate(`/ads/${params.row.adId}/${AdRoutes.DETAIL}`)}>
            <VisibilityOutlinedIcon color="primary" />
          </IconButton>
        );
      },
    },
  ];

  const rows = ads.map((ad, i) => ({
    id: i,
    offer: { name: ad.name, location: ad.location, publicationDate: ad.publicationDate },
    ref: ad.reference,
    applicationNb: (ad.applications?.aioApplications || 0) + (ad.applications?.powerBiApplications || 0),
    quality: ad.reportStats?.quality !== undefined ? `${Math.round(ad.reportStats.quality * 100)}%` : "NC",
    engagementRate: ad.reportStats?.engagementRate !== undefined ? `${Math.round(ad.reportStats.engagementRate * 10000) / 100}%` : "NC",
    nbViews: ad.reportStats?.nbViews ?? 0,
    boostStatus: ad.boostStatus,
    adId: ad.id,
  }));

  return (
    <Stack overflow="auto" height="100%" width="100%">
      <Stack direction="row" alignItems="center" justifyContent="flex-end" width="100%">
        <Stack
          direction={breakpointDownMD ? "column" : "row"}
          width="100%"
          alignItems="center"
          spacing={breakpointDownMD ? 0 : 2}
          justifyContent={breakpointDownMD ? "flex-start" : "flex-end"}>
          <Stack spacing={1.5} width="100%" alignItems="flex-end">
            <AIOSearchComponent
              placeholder={t("ads.searchPlaceholder")}
              fullWidth
              width="100%"
              onChange={(value) => adsService.setFilters({ search: value })}
            />
          </Stack>
          <Stack direction="row" flexWrap={breakpointDownMD ? "wrap" : undefined} justifyContent={breakpointDownMD ? "center" : "flex-end"}>
            <AIOSelectComponent
              items={AdsSortField.selectItems}
              handleChange={(value) => adsService.setSort({ field: value })}
              maxWidth="180px"
              value={sort.field}
            />
          </Stack>
          <Box flexShrink={0}>
            <Button
              onClick={handleExportCSV}
              variant="text"
              color="primary"
              startIcon={<img alt="download" height={"14px"} src="/images/download_media.svg" />}>
              <Typography>{t("reports.exportCSV")}</Typography>
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Stack direction="row" width="100%" height="100%" justifyContent="center" alignItems="center" pt="20px">
        <Stack sx={{ height: "100%", width: "100%" }}>
          <StyledDataGrid
            rows={rows}
            loading={adsLoading}
            rowsPerPageOptions={[]}
            pagination
            disableColumnMenu
            disableSelectionOnClick
            page={currentPage - 1}
            pageSize={adsPaginationData.perPage}
            paginationMode="server"
            filterMode="server"
            headerHeight={35}
            rowHeight={61}
            rowCount={adsPaginationData.total}
            columns={columns}
            components={{
              Footer: CustomFooter,
            }}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AdsReportsTab;
