import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";
import { CompaniesFilters, CompanySortModel } from "./companies.model";
import { companiesService } from "./companies.service";

interface SearchCompaniesParams {
  filters?: CompaniesFilters;
  sort?: CompanySortModel;
  page?: number;
  take?: number;
}

export const searchCompaniesEffect = createEffectFn((params$: Observable<SearchCompaniesParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ filters, sort, page, take }) => companiesService.getCompanies(filters, sort, page, take))
  );
});
