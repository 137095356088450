import React from "react";
import { Paper } from "@mui/material";

interface Props {
  img: string;
  children: JSX.Element | string;
  borderRadius?: "10px" | "15px";
  height?: string;
  minHeight?: string;
  overflow?: string;
  withPadding?: boolean;
  bgColor?: string;
}

const CardBackground = (props: Props) => {
  const { img, children, borderRadius = "10px", height, minHeight, withPadding, bgColor, overflow } = props;

  return (
    <Paper
      elevation={0}
      variant="with-background"
      id="card-background"
      sx={{
        borderRadius: `${borderRadius}`,
        backgroundImage: `${img ? `url(${img})` : null}`,
        width: "100%",
        height,
        minHeight,
        overflow,
        padding: withPadding ? "30px" : "0",
        backgroundColor: bgColor ? bgColor : "inherit",
        flexShrink: 0,
        boxShadow: "0px 0px 20px 0px rgba(0,0,0,0.05)",
        "&::-webkit-scrollbar": { display: "none" },
      }}>
      {children}
    </Paper>
  );
};

export default CardBackground;
