import React, { Dispatch, SetStateAction, useState } from "react";
import { alpha, Box, Checkbox, FormControlLabel, IconButton, Stack, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { ATSEnumFunctions, ManageATSModel } from "@store/common/ats.model";
import { EntityTypeEnum } from "@store/entities/entities.model";
import { ManageEntityParametersModel } from "@store/entities/details";

import { Colors } from "@constants/colors.constant";

import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import ConfirmModal from "@components/modal/Confirm.modal";
import ATSAddCompanyNameModal from "@screens/auth/admin/entities/parameters/ATSAddCompanyName.modal";

interface ATSConfigComponentProps {
  ats: ManageATSModel;
  errors: { [key: string]: any};
  isActivated?: boolean;
  type: EntityTypeEnum
  updateParameters: Dispatch<SetStateAction<ManageEntityParametersModel>>;
}

const ATSConfigComponent = (props: ATSConfigComponentProps) => {
  const { ats, errors, isActivated = false, type, updateParameters } = props;

  const { t } = useTranslation();

  const [openAddCompanyName, setOpenAddCompanyName] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState<string | false>(false);

  const updateAts = (name: string) => (value: any) => {
    updateParameters((state) => ({
      ...state,
      ats: {
        ...state.ats,
        [name]: value,
      },
    }))
  };

  return (
    <Stack position="relative" width="100%">
      <Stack width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing={2}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
          <Typography fontSize={16} fontWeight="700">
            {t("clients.details.parametersTab.ats.ATS")}
          </Typography>
          <Switch
            checked={isActivated}
            disabled={isActivated}
            onChange={(evt, checked) =>
              updateParameters((state) => ({...state, atsActivated: checked}))
            }/>
        </Stack>
        {isActivated && (
          <Stack spacing={3}>
            <SelectWithSearchComponent
              placeholder={t(`clients.details.parametersTab.ats.${type === EntityTypeEnum.CLIENT ? "selectATS" : "clientXML"}`)}
              items={ATSEnumFunctions.selectItems}
              handleChange={updateAts("type")}
              value={ats.type ?? ""}
              error={errors.ats.type}
            />
            <Stack spacing={3}>
              {!!ats.type && (
                <AIOTextfieldComponent
                  title={t("clients.details.parametersTab.ats.XMLUrl")}
                  multiline
                  color="secondary"
                  onChange={updateAts("url")}
                  value={ats.url ?? ""}
                  error={errors.ats.url}
                />
              )}
              {type === EntityTypeEnum.AFFILIATE && (
                <>
                  <Stack spacing={1}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                      <Typography fontSize={14} fontWeight="700">
                        {t("clients.details.parametersTab.ats.companyName")}
                      </Typography>
                      <IconButton onClick={() => setOpenAddCompanyName(true)}>
                        <img src="/images/icon_add.svg" alt="" />
                      </IconButton>
                    </Stack>
                    <Stack spacing="10px">
                      {ats.companyNames?.map((companyName, index) => (
                        <Stack key={index} direction="row" justifyContent="flex-start" alignItems="center" spacing={1} width="calc(100% + 10px)" ml="-10px !important">
                          <IconButton onClick={() => setConfirmDelete(companyName)}>
                            <img src="/images/icon_remove.svg" alt="" />
                          </IconButton>
                          <Box flex={1} bgcolor={alpha(Colors.primary, 0.05)} borderRadius="10px" px="15px" py="8px">
                            <Typography fontSize="14px" fontWeight="300">
                              {companyName}
                            </Typography>
                          </Box>
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                  <Box pl="7px">
                    <FormControlLabel
                      onChange={(evt, checked) => updateAts("autoStartCampaign")(checked)}
                      control={<Checkbox size="small" />}
                      checked={ats.autoStartCampaign ?? false}
                      label={
                        <Typography ml="10px" fontWeight="300" lineHeight={1.25}>
                          {t("clients.details.parametersTab.ats.autoStartCampaign")}
                        </Typography>
                      } />
                  </Box>
                </>
              )}
            </Stack>
          </Stack>
        )}
      </Stack>
      {openAddCompanyName && (
        <ATSAddCompanyNameModal
          handleAdd={(companyName) => {
            updateParameters((state) => ({
              ...state,
              ats: {
                ...state.ats,
                companyNames: (state.ats?.companyNames || []).concat(companyName),
              },
              googleAnalytics: {
                ...state.googleAnalytics,
                companyNames: (state.googleAnalytics?.companyNames || []).concat({ companyName, adsFilters: [] }),
              },
            }));
            setOpenAddCompanyName(false);
          }}
          handleClose={() => setOpenAddCompanyName(false)}/>
      )}
      {!!confirmDelete && (
        <ConfirmModal
          handleClose={() => setConfirmDelete(false)}
          handleConfirm={() => {
            updateParameters((state) => ({
              ...state,
              ats: {
                ...state.ats,
                companyNames: (state.ats?.companyNames || []).filter((c) => c !== confirmDelete),
              },
              googleAnalytics: {
                ...state.googleAnalytics,
                companyNames: (state.googleAnalytics?.companyNames || []).filter((c) => c.companyName !== confirmDelete),
              },
            }));
            setConfirmDelete(false);
          }}
          confirmMsg={t("clients.details.parametersTab.ats.confirmDelete.description")}
          modalTitle={t("clients.details.parametersTab.ats.confirmDelete.title")}/>
      )}
    </Stack>
  );
};

export default ATSConfigComponent;
