import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Box, Grid, Stack, Typography } from "@mui/material";

import { BoostDecision, BoostDetailsDetailsModel } from "@store/boosts/details";
import { boostsService, BoostStatus, BoostType } from "@store/boosts";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";

import CardBackgroundComponent from "@components/cardBackground/CardBackground.component";
import AIOButtonComponent from "@components/Button.component";
import BoostConfirmationModal from "./BoostConfirmation.modal";
import ChipComponent from "@components/Chip.component";

import { Colors } from "@constants/colors.constant";
import { getAppCurrency } from "@store/common/country.model";

interface PendingRequestCardProps {
  boost: BoostDetailsDetailsModel;
}

const PendingRequestCard = (props: PendingRequestCardProps) => {
  const { boost } = props;

  const { t } = useTranslation();

  const [openBoostDecision, setOpenBoostDecision] = useState<BoostDecision | false>(false);

  let waitingCurrentUserValidation = false;
  if (boost.state === BoostStatus.WAITING_FOR_VALIDATION && sessionQuery.buyerMode) waitingCurrentUserValidation = true;
  else if (boost.state === BoostStatus.IN_PROGRESS && sessionQuery.role === UserRoleEnum.ADMIN) waitingCurrentUserValidation = true;

  const hasCreditForBoostItem = useCallback(
    (boostItemId: string) => {
      return boost.affiliate.boostCredits.some((c) => c.boostItemId === boostItemId);
    },
    [boost]
  );

  const jobBoardsWithCredits = useMemo(() => boost.jobBoards.filter((x) => hasCreditForBoostItem(x.jobBoard.id)), [hasCreditForBoostItem, boost]);
  const sequencesWithCredits = useMemo(() => boost.sequences.filter((x) => hasCreditForBoostItem(x.sequence.id)), [hasCreditForBoostItem, boost]);
  const boostActionsWithCredits = useMemo(
    () => boost.boostActions.filter((x) => hasCreditForBoostItem(x.boostAction.id)),
    [hasCreditForBoostItem, boost]
  );
  const totalCredits = useMemo(
    () => jobBoardsWithCredits.length + sequencesWithCredits.length + boostActionsWithCredits.length,
    [jobBoardsWithCredits, sequencesWithCredits, boostActionsWithCredits]
  );

  const jobBoardsTotal = useMemo(
    () =>
      boost.jobBoards
        .filter(({ jobBoard: j }) => j.price && j.price > 0 && !hasCreditForBoostItem(j.id))
        .reduce((prev, current) => prev + current.jobBoard.price, 0),
    [hasCreditForBoostItem, boost]
  );
  const sequencesTotal = useMemo(
    () =>
      boost.sequences
        .filter(({ sequence: s }) => s.price && s.price > 0 && !hasCreditForBoostItem(s.id))
        .reduce((prev, current) => prev + current.sequence.price, 0),
    [hasCreditForBoostItem, boost]
  );
  const boostActionsTotal = useMemo(
    () =>
      boost.boostActions
        .filter(({ boostAction: b }) => b.price && b.price > 0 && !hasCreditForBoostItem(b.id))
        .reduce((prev, current) => prev + current.boostAction.price, 0),
    [hasCreditForBoostItem, boost]
  );

  return (
    <Stack bgcolor={Colors.background} borderRadius="15px">
      <Stack p="30px" spacing={2.5}>
        {boost.type === BoostType.MANUAL && (
          <>
            {!!boost.jobBoards?.length && (
              <Stack spacing={1}>
                <Typography fontSize={16} fontWeight="700">
                  {t("boost.details.detailsTab.jobBoardsSelected")}
                </Typography>
                <Box>
                  <Grid container spacing={1.5}>
                    {boost.jobBoards.map((j) => (
                      <Grid item key={j.jobBoard.id}>
                        <ChipComponent label={j.jobBoard.name} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Stack>
            )}
            {!!boost.sequences?.length && (
              <Stack spacing={1}>
                <Typography fontSize={16} fontWeight="700">
                  {t("boost.details.detailsTab.sequencesSelected")}
                </Typography>
                <Box>
                  <Grid container spacing={1.5}>
                    {boost.sequences.map((s) => (
                      <Grid item key={s.sequence.id}>
                        <ChipComponent label={s.sequence.name} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Stack>
            )}
            {!!boost.boostActions?.length && (
              <Stack spacing={1}>
                <Typography fontSize={16} fontWeight="700">
                  {t("boost.details.detailsTab.boostActionsSelected")}
                </Typography>
                <Box>
                  <Grid container spacing={1.5}>
                    {boost.boostActions.map((b) => (
                      <Grid item key={b.boostAction.id}>
                        <ChipComponent label={b.boostAction.name} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Stack>
            )}
          </>
        )}

        <Stack spacing={0.25}>
          <Typography fontSize={16} fontWeight="700">
            {t("boost.details.detailsTab.budget")}
          </Typography>
          {boost.type === BoostType.MANUAL ? (
            <Typography>
              {(jobBoardsTotal + sequencesTotal + boostActionsTotal).toFixed(2)}
              {getAppCurrency()} {t("global.exclTax")}
            </Typography>
          ) : (
            <Typography>{t("boost.details.detailsTab.includedInSubscription")}</Typography>
          )}
        </Stack>
        {boost.type === BoostType.MANUAL && !!totalCredits && (
          <Stack spacing={0.25}>
            <Typography fontSize={16} fontWeight="700">
              {t("boost.details.detailsTab.credits")} ({totalCredits})
            </Typography>
            <Typography whiteSpace="pre-line">
              •{" "}
              {[
                ...jobBoardsWithCredits.map((j) => j.jobBoard.name),
                ...sequencesWithCredits.map((s) => s.sequence.name),
                ...boostActionsWithCredits.map((b) => b.boostAction.name),
              ].join(",\n• ")}
            </Typography>
          </Stack>
        )}
        {boost.requestedBy && (
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <Avatar src={boost.requestedBy.picture} sx={{ height: 22, width: 22 }} />
            <Typography lineHeight={1.25} whiteSpace="pre-line">
              {boost.requestedBy.firstname} {boost.requestedBy.lastname}
            </Typography>
          </Stack>
        )}
      </Stack>
      <CardBackgroundComponent img="/images/background_campaign.svg">
        <Stack>
          <Stack direction="row" spacing={0.5} overflow="hidden" borderRadius="10px 10px 0 0" height="10px">
            {Array.from({ length: 3 }, (_, idx) => (
              <Box
                key={idx}
                height="6px"
                flex={1 / 3}
                bgcolor={boostsService.getBoostProgress(boost) > idx ? Colors.purple : Colors.stepperDisabled}
              />
            ))}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="space-between" p="20px">
            <Typography fontWeight="300" color={Colors.white}>
              {t(`boost.details.detailsTab.${waitingCurrentUserValidation ? "twininDecision" : "waitingBuyer"}`)}
            </Typography>
            {waitingCurrentUserValidation && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box sx={{ "& .MuiButton-root": { borderRadius: "10px", color: Colors.white, height: "40px" } }}>
                  <AIOButtonComponent title={t("global.reject")} onClick={() => setOpenBoostDecision(BoostStatus.REJECTED)} variant="text" />
                </Box>
                <Box sx={{ "& .MuiButton-root": { borderRadius: "10px", height: "40px" } }}>
                  <AIOButtonComponent
                    title={t("global.accept")}
                    onClick={() => setOpenBoostDecision(BoostStatus.VALIDATED)}
                    color="secondary"
                    variant="contained"
                  />
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>
      </CardBackgroundComponent>
      {openBoostDecision && <BoostConfirmationModal boostDecision={openBoostDecision} handleClose={() => setOpenBoostDecision(false)} />}
    </Stack>
  );
};

export default PendingRequestCard;
