import { io } from "socket.io-client";

const URL = (process.env.REACT_APP_API_URL || "http://localhost:3001/api").replace("/api", "");
class SocketUtils {
  static socket = io(URL, { autoConnect: false });

  static listenedEvents = new Set<string>();

  connect() {
    SocketUtils.socket.connect();
  }

  disconnect() {
    SocketUtils.socket.disconnect();
  }

  isConnected() {
    return SocketUtils.socket.connected;
  }

  listenEvent(event: string, callback: (...args: any[]) => void) {
    if (!SocketUtils.listenedEvents.has(event)) {
      SocketUtils.socket.on(event, callback);
      SocketUtils.listenedEvents.add(event);
    }
  }

  removeListener(event: string) {
    SocketUtils.socket.off(event);
    SocketUtils.listenedEvents.delete(event);
  }
}

const socketUtilsInstance = new SocketUtils();

export default socketUtilsInstance;
