import React, { useState } from "react";
import { Avatar, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useObservable } from "@ngneat/react-rxjs";

import { AdDetailsDetailsModel } from "@store/ads/details";
import { sessionQuery } from "@store/session";

import { Colors } from "@constants/colors.constant";

import AIOButtonComponent from "@components/Button.component";
import CardBackground from "@components/cardBackground/CardBackground.component";
import SelectBoostRequestTypeModal from "@screens/auth/common/boosts/boostRequest/SelectBoostRequestType.modal";
import ConfyAdsModal from "./ConfyAds.modal";

interface BoostAdCardProps {
  ad: AdDetailsDetailsModel;
}

const BoostAdCard = (props: BoostAdCardProps) => {
  const { ad } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [user] = useObservable(sessionQuery.user$);

  const [openConfyAdsModal, setOpenConfyAdsModal] = useState(false);
  const [openBoostRequest, setOpenBoostRequest] = useState(false);

  if (!user) return null;

  const thisAdAffiliate = user.affiliates.find((a) => a.affiliate.id === ad.affiliate.id);
  const affiliateHasUnlimitedSubscription = thisAdAffiliate?.affiliate?.subscriptions.some((s) => s.unlimited) ?? [];

  return (
    <>
      <CardBackground img="/images/background_campaign.svg">
        <Stack
          direction={breakpointDownSM ? "column" : "row"}
          alignItems="center"
          justifyContent="space-between"
          spacing={breakpointDownSM ? 2 : 4}
          px={breakpointDownSM ? "15px" : "20px"}
          py={breakpointDownSM ? "20px" : "30px"}>
          <Stack direction="row" spacing={2} flex={1} alignItems="center" justifyContent={breakpointDownSM ? "center" : "flex-start"}>
            <Avatar
              style={{
                padding: "10px 10px 10px 12px",
                backgroundColor: Colors.lightpurple,
                width: 45,
                height: 45,
              }}
              src="/images/icon_candidatures.svg"
            />
            <Stack>
              <Typography color="white" fontSize="16px" fontWeight={700}>
                {t("global.candidates", { count: (ad.applications?.aioApplications ?? 0) + (ad.applications?.powerBiApplications ?? 0) })}
              </Typography>
              {!ad?.isBoosted && (
                <Typography color={Colors.greyLight} fontWeight={300} lineHeight={1.25}>
                  {t("ads.details.detailTab.campaignInProgress")}
                </Typography>
              )}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            {!ad.managedByTwinin && !affiliateHasUnlimitedSubscription && (
              <AIOButtonComponent
                variant="contained"
                icon={<img alt="star" src="/images/icon_star_3d.svg" style={{ height: "12px", width: "12px" }} />}
                title={t("ads.confyAdToTwinin")}
                onClick={() => setOpenConfyAdsModal(true)}
              />
            )}
            {!ad.isBoosted && (
              <AIOButtonComponent
                variant="contained"
                onClick={() => setOpenBoostRequest(true)}
                icon={<img alt="boost" src="/images/icon_boost_rocket.svg" style={{ height: "12px", width: "12px" }} />}
                title={t("ads.details.detailTab.booster")}
              />
            )}
          </Stack>
        </Stack>
      </CardBackground>
      {openBoostRequest && <SelectBoostRequestTypeModal handleClose={() => setOpenBoostRequest(false)} />}
      {openConfyAdsModal && (
        <ConfyAdsModal ad={ad} handleClose={() => setOpenConfyAdsModal(false)} />
      )}
    </>
  );
};

export default BoostAdCard;
