import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";

import { AdModel, adsService, AdsSortField } from "@store/ads";

import { Colors } from "@constants/colors.constant";

import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import { InfiniteScrollContainer, VirtualScrollItem } from "@utils/infinitescroll.utils";
import AdCard from "@components/card/Ad.card";
import { PaginationData } from "@ngneat/elf-pagination";
import { finalize } from "rxjs";

interface SelectCompanySubscriptionAdsModalProps {
  concernedAds: AdModel[];
  credits: number;
  disableConcernedAds?: boolean;
  disableButtons?: boolean;
  entityId?: string;
  handleClose: () => void;
  handleValidate: (selectedAds: AdModel[]) => void;
  subtitle?: string;
}

const SelectCompanySubscriptionAdsModal = (props: SelectCompanySubscriptionAdsModalProps) => {
  const { concernedAds, credits, disableConcernedAds, disableButtons, entityId: entityIdProps, handleClose, handleValidate, subtitle } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const { entityId: entityIdParams } = useParams<{ entityId: string }>();
  const entityId = entityIdProps || entityIdParams;

  const scrollableAdsRef = useRef<HTMLDivElement | null>(null);

  const [ads, setAds] = useState<AdModel[]>([]);
  const [adsPaginationData, setAdsPaginationData] = useState<PaginationData | undefined>();
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 24;

  const [selectedAds, setSelectedAds] = useState<AdModel[]>(concernedAds ?? []);

  useEffect(() => {
    setLoading(true);
    adsService
      .getAdsWithStatsNoStore(
        {
          affiliateIds: entityId ? [{ value: entityId, label: "" }] : [],
        },
        { field: AdsSortField.NAME },
        currentPage,
        itemsPerPage
      )
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: (res) => {
          setAds((prv) => [...prv, ...res.data]);
          setAdsPaginationData({
            currentPage: res.meta.page,
            perPage: res.meta.take,
            total: res.meta.itemCount,
            lastPage: res.meta.pageCount,
          });
        },
        error: (err) => {
          enqueueSnackbar(err.message, { variant: "error" });
        },
      });
  }, [currentPage, enqueueSnackbar, entityId]);

  const handleNextPage = useCallback(() => {
    if (!adsPaginationData) return;
    if (adsPaginationData.lastPage >= adsPaginationData.currentPage) {
      setCurrentPage(adsPaginationData.currentPage + 1);
    }
  }, [adsPaginationData]);

  const backToTop = () => {
    if (scrollableAdsRef.current)
      scrollableAdsRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
  };

  return (
    <ModalComponent
      maxWidth="lg"
      title={t("subscriptions.addCompanySubscription.select.title")}
      handleClose={handleClose}
      titleLeft
      content={
        <Stack direction="column" spacing={1} overflow="auto" height="60vh" mb={1} py="10px" width="100%">
          {subtitle && (
            <Typography fontSize={14} fontWeight={500} color={Colors.greyLight} whiteSpace="pre-wrap" lineHeight={1.25}>
              {subtitle}
            </Typography>
          )}
          <Stack alignItems="flex-end" spacing={1}>
            <Stack direction="row" alignSelf="flex-end" alignItems="center" spacing={1}>
              <Typography fontSize={14} fontWeight="300">
                {t("subscriptions.addCompanySubscription.select.adsLeft")}
              </Typography>
              <Typography fontSize="16px" fontWeight="500" color={Colors.primary}>
                {`${selectedAds.filter((a) => !disableConcernedAds || !concernedAds.some((x) => a.id === x.id)).length}/${credits}`}
              </Typography>
            </Stack>
          </Stack>
          <Box overflow="auto" className="scrollable" py="20px" px="5px" ref={scrollableAdsRef}>
            <Grid container spacing={3}>
              <InfiniteScrollContainer
                nextPageHandler={handleNextPage}
                itemsPerPage={itemsPerPage}
                componentType="Grid"
                listItems={ads.map((ad) => (
                  <VirtualScrollItem
                    key={ad.id}
                    height={200}
                    children={
                      <AdCard
                        selected={!!selectedAds.find((selectedAd) => selectedAd.id === ad.id)}
                        ad={ad}
                        disabled={disableConcernedAds && concernedAds.some((a) => a.id === ad.id)}
                        handleClick={() =>
                          setSelectedAds((prev) =>
                            !!prev.find((x) => x.id === ad.id)
                              ? prev.filter((x) => x.id !== ad.id)
                              : selectedAds.filter((a) => !disableConcernedAds || !concernedAds.some((x) => a.id === x.id)).length < credits
                              ? [...prev, ad]
                              : prev
                          )
                        }
                      />
                    }
                  />
                ))}
              />
            </Grid>
          </Box>
          {loading && (
            <Stack direction="row" width="100%" justifyContent="center" mt="20px" height={"80px"} alignItems="center">
              {loading && <CircularProgress size={30} />}
            </Stack>
          )}
        </Stack>
      }
      actions={
        <Stack direction="row" alignItems="center" spacing="10px">
          <AIOButtonComponent
            variant="contained"
            color="secondary"
            ariaLabel="modal"
            title={t("global.validate")}
            onClick={() => {
              handleValidate(selectedAds);
              handleClose();
            }}
            disabled={!selectedAds.length || disableButtons}
          />
          <Stack
            sx={{
              bgcolor: Colors.lightPink,
              borderRadius: "15px",
              cursor: "pointer",
              height: "39px",
              p: "10px",
            }}
            justifyContent="center"
            alignItems="center"
            onClick={backToTop}>
            <img width="18px" src="/images/arrow_up.svg" alt="back to top arrow" />
          </Stack>
        </Stack>
      }
    />
  );
};

export default SelectCompanySubscriptionAdsModal;
