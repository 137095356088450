import { select } from "@ngneat/elf";
import { selectEntitiesCount } from "@ngneat/elf-entities";

import { stripeProductsStore } from "@store/stripe/products/stripeProducts.store";
import { getStripeProductsDataSource } from "@store/stripe/products/stripeProducts.requests";

export class StripeProductsQuery {
  store = stripeProductsStore;

  stripeProducts$ = getStripeProductsDataSource.data$();
  stripeProductsCount$ = this.store.pipe(selectEntitiesCount());

  filters$ = this.store.pipe(select((state) => state.filters));

  get filters() {
    return this.store.getValue().filters;
  }
}

export const stripeProductsQuery = new StripeProductsQuery();
