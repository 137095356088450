import { useLocalStorageState } from "ahooks";
import { useEffect } from "react";

const useLocalStorageCountDown = (localStorageKey: string, autoStopAtZero?: boolean) => {
  const [start, setStart] = useLocalStorageState(`${localStorageKey}Start`, { defaultValue: false });
  const [countDown, setCountDown] = useLocalStorageState(`${localStorageKey}CountDown`, { defaultValue: 0 });

  useEffect(() => {
    if (countDown === 0 && autoStopAtZero) {
      setStart(false);
    }
  }, [autoStopAtZero, countDown]);

  useEffect(() => {
    if (start) {
      const interval = setInterval(() => {
        setCountDown((state) => state ? state - 1 : 0);
      }, 1000);

      return () => clearInterval(interval);
    } else {
      setCountDown(0);
    }
  }, [start]);

  return {
    countDown,
    countDownStarted: start,
    startCountDown: (seconds: number) => {
      setStart(true);
      setCountDown(seconds);
    },
    stopCountDown: () => setStart(false),
  };
};

export default useLocalStorageCountDown;
