import React, { FormEvent, useEffect, useState } from "react";
import { alpha, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CustomAuthentication, sessionService } from "@store/session";
import { FieldErrors } from "@utils/yup.utils";
import { customSignInValidation } from "@screens/unauth/login/login.yups";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import ForgotPasswordModal from "@screens/unauth/forgotPassword/ForgotPassword.modal";
import { useSnackbar, VariantType } from "notistack";
import { finalize, forkJoin, switchMap, tap } from "rxjs";
import { Pages, ParametersRoutes, RoutesUnAuth } from "@utils/routes.utils";
import { linkedinService } from "@store/linkedin";
import { jobBoardsService } from "@store/jobBoards";
import { toolboxService } from "@store/toolbox";
import { stripeService } from "@store/stripe/stripe.service";
import ModalComponent from "@components/modal/Modal.component";

const LoginContainer = styled(Stack)({
  backgroundColor: Colors.white,
  borderRadius: "15px",
  width: "365px",
  paddingTop: "37px",
  paddingBottom: "42px",
  boxShadow: "0 0 20px 0 rgba(34,34,64,0.05)",
});

const StyledDivider = styled("div")({
  width: "100%",
  height: "1px",
  backgroundColor: alpha(Colors.lightGrey, 0.3),
});

const SignUpButton = styled(Typography)({
  textDecoration: "underline",
  cursor: "pointer",
});

const Login = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const [data, setData] = useState<CustomAuthentication>({});
  const [errors, setErrors] = useState<FieldErrors>({});

  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);

  const updateData = (name: string) => (value: any) => setData({ ...data, [name]: value });

  const handleCustomAuthentication = (evt: FormEvent) => {
    evt.preventDefault();

    const errors = customSignInValidation(data);

    setErrors(errors);
    if (!!Object.keys(errors).length) return;

    setLoading(true);
    sessionService
      .login(data)
      .pipe(
        switchMap(() =>
          forkJoin([
            jobBoardsService.getJobBoards(),
            jobBoardsService.getSequences(),
            jobBoardsService.getBoostActions(),
            jobBoardsService.getSchools(),
            toolboxService.getToolboxes(),
          ])
        ),
        tap(() => {
          if (searchParams.get('redirect')?.includes("subscription_")) {
            window.location.href = `/${Pages.PARAMETERS}/${ParametersRoutes.SUBSCRIPTION}`;
          }
        }),
        finalize(() => setLoading(false))
      )
      .subscribe({
        error: (err) => {
          enqueueSnackbar(err.text, err.options);
        },
      });
  };

  const handleConnectWithLinkedin = () => {
    linkedinService.connectToLinkedin(RoutesUnAuth.LOGIN, window.location.pathname);
  };

  useEffect(() => {
    const msg = searchParams.get("msg");
    const variant = searchParams.get("variant") as VariantType;
    const linkedinCode = searchParams.get("code");

    if (msg && variant) {
      enqueueSnackbar(msg, { variant, autoHideDuration: 5000 });
    }
    if (linkedinCode) {
      setLoading(true);
      sessionService
        .loginWithLinkedin(linkedinCode)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }

    window.history.replaceState({}, "", window.location.pathname); // remove query params from url

    const clientSecret = searchParams.get("payment_intent_client_secret");

    if (!clientSecret) return;

    stripeService.checkPaymentSucceeded(clientSecret, true).subscribe({
      next: () => setShowPaymentSuccessModal(true),
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [searchParams, enqueueSnackbar]);

  return (
    <Stack direction="column" height="100%" justifyContent="center" alignItems="center" spacing={breakpointDownSM ? 3 : 6}>
      <img alt="aio logo" height={140} width={113} src="/images/logo_txt.svg" />

      <LoginContainer direction="column" spacing={3} px={3.75}>
        <Typography fontSize="20px" fontWeight="700" mb="11px" textAlign="left">
          {t("auth.title")}
        </Typography>

        <form onSubmit={handleCustomAuthentication} noValidate>
          <Stack direction="column" spacing={3}>
            <AIOTextfieldComponent
              title={t("auth.email")}
              placeholder={t("auth.email")}
              onChange={updateData("email")}
              value={data.email || ""}
              error={errors.email}
              required
            />

            <Stack direction="column" spacing={1}>
              <AIOTextfieldComponent
                title={t("auth.password")}
                placeholder={t("auth.password")}
                onChange={updateData("password")}
                value={data.password || ""}
                error={errors.password}
                endAdornment={(
                  <IconButton size="small" sx={{mr: '-5px'}} onClick={() => setShowPassword((state) => !state)}>
                    <img alt="show password" src={`/images/icon_${showPassword ? 'hide' : 'show'}_pwd_primary.svg`} />
                  </IconButton>
                )}
                type={showPassword ? undefined : "password"}
                required
              />
              <Typography
                fontSize="12px"
                color="primary"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                align="right"
                onClick={() => setShowResetPassword(true)}>
                {t("auth.forgotPassword.title")} ?
              </Typography>
            </Stack>
            <Stack spacing="22px" mt="0px !important">
              <Stack direction="row" justifyContent="center" pt="30px">
                <AIOButtonComponent title={t("auth.login")} variant="contained" color="secondary" disabled={loading} isSubmit />
              </Stack>
              <Stack direction="row" alignItems="center" spacing="13px">
                <StyledDivider />
                <Typography fontWeight={300} color={Colors.lightGrey}>
                  {t("global.or")}
                </Typography>
                <StyledDivider />
              </Stack>
              <Stack direction="row" justifyContent="center">
                <AIOButtonComponent
                  ariaLabel="linkedin"
                  title={t("linkedin.connectWith")}
                  variant="contained"
                  color="secondary"
                  icon={<img width="18px" alt="" src="/images/icon_linkedin.svg" />}
                  disabled={loading}
                  onClick={handleConnectWithLinkedin}
                />
              </Stack>
            </Stack>
          </Stack>
        </form>
        <Stack direction="row" alignItems="center" spacing={0.8} width={"100%"} justifyContent={"center"} pt="10px">
          <Typography fontWeight={300} fontSize={"12px"}>
            {t("auth.noAccount")}
          </Typography>
          <SignUpButton
            color={"primary"}
            fontWeight={500}
            fontSize={"12px"}
            onClick={() => {
              const redirect = searchParams.get("redirect");
              navigate(`/${RoutesUnAuth.SIGN_UP}${!!redirect ? `?redirect=${redirect}` : ""}`);
            }}>
            {t("auth.registerNow")}
          </SignUpButton>
        </Stack>
      </LoginContainer>

      {showResetPassword && <ForgotPasswordModal handleClose={() => setShowResetPassword(false)} />}
      {showPaymentSuccessModal && (
        <ModalComponent
          handleClose={() => setShowPaymentSuccessModal(false)}
          icon={<img alt="check" src="/images/illus_check.svg" />}
          title={t("stripe.paymentSuccess.title")}
          content={
            <Stack px="50px" py="20px">
              <Typography textAlign="center" pb="25px" lineHeight="1.25" color={Colors.secondaryText} fontWeight={300}>
                {t("stripe.paymentSuccess.description.subscriptionRegisterUser")}
              </Typography>
            </Stack>
          }
          maxWidth="xs"
          paddingButton="10px 0px 40px 0px"
        />
      )}
    </Stack>
  );
};

export default Login;
