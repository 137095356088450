import { Colors } from "@constants/colors.constant";
import styled from "@emotion/styled";
import { CardActionArea, Checkbox, Typography } from "@mui/material";
import { alpha, Stack } from "@mui/system";
import { PotentialProfile } from "@store/ads/details";

const PotentialProfileCardStyled = styled(Stack)`
  background-color: ${Colors.white};
  border-radius: 15px;
  height: 100%;
  flex: 1;
  opacity: 1;
  position: relative;
  width: 100%;
  text-align: center;
  padding: 0px;
  & > *:first-of-type:not(#checkbox-container) {
    align-items: center;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: 0px 0px 20px rgba(34, 34, 64, 0.05);
    padding: 25px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
  }
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    }
  }
`;

const AvatarStyled = styled(Stack)({
  width: "54px",
  height: "54px",
  borderRadius: "50%",
  boxShadow: "0px 0px 2px #9393a9",
  border: `2px solid ${Colors.white}`,
  overflow: "hidden",
  marginBottom: "10px",
  justifyContent: "center",
  alignItems: "center",
  "& > img": {
    height: "100%",
    objectFit: "cover",
  },
});

interface PotentialProfileCardProps {
  disabledSelection?: boolean;
  handleSelect?: (checked: boolean) => void;
  profile: PotentialProfile;
  selected?: boolean;
}

const PotentialProfileCard = (props: PotentialProfileCardProps) => {
  const { disabledSelection, handleSelect, profile, selected } = props;

  const handleClick = () => {
    window.open(profile.profileURL, "_blank");
  };

  return (
    <PotentialProfileCardStyled>
      <Stack component={CardActionArea} onClick={handleClick} height="100%">
        <AvatarStyled>
          <img alt="profile" src={profile.profilePictureURL ?? "/images/Linkedin.png"} />
        </AvatarStyled>
        <Typography fontSize="16px" fontWeight={500} lineHeight={1.083}>
          {profile.profileName}
        </Typography>
        <Typography fontSize="12px" marginTop="8px" fontWeight={300} color={Colors.greyLight} lineHeight={1.083}>
          {profile.profileDescription}
        </Typography>
      </Stack>
      {!!handleSelect && (
        <Stack id="checkbox-container" position="absolute" top="12px" left="12px">
          <Checkbox
            disabled={disabledSelection && !selected}
            onChange={(evt, checked) => {
              evt.stopPropagation();
              handleSelect?.(checked);
            }}
            checked={selected}/>
        </Stack>
      )}
    </PotentialProfileCardStyled>
  );
};
export default PotentialProfileCard;
