import { createRequestDataSource } from "@ngneat/elf-requests";
import { select } from "@ngneat/elf";
import { boostDetailsStore } from "@store/boosts/details/boostDetails.store";

export const getBoostDetailsDetailsDataSource = createRequestDataSource({
  store: boostDetailsStore,
  data$: () => boostDetailsStore.pipe(select((state) => state.details)),
  requestKey: "getBoostDetailsDetails",
  dataKey: "boostDetails",
});
