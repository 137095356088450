import React from "react";
import { Stack, styled, Typography } from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import { Colors } from "@constants/colors.constant";

const CustomStack = styled(Stack)({
  border: `1px solid ${Colors.prompts.border}`,
  borderRadius: '15px',
  marginBottom: '30px',
  paddingLeft: '30px',
  paddingRight: '30px',
  position: 'relative',
});

interface PromptsCardProps {
  hasAddBtn?: boolean;
  handleAddBtn?: () => void;
  title?: string;
  children?: string | JSX.Element | JSX.Element[]
}

const PromptsCard = (props: PromptsCardProps) => {
  const { hasAddBtn, children,title,handleAddBtn } = props

  return (
    <CustomStack>
      {hasAddBtn &&
        <Stack sx={{position: 'absolute', right: '16px', top: '22px'}}>
          <AddCircleOutlineIcon sx={{width: "26px", cursor: 'pointer'}} color="primary" onClick={handleAddBtn} />
        </Stack>}
      {title &&
        <Stack mt="25px">
          <Typography fontWeight={700} fontSize="20px">{title}</Typography>
        </Stack>}
      {children}
    </CustomStack>
  )
}

export default PromptsCard