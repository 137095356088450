import { Box, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import { useObservable } from "@ngneat/react-rxjs";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

import { companyDetailsService, companytDetailsQuery } from "@store/ai-o/companies/details";

import { Colors } from "@constants/colors.constant";

import StatCardComponent from "@components/card/StatCard.component";

const CustomerDetailsTab = () => {
  const { t } = useTranslation();
  const { clientId } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [company] = useObservable(companytDetailsQuery.companyDetails$);
  const [{companyStats, loading}] = useObservable(companytDetailsQuery.stats$);

  useEffect(() => {
    if (!!clientId) {
      companyDetailsService.getCompanyStats(clientId).subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [clientId, enqueueSnackbar]);

  if (!company) return null;

  return (
    <>
      <Stack
        width="100%"
        alignItems="center"
        py={breakpointDownSM ? "20px" : "40px"}
        overflow="auto"
        className="scrollable">
        <Container maxWidth="lg" sx={{ px: breakpointDownSM ? "4px" : undefined }}>
          <Stack spacing={breakpointDownSM ? 2 : 4} width="100%">
            <Box>
              <Grid container spacing={breakpointDownSM ? 1 : 3}>
                <Grid item xs={12} sm={3}>
                  <StatCardComponent
                    bgColor={Colors.stats.red}
                    label={t("aio.clients.details.stats.jobs")}
                    loading={loading}
                    value={companyStats?.nbrJobs ?? 0}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StatCardComponent
                    bgColor={Colors.stats.purple}
                    label={t("aio.clients.details.stats.applications")}
                    loading={loading}
                    value={companyStats?.nbrCandidature ?? 0}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <StatCardComponent
                    bgColor={Colors.stats.orange}
                    label={t("aio.clients.details.stats.cvs")}
                    loading={loading}
                    value={companyStats?.nbrCv ?? 0}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <StatCardComponent
                    bgColor={Colors.stats.blue}
                    label={t("aio.clients.details.stats.coverLetter")}
                    loading={loading}
                    value={companyStats?.nbrLetterCover ?? 0}
                  />
                </Grid>
              </Grid>
            </Box>
            {!!company.description && (
              <Stack spacing={breakpointDownSM ? 1 : 2}>
                <Typography fontSize={20} fontWeight="700">
                  {t("clients.details.description")}
                </Typography>
                <Typography fontSize={14} fontWeight="300" textAlign="justify" color={Colors.grey}>
                  {company.description}
                </Typography>
              </Stack>
            )}
            {!!company.location && (
              <Stack spacing={breakpointDownSM ? 1 : 2}>
                <Typography fontSize={20} fontWeight="700">
                  {t("clients.details.address")}
                </Typography>
                <Typography fontSize={14} fontWeight="300" color={Colors.grey}>
                  {company.location}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Container>
      </Stack>
    </>
  );
};

export default CustomerDetailsTab;
