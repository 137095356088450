import React, { ReactElement, ReactNode } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Popper from "@mui/material/Popper";
import { styled } from "@mui/material/styles";
import { ListChildComponentProps, VariableSizeList } from "react-window";
import Typography from "@mui/material/Typography";
import { Colors } from "@constants/colors.constant";
import { alpha, Stack } from "@mui/material";
import { SelectItem } from "@components/input/Select.component";

const LISTBOX_PADDING = 8; // px

function renderRow(props: ListChildComponentProps) {
  const {data, index, style} = props;
  const inlineStyle = {
    ...style,
    top: (style.top as number) + LISTBOX_PADDING,
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      component="li" {...data[index][0]}
      bgcolor={data[index][1].bgColor || undefined}
      style={inlineStyle}
      spacing={1}>
      {data[index][1]?.icon}
      <Typography noWrap fontWeight={data[index][0]["aria-selected"] ? "700" : "300"}>
        {data[index][1].label}
      </Typography>
    </Stack>
  );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

// Adapter for react-window
const ListBoxComponent = React.forwardRef<HTMLDivElement,
  React.HTMLAttributes<HTMLElement>>(function ListboxComponent(props, ref) {
  const {children, ...other} = props;
  const itemData: ReactElement[] = [];
  (children as ReactElement[]).forEach(
    (item: ReactElement & { children?: ReactElement[] }) => {
      itemData.push(item);
      itemData.push(...(item.children || []));
    },
  );

  const getHeight = () => {
    if (itemData.length > 8) {
      return 8 * 30;
    }
    return itemData.map(() => 30).reduce((a, b) => a + b, 0);
  };

  const gridRef = useResetCache(itemData.length);

  return (
    <div ref={ref} className="scrollable">
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={() => 30}
          overscanCount={5}
          itemCount={itemData.length}>
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.paper}`]: {
    borderRadius: 15,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  [`& .${autocompleteClasses.endAdornment}`]: {
    right: '12px !important',
  },
  [`& .${autocompleteClasses.inputRoot}`]: {
    height: 35,
    padding: "0 36px 0 15px !important",
  },
  [`& .${autocompleteClasses.input}`]: {
    color: Colors.primary,
    fontSize: 14,
    fontWeight: 500,
    padding: 'auto 8px !important',
    "&::placeholder": {
      opacity: 1,
    },
  },
}) as typeof Autocomplete;

interface SelectWithSearchComponentProps {
  error?: string;
  handleChange?: (value?: SelectItem) => void;
  items?: SelectItem[];
  loading?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  startIcon?: ReactNode;
  value?: SelectItem | "";
}

const SelectWithSearchComponent = (props: SelectWithSearchComponentProps) => {
  const {error, handleChange, items = [], loading, placeholder, readOnly, startIcon, value} = props;

  return (
    <StyledAutocomplete
      fullWidth
      disableListWrap
      clearOnBlur={false}
      readOnly={readOnly}
      popupIcon={readOnly ? null : <img alt="" src="/images/arrow_dropdownlist_primary.svg"/>}
      PopperComponent={StyledPopper}
      ListboxComponent={ListBoxComponent}
      getOptionLabel={(option: any) => option.label ?? ""}
      isOptionEqualToValue={(option: any, value: SelectItem | "") =>
        typeof value === "string" && value === "" ? false : option.value === value?.value
      }
      loading={loading}
      options={loading ? [] : items}
      onChange={(e, newValue, reason, details) => {
        if (details && (!details.option || !(details.option as any)?.value)) {
          handleChange?.(undefined);
        } else {
          handleChange?.(newValue || undefined);
        }
      }}
      clearIcon={null}
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{backgroundColor: alpha(Colors.primary, 0.1), borderRadius: '7px'}}
          InputProps={{
            ...params.InputProps,
            startAdornment: typeof value === "string" || !value?.icon ? startIcon : value.icon,
          }}
          placeholder={placeholder}
          helperText={error}
          error={!!error}/>
      )}
      value={value}
      renderOption={(props, option) => [props, option] as React.ReactNode}/>
  );
}

export default SelectWithSearchComponent;