import CardBackgroundComponent from "@components/cardBackground/CardBackground.component";
import { Colors } from "@constants/colors.constant";
import styled from "@emotion/styled";
import { alpha, Collapse, LinearProgress, Stack, Typography } from "@mui/material";
import { Subscription } from "@store/subscriptions";
import { useTranslation } from "react-i18next";
import EuroIcon from "@mui/icons-material/Euro";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import AIOButtonComponent from "@components/Button.component";
import { useState } from "react";
import { AffiliateSubscription } from "@store/entities/afiliates/details";
import dayjsUtils from "@utils/dayjs.utils";
import AdsListComponent from "@screens/auth/admin/entities/components/subscription/AdsList.component";

const CardContainer = styled(Stack)({
  backgroundColor: Colors.users.listBackground,
  borderRadius: "10px",
  overflow: "visible",
  width: "100%",
});

const Tag = styled(Stack)({
  backgroundColor: alpha(Colors.white, 0.1),
  borderRadius: "7px",
  padding: "4px 6px",
});

const TagText = styled(Typography)({
  color: Colors.white,
  fontSize: "14px",
  fontWeight: 500,
});

const TagTextSecondary = styled(Typography)({
  color: Colors.greyLight,
  fontSize: "11px",
  fontWeight: 300,
});

const StyledLinearProgress = styled(LinearProgress)({
  backgroundColor: alpha(Colors.white, 0.1),
  height: "5px",
  width: "100%",
  borderRadius: "3px",
  "& .MuiLinearProgress-bar": {
    backgroundColor: Colors.white,
  },
});

interface CompanySubscriptionCardProps {
  affiliateSubscription: AffiliateSubscription;
  onClick?: () => void;
  subscription?: Subscription;
  selected?: boolean;
}

const CompanySubscriptionCard = (props: CompanySubscriptionCardProps) => {
  const { affiliateSubscription, onClick, subscription, selected } = props;

  const { t } = useTranslation();

  const [showAds, setShowAds] = useState(false);

  const daysLeftBeforeEnd = Math.abs(dayjsUtils().diff(affiliateSubscription.endDate, "day"));

  const totalDaysInPeriod = Math.abs(dayjsUtils(affiliateSubscription.startDate).diff(affiliateSubscription.endDate, "d"));

  return (
    <CardContainer onClick={onClick}>
      <CardBackgroundComponent img="/images/background_campaign.svg" borderRadius="10px">
        <Stack
          px="21px"
          py="13px"
          sx={{ border: selected ? "3px solid red" : "3px solid transparent", borderRadius: "10px", cursor: onClick ? "pointer" : "default" }}>
          <Stack direction="row" justifyContent="space-between" mb="30px">
            <Stack direction="row" spacing="5px" alignItems="center" mr="20px">
              <img height="14px" src="/images/subscription/icon_subscription_company.svg" alt="company" />
              <Typography fontSize="16px" fontWeight={500} color={Colors.subscriptions.company}>
                {subscription?.name}
              </Typography>
            </Stack>
            <Stack direction="row" spacing="5px" alignItems="center">
              <Tag direction="row" alignItems="center" spacing="4px">
                <StarOutlineIcon sx={{ color: Colors.greyLight, fontSize: "16px" }} />
                <TagText>{affiliateSubscription.unlimited ? t("subscriptions.unlimited") : affiliateSubscription.startCredits}</TagText>
              </Tag>
              <Tag direction="row" alignItems="center" spacing="4px">
                <EuroIcon sx={{ color: Colors.greyLight, fontSize: "14px" }} />
                <Stack direction="row" alignItems="center">
                  <TagText>{(subscription?.price ?? 0).toFixed(2)}</TagText>
                  <TagTextSecondary>{`/${t("subscriptions.offer", { count: 1 })}`}</TagTextSecondary>
                </Stack>
              </Tag>
            </Stack>
          </Stack>
          <StyledLinearProgress variant="determinate" value={100 - (daysLeftBeforeEnd / totalDaysInPeriod) * 100} />
          <Stack width="100%" alignItems="flex-end" mt="8px" mb="5px">
            <Typography fontWeight={500} color={Colors.white}>
              {t("subscriptions.endSub", { date: new Date(affiliateSubscription.endDate) })}
            </Typography>
            {!affiliateSubscription.unlimited && (affiliateSubscription.currentCredits ?? 0) < (affiliateSubscription.startCredits ?? 0) && (
              <Typography fontSize="14px" fontWeight="300" color={Colors.gold}>
                {t("subscriptions.adsLeft", { count: affiliateSubscription.currentCredits ?? 0 })}
              </Typography>
            )}
          </Stack>
          <Stack alignSelf="center" minHeight="40px">
            {!affiliateSubscription.unlimited && (
              <AIOButtonComponent
                variant="text"
                color="primary"
                endIcon={
                  <img
                    alt=""
                    style={{
                      transform: showAds ? "rotate(180deg)" : undefined,
                      transition: "transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    }}
                    src="/images/arrow_dropdownlist_primary.svg"
                  />
                }
                title={t(`subscriptions.${showAds ? "hideAds" : "showAds"}`)}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowAds((state) => !state);
                }}
              />
            )}
          </Stack>
        </Stack>
      </CardBackgroundComponent>
      <Collapse in={showAds}>
        <AdsListComponent inAccordion ads={affiliateSubscription.ads} />
      </Collapse>
    </CardContainer>
  );
};

export default CompanySubscriptionCard;
