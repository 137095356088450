import React, { useEffect, useState } from "react";
import { AppBar, Avatar, IconButton, Stack, styled, useMediaQuery, useTheme } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useIntercom } from "react-use-intercom";

import {
  AdminAioClientRoutes,
  AdminAioRoutes,
  AdminParametersRoutes,
  AdRoutes,
  AffiliateRoutes,
  ClientRoutes,
  ClientTabRoutes,
  Pages,
  ParametersRoutes,
  PotentialProfilesRoutes,
  ReportsRoutes
} from "@utils/routes.utils";
import { Sizes } from "@constants/sizes.constant";
import { AFFILIATES_FILTER_STORAGE_KEY } from "@constants/localStorage.constant";
import { STORAGE } from "@constants/events.constant";

import { EntityTypeEnum } from "@store/entities/entities.model";
import { sessionQuery, sessionService } from "@store/session";
import { UserRoleEnum } from "@store/users";

import Dashboard from "./client/dashboard/Dashboard";
import AdminDashboard from "@screens/auth/admin/dashboard/AdminDashboard";
import { Colors } from "@constants/colors.constant";
import LinkedinSync from "@screens/linkedin/LinkedinSync.screen";
import LeftBarComponent from "@components/LeftBar.component";
import Entities from "@screens/auth/admin/entities/Entities";
import EntityDetails from "@screens/auth/admin/entities/details/EntityDetails";
import EntityDetailsTab from "@screens/auth/admin/entities/details/EntityDetails.tab";
import EntitySubscriptionTab from "@screens/auth/admin/entities/details/EntitySubscription.tab";
import EntityUsersTab from "@screens/auth/admin/entities/details/EntityUsers.tab";
import EntityReportsTab from "@screens/auth/admin/entities/details/EntityReports.tab";
import EntityFileReportsTab from "@screens/auth/admin/entities/details/EntityFileReports.tab";
import EntityToolboxTab from "@screens/auth/admin/entities/details/EntityToolbox.tab";
import EntityHistoricTab from "@screens/auth/admin/entities/details/EntityHistoric.tab";
import Ads from "@screens/auth/common/ads/Ads";
import AdsDetails from "@screens/auth/common/ads/details/AdsDetails";
import AdsDetailsTab from "@screens/auth/common/ads/details/AdsDetails.tab";
import AdsStatsTab from "@screens/auth/common/ads/details/AdsStats.tab";
import AdsApplicationTab from "@screens/auth/common/ads/details/AdsApplication.tab";
import ApplicationModal from "@screens/auth/common/ads/components/applicationDetails/Application.modal";
import AdsPotentialProfilesTab from "@screens/auth/common/ads/details/AdsPotentialProfiles.tab";
import AdsCommentTab from "@screens/auth/common/ads/details/AdsComment.tab";
import AdsHistoricTab from "@screens/auth/common/ads/details/AdsHistoric.tab";
import Boosts from "@screens/auth/common/boosts/Boosts";
import BoostsDetails from "@screens/auth/common/boosts/details/BoostsDetails";
import AdminParameters from "@screens/auth/admin/parameters/AdminParameters";
import AdminUsersTab from "@screens/auth/admin/parameters/AdminUsers.tab";
import AdminPromptsTab from "./admin/parameters/AdminPrompts.tab";
import AdminPersonaeTab from "@screens/auth/admin/parameters/AdminPersonae.tab";
import AdminBannerTab from "./admin/parameters/AdminBanner.tab";
import AdminStripeProductsTab from "./admin/parameters/AdminStripeProducts.tab";
import UserModal from "@components/modal/User.modal";
import ClientParameters from "@screens/auth/client/parameters/ClientParameters";
import ClientUsersTab from "@screens/auth/client/parameters/ClientUsers.tab";
import Personae from "@screens/auth/common/personae/Personae";
import PersonaeProfile from "@screens/auth/common/personae/PersonaeProfile";
import Reports from "@screens/auth/client/reports/Reports";
import FileReportsTab from "@screens/auth/client/reports/FileReports.tab";
import AdsReportsTab from "@screens/auth/common/reports/AdsReports.tab";
import PowerBiReportsTab from "@screens/auth/common/reports/PowerBiReports.tab";
import Toolbox from "@screens/auth/client/toolbox/Toolbox";
import CVTheque from "./client/cvtheque/CVTheque";
import SubscriptionPricingComponent from "@components/subscriptions/SubscriptionPricing.component";
import Aio from "@screens/auth/admin/aio/Aio";
import AioPromptsTab from "@screens/auth/admin/aio/AioPrompts.tab";
import AioCustomersTab from "@screens/auth/admin/aio/AioCustomers.tab";
import AioApplicationsTab from "@screens/auth/admin/aio/AioApplications.tab";
import CustomerDetails from "@screens/auth/admin/aio/customerDetails/CustomerDetails";
import CustomerDetailsTab from "@screens/auth/admin/aio/customerDetails/CustomerDetails.tab";
import CustomerCompanyProfileTab from "@screens/auth/admin/aio/customerDetails/CustomerCompanyProfile.tab";
import BannerModal from "@screens/auth/admin/parameters/components/Banner.modal";
import Banners from "@components/Banners.component";
import EntitiesList from "./admin/entities/components/EntitiesList.component";
import PrivacyPolicyUS from "@screens/legalNotice/US/PrivacyPolicy";
import PrivacyPolicyFR from "@screens/legalNotice/FR/PrivacyPolicy";
import CGUUS from "@screens/legalNotice/US/CGU";
import CGUFR from "@screens/legalNotice/FR/CGU";
import { AppCountryEnum, getAppCountry } from "@store/common/country.model";
import Connectors from "@screens/auth/client/connectors/Connectors";
import AdsPotentialProfilesListTab from "@screens/auth/common/ads/details/AdsPotentialProfilesList.tab";
import AdsLinkedinInvitationsTab from "@screens/auth/common/ads/details/AdsLinkedinInvitations.tab";
import AcceptRGPDAndCGUModal from "@screens/legalNotice/AcceptRGPDAndCGU.modal";

const Container = styled(Stack)`
  background-color: white;
  height: 100%;
  position: relative;
  z-index: 1;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: -1;
    background: radial-gradient(
      ellipse at 140% -100%,
      rgba(212, 212, 212, 0.361448) 0%,
      rgba(219, 219, 219, 0.450244) 57%,
      rgba(235, 235, 235, 0) 100%
    );
  }
`;

const Auth = React.memo(() => {
  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const { boot, shutdown } = useIntercom();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [intercomBooted, setIntercomBooted] = useState(false);
  const [showRGPDAndCGU, setShowRGPDAndCGU] = useState(false);

  const [buyerMode] = useObservable(sessionQuery.buyerMode$);
  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);
  const [{ user }] = useObservable(sessionQuery.isAuthenticated$);

  if (user && !intercomBooted) {
    boot({ userId: user?.id, email: user?.email, name: user?.firstname, userHash: user?.intercomHmac });
    setIntercomBooted(true);
  } else if (!user) {
    shutdown();
  }

  const handleHumburgerClick = () => {
    setDrawerOpen((prv) => !prv);
  };

  useEffect(() => {
    if (!user?.RGPDAndCGUAccepted) {
      setShowRGPDAndCGU(true);
    } else {
      setShowRGPDAndCGU(false);
    }
  }, [user]);

  useEffect(() => {
    const onStorageUpdate = () => {
      sessionService.setAffiliateIds(JSON.parse(localStorage.getItem(AFFILIATES_FILTER_STORAGE_KEY) ?? "[]"));
    };

    window.addEventListener(STORAGE, onStorageUpdate);

    return () => window.removeEventListener(STORAGE, onStorageUpdate);
  }, []);

  if (showRGPDAndCGU) return <AcceptRGPDAndCGUModal />;

  return (
    <>
      {breakpointDownMD && (
        <AppBar sx={{ zIndex: theme.zIndex.drawer + 1 }}>
          <Stack
            direction="row"
            justifyContent="space-between"
            height={Sizes.topBar}
            width="100%"
            position="absolute"
            zIndex={10}
            bgcolor={Colors.background}
            pl="6px"
            pr="20px">
            <IconButton color="inherit" aria-label="open drawer" edge="end" onClick={handleHumburgerClick}>
              <img width="26px" height="26px" src="/images/hamburger.svg" alt="hamberger menu" />
            </IconButton>
            <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
              {clientViewClient && clientViewClient.picture && (
                <Avatar
                  sizes="contains"
                  sx={{
                    height: "28px !important",
                    width: "28px !important",
                    border: "none",
                    borderRadius: "10px !important",
                    backgroundColor: clientViewClient.picture,
                    color: Colors.secondary,
                  }}
                  src={clientViewClient.picture}
                />
              )}
              <img height={"37px"} src="/images/logo-top-bar.svg" alt="ALLinOne by twinin" />
            </Stack>
          </Stack>
        </AppBar>
      )}
      <Container
        sx={{
          marginLeft: (breakpointDownMD ? 0 : Sizes.leftBar) + "px",
          paddingTop: (breakpointDownMD ? Sizes.topBar : 0) + "px",
          flexDirection: breakpointDownMD ? "column" : "row",
          position: "relative",
        }}>
        {sessionQuery.role !== UserRoleEnum.ADMIN && <Banners />}
        <LeftBarComponent handleHumburgerClick={handleHumburgerClick} drawerOpen={drawerOpen} />
        {buyerMode ? (
          <Routes>
            <Route path={Pages.BOOST} element={<Boosts />} />
            <Route path={Pages.BOOST}>
              <Route path=":boostId" element={<BoostsDetails />} />
            </Route>
            <Route path={Pages.CLIENTS} element={<Entities />}>
              <Route path={ClientTabRoutes.AFFILIATES} element={<EntitiesList entityType={EntityTypeEnum.AFFILIATE} />} />
              <Route path={ClientTabRoutes.LIST} element={<EntitiesList entityType={EntityTypeEnum.CLIENT} />} />
              <Route index element={<Navigate replace to={ClientTabRoutes.AFFILIATES} />} />
            </Route>
            <Route path="*" element={<Navigate replace to={Pages.BOOST} />} />
            <Route path={Pages.CLIENTS}>
              <Route path={Pages.AFFILIATES}>
                <Route path=":entityId" element={<EntityDetails type={EntityTypeEnum.AFFILIATE} />}>
                  <Route path={AffiliateRoutes.DETAILS} element={<EntityDetailsTab type={EntityTypeEnum.AFFILIATE} />} />
                  <Route path={AffiliateRoutes.SUBSCRIPTION} element={<EntitySubscriptionTab />}>
                    <Route path=":userId" element={<UserModal readOnly entityType={EntityTypeEnum.AFFILIATE} />} />
                  </Route>
                </Route>
              </Route>
              <Route path=":entityId" element={<EntityDetails type={EntityTypeEnum.CLIENT} />}>
                <Route path={ClientRoutes.DETAILS} element={<EntityDetailsTab type={EntityTypeEnum.CLIENT} />} />
                <Route path="*" element={<Navigate replace to={ClientRoutes.DETAILS} />} />
              </Route>
            </Route>
            <Route path={Pages.LINKEDIN} element={<LinkedinSync />} />
          </Routes>
        ) : (
          <Routes>
            <Route path={Pages.DASHBOARD} element={sessionQuery.role === UserRoleEnum.ADMIN ? <AdminDashboard /> : <Dashboard />} />
            <Route path={Pages.ADS} element={<Ads />} />
            <Route path={Pages.ADS}>
              <Route path={":adId"} element={<AdsDetails />}>
                <Route path={AdRoutes.DETAIL} element={<AdsDetailsTab />} />
                <Route path={AdRoutes.STATISTICS} element={<AdsStatsTab />} />
                <Route path={AdRoutes.APPLYING} element={<AdsApplicationTab />}>
                  <Route path=":applicationId" element={<ApplicationModal />} />
                </Route>
                {/*<Route path={AdRoutes.SLOGAN} element={<AdsSloganTab />} />*/}
                <Route path={AdRoutes.POTENTIAL_PROFILES} element={<AdsPotentialProfilesTab />} />

                <Route path={AdRoutes.POTENTIAL_PROFILES} element={<AdsPotentialProfilesTab />}>
                  <Route path={PotentialProfilesRoutes.SEARCH} element={<AdsPotentialProfilesListTab />} />
                  <Route path={PotentialProfilesRoutes.LINKEDIN_INVITATIONS} element={<AdsLinkedinInvitationsTab />} />
                  <Route index element={<Navigate to={PotentialProfilesRoutes.SEARCH} replace />} />
                </Route>
                <Route path={AdRoutes.COMMENT} element={<AdsCommentTab />} />
                <Route path={AdRoutes.HISTORIC} element={<AdsHistoricTab />} />
                <Route path="*" element={<Navigate replace to={AdRoutes.DETAIL} />} />
              </Route>
            </Route>
            <Route path={Pages.BOOST} element={<Boosts />} />
            <Route path={Pages.PERSONAE} element={<Personae />} />
            <Route path={Pages.PERSONAE}>
              <Route path=":personaeId" element={<PersonaeProfile />} />
            </Route>

            {sessionQuery.role !== UserRoleEnum.ADMIN && (
              <>
                <Route path={Pages.CONNECTORS} element={<Connectors />} />
                <Route path={Pages.REPORTS} element={<Reports />}>
                  <Route path={ReportsRoutes.ADS} element={<AdsReportsTab />} />
                  <Route path={ReportsRoutes.FILES} element={<FileReportsTab />} />
                  <Route path={ReportsRoutes.POWER_BI} element={<PowerBiReportsTab />} />
                  <Route index element={<Navigate to={ReportsRoutes.FILES} replace />} />
                </Route>
                <Route path={Pages.TOOLBOX} element={<Toolbox />} />
                <Route path={Pages.CVTHEQUE} element={<CVTheque />}>
                  <Route path={`${AdRoutes.APPLYING}/:applicationId`} element={<ApplicationModal fromCVTheque />} />
                </Route>
                <Route path={Pages.PARAMETERS} element={<ClientParameters />}>
                  <Route path={ParametersRoutes.USERS} element={<ClientUsersTab />}>
                    <Route path=":userId" element={<UserModal entityType={EntityTypeEnum.CLIENT} />} />
                  </Route>
                  <Route path={ParametersRoutes.SUBSCRIPTION} element={<SubscriptionPricingComponent />} />
                  <Route path={ParametersRoutes.AFFILIATES} element={<EntitiesList notInTab entityType={EntityTypeEnum.AFFILIATE} />} />
                  <Route index element={<Navigate replace to={ParametersRoutes.USERS} />} />
                </Route>
                <Route path={Pages.PARAMETERS}>
                  <Route path={ParametersRoutes.AFFILIATES}>
                    <Route path=":entityId" element={<EntityDetails type={EntityTypeEnum.AFFILIATE} />}>
                      <Route path={AffiliateRoutes.DETAILS} element={<EntityDetailsTab type={EntityTypeEnum.AFFILIATE} />} />
                      <Route path={AffiliateRoutes.SUBSCRIPTION} element={<EntitySubscriptionTab />}>
                        <Route path=":userId" element={<UserModal readOnly entityType={EntityTypeEnum.AFFILIATE} />} />
                      </Route>
                      <Route path={AffiliateRoutes.CAREER} element={<CustomerCompanyProfileTab />} />
                    </Route>
                  </Route>
                </Route>
              </>
            )}

            {sessionQuery.role === UserRoleEnum.ADMIN && (
              <>
                <Route path={Pages.BOOST}>
                  <Route path=":boostId" element={<BoostsDetails />} />
                </Route>
                <Route path={Pages.CLIENTS} element={<Entities />}>
                  <Route path={ClientTabRoutes.AFFILIATES} element={<EntitiesList entityType={EntityTypeEnum.AFFILIATE} />} />
                  <Route path={ClientTabRoutes.LIST} element={<EntitiesList entityType={EntityTypeEnum.CLIENT} />} />
                  <Route index element={<Navigate replace to={ClientTabRoutes.AFFILIATES} />} />
                </Route>
                <Route path={Pages.CLIENTS}>
                  <Route path={Pages.AFFILIATES}>
                    <Route path=":entityId" element={<EntityDetails type={EntityTypeEnum.AFFILIATE} />}>
                      <Route path={AffiliateRoutes.DETAILS} element={<EntityDetailsTab type={EntityTypeEnum.AFFILIATE} />} />
                      <Route path={AffiliateRoutes.SUBSCRIPTION} element={<EntitySubscriptionTab />}>
                        <Route path=":userId" element={<UserModal readOnly entityType={EntityTypeEnum.AFFILIATE} />} />
                      </Route>
                      <Route path={AffiliateRoutes.USERS} element={<EntityUsersTab type={EntityTypeEnum.AFFILIATE} />}>
                        <Route path=":userId" element={<UserModal entityType={EntityTypeEnum.AFFILIATE} />} />
                        <Route path="add" element={<UserModal isCreation={UserRoleEnum.CLIENT} entityType={EntityTypeEnum.AFFILIATE} />} />
                      </Route>
                      <Route path={Pages.TOOLBOX} element={<EntityToolboxTab type={EntityTypeEnum.AFFILIATE} />} />
                      <Route path={AffiliateRoutes.HISTORIC} element={<EntityHistoricTab type={EntityTypeEnum.AFFILIATE} />} />
                      <Route path={Pages.REPORTS} element={<EntityReportsTab />}>
                        <Route path={ReportsRoutes.FILES} element={<EntityFileReportsTab />} />
                        <Route path={ReportsRoutes.ADS} element={<AdsReportsTab />} />
                        <Route path={ReportsRoutes.POWER_BI} element={<PowerBiReportsTab />} />
                        <Route index element={<Navigate to={ReportsRoutes.FILES} replace />} />
                        </Route>
                        <Route path={AffiliateRoutes.CAREER} element={<CustomerCompanyProfileTab />} />
                      <Route path="*" element={<Navigate replace to={AffiliateRoutes.DETAILS} />} />
                    </Route>
                  </Route>
                  <Route path=":entityId" element={<EntityDetails type={EntityTypeEnum.CLIENT} />}>
                    <Route path={ClientRoutes.DETAILS} element={<EntityDetailsTab type={EntityTypeEnum.CLIENT} />} />
                    <Route path={ClientRoutes.USERS} element={<EntityUsersTab type={EntityTypeEnum.CLIENT} />}>
                      <Route path=":userId" element={<UserModal entityType={EntityTypeEnum.CLIENT} />} />
                      <Route path="add" element={<UserModal isCreation={UserRoleEnum.CLIENT} entityType={EntityTypeEnum.CLIENT} />} />
                    </Route>
                    <Route path={ClientRoutes.HISTORIC} element={<EntityHistoricTab type={EntityTypeEnum.CLIENT} />} />
                    <Route path="*" element={<Navigate replace to={ClientRoutes.DETAILS} />} />
                  </Route>
                </Route>
                <Route path={Pages.PARAMETERS} element={<AdminParameters />}>
                  <Route path={AdminParametersRoutes.ADMINS} element={<AdminUsersTab />}>
                    <Route path=":userId" element={<UserModal />} />
                    <Route path="add" element={<UserModal isCreation={UserRoleEnum.ADMIN} />} />
                  </Route>
                  <Route path={AdminParametersRoutes.PROMPTS} element={<AdminPromptsTab />} />
                  <Route path={AdminParametersRoutes.PERSONAE} element={<AdminPersonaeTab />} />
                  <Route path={AdminParametersRoutes.BANNER} element={<AdminBannerTab />}>
                    <Route path=":bannerId" element={<BannerModal />} />
                    <Route path="add" element={<BannerModal isCreation />} />
                  </Route>
                  <Route path={AdminParametersRoutes.STRIPE_PRODUCTS} element={<AdminStripeProductsTab />} />
                  <Route index element={<Navigate replace to={AdminParametersRoutes.ADMINS} />} />
                </Route>
                <Route path={Pages.AIO} element={<Aio />}>
                  <Route path={AdminAioRoutes.PROMPTS} element={<AioPromptsTab />} />
                  <Route path={AdminAioRoutes.CUSTOMERS} element={<AioCustomersTab />} />
                  <Route path={AdminAioRoutes.APPLICATIONS} element={<AioApplicationsTab />} />
                  <Route index element={<Navigate replace to={AdminAioRoutes.PROMPTS} />} />
                </Route>
                <Route path={Pages.AIO}>
                  <Route path={AdminAioRoutes.CUSTOMERS}>
                    <Route path=":clientId" element={<CustomerDetails />}>
                      <Route path={AdminAioClientRoutes.DETAILS} element={<CustomerDetailsTab />} />
                      <Route path={AdminAioClientRoutes.COMPANY_PROFILE} element={<CustomerCompanyProfileTab />} />
                      <Route path="*" element={<Navigate replace to={AdminAioClientRoutes.DETAILS} />} />
                    </Route>
                  </Route>
                </Route>
              </>
            )}
            <Route path="*" element={<Navigate replace to={Pages.DASHBOARD} />} />
            <Route path={Pages.LINKEDIN} element={<LinkedinSync />} />
            <Route path={Pages.PRIVACY} element={getAppCountry() === AppCountryEnum.US ? <PrivacyPolicyUS /> : <PrivacyPolicyFR />} />
            <Route path={Pages.CGU} element={getAppCountry() === AppCountryEnum.US ? <CGUUS /> : <CGUFR />} />
          </Routes>
        )}
      </Container>
    </>
  );
});

export default Auth;
