import { createRequestDataSource } from "@ngneat/elf-requests";
import { select } from "@ngneat/elf";

import { clientDetailsStore } from "@store/entities/clients/details/clientDetails.store";

export const getClientDetailsDataSource = createRequestDataSource({
  store: clientDetailsStore,
  data$: () => clientDetailsStore.pipe(select((state) => state.details)),
  requestKey: "getClientDetails",
  dataKey: "entityDetails",
});

export const getClientActionsDataSource = createRequestDataSource({
  store: clientDetailsStore,
  data$: () => clientDetailsStore.pipe(select((state) => state.actions)),
  requestKey: "getClientActions",
  dataKey: "entityActions",
});

export const getClientCommentsDataSource = createRequestDataSource({
  store: clientDetailsStore,
  data$: () => clientDetailsStore.pipe(select((state) => state.comments)),
  requestKey: "getClientDetailsComments",
  dataKey: "entityComments",
});
