import React, { useState } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { BoostDetailsDetailsModel, boostDetailsService } from "@store/boosts/details";
import { BoostStatus, boostsService } from "@store/boosts";

import { Colors } from "@constants/colors.constant";
import ChipComponent from "@components/Chip.component";
import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";

interface ManualRequestCardProps {
  boost: BoostDetailsDetailsModel;
}

const ManualRequestCard = (props: ManualRequestCardProps) => {
  const { boost } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const handleFinish = () => {
    if (!boost.id) return;
    setLoading(true);
    boostDetailsService
      .manageBoostRequest(boost.id, BoostStatus.FINISHED)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => setOpenConfirm(false),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const handlePublished = (jobBoardId?: string, sequenceId?: string, boostActionId?: string, schoolId?: string) => {
    if (!boost.id) return;
    boostDetailsService
      .updateBoostPublications(
        boost.id,
        (boost.jobBoards ?? []).map((j) => ({
          ...j,
          isPublished: j.jobBoard.id === jobBoardId ? true : j.isPublished,
        })),
        (boost.sequences ?? []).map((s) => ({
          ...s,
          isPublished: s.sequence.id === sequenceId ? true : s.isPublished,
        })),
        (boost.boostActions ?? []).map((s) => ({
          ...s,
          isPublished: s.boostAction.id === boostActionId ? true : s.isPublished,
        })),
        (boost.schools ?? []).map((s) => ({
          ...s,
          isPublished: s.school.id === schoolId ? true : s.isPublished,
        }))
      )
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <>
      <Stack bgcolor={Colors.marine} borderRadius={"15px"} overflow={"hidden"}>
        <Stack direction="row" spacing={0.5} overflow="hidden" borderRadius="10px 10px 0 0" height="10px">
          {Array.from({ length: 3 }, (_, idx) => (
            <Box key={idx} height="6px" flex={1 / 3} bgcolor={boostsService.getBoostProgress(boost) > idx ? Colors.purple : Colors.stepperDisabled} />
          ))}
        </Stack>
        <Stack spacing={3} p={"33px 28px"}>
          {!!boost.jobBoards?.length && (
            <Stack position="relative">
              {boost.state === BoostStatus.VALIDATED && (
                <Box position="absolute" right={0} sx={{ "& .MuiButton-root": { borderRadius: "10px", height: "40px" } }}>
                  <AIOButtonComponent
                    title={t("global.finish")}
                    disabled={loading || boost.jobBoards.some((j) => !j.isPublished) || boost.sequences.some((s) => !s.isPublished)}
                    onClick={() => setOpenConfirm(true)}
                    color="secondary"
                    variant="contained"
                  />
                </Box>
              )}
              <Stack spacing={0.5}>
                <Typography fontSize={"16px"} fontWeight={700} color={Colors.white}>
                  {t("boost.details.detailsTab.publication.title")}
                </Typography>
                <Typography fontSize={"14px"} fontWeight={300} color={Colors.boostCard.secondaryText}>
                  {t("boost.details.detailsTab.publication.jobBoardDescription")}
                </Typography>
              </Stack>
              <Stack pt="13px">
                {boost.jobBoards?.map((j, index) => (
                  <Stack key={j.jobBoard.id} px="10px">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" py="10px">
                      <ChipComponent label={j.jobBoard.name} color="secondary" />
                      <Stack direction="row" spacing={1} alignItems="center">
                        {j.isPublished ? (
                          <>
                            <Stack width="16px" height="16px" borderRadius="100%" justifyContent="center" alignItems="center" bgcolor={Colors.green}>
                              <img width="8px" height="8px" alt="check_white" src="/images/icon_check_white.svg" />
                            </Stack>
                            <Typography fontSize={"14px"} fontWeight={500} color={Colors.green} mr="13px !important">
                              {t("boost.details.detailsTab.publication.published")}
                            </Typography>
                          </>
                        ) : (
                          <Box sx={{ "& .MuiButton-root": { height: 34 } }}>
                            <AIOButtonComponent
                              color="primary"
                              variant="text"
                              onClick={() => handlePublished(j.jobBoard.id)}
                              title={t("boost.details.detailsTab.publication.publish")}
                            />
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                    {index < boost.jobBoards.length - 1 && <Divider variant="fullWidth" color={Colors.white} sx={{ opacity: "20%" }} />}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
          {!!boost.sequences?.length && (
            <Stack>
              <Typography fontSize={"14px"} fontWeight={300} color={Colors.boostCard.secondaryText}>
                {t("boost.details.detailsTab.publication.sequenceDescription")}
              </Typography>
              <Stack pt="13px">
                {boost.sequences?.map((s, index) => (
                  <Stack key={s.sequence.id} px="10px">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" py="10px">
                      <ChipComponent label={s.sequence.name} color="secondary" />
                      <Stack direction="row" spacing={1} alignItems="center">
                        {s.isPublished ? (
                          <>
                            <Stack width="16px" height="16px" borderRadius="100%" justifyContent="center" alignItems="center" bgcolor={Colors.green}>
                              <img width="8px" height="8px" alt="check_white" src="/images/icon_check_white.svg" />
                            </Stack>
                            <Typography fontSize={"14px"} fontWeight={500} color={Colors.green} mr="13px !important">
                              {t("boost.details.detailsTab.publication.finished")}
                            </Typography>
                          </>
                        ) : (
                          <Box sx={{ "& .MuiButton-root": { height: 34 } }}>
                            <AIOButtonComponent
                              color="primary"
                              variant="text"
                              onClick={() => handlePublished(undefined, s.sequence.id)}
                              title={t("boost.details.detailsTab.publication.finish")}
                            />
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                    {index < boost.sequences.length - 1 && <Divider variant="fullWidth" color={Colors.white} sx={{ opacity: "20%" }} />}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
          {!!boost.boostActions?.length && (
            <Stack>
              <Typography fontSize={"14px"} fontWeight={300} color={Colors.boostCard.secondaryText}>
                {t("boost.details.detailsTab.publication.boostActionDescription")}
              </Typography>
              <Stack pt="13px">
                {boost.boostActions?.map((b, index) => (
                  <Stack key={b.boostAction.id} px="10px">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" py="10px">
                      <ChipComponent label={b.boostAction.name} color="secondary" />
                      <Stack direction="row" spacing={1} alignItems="center">
                        {b.isPublished ? (
                          <>
                            <Stack width="16px" height="16px" borderRadius="100%" justifyContent="center" alignItems="center" bgcolor={Colors.green}>
                              <img width="8px" height="8px" alt="check_white" src="/images/icon_check_white.svg" />
                            </Stack>
                            <Typography fontSize={"14px"} fontWeight={500} color={Colors.green} mr="13px !important">
                              {t("boost.details.detailsTab.publication.finished")}
                            </Typography>
                          </>
                        ) : (
                          <Box sx={{ "& .MuiButton-root": { height: 34 } }}>
                            <AIOButtonComponent
                              color="primary"
                              variant="text"
                              onClick={() => handlePublished(undefined, undefined, b.boostAction.id)}
                              title={t("boost.details.detailsTab.publication.finish")}
                            />
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                    {index < boost.boostActions.length - 1 && <Divider variant="fullWidth" color={Colors.white} sx={{ opacity: "20%" }} />}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
          {!!boost.schools?.length && (
            <Stack>
              <Typography fontSize={"14px"} fontWeight={300} color={Colors.boostCard.secondaryText}>
                {t("boost.details.detailsTab.publication.sequenceDescription")}
              </Typography>
              <Stack pt="13px">
                {boost.schools?.map((s, index) => (
                  <Stack key={s.school.id} px="10px">
                    <Stack direction="row" justifyContent="space-between" alignItems="center" py="10px">
                      <ChipComponent label={s.school.name} color="secondary" />
                      <Stack direction="row" spacing={1} alignItems="center">
                        {s.isPublished ? (
                          <>
                            <Stack width="16px" height="16px" borderRadius="100%" justifyContent="center" alignItems="center" bgcolor={Colors.green}>
                              <img width="8px" height="8px" alt="check_white" src="/images/icon_check_white.svg" />
                            </Stack>
                            <Typography fontSize={"14px"} fontWeight={500} color={Colors.green} mr="13px !important">
                              {t("boost.details.detailsTab.publication.finished")}
                            </Typography>
                          </>
                        ) : (
                          <Box sx={{ "& .MuiButton-root": { height: 34 } }}>
                            <AIOButtonComponent
                              color="primary"
                              variant="text"
                              onClick={() => handlePublished(undefined, s.school.id)}
                              title={t("boost.details.detailsTab.publication.finish")}
                            />
                          </Box>
                        )}
                      </Stack>
                    </Stack>
                    {index < boost.schools.length - 1 && <Divider variant="fullWidth" color={Colors.white} sx={{ opacity: "20%" }} />}
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
        </Stack>
      </Stack>
      {openConfirm && (
        <ModalComponent
          handleClose={() => setOpenConfirm(false)}
          title={t("boost.details.detailsTab.twinin.confirmation.title")}
          maxWidth="xs"
          titleLeft
          content={
            <Stack spacing={3} pb="20px" pt="10px">
              <Typography flex={1} color={Colors.secondaryText} lineHeight="1.25" fontWeight={300}>
                {t("boost.details.detailsTab.twinin.confirmation.description")}
              </Typography>
            </Stack>
          }
          paddingButton="10px 0px 40px 0px"
          actions={<AIOButtonComponent title={t("global.confirm")} variant="contained" disabled={loading} ariaLabel="modal" onClick={handleFinish} />}
        />
      )}
    </>
  );
};

export default ManualRequestCard;
