import { Colors } from "@constants/colors.constant";
import { CardActionArea, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import MultipleToolboxCellsModal
  from "@screens/auth/admin/entities/components/toolbox/MultipleToolboxCellsModal.modal";
import { AffiliateToolboxTool } from "@store/entities/afiliates/details";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { EntitiesModel } from "@store/entities/entities.model";

interface ToolboxToolGroupedCellsProps {
  entity: EntitiesModel;
  columnId: string;
  tools: AffiliateToolboxTool[];
}

const ToolboxToolGroupedCells = (props: ToolboxToolGroupedCellsProps) => {
  const { tools, entity, columnId } = props;

  const { t } = useTranslation();

  const [multipleCellsModalOpen, setMultipleCellsModalOpen] = useState(false);

  return (
    <>
      <Stack spacing={1}>
        <CardActionArea
          sx={{ width: "100%", borderRadius: "15px", padding: "10px", backgroundColor: Colors.lightMarine }}
          onClick={() => setMultipleCellsModalOpen(true)}>
          <Grid container spacing="15px">
            {tools.slice(0, tools.length > 4 ? 3 : 4).map((tool) => (
              <Grid item xs={6} key={tool.id} width="100%">
                <img
                  style={{ aspectRatio: "1/1", width: "100%", objectFit: "cover", borderRadius: "15px", border: "1px solid " + Colors.lightMarine }}
                  src={tool.fileFormat === "pdf" ? "/images/pdf-thumbnail.png" : tool.downloadURL ?? ""}
                  alt={tool.title}
                />
              </Grid>
            ))}
            {tools.length > 4 && (
              <Grid item xs={6} width="100%">
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  style={{ backgroundColor: Colors.lightBackground, aspectRatio: "1/1", width: "100%", objectFit: "cover", borderRadius: "15px" }}>
                  <Typography fontSize="24px" fontWeight="700">
                    {`+${tools.length - 3}`}
                  </Typography>
                </Stack>
              </Grid>
            )}
          </Grid>
        </CardActionArea>
        <Stack direction="row" justifyContent="space-between" mt="10px !important" alignItems="center">
          <Typography maxWidth="65%" noWrap color={Colors.greyLight}>
            {`${tools[0].category ? tools[0].category + " - " : ""}${tools[0].title}`}
          </Typography>
          <Typography color={Colors.greyLight} fontWeight="300px" fontSize="12px">
            {`${tools.length} ${t("toolbox.files")}`}
          </Typography>
        </Stack>
      </Stack>
      {multipleCellsModalOpen && (
        <MultipleToolboxCellsModal tools={tools} entity={entity} columnId={columnId} handleClose={() => setMultipleCellsModalOpen(false)} />
      )}
    </>
  );
};

export default ToolboxToolGroupedCells;
