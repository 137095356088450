import React, { useState } from "react";
import {
  alpha,
  Box,
  Button,
  Stack,
  Step,
  StepConnector,
  stepConnectorClasses,
  StepIconProps,
  StepLabel,
  Stepper,
  styled,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import dayjs from "dayjs";

import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import { affiliateDetailsService } from "@store/entities/afiliates/details";

import { Colors } from "@constants/colors.constant";

import CardBackground from "@components/cardBackground/CardBackground.component";
import ModalComponent from "@components/modal/Modal.component";
import { EntitiesModel } from "@store/entities/entities.model";
import { subscriptionsQuery } from "@store/subscriptions";

const Connector = styled(StepConnector)({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 30,
    left: "calc(-50%)",
    right: "calc(50%)",
  },
  [`&.${stepConnectorClasses.active}, &.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: Colors.mauve,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    backgroundColor: "#5C5C72",
    border: 0,
    height: 20,
  },
});

const StepIconRoot = styled("div")(() => ({
  display: "flex",
  height: 38,
  alignItems: "center",
  "& .MuiStepLabel-alternativeLabel": {
    display: "none",
  },
  "& .rounded-icon": {
    backgroundColor: Colors.white,
    zIndex: 1,
    height: 14,
    width: 14,
    borderRadius: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "content-box",
    border: `3px solid #5C5C72`,
  },
  "& .rounded-icon.completed, & .rounded-icon.active": {
    border: `3px solid ${Colors.mauve}`,
  },
  "& .rounded-icon .check": {
    height: 10,
    width: 10,
  },
  "&.big .rounded-icon .check": {
    height: 16,
    width: 16,
  },
  "&.big .rounded-icon": {
    borderWidth: 4,
    height: 28,
    width: 28,
  },
}));

const stepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <StepIconRoot className={className}>
      <Box className={`rounded-icon ${completed ? "completed" : undefined} ${active ? "active" : undefined}`}>
        {completed && <img alt="check" src="/images/icon_check_green.svg" className="check" />}
      </Box>
    </StepIconRoot>
  );
};

const getActiveSteps = (affiliate: EntitiesModel): number => {
  let activeSteps = 0;
  if (!subscriptionsQuery.affiliateSubscriptionOnGoing(affiliate)) {
    affiliate.timeline.actions.forEach((action) => {
      if (action.j > dayjs().date()) {
        activeSteps++;
      }
    });
    return activeSteps;
  }
  if (!affiliate?.timeline?.actions?.length) {
    return 0;
  }
  affiliate.timeline.actions.forEach((action) => {
    if (action.isPassed) {
      activeSteps++;
    }
  });
  return activeSteps;
};

interface AffiliateTimelineComponentProps {
  affiliate?: EntitiesModel;
}

const AffiliateTimelineComponent = (props: AffiliateTimelineComponentProps) => {
  const { affiliate } = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [openTimelineImg, setOpenTimelineImg] = useState<string | false>(false);

  if (!affiliate || !affiliate.timeline?.actions.length) {
    return null;
  }

  const steps = affiliate.timeline.actions.map((action) => action.name) || [];

  let activeStep = getActiveSteps(affiliate);

  const maxStepBeforeOverflow = 8;
  const minStepSpacing = 100;
  const canScroll = steps.length > maxStepBeforeOverflow;

  const scrollHorizontally = (amount: number) => {
    const cardBackground = document.getElementById("card-background");
    if (cardBackground) {
      cardBackground.scrollTo({
        top: 0,
        left: cardBackground.scrollLeft + amount,
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Stack position="relative">
        {canScroll && (
          <Stack
            sx={{
              p: "10px",
              height: "34px",
              bgcolor: alpha(Colors.lightBackground, 0.9),
              borderRadius: "50%",
              position: "absolute",
              left: "10px",
              top: 0,
              bottom: 0,
              margin: "auto",
              cursor: "pointer",
              transform: "rotate(-90deg)",
              width: "34px",
              zIndex: 100,
            }}
            justifyContent="center"
            alignItems="center"
            onClick={() => scrollHorizontally(-300)}>
            <img width="18px" src="/images/arrow_up.svg" alt="scroll left" />
          </Stack>
        )}
        {canScroll && (
          <Stack
            sx={{
              p: "10px",
              height: "34px",
              bgcolor: alpha(Colors.lightBackground, 0.9),
              borderRadius: "50%",
              position: "absolute",
              right: "10px",
              top: 0,
              bottom: 0,
              margin: "auto",
              cursor: "pointer",
              transform: "rotate(90deg)",
              width: "34px",
              zIndex: 100,
            }}
            justifyContent="center"
            alignItems="center"
            onClick={() => scrollHorizontally(300)}>
            <img width="18px" src="/images/arrow_up.svg" alt="scroll left" />
          </Stack>
        )}
        <CardBackground
          img="/images/background_campaign.svg"
          height={breakpointDownSM ? undefined : "140px"}
          minHeight={breakpointDownSM ? "140px" : undefined}
          overflow="auto">
          <Box
            py="20px"
            height="100%"
            width={canScroll ? `${steps.length * minStepSpacing}px` : "100%"}
            px={canScroll ? "25px" : 0}
            position="relative">
            <Stepper alternativeLabel activeStep={activeStep} connector={<Connector />}>
              {steps.map((label, index) => (
                <Tooltip
                  title={
                    <Stack spacing={"6px"}>
                      <Typography fontSize="14px" fontWeight="700">
                        {t("subscriptions.strategy")} :
                      </Typography>
                      <Typography fontSize="14px" fontWeight="400">
                        {affiliate.timeline.actions[index].strategy || t("subscriptions.noStrategy")}
                      </Typography>
                    </Stack>
                  }
                  key={label}>
                  <Step
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}>
                    <Typography
                      fontSize="14px"
                      fontWeight="700"
                      sx={{
                        color: activeStep === index ? Colors.mauve : Colors.white,
                      }}>
                      {`${t("subscriptions.dayAbbreviation")}+${affiliate.timeline.actions[index].j}`}
                    </Typography>
                    <StepLabel
                      sx={{ display: "flex", flexDirection: "column" }}
                      StepIconComponent={stepIcon}
                      StepIconProps={{
                        className: index === 0 || index === steps.length - 1 ? "big" : undefined,
                      }}>
                      <Typography
                        fontSize="12px"
                        fontWeight="700"
                        sx={{
                          color: activeStep === index ? Colors.mauve : Colors.white,
                        }}>
                        {label}
                      </Typography>
                    </StepLabel>
                  </Step>
                </Tooltip>
              ))}
            </Stepper>
          </Box>
        </CardBackground>

        {!!affiliate.timeline?.fileKey && (
          <Button
            sx={{ alignSelf: "flex-end", py: "5px", mt: "5px" }}
            onClick={() => {
              affiliateDetailsService.getAffiliateTimelineImage(affiliate.id).subscribe({
                next: (file) => setOpenTimelineImg(file || false),
                error: (err) => enqueueSnackbar(err.text, err.options),
              });
            }}>
            <InsertDriveFileOutlinedIcon fontSize="small" />
            <Typography ml="4px">{t("subscriptions.consultTimeline")}</Typography>
          </Button>
        )}
      </Stack>
      {!!openTimelineImg && (
        <ModalComponent
          handleClose={() => setOpenTimelineImg(false)}
          content={<img style={{ width: "100%", height: "auto", padding: "10px 0" }} src={openTimelineImg} alt="timeline" />}
        />
      )}
    </>
  );
};

export default AffiliateTimelineComponent;
