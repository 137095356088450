import AIOSearchComponent from "@components/input/AIOSearch.component";
import ModalComponent from "@components/modal/Modal.component";
import { Box, debounce, Stack } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AdCallHistory from "./AdCallHistory.table";
import { TwilioCallStatusEnum, twilioCallStatusItems } from "@store/twilioAiCalls";
import AIOSelectComponent from "@components/input/Select.component";

interface AdsHistoryModalProps {
  handleClose: () => void;
}

const AdsHistoryModal = (props: AdsHistoryModalProps) => {
  const { handleClose } = props;
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<TwilioCallStatusEnum>();
  return (
    <ModalComponent
      handleClose={handleClose}
      backgroundColor="#FAFAFA"
      title={t("ads.details.applyTab.history.title")}
      titleLeft
      maxWidth="lg"
      content={
        <Stack gap={2} minHeight={"60dvh"} width="100%" justifyContent="flex-start">
          <Stack flexDirection="row" justifyContent="space-between" width="100%" alignItems="center">
            <Stack mt={"20px"} width="100%" direction={"row"} justifyContent={"space-between"}>
              <Box width={"100%"} maxWidth="500px">
                <AIOSearchComponent
                  placeholder={t("ads.details.applyTab.searchPlaceholder")}
                  fullWidth
                  width="100%"
                  onChange={debounce((value?: string) => setSearch(value || ""), 400)}
                />
              </Box>
              <AIOSelectComponent
                placeholder={t("ads.details.applyTab.history.statusFilter")}
                items={twilioCallStatusItems}
                handleChange={(value) => setStatusFilter(value)}
                maxWidth="180px"
                value={statusFilter}
              />
            </Stack>
          </Stack>
          <AdCallHistory search={search} statusFilter={statusFilter} sortedBy={["dateFromRecent"]} />
        </Stack>
      }
    />
  );
};

export default AdsHistoryModal;
