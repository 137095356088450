import { createRequestDataSource } from "@ngneat/elf-requests";
import { select } from "@ngneat/elf";

import { applicationsStore } from "@store/applications/applications.store";

export const getPaginatedApplicationsDataSource = createRequestDataSource({
  store: applicationsStore,
  data$: () =>
    applicationsStore.pipe(
      select((state) =>
        Object.values(state.entities).filter((ad) => {
          return state.pagination.pages[state.pagination.currentPage].map((x) => x.toString()).includes(ad.id.toString());
        })
      )
    ),
  requestKey: "getPaginatedApplications",
  dataKey: "applications",
});
