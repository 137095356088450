import { SelectItem } from "@components/input/Select.component";
import I18nUtils from "@utils/i18n.utils";

export interface BoostCreditsModel {
  type: BoostItemEnum;
  boostItemId: string;
  credits: number;
  creditsWaitingForValidation: number;
}

export interface ManageBoostCreditsModel extends Partial<Omit<BoostCreditsModel, "type" | "boostItemId">> {
  type?: SelectItem;
  boostItem?: SelectItem;
}

export enum BoostItemEnum {
  BOOST_ACTION = "BOOST_ACTION",
  JOBBOARD = "JOBBOARD",
  SEQUENCE = "SEQUENCE",
  SCHOOL = "SCHOOL",
}

export namespace BoostItemTypeEnumFunctions {
  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("clients.details.parametersTab.boostCredits.types.boostAction"), value: BoostItemEnum.BOOST_ACTION },
    { label: I18nUtils.t("clients.details.parametersTab.boostCredits.types.jobBoard"), value: BoostItemEnum.JOBBOARD },
    { label: I18nUtils.t("clients.details.parametersTab.boostCredits.types.sequence"), value: BoostItemEnum.SEQUENCE },
    { label: I18nUtils.t("clients.details.parametersTab.boostCredits.types.school"), value: BoostItemEnum.SCHOOL },
  ];

  export const label = (type: BoostItemEnum) => {
    switch (type) {
      case BoostItemEnum.BOOST_ACTION:
        return I18nUtils.t("clients.details.parametersTab.boostCredits.types.boostAction");
      case BoostItemEnum.JOBBOARD:
        return I18nUtils.t("clients.details.parametersTab.boostCredits.types.jobBoard");
      case BoostItemEnum.SEQUENCE:
        return I18nUtils.t("clients.details.parametersTab.boostCredits.types.sequence");
      case BoostItemEnum.SCHOOL:
        return I18nUtils.t("clients.details.parametersTab.boostCredits.types.school");
      default:
        return "";
    }
  };
}
