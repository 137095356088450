import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { sessionQuery } from "@store/session";
import { FeatureCreditsTypeEnum, subscriptionsQuery } from "@store/subscriptions";

import {
  FREEMIUM_CANDIDATE_DATABASE_CREDITS,
  FREEMIUM_LINKEDIN_POSTING_CREDITS,
  FREEMIUM_POTENTIAL_SEARCH_CREDITS,
  PROFESSIONAL_POTENTIAL_SEARCH_CREDITS
} from "@constants/subscriptions.constant";

import NoCreditsModal from "@components/modal/NoCredits.modal";
import ConfirmModal from "@components/modal/Confirm.modal";

interface CheckCreditsModalProps {
  handleCloseCheck: () => void;
  handleCreditsValidated: () => void;
  type: FeatureCreditsTypeEnum;
}

const CheckCreditsModal = (props: CheckCreditsModalProps) => {
  const { handleCloseCheck, handleCreditsValidated, type } = props;

  const { t } = useTranslation();

  const [openNoCreditsModal, setOpenNoCreditsModal] = useState<number | false>(false);
  const [confirmCreditUseModal, setConfirmCreditUseModal] = useState(false);

  useEffect(() => {
    const user = sessionQuery.user;

    switch (type) {
      case FeatureCreditsTypeEnum.POTENTIAL_PROFILES:
        if ((user?.featureCredits?.potentialProfileSearch ?? 0) <= 0) {
          setOpenNoCreditsModal(subscriptionsQuery.sessionUserSubscriptionOngoing
            ? PROFESSIONAL_POTENTIAL_SEARCH_CREDITS
            : FREEMIUM_POTENTIAL_SEARCH_CREDITS
          );
        } else {
          setConfirmCreditUseModal(true);
        }
        break;
      case FeatureCreditsTypeEnum.LINKEDIN_POST:
        if ((user?.featureCredits?.linkedinPosting ?? 0) <= 0) {
          setOpenNoCreditsModal(FREEMIUM_LINKEDIN_POSTING_CREDITS);
        } else {
          setConfirmCreditUseModal(true);
        }
        break;
      case FeatureCreditsTypeEnum.APPLICATIONS:
        if ((user?.featureCredits?.candidateDatabase ?? 0) <= 0) {
          setOpenNoCreditsModal(FREEMIUM_CANDIDATE_DATABASE_CREDITS);
        } else {
          setConfirmCreditUseModal(true);
        }
        break;
      default:
        return handleCloseCheck();
    }
  }, [handleCloseCheck, type]);

  const userCreditsByType = useMemo(() => {
    const user = sessionQuery.user;

    return {
      [FeatureCreditsTypeEnum.POTENTIAL_PROFILES]: user?.featureCredits?.potentialProfileSearch ?? 0,
      [FeatureCreditsTypeEnum.LINKEDIN_POST]: user?.featureCredits?.linkedinPosting ?? 0,
      [FeatureCreditsTypeEnum.APPLICATIONS]: user?.featureCredits?.candidateDatabase ?? 0,
    };
  }, []);

  if (confirmCreditUseModal) {
    return (
      <ConfirmModal
        handleClose={handleCloseCheck}
        handleConfirm={handleCreditsValidated}
        modalTitle={t("subscriptions.spendFeatureCreditTitle")}
        confirmMsg={t("subscriptions.spendFeatureCredit", { count: userCreditsByType[type] })} />
    );
  }

  if (openNoCreditsModal !== false) {
    return <NoCreditsModal credits={openNoCreditsModal} handleClose={handleCloseCheck} />;
  }

  return null;
};

export default CheckCreditsModal;
