import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Box, Stack, Typography } from "@mui/material";
import { finalize } from "rxjs";

import { PotentialProfile } from "@store/ads/details";
import { unipileInvitationsService } from "@store/unipile/unipileInvitations";

import AIOButtonComponent from "@components/Button.component";
import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { Colors } from "@constants/colors.constant";

const MAX_CHAR = 200;

interface LinkedinInvitationMessageModalProps {
  adId: string;
  handleClose: () => void;
  handleSuccess: () => void;
  potentialProfiles: PotentialProfile[];
}

const LinkedinInvitationMessageModal = (props: LinkedinInvitationMessageModalProps) => {
  const { adId, handleClose, handleSuccess, potentialProfiles } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(t("ads.details.potentialProfilesTab.defaultInvitationMessage") as string);

  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleSendLinkedinInvitations = () => {
    if (!message || !potentialProfiles.length) return;

    setLoading(true);
    unipileInvitationsService
      .sendUnipileLinkedinInvitations(adId, potentialProfiles.map((p) => p.id), message)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => setShowSuccessModal(true),
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
  };

  if (showSuccessModal) {
    return (
      <ModalComponent
        handleClose={handleSuccess}
        icon={<img alt="check" src="/images/illus_check.svg" />}
        title={t("ads.details.potentialProfilesTab.successModal.title")}
        content={
          <Stack px="50px" py="20px">
            <Typography
              textAlign="center"
              pb="25px"
              lineHeight="1.25"
              whiteSpace="pre-line"
              color={Colors.secondaryText}
              fontWeight={300}>
              {t("ads.details.potentialProfilesTab.successModal.description")}
            </Typography>
          </Stack>
        }
        maxWidth="xs"
        paddingButton="10px 0px 40px 0px"
      />
    );
  }

  return (
    <ModalComponent
      maxWidth="xs"
      titleLeft
      title={t("ads.details.potentialProfilesTab.inviteSelectedWithLinkedin")}
      handleClose={handleClose}
      content={
        <Stack spacing={3} py="25px" alignItems="stretch" width="100%">
          <Typography
            fontSize="13px"
            fontWeight="500"
            lineHeight="1.25"
            dangerouslySetInnerHTML={{
              __html: t('ads.details.potentialProfilesTab.warning'),
            }}/>
          <Box width="100%">
            <AIOTextfieldComponent
              title={t('ads.details.potentialProfilesTab.message', {
                count: message.length,
                MAX_CHAR,
              })}
              fullWidth
              multiline
              minRows={2}
              maxLength={MAX_CHAR}
              value={message}
              onChange={setMessage} />
          </Box>
        </Stack>
      }
      actions={
        <AIOButtonComponent
          title={t("global.send")}
          ariaLabel="modal"
          disabled={loading || !message}
          onClick={handleSendLinkedinInvitations}
          variant="contained"
          color="secondary"
        />
      }
    />
  );
};

export default LinkedinInvitationMessageModal;