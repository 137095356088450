import { select } from "@ngneat/elf";
import { selectPaginationData } from "@ngneat/elf-pagination";

import { applicationsStore } from "@store/applications/applications.store";
import { getPaginatedApplicationsDataSource } from "@store/applications/applications.requests";

export class ApplicationsQuery {
  store = applicationsStore;

  paginatedApplications$ = getPaginatedApplicationsDataSource.data$();
  applicationsPaginationData$ = this.store.pipe(selectPaginationData());

  filters$ = this.store.pipe(select((state) => state.filters));

  get filters() {
    return this.store.getValue().filters;
  }
}

export const applicationsQuery = new ApplicationsQuery();
