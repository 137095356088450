import { Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { personaeQuery, PersonaeQuizAnswer, PersonaeQuizCategory, personaeService } from "@store/personae";

import PersonaeQuizCard from "./components/PersonaeQuiz.card";
import ManageQuizAnswerModal from "./components/ManageQuizAnswer.modal";

const AdminPersonaeTab = () => {
  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [manageAnswer, setManageAnswer] = useState<{ answer?: PersonaeQuizAnswer; category: PersonaeQuizCategory; isCreation: boolean }>();

  const [{ personaeQuiz, loading }] = useObservable(personaeQuery.personaeQuiz$);

  useEffect(() => {
    personaeService.getPersonaeQuiz().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  return (
    <Stack px={breakpointDownSM ? "10px" : "40px"} flex={1} width="100%" overflow="auto">
      <Stack direction="row" alignItems="center" spacing={2} mb={"28px"}>
        <Typography fontSize="20px" fontWeight="700">
          {t("adminParameters.personae.title")}
        </Typography>
      </Stack>
      <Grid container spacing={"36px"}>
        {PersonaeQuizCategory.categories.map((category) => (
          <Grid key={category} item xs={12} sm={6} lg={4} xl={3}>
            <PersonaeQuizCard category={category} loading={loading} personaeQuiz={personaeQuiz} setManageAnswer={setManageAnswer} />
          </Grid>
        ))}
      </Grid>
      {!!manageAnswer && (
        <ManageQuizAnswerModal
          category={manageAnswer.category}
          answer={manageAnswer.isCreation ? undefined : manageAnswer.answer}
          isCreation={manageAnswer.isCreation}
          handleClose={() => setManageAnswer(undefined)}
        />
      )}
    </Stack>
  );
};

export default AdminPersonaeTab;