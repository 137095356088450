import styled from "@emotion/styled";
import { alpha, Checkbox, FormControlLabel, IconButton, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import {
  ManageSubscriptionParameters,
  Subscription,
  SubscriptionTypeEnum
} from "@store/subscriptions/subscriptions.model";

import { Colors } from "@constants/colors.constant";

import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import { getAppCurrency, getToLocalStringCode } from "@store/common/country.model";

const PriceBadge = styled(Stack)((props: { bgcolor: string; height: string }) => ({
  height: props.height,
  width: "166px",
  borderRadius: "10px",
  backgroundColor: props.bgcolor,
}));

const ChoiceButton = styled(Stack)({
  padding: "0 15px",
  backgroundColor: alpha(Colors.purple, 0.1),
  borderRadius: "10px",
  width: "150px",
  height: "40px",
});

const MinusBtn = styled(IconButton)({
  borderRadius: "100%",
  backgroundColor: alpha(Colors.secondary, 0.1),
  "&:hover": { backgroundColor: alpha(Colors.purple, 0.1) },
  transition: "all 0.2s ease",
  width: "20px",
  height: "20px",
  cursor: "pointer",
  userSelect: "none",
});
const PlusBtn = styled(IconButton)({
  borderRadius: "100%",
  backgroundColor: Colors.white,
  "&:hover": { backgroundColor: alpha(Colors.purple, 0.1) },
  transition: "all 0.2s ease",
  width: "20px",
  height: "20px",
  cursor: "pointer",
  userSelect: "none",
});

const StyledNumberChoiceTextfield = styled(Stack)({
  width: "155px",
  marginTop: "2px",
  "& .MuiFormControl-root": {
    backgroundColor: alpha(Colors.purple, 0.1),
  },
  "& input": {
    textAlign: "center",
    backgroundColor: "transparent",
    color: Colors.secondary,
    border: `1px solid ${alpha(Colors.white, 0.2)}`,
    borderRadius: "10px",
    padding: "14px 22px",
  },
});

const StyledCheckBox = styled(Stack)({
  width: "140px",
  marginTop: "12px",
  "& label": { marginRight: 0 },
  "& label .MuiTypography-root": {
    color: Colors.lightGrey,
    paddingLeft: "4px",
  },
});

interface AmountChoiceComponentProps {
  isYearly: boolean;
  subscription: Subscription;
  setSubscriptionParameters: Dispatch<SetStateAction<ManageSubscriptionParameters>>;
  subscriptionParameters: ManageSubscriptionParameters;
}

const AmountChoiceComponent = (props: AmountChoiceComponentProps) => {
  const { isYearly, subscription, setSubscriptionParameters, subscriptionParameters } = props;

  const { t } = useTranslation();

  const handleAmountChange = (amount: number) => {
    setSubscriptionParameters((prevState) => ({
      ...prevState,
      amount: Math.max((prevState.amount ?? 0) + amount, 1),
    }));
  };

  const isSpecialSubscription = subscription.type === SubscriptionTypeEnum.EMPLOYER_BRAND || subscription.type === SubscriptionTypeEnum.COMPANY;

  return (
    <PriceBadge
      height={subscription.type === SubscriptionTypeEnum.COMPANY ? "auto" : "100px"}
      bgcolor={subscription.price && subscription.type !== SubscriptionTypeEnum.EMPLOYER_BRAND ? "transparent" : alpha(Colors.purple, 0.1)}
      alignItems="center"
      justifyContent="center">
      {subscription.price ? (
        <Stack
          justifyContent={isSpecialSubscription ? "center" : "space-between"}
          alignItems="center"
          height="100%"
          direction={subscription.type === SubscriptionTypeEnum.EMPLOYER_BRAND ? "column-reverse" : "column"}
          spacing={isSpecialSubscription ? "6px" : 0}>
          <Typography fontSize="40px" fontWeight={500} lineHeight={0.8}>
            {(subscription.type === SubscriptionTypeEnum.EMPLOYER_BRAND || !isYearly
              ? subscription.price
              : subscription.price * 0.9
            ).toLocaleString(getToLocalStringCode())}{getAppCurrency()}
          </Typography>
          <Typography fontSize="10px" fontWeight={300}>
            {subscription.type === SubscriptionTypeEnum.EMPLOYER_BRAND
              ? t("subscriptions.from")
              : subscription.type === SubscriptionTypeEnum.PROFESSIONAL
              ? `/ ${t("subscriptions.user", { count: 1 })}`
              : `/ ${t("subscriptions.jobOffer")}`}
            {subscription.type !== SubscriptionTypeEnum.EMPLOYER_BRAND && ` / ${t("subscriptions.month")}`}
          </Typography>
          {!isSpecialSubscription && (
            <ChoiceButton direction="row" alignItems="center" justifyContent="space-between">
              <MinusBtn onClick={() => handleAmountChange(-1)}>
                <Typography fontSize="16px" fontWeight={300}>
                  -
                </Typography>
              </MinusBtn>
              <Typography width="50px" fontSize="12px" fontWeight={700} textAlign="center" lineHeight={1}>
                {subscription.type === SubscriptionTypeEnum.PROFESSIONAL
                  ? `${subscriptionParameters.amount} ${t("subscriptions.user", { count: subscriptionParameters.amount })}`
                  : `${subscriptionParameters.amount} ${t("subscriptions.offer", { count: subscriptionParameters.amount })}`}
              </Typography>
              <PlusBtn onClick={() => handleAmountChange(1)}>
                <Typography fontSize="16px" fontWeight={300} color="primary">
                  +
                </Typography>
              </PlusBtn>
            </ChoiceButton>
          )}
          {subscription.type === SubscriptionTypeEnum.COMPANY && (
            <Stack>
              <StyledNumberChoiceTextfield>
                <AIOTextfieldComponent
                  placeholder={t("subscriptions.enterNumber")}
                  onChange={(amount) => {
                    setSubscriptionParameters((prevState) => ({
                      ...prevState,
                      amount: +amount,
                    }));
                  }}
                  value={subscriptionParameters.unlimitedOffer ? t("subscriptions.unlimitedOffer") : subscriptionParameters.amount?.toString() || ""}
                  pattern={subscriptionParameters.unlimitedOffer ? undefined : new RegExp(/^\d*$/)}
                  color="secondary"
                  readonly={!!subscriptionParameters.unlimitedOffer}
                  fullWidth
                />
              </StyledNumberChoiceTextfield>
              <StyledCheckBox alignItems="center" justifyContent="center" direction="row" spacing="5px">
                <FormControlLabel
                  sx={{ width: "fit-content" }}
                  label={
                    <Typography fontSize="12px" fontWeight="300">
                      {t("subscriptions.unlimitedOffer")}
                    </Typography>
                  }
                  onChange={(evt, checked) => {
                    setSubscriptionParameters((prevState) => ({
                      ...prevState,
                      unlimitedOffer: checked,
                      amount: undefined,
                    }));
                  }}
                  checked={!!subscriptionParameters.unlimitedOffer}
                  control={
                    <Checkbox
                      size="small"
                      icon={<img width="13px" src="/images/icon_checkbox_empty.svg" alt="unchecked" />}
                      checkedIcon={<img width="13px" src="/images/icon_checkbox_filled.svg" alt="checked" />}
                    />
                  }
                />
              </StyledCheckBox>
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack alignItems="center" spacing="4px">
          <Typography fontWeight={500} fontSize="25px" lineHeight={1}>
            {t("subscriptions.free")}
          </Typography>
          <img width="130px" src="/images/subscription/underline_purple.svg" alt="underline" />
        </Stack>
      )}
    </PriceBadge>
  );
};

export default AmountChoiceComponent;
