import React, { useCallback, useEffect, useState } from "react";
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { globalStatisticsService } from "@store/globalStatistics";
import ClientsFilterComponent from "@components/input/ClientsFilterComponent";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery } from "@store/session";
import AffiliateDashboardComponent from "@screens/auth/common/dashboard/AffiliateDashboard.component";
import AdminDashboardComponent from "@screens/auth/admin/dashboard/AdminDashboard.component";
import { AffiliateDetailsDetailsModel, affiliateDetailsService } from "@store/entities/afiliates/details";
import { forkJoin } from "rxjs";

const AdminDashboard = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownLG = useMediaQuery(theme.breakpoints.down("lg"));

  const [affiliateDetails, setAffiliateDetails] = useState<AffiliateDetailsDetailsModel | undefined>();
  const [affiliateIds] = useObservable(sessionQuery.affiliateIds$);

  const fetchSingleClient = useCallback(
    () => {
      forkJoin([
        affiliateDetailsService.getAffiliateDetails(affiliateIds[0].value),
        globalStatisticsService.getAffiliatesStats(),
      ])
      .subscribe({
        next: ([details]) => setAffiliateDetails(details),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    },
    [affiliateIds, enqueueSnackbar]
  );

  useEffect(() => {
    globalStatisticsService.resetStore();

    if (affiliateIds?.length === 1 && affiliateIds[0].value !== "FAVORITES") {
      fetchSingleClient();
    } else {
      globalStatisticsService.getAdminStats().subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options)
      });
    }
  }, [affiliateIds, enqueueSnackbar, fetchSingleClient]);

  const showAdminDashboard = affiliateIds.length !== 1 || affiliateIds.some((c) => c.value === "FAVORITES");

  return (
    <Stack
      pt={breakpointDownSM ? "10px" : "60px"}
      px={breakpointDownSM ? "10px" : "40px"}
      height="100%"
      spacing={breakpointDownLG ? 0 : 2}
      width="100%">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography fontSize={breakpointDownSM ? "30px" : "35px"} fontWeight="700" mr="20px">
          {t("dashboard.title")}
        </Typography>
        <Box width="300px">
          <ClientsFilterComponent values={affiliateIds} />
        </Box>
      </Stack>
      {showAdminDashboard ? (
        <AdminDashboardComponent />
      ) : (
        <AffiliateDashboardComponent
          affiliate={affiliateIds?.length === 1 && affiliateIds[0].value !== "FAVORITES" ? affiliateDetails : undefined} />
      )}
    </Stack>
  );
};

export default AdminDashboard;
