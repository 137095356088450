import React, { useEffect, useRef, useState } from "react";
import {
  alpha,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { finalize } from "rxjs";

import { EntityTypeEnum } from "@store/entities/entities.model";
import {
  EntityDetailsModel,
  entityDetailsService,
  entityToManageEntity,
  ManageEntityDetailsModel
} from "@store/entities/details";
import { UserRoleEnum, usersService } from "@store/users";

import { Colors } from "@constants/colors.constant";

import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import { AffiliateRoutes, ClientRoutes, Pages } from "@utils/routes.utils";
import { RELOAD_CLIENTS_AND_AFFILIATES } from "@constants/events.constant";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import AIOButtonComponent from "@components/Button.component";
import { SelectItem } from "@components/input/Select.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import { sessionQuery } from "@store/session";

interface EntityModalProps {
  defaultEntity?: ManageEntityDetailsModel;
  entityId?: string;
  forceIsAlsoAffiliate?: boolean;
  handleClose: () => void;
  isCreation?: boolean;
  isInDetails?: boolean;
  onCreation?: (newEntity: EntityDetailsModel) => void;
  type: EntityTypeEnum;
}

const EntityModal = (props: EntityModalProps) => {
  const { defaultEntity, entityId, forceIsAlsoAffiliate, handleClose, isCreation, isInDetails, onCreation, type } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { entityId: clientId } = useParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [loading, setLoading] = useState(false);

  const [admins, setAdmins] = useState<SelectItem[]>([]);
  const [entity, setEntity] = useState<ManageEntityDetailsModel>({
    isAlsoAffiliate: false,
    twinners: type === EntityTypeEnum.AFFILIATE ? [undefined] : undefined,
    ...(defaultEntity ? defaultEntity : {}),
  });

  useEffect(() => {
    if (!isCreation && !!entityId) {
      entityDetailsService.getEntityDetails(type, entityId).subscribe({
        next: (e) => setEntity(entityToManageEntity(e)),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
    }

    usersService.getUsers({ roles: [UserRoleEnum.ADMIN] }).subscribe({
      next: (admins) => setAdmins(admins.map((a) => ({ label: `${a.firstname} ${a.lastname}`, value: a.id }))),
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar, isCreation, entityId, type]);

  if (!isCreation && !entity.id) return null;

  const updateEntity = (name: string) => (value: any) => setEntity((state) => ({ ...state, [name]: value }));
  const updateContact = (name: string, index: number) => (value: any) => {
    const contacts = [...(entity.contacts ?? [])];
    contacts[index] = { ...contacts[index], [name]: value };
    updateEntity("contacts")(contacts);
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      setEntity((state) => ({ ...state, picture: undefined, pictureFile: files[0] }));
      e.target.value = "";
    }
  };

  const handleSave = () => {
    if (isCreation) {
      setLoading(true);
      entityDetailsService
        .createEntity(type, entity, type === EntityTypeEnum.AFFILIATE ? clientId : undefined)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: (newEntity) => {
            enqueueSnackbar(t(`clients.details.success.created.${type}`), { variant: "success" });
            if (onCreation) return onCreation(newEntity);
            switch (type) {
              case EntityTypeEnum.CLIENT:
                return navigate(`${newEntity.id}/${ClientRoutes.DETAILS}`);
              case EntityTypeEnum.AFFILIATE:
                return navigate(`../../${Pages.AFFILIATES}/${newEntity.id}/${AffiliateRoutes.DETAILS}`);
            }
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      if (!entityId) return;
      setLoading(true);
      entityDetailsService
        .updateEntityDetails(type, entityId, entity)
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t(`clients.details.success.edited.${type}`), { variant: "success" });
            if (!isInDetails) window.dispatchEvent(new Event(RELOAD_CLIENTS_AND_AFFILIATES));
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  };

  const errors = {
    name: getFieldError(entity.name, FieldValidationType.REQUIRED_STRING),
    address: getFieldError(entity.address, FieldValidationType.REQUIRED_STRING),
    city: getFieldError(entity.city, FieldValidationType.REQUIRED_STRING),
    contacts: entity.contacts?.map((c) => ({
      lastname: getFieldError(c.lastname, FieldValidationType.REQUIRED_STRING),
      firstname: getFieldError(c.firstname, FieldValidationType.REQUIRED_STRING),
      email: getFieldError(c.email, FieldValidationType.REQUIRED_EMAIL),
    })),
    twinners:
      entity.isAlsoAffiliate || type === EntityTypeEnum.AFFILIATE
        ? entity.twinners?.map((t) => getFieldError(t, FieldValidationType.REQUIRED_SELECT_ITEM))
        : undefined,
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      titleLeft
      title={t(`clients.details.title.${isCreation ? `new.${type}` : "details"}`)}
      content={
        <Stack spacing={3} py="20px" px="4px" pr="5px">
          <Stack alignItems="center">
            <input ref={inputRef} hidden onChange={handleSelectFile} accept="image/*" type="file" />
            <IconButton
              aria-label="avatar-rounded"
              onClick={() => inputRef.current?.click()}
              sx={{
                background:
                  entity.pictureFile || entity.picture || entity.pictureURL
                    ? `linear-gradient(${alpha(Colors.black, 0.4)}, ${alpha(Colors.black, 0.4)}), url(${
                        entity.pictureFile ? URL.createObjectURL(entity.pictureFile) : entity.picture ?? entity.pictureURL
                      })`
                    : "",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}>
              <img alt="" src="/images/camera.svg" />
            </IconButton>
          </Stack>
          <Box>
            <Grid container spacing={3.5}>
              {type === EntityTypeEnum.CLIENT && (isCreation || entity.affiliates?.length === 1) && (
                <Grid item xs={12}>
                  <Box ml="10px" mb="-15px">
                    <FormControlLabel
                      onChange={(evt, checked) => {
                        setEntity((state) => ({
                          ...state,
                          isAlsoAffiliate: checked,
                          twinners: checked ? [undefined] : undefined,
                        }));
                      }}
                      disabled={forceIsAlsoAffiliate}
                      control={<Checkbox size="small" />}
                      checked={entity.isAlsoAffiliate ?? false}
                      label={
                        <Typography ml="10px" mt="1px" fontSize="12px" lineHeight={1.25}>
                          {t("clients.details.isAlsoAffiliate")}
                        </Typography>
                      }
                    />
                  </Box>
                </Grid>
              )}
              <Grid item xs={breakpointDownSM ? 12 : 6}>
                <AIOTextfieldComponent
                  required
                  title={t("clients.details.name")}
                  placeholder={t("clients.details.name")}
                  onChange={updateEntity("name")}
                  value={entity.name ?? ""}
                  error={errors.name}
                />
              </Grid>
              <Grid item xs={breakpointDownSM ? 12 : 6}>
                <AIOTextfieldComponent
                  required
                  title={t("clients.details.address")}
                  placeholder={t("clients.details.address")}
                  onChange={updateEntity("address")}
                  value={entity.address ?? ""}
                  error={errors.address}
                />
              </Grid>
              <Grid item xs={breakpointDownSM ? 12 : 6}>
                <AIOTextfieldComponent
                  required
                  title={t("clients.details.city")}
                  placeholder={t("clients.details.city")}
                  onChange={updateEntity("city")}
                  value={entity.city ?? ""}
                  error={errors.city}
                />
              </Grid>
              <Grid item xs={12}>
                <AIOTextfieldComponent
                  title={t("clients.details.description")}
                  placeholder={t("clients.details.description")}
                  onChange={updateEntity("description")}
                  value={entity.description ?? ""}
                  multiline
                  minRows={4}
                />
              </Grid>
              <Grid item xs={12}>
                <AIOTextfieldComponent
                  title={t("clients.details.otherInformation")}
                  placeholder={t("clients.details.otherInformation")}
                  onChange={updateEntity("otherInformation")}
                  value={entity.otherInformation ?? ""}
                  multiline
                  minRows={4}
                />
              </Grid>
            </Grid>
          </Box>
          {(type === EntityTypeEnum.AFFILIATE || entity.isAlsoAffiliate) && sessionQuery.role === UserRoleEnum.ADMIN && (
            <Stack flex={1} mt="40px">
              <Stack direction="row" justifyContent="space-between" alignItems="center" mb="15px">
                <Typography fontSize="20px" fontWeight={700} color={Colors.secondary}>
                  {t("clients.details.twinnerTitle")} *
                </Typography>
                <Box sx={{ "& .MuiButton-root": { px: "10px", py: "5px" } }}>
                  <AIOButtonComponent
                    title={t("clients.details.twinnerButton")}
                    onClick={() => updateEntity("twinners")((entity.twinners ?? []).concat(undefined))}
                    variant="text"
                    color="primary"
                  />
                </Box>
              </Stack>
              <Stack spacing={3}>
                {entity.twinners?.map((twinner, index) => (
                  <Stack direction="row" alignItems="center" spacing={3} key={`twinner ${twinner?.value ?? index}`}>
                    {(entity.twinners?.length ?? 0) > 1 && (
                      <Stack height="35px" alignItems="center" justifyContent="center">
                        <IconButton onClick={() => updateEntity("twinners")((entity.twinners ?? []).filter((t, tIndex) => index !== tIndex))}>
                          <img alt="remove" src="/images/icon_remove.svg" />
                        </IconButton>
                      </Stack>
                    )}
                    <Box width="300px">
                      <SelectWithSearchComponent
                        placeholder={t("clients.details.selectTwinner")}
                        handleChange={(value) => updateEntity("twinners")((entity.twinners ?? []).map((t, tIndex) => (index === tIndex ? value : t)))}
                        items={admins.filter((a) => a.value === twinner?.value || !(entity.twinners ?? []).map((t) => t?.value).includes(a.value))}
                        error={errors.twinners?.[index]}
                        value={twinner}
                      />
                    </Box>
                  </Stack>
                ))}
              </Stack>
            </Stack>
          )}
          <Stack flex={1} mt="40px">
            <Stack direction="row" justifyContent="space-between" alignItems="center" mb="15px">
              <Typography fontSize="20px" fontWeight={700} color={Colors.secondary}>
                {t("clients.details.mainContactTitle")}
              </Typography>
              {(entity.contacts?.length ?? 0) < 2 && (
                <Box sx={{ "& .MuiButton-root": { px: "10px", py: "5px" } }}>
                  <AIOButtonComponent
                    title={t("clients.details.mainContactButton")}
                    onClick={() => setEntity((state) => ({ ...state, contacts: (state.contacts ?? []).concat({}) }))}
                    variant="text"
                    color="primary"
                  />
                </Box>
              )}
            </Stack>
            <Stack spacing={4}>
              {entity.contacts?.map((contact, idx) => (
                <Stack direction="row" alignItems="flex-start" spacing={3} key={`contact ${idx}`}>
                  <Stack>
                    <Box height="18px" />
                    <Stack height="38px" alignItems="center" justifyContent="center">
                      <IconButton
                        onClick={() =>
                          setEntity((state) => ({
                            ...state,
                            contacts: state.contacts!.filter((c, cIndex) => idx !== cIndex),
                          }))
                        }>
                        <img alt="remove" src="/images/icon_remove.svg" />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item xs={breakpointDownSM ? 12 : 6}>
                        <AIOTextfieldComponent
                          required
                          title={t("clients.details.lastname")}
                          placeholder={t("clients.details.lastname")}
                          onChange={updateContact("lastname", idx)}
                          value={contact.lastname ?? ""}
                          error={errors.contacts?.[idx].lastname}
                        />
                      </Grid>
                      <Grid item xs={breakpointDownSM ? 12 : 6}>
                        <AIOTextfieldComponent
                          required
                          title={t("clients.details.firstname")}
                          placeholder={t("clients.details.firstname")}
                          onChange={updateContact("firstname", idx)}
                          value={contact.firstname ?? ""}
                          error={errors.contacts?.[idx].firstname}
                        />
                      </Grid>
                      <Grid item xs={breakpointDownSM ? 12 : 6}>
                        <AIOTextfieldComponent
                          required
                          title={t("clients.details.email")}
                          placeholder={t("clients.details.email")}
                          onChange={updateContact("email", idx)}
                          value={contact.email ?? ""}
                          error={errors.contacts?.[idx].email}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Stack>
      }
      maxWidth="sm"
      actions={
        <AIOButtonComponent
          title={t(`global.${isCreation ? "add" : "modify"}`)}
          ariaLabel="modal"
          disabled={loading || checkIfErrors(errors)}
          onClick={handleSave}
          variant="contained"
          color="secondary"
        />
      }
    />
  );
};

export default EntityModal;
