import { catchError, from, map, Observable, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";
import SnackError from "@utils/error.utils";
import { candidatesStore } from "./candidates.store";
import { Candidate, CandidatesFilters, CandidateSortModel } from "./candidates.model";
import { deleteAllEntities, upsertEntities } from "@ngneat/elf-entities";
import { getPaginatedCandidatesDataSource } from "./candidates.requests";
import { PaginatedData } from "@utils/infinitescroll.utils";
import { deleteAllPages, setPage, updatePaginationData } from "@ngneat/elf-pagination";
import AIOAPIAxios, { AIOAPIRoutes } from "@api/aio-axios.api";

export class CandidatesService {
  store = candidatesStore;

  resetStore = () => this.store.reset();
  deleteCandidates = () => this.store.update(deleteAllEntities());
  deleteAllPages = () => this.store.update(deleteAllPages());

  setFilters = (filters: Partial<CandidatesFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  setSort = (sort: Partial<CandidateSortModel>) =>
    this.store.update((state) => ({
      ...state,
      sort: {
        ...state.sort,
        ...sort,
      },
    }));

  getCandidates = (
    filters?: CandidatesFilters,
    sort?: CandidateSortModel,
    page: number = 1,
    take: number = 24,
  ): Observable<PaginatedData<Candidate[]>> => {
    return from(
      AIOAPIAxios({
        ...AIOAPIRoutes.GETCandidates(),
        params: {
          search: filters?.search || undefined,
          reattachedAnnouncement: filters?.notAttachedAnnouncement ?? undefined,
          reattachedCompany: filters?.notAttachedCompany ?? undefined,
          sort: sort?.field,
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<Candidate[]>>) => {
        return response.data;
      }),
      tap((candidates) => {
        this.store.update(
          upsertEntities(candidates.data),
          updatePaginationData({
            currentPage: candidates.meta.page,
            perPage: candidates.meta.take,
            total: candidates.meta.itemCount,
            lastPage: candidates.meta.pageCount,
          }),
          setPage(
            candidates.meta.page,
            candidates.data.map((candidate) => candidate.id)
          ),
          getPaginatedCandidatesDataSource.setSuccess()
        );
      }),
      getPaginatedCandidatesDataSource.trackRequestStatus()
    );
  };

  reattachAnnouncement = (candidateId: string, announcementId: string, announcementRef: string): Observable<AxiosResponse> => {
    return from(AIOAPIAxios({
      ...AIOAPIRoutes.PATCHReattachAnnouncement(candidateId),
      data: { announcementId, announcementRef },
    })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
    );
  };
}

export const candidatesService = new CandidatesService();
