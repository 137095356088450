import { catchError, from, map, Observable, tap } from "rxjs";
import { setEntities, updateEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";

import AIOAPIAxios, { AIOAPIRoutes } from "@api/aio-axios.api";
import SnackError from "@utils/error.utils";
import { ManagePrompt, Prompt } from "./prompts.model";
import { promptsStore } from "./prompts.store";
import { getPromptsDataSource } from "./prompts.requests";

export class PromptsService {
  store = promptsStore;

  getPrompts = (): Observable<Prompt[]> => {
    return from(AIOAPIAxios(AIOAPIRoutes.GETPrompts())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Prompt[]>) => {
        return response.data;
      }),
      tap((prompts) => {
        this.store.update(setEntities(prompts), getPromptsDataSource.setSuccess());
      }),
      getPromptsDataSource.trackRequestStatus()
    );
  };

  updatePrompt = (promptId: string, prompt: ManagePrompt): Observable<Prompt> => {
    return from(
      AIOAPIAxios({
        ...AIOAPIRoutes.PUTPrompt(promptId),
        data: { content: prompt.content, modelPrompt: prompt.modelPrompt },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Prompt>) => {
        return response.data;
      }),
      tap((prompt) => {
        this.store.update(updateEntities(promptId, prompt));
      })
    );
  };
}

export const promptsService = new PromptsService();
