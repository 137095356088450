import { boostDetailsStore } from "@store/boosts/details/boostDetails.store";
import { getBoostDetailsDetailsDataSource } from "@store/boosts/details/boostDetails.requests";
import { select } from "@ngneat/elf";

export class BoostDetailsQuery {
  store = boostDetailsStore;

  details$ = getBoostDetailsDetailsDataSource.data$();

  boostsDetails$ = this.store.pipe(select((state) => state.details));
}

export const boostDetailsQuery = new BoostDetailsQuery();
