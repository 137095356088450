import { Colors } from "@constants/colors.constant";
import { Typography, styled } from "@mui/material";


export const BoldTitle = styled(Typography)({
  fontWeight: 700,
  fontSize: "16px",
  color: Colors.primary,
})

export const Title = styled(Typography)({
  fontWeight: 500,
  fontSize: "16px",
  color: Colors.text,
})

export const Content = styled(Typography)({
  fontWeight: 300,
  fontSize: "16px",
  color: Colors.text,
  '& ul': {
    listStyle: 'none',
    paddingLeft: '1em',
  },
  '& ul li::before': {
    content: '"-"',
    marginRight: '0.5em',
  },
})

