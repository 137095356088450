import React, { useState } from "react";
import { Box, CardActionArea, Skeleton, Stack, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

import { Colors } from "@constants/colors.constant";

import { DashboardStat } from "@store/globalStatistics";
import BoostsListModal from "@screens/auth/common/dashboard/modals/BoostsList.modal";
import AdsListModal from "@screens/auth/client/dashboard/modals/AdsList.modal";

interface DashboardCardProps {
  disableClick?: boolean;
  extra?: string;
  helper?: JSX.Element;
  loading?: boolean;
  stat: DashboardStat;
  value?: number;
}

const DashboardCard = (props: DashboardCardProps) => {
  const { disableClick, extra, helper, loading, stat, value } = props;

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [openDetails, setOpenDetails] = useState(false);

  return (
    <>
      <Stack
        bgcolor={DashboardStat.bgColor(stat)}
        flex={1}
        borderRadius="15px"
        position="relative"
        height="100%">
        <Stack
          disabled={disableClick}
          component={CardActionArea}
          onClick={() => setOpenDetails(true)}
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          height="100%"
          width="100%"
          p="30px 22px"
          borderRadius="15px"
          spacing={2}>
          <Box>
            <Stack
              height={breakpointDownMD ? "40px" : "60px"}
              width={breakpointDownMD ? "40px" : "60px"}
              bgcolor="white"
              borderRadius="50%"
              p={breakpointDownMD ? "8px" : "13px"}
              alignItems="center"
              justifyContent="center">
              <img
                alt=""
                src={DashboardStat.icon(stat)}
                style={{ height: "100%", width: "100%", objectFit: "contain" }} />
            </Stack>
          </Box>
          <Stack>
            {loading ? (
              <Skeleton variant="text" height="60px" width="70%" sx={{ mb: "5px", mt: "-5px" }} />
            ) : (
              <Typography fontSize={breakpointDownMD ? "40px" : "50px"} fontWeight="500" mt={-2}>
                {value ?? "?"}{extra}
              </Typography>
            )}
            <Typography fontSize="13px" fontWeight="500" color={`${Colors.marine}78`} mt={-1.5} sx={{ lineHeight: 1 }}>
              {DashboardStat.label(stat)}
            </Typography>
          </Stack>
        </Stack>
        {!!helper && (
          <Box sx={{ position: "absolute", top: "22px", right: "22px" }}>
            <Tooltip title={helper} arrow placement="top">
              <img alt="information" src="/images/icon_informations.svg" style={{ alignSelf: "flex-end" }} />
            </Tooltip>
          </Box>
        )}
      </Stack>
      {openDetails && [DashboardStat.INEFFICIENT].includes(stat) && (
        <AdsListModal
          handleClose={() => setOpenDetails(false)}
          stat={stat}
          title={DashboardStat.label(stat, true)} />
      )}
      {openDetails && [DashboardStat.BOOSTS].includes(stat) && (
        <BoostsListModal
          handleClose={() => setOpenDetails(false)}
          stat={stat}
          title={DashboardStat.label(stat, true)} />
      )}
    </>
  );
};

export default DashboardCard;
