import { catchError, from, map, Observable, tap } from "rxjs";
import { addEntities, deleteEntities, setEntities, updateEntities } from "@ngneat/elf-entities";
import { AxiosError, AxiosResponse } from "axios";

import { reportsStore } from "@store/reports/reports.store";
import { ManageNewReport, NewReport, Report, ReportsFilters } from "@store/reports/reports.model";
import { getReportsDataSource } from "@store/reports/reports.requests";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";

export class ReportsService {
  store = reportsStore;

  resetStore = () => this.store.reset();
  setFilters = (filters: Partial<ReportsFilters>) =>
    this.store.update((state) => ({
      ...state,
      filters: {
        ...state.filters,
        ...filters,
      },
    }));

  addReport = (newReport: NewReport): Observable<Report[]> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTReport(newReport.affiliateId),
        data: ManageNewReport.toFormData(newReport),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Report[]>) => {
        return response.data;
      }),
      tap((report) => {
        this.store.update(addEntities(report));
      })
    );
  };

  getReports = (filters?: ReportsFilters): Observable<Report[]> => {
    return from(
      APIAxios({
        ...APIRoutes.GETReports(),
        params: {
          affiliateIds: filters?.affiliateIds?.map((c) => c.value) || [],
          search: filters?.search || undefined,
          from: filters?.from?.toISOString() || undefined,
          to: filters?.to?.toISOString() || undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Report[]>) => {
        return response.data;
      }),
      tap((reports) => {
        this.store.update(setEntities(reports), getReportsDataSource.setSuccess());
      }),
      getReportsDataSource.trackRequestStatus()
    );
  };

  incrementDownload = (reportId: string): Observable<Report> => {
    return from(APIAxios(APIRoutes.PUTReportIncrementDownload(reportId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Report>) => {
        return response.data;
      }),
      tap((report) => {
        this.store.update(updateEntities(reportId, { downloads: report.downloads, isNew: report.isNew }));
      })
    );
  };

  deleteReport = (reportId: string): Observable<Report> => {
    return from(APIAxios(APIRoutes.DELETEReport(reportId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Report>) => {
        return response.data;
      }),
      tap((report) => {
        this.store.update(deleteEntities(reportId));
      })
    );
  };
}

export const reportsService = new ReportsService();
