import { Box, Container, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import { adDetailsQuery, adDetailsService } from "@store/ads/details";
import { Colors } from "@constants/colors.constant";
import StatCardComponent from "@components/card/StatCard.component";
import { useTranslation } from "react-i18next";
import { StatTooltipComponent } from "@screens/auth/common/ads/Ads";
import AdApplicationsChart from "@components/charts/AdApplications.chart";
import { AdStatProgress } from "@store/ads";

const AdsStatsTab = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { adId } = useParams();
  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [{ adStats: stats, loading }] = useObservable(adDetailsQuery.stats$);

  useEffect(() => {
    if (adId) {
      adDetailsService.getAdStats(adId).subscribe({
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [adId, enqueueSnackbar]);

  const getProgress = (statProgress?: AdStatProgress) => {
    if (!statProgress || statProgress.previous === 0) return 0;
    return Math.round(((statProgress.current - statProgress.previous) / statProgress.previous) * 100);
  };

  return (
    <Stack width="100%" alignItems="center" py={breakpointDownSM ? "20px" : "40px"} overflow="auto" className="scrollable">
      <Container maxWidth="md" sx={{ px: breakpointDownSM ? "4px" : undefined }}>
        <Stack spacing={2}>
          <Box>
            <Grid container spacing={breakpointDownSM ? 1 : 3}>
              <Grid item xs={12} sm={4}>
                <StatCardComponent
                  bgColor={Colors.stats.green}
                  label={t("ads.stats.nbViewsSingle")}
                  helper={<StatTooltipComponent title={t("ads.stats.nbViewsSingle")} description={t("ads.stats.nbViewsHelper")} />}
                  loading={loading}
                  progress={getProgress(stats?.nbViews)}
                  value={stats?.nbViews?.current}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StatCardComponent
                  bgColor={Colors.stats.purple}
                  label={t("ads.stats.uniqueVisitors")}
                  loading={loading}
                  progress={getProgress(stats?.uniqueVisitor)}
                  value={stats?.uniqueVisitor?.current}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StatCardComponent
                  bgColor={Colors.stats.red}
                  label={t("ads.stats.engagementRate")}
                  helper={<StatTooltipComponent title={t("ads.stats.engagementRate")} description={t("ads.stats.engagementRateHelper")} />}
                  extra="%"
                  loading={loading}
                  progress={getProgress(stats?.engagementRate)}
                  progressReverted
                  value={stats?.engagementRate?.current}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <StatCardComponent
                  bgColor={Colors.stats.purple}
                  label={t("ads.stats.qualityRate")}
                  helper={<StatTooltipComponent title={t("dashboard.stats.qualityRate")} description={t("dashboard.stats.qualityRateHelper")} />}
                  extra="%"
                  loading={loading}
                  value={stats?.quality?.current ? Math.round(stats.quality.current * 100) : undefined}
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <StatCardComponent
                  bgColor={Colors.stats.orange}
                  label={t("ads.stats.applications")}
                  loading={loading}
                  value={stats?.applications?.current}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <StatCardComponent
                  bgColor={Colors.stats.blue}
                  label={t("ads.stats.bounceRate")}
                  helper={<StatTooltipComponent title={t("ads.stats.bounceRate")} description={t("ads.stats.bounceRateHelper")} />}
                  extra="%"
                  loading={loading}
                  progress={getProgress(stats?.bounceRate)}
                  value={stats?.bounceRate?.current}
                />
              </Grid>
            </Grid>
          </Box>
          <Box flex={0.5}>
            <AdApplicationsChart
              getChartData={adId ? (year) => adDetailsService.getAdApplicationsStats(adId, year) : undefined}
              subtitle={t("ads.details.statsTab.viewsAmount")}
              title={t("ads.details.statsTab.performance")} />
          </Box>
        </Stack>
      </Container>
    </Stack>
  );
};

export default AdsStatsTab;
