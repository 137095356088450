import { Box, Grid } from "@mui/material";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";
import { CompanyDetailsDetailsModel, companyDetailsService, ManageCompanySocialMedia } from "@store/ai-o/companies/details";
import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import ModalComponent from "@components/modal/Modal.component";
import { FieldValidationType, getFieldError } from "@utils/yup.utils";

interface EditSocialMediaModalProps {
  company: CompanyDetailsDetailsModel;
  handleClose: () => void;
}

const EditSocialMediaModal = (props: EditSocialMediaModalProps) => {
  const { company, handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<ManageCompanySocialMedia>(company);

  const updateData = (name: string) => (value: any) => setData((state) => ({ ...state, [name]: value }));

  const handleSave = () => {
    setLoading(true);
    companyDetailsService
      .updateSocialMediaCompany(company.id, data)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.socialMedia.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const errors = {
    linkedin: getFieldError(data.linkedin, FieldValidationType.WEBSITE),
    instagram: getFieldError(data.instagram, FieldValidationType.WEBSITE),
    youtube: getFieldError(data.youtube, FieldValidationType.WEBSITE),
    twitter: getFieldError(data.twitter, FieldValidationType.WEBSITE),
    facebook: getFieldError(data.facebook, FieldValidationType.WEBSITE),
    tiktok: getFieldError(data.tiktok, FieldValidationType.WEBSITE),
  };

  return (
    <ModalComponent
      title={t("aio.clients.details.information.editTitle")}
      titleLeft
      content={
        <Box p="10px">
          <Grid container columnSpacing="30px" rowSpacing="20px">
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.socialMedia.linkedin")}
                placeholder={t("aio.clients.details.socialMedia.linkedin")}
                value={data.linkedin ?? ""}
                onChange={updateData("linkedin")}
                error={errors.linkedin}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.socialMedia.instagram")}
                placeholder={t("aio.clients.details.socialMedia.instagram")}
                value={data.instagram ?? ""}
                onChange={updateData("instagram")}
                error={errors.instagram}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.socialMedia.youtube")}
                placeholder={t("aio.clients.details.socialMedia.youtube")}
                value={data.youtube ?? ""}
                onChange={updateData("youtube")}
                error={errors.youtube}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.socialMedia.twitter")}
                placeholder={t("aio.clients.details.socialMedia.twitter")}
                value={data.twitter ?? ""}
                onChange={updateData("twitter")}
                error={errors.twitter}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.socialMedia.facebook")}
                placeholder={t("aio.clients.details.socialMedia.facebook")}
                value={data.facebook ?? ""}
                onChange={updateData("facebook")}
                error={errors.facebook}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.socialMedia.tiktok")}
                placeholder={t("aio.clients.details.socialMedia.tiktok")}
                value={data.tiktok ?? ""}
                onChange={updateData("tiktok")}
                error={errors.tiktok}
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          ariaLabel="modal"
          disabled={loading}
          onClick={handleSave}
          title={t("global.save")}
        />
      }
      handleClose={handleClose}
    />
  );
};

export default EditSocialMediaModal;
