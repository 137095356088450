import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { templatesStore } from "./templates.store";

export const getTemplatesDataSource = createRequestDataSource({
  store: templatesStore,
  data$: () => templatesStore.pipe(selectAllEntities()),
  requestKey: "getTemplates",
  dataKey: "templates",
});
