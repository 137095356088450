import ModalComponent from "@components/modal/Modal.component";
import { Stack, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

interface AiApplicationAnalysisModalProps {
  aiAnalysis: string;
  handleCloseModal: () => void;
}

const AiApplicationAnalysisModal = (props: AiApplicationAnalysisModalProps) => {
  const { aiAnalysis, handleCloseModal } = props;
  const { t } = useTranslation();
  return (
    <ModalComponent
      handleClose={handleCloseModal}
      title={t("ads.details.applyTab.aiSorting.aiAnalysisTitle")}
      content={
        <Stack p="20px" width="100%">
          <Typography fontSize={12} fontWeight={400} textAlign="center">
            {aiAnalysis.split(". ").map((line, index, array) => (
              <React.Fragment key={index}>
                {line}
                {index < array.length - 1 && (
                  <>
                    <span>.</span>
                    <br />
                  </>
                )}
              </React.Fragment>
            ))}
          </Typography>
        </Stack>
      }
      maxWidth="xs"
      fullWidth={false}
    />
  );
};
export default AiApplicationAnalysisModal;