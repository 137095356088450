import { sessionStore } from "@store/session/session.store";
import { catchError, from, map, Observable, tap } from "rxjs";
import { Buffer } from "buffer";
import { AxiosError, AxiosResponse } from "axios";

import {
  AuthenticationResponse,
  CustomAuthentication,
  SessionUser,
  UpdatePassword
} from "@store/session/session.model";
import { getUnipileAccountStatusDataSource, getUserDataSource } from "@store/session/session.requests";
import { ManageUserModel, manageUserToFormData, UserModel } from "@store/users";

import { SelectItem } from "@components/input/Select.component";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";
import { Pages, RoutesUnAuth } from "@utils/routes.utils";
import { AffiliateDetailsDetailsModel } from "@store/entities/afiliates/details";
import { FeatureCreditsTypeEnum } from "@store/subscriptions";
import { AFFILIATES_FILTER_STORAGE_KEY, TOKEN_STORAGE_KEY } from "@constants/localStorage.constant";
import { UnipileAccountConnectionStatusEnum } from "@store/unipile";
import { OnboardingTypeEnum } from "@store/common/onboarding.model";

export class SessionService {
  store = sessionStore;

  setAffiliateIds = (affiliateIds: SelectItem[]) =>
    this.store.update((state) => ({
      ...state,
      affiliateIds,
    }));

  setAccessToken = (accessToken: string) => {
    localStorage.setItem(TOKEN_STORAGE_KEY, Buffer.from(accessToken).toString("base64"));
  };

  subtractCredit = (type: FeatureCreditsTypeEnum) => {
    this.store.update((state) => {
      switch (type) {
        case FeatureCreditsTypeEnum.POTENTIAL_PROFILES:
          if (state.user) state.user.featureCredits.potentialProfileSearch -= 1;
          break;
        case FeatureCreditsTypeEnum.LINKEDIN_POST:
          if (state.user) state.user.featureCredits.linkedinPosting -= 1;
          break;
        case FeatureCreditsTypeEnum.APPLICATIONS:
          if (state.user) state.user.featureCredits.candidateDatabase -= 1;
          break;
      }
      return state;
    });
  };

  switchToBuyerMode = () =>
    this.store.update((state) => ({
      ...state,
      buyerMode: !state.buyerMode,
    }));

  switchToClientView = (clientViewId: string) => {
    return from(APIAxios(APIRoutes.GETAffiliateDetails(clientViewId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AffiliateDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((affiliateDetails) => {
        this.store.update((state) => ({
          ...state,
          clientViewClient: affiliateDetails,
        }));
      })
    );
  };

  switchToAdminMode = () => {
    this.store.update((state) => ({
      ...state,
      clientViewClient: undefined,
    }));
  };

  login = (data: CustomAuthentication): Observable<AuthenticationResponse> => {
    return from(APIAxios({ ...APIRoutes.POSTLogin(), data })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AuthenticationResponse>) => {
        return response.data;
      }),
      tap(async (authenticationResponse) => {
        this.store.update(
          (state) => ({
            ...state,
            ...authenticationResponse,
            unipileAccountStatus: !authenticationResponse.user.hasUnipileAccount
              ? UnipileAccountConnectionStatusEnum.DO_NOT_EXIST
              : undefined,
          }),
          getUserDataSource.setSuccess()
        );
        this.setAccessToken(authenticationResponse.accessToken);
      }),
      getUserDataSource.trackRequestStatus()
    );
  };

  loginWithLinkedin = (code: string): Observable<AuthenticationResponse> => {
    return from(
      APIAxios({ ...APIRoutes.POSTLoginWithLinkedin(), data: { code, redirectURI: `${window.location.origin}/${RoutesUnAuth.LOGIN}` } })
    ).pipe(
      catchError((err: AxiosError) => {
        if ((err.response as any)?.data?.message === "NO_LINKEDIN_USER_FOUND") {
          throw new SnackError("NO_LINKEDIN_USER_FOUND", "error");
        }
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AuthenticationResponse>) => {
        return response.data;
      }),
      tap(async (authenticationResponse) => {
        this.store.update(
          (state) => ({
            ...state,
            ...authenticationResponse,
            unipileAccountStatus: !authenticationResponse.user.hasUnipileAccount
              ? UnipileAccountConnectionStatusEnum.DO_NOT_EXIST
              : undefined,
          }),
          getUserDataSource.setSuccess()
        );
        this.setAccessToken(authenticationResponse.accessToken);
      }),
      getUserDataSource.trackRequestStatus()
    );
  };

  updateUser = (user: ManageUserModel): Observable<UserModel> => {
    return from(APIAxios({ ...APIRoutes.PUTUser(), data: manageUserToFormData(user, undefined, true) })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<SessionUser>) => {
        return response.data;
      }),
      tap((updatedUser) => {
        this.store.update((state) => ({
          ...state,
          user: updatedUser,
        }));
      })
    );
  };

  syncLinkedinUser = (code: string): Observable<AuthenticationResponse> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTSyncLinkedin(),
        data: { code, redirectURI: `${window.location.origin}/${Pages.LINKEDIN}` },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<AuthenticationResponse>) => {
        return response.data;
      }),
      tap(async (authenticationResponse) => {
        this.store.update((state) => ({
          ...state,
          ...authenticationResponse,
        }));
        this.setAccessToken(authenticationResponse.accessToken);
      })
    );
  };

  fetchCurrentUser = (): Observable<SessionUser> => {
    return from(APIAxios(APIRoutes.GETCurrentUser())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<SessionUser>) => {
        return response.data;
      }),
      tap(async (user) => {
        this.store.update(
          (state) => ({
            ...state,
            user,
            unipileAccountStatus: !user.hasUnipileAccount ? UnipileAccountConnectionStatusEnum.DO_NOT_EXIST : undefined,
          }),
          getUserDataSource.setSuccess()
        );
      }),
      getUserDataSource.trackRequestStatus()
    );
  };

  checkUnipileAccountStatus = (): Observable<{ status: UnipileAccountConnectionStatusEnum }> => {
    return from(APIAxios(APIRoutes.GETUnipileAccountStatus())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<{ status: UnipileAccountConnectionStatusEnum }>) => {
        return response.data;
      }),
      tap((response) => {
        this.store.update(
          (state) => ({
            ...state,
            unipileAccountStatus: response.status,
          }),
          getUnipileAccountStatusDataSource.setSuccess(),
        );
      }),
      getUnipileAccountStatusDataSource.trackRequestStatus(),
    );
  };

  updateUserPassword = (password: UpdatePassword): Observable<AxiosResponse> => {
    return from(APIAxios({ ...APIRoutes.PUTUserPassword(), data: password })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  forgotPassword = (email: string): Observable<AxiosResponse> => {
    return from(APIAxios({ ...APIRoutes.POSTLostPassword(), data: { email } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  resetPassword = (password: string, token: string): Observable<AxiosResponse> => {
    return from(APIAxios({ ...APIRoutes.POSTResetPassword(), data: { password, token } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      })
    );
  };

  logout = async (notif?: { msg: string; variant: "error" | "success" }) => {
    await window.localStorage.removeItem(TOKEN_STORAGE_KEY);
    await window.localStorage.removeItem(AFFILIATES_FILTER_STORAGE_KEY);
    window.location.href = `/${RoutesUnAuth.LOGIN}${notif?.msg ? `?msg=${notif.msg}&variant=${notif.variant}` : ""}`;
  };

  acceptRGPDAndCGU = () => {
    return from(APIAxios(APIRoutes.PUTAcceptRGPDAndCGU())).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<SessionUser>) => {
        return response.data;
      }),
      tap((updatedUser) => {
        this.store.update((state) => ({
          ...state,
          user: updatedUser,
        }));
      })
    );
  };

  saveOnboarding = (onboardingType: OnboardingTypeEnum) => {
    return from(APIAxios({ ...APIRoutes.POSTSaveOnboarding(), data: { onboardingType } })).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<SessionUser>) => {
        return response.data;
      }),
      tap((updatedUser) => {
        this.store.update((state) => ({
          ...state,
          user: updatedUser,
        }));
      })
    );
  };
}

export const sessionService = new SessionService();
