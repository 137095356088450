import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Box, FormHelperText, Stack, Typography } from "@mui/material";
import { emitOnce } from "@ngneat/elf";
import { convertToRaw, EditorState } from "draft-js";
import { finalize } from "rxjs";
import "draft-js/dist/Draft.css";

import {
  BannerBackgroundColorPalette,
  BannerFontColorPalette,
  bannersService,
  bannerToManageBanner,
  ManageBanner
} from "@store/banners";
import { AffiliateModel, affiliatesService } from "@store/entities/afiliates";

import { Colors } from "@constants/colors.constant";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

import ModalComponent from "@components/modal/Modal.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import AIOButtonComponent from "@components/Button.component";
import AIOTextEditor from "@components/input/AIOTextEditor.component";
import ConfirmModal from "@components/modal/Confirm.modal";

export interface BannerModalProps {
  isCreation?: boolean;
}

const BannerModal = (props: BannerModalProps) => {
  const { isCreation } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { bannerId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [banner, setBanner] = useState<ManageBanner>({
    bannerColor: BannerBackgroundColorPalette[1],
    editorContent: EditorState.createEmpty(),
  });

  const [affiliates, setAffiliates] = useState<AffiliateModel[]>([]);

  const [openDelete, setOpenDelete] = useState(false);

  const updateBanner = (name: string) => (value: any) => setBanner((state) => ({ ...state, [name]: value }));

  useEffect(() => {
    emitOnce(() => {
      affiliatesService.resetStore();
      affiliatesService.getAffiliates().subscribe((affiliates) => setAffiliates(affiliates.data));
    });
  }, []);

  useEffect(() => {
    if (!isCreation && !!bannerId)
      bannersService.getBanner(bannerId).subscribe({
        next: (b) => setBanner(bannerToManageBanner(b)),
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  }, [bannerId, enqueueSnackbar, isCreation]);

  const handleClose = () => navigate("..");

  const handleManageBanner = () => {
    if (isCreation) {
      setLoading(true);
      bannersService
        .createBanner({
          ...banner,
          content: JSON.stringify(convertToRaw(banner.editorContent.getCurrentContent())),
        })
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("adminParameters.banners.success.add"), { variant: "success" });
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    } else {
      if (!banner?.id) return;

      setLoading(true);
      bannersService
        .updateBanner(banner.id, {
          ...banner,
          content: JSON.stringify(convertToRaw(banner.editorContent.getCurrentContent())),
        })
        .pipe(finalize(() => setLoading(false)))
        .subscribe({
          next: () => {
            enqueueSnackbar(t("adminParameters.banners.success.modify"), { variant: "success" });
            handleClose();
          },
          error: (err) => enqueueSnackbar(err.text, err.options),
        });
    }
  };

  const handleDelete = () => {
    if (!banner?.id) return;

    setLoading(true);
    bannersService
      .deleteBanner(banner.id)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        error: (err) => enqueueSnackbar(err.text, err.options),
        next: () => {
          enqueueSnackbar(t("adminParameters.banners.delete.success"), { variant: "success" });
          handleClose();
        },
      });
  };

  if (!isCreation && (!bannerId || !banner?.id)) return null;

  const errors = {
    content: getFieldError(banner.editorContent.getCurrentContent().getPlainText(), FieldValidationType.REQUIRED_STRING),
    bannerColor: getFieldError(banner.bannerColor, FieldValidationType.REQUIRED_STRING),
    title: getFieldError(banner.title, FieldValidationType.REQUIRED_STRING),
    affiliates: getFieldError(banner.affiliates, FieldValidationType.REQUIRED_ARRAY),
  };

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        titleLeft
        title={!isCreation ? t("adminParameters.banners.modifyBannerLong") : t("adminParameters.banners.addBannerLong")}
        content={
          <Stack py="15px" spacing={"30px"}>
            <Stack>
              <Typography fontSize="12px" mb="10px" color={Colors.secondaryText}>
                {t("adminParameters.banners.yourDescription")} *
              </Typography>
              <AIOTextEditor
                colorPalette={BannerFontColorPalette}
                editorState={banner.editorContent}
                onContentChange={updateBanner("editorContent")}
                backgroundColor={banner.bannerColor}
              />
              {!!errors.content && (
                <FormHelperText error={!!errors.content} sx={{ ml: "14px" }}>
                  {errors.content}
                </FormHelperText>
              )}
            </Stack>
            <Stack spacing={"20px"}>
              <Stack direction={"row"} width={"100%"}>
                <Box width="50%" pr="15px">
                  <Stack width={"100%"}>
                    <Typography fontSize="12px" color={Colors.secondaryText}>
                      {t("adminParameters.banners.bannerColorLabel")} *
                    </Typography>
                    <SelectWithSearchComponent
                      items={BannerBackgroundColorPalette.map((c) => ({
                        label: c,
                        value: c,
                        icon: <Box height="15px" width="15px" borderRadius="50%" bgcolor={c} />,
                      }))}
                      value={
                        banner.bannerColor
                          ? {
                              label: banner.bannerColor,
                              value: banner.bannerColor,
                              icon: <Box height="15px" width="15px" borderRadius="50%" bgcolor={banner.bannerColor} />,
                            }
                          : ""
                      }
                      handleChange={(value) => {
                        if (value) updateBanner("bannerColor")(value.value);
                      }}
                      error={errors.bannerColor}
                    />
                  </Stack>
                </Box>
                <Box width="50%" pl="15px">
                  <AIOTextfieldComponent
                    required
                    fullWidth
                    value={banner.title ?? ""}
                    onChange={updateBanner("title")}
                    title={t("adminParameters.banners.title")}
                    placeholder={t("adminParameters.banners.title")}
                    error={errors.title}
                  />
                </Box>
              </Stack>
              <Stack direction={"row"} width={"100%"}>
                <Box width="50%" pr="15px">
                  <AIOTextfieldComponent
                    fullWidth
                    value={banner.buttonLabel ?? ""}
                    onChange={updateBanner("buttonLabel")}
                    title={t("adminParameters.banners.buttonLabelLabel")}
                    placeholder={t("adminParameters.banners.buttonLabel")}
                  />
                </Box>
                <Box width="50%" pl="15px">
                  <AIOTextfieldComponent
                    fullWidth
                    value={banner.buttonURL ?? ""}
                    onChange={updateBanner("buttonURL")}
                    title={t("adminParameters.banners.buttonURLLabel")}
                    placeholder={t("adminParameters.banners.buttonURL")}
                  />
                </Box>
              </Stack>
              <Stack spacing={"15px"}>
                <Typography fontWeight={600} fontSize="18px">
                  {t("adminParameters.banners.companies")}
                </Typography>
                <Box width="50%" pr="15px">
                  <MultiSelectWithSearchComponent
                    startIcon={<img alt="" src="/images/icon_select_company.svg" />}
                    placeholder={t("adminParameters.banners.noCompany")}
                    handleChange={updateBanner("affiliates")}
                    multiSelectedLabel={(count) => t("global.companiesCount", { count })}
                    handleGroupBy={(option) => {
                      if (!option.data) return "";
                      return option.data.groupName;
                    }}
                    items={affiliates.map((affiliate) => ({
                      label: affiliate.name,
                      value: affiliate.id,
                      data: { groupName: affiliate.client.name },
                    }))}
                    values={banner.affiliates}
                  />
                  {!!errors.affiliates && (
                    <FormHelperText error={!!errors.affiliates} sx={{ ml: "14px" }}>
                      {errors.affiliates}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Stack>
        }
        maxWidth="md"
        paddingButton="10px 0px 40px 0px"
        actions={
          <>
            {!isCreation && (
              <AIOButtonComponent
                title={t("global.delete")}
                ariaLabel="modal"
                disabled={loading || checkIfErrors(errors)}
                onClick={() => setOpenDelete(true)}
                color="primary"
              />
            )}
            <AIOButtonComponent
              title={t(`global.${isCreation ? "add" : "modify"}`)}
              ariaLabel="modal"
              disabled={loading || checkIfErrors(errors)}
              onClick={handleManageBanner}
              variant="contained"
              color="secondary"
            />
          </>
        }
      />
      {openDelete && (
        <ConfirmModal
          handleClose={() => setOpenDelete(false)}
          handleConfirm={handleDelete}
          confirmMsg={t("adminParameters.banners.delete.message")}
          modalTitle={t("adminParameters.banners.delete.title")}
        />
      )}
    </>
  );
};

export default BannerModal;
