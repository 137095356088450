import React, { Dispatch, SetStateAction, useRef, useState } from "react";
import { Checkbox, FormControlLabel, IconButton, Stack, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

import { ManageEntityParametersModel } from "@store/entities/details";

import { Colors } from "@constants/colors.constant";
import { ManagePlanMediaModel } from "@store/common/planMedia.model";
import { filesService } from "@store/files";
import { affiliateDetailsQuery, affiliateDetailsService } from "@store/entities/afiliates/details";
import { useObservable } from "@ngneat/react-rxjs";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";

interface PlanMediaConfigComponentProps {
  isActivated?: boolean;
  updateParameters: Dispatch<SetStateAction<ManageEntityParametersModel>>;
  planMedia?: ManagePlanMediaModel;
}

const PlanMediaConfigComponent = (props: PlanMediaConfigComponentProps) => {
  const { isActivated = false, updateParameters, planMedia } = props;

  const { enqueueSnackbar } = useSnackbar();
console.log(planMedia);
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const [selectedFile, setSelectedFile] = useState<File | undefined>();
  const [affiliate] = useObservable(affiliateDetailsQuery.affiliateDetails$);

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files?.length) {
      setSelectedFile(files[0]);
      updateParameters((state) => ({
        ...state,
        planMedia: {
          ...state.planMedia,
          file: files[0],
        },
      }));
      e.target.value = "";
    }
  };

  if (!affiliate) {
    return null;
  }

  return (
    <Stack flex={0.5} width="100%" p="25px" bgcolor={Colors.background} borderRadius="15px" spacing={2}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" width="calc(100% + 10px)">
        <Typography fontSize={16} fontWeight="700">
          {t("clients.details.parametersTab.planMedia.title")}
        </Typography>
        <Switch checked={isActivated} onChange={(_, checked) => updateParameters((state) => ({ ...state, planMediaActivated: checked }))} />
      </Stack>
      {isActivated && (
        <Stack>
          <Stack spacing="40px">
            <FormControlLabel
              onChange={(evt, checked) => {
                updateParameters((state) => ({ ...state, planMedia: { ...state.planMedia, generateTimelineAutomatically: checked } }));
              }}
              control={<Checkbox size="small" />}
              checked={planMedia?.generateTimelineAutomatically}
              label={
                <Typography ml="10px" mt="1px" fontSize="12px" lineHeight={1.25}>
                  {t("clients.details.parametersTab.planMedia.generateTimelineAutomatically")}
                </Typography>
              }
            />
            <Stack spacing={"10px"}>
              <Typography>{t("clients.details.parametersTab.planMedia.addFile")}</Typography>
              <Stack alignItems="center">
                <input
                  ref={inputRef}
                  hidden
                  onChange={handleSelectFile}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  type="file"
                />
                <IconButton aria-label="avatar-rounded" onClick={() => inputRef.current?.click()}>
                  <img width="20px" alt="upload" src="/images/download_media.svg" />
                </IconButton>
              </Stack>
              {(planMedia?.fileKey || !!planMedia?.file) && (
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography fontSize="14px" fontWeight={300}>
                    {planMedia.file?.name || planMedia.fileName}
                  </Typography>
                  <Stack direction="row" spacing="6px">
                    <IconButton
                      onClick={() => {
                        updateParameters((state) => ({ ...state, planMedia: { ...state.planMedia, fileKey: undefined, fileName: undefined, file: undefined } }));
                      }}>
                      <img alt="delete" src="/images/icon_delete.svg" />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        if (selectedFile) {
                          filesService.downloadDocument(selectedFile, selectedFile.name);
                        } else {
                          affiliateDetailsService.getAffiliatePlanMediaFile(affiliate.id).subscribe({
                            next: (fileURL) => fileURL && filesService.openUrlInNewTab(fileURL),
                            error: (err) => enqueueSnackbar(err.text, err.options),
                          });
                        }
                      }}>
                      <img alt="download" src="/images/icon_download_file.svg" />
                    </IconButton>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Stack>
              <Typography>{t("clients.details.parametersTab.planMedia.customPromptTitle")}</Typography>
              <AIOTextfieldComponent
                title={t("clients.details.parametersTab.planMedia.customPromptLabel")}
                placeholder={t("clients.details.parametersTab.planMedia.customPromptPlaceholder")}
                multiline
                sx={{ background: "white" }}
                onChange={(value) =>
                  updateParameters((state) => ({ ...state, planMedia: { ...state.planMedia, customPrompt: value } }))
                }
                value={planMedia?.customPrompt}
              />
            </Stack>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default PlanMediaConfigComponent;
