import styled from "@emotion/styled";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { BannerModel, bannersService } from "@store/banners";
import { useEffect, useState } from "react";
import Banner from "./Banner.component";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery } from "@store/session";

const BannersContainer = styled(Stack)((props: { mobile: boolean }) => ({
  width: "100%",
  position: "fixed",
  top: props.mobile ? undefined : 0,
  bottom: props.mobile ? 0 : undefined,
  left: 0,
  zIndex: 1400,
}));

const Banners = () => {
  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [banners, setBanners] = useState<BannerModel[]>([]);

  useEffect(() => {
    if (!clientViewClient) {
      bannersService.getBannerForUser().subscribe({ next: setBanners });
    } else {
      bannersService.getBanners({affiliates: [{label: '', value: clientViewClient.id}]}).subscribe({ next: setBanners });
    }
  }, [clientViewClient]);

  return (
    <BannersContainer mobile={breakpointDownMD}>
      {banners.map((banner) => (
        <Banner key={banner.id} banner={banner} handleClose={(id: string) => setBanners((prv) => prv.filter((x) => x.id !== id))} />
      ))}
    </BannersContainer>
  );
};

export default Banners;
