import { select } from "@ngneat/elf";
import { selectEntitiesCount } from "@ngneat/elf-entities";
import { personaeStore } from "@store/personae/personae.store";
import { getPersonaeDataSource, getPersonaeQuizDataSource } from "@store/personae/personae.requests";

export class PersonaeQuery {
  store = personaeStore;

  personaeQuiz$ = getPersonaeQuizDataSource.data$();

  personae$ = getPersonaeDataSource.data$();
  personaeCount$ = this.store.pipe(selectEntitiesCount());

  filters$ = this.store.pipe(select((state) => state.filters));
  sort$ = this.store.pipe(select((state) => state.sort));
}

export const personaeQuery = new PersonaeQuery();
