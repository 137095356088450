import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { CompanyDetailsDetailsModel, companyDetailsService, ManageCompanyInformation } from "@store/ai-o/companies/details";

import { Colors } from "@constants/colors.constant";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import DatePickerComponent from "@components/input/DatePicker.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";

interface EditInformationModalProps {
  company: CompanyDetailsDetailsModel;
  handleClose: () => void;
}

const EditInformationModal = (props: EditInformationModalProps) => {
  const { company, handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState<ManageCompanyInformation>(company);

  const updateData = (name: string) => (value: any) => setData((state) => ({ ...state, [name]: value }));

  const handleSave = () => {
    setLoading(true);
    companyDetailsService
      .updateInformationCompany(company.id, data)
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.information.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  const errors = {
    location: getFieldError(data.location, FieldValidationType.REQUIRED_STRING),
    website: getFieldError(data.website, FieldValidationType.WEBSITE),
  };

  return (
    <ModalComponent
      title={t("aio.clients.details.information.editTitle")}
      titleLeft
      content={
        <Box p="10px">
          <Grid container columnSpacing="30px" rowSpacing="20px">
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                required
                title={t("aio.clients.details.information.fields.headOffice")}
                placeholder={t("aio.clients.details.information.fields.headOffice")}
                error={errors.location}
                value={data.location ?? ""}
                onChange={updateData("location")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <DatePickerComponent
                required
                title={t("aio.clients.details.information.fields.createdAt")}
                placeholder={t("aio.clients.details.information.fields.createdAt")}
                value={data.creationDate}
                handleChange={updateData("creationDate")}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.information.fields.collaborators")}
                placeholder={t("aio.clients.details.information.fields.collaborators")}
                pattern={new RegExp(/^\d*$/)}
                value={data.nbCollaborates?.toString()}
                onChange={(v) => updateData("nbCollaborates")(+v)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.information.fields.turnover")}
                placeholder={t("aio.clients.details.information.fields.turnover")}
                inputNumberFormatSeparator
                value={data.turnover?.toString() ?? ""}
                onChange={(v) => updateData("turnover")(+v)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack>
                <Typography fontSize="12px" color={Colors.secondaryText}>
                  {t("aio.clients.details.information.fields.telework")} *
                </Typography>
                <SelectWithSearchComponent
                  placeholder={t("global.select")}
                  items={[
                    { label: t("global.yes"), value: true },
                    { label: t("global.no"), value: false },
                  ]}
                  value={{ label: data.telework ? t("global.yes") : t("global.no"), value: data.telework }}
                  handleChange={(i) => updateData("telework")(!!i?.value)}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <AIOTextfieldComponent
                title={t("aio.clients.details.information.fields.website")}
                placeholder={t("aio.clients.details.information.fields.website")}
                error={errors.website}
                value={data.website ?? ""}
                onChange={updateData("website")}
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          ariaLabel="modal"
          disabled={loading || checkIfErrors(errors)}
          onClick={handleSave}
          title={t("global.save")}
        />
      }
      handleClose={handleClose}
    />
  );
};

export default EditInformationModal;
