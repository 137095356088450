import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Stack, Typography } from "@mui/material";

import { subscriptions, subscriptionsQuery, SubscriptionTypeEnum } from "@store/subscriptions";

import { Colors } from "@constants/colors.constant";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import SeeSubscriptionsModal from "@components/subscriptions/modal/SeeSubscriptions.modal";

interface WrongSubscriptionModalProps {
  description: string;
  handleClose: () => void;
  subscriptionsRequired: SubscriptionTypeEnum[];
  title: string;
}

const WrongSubscriptionModal = (props: WrongSubscriptionModalProps) => {
  const { description, handleClose, subscriptionsRequired, title } = props;

  const { t } = useTranslation();

  const [openSeeSubscriptionsModal, setOpenSeeSubscriptionsModal] = useState(false);

  return (
    <>
      <ModalComponent
        handleClose={handleClose}
        title={title}
        content={
          <Stack py="10px">
            <Typography textAlign="center" whiteSpace="pre-line" color={Colors.secondaryText} lineHeight="1.25" mt="6px">
              {description}
            </Typography>
          </Stack>
        }
        actions={
          <AIOButtonComponent
            title={t("subscriptions.seeSubscriptions")}
            variant="contained"
            ariaLabel="modal"
            onClick={() => setOpenSeeSubscriptionsModal(true)}
          />
        }
        maxWidth="xs"
      />
      {openSeeSubscriptionsModal && (
        <SeeSubscriptionsModal
          subscriptions={subscriptions.filter(
            (s) =>
              subscriptionsRequired.includes(s.type) && (s.type !== SubscriptionTypeEnum.PROFESSIONAL || !subscriptionsQuery.sessionUserSubscriptionOngoing)
          )}
          handleClose={() => setOpenSeeSubscriptionsModal(false)}
        />
      )}
    </>
  );
};

export default WrongSubscriptionModal;