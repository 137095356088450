import ModalComponent from "@components/modal/Modal.component";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import AIOButtonComponent from "@components/Button.component";
import { useTranslation } from "react-i18next";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import { Dispatch, SetStateAction, useState } from "react";
import { FieldValidationType, getFieldError } from "@utils/yup.utils";
import { SelectItem } from "@components/input/Select.component";
import { ActionTimeline, TimelineEmailTemplateEnum, TimelineEmailTemplateEnumFunctions } from "@store/entities/entities.model";

interface AddOrEditActionModalProps {
  actions: ActionTimeline[];
  actionToEdit: ActionTimeline | boolean;
  handleCloseModal: () => void;
  setActions: Dispatch<SetStateAction<ActionTimeline[]>>;
  updateTimeline: (actions: ActionTimeline[]) => void;
}

const AddOrEditAction = (props: AddOrEditActionModalProps) => {
  const { setActions, actions, actionToEdit, handleCloseModal, updateTimeline } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [newActionTitle, setNewActionTitle] = useState(typeof actionToEdit === "boolean" ? "" : actionToEdit.name);
  const [newActionStrategy, setNewActionStrategy] = useState(typeof actionToEdit === "boolean" ? "" : actionToEdit.strategy);
  const [newActionDayNumber, setNewActionDayNumber] = useState(typeof actionToEdit === "boolean" ? 1 : actionToEdit.j);

  const daysSelectItem = Array.from(Array(30)).map((n, i) => ({
    label: t("subscriptions.dayAbbreviation") + "+" + (i + 1),
    value: i + 1,
  }));

  Array.from(Array(30)).map((n, i) => ({
    label: t("subscriptions.dayAbbreviation") + "+" + (i + 1),
    value: i + 1,
  }));

  const [selectedEmailTemplateName, setSelectedEmailTemplateName] = useState<SelectItem>(
    typeof actionToEdit === "boolean"
      ? TimelineEmailTemplateEnumFunctions.selectItems().find((item) => item.value === TimelineEmailTemplateEnum.NO_TEMPLATE)!
      : TimelineEmailTemplateEnumFunctions.selectItems().find((item) => item.value === actionToEdit.emailTemplateName)!
  );

  const handleActionTitleChange = (value: string) => {
    setNewActionTitle(value);
  };

  const handleActionStrategyChange = (value: string) => {
    setNewActionStrategy(value);
  };

  const handleChangeDayNumber = (newDayNumber: number | undefined) => {
    if (newDayNumber) setNewActionDayNumber(newDayNumber);
  };

  const handleChangeTemplateEmail = (emailTemplateName: SelectItem | undefined) => {
    if (emailTemplateName) setSelectedEmailTemplateName(emailTemplateName);
  };

  const reorderActionsByDayNumber = (actionsTimeline: ActionTimeline[]): ActionTimeline[] => {
    return actionsTimeline.sort((a, b) => (a.j || 1) - (b.j || 1));
  };

  const handleConfirm = () => {
    if (errors.actionTitle || errors.doublon) return;
    setActions((previousActions) => {
      let tempActions = [...previousActions];
      if (typeof actionToEdit !== "boolean") {
        tempActions = previousActions.map((action) => {
          if (action.name === actionToEdit.name) {
            action.j = newActionDayNumber;
            action.name = newActionTitle;
            action.strategy = newActionStrategy;
            action.emailTemplateName = selectedEmailTemplateName.value;
          }
          return {
            name: action.name,
            j: action.j,
            strategy: action.strategy,
            isPassed: action.isPassed,
            emailTemplateName: action.emailTemplateName,
          };
        });
      } else {
        tempActions = [
          ...tempActions,
          {
            name: newActionTitle,
            j: newActionDayNumber,
            strategy: newActionStrategy,
            isPassed: false,
            emailTemplateName: selectedEmailTemplateName.value,
          },
        ];
      }
      tempActions = reorderActionsByDayNumber(tempActions);
      tempActions.forEach((action) => {
        if (!action.emailTemplateName) action.emailTemplateName = TimelineEmailTemplateEnum.NO_TEMPLATE;
      });
      updateTimeline(tempActions);
      return tempActions;
    });

    handleCloseModal();
  };

  const errors = {
    actionTitle: getFieldError(newActionTitle, FieldValidationType.REQUIRED_STRING),
    doublon:
      typeof actionToEdit === "boolean" && actions?.some((a) => a.name.trim() === newActionTitle.trim()) ? t("subscriptions.doublonName") : false,
  };

  return (
    <ModalComponent
      handleClose={handleCloseModal}
      title={t(actionToEdit ? "subscriptions.editAction" : "subscriptions.addAction")}
      titleLeft
      content={
        <Stack spacing={3} py="30px" width={breakpointDownSM ? "100%" : "300px"}>
          <AIOTextfieldComponent
            required
            title={t("subscriptions.actionName")}
            placeholder={t("subscriptions.actionName")}
            onChange={handleActionTitleChange}
            value={newActionTitle}
            multiline
            minRows={1}
            error={errors.actionTitle || errors.doublon}
          />
          <AIOTextfieldComponent
            title={t("subscriptions.strategy")}
            placeholder={t("subscriptions.strategy")}
            onChange={handleActionStrategyChange}
            value={newActionStrategy}
            multiline
            minRows={3}
          />
          <SelectWithSearchComponent
            placeholder={t("global.dateLabel")}
            items={daysSelectItem}
            handleChange={(value) => handleChangeDayNumber(value?.value)}
            value={daysSelectItem[newActionDayNumber - 1]}
          />
          <SelectWithSearchComponent
            placeholder={t("subscriptions.timelineEmail.selectTemplate")}
            items={TimelineEmailTemplateEnumFunctions.selectItems()}
            handleChange={(value) => handleChangeTemplateEmail(value)}
            value={selectedEmailTemplateName}
          />
        </Stack>
      }
      maxWidth={false}
      fullWidth={false}
      paddingButton="10px 0px 40px 0px"
      actions={<AIOButtonComponent title={t("global.confirm")} variant="contained" ariaLabel="modal" onClick={handleConfirm} />}
    />
  );
};

export default AddOrEditAction;
