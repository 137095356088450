import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { select } from "@ngneat/elf";

import { adsStore } from "@store/ads/ads.store";

export const getAdsDataSource = createRequestDataSource({
  store: adsStore,
  data$: () => adsStore.pipe(selectAllEntities()),
  requestKey: "getAds",
  dataKey: "ads",
});

export const getPaginatedAdsDataSource = createRequestDataSource({
  store: adsStore,
  data$: () => adsStore.pipe(
    select((state) =>
      Object.values(state.entities).filter((ad) =>
        state.pagination.pages[state.pagination.currentPage].map((x) => x.toString()).includes(ad.id.toString())
      )
    ),
  ),
  requestKey: "getPaginatedAds",
  dataKey: "ads",
});

export const getAdsStatsDataSource = createRequestDataSource({
  store: adsStore,
  data$: () => adsStore.pipe(select((state) => state.stats)),
  requestKey: "getAdsStats",
  dataKey: "stats",
});