import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { finalize } from "rxjs";

import { AdDetailsDetailsModel, adDetailsQuery, adDetailsService } from "@store/ads/details";
import { jobBoardsQuery } from "@store/jobBoards";

import { SelectItem } from "@components/input/Select.component";

import AIOButtonComponent from "@components/Button.component";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";
import ModalComponent from "@components/modal/Modal.component";

interface ManageDiffusionListModalProps {
  ad: AdDetailsDetailsModel;
  handleClose: () => void;
}

const ManageDiffusionListModal = (props: ManageDiffusionListModalProps) => {
  const { handleClose, ad } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { adId } = useParams();

  const [loading, setLoading] = useState(false);

  const [jobBoards] = useState<SelectItem[]>(
    (ad.affiliate.prefilteredJobBoards?.length ? ad.affiliate.prefilteredJobBoards : jobBoardsQuery.jobBoards).map((j) => ({
      label: j.name,
      value: j.id,
    }))
  );
  const [selectedJobBoards, setSelectedJobBoards] = useState<SelectItem[]>(
    adDetailsQuery.jobBoards.map((j) => ({ label: j.name, value: j.id }))
  );

  const handleSave = () => {
    if (!adId) return;
    setLoading(true);
    adDetailsService.updateAd(adId, {jobBoards: selectedJobBoards}).pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("ads.details.detailTab.diffusionUpdated"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <ModalComponent
      maxWidth="xs"
      titleLeft
      title={t("ads.details.detailTab.manageDiffusionList")}
      handleClose={handleClose}
      content={(
        <Stack px="50px" spacing={3} py="25px" alignItems="center">
          <MultiSelectWithSearchComponent
            placeholder={t("ads.details.detailTab.noDiffusion")}
            handleChange={setSelectedJobBoards}
            multiSelectedLabel={(count) => t("ads.details.detailTab.jobBoards", { count })}
            items={jobBoards}
            values={selectedJobBoards} />
        </Stack>
      )}
      actions={(
        <AIOButtonComponent
          title={t("global.modify")}
          ariaLabel="modal"
          disabled={loading}
          onClick={handleSave}
          variant="contained"
          color="secondary" />
      )} />
  );
};

export default ManageDiffusionListModal;
