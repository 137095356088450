import { SelectItem } from "@components/input/Select.component";
import I18nUtils from "@utils/i18n.utils";

export enum StripeProductTypeEnum {
  NONE = "NONE",
  SEQUENCE = "SEQUENCE",
  JOB_BOARD = "JOB_BOARD",
  BOOST_ACTION = "BOOST_ACTION",
  SCHOOL = "SCHOOL",
}

export namespace StripeProductTypeFunctions {
  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("adminParameters.stripeProducts.type.NONE"), value: StripeProductTypeEnum.NONE },
    { label: I18nUtils.t("adminParameters.stripeProducts.type.SEQUENCE"), value: StripeProductTypeEnum.SEQUENCE },
    { label: I18nUtils.t("adminParameters.stripeProducts.type.SCHOOL"), value: StripeProductTypeEnum.SCHOOL },
    { label: I18nUtils.t("adminParameters.stripeProducts.type.JOB_BOARD"), value: StripeProductTypeEnum.JOB_BOARD },
    { label: I18nUtils.t("adminParameters.stripeProducts.type.BOOST_ACTION"), value: StripeProductTypeEnum.BOOST_ACTION },
  ];
}

export interface AddStripeProductModel {
  stripeProductId: string;
  name: string;
  information?: string;
  price: number;
  picture?: string;
  productType: SelectItem;
  type?: string;
  link?: string;
  sellsyProviderId?: string;
  providerPrice?: number;
  jobboards?: SelectItem[];
}

export interface StripeProductsFilters {
  search?: string;
}

export interface StripeProductModel {
  id: string;
  name: string;
  information?: string;
  price: number;
  picture?: string;
}