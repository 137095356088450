import { catchError, from, map, Observable, tap } from "rxjs";
import { AxiosError, AxiosResponse } from "axios";

import APIAxios, { APIRoutes } from "@api/axios.api";
import SnackError from "@utils/error.utils";

import { PaginatedData } from "@utils/infinitescroll.utils";

import { clientDetailsStore } from "@store/entities/clients/details/clientDetails.store";
import {
  getClientCommentsDataSource,
  getClientDetailsDataSource
} from "@store/entities/clients/details/clientDetails.requests";
import {
  ClientDetailsDetailsModel,
  ManageClientDetailsModel,
  manageClientDetailsToFormData,
  ManageClientParametersModel
} from "@store/entities/clients/details/clientDetails.model";
import { Comment } from "@store/common/comments.model";
import { MentionItem } from "react-mentions";

export class ClientDetailsService {
  store = clientDetailsStore;

  resetStore = () => this.store.reset();

  setDetails = (details: ClientDetailsDetailsModel) =>
    this.store.update((state) => ({
      ...state,
      details,
    }));

  getClientDetails = (clientId: string | number): Observable<ClientDetailsDetailsModel> => {
    return from(APIAxios(APIRoutes.GETClientDetails(clientId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ClientDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => {
        this.store.update(
          (state) => ({
            ...state,
            details,
          }),
          getClientDetailsDataSource.setSuccess()
        );
      }),

      getClientDetailsDataSource.trackRequestStatus()
    );
  };

  createClient = (client: ManageClientDetailsModel): Observable<ClientDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTClient(),
        data: manageClientDetailsToFormData(client),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ClientDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  updateClientDetails = (clientId: string | number, client: ManageClientDetailsModel): Observable<ClientDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTClientDetails(clientId),
        data: manageClientDetailsToFormData(client),
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ClientDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  updateClientParameters = (clientId: string | number, parameters: ManageClientParametersModel): Observable<ClientDetailsDetailsModel> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTClientDetails(clientId),
        data: {
          ats: parameters.ats ? { ...parameters.ats, type: parameters.ats.type?.value } : undefined,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<ClientDetailsDetailsModel>) => {
        return response.data;
      }),
      tap((details) => this.setDetails(details))
    );
  };

  getClientHistory = (clientId: string | number, page: number = 1, take: number = 24): Observable<PaginatedData<any[]>> => {
    return from(
      APIAxios({
        ...APIRoutes.GETClientHistory(clientId),
        params: {
          page,
          take,
        },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<PaginatedData<any[]>>) => {
        return response.data ?? [];
      }),
      tap((actions) => {
        this.store.update((state) => ({
          ...state,
          actions: state.actions
            ? [...state.actions, ...actions.data.filter((action) => !state.actions?.some((a) => a.id === action.id))]
            : actions.data,
          actionsPaginatedMeta: actions.meta,
        }));
      })
    );
  };

  getClientComments = (clientId: string | number): Observable<Comment[]> => {
    return from(APIAxios(APIRoutes.GETClientComments(clientId))).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment[]>) => {
        return response.data;
      }),
      tap((comments) => {
        this.store.update(
          (state) => ({
            ...state,
            comments,
          }),
          getClientCommentsDataSource.setSuccess()
        );
      }),
      getClientCommentsDataSource.trackRequestStatus()
    );
  };

  addComment = (clientId: number | string, content: string, mentions: MentionItem[] = []): Observable<Comment> => {
    return from(
      APIAxios({
        ...APIRoutes.POSTClientComment(clientId),
        data: { content, mentions },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment>) => {
        return response.data;
      }),
      tap((comment) => {
        this.store.update((state) => ({
          ...state,
          comments: [comment, ...(state.comments || [])],
        }));
      })
    );
  };

  updateComment = (clientId: number | string, commentId: string, content: string, mentions: MentionItem[] = []): Observable<Comment> => {
    return from(
      APIAxios({
        ...APIRoutes.PUTClientComment(clientId),
        data: { commentId, content, mentions },
      })
    ).pipe(
      catchError((err: AxiosError) => {
        throw new SnackError((err.response as any)?.data?.message, "error");
      }),
      map((response: AxiosResponse<Comment>) => {
        return response.data;
      }),
      tap((updatedComment) => {
        this.store.update((state) => {
          const updatedComments = state.comments?.map((comment) => (comment.id === updatedComment.id ? updatedComment : comment));
          return {
            ...state,
            comments: updatedComments ?? [],
          };
        });
      })
    );
  };
}

export const clientDetailsService = new ClientDetailsService();
