import React from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useObservable } from "@ngneat/react-rxjs";
import { useNavigate } from "react-router-dom";

import { DashboardStat, globalStatisticsQuery } from "@store/globalStatistics";

import { Pages } from "@utils/routes.utils";

import ModalComponent from "@components/modal/Modal.component";
import AdCard from "@components/card/Ad.card";

interface AdsListModalProps {
  handleClose: () => void;
  stat: DashboardStat;
  title: string;
}

const AdsListModal = (props: AdsListModalProps) => {
  const { handleClose, stat, title } = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const navigate = useNavigate();

  const [ads] = useObservable(globalStatisticsQuery.adsByStat$(stat));

  return (
    <ModalComponent
      maxWidth={breakpointDownSM ? "xs" : false}
      fullWidth={breakpointDownSM}
      handleClose={handleClose}
      titleLeft
      content={
        <Box py="20px" className="scrollable" width={breakpointDownSM ? "100%" : "700px"}
             maxHeight="60vh" overflow="auto" px="10px">
          <Grid container spacing={3}>
            {ads.map((ad) => (
              <Grid key={ad.id} item xs={12} sm={6}>
                <AdCard
                  ad={ad}
                  handleClick={() => navigate(`/${Pages.ADS}/${ad.id}`)} />
              </Grid>
            ))}
          </Grid>
        </Box>
      }
      title={title}
    />
  );
};

export default AdsListModal;
