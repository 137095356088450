import { useLocation } from "react-router-dom";
import i18n from "@utils/i18n.utils";
import { Stack } from "@mui/material";

export enum Pages {
  DASHBOARD = "dashboard",
  DEMO = "demo",
  BOOST = "boost",
  ADS = "ads",
  PARAMETERS = "parameters",
  CLIENTS = "clients",
  AFFILIATES = "affiliates",
  USERS = "users",
  REPORTS = "reports",
  TOOLBOX = "toolbox",
  CVTHEQUE = "cvtheque",
  PERSONAE = "personae",
  LINKEDIN = "linkedin",
  AIO = "aio",
  CGU = "cgu",
  PRIVACY = "privacy",
  MEDIAS = "medias",
  CONNECTORS = "connectors",
}

export enum RoutesUnAuth {
  LOGIN = "login",
  LOST_PASSWORD = "lost-password",
  SIGN_UP = "sign-up",
}

export enum AdRoutes {
  DETAIL = "detail",
  STATISTICS = "stats",
  APPLYING = "apply",
  SLOGAN = "slogan",
  POTENTIAL_PROFILES = "potential-profiles",
  COMMENT = "comment",
  HISTORIC = "historic",
}

export enum PotentialProfilesRoutes {
  SEARCH = "search",
  LINKEDIN_INVITATIONS = "linkedin-invitations",
}

export enum ClientRoutes {
  DETAILS = "details",
  USERS = "users",
  HISTORIC = "historic",
}

export enum ClientTabRoutes {
  AFFILIATES = "affiliates",
  LIST = "list",
}

export enum AffiliateRoutes {
  DETAILS = "details",
  SUBSCRIPTION = "subscription",
  USERS = "users",
  REPORTS = "reports",
  TOOLBOX = "toolbox",
  HISTORIC = "historic",
  CAREER = "career",
}

export enum AdminParametersRoutes {
  ADMINS = "admins",
  PROMPTS = "prompts",
  PERSONAE = "profiles",
  BANNER = "banner",
  STRIPE_PRODUCTS = "stripe-products",
}

export enum AdminAioRoutes {
  PROMPTS = "prompts",
  CUSTOMERS = "customers",
  APPLICATIONS = "applications",
}

export enum AdminAioClientRoutes {
  DETAILS = "details",
  COMPANY_PROFILE = "company-profile",
}

export enum ParametersRoutes {
  USERS = "users",
  SUBSCRIPTION = "subscription",
  AFFILIATES = "affiliates",
}

export enum ReportsRoutes {
  FILES = "files",
  ADS = "ads-reports",
  POWER_BI = "power-bi",
}

export namespace AdRoutes {
  export const routes: any = [
    { label: i18n.t("ads.routes.detail"), path: AdRoutes.DETAIL },
    { label: i18n.t("ads.routes.stats"), path: AdRoutes.STATISTICS },
    { label: i18n.t("ads.routes.apply"), path: AdRoutes.APPLYING },
    // { label: i18n.t("ads.routes.slogan"), path: AdRoutes.SLOGAN },
    { label: i18n.t("ads.routes.potentialProfiles"), path: AdRoutes.POTENTIAL_PROFILES },
    { label: i18n.t("ads.routes.comment"), path: AdRoutes.COMMENT },
    { label: i18n.t("ads.routes.historic"), path: AdRoutes.HISTORIC },
  ];
}

export namespace PotentialProfilesRoutes {
  export const routes: any = [
    { label: i18n.t("ads.details.potentialProfilesTab.tabs.SEARCH"), path: PotentialProfilesRoutes.SEARCH },
    { label: i18n.t("ads.details.potentialProfilesTab.tabs.LINKEDIN_INVITATIONS"), path: PotentialProfilesRoutes.LINKEDIN_INVITATIONS },
  ];
}

export namespace ClientRoutes {
  export const routes: any = [
    { label: i18n.t("clients.routes.details"), path: ClientRoutes.DETAILS },
    { label: i18n.t("clients.routes.users"), path: ClientRoutes.USERS },
    { label: i18n.t("clients.routes.historic"), path: ClientRoutes.HISTORIC },
  ];
}

export namespace ClientTabRoutes {
  export const routes: { label: string; path: string }[] = [
    { label: i18n.t("clients.type.affiliates"), path: ClientTabRoutes.AFFILIATES },
    { label: i18n.t("clients.type.clients"), path: ClientTabRoutes.LIST },
  ];
}

export namespace AffiliateRoutes {
  export const routes: any = [
    { label: i18n.t("clients.routes.details"), path: AffiliateRoutes.DETAILS },
    { label: i18n.t("clients.routes.subscription"), path: AffiliateRoutes.SUBSCRIPTION },
    { label: i18n.t("clients.routes.users"), path: AffiliateRoutes.USERS },
    { label: i18n.t("clients.routes.reports"), path: AffiliateRoutes.REPORTS },
    { label: i18n.t("clients.routes.toolbox"), path: AffiliateRoutes.TOOLBOX },
    { label: i18n.t("clients.routes.historic"), path: AffiliateRoutes.HISTORIC },
  ];

  export const careerRoute: any = { label: i18n.t("clients.routes.careerWebsite"), path: AffiliateRoutes.CAREER };
}

export namespace AdminParametersRoutes {
  export const routes: any = [
    { label: i18n.t("adminParameters.routes.admins"), path: AdminParametersRoutes.ADMINS },
    { label: i18n.t("adminParameters.routes.prompts"), path: AdminParametersRoutes.PROMPTS },
    { label: i18n.t("adminParameters.routes.personae"), path: AdminParametersRoutes.PERSONAE },
    { label: i18n.t("adminParameters.routes.banner"), path: AdminParametersRoutes.BANNER },
    { label: i18n.t("adminParameters.routes.stripeProducts"), path: AdminParametersRoutes.STRIPE_PRODUCTS },
  ];
}

export namespace AdminAioRoutes {
  export const routes: any = [
    { label: i18n.t("aio.routes.prompts"), path: AdminAioRoutes.PROMPTS },
    { label: i18n.t("aio.routes.customers"), path: AdminAioRoutes.CUSTOMERS },
    { label: i18n.t("aio.routes.applications"), path: AdminAioRoutes.APPLICATIONS },
  ];
}

export namespace AdminAioClientRoutes {
  export const routes: any = [
    { label: i18n.t("aio.clients.routes.details"), path: AdminAioClientRoutes.DETAILS },
    { label: i18n.t("aio.clients.routes.companyProfile"), path: AdminAioClientRoutes.COMPANY_PROFILE },
  ];
}

export namespace ParametersRoutes {
  export const routes: any = [
    { label: i18n.t("parameters.routes.users"), path: ParametersRoutes.USERS },
    { label: i18n.t("parameters.routes.subscription"), path: ParametersRoutes.SUBSCRIPTION },
    { label: i18n.t("parameters.routes.affiliates"), path: ParametersRoutes.AFFILIATES },
  ];
}

export namespace ReportsRoutes {
  export const routes: any = [
    { label: i18n.t("reports.reports"), path: ReportsRoutes.FILES },
    { label: i18n.t("reports.adsReports"), path: ReportsRoutes.ADS },
    { label: i18n.t("reports.powerBI"), path: ReportsRoutes.POWER_BI },
  ];
}

export namespace Pages {
  export const routes: Pages[] = [Pages.DASHBOARD, Pages.ADS, Pages.CONNECTORS, Pages.CVTHEQUE, Pages.BOOST, Pages.MEDIAS, Pages.PARAMETERS];
  export const adminRoutes: Pages[] = [Pages.DASHBOARD, Pages.ADS, Pages.BOOST, Pages.CLIENTS, Pages.PERSONAE, Pages.PARAMETERS, Pages.AIO];
  export const buyerRoutes: Pages[] = [Pages.BOOST, Pages.CLIENTS];
  export const mediasRoutes: Pages[] = [Pages.REPORTS, Pages.TOOLBOX, Pages.PERSONAE];

  export const label = (route: Pages): string => {
    switch (route) {
      case Pages.DASHBOARD:
        return i18n.t(`menu.dashboard`);
      case Pages.ADS:
        return i18n.t(`menu.ads`);
      case Pages.BOOST:
        return i18n.t(`menu.boost`);
      case Pages.CLIENTS:
        return i18n.t(`menu.clients`);
      case Pages.PARAMETERS:
        return i18n.t(`menu.parameters`);
      case Pages.REPORTS:
        return i18n.t(`menu.reports`);
      case Pages.TOOLBOX:
        return i18n.t(`menu.toolbox`);
      case Pages.CVTHEQUE:
        return i18n.t(`menu.cvtheque`);
      case Pages.PERSONAE:
        return i18n.t(`menu.personae`);
      case Pages.AIO:
        return i18n.t(`menu.aio`);
      case Pages.MEDIAS:
        return i18n.t(`menu.medias`);
      case Pages.CONNECTORS:
        return i18n.t(`menu.connectors`);
      default:
        return "";
    }
  };
  export const icons = (route: Pages, isSelected: boolean): JSX.Element | null => {
    switch (route) {
      case Pages.DASHBOARD:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="white dashboard" src="/images/icon_dashboard_white.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="dashboard" src="/images/icon_dashboard_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.ADS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="whiteJobs" src="/images/icon_jobs_white.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="jobs" src="/images/icon_jobs_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.BOOST:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="whiteBoost" src="/images/icon_boost_white.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="boost" src="/images/icon_boost_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.CLIENTS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="whiteBoost" src="/images/icon_customers_white.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="boost" src="/images/icon_customers_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.PARAMETERS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="whiteUsers" src="/images/icon_settings_white.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="users" src="/images/icon_settings_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.REPORTS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="whiteReport" src="/images/icon_report_white.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="report" src="/images/icon_report_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.TOOLBOX:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="whiteToolbox" src="/images/icon_toolbox_white.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="toolbox" src="/images/icon_toolbox_unselected.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.CVTHEQUE:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="cvtheque" src="/images/cvtheque.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="whiteCvtheque" src="/images/cvtheque_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.PERSONAE:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="personae" src="/images/icon_personae_white.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="whitePersonae" src="/images/icon_personae_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.AIO:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="aio" src="/images/icon_aiobubblebox_light.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="whiteAio" src="/images/icon_aiobubblebox_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.MEDIAS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="aio" src="/images/icon_aiobubblebox_light.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="whiteAio" src="/images/icon_aiobubblebox_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      case Pages.CONNECTORS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            {isSelected ? (
              <img alt="aio" src="/images/icon_connectors_white.svg" style={{ height: 18, width: 18 }} />
            ) : (
              <img alt="whiteAio" src="/images/icon_connectors_grey.svg" style={{ height: 18, width: 18 }} />
            )}
          </Stack>
        );
      default:
        return null;
    }
  };

  export const overIcons = (route: Pages): JSX.Element | null => {
    switch (route) {
      case Pages.DASHBOARD:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryDashboard" src="/images/icon_dashboard_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.ADS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryJobs" src="/images/icon_jobs_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.BOOST:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryBoost" src="/images/icon_boost_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.CLIENTS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryBoost" src="/images/icon_customers_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.PARAMETERS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="users" src="/images/icon_settings_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.REPORTS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryReport" src="/images/icon_report_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.TOOLBOX:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryToolBox" src="/images/icon_toolbox_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.CVTHEQUE:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryCvtheque" src="/images/cvtheque_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.PERSONAE:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryPersonae" src="/images/icon_personae_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.AIO:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryAio" src="/images/icon_aiobubblebox_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.MEDIAS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryAio" src="/images/icon_aiobubblebox_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      case Pages.CONNECTORS:
        return (
          <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
            <img alt="primaryAio" src="/images/icon_connectors_primary.svg" style={{ height: 18, width: 18 }} />
          </Stack>
        );
      default:
        return null;
    }
  };
}

export const useRouteMatch = (patterns: readonly string[] = []) => {
  const { pathname } = useLocation();

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i];

    const possibleMatch = pathname.split("/").some((p) => {
      return p === pattern;
    });

    if (possibleMatch) {
      return pattern;
    }
  }

  return null;
};
