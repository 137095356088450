import { useState } from "react";
import { Stack, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Subscription, SubscriptionTypeEnum } from "@store/subscriptions/subscriptions.model";

import ModalComponent from "@components/modal/Modal.component";
import SubscriptionPeriodSwitch from "../SubscriptionPeriod.switch";
import ShowMoreModal from "./ShowMore.modal";
import SubscriptionColumnComponent from "../SubscriptionColumn.component";

interface SeeSubscriptionsModalProps {
  handleClose: () => void;
  subscriptions: Subscription[];
}

const SeeSubscriptionsModal = (props: SeeSubscriptionsModalProps) => {
  const { handleClose, subscriptions } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [showMoreModal, setShowMoreModal] = useState(false);

  const [isYearly, setIsYearly] = useState(false);

  return (
    <ModalComponent
      handleClose={handleClose}
      title={
        subscriptions.length === 1 && subscriptions[0].type === SubscriptionTypeEnum.COMPANY
          ? t("subscriptions.discoverCompanySubscriptions")
          : t("subscriptions.discoverOurSubscriptions")
      }
      content={
        <Stack py="10px" alignItems={"center"}>
          <SubscriptionPeriodSwitch checked={isYearly} onChange={setIsYearly} />
          <Stack
            direction={breakpointDownSM ? "column" : "row"}
            alignItems="flex-start"
            justifyContent={breakpointDownSM ? "center" : breakpointDownMD ? "space-around" : "center"}
            position="relative"
            mt="30px"
            flexWrap="wrap">
            {subscriptions.map((subscription) => (
              <SubscriptionColumnComponent
                key={subscription.id}
                isYearly={isYearly}
                subscription={subscription}
                handleShowModal={() => setShowMoreModal(true)}/>
            ))}
          </Stack>
          {showMoreModal && <ShowMoreModal subscriptions={subscriptions} handleClose={() => setShowMoreModal(false)} />}
        </Stack>
      }
      maxWidth="md"
    />
  );
};

export default SeeSubscriptionsModal;