import { createRequestDataSource } from "@ngneat/elf-requests";
import { selectAllEntities } from "@ngneat/elf-entities";
import { twilioAiCallsStore } from "@store/twilioAiCalls/twilioAiCalls.store";

export const getTwilioAiCallsDataSource = createRequestDataSource({
  store: twilioAiCallsStore,
  data$: () => twilioAiCallsStore.pipe(selectAllEntities()),
  requestKey: "getTwilioAiCalls",
  dataKey: "twilioAiCalls",
});
