import { select } from "@ngneat/elf";

import { globalStatisticsStore } from "@store/globalStatistics/globalStatistics.store";
import {
  getAdminGlobalStatisticsDataSource,
  getAffiliatesGlobalStatisticsDataSource
} from "@store/globalStatistics/globalStatistics.requests";
import { DashboardStat } from "@store/globalStatistics/globalStatistics.model";
import { sessionQuery } from "@store/session";
import { UserRoleEnum } from "@store/users";

export class GlobalStatisticsQuery {
  store = globalStatisticsStore;

  adminStatistics$ = getAdminGlobalStatisticsDataSource.data$();
  affiliatesStatistics$ = getAffiliatesGlobalStatisticsDataSource.data$();

  filters$ = this.store.pipe(select((state) => state.filters));

  adsByStat$ = (stat: DashboardStat) => this.store.pipe(select((state) => {
    switch (stat) {
      case DashboardStat.INEFFICIENT:
        return state.affiliates?.inefficient ?? [];
      default:
        return [];
    }
  }));
  boostsByStat$ = (stat: DashboardStat) => this.store.pipe(select((state) => {
    let adminAffiliate = false;

    if (sessionQuery.affiliateIds?.length === 1 && sessionQuery.affiliateIds[0].value !== "FAVORITES") {
      adminAffiliate = true;
    }

    switch (stat) {
      case DashboardStat.BOOSTS:
        return state[(sessionQuery.role === UserRoleEnum.CLIENT || adminAffiliate ? 'affiliates' : 'admin')]?.boosts ?? [];
      case DashboardStat.ADMIN_NEW_BOOSTS:
        return state.admin?.newBoost ?? [];
      default:
        return [];
    }
  }));
  lastActions$ = (isInClient?: boolean) => this.store.pipe(select((state) => {
    switch (sessionQuery.role) {
      case UserRoleEnum.ADMIN:
        return state[isInClient ? "affiliates" : "admin"]?.lastActions ?? [];
      default:
        return state.affiliates?.lastActions ?? [];
    }
  }));

  get filters() {
    return this.store.getValue().filters;
  }
}

export const globalStatisticsQuery = new GlobalStatisticsQuery();
