import { Box, CardActionArea, IconButton, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import { useEffect, useState } from "react";
import { finalize } from "rxjs";
import { useParams } from "react-router-dom";

import { EntityTypeEnum } from "@store/entities/entities.model";
import { entityDetailsQuery } from "@store/entities/details";
import { ToolboxColumn, toolboxQuery } from "@store/toolbox";
import {
  affiliateDetailsQuery,
  affiliateDetailsService,
  AffiliateToolboxTool,
  AffiliateToolboxToolStatusEnum
} from "@store/entities/afiliates/details";

import { Colors } from "@constants/colors.constant";
import AddToolboxColumnModal from "@screens/auth/admin/entities/components/toolbox/AddToolboxColumnModal.modal";
import ToolboxToolGroupedCells from "@screens/auth/client/toolbox/components/ToolboxToolGroupedCells.component";
import ToolboxToolCell from "@screens/auth/client/toolbox/components/ToolboxToolCell.component";
import ConfirmModal from "@components/modal/Confirm.modal";
import AddToolboxDocumentModal
  from "@screens/auth/admin/entities/components/toolbox/AddToolboxDocumentModal.modal";

const IconButtonStyles = styled(IconButton)`
  background-color: transparent;
  width: 30px;
  height: 30px;
  &:hover {
    background-color: white !important;
  }
`;

interface EntityToolboxTabProps {
  type: EntityTypeEnum;
}

const EntityToolboxTab = (props: EntityToolboxTabProps) => {
  const {type} = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const { entityId } = useParams();

  const [{ entityDetails: details }] = useObservable(entityDetailsQuery.details$(type));

  const [toolboxes] = useState(toolboxQuery.toolboxes);

  const [deleteColumnLoading, setDeleteColumnLoading] = useState(false);

  const [addColumnModalOpen, setAddColumnModalOpen] = useState(false);
  const [columnIdToAddDocument, setColumnIdToAddDocument] = useState<string | null>(null);
  const [columnToDelete, setColumnToDelete] = useState<ToolboxColumn | null>(null);

  const [{ affiliateToolboxTools }] = useObservable(affiliateDetailsQuery.toolboxTools$);

  useEffect(() => {
    if (entityId) {
      affiliateDetailsService.getAffiliateToolboxTools(entityId).subscribe({
        error: (err: any) => enqueueSnackbar(err.text, err.options),
      });
    }
  }, [enqueueSnackbar, entityId]);

  const groupedByDocClientToolboxTools: { [key: string]: AffiliateToolboxTool[] } = {};
  details?.toolboxColumns?.forEach((toolbox) => {
    if (affiliateToolboxTools) {
      affiliateToolboxTools
        .filter((ct) => ct.column.name === toolbox.name)
        .sort((a, b) => {
          return a.status === AffiliateToolboxToolStatusEnum.REQUESTED ? -1 : 1;
        })
        .forEach((ct) => {
          if (!groupedByDocClientToolboxTools[ct.column.name + (ct.category ?? "") + ct.title]) {
            groupedByDocClientToolboxTools[ct.column.name + (ct.category ?? "") + ct.title] = [ct];
          } else {
            groupedByDocClientToolboxTools[ct.column.name + (ct.category ?? "") + ct.title].push(ct);
          }
        });
    }
  });

  if (!details) return null;

  return (
    <>
      <Stack overflow="auto" spacing={0.5} position="relative" width="calc(100% + 40px)" flex={1} ml="-20px">
        <Stack
          flex={1}
          pt={breakpointDownSM ? "20px" : "40px"}
          px={breakpointDownSM ? "10px" : "30px"}
          justifyContent="space-between"
          overflow="auto"
          width="100%">
          <Box overflow="auto" pb={breakpointDownSM ? "20px" : "30px"} width="100%" flex={1} px="20px" className="scrollable-horizontal">
            <Stack direction="row" height="100%" width="100%">
              {details.toolboxColumns?.map((toolbox) => (
                <Box key={toolbox.id} height="100%" overflow="auto" minWidth="300px" maxWidth="333px" pr="20px">
                  <Stack bgcolor={Colors.greyCardBg} borderRadius="15px" height="100%">
                    <Stack direction="row" alignItems="center" justifyContent="space-between" p={breakpointDownSM ? "15px" : "25px"} pb="0px">
                      <Typography fontWeight="700" fontSize="16px">
                        {toolbox.name}
                      </Typography>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <IconButtonStyles disabled={deleteColumnLoading} onClick={() => setColumnToDelete(toolbox)}>
                          <img alt="delete" src="/images/icon_delete.svg" />
                        </IconButtonStyles>
                        <IconButtonStyles disabled={deleteColumnLoading} onClick={() => setColumnIdToAddDocument(toolbox.id)}>
                          <img alt="add" width="16px" src="/images/icon_add_more.svg" />
                        </IconButtonStyles>
                      </Stack>
                    </Stack>
                    <Stack overflow="auto" className="scrollable" p={breakpointDownSM ? "15px" : "25px"} spacing={3}>
                      {groupedByDocClientToolboxTools &&
                        Object.values(groupedByDocClientToolboxTools)
                          .filter((g) => g[0].column.name === toolbox.name)
                          .map((tools) => {
                            if (tools?.length > 1) {
                              return <ToolboxToolGroupedCells key={tools[0].id} tools={tools} entity={details} columnId={toolbox.id} />;
                            } else {
                              return (
                                <ToolboxToolCell
                                  key={tools[0].id}
                                  tool={{ category: tools[0].category ?? undefined, title: tools[0].title }}
                                  affiliateToolboxTool={tools[0]}
                                  entity={details}
                                  columnId={toolbox.id}
                                />
                              );
                            }
                          })}
                    </Stack>
                  </Stack>
                </Box>
              ))}
              {toolboxes.length > (details.toolboxColumns?.length ?? 0) && (
                <Box height="100%" pr="20px">
                  <CardActionArea
                    sx={{
                      borderRadius: "15px !important",
                      height: "100%",
                      maxWidth: !details.toolboxColumns?.length ? "300px" : "64px",
                    }}
                    onClick={() => setAddColumnModalOpen(true)}>
                    <Stack
                      justifyContent="center"
                      alignItems="center"
                      bgcolor={Colors.greyCardBg}
                      spacing={3}
                      p="30px"
                      height="100%"
                      borderRadius="15px">
                      {!details.toolboxColumns?.length && (
                        <Typography>{t("toolbox.addColumn")}</Typography>
                      )}
                      <img alt="add" width="16px" src="/images/icon_add_more.svg" />
                    </Stack>
                  </CardActionArea>
                </Box>
              )}
            </Stack>
          </Box>
        </Stack>
      </Stack>
      {addColumnModalOpen && (
        <AddToolboxColumnModal
          handleClose={() => setAddColumnModalOpen(false)}
          toolboxColumns={toolboxes}
          entity={details} />
      )}
      {columnToDelete && (
        <ConfirmModal
          modalTitle={t("toolbox.confirmDeleteColumnTitle")}
          confirmMsg={t("toolbox.confirmDeleteColumnMsg", { title: columnToDelete.name })}
          handleClose={() => setColumnToDelete(null)}
          handleConfirm={() => {
            setDeleteColumnLoading(true);
            affiliateDetailsService
              .deleteToolboxColumn(details.id, columnToDelete.id, columnToDelete.name)
              .pipe(finalize(() => setDeleteColumnLoading(false)))
              .subscribe({
                error: (err) => {
                  enqueueSnackbar(err.text, err.options);
                },
              });
          }} />
      )}
      {columnIdToAddDocument && (
        <AddToolboxDocumentModal
          handleClose={() => setColumnIdToAddDocument(null)}
          columnIdToAddDocument={columnIdToAddDocument}
          toolboxColumns={toolboxes}
          entity={details} />
      )}
    </>
  );
};

export default EntityToolboxTab;
