import { Box, Stack, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

import CheckIcon from "@mui/icons-material/Check";

import { Subscription, SubscriptionDetailedFeatureEnum } from "@store/subscriptions/subscriptions.model";

import { Colors } from "@constants/colors.constant";

import ModalComponent from "@components/modal/Modal.component";

const HEADER_HEIGHT = "94px";
const ROW_HEIGHT = "45px";

const HeaderCell = styled(Stack)({
  height: HEADER_HEIGHT,
  backgroundColor: Colors.subscriptions.detailsOddRow,
  textAlign: "center",
});
const HeaderCellLabel = styled(Stack)({ height: HEADER_HEIGHT });

const RowCell = styled(Stack)((props: { even: boolean }) => ({
  height: ROW_HEIGHT,
  backgroundColor: props.even ? Colors.subscriptions.detailsEvenRow : Colors.subscriptions.detailsOddRow,
  textAlign: "center",
  padding: "0 4px",
}));

const RowLabel = styled(Stack)({ height: ROW_HEIGHT, paddingRight: "15px" });

const ColumnsContainer = styled(Stack)({
  borderTopRightRadius: "10px",
  borderTopLeftRadius: "10px",
  overflow: "hidden",
});

const GreenCheckIcon = styled(CheckIcon)({ color: Colors.checkIcon, fontSize: "18px" });

const FeaturePrecision = styled(Typography)({ color: Colors.grey });

interface ShowMoreModalProps {
  subscriptions: Subscription[];
  handleClose: () => void;
}

const ShowMoreModal = (props: ShowMoreModalProps) => {
  const { handleClose, subscriptions } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <ModalComponent
      handleClose={handleClose}
      title={t("subscriptions.features")}
      titleLeft
      content={
        <Stack px={breakpointDownSM ? "0px" : breakpointDownMD ? "20px" : "80px"} py="15px">
          <Stack direction="row">
            <Stack width={breakpointDownSM ? "150px" : "400px"}>
              <HeaderCellLabel/>
              {subscriptions[0].detailedFeatures?.map((feature) => (
                <RowLabel justifyContent={"center"}>
                  <Typography
                    fontSize={breakpointDownSM ? "12px" : "14px"}
                    lineHeight={1}
                    fontWeight={
                      feature.type === SubscriptionDetailedFeatureEnum.UNLIMITED_CV_PARSING ||
                      feature.type === SubscriptionDetailedFeatureEnum.AUTOMATED_SOURCING_SCENARIO ||
                      feature.type === SubscriptionDetailedFeatureEnum.GPT_SORT
                        ? 500
                        : 300
                    }>
                    {t(`subscriptions.detailedFeaturesLabel.${feature.type}`)}
                  </Typography>
                </RowLabel>
              ))}
            </Stack>

            <ColumnsContainer direction="row">
              {subscriptions
                .filter((s) => !!s.detailedFeatures)
                .map((subscription) => (
                  <Stack key={subscription.id} width={breakpointDownSM ? "80" : "193px"}>
                    <HeaderCell justifyContent="center" alignItems="center">
                      <Stack direction={breakpointDownSM ? "column" : "row"} alignItems={"center"} spacing="6px">
                        <Box sx={{"& > img": {height: "13px"}}}>{subscription.icon}</Box>
                        <Typography fontSize={breakpointDownSM ? "12px" : "15px"} fontWeight={500} lineHeight={1}>
                          {subscription.name}
                        </Typography>
                      </Stack>
                    </HeaderCell>
                    {subscription.detailedFeatures?.map((feature, index) => (
                      <RowCell justifyContent="center" alignItems="center" even={index % 2 === 0}>
                        {feature.included ? (
                          feature.text ? (
                            <FeaturePrecision fontSize={breakpointDownSM ? "11px" : "13px"} fontWeight={300} lineHeight={1}>
                              {feature.text}
                            </FeaturePrecision>
                          ) : (
                            <GreenCheckIcon />
                          )
                        ) : (
                          ""
                        )}
                      </RowCell>
                    ))}
                  </Stack>
                ))}
            </ColumnsContainer>
          </Stack>
        </Stack>
      }
      maxWidth={breakpointDownSM ? "xs" : false}
      fullWidth={false}
    />
  );
};

export default ShowMoreModal;