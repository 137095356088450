import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, Observable, switchMap } from "rxjs";
import { CandidatesFilters, CandidateSortModel } from "./candidates.model";
import { candidatesService } from "./candidates.service";

interface SearchCandidatesParams {
  filters?: CandidatesFilters;
  sort?: CandidateSortModel
  page?: number;
  take?: number;
}

export const searchCandidatesEffect = createEffectFn((params$: Observable<SearchCandidatesParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({ filters, sort, page, take }) => candidatesService.getCandidates(filters, sort, page, take))
  );
});
