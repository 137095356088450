import { createEffectFn } from "@ngneat/effects";
import { debounceTime, distinctUntilChanged, forkJoin, Observable, switchMap } from "rxjs";

import { EntityTypeEnum } from "@store/entities/entities.model";
import { clientsService } from "@store/entities/clients";
import { affiliatesService } from "@store/entities/afiliates";
import { EntitiesFiltersModel, EntitiesSortModel } from "@store/entities/entitiesFilters/entitiesFilters.model";

interface SearchEntitiesParams {
  filters?: EntitiesFiltersModel;
  sort?: EntitiesSortModel;
  page?: number;
  take?: number;
}

export const searchEntitiesEffect = createEffectFn((params$: Observable<SearchEntitiesParams>) => {
  return params$.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    switchMap(({filters, page, take }) => {
      if (filters?.type === EntityTypeEnum.CLIENT) return clientsService.getClients(page, take);
      else if (filters?.type === EntityTypeEnum.AFFILIATE) return affiliatesService.getAffiliates(page, take);
      return forkJoin([
        clientsService.getClients(),
        affiliatesService.getAffiliates(),
      ])
    })
  );
});
