import { alpha, Avatar, Box, CardActionArea, Stack, styled, Typography } from "@mui/material";
import { Colors } from "@constants/colors.constant";
import { UserLightModel, UserModel, UserRoleEnum, UserRoleEnumFunctions } from "@store/users";
import { sessionQuery } from "@store/session";

const UserCardStyled = styled(Stack)(
  (props) => `
  background-color: ${Colors.white};
  border-radius: 15px;
  flex: 1;
  height: 100%;
  opacity: 1;
  position: relative;
  width: 100%;
  & > *:first-of-type {
    align-items: center;
    background-color: ${Colors.white};
    border-radius: 15px;
    box-shadow: ${props["aria-selected"] ? `inset 0px 0px 0px 1px ${Colors.primary},` : ""} 0px 0px 20px rgba(34,34,64, 0.05);  
    height: 100%;
    padding: 22px;
    &:not(.MuiCardActionArea-focusHighlight):hover {
      background-color: ${alpha(Colors.primary, 0.1)};
    }
  };
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    };
  };
`
);

const BadgeRole = styled(Stack)`
  border-radius: 5px;
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 500;
  height: 15px;
  padding: 0 8px;
  position: absolute;
  top: -8px;
  z-index: 1;
`;

interface UserCardProps {
  handleClick?: () => void;
  selected?: boolean;
  user: UserModel | UserLightModel;
}

const UserCard = (props: UserCardProps) => {
  const { handleClick, selected, user } = props;

  return (
    <UserCardStyled aria-selected={selected}>
      <Stack component={!handleClick ? Stack : CardActionArea} onClick={handleClick}>
        {sessionQuery.role === UserRoleEnum.ADMIN && (
          <BadgeRole bgcolor={UserRoleEnumFunctions.color(user.role)} alignItems="center" justifyContent="center">
            {UserRoleEnumFunctions.allItems.find((r) => r.value === user.role)?.label ?? ""}
          </BadgeRole>
        )}
        <Stack alignItems="center" width="100%" spacing={1.5}>
          <Box p="1px" bgcolor={Colors.greyLight} borderRadius="50%">
            <Avatar sx={{ border: `1.5px solid ${Colors.white}`, height: 50, width: 50 }} src={user.picture} />
          </Box>
          <Stack spacing={-0.5} alignItems="center">
            <Typography fontSize={16} fontWeight="500" lineHeight={1.25} textAlign="center">
              {user.firstname} {user.lastname}
            </Typography>
            <Typography fontSize={12} fontWeight="300" color={Colors.greyLight}>
              {user.email}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </UserCardStyled>
  );
};

export default UserCard;
