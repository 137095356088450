import { Box, CircularProgress, Container, Grid, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { useObservable } from "@ngneat/react-rxjs";
import { useSnackbar } from "notistack";
import parse from "html-react-parser";
import styled from "@emotion/styled";
import { finalize, map } from "rxjs";

import { adDetailsQuery, adDetailsService } from "@store/ads/details";
import { sessionQuery } from "@store/session";
import { affiliateDetailsService } from "@store/entities/afiliates/details";
import { linkedinService } from "@store/linkedin";
import { UserRoleEnum } from "@store/users";
import { FeatureCreditsTypeEnum, subscriptionsQuery } from "@store/subscriptions";
import { stripeService } from "@store/stripe/stripe.service";

import { Colors } from "@constants/colors.constant";
import { Pages } from "@utils/routes.utils";

import AIOButtonComponent from "@components/Button.component";
import BoostAdCard from "@screens/auth/common/ads/components/BoostAd.card";
import AffiliateAdCard from "@screens/auth/common/ads/components/AffiliateAd.card";
import ChipComponent from "@components/Chip.component";
import ManageDiffusionListModal from "@screens/auth/common/ads/components/ManageDiffusionList.modal";
import CommentsComponent from "@components/input/Comments.component";
import LinkedinPostModal from "@screens/auth/common/ads/components/LinkedinPost.modal";
import CheckCreditsModal from "@components/modal/CheckCredits.modal";
import ModalComponent from "@components/modal/Modal.component";
import { MentionItem } from "react-mentions";

const LinkedinPostCard = styled(Stack)({
  backgroundColor: "white",
  borderRadius: "15px",
  boxShadow: "0px 0px 20px rgba(34,34,64, 0.05)",
  padding: "18px 20px",
  justifyContent: "space-between",
  alignItems: "center",
});

const StyledLink = styled("a")({
  color: Colors.primary,
});

const AdsDetailsTab = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchParams, setSearchParams] = useSearchParams();
  const openPostModal = searchParams.get("open_post");

  const [loadingConnectedWithLinkedin, setLoadingConnectedWithLinkedin] = useState(false);
  const [openManageDiffusionList, setOpenManageDiffusionList] = useState(false);
  const [openLinkedinPost, setOpenLinkedinPost] = useState(!!openPostModal);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false);

  const [checkUserCredits, setCheckUserCredits] = useState(false);

  const [ad] = useObservable(adDetailsQuery.adDetails$);
  const [{ adComments: comments, loading: commentsLoading }] = useObservable(adDetailsQuery.comments$);

  const handleUpdateComment = (commentId: string, comment: string, mentions: MentionItem[]) => {
    if (!ad?.id) return;
    adDetailsService.updateComment(ad.id, commentId, comment, mentions).subscribe({
      error: (err: any) => enqueueSnackbar(err.text, err.options),
    });
  };

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");

    if (!clientSecret) return;

    setSearchParams({}); // remove query params from url

    stripeService.checkPaymentSucceeded(clientSecret).subscribe({
      next: () => setShowPaymentSuccessModal(true),
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar, setSearchParams]);

  if (!ad) return null;

  const handlePublishClick = () => {
    setLoadingConnectedWithLinkedin(true);
    linkedinService
      .checkLinkedinConnection()
      .pipe(finalize(() => setLoadingConnectedWithLinkedin(false)))
      .subscribe({
        next: (isConnectedWithLinkedin) => {
          if (isConnectedWithLinkedin) {
            if (sessionQuery.user?.role === UserRoleEnum.ADMIN || subscriptionsQuery.sessionUserSubscriptionOngoing || ad.managedByTwinin) {
              setOpenLinkedinPost(true);
            } else {
              setCheckUserCredits(true);
            }
          } else {
            linkedinService.connectToLinkedin(Pages.LINKEDIN, `${window.location.pathname}?open_post=1`);
          }
        },
        error: (err: any) => {
          enqueueSnackbar(err.text, err.options);
        },
      });
  };

  return (
    <>
      <Stack width="100%" alignItems="center" py={breakpointDownSM ? "20px" : "40px"} overflow="auto" className="scrollable">
        <Container maxWidth="md" sx={{ px: breakpointDownSM ? "4px" : undefined }}>
          <Stack spacing={4} pb="30px">
            <Stack spacing="20px">
              <BoostAdCard ad={ad} />
              <Stack direction={breakpointDownSM ? "column" : "row"} alignItems="center" spacing="20px" flexWrap="wrap">
                <Box flex={1}>
                  <AffiliateAdCard affiliate={ad.affiliate} />
                </Box>
                <Box width="350px">
                  <LinkedinPostCard direction="row">
                    <Stack spacing="8px" alignItems={"flex-start"}>
                      <img height="22px" width={"auto"} src="/images/linkedin-logo.png" alt="linkedin" />
                      <Typography fontWeight={300} color={Colors.grey}>
                        {t("ads.details.detailTab.linkedin.postOnLinkedin")}
                      </Typography>
                    </Stack>
                    <AIOButtonComponent
                      ariaLabel="linkedin"
                      title={t("ads.details.detailTab.linkedin.publish")}
                      variant="contained"
                      color="secondary"
                      disabled={loadingConnectedWithLinkedin}
                      onClick={handlePublishClick}
                    />
                  </LinkedinPostCard>
                </Box>
              </Stack>
            </Stack>
            {(sessionQuery.role === UserRoleEnum.ADMIN || !!ad.jobBoards?.length) && (
              <Stack spacing={breakpointDownSM ? 1 : 2}>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography fontSize="20px" fontWeight={700}>
                      {t("ads.details.detailTab.diffused")}
                    </Typography>
                    <Typography color={Colors.grey} fontSize="15px" fontWeight={300}>
                      {t("ads.details.detailTab.inProgress")}
                    </Typography>
                  </Stack>
                  {sessionQuery.role === UserRoleEnum.ADMIN && (
                    <AIOButtonComponent
                      title={t("ads.details.detailTab.manageDiffusionList")}
                      onClick={() => setOpenManageDiffusionList(true)}
                      variant="text"
                      color="primary"
                    />
                  )}
                </Stack>
                <Box>
                  <Grid container spacing={breakpointDownSM ? 1 : 2}>
                    {ad.jobBoards?.map((j) => (
                      <Grid item key={j.id}>
                        <ChipComponent label={j.name} />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Stack>
            )}
            {!!ad.companyInformation && (
              <Stack spacing={breakpointDownSM ? 1 : 2} fontSize={14} fontWeight={300} color={Colors.grey} whiteSpace="pre-line">
                <Typography fontSize="20px" fontWeight={700} color="text.primary">
                  {t("ads.details.detailTab.companyInformation")}
                </Typography>
                {parse(ad.companyInformation)}
              </Stack>
            )}
            {!!ad.description && (
              <Stack spacing={breakpointDownSM ? 1 : 2} fontSize={14} fontWeight={300} color={Colors.grey} whiteSpace="pre-line">
                <Typography fontSize="20px" fontWeight={700} color="text.primary">
                  {t("ads.details.detailTab.description")}
                </Typography>
                {parse(ad.description)}
              </Stack>
            )}
            {!!ad.profile && (
              <Stack spacing={breakpointDownSM ? 1 : 2} fontSize={14} fontWeight={300} color={Colors.grey} whiteSpace="pre-line">
                <Typography fontSize="20px" fontWeight={700} color="text.primary">
                  {t("ads.details.detailTab.profile")}
                </Typography>
                {parse(ad.profile)}
              </Stack>
            )}
            {!!ad.advantages && (
              <Stack spacing={breakpointDownSM ? 1 : 2} fontSize={14} fontWeight={300} color={Colors.grey} whiteSpace="pre-line">
                <Typography fontSize="20px" fontWeight={700} color="text.primary">
                  {t("ads.details.detailTab.advantages")}
                </Typography>
                {parse(ad.advantages)}
              </Stack>
            )}
            {!!ad.url && (
              <Stack spacing={breakpointDownSM ? 1 : 2} fontSize={14} fontWeight={300} color={Colors.grey}>
                <Typography fontSize="20px" fontWeight={700} color="text.primary">
                  {t("ads.details.detailTab.url")}
                </Typography>
                <StyledLink href={ad.url} target="_blank" rel="noreferrer">
                  {ad.url}
                </StyledLink>
              </Stack>
            )}
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Typography fontSize="20px" fontWeight={700}>
                  {commentsLoading ? "-" : ""}
                  {!commentsLoading && t("ads.details.detailTab.comments", { count: comments?.length ?? 0 })}
                </Typography>
                {commentsLoading && <CircularProgress size={20} />}
              </Stack>
              <CommentsComponent
                handleAddComment={(comment, mentions) => {
                  adDetailsService.addComment(ad.id, comment, mentions).subscribe({
                    error: (err) => enqueueSnackbar(err.text, err.options),
                  });
                }}
                handleUpdateComment={handleUpdateComment}
                comments={comments}
                fetchTags={() =>
                  affiliateDetailsService.getAffiliateUsers(ad.affiliate.id).pipe(
                    map((users) =>
                      users
                        .filter((user) => user.role !== UserRoleEnum.ADMIN)
                        .map((user) => ({
                          id: user.email,
                          display: `${user.firstname} ${user.lastname}`,
                        }))
                    )
                  )
                }
              />
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Outlet />
      {openManageDiffusionList && <ManageDiffusionListModal handleClose={() => setOpenManageDiffusionList(false)} ad={ad} />}
      {openLinkedinPost && <LinkedinPostModal handleClose={() => setOpenLinkedinPost(false)} ad={ad} />}
      {checkUserCredits && (
        <CheckCreditsModal
          handleCreditsValidated={() => {
            setCheckUserCredits(false);
            setOpenLinkedinPost(true);
          }}
          handleCloseCheck={() => setCheckUserCredits(false)}
          type={FeatureCreditsTypeEnum.LINKEDIN_POST}
        />
      )}
      {showPaymentSuccessModal && (
        <ModalComponent
          handleClose={() => {
            setShowPaymentSuccessModal(false);
          }}
          icon={<img alt="check" src="/images/illus_check.svg" />}
          title={t("stripe.paymentSuccess.title")}
          content={
            <Stack px="50px" py="20px">
              <Typography textAlign="center" pb="25px" lineHeight="1.25" color={Colors.secondaryText} fontWeight={300}>
                {t("stripe.paymentSuccess.description.manualBoost")}
              </Typography>
            </Stack>
          }
          maxWidth="xs"
          paddingButton="10px 0px 40px 0px"
        />
      )}
    </>
  );
};

export default AdsDetailsTab;
