import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import localeData from "dayjs/plugin/localeData";
import isoWeek from "dayjs/plugin/isoWeek";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isBetween from "dayjs/plugin/isBetween";
import { AppCountryEnum, getAppCountry } from "@store/common/country.model";
require("dayjs/locale/en");
require("dayjs/locale/fr");

if (getAppCountry() === AppCountryEnum.US) {
  dayjs.locale("en");
} else {
  dayjs.locale("fr");
}

dayjs.extend(isToday);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);

dayjs.extend(localeData);
dayjs.extend(isoWeek);
dayjs.extend(relativeTime);

export const globalMonthsShort = dayjs.monthsShort();
export const dayOfMonth = dayjs().date();


/**
 * Fonction qui prend une date et renvoie la date formatée selon le pays local
 * @param {Date} date - La date sous forme Date (ex: "2024-07-11T12:26:16.091Z")
 * @returns {string} - La date formatée (ex: "11/07/2024" pour la France, "07/11/2024" pour les États-Unis)
 */
export function formatLocalDate(date: Date) {
  if (getAppCountry() === AppCountryEnum.US) {
    return dayjs(date).format("MM/DD/YYYY");
  } else {
    return dayjs(date).format("DD/MM/YYYY");
  }
}

/**
 * Fonction qui prend une date et renvoie l'heure et les minutes (HH:MM)
 * @param {Date} date - La date sous forme Date (ex: "2024-07-11T12:26:16.091Z")
 * @returns {string} - L'heure et les minutes (ex: "12:26")
 */
export function formatTime(date: Date) {
  return dayjs(date).format("HH:mm");
}

export default dayjs;
