import { AffiliateRoutes, Pages, useRouteMatch } from "@utils/routes.utils";
import AIOButtonComponent from "@components/Button.component";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Colors } from "@constants/colors.constant";
import { useObservable } from "@ngneat/react-rxjs";
import { sessionQuery, sessionService } from "@store/session";
import { Sizes } from "@constants/sizes.constant";
import { bindHover, bindMenu, bindPopover, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import HoverMenu from "material-ui-popup-state/HoverMenu";
import { useTranslation } from "react-i18next";
import ProfileModale from "@components/modal/Profile.modal";
import React, { useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { UserAffiliateRoleEnum, UserRoleEnum } from "@store/users";
import CreditsRecap from "./card/CreditsRecap.card";

interface LeftBarComponentProps {
  handleHumburgerClick: () => void;
  drawerOpen: boolean;
}

const LeftBarComponent = (props: LeftBarComponentProps) => {
  const { handleHumburgerClick, drawerOpen } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));
  const breakpointDownXL = useMediaQuery(theme.breakpoints.down("xl"));

  const [buyerMode] = useObservable(sessionQuery.buyerMode$);

  const [user] = useObservable(sessionQuery.user$);
  const [clientViewClient] = useObservable(sessionQuery.clientViewClient$);

  const [accountOpen, setAccountOpen] = useState(false);

  const userMenuPopup = usePopupState({ variant: "popover", popupId: "user-menu" });
  const mediasMenuPopup = usePopupState({ variant: "popover", popupId: "medias-menu" });

  let routes = Pages.routes;
  if (sessionQuery.role === UserRoleEnum.ADMIN) routes = Pages.adminRoutes;
  else if (buyerMode) routes = Pages.buyerRoutes;

  const currentRoute = useRouteMatch(routes.concat(sessionQuery.role !== UserRoleEnum.ADMIN && !buyerMode ? Pages.mediasRoutes : []));

  if (!user) return null;

  const userIsBuyer = user.affiliates.some((a) => a.role === UserAffiliateRoleEnum.BUYER);

  const showUserCredits = sessionQuery.role !== UserRoleEnum.ADMIN;

  return (
    <Drawer
      open={!breakpointDownMD || drawerOpen}
      anchor="left"
      variant={breakpointDownMD ? "temporary" : "persistent"}
      ModalProps={{ onBackdropClick: handleHumburgerClick }}
      transitionDuration={200}>
      <Stack spacing={showUserCredits ? 5 : 8} alignItems="center" flex={1} pt={breakpointDownMD ? "90px" : showUserCredits ? "25px" : "50px"}>
        {!breakpointDownMD && (
          <Stack position="relative">
            {clientViewClient && clientViewClient.picture && (
              <Avatar
                sizes="contains"
                sx={{
                  height: "33px",
                  width: "33px",
                  position: "absolute !important",
                  top: 0,
                  right: 26,
                  border: "none",
                  borderRadius: "10px !important",
                  backgroundColor: clientViewClient.picture,
                  color: Colors.secondary,
                  zIndex: 10,
                }}
                src={clientViewClient.picture}
              />
            )}
            <img alt="aio logo" height={90} src="/images/logo_txt.svg" />
          </Stack>
        )}
        <Stack justifyContent="space-between" flex={1}>
          <Stack spacing={showUserCredits || breakpointDownXL ? 2 : 4} alignItems="center">
            {routes.map((route) => (
              <React.Fragment key={route}>
                {route === Pages.MEDIAS ? (
                  <div>
                    <Box {...bindHover(mediasMenuPopup)}>
                      <AIOButtonComponent
                        fontSize="15px"
                        fontWeight="500"
                        key={route}
                        isTitle
                        ariaLabel="title"
                        endIconFullRight
                        endIcon={
                          Pages.mediasRoutes.findIndex((x) => currentRoute === x) !== -1 ? (
                            <img width="16px" style={{marginLeft: ''}} src="/images/icon_arrow_menu_white.svg" alt="menu arrow" />
                          ) : (
                            <img width="16px" src="/images/icon_arrow_menu_grey.svg" alt="menu arrow" />
                          )
                        }
                        overEndIcon={<img width="16px" src="/images/icon_arrow_menu_primary.svg" alt="menu arrow" />}
                        overIcon={Pages.overIcons(route)}
                        icon={Pages.icons(route, Pages.mediasRoutes.findIndex((x) => currentRoute === x) !== -1)}
                        title={Pages.label(route)}
                        variant={Pages.mediasRoutes.findIndex((x) => currentRoute === x) !== -1 ? "contained" : undefined}
                      />
                    </Box>
                    <HoverMenu
                      {...bindPopover(mediasMenuPopup)}
                      aria-label="medias-menu"
                      anchorOrigin={{ vertical: "center", horizontal: "right" }}
                      transformOrigin={{ vertical: "center", horizontal: "left" }}>
                      {Pages.mediasRoutes.map((mediaRoute) => (
                        <MenuItem
                          disableRipple
                          aria-label="medias-menu-item"
                          key={mediaRoute}
                          onClick={() => {
                            mediasMenuPopup.close();
                            navigate(mediaRoute);
                          }}>
                          <AIOButtonComponent
                            fontSize="15px"
                            fontWeight="500"
                            key={mediaRoute}
                            isTitle
                            ariaLabel="title"
                            overIcon={Pages.overIcons(mediaRoute)}
                            icon={Pages.icons(mediaRoute, currentRoute === mediaRoute)}
                            title={Pages.label(mediaRoute)}
                            variant={currentRoute === mediaRoute ? "contained" : undefined}
                          />
                        </MenuItem>
                      ))}
                    </HoverMenu>
                  </div>
                ) : (
                  <AIOButtonComponent
                    fontSize="15px"
                    fontWeight="500"
                    key={route}
                    isTitle
                    ariaLabel="title"
                    overIcon={Pages.overIcons(route)}
                    icon={Pages.icons(route, currentRoute === route)}
                    onClick={() => {
                      handleHumburgerClick();
                      navigate(route);
                    }}
                    title={Pages.label(route)}
                    variant={currentRoute === route ? "contained" : undefined}
                  />
                )}
              </React.Fragment>
            ))}
          </Stack>
          <Stack spacing="20px" width="100%">
            {showUserCredits && !buyerMode && (
              <Box>
                <CreditsRecap />
              </Box>
            )}
            {clientViewClient && (
              <Box pb={"29px"} mx="20px">
                <AIOButtonComponent
                  title={t("menu.backToAdmin")}
                  fontSize="16px"
                  isTitle
                  icon={
                    <Stack justifyContent="center" alignItems="center" height="22px" width="22px">
                      <KeyboardBackspaceIcon />
                    </Stack>
                  }
                  ariaLabel="modal"
                  variant="contained"
                  onClick={() => {
                    let clientViewId = clientViewClient.id;
                    sessionService.switchToAdminMode();
                    navigate(`/${Pages.CLIENTS}/${Pages.AFFILIATES}/${clientViewId}/${AffiliateRoutes.DETAILS}`);
                  }}
                />
              </Box>
            )}
            {!clientViewClient && (
              <Stack width={`calc(${Sizes.leftBar}px - 40px)`} mx="20px" position="relative" alignItems="center">
                <Divider variant="fullWidth" style={{ width: "100%" }} orientation="horizontal" />
                {userIsBuyer && (
                  <Box position="absolute" top="-12px">
                    <IconButton
                      size="small"
                      sx={{ p: 0 }}
                      onClick={() => {
                        navigate(`/`);
                        sessionService.switchToBuyerMode();
                      }}>
                      <img alt="switch space" src="/images/icon_switch.svg" />
                    </IconButton>
                  </Box>
                )}
                <Stack spacing={1} direction="row" p="21px 0px" width="100%">
                  <Avatar style={{ height: "36px", width: "36px" }} src={user.picture} />
                  <Stack spacing={-0.5} flex={1} overflow="hidden">
                    <Typography fontSize="14px" fontWeight="500" width="100%" textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
                      {user.firstname} {user.lastname}
                    </Typography>
                    <Typography
                      fontSize="12px"
                      fontWeight="300"
                      color={Colors.secondaryText}
                      width="100%"
                      textOverflow="ellipsis"
                      overflow="hidden"
                      whiteSpace="nowrap">
                      {user.position}
                    </Typography>
                  </Stack>
                  <IconButton {...bindTrigger(userMenuPopup)} sx={{ mr: "-10px !important", ml: "-3px !important" }} size="small">
                    <MoreHorizIcon />
                  </IconButton>
                  <Menu
                    {...bindMenu(userMenuPopup)}
                    aria-label="user-menu"
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{ vertical: "bottom", horizontal: "center" }}>
                    {userIsBuyer && (
                      <MenuItem
                        onClick={() => {
                          navigate(`/`);
                          sessionService.switchToBuyerMode();
                          userMenuPopup.close();
                        }}>
                        <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                          <img alt="switch space" src="/images/icon_switch_black.svg" />
                        </Stack>
                        {t(`menu.${buyerMode ? "clientSpace" : "buyerSpace"}`)}
                      </MenuItem>
                    )}
                    <MenuItem
                      onClick={() => {
                        setAccountOpen(true);
                        userMenuPopup.close();
                      }}>
                      <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                        <img alt="account" src="/images/icon_account.svg" />
                      </Stack>
                      {t("menu.myAccount")}
                    </MenuItem>

                    <MenuItem
                      onClick={() => {
                        userMenuPopup.close();
                        return navigate(Pages.PRIVACY);
                      }}>
                      <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                        <img alt="privacy policy" src="/images/icon_privacy.svg" />
                      </Stack>
                      {t("global.policy")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        userMenuPopup.close();
                        return navigate(Pages.CGU);
                      }}>
                      <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                        <img alt="cgu" src="/images/icon_terms_and_conditions.svg" />
                      </Stack>
                      {t("global.cgu")}
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        userMenuPopup.close();
                        return sessionService.logout();
                      }}>
                      <Stack width="30px" height="30px" justifyContent="center" alignItems="center" mr="5px">
                        <img alt="logout" src="/images/icon_logout.svg" style={{ marginBottom: 5, marginLeft: 2 }} />
                      </Stack>
                      {t("menu.logout")}
                    </MenuItem>
                  </Menu>
                  {accountOpen && <ProfileModale handleClose={() => setAccountOpen(false)} />}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default LeftBarComponent;
