import React, { useMemo, useState } from "react";
import { alpha, Avatar, FormHelperText, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { finalize } from "rxjs";

import {
  AddStripeProductModel,
  StripeProductModel,
  StripeProductTypeEnum,
  StripeProductTypeFunctions
} from "@store/stripe/products/stripeProducts.model";
import { JobBoard, jobBoardsQuery, School, Sequence } from "@store/jobBoards";
import { stripeProductsService } from "@store/stripe/products/stripeProducts.service";

import { Colors } from "@constants/colors.constant";
import { checkIfErrors, FieldValidationType, getFieldError } from "@utils/yup.utils";
import { SelectItem } from "@components/input/Select.component";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";
import SelectWithSearchComponent from "@components/input/SelectWithSearch.component";
import MultiSelectWithSearchComponent from "@components/input/MultiSelectWithSearch.component";

interface StripeProductModalProps {
  handleClose: () => void;
  stripeProduct: StripeProductModel;
  productType: StripeProductTypeEnum;
}

const StripeProductModal = (props: StripeProductModalProps) => {
  const { handleClose, stripeProduct, productType } = props;

  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<AddStripeProductModel>({
    stripeProductId: stripeProduct.id,
    name: stripeProduct.name,
    information: stripeProduct.information,
    price: stripeProduct.price,
    picture: stripeProduct.picture,
    productType: {label: t(`adminParameters.stripeProducts.type.${productType}`), value: productType},
    ...(productType === StripeProductTypeEnum.SEQUENCE ? (() => {
      const sequence = jobBoardsQuery.sequencesSelectItems.find(
        (x) => x.data.stripeProductId === stripeProduct.id
      );

      const sequenceJobboards = sequence?.data ? (sequence.data as Sequence).jobboards : [];
      const jobboards = jobBoardsQuery.jobBoardsSelectItems;

      return {
        jobboards: sequenceJobboards.map((jId) =>
          jobboards.find((x) => x.value === jId)
        ).filter((x) => !!x) as SelectItem[],
      };
    })() : {}),
    ...(productType === StripeProductTypeEnum.JOB_BOARD ? (() => {
      const jobboard = jobBoardsQuery.jobBoardsSelectItems.find(
        (x) => x.data.stripeProductId === stripeProduct.id
      );

      const jobbardData = jobboard?.data ? (jobboard.data as JobBoard) : undefined;

      return {
        type: jobbardData?.type ?? '',
        link: jobbardData?.link ?? '',
        sellsyProviderId: jobbardData?.sellsyProviderId ?? '',
        providerPrice: jobbardData?.providerPrice,
      };
    })() : {}),
    ...(productType === StripeProductTypeEnum.SCHOOL ? (() => {
      const school = jobBoardsQuery.schoolsSelectItems.find(
        (x) => x.data.stripeProductId === stripeProduct.id
      );

      return { type: school?.data ? (school.data as School).type : '' };
    })() : {}),
  });

  const errors = useMemo(() => ({
    name: getFieldError(data.name, FieldValidationType.REQUIRED_STRIPE_PRODUCT_DATA),
    information: getFieldError(data.information, FieldValidationType.REQUIRED_STRIPE_PRODUCT_DATA),
    price: getFieldError(data.price, FieldValidationType.REQUIRED_STRIPE_PRODUCT_DATA),
    picture: getFieldError(data.picture, FieldValidationType.REQUIRED_STRIPE_PRODUCT_DATA),
    productType: getFieldError(data.productType, FieldValidationType.REQUIRED_SELECT_ITEM),
    type: [StripeProductTypeEnum.JOB_BOARD, StripeProductTypeEnum.SCHOOL].includes(data.productType.value)
      ? getFieldError(data.type, FieldValidationType.REQUIRED_STRING)
      : undefined,
    providerPrice: !!data.sellsyProviderId
      ? getFieldError(data.providerPrice, FieldValidationType.REQUIRED_STRING)
      : undefined,
  }), [data]);

  const updateData = (name: string) => (value: any) => setData((state) => ({
    ...state,
    [name]: value,
  }));

  const handleValidate = () => {
    if (productType !== StripeProductTypeEnum.NONE || data.productType.value === StripeProductTypeEnum.NONE) return;

    setLoading(true);
    stripeProductsService.createStripeProduct(data).pipe(finalize(() => setLoading(false))).subscribe({
      next: () => {
        enqueueSnackbar(t(`adminParameters.stripeProducts.success.${data.productType.value}`), { variant: "success" });
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  };

  return (
    <ModalComponent
      handleClose={handleClose}
      titleLeft
      title={t("adminParameters.stripeProducts.details.title")}
      content={
        <Stack px={breakpointDownMD ? "10px" : "20px"} spacing={3} py={breakpointDownMD ? "15px" : "25px"}>
          <Stack alignItems="center">
            <Avatar
              sx={{
                border: "none",
                borderRadius: "10px !important",
                backgroundColor: stripeProduct.picture ? Colors.white : alpha(Colors.lightpurple, 0.6),
                color: Colors.secondary,
                height: 120,
                width: 120,
              }}
              src={stripeProduct.picture}>
              {stripeProduct.name[0]}
            </Avatar>
            {!!errors.picture && <FormHelperText error>{errors.picture}</FormHelperText>}
          </Stack>
          <AIOTextfieldComponent
            required
            readonly
            title={t("adminParameters.stripeProducts.details.name")}
            value={stripeProduct.name ?? ''}
            error={errors.name} />
          <AIOTextfieldComponent
            required
            readonly
            multiline
            title={t("adminParameters.stripeProducts.details.information")}
            value={stripeProduct.information ?? ''}
            error={errors.information} />
          <AIOTextfieldComponent
            required
            readonly
            title={t("adminParameters.stripeProducts.details.price")}
            value={stripeProduct.price?.toString() ?? ''}
            error={errors.price} />
          <Stack>
            <Typography fontSize="12px" color={Colors.secondaryText}>
              {t("adminParameters.stripeProducts.details.productType")} *
            </Typography>
            <SelectWithSearchComponent
              readOnly={productType !== StripeProductTypeEnum.NONE}
              items={StripeProductTypeFunctions.selectItems}
              handleChange={updateData("productType")}
              value={data?.productType ?? ''}
              error={errors.productType} />
          </Stack>
          {[StripeProductTypeEnum.JOB_BOARD, StripeProductTypeEnum.SCHOOL].includes(data.productType.value) && (
            <AIOTextfieldComponent
              required
              readonly={productType !== StripeProductTypeEnum.NONE}
              title={t("adminParameters.stripeProducts.details.type")}
              placeholder={t("adminParameters.stripeProducts.details.type")}
              onChange={updateData("type")}
              value={data.type ?? ""}
              error={errors.type} />
          )}
          {data.productType.value === StripeProductTypeEnum.JOB_BOARD && (
            <>
              <AIOTextfieldComponent
                readonly={productType !== StripeProductTypeEnum.NONE}
                title={t("adminParameters.stripeProducts.details.link")}
                placeholder={t("adminParameters.stripeProducts.details.link")}
                onChange={updateData("link")}
                value={data.link ?? ""} />
              <AIOTextfieldComponent
                readonly={productType !== StripeProductTypeEnum.NONE}
                title={t("adminParameters.stripeProducts.details.sellsyProviderId")}
                placeholder={t("adminParameters.stripeProducts.details.sellsyProviderId")}
                onChange={updateData("sellsyProviderId")}
                value={data.sellsyProviderId ?? ""} />
              {!!data.sellsyProviderId && (
                <AIOTextfieldComponent
                  required
                  readonly={productType !== StripeProductTypeEnum.NONE}
                  title={t("adminParameters.stripeProducts.details.providerPrice")}
                  placeholder={t("adminParameters.stripeProducts.details.providerPrice")}
                  pattern={new RegExp(/[0-9]*/)}
                  onChange={(val) => updateData("providerPrice")(val === '' ? undefined : +val)}
                  value={data.providerPrice?.toString() ?? ""}
                  error={errors.providerPrice} />
              )}
            </>
          )}
          {data.productType.value === StripeProductTypeEnum.SEQUENCE && (
            <Stack>
              <Typography fontSize="12px" color={Colors.secondaryText}>
                {t("adminParameters.stripeProducts.details.jobboards")}
              </Typography>
              <MultiSelectWithSearchComponent
                readOnly={productType !== StripeProductTypeEnum.NONE}
                placeholder={t("adminParameters.stripeProducts.details.jobboardsPlaceholder")}
                handleChange={updateData('jobboards')}
                multiSelectedLabel={(count) => t("adminParameters.stripeProducts.details.jobboardsNb", { count })}
                items={jobBoardsQuery.jobBoardsSelectItems}
                values={data.jobboards ?? []} />
            </Stack>
          )}
        </Stack>
      }
      maxWidth="xs"
      actions={productType === StripeProductTypeEnum.NONE ? (
        <>
          <AIOButtonComponent
            title={t("global.modify")}
            ariaLabel="modal"
            disabled={loading || checkIfErrors(errors) || data.productType?.value === StripeProductTypeEnum.NONE}
            onClick={handleValidate}
            variant="contained"
            color="secondary"
          />
        </>
      ) : undefined}
    />
  );
};

export default StripeProductModal;
