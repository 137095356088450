import { IconButton, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { useObservable } from "@ngneat/react-rxjs";
import { useTranslation } from "react-i18next";

import { Tone, tonesQuery, tonesService } from "@store/ai-o/tones";

import { Colors } from "@constants/colors.constant";

import PromptsCard from "@components/card/Prompts.card";
import TonesModal from "@components/aio-prompts/modals/Tones.modal";
import ConfirmModal from "@components/modal/Confirm.modal";

const TonesComponent = () => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  const [{tones}] = useObservable(tonesQuery.tone$);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedTone, setSelectedTone] = useState<Tone | undefined>();

  useEffect(() => {
    tonesService.getTones().subscribe({
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }, [enqueueSnackbar]);

  const handleClose = () => {
    setConfirmDelete(false);
    setIsModalOpen(false);
    setSelectedTone(undefined);
  }

  const handleDeleteTone = () => {
    if (!selectedTone) return;
    tonesService.deleteTone(selectedTone.id).subscribe({
      next: () => {
        enqueueSnackbar(t("aio.tones.delete.success"), { variant: "success"});
      },
      error: (err) => enqueueSnackbar(err.text, err.options),
    });
  }

  return (
    <>
      <PromptsCard title={t("aio.tones.title")} hasAddBtn handleAddBtn={() => setIsModalOpen(true)}>
        <Stack m="15px 0 42px 0" direction="row" spacing={2}>
          {tones.map((tone) => (
            <Stack key={tone.id} width="170px">
              <Stack
                height="170px"
                alignItems="center"
                justifyContent="center"
                padding="52px 48px"
                bgcolor={Colors.prompts.toneBg}
                borderRadius="7px"
                position="relative">
                <Stack direction="row" position="absolute" top="10px" right="10px">
                  <IconButton
                    onClick={() => {
                      setIsModalOpen(true);
                      setSelectedTone(tone);
                    }}
                    size="small">
                    <img alt="edit" src="/images/edit.svg" style={{height: '18px'}} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      setConfirmDelete(true);
                      setSelectedTone(tone);
                    }}
                    size="small">
                    <img alt="delete" src="/images/delete.svg" style={{height: '17px'}}/>
                  </IconButton>
                </Stack>
                <img alt="tones representation" src={tone.logoURL} style={{height: '50px'}} />
              </Stack>
              <Typography mt="12px" fontWeight={500} fontSize="14px" textAlign="center">
                {tone.title}
              </Typography>
              <Typography mt="4px" fontWeight={300} fontSize="12px" color={Colors.greyLight} textAlign="center">
                {tone.description}
              </Typography>
            </Stack>
          ))}
        </Stack>
      </PromptsCard>
      {isModalOpen && (
        <TonesModal
          tone={selectedTone}
          handleClose={handleClose} />
      )}
      {!!confirmDelete && (
        <ConfirmModal
          handleConfirm={handleDeleteTone}
          confirmMsg={t("aio.tones.delete.description")}
          modalTitle={t("aio.tones.delete.title")}
          handleClose={handleClose} />
      )}
    </>
  )
}

export default TonesComponent