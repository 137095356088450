import translationFR from "@locales/fr/translations.json";
import translationUS from "@locales/us/translations.json";

export enum Locales {
  FR = "FR",
  US = "US",
}

export const LocaleTranslations = {
  [Locales.FR]: {
    translation: translationFR,
  },
  [Locales.US]: {
    translation: translationUS,
  },
};