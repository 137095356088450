import { Colors } from "@constants/colors.constant";
import { alpha, Components } from "@mui/material";

declare module '@mui/material/Paper' {
  interface PaperPropsVariantOverrides {
    "with-background": true;
  }
}

const paper: Components = {
  MuiPaper: {
    styleOverrides: {
      root: (props) => ({
        ...(props.ownerState.variant === "with-background" ? {
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          color: Colors.marine,
          boxShadow: `0 3px 0px 0px ${alpha("#03053D", 0.02)}`,
          backdropFilter: "blur(11px)",
          width: "fit-content",
        } : {}),
      }),
    },
  },
  MuiCardActionArea: {
    styleOverrides: {
      root: {
        "& > .MuiTouchRipple-root": {
          marginLeft: '0 !important',
          marginTop: '0 !important',
        },
      },
      focusHighlight: {
        marginLeft: '0 !important',
        marginTop: '0 !important',
      },
    },
  },
};

export default paper;
