import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";
import { finalize } from "rxjs";
import { useSnackbar } from "notistack";

import { CompanyDetailsDetailsModel, companyDetailsService } from "@store/ai-o/companies/details";

import ModalComponent from "@components/modal/Modal.component";
import AIOButtonComponent from "@components/Button.component";
import AIOTextfieldComponent from "@components/input/AIOTextfield.component";

interface EditPresentationModalProps {
  company: CompanyDetailsDetailsModel;
  handleClose: () => void;
}

const EditPresentationModal = (props: EditPresentationModalProps) => {
  const { company, handleClose } = props;

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const [description, setDescription] = useState<string>(company.description ?? "");

  const handleSave = () => {
    setLoading(true);
    companyDetailsService
      .updateInformationCompany(company.id, { description })
      .pipe(finalize(() => setLoading(false)))
      .subscribe({
        next: () => {
          enqueueSnackbar(t("aio.clients.details.presentation.success"), { variant: "success" });
          handleClose();
        },
        error: (err) => enqueueSnackbar(err.text, err.options),
      });
  };

  return (
    <ModalComponent
      title={t("aio.clients.details.presentation.editTitle")}
      titleLeft
      content={
        <Box p="10px">
          <Grid container columnSpacing="30px" rowSpacing="20px">
            <Grid item xs={12}>
              <AIOTextfieldComponent
                maxLength={700}
                multiline
                minRows={5}
                title={t("aio.clients.details.presentation.fields.description")}
                placeholder={t("aio.clients.details.presentation.fields.description")}
                value={description ?? ""}
                onChange={setDescription}
              />
            </Grid>
          </Grid>
        </Box>
      }
      actions={
        <AIOButtonComponent
          variant="contained"
          color="secondary"
          ariaLabel="modal"
          disabled={loading}
          onClick={handleSave}
          title={t("global.save")}
        />
      }
      handleClose={handleClose}
    />
  );
};

export default EditPresentationModal;
