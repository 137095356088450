import { SelectItem } from "@components/input/Select.component";
import I18n from "@utils/i18n.utils";
import { Colors } from "@constants/colors.constant";
import { ClientModel } from "@store/entities/clients";
import { FeatureCredits, UserSubscription } from "@store/subscriptions";
import { AffiliateDetailsDetailsModel } from "@store/entities/afiliates/details";

export enum UserAffiliateRoleEnum {
  MANAGER = "MANAGER",
  RESPONSIBLE = "RESPONSIBLE",
  BUYER = "BUYER",
}

export namespace UserAffiliateRoleEnumFunctions {
  export const allItems: SelectItem[] = [
    { label: I18n.t("roles.MANAGER"), value: UserAffiliateRoleEnum.MANAGER },
    { label: I18n.t("roles.RESPONSIBLE"), value: UserAffiliateRoleEnum.RESPONSIBLE },
    { label: I18n.t("roles.BUYER"), value: UserAffiliateRoleEnum.BUYER },
  ];

  export const label = (role: UserAffiliateRoleEnum): string => {
    return I18n.t(`roles.${role}`);
  };
}

export enum UserRoleEnum {
  ADMIN = "ADMIN",
  CLIENT = "CLIENT",
}

export namespace UserRoleEnumFunctions {
  export const allItems: SelectItem[] = [
    { label: I18n.t("roles.admin"), value: UserRoleEnum.ADMIN },
    { label: I18n.t("roles.client"), value: UserRoleEnum.CLIENT },
  ];

  export const color = (role: UserRoleEnum) => {
    switch (role) {
      case UserRoleEnum.ADMIN:
        return Colors.twinin;
      case UserRoleEnum.CLIENT:
        return Colors.primary;
    }
  };
}

export interface UserTagItem {
  id: string;
  display: string;
}

export interface ManageUserAffiliates {
  affiliate?: SelectItem;
  role?: UserAffiliateRoleEnum;
}

export interface ManageUserModel extends Partial<Omit<UserModel, "affiliates" | "client">> {
  linkedinTag?: string;
  pictureFile?: File;
  client?: string;
  affiliates?: ManageUserAffiliates[];
  dontSendWelcomeEmail?: boolean;
}

export const manageUserFromUser = (user: UserModel): ManageUserModel => ({
  ...user,
  client: user.client?.id,
  affiliates: user.affiliates
    ? user.affiliates.map((a) => ({
        affiliate: { label: a.affiliate.name, value: a.affiliate.id },
        role: a.role,
      }))
    : [],
});

export const manageUserToFormData = (user: ManageUserModel, userId?: string | number, isCurrentUser?: boolean) => {
  const formData = new FormData();

  if (!!userId && !isCurrentUser) formData.append("userId", userId.toString());

  if (user.firstname) formData.append("firstname", user.firstname);
  if (user.lastname) formData.append("lastname", user.lastname);
  if (user.email) formData.append("email", user.email);
  if (user.role) formData.append("role", user.role);
  if (user.position) formData.append("position", user.position);
  if (user.picture) formData.append("pictureUrl", user.picture);
  if (user.postalCode) formData.append("postalCode", user.postalCode);
  if (user.dontSendWelcomeEmail) formData.append("dontSendWelcomeEmail", user.dontSendWelcomeEmail.toString());

  if (user.pictureFile) formData.append("picture", user.pictureFile);

  if (user.client) formData.append("client", user.client);
  if (user.affiliates) {
    formData.append(
      "affiliates",
      JSON.stringify(
        user.affiliates
          .filter((a) => !!a.affiliate && !!a.role)
          .map((a) => ({
            affiliateId: a.affiliate!.value,
            role: a.role!,
          }))
      )
    );
  }
  return formData;
};

export interface UserLightModel {
  id: string;
  picture: string;
  firstname: string;
  lastname: string;
  position: string;
  email: string;
  role: UserRoleEnum;
}

export enum UsersSortField {
  LASTNAME = "lastname",
  EMAIL = "email",
  TYPE = "role",
}

export interface UsersSort {
  field?: UsersSortField;
}

export interface UsersFilters {
  search?: string;
  roles?: UserRoleEnum[];
  clientIds?: string[];
  affiliateIds?: string[];
  limit?: number;
}

export interface UserModel {
  id: string;
  picture: string;
  firstname: string;
  lastname: string;
  email: string;
  position: string;
  role: UserRoleEnum;
  client: ClientModel;
  affiliates: {
    role: UserAffiliateRoleEnum;
    affiliate: AffiliateDetailsDetailsModel;
  }[];
  subscription?: UserSubscription;
  featureCredits: FeatureCredits;
  postalCode?: string;
}
