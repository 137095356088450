import { IconButton, InputAdornment, TextField, useMediaQuery, useTheme } from "@mui/material";
import { createRef } from "react";
import { useTranslation } from "react-i18next";

interface AIOSearchComponentProps {
  color?: "primary" | "secondary";
  placeholder?: string;
  value?: string;
  onChange?: (value?: string) => void;
  fullWidth?: boolean;
  width?: string;
  minWidth?: string;
}

const AIOSearchComponent = (props: AIOSearchComponentProps) => {
  const { color = "secondary", placeholder, value, onChange, fullWidth, width, minWidth } = props;

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const breakpointDownMD = useMediaQuery(theme.breakpoints.down("md"));

  const inputRef = createRef<HTMLInputElement>();

  const { t } = useTranslation();

  return (
    <TextField
      placeholder={placeholder || t("global.search")}
      value={value}
      size="small"
      color={color}
      aria-label="search"
      style={{ width: width ?? (breakpointDownSM ? "100%" : breakpointDownMD ? "40vw" : "30vw"), height: "40px", minWidth: minWidth ?? "auto" }}
      fullWidth={fullWidth}
      inputRef={inputRef}
      InputProps={{
        startAdornment: <img alt="search" src="/images/icon_search.svg" style={{ paddingRight: "10px" }} />,
        endAdornment: (
          <InputAdornment position="end">
            {value && (
              <IconButton
                onClick={() => {
                  if (inputRef.current) inputRef.current.value = "";
                  onChange?.("");
                }}>
                <img alt="cross" src="/images/icon_cross.svg" style={{ transform: "scale(1.5)" }} />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
      onChange={(evt) => onChange?.(evt.target.value)}
    />
  );
};

export default AIOSearchComponent;
