import React from "react";
import { useObservable } from "@ngneat/react-rxjs";
import { CardActionArea, Grid, Stack, styled, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material";

import { JobBoard, jobBoardsQuery, jobBoardsService } from "@store/jobBoards";
import { adDetailsQuery } from "@store/ads/details";

import { Colors } from "@constants/colors.constant";
import { BoostCreditsModel } from "@store/common/boostCredits.model";
import { useTranslation } from "react-i18next";
import ImageWithRetryFeature from "@components/ImageWithRetryFeature.component";

interface BoostActionCardStyledProps {
  breakpointDownSM?: boolean;
  selected?: boolean;
}

const BoostActionCardStyled = styled(Stack)(
  (props: BoostActionCardStyledProps) => `
  box-shadow: 0px 0px 20px rgba(34, 34, 64, 0.05);
  background-color: ${Colors.white};
  border: 1px solid ${props.selected ? Colors.primary : "transparent"};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 20px;
  height: ${props.breakpointDownSM ? 130 : 158}px;
  width: ${props.breakpointDownSM ? 130 : 158}px;
  margin: auto;
  opacity: 1;
  flex: 1;
  position: relative;
  & > .MuiCardActionArea-root {
    background-color: ${Colors.white};
    background-repeat: no-repeat;
    background-position: center;
    backdrop-filter: blur(20px);
    align-items: flex-start;
    background-size: cover;
    border-radius: 20px;
    padding: 10px;
    height: 100%;
  }
  &:hover {
    & .MuiCardActionArea-focusHighlight {
      background-color: ${Colors.primary};
      opacity: 0.1;
    }
  }
`
);

const CreditBadgeStyled = styled(Stack)`
  background-color: ${Colors.lightPrimaryNoAlpha};
  height: 22px;
  width: fit-content;
  z-index: 1;
  position: absolute;
  top: 10px;
  left: 10px;
  border-radius: 4px;
`;

const actionsNeededInPrefilteredJobBoards: { [key: string]: string } = {
  "635119b3e5fbb7807c4efcb2": "635c498cbda41f049f2ea332",
};

interface BoostActionsListProps {
  boostCredits?: BoostCreditsModel[];
}

const BoostActionsList = (props: BoostActionsListProps) => {
  const { boostCredits = [] } = props;

  const { t } = useTranslation();

  const theme = useTheme();
  const breakpointDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  const [boostActions] = useObservable(jobBoardsQuery.boostActions$);

  const [filters] = useObservable(jobBoardsQuery.filters$);

  const [clientPrefilteredJobBoards] = React.useState<JobBoard[]>(adDetailsQuery.affiliatePrefilteredJobBoards);

  const boostActionsFiltered = boostActions
    .filter((boostAction) => {
      if (!!filters?.search) {
        return boostAction.name.toLowerCase().includes(filters.search.toLowerCase());
      }
      return true;
    })
    .sort((a, b) => {
      const boostCreditA = boostCredits.find((x) => x.boostItemId === a.id);
      const boostCreditB = boostCredits.find((x) => x.boostItemId === b.id);
      return boostCreditA ? -1 : boostCreditB ? 1 : a.name.localeCompare(b.name);
    });

  return (
    <Stack minHeight="100%" alignItems="center">
      <Grid container spacing={3} justifyContent={breakpointDownSM ? "center" : undefined}>
        {boostActionsFiltered.map((boostAction) => {
          const boostCredit = boostCredits.find((x) => x.boostItemId === boostAction.id);
          const disabledAction =
            boostAction.price <= 0 ||
            (boostCredit && boostCredit.credits - boostCredit.creditsWaitingForValidation <= 0) ||
            (clientPrefilteredJobBoards &&
              clientPrefilteredJobBoards.length > 0 &&
              Object.keys(actionsNeededInPrefilteredJobBoards).includes(boostAction.id) &&
              !clientPrefilteredJobBoards.map((x) => x.id).includes(actionsNeededInPrefilteredJobBoards[boostAction.id]));

          return (
            <Grid item key={boostAction.id}>
              <BoostActionCardStyled selected={boostAction.selected} breakpointDownSM={breakpointDownSM}>
                {boostCredit && (
                  <CreditBadgeStyled alignItems="center" justifyContent="center">
                    <Typography mx="10px" color={Colors.primary} fontWeight={500} fontSize={11}>
                      {t("boostRequest.selection.myself.credits.amount", { count: boostCredit.credits - boostCredit.creditsWaitingForValidation })}
                    </Typography>
                  </CreditBadgeStyled>
                )}
                <Stack
                  disableRipple={disabledAction}
                  disableTouchRipple={disabledAction}
                  sx={{
                    cursor: disabledAction ? "default" : undefined,
                    opacity: disabledAction ? 0.35 : 1,
                  }}
                  onClick={() => {
                    if (!disabledAction) jobBoardsService.selectJobBoardOrSequenceOrBoostAction(boostAction.id);
                  }}
                  component={CardActionArea}>
                  <Stack width="100%" height="100%" justifyContent="center" alignItems="center" position="relative">
                    <Tooltip
                      sx={{ position: "relative" }}
                      title={
                        <Stack spacing={0.25}>
                          <Typography fontSize={12} fontWeight="500">
                            {boostAction.name}
                          </Typography>
                          <Typography fontSize={12} fontWeight="300" lineHeight={1.25} whiteSpace="pre-line">
                            {boostAction.information}
                          </Typography>
                        </Stack>
                      }
                      arrow
                      placement="top">
                      <img alt="details" style={{ position: "absolute", top: "0px", right: "0px" }} src="/images/icon_informations.svg" />
                    </Tooltip>
                    {boostAction.picture && (
                      <Stack height="60%" justifyContent="center" alignItems="center" maxWidth={breakpointDownSM ? "130px" : "158px"}>
                        <ImageWithRetryFeature
                          style={{
                            height: "60%",
                          }}
                          src={boostAction.picture}
                          alt={boostAction.name}
                        />
                      </Stack>
                    )}

                    <Typography
                      alignSelf="center"
                      m="auto"
                      textAlign="center"
                      sx={{ wordBreak: "break-word" }}
                      lineHeight={1.25}
                      fontSize={14}
                      fontWeight="500">
                      {boostAction.name}
                    </Typography>
                  </Stack>
                </Stack>
              </BoostActionCardStyled>
            </Grid>
          );
        })}
      </Grid>
    </Stack>
  );
};

export default BoostActionsList;
