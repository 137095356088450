import I18nUtils from "@utils/i18n.utils";
import { SelectItem } from "@components/input/Select.component";
import { AffiliateLightModel } from "@store/entities/afiliates";
import { BoostStatus } from "../boosts";

export enum AdsSortField {
  NAME = "name",
  LOCATION = "location",
  REFERENCE = "reference",
  CREATION = "publicationDate",
}

export namespace AdsSortField {
  export const selectItems: SelectItem[] = [
    { label: I18nUtils.t("ads.sort.byName"), value: AdsSortField.NAME },
    { label: I18nUtils.t("ads.sort.byReference"), value: AdsSortField.REFERENCE },
    { label: I18nUtils.t("ads.sort.byLocation"), value: AdsSortField.LOCATION },
    { label: I18nUtils.t("ads.sort.byPublicationDate"), value: AdsSortField.CREATION },
  ];
}

export interface AdsSort {
  field: AdsSortField;
}

export interface AdsFilters {
  search?: string;
  affiliateIds?: SelectItem[];
  aioCandidateJobName?: string;
  aioCandidateJobLocation?: string;
  aioCandidateJobReference?: string;

}

export interface AdStatProgress {
  current: number;
  previous: number;
}

export interface AdStats {
  nbViews?: AdStatProgress;
  uniqueVisitor?: AdStatProgress;
  engagementRate?: AdStatProgress;
  bounceRate?: AdStatProgress;
  applications?: AdStatProgress;
  quality?: AdStatProgress;
}

export interface AdStatsReport {
  nbViews?: number;
  engagementRate?: number;
  quality?: number;
}

export interface AdUi {
  id: string;
  selected: boolean;
}

export interface BoostAdModel {
  id: string;
  location: string;
  name: string;
  reference: string;
  description: string;
  affiliate: AffiliateLightModel;
  applications?: {
    aioApplications: number;
    powerBiApplications: number;
  };
  publicationDate: Date;
  managedByTwinin: boolean;
}

export interface AdModel {
  id: string;
  department: string;
  location: string;
  name: string;
  reference: string;
  affiliate: AffiliateLightModel;
  applications?: {
    aioApplications: number;
    powerBiApplications: number;
  };
  managedByTwinin: boolean;
  isBoosted: boolean;
  reportStats?: AdStatsReport;
  boostStatus?: BoostStatus;
  publicationDate: Date;
  inDistress: boolean;
  isCustom: boolean;
  lastSeen: Date;
}
