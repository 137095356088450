import ModalComponent from "@components/modal/Modal.component";
import { Colors } from "@constants/colors.constant";
import { Box, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface ConnectorsOnboardingModalProps {
  handleClose: (dontShowAgain: boolean) => void;
}

const ConnectorsOnboardingModal = (props: ConnectorsOnboardingModalProps) => {
  const { handleClose } = props;

  const { t } = useTranslation();

  return (
    <ModalComponent
      handleClose={() => handleClose(false)}
      titleLeft
      content={
      <Stack direction="row" alignItems="center" py="8px" spacing="40px">
        <img src="/images/phare.svg" />
        <Stack>
          <Box position="relative" width="fit-content">
            <Typography fontSize="32px" fontWeight={500} color={Colors.secondaryText} mb="30px">
              {t("connectors.onboarding.title")}
            </Typography>
            <img
              width="314px"
              src="/images/underline-draw-2.svg"
              alt="underline"
              style={{ position: "absolute", bottom: "36px", right: 0, zIndex: -1 }}
            />
          </Box>
          <Typography fontSize="16px" fontWeight="400" color={Colors.secondaryText} mb="20px">
            {t("connectors.onboarding.description")}
          </Typography>
          <Box position="relative">
            <Typography fontSize="32px" fontWeight={500} color={Colors.secondaryText} mb="40px">
              {t("connectors.onboarding.slogan")}
            </Typography>
            <img width="200px" src="/images/underline-draw-1.svg" alt="underline" style={{ position: "absolute", bottom: "20px", zIndex: -1 }} />
          </Box>
          <Box pl="7px">
            <FormControlLabel
              sx={{ width: "fit-content" }}
              label={(
                <Typography ml="8px" fontSize="14px" fontWeight="500">
                  {t("connectors.onboarding.dontShowAgain")}
                </Typography>
              )}
              onChange={(_, checked) => handleClose(true)}
              control={<Checkbox size="small" />}
            />
          </Box>
        </Stack>
      </Stack>
      }
    />
  );
};

export default ConnectorsOnboardingModal;
